import { FC, PropsWithChildren } from "react"
import { classNames } from "../../../utils"

type Props = {
  className?: string
}

const Skeleton: FC<PropsWithChildren<Props>> = ({ children, className }) => {
  return (
    <div className={classNames("animate-pulse", className)}>{children}</div>
  )
}

export default Skeleton
