import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { getQueryString } from "../../common/utils"
import {
  ACTIVITY_LEGACY_ENDPOINT,
  FIND_OB_ORG_ENDPOINT,
  ORG_LIST_ENDPOINT,
  ORG_MANAGE_ENDPOINT,
  RTK_SLICE_API_LIST
} from "../../common/constants"
import {
  CreateOrgPayload,
  OrgActivity,
  Organization,
  FindObOrgItem
} from "../../types"
import { baseQuery, getTags } from "../api"
import bbApi from "../../api"

const reducerPath = "org"
export const orgMembersPath = "org-members"

export const orgApi = createApi({
  reducerPath,
  baseQuery,
  tagTypes: [reducerPath, orgMembersPath],
  endpoints: builder => ({
    fetchOrgList: builder.query({
      query: (params: any) => `${ORG_LIST_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: { data: Organization[] }) =>
        responseData?.data || [],
      providesTags: result => getTags(reducerPath, "id", result)
    }),
    fetchOrgDetails: builder.query({
      query: (params: { id: string }) =>
        `${ORG_MANAGE_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: Organization) => responseData || [],
      providesTags: (result, error, arg) => [{ type: reducerPath, id: arg.id }]
    }),
    fetchOrgActivities: builder.query({
      query: (params: {
        group_id: string
        org_id?: string
        range_start: number
        range_end: number
        limit?: number
        type?: string
      }) => `${ACTIVITY_LEGACY_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: OrgActivity[]) => responseData || []
    }),
    createOrganization: builder.mutation({
      queryFn: async (data: CreateOrgPayload) => {
        const response = await bbApi.org.create(data)
        return { data: response }
      },
      invalidatesTags: [{ type: reducerPath, id: RTK_SLICE_API_LIST }]
    }),
    updateOrganization: builder.mutation({
      queryFn: async (data: Organization) => {
        const response = await bbApi.org.update(data)
        return { data: response }
      },
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: arg.id }
      ]
    }),
    findObOrg: builder.query({
      query: (params: {
        originator_id?: string
        biz_channel?: string
        org_id?: string
        name?: string
        ignore_cache?: boolean
      }) => `${FIND_OB_ORG_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: FindObOrgItem[]) => responseData
    })
  })
})

export const {
  useFetchOrgListQuery,
  useFetchOrgDetailsQuery,
  useFetchOrgActivitiesQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useFindObOrgQuery
} = orgApi
