import { PricingRate } from "../../../../../types"
import { formatCurrency, getShortFeeTitle } from "../../../../utils"

type Props = {
  value: PricingRate
}

const PtsCell = ({ value }: Props) => {
  return (
    <td className="whitespace-nowrap py-4 pl-2 pr-3 cursor-pointer">
      <div>
        <div className="text-sm text-neutral-400 font-medium">
          PTS {value.pts}
        </div>
        {value.fee && (
          <div className="text-sm">
            <span>{getShortFeeTitle(value.fee)}:&nbsp;</span>
            <span>{formatCurrency(value.fee.amount)}</span>
          </div>
        )}
      </div>
    </td>
  )
}

export default PtsCell
