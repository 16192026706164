import { UTMParametersStats, UTMParametersStatsType } from "../../types"

export const fetchParams = (
  range: number,
  type: UTMParametersStatsType,
  orgId?: string,
  loid?: string
) => ({
  range,
  utm_key: type,
  org_id: orgId,
  loid
})

export const fetchOptions = {
  refetchOnMountOrArgChange: true
}

export const calculateMaxCount = (data: UTMParametersStats[]) =>
  data.length > 0 ? Math.max(...data.map(d => d.lead_count)) : 0
