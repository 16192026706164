import { FC, Fragment } from "react"
import { Combobox, Transition } from "@headlessui/react"
import { Check, ChevronsUpDown, Loader2 } from "lucide-react"

export interface Option {
  id: string
  text: string
}

interface Props {
  name: string
  query: string
  label?: string
  value?: Option
  placeholder?: string
  options: Option[]
  direction?: "vertical" | "horizontal"
  isLoading?: boolean
  onChange: (val: Option) => void
  onChangeQuery: (val: string) => void
}

const Autocomplete: FC<Props> = ({
  name,
  label,
  value,
  query,
  placeholder,
  options,
  direction = "vertical",
  isLoading = false,
  onChange,
  onChangeQuery
}) => {
  return (
    <div
      className={`w-full ${
        direction === "horizontal" ? "flex items-center" : ""
      }`}
    >
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <Combobox value={value} onChange={onChange}>
        <div
          className={`relative ${
            direction === "vertical" ? "mt-1" : "ml-3 flex-1"
          }`}
        >
          <div className="relative flex items-center w-full cursor-default overflow-hidden rounded-lg bg-white text-left border border-neutral-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              className="w-full border-none py-3 pl-3 pr-10 leading-5 text-gray-900 focus:ring-0 appearance-none"
              displayValue={(option: Option) => option?.text}
              onChange={event => onChangeQuery(event.target.value)}
              placeholder={placeholder}
            />
            {isLoading ? (
              <Loader2
                className="animate-spin h-6 w-6 mr-2 text-primary-alt-400"
                aria-hidden="true"
              />
            ) : (
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronsUpDown
                  size={18}
                  className="text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
            )}
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => onChangeQuery("")}
          >
            <Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {options.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                options.map(option => (
                  <Combobox.Option
                    key={option.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? "bg-teal-600 text-white" : "text-gray-900"
                      }`
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {option.text}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-white" : "text-teal-600"
                            }`}
                          >
                            <Check size={16} aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  )
}

export default Autocomplete
