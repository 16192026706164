import { FC, useMemo, useState } from "react"
import BBModal from "../../modal"
import Autocomplete from "../../form/autocomplete"
import {
  useFindCountyDetailsQuery,
  useFindCountyOptionsQuery
} from "../../../../redux/queries"
import TaxRateForm from "./tax-rate-form"

interface Props {
  show: boolean
  onClose: () => void
}

const ConfigCountyAdmin: FC<Props> = ({ show, onClose }) => {
  const [query, setQuery] = useState<string>("")
  const [selectedName, setSelectedName] = useState<string>("")

  const { data: countyOptions, isFetching: isFetchingCountyOptions } =
    useFindCountyOptionsQuery({ name_like: query }, { skip: !query })

  const [countyName, stateName] = selectedName.split(",").map(str => str.trim())
  const { data: countyDetails } = useFindCountyDetailsQuery(
    { name: countyName, state: stateName },
    { skip: !countyName || !stateName }
  )

  const options = useMemo(
    () =>
      isFetchingCountyOptions
        ? []
        : countyOptions?.map(co => ({
            id: co,
            text: co
          })) ?? [],
    [countyOptions, isFetchingCountyOptions]
  )

  const resetSelectedName = () => setSelectedName("")
  const handleClose = () => {
    onClose()
    resetSelectedName()
  }

  return (
    <BBModal
      show={show}
      onClose={onClose}
      title=""
      size="xl"
      forceShowCloseButton
    >
      <div className="min-h-[20rem] p-4">
        <div className="text-lg text-center font-bold">
          Search for a county to view effective
          <br />
          tax rate
        </div>
        <div className="py-6">
          <Autocomplete
            name="query"
            query={query}
            placeholder="Search for a county"
            options={options}
            onChange={option => setSelectedName(option.id)}
            onChangeQuery={setQuery}
            isLoading={isFetchingCountyOptions}
          />
        </div>
        {selectedName && countyDetails && (
          <TaxRateForm data={countyDetails} onClose={handleClose} />
        )}
      </div>
    </BBModal>
  )
}

export default ConfigCountyAdmin
