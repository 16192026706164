import {
  ChangeEvent,
  DragEvent,
  FC,
  ReactNode,
  useCallback,
  useState
} from "react"
import {
  Image as ImageIcon,
  X as CloseIcon,
  Trash2 as TrashIcon
} from "lucide-react"
import { useAppDispatch } from "../../hooks"
import bbApi from "../../../api"
import { pushToast } from "../../../redux/slices/notifications"
import DropZone from "../drop-zone"
import { formatMessage } from "../../utils"
import If from "../if"

interface Props {
  className?: string
  buttonLabel?: string
  imageUrl: string
  droppable?: boolean
  placeholder?: ReactNode
  isFullRounded?: boolean
  onChangeImageUrl: (url: string) => void
  onRemoveImage?: () => void
}

const ImageUploader: FC<Props> = ({
  className = "",
  buttonLabel = "Choose File",
  imageUrl,
  droppable,
  placeholder,
  isFullRounded = true,
  onChangeImageUrl,
  onRemoveImage
}) => {
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState<boolean>(false)

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      uploadPicture(e.target.files?.[0])
    }
  }

  const onChooseFile = (e: DragEvent<HTMLDivElement>) => {
    if (e.dataTransfer.files[0]) {
      uploadPicture(e.dataTransfer.files[0])
    }
  }

  const removeBgImage = () => {
    onRemoveImage?.()
    onChangeImageUrl("")
  }

  const uploadPicture = useCallback(
    async (file: File) => {
      try {
        if (file) {
          setLoading(true)
          const data = await bbApi.asset.uploadImage(file, file.name)

          if ("status" in data) {
            dispatch(
              pushToast({
                msg:
                  formatMessage(data.message) ||
                  "Failed to upload file, please try again later.",
                timeout: 3000
              })
            )
          } else {
            onChangeImageUrl(data.url)
          }

          setLoading(false)
        }
      } catch (error) {
        setLoading(false)
      }
    },
    [dispatch, onChangeImageUrl]
  )

  if (droppable) {
    return (
      <DropZone noDropCover onDrop={onChooseFile}>
        <div className={`${className} w-full`}>
          {imageUrl ? (
            <div className="h-full">
              <div className="relative cursor-pointer" onClick={removeBgImage}>
                <CloseIcon className="absolute top-2 right-2" />
              </div>
              <img className="max-h-full m-auto" src={imageUrl} alt="" />
            </div>
          ) : (
            <div className="h-full flex flex-col items-center justify-center">
              <ImageIcon width={30} height={30} />
              <div className="py-3">Drag and drop file here or</div>

              <div className="text-center">
                {loading ? (
                  <div className="text-sm border border-neutral-300 rounded-md overflow-hidden px-2 py-1 bg-neutral-100 text-neutral-400">
                    Processing...
                  </div>
                ) : (
                  <label className="cursor-pointer text-sm border border-neutral-300 rounded-md overflow-hidden px-2 py-1 bg-neutral-100">
                    {buttonLabel}
                    <input
                      name="file"
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={handleChangeInput}
                    />
                  </label>
                )}
              </div>
            </div>
          )}
        </div>
      </DropZone>
    )
  }

  return (
    <div className={`${className} w-full flex items-center`}>
      <div
        className={`flex flex-col items-center justify-center overflow-hidden ${
          isFullRounded
            ? "rounded-full w-24 h-24 border border-gray-200"
            : "w-40 h-24 border border-gray-200 rounded-lg"
        }`}
      >
        <If
          condition={imageUrl.length > 0}
          then={
            <img
              src={imageUrl}
              className={
                isFullRounded
                  ? "w-24 h-24 rounded-full object-cover truncate"
                  : "w-40 h-24 object-contain"
              }
              alt=""
            />
          }
          else={
            placeholder || (
              <span
                className={`${
                  isFullRounded ? "" : "w-24 h-24"
                } text-center text-sm inline-flex items-center justify-center`}
              >
                No file selected
              </span>
            )
          }
        />
      </div>

      <div className="flex ml-5 text-center">
        {loading ? (
          <div className="text-sm border border-neutral-300 rounded-md overflow-hidden px-2 py-1 bg-neutral-100 text-neutral-400">
            Processing...
          </div>
        ) : (
          <label className="cursor-pointer text-sm border border-neutral-300 rounded-md overflow-hidden px-2 py-1 bg-neutral-100">
            {buttonLabel}
            <input
              name="file"
              type="file"
              hidden
              accept="image/*"
              onChange={handleChangeInput}
            />
          </label>
        )}
        {imageUrl && !loading && (
          <div
            className="px-2 ml-3 flex items-center rounded bg-red-200 cursor-pointer"
            onClick={removeBgImage}
          >
            <TrashIcon size={16} />
          </div>
        )}
      </div>
    </div>
  )
}

export default ImageUploader
