import { useCallback } from "react"
import { pushToast as pushToastAction } from "../../redux/slices/notifications"
import { ToastTheme } from "../../types"
import { useAppDispatch } from "./use-store"

export const useToast = () => {
  const dispatch = useAppDispatch()

  const dispatchPushToast = useCallback(
    (msg: string, theme: ToastTheme) => {
      dispatch(
        pushToastAction({
          msg: msg,
          theme: theme,
          timeout: 3000
        })
      )
    },
    [dispatch]
  )

  const pushSuccessToast = useCallback(
    (msg: string) => {
      dispatchPushToast(msg, "success")
    },
    [dispatchPushToast]
  )

  const pushErrorToast = useCallback(
    (msg: string) => {
      dispatchPushToast(msg, "danger")
    },
    [dispatchPushToast]
  )

  const pushToast = useCallback(
    (msg: string) => {
      dispatchPushToast(msg, "danger")
    },
    [dispatchPushToast]
  )

  return {
    pushSuccessToast,
    pushErrorToast,
    pushToast
  }
}
