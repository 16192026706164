import { AnyAction, Dispatch, MiddlewareAPI } from "@reduxjs/toolkit"
import { capitalize } from "../../common/utils"

export const handleFetchRequestsMiddleware =
  (store: MiddlewareAPI) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction) => {
    const { type, payload } = action
    if (
      type?.includes("executeQuery/fulfilled") &&
      payload?.status === "error"
    ) {
      // if query was "fulfulled" but with error status, convert its type to "rejected"
      const newActionType = type.split("/").slice(0, -1).join("/")
      return next({
        ...action,
        type: `${newActionType}/rejected`,
        payload: undefined,
        error: {
          message: capitalize(payload.message ?? "something went wrong"),
          type: "APIError"
        }
      })
    }

    return next(action)
  }
