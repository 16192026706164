import { ArrowRight, LifeBuoy } from "lucide-react"
import { SUPPORT_URL } from "../../constants"
import If from "../if"
import ReferralBadge from "../referral-badge"

interface Props {
  isFull: boolean
  isDesktop?: boolean
  className?: string
}

const Support: React.FC<Props> = ({ isFull, isDesktop, className = "" }) => {
  return (
    <div
      className={`p-2 pb-12 text-gray-300 whitespace-nowrap overflow-hidden ${className}`}
    >
      <ReferralBadge
        isIcon={!isFull}
        className={`${isDesktop && isFull ? "-mx-2" : ""}`}
      />
      <div className="pt-3">
        <If
          condition={isFull}
          then={
            <>
              <p className="pb-4 text-sm text-gray-400 uppercase">
                Other resources
              </p>
              <a
                href={SUPPORT_URL}
                className="flex-shrink-0 w-full group cursor-pointer flex items-center text-sm text-white"
                target="_blank"
                rel="noreferrer"
              >
                Help Articles
                <ArrowRight
                  size={20}
                  className="text-white"
                  aria-hidden="true"
                />
              </a>
            </>
          }
          else={
            <div className="flex items-center justify-center cursor-pointer">
              <a href={SUPPORT_URL} target="_blank" rel="noreferrer">
                <span className="sr-only">Help Articles</span>
                <LifeBuoy size={22} className="text-white" aria-hidden="true" />
              </a>
            </div>
          }
        />
      </div>
    </div>
  )
}

export default Support
