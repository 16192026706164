import { useSearchAnything } from "../../hooks"
import BBModal from "../../ui/modal"
import SearchResultItem from "./result-item"
import If from "../if"
import SingleRowListSlim from "../skeletons/single-row-list-slim"
import RecentSearches from "./recent-searches"
import PageSearchForm from "./page-search-form"
import ModalSearchForm from "./modal-search-form"

interface Props {
  handleMasquerade: (loid: string) => void
  isAdmin: boolean
}

const SearchAnything = ({ isAdmin, handleMasquerade }: Props) => {
  const {
    modalVisible,
    isLoadingResults,
    query,
    setQuery,
    searchResults,
    recentSearchesData,
    closeModal,
    onClickInput,
    onClickItem,
    onClickResetRecentSearches
  } = useSearchAnything()

  return (
    <div className="relative w-full flex items-center text-gray-400 focus-within:text-gray-600">
      <PageSearchForm onClickInput={onClickInput} />

      <BBModal show={modalVisible} onClose={closeModal} noPadding>
        <>
          <ModalSearchForm query={query} setQuery={setQuery} />
          <div className="h-80 overflow-auto">
            {searchResults.length ? (
              <>
                {searchResults.map((result, idx) => (
                  <SearchResultItem
                    key={`${result.id}-${result.item}-${idx}`}
                    isAdmin={isAdmin}
                    isFirstItem={
                      !idx || result.type !== searchResults[idx - 1].type
                    }
                    data={result}
                    onClickItem={onClickItem}
                    handleMasquerade={handleMasquerade}
                    closeModal={closeModal}
                  />
                ))}
              </>
            ) : query?.length >= 3 ? (
              <If
                condition={isLoadingResults}
                then={<SingleRowListSlim rows={4} />}
                else={
                  <div className="p-4 text-sm text-neutral-400 font-medium">
                    No results found.
                  </div>
                }
              />
            ) : (
              <RecentSearches
                recentSearches={recentSearchesData}
                onClickItem={onClickItem}
                onClickResetRecentSearches={onClickResetRecentSearches}
              />
            )}
          </div>
        </>
      </BBModal>
    </div>
  )
}

export default SearchAnything
