import { Search } from "lucide-react"
import DebouncedInput from "../form/debouncedInput"

interface Props {
  query: string
  setQuery: (val: string) => void
}

const ModalSearchForm = ({ query, setQuery }: Props) => (
  <div className="relative w-full flex items-center text-gray-400 focus-within:text-gray-600 border-b border-neutral-200 p-2">
    <div className="absolute inset-y-0 left-4 flex items-center pointer-events-none">
      <Search className="text-neutral-400" size={20} aria-hidden="true" />
    </div>
    <DebouncedInput
      className="block w-full h-full pl-10 pr-3 py-2 border-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
      value={query}
      onChange={val => setQuery(val.toString())}
    />
  </div>
)

export default ModalSearchForm
