import mapDataUS from "@highcharts/map-collection/countries/us/us-all.topo.json"

import { TransformedCountDataItem } from "../../hooks"

export const columnSeries = {
  name: "Date",
  colorByPoint: true,
  type: "column",
  data: []
}

export const getChartOptions = (): Highcharts.Options => ({
  title: {
    align: "left",
    text: ""
  },
  subtitle: {
    align: "left",
    text: ""
  },
  accessibility: {
    announceNewData: {
      enabled: true
    }
  },
  legend: {
    enabled: false
  },
  credits: {
    enabled: false
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 600
        },
        chartOptions: {
          legend: {
            enabled: false
          }
        }
      }
    ]
  }
})

export const getColumnChartOptions = (type: string): Highcharts.Options => ({
  ...getChartOptions(),
  chart: {
    type: "column"
  },
  xAxis: {
    type: "category"
  },
  yAxis: {
    title: {
      text: type === "emails" ? "Opens" : "Leads"
    }
  },
  plotOptions: {
    series: {
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        format: "{point.y}"
      }
    }
  },
  tooltip: {
    headerFormat: '<span style="font-size:11px">{series.value}</span><br>',
    pointFormat: `<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> ${
      type === "emails" ? "opens" : "leads"
    }<br/>`
  }
})

export const getAreaSplineChartOptions = (
  containsData: boolean | undefined,
  chartLabels: TransformedCountDataItem[],
  tooltipText: string,
  text: string = "",
  areaSplineOptions: Highcharts.PlotAreasplineOptions = {
    fillOpacity: 0.5,
    lineWidth: 2,
    lineColor: "#786ff6",
    fillColor: {
      linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
      stops: [[0, "rgba(120, 111, 246, 0.3)"]]
    }
  }
): Highcharts.Options => ({
  ...getChartOptions(),
  chart: {
    type: "areaspline"
  },
  xAxis: {
    type: "category",
    minPadding: 0
  },
  yAxis: containsData
    ? {
        lineWidth: 1,
        title: {
          text
        }
      }
    : {
        gridLineWidth: 0,
        lineWidth: 1,
        title: {
          text
        },
        tickInterval: 5,
        min: 5,
        max: 25
      },
  tooltip: {
    backgroundColor: "#42405A",
    borderColor: "#42405A",
    borderRadius: 5,
    shadow: false,
    style: {
      color: "#ffffff",
      fontSize: "12px",
      padding: "10px"
    },
    headerFormat: "",
    pointFormat: `<span style="font-weight: 700; padding-left: 5px">{point.name}</span><br/>
          <span>{point.y} ${tooltipText}</span><br/>`
  },
  plotOptions: {
    areaspline: areaSplineOptions
  },
  series: [
    {
      ...columnSeries,
      type: "areaspline",
      data: chartLabels,
      marker: {
        enabled: false
      }
    }
  ]
})

export const getMapChartDefaultOptions = (): Highcharts.Options => ({
  chart: {
    map: mapDataUS
  },
  title: {
    text: undefined
  },
  series: [
    {
      type: "map",
      data: [],
      color: "#F3F4F6",
      borderColor: "#FFFFFF"
    }
  ],
  legend: {
    enabled: false
  },
  credits: {
    enabled: false // Disable the copyright notice
  }
})

export const getMapChartOptions = (
  chartLabels: [string, number][]
): Highcharts.Options => ({
  ...getMapChartDefaultOptions(),
  colorAxis: {
    minColor: "#F4F4F4",
    maxColor: "#786FF6"
  },
  tooltip: {
    backgroundColor: "#42405A",
    borderColor: "#42405A",
    borderRadius: 5,
    shadow: false,
    style: {
      color: "#ffffff",
      fontSize: "12px",
      padding: "10px"
    },
    headerFormat: "",
    pointFormat: `<span style="font-weight: 700; padding-left: 5px">{point.name}</span><br/>
      <span>{point.value} leads</span><br/>`
  },
  series: [
    {
      type: "map",
      data: chartLabels,
      color: "#F3F4F6",
      states: {
        hover: {
          color: "#8B83FB"
        }
      }
    }
  ],
  legend: {
    align: "left",
    verticalAlign: "bottom",
    x: -15,
    y: 18
  }
})

const createTooltipHtml = (
  points: Highcharts.TooltipFormatterContextObject[] | undefined,
  xValue: string | number | undefined
): string => {
  let tooltipHtml = `<div class="highcharts-tooltip-container">`

  if (points) {
    tooltipHtml += points
      .map(
        point => `
          <div class="highcharts-tooltip-point">
            <span style="color:${point.series.options.color}">\u25CF</span>
            <span class="highcharts-tooltip-label">${point.series.name}: ${point.y}</span>
          </div>
        `
      )
      .join("")
  }

  tooltipHtml += `<div class="highcharts-tooltip-footer">${xValue}</div>`
  tooltipHtml += "</div>"

  return tooltipHtml
}

export const getMultiLineChartOptions = (
  containsData: boolean | undefined,
  multiLineOptions: Highcharts.Options
): Highcharts.Options => ({
  ...getChartOptions(),
  ...multiLineOptions,
  yAxis: containsData
    ? {
        lineWidth: 1,
        title: {
          text: ""
        }
      }
    : {
        gridLineWidth: 0,
        lineWidth: 1,
        title: {
          text: ""
        },
        tickInterval: 5,
        min: 5,
        max: 25
      },
  tooltip: {
    useHTML: true,
    formatter: function () {
      return createTooltipHtml(this.points, this.x)
    },
    shared: true
  },
  legend: {
    enabled: false
  }
})

interface CustomPiePoint extends Highcharts.Point {
  y: number
  percentage: number
}

export const getPieChartOptions = (
  chartLabels: [string, number][]
): Highcharts.Options => ({
  title: {
    text: ""
  },
  chart: {
    type: "pie",
    height: 220
  },
  colors: ["#786FF6", "#63C7C4"],
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: false
      },
      showInLegend: true,
      slicedOffset: 0,
      innerSize: "50%"
    }
  },
  series: [
    {
      type: "pie",
      keys: ["name", "y"],
      data: chartLabels
    }
  ],
  legend: {
    enabled: true,
    align: "right",
    verticalAlign: "middle",
    layout: "vertical",
    itemMarginTop: 5,
    itemMarginBottom: 5,
    itemStyle: {
      fontFamily: "Roboto,sans-serif",
      fontSize: "14px",
      fontWeight: "400",
      left: "25px"
    },
    itemHiddenStyle: {
      fontFamily: "Roboto,sans-serif",
      fontSize: "14px",
      fontWeight: "400",
      left: "25px"
    },
    itemHoverStyle: {
      fontFamily: "Roboto,sans-serif",
      fontSize: "14px",
      left: "25px"
    },
    useHTML: false,
    labelFormatter: function () {
      const point = this as CustomPiePoint
      return `${point.name} - ${point.percentage.toFixed(2)}%`
    }
  },
  tooltip: {
    backgroundColor: "#42405A",
    borderColor: "#42405A",
    borderRadius: 5,
    shadow: false,
    style: {
      color: "#ffffff",
      fontSize: "12px",
      padding: "10px"
    },
    headerFormat: "",
    pointFormat: `<span style="font-weight: 700; padding-left: 5px">{point.name}</span><br/>
      <span>{point.y} leads</span><br/>`
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 450
        },
        chartOptions: {
          legend: {
            align: "center",
            verticalAlign: "bottom",
            layout: "horizontal"
          }
        }
      }
    ]
  },
  credits: {
    enabled: false // Disable the copyright notice
  }
})
