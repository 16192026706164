// Import the RTK Query methods from the React-specific entry point
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { RTK_SLICE_API_LIST } from "../../common/constants"

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_HOST ?? "/",
  credentials: "include",
  prepareHeaders: (headers: any, { getState }: any) => {
    const token = getState().auth.user?.sessid
    if (token) {
      headers.set("Authorization", token)
    }
    return headers
  }
})

export const getTags = (reducerPath: string, key: string, result?: any[]) => [
  ...(result ?? []).map(
    item =>
      ({
        type: reducerPath as any,
        id: item[key]
      } as const)
  ),
  { type: reducerPath as any, id: RTK_SLICE_API_LIST }
]
