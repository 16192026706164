import { FC } from "react"
import { Info } from "lucide-react"
import Tooltip from "../tool-tip"

interface Props {
  className?: string
  name: string
  showLabel?: boolean
  label: string
  subLabel?: string
  required?: boolean
  helperText?: string
}

const Label: FC<Props> = ({
  className = "",
  name,
  showLabel = true,
  label,
  subLabel,
  required,
  helperText
}) => {
  return (
    <label
      htmlFor={name}
      className={`${className} block font-medium ${showLabel ? "" : "sr-only"}`}
    >
      <div className="flex items-center">
        {label}
        {subLabel && (
          <span className="text-neutral-400 text-sm">&nbsp;{subLabel}</span>
        )}{" "}
        {required && <span className="text-red-400">*</span>}
        <div className="ml-2">
          {helperText && (
            <Tooltip
              toolTipNode={<Info size={16} color="#aaa" />}
              text={helperText}
              displayTextClass="w-4"
              placement="top"
            />
          )}
        </div>
      </div>
    </label>
  )
}

export default Label
