import { FC } from "react"
import { useNavigate } from "react-router-dom"
import { BBColumnDef, Integration } from "../../../../types"
import Card from "../table/card"
import TableUI from "../../other-table"
import PreviewModal from "../../preview-modal"
import { useAppDispatch } from "../../../hooks"
import { setPreviewModal } from "../../../../redux/slices/integrations"
import TH from "../../th"
import { IntegrationCell } from "../table/cells"

interface Props {
  data: Integration[]
  isLoading: boolean
}

const IntegrationTable: FC<Props> = ({ data, isLoading = false }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const columns: BBColumnDef<Integration>[] = [
    {
      id: "title",
      header: ({ header }) => <TH header={header} srOnly label="Name" />,
      footer: props => props.column.id,
      cell: info => (
        <IntegrationCell
          data={info.row.original}
          onSelect={handleSelectIntegration}
        />
      )
    }
  ]

  const handleSelectIntegration = (id: string) =>
    navigate(`/integrations/${id}`)

  return (
    <div>
      <TableUI
        CardComponent={Card}
        isFetching={isLoading}
        noHeader
        noToolbar
        columns={columns}
        data={data}
      />
      <PreviewModal
        onClose={() => {
          dispatch(
            setPreviewModal({
              isOpen: false,
              appKey: ""
            })
          )
        }}
      />
    </div>
  )
}
export default IntegrationTable
