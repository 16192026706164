import { FC, ReactNode, useEffect, useState } from "react"
import { ChevronDown, ChevronUp } from "lucide-react"

interface Props {
  title: string
  children: ReactNode
  openByDefault?: boolean
  noBorder?: boolean
  childrenClassName?: string
}

const Accordion: FC<Props> = ({
  title,
  children,
  openByDefault,
  noBorder = false,
  childrenClassName = "p-4"
}) => {
  const [collapsed, setCollapsed] = useState<boolean>(!openByDefault)

  const toggleCollapsed = () => setCollapsed(!collapsed)

  useEffect(() => {
    setCollapsed(!openByDefault)
  }, [openByDefault])

  return (
    <>
      <div
        className={`flex items-center justify-between text-base font-semibold text-neutral-900 bg-white hover:bg-gray-100 py-4 px-6 w-full focus:outline-none select-none ${
          collapsed ? "rounded-md" : "rounded-t-md"
        } ${noBorder ? "" : "border border-neutral-200"}`}
        onClick={toggleCollapsed}
      >
        {title}
        <span>{collapsed ? <ChevronDown /> : <ChevronUp />}</span>
      </div>

      {collapsed ? null : (
        <div
          className={`bg-white rounded-b-md ${childrenClassName} ${
            noBorder ? "" : "border-neutral-200 border-b border-l border-r"
          }`}
        >
          {children}
        </div>
      )}
    </>
  )
}

export default Accordion
