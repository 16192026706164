/**
  Pricing set API wrapper

**/

import { api } from "../common/utils"
import {
  SUBSCRIPTION_EMAIL_ENDPOINT,
  SUBSCRIPTION_EMAIL_LOG_ENDPOINT,
  SUBSCRIPTION_ENDPOINT,
  SUBSCRIPTION_LOG_ENDPOINT,
  SUBSCRIPTION_QUOTE_ENDPOINT
} from "../common/constants"

const pricingSets = {
  get: async (params: any): Promise<any> => {
    const { data } = await api.get(SUBSCRIPTION_ENDPOINT, { params })
    return data
  },
  update: async (params: any) => {
    if (!params.id) {
      throw new Error("subscription id missing")
    }
    const { data } = await api.post(SUBSCRIPTION_ENDPOINT, params)
    return data
  },
  add: async (params: any) => {
    if (!params.email && !params.lead_id) {
      throw new Error("subscription email or lead_id missing")
    }
    const { data } = await api.post(SUBSCRIPTION_ENDPOINT, params)
    return data
  },
  delete: async (id: string) => {
    const { data } = await api.delete(`${SUBSCRIPTION_ENDPOINT}?id=${id}`)
    return data
  },
  quote: async (id: string) => {
    const { data } = await api.get(SUBSCRIPTION_QUOTE_ENDPOINT, {
      params: { id }
    })
    return data
  },
  sendIntroEmail: async (id: string) => {
    const { data } = await api.post(SUBSCRIPTION_EMAIL_ENDPOINT, {
      id,
      type: "intro"
    })
    return data
  },
  getLog: async (params: any) => {
    const { data } = await api.get(SUBSCRIPTION_LOG_ENDPOINT, {
      params
    })
    return data
  },
  getEmailLog: async (params: any) => {
    const { data } = await api.get(SUBSCRIPTION_EMAIL_LOG_ENDPOINT, {
      params
    })
    return data
  }
}

export default pricingSets
