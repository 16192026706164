import { classNames } from "../../utils"

export interface TabProps {
  id: string
  label: string
}

interface Props {
  tabs: TabProps[]
  currentTab: string
  onChange: (tabId: string) => void
  className?: string
  navigationClassName?: string
  activeTabClassName?: string
  commontabClassName?: string
  defaultTabClassName?: string
}

const PillTabs = ({
  tabs,
  currentTab,
  onChange,
  className = "justify-center px-6 pb-6",
  navigationClassName = "",
  activeTabClassName = "bg-teal-50 border-teal-500",
  commontabClassName = "whitespace-nowrap px-5 py-2.5 border text-sm leading-relaxed cursor-pointer hover:text-neutral-500 hover:border-neutral-400",
  defaultTabClassName = "border-zinc-200"
}: Props) => {
  return (
    <div className={`flex ${className}`}>
      <nav className={`flex ${navigationClassName}`} aria-label="PillTabs">
        {tabs.map((tab, index) => (
          <div
            key={tab.id}
            className={classNames(
              commontabClassName,
              index === 0 && "rounded-l-md",
              index === tabs.length - 1 && "rounded-r-md",
              tab.id === currentTab ? activeTabClassName : defaultTabClassName
            )}
            onClick={() => onChange(tab.id)}
          >
            {tab.label}
          </div>
        ))}
      </nav>
    </div>
  )
}

export default PillTabs
