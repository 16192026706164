import { FC, memo } from "react"

import Skeleton from "./base"
import GraphSkeletonImage from "../../../assets/images/graph-skeleton.png"

interface GraphProps {
  className?: string
  lineClassName?: string
  showTopTiles?: boolean
  showBottomTiles?: boolean
  noOfTopColumns?: number
  noOfBottomColumns?: number
}

const Graph: FC<GraphProps> = ({
  className = "",
  lineClassName = "mt-11 mb-[22px]",
  showTopTiles = true,
  showBottomTiles = false,
  noOfTopColumns = 2,
  noOfBottomColumns = 4
}) => {
  return (
    <div
      className={`bg-white flex-1 content-center overflow-hidden ${className}`}
    >
      <Skeleton className="h-full">
        <div className="flex flex-col justify-between items-between h-full">
          {showTopTiles && (
            <div className="flex w-full h-32 mb-2">
              {[...Array(noOfTopColumns)].map((_, index) => (
                <div
                  key={`graph-top-column-${index}`}
                  className="flex flex-col w-1/3"
                >
                  <div className="w-3/4 h-3 bg-gray-200 rounded-full mb-2.5"></div>
                  <div className="w-3/5 h-5 bg-gray-100 rounded-full"></div>
                </div>
              ))}
            </div>
          )}
          <div className="w-full flex-1 flex items-end justify-center">
            <img
              className="w-full h-16"
              src={GraphSkeletonImage}
              alt="graph-skeleton"
            />
          </div>
          <div className={`w-full h-[3px] bg-gray-100 ${lineClassName}`}></div>
          {showBottomTiles && (
            <div className="flex w-4/5 mb-2">
              {[...Array(noOfBottomColumns)].map((_, index) => (
                <div
                  key={`graph-bottom-column-${index}`}
                  className="flex flex-col w-1/5"
                >
                  <div className="w-3/4 h-3 bg-gray-200 rounded-full mb-2.5"></div>
                  <div className="w-3/5 h-5 bg-gray-100 rounded-full"></div>
                </div>
              ))}
            </div>
          )}
        </div>
      </Skeleton>
    </div>
  )
}

export default memo(Graph)
