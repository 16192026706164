import { FC, RefObject, useCallback, useEffect, useState } from "react"
import { FormikProps } from "formik"
import SidePanel from "../../side-panel"
import { useAppDispatch } from "../../../hooks"
import CircleSteps from "../../circle-steps"
import CreateOrgForm from "./forms/create-org-form"
import CreateBrandForm from "./forms/create-brand-form"
import CreateLoForm from "./forms/create-lo-form"
import { Brand, CreateOrgPayload, LoanOfficer, IStep } from "../../../../types"
import {
  useCreateOrganizationMutation,
  useAddBrandMutation,
  useCreateLoanOfficerMutation
} from "../../../../redux/queries"
import { pushToast } from "../../../../redux/slices/notifications"

interface Props {
  open: boolean
  onClose: () => void
}

const steps: IStep[] = [
  {
    id: "org",
    label: "Organization details"
  },
  {
    id: "brand",
    label: "Add brand"
  },
  {
    id: "loan-officer",
    label: "Add loan officer"
  }
]

const CreateOrganizationPanel: FC<Props> = ({ open, onClose }) => {
  const dispatch = useAppDispatch()

  const [newOrg, setNewOrg] = useState<string>() // new organization id
  const [newBrand, setNewBrand] = useState<string>() // new brand id
  const [newLo, setNewLo] = useState<string>() // new LO id
  const [currentStep, setCurrentStep] = useState<string>("org") // "org" | "brand" | "loan-officer"

  const [createOrgFormRef, setCreateOrgFormRef] =
    useState<RefObject<FormikProps<CreateOrgPayload>>>()
  const [createBrandFormRef, setCreateBrandFormRef] =
    useState<RefObject<FormikProps<Brand>>>()
  const [createLoFormRef, setCreateLoFormRef] =
    useState<RefObject<FormikProps<LoanOfficer>>>()

  const [
    createOrganization,
    { isLoading: isLoadingOrg, reset: resetCreateOrgApi }
  ] = useCreateOrganizationMutation()
  const [
    createBrand,
    { isLoading: isLoadingBrand, reset: resetCreateBrandApi }
  ] = useAddBrandMutation()
  const [createLo, { isLoading: isLoadingLo, reset: resetCreateLoApi }] =
    useCreateLoanOfficerMutation()

  const isLoading = isLoadingOrg || isLoadingBrand || isLoadingLo

  const handleStepChange = (stepId: string) => setCurrentStep(stepId)

  const showError = (type: "organization" | "brand" | "loan officer") => {
    dispatch(
      pushToast({
        msg: `Failed to create ${type}.`,
        theme: "danger",
        timeout: 3000
      })
    )
  }

  const resetForms = useCallback(() => {
    createOrgFormRef?.current?.resetForm()
    createBrandFormRef?.current?.resetForm()
    createLoFormRef?.current?.resetForm()
  }, [createBrandFormRef, createLoFormRef, createOrgFormRef])

  const handleClose = useCallback(() => {
    setCurrentStep("org")
    resetForms()
    resetCreateOrgApi()
    resetCreateBrandApi()
    resetCreateLoApi()
    onClose()
  }, [
    onClose,
    resetCreateBrandApi,
    resetCreateLoApi,
    resetCreateOrgApi,
    resetForms
  ])

  const onSubmitCreateOrgForm = async () => {
    try {
      if (
        createOrgFormRef?.current?.isValid &&
        createOrgFormRef?.current?.values
      ) {
        const res = await createOrganization(
          createOrgFormRef.current?.values
        ).unwrap()

        if ("org_id" in res) {
          setNewOrg(res.org_id)
          createOrgFormRef.current.resetForm()
          return
        }

        showError("organization")
      }
    } catch (error) {
      showError("organization")
    }
  }

  const onSubmitCreateBrandForm = async () => {
    try {
      if (
        createBrandFormRef?.current?.isValid &&
        createBrandFormRef?.current?.values
      ) {
        const res = await createBrand(
          createBrandFormRef.current?.values
        ).unwrap()

        if ("brand_id" in res) {
          setNewBrand(res.brand_id)
          createBrandFormRef.current.resetForm()
          return
        }

        showError("brand")
      }
    } catch (error) {
      showError("brand")
    }
  }

  const onSubmitCreateLoForm = async () => {
    try {
      if (
        createLoFormRef?.current?.isValid &&
        createLoFormRef?.current?.values
      ) {
        const res = await createLo(createLoFormRef.current?.values).unwrap()

        if ("loid" in res) {
          setNewLo(res.loid)
          createLoFormRef.current.resetForm()
          return
        }

        showError("loan officer")
      }
    } catch (error) {
      showError("loan officer")
    }
  }

  const onSave = () => {
    if (currentStep === "org") {
      onSubmitCreateOrgForm()
    } else if (currentStep === "brand") {
      onSubmitCreateBrandForm()
    } else if (currentStep === "loan-officer") {
      onSubmitCreateLoForm()
    }
  }

  useEffect(() => {
    if (newOrg) {
      setCurrentStep("brand")
    }
  }, [newOrg])

  useEffect(() => {
    if (newBrand) {
      setCurrentStep("loan-officer")
    }
  }, [newBrand])

  useEffect(() => {
    if (newLo) {
      dispatch(
        pushToast({
          msg: "Successfully completed all the steps.",
          theme: "success",
          timeout: 3000
        })
      )
      handleClose()
    }
  }, [dispatch, newLo, handleClose])

  return (
    <SidePanel
      title="Add new organization"
      open={open}
      onClose={handleClose}
      actions={[
        {
          label: "Save & Continue",
          type: "primary",
          onClick: onSave,
          disabled: isLoading
        }
      ]}
    >
      <div className="py-12">
        <CircleSteps
          className="flex justify-center"
          steps={steps}
          activeStep={currentStep}
          onStepChange={handleStepChange}
        />
      </div>
      {currentStep === "org" && (
        <CreateOrgForm
          setFormRef={setCreateOrgFormRef}
          onSubmit={onSubmitCreateOrgForm}
        />
      )}
      {currentStep === "brand" && (
        <CreateBrandForm orgId={newOrg} setFormRef={setCreateBrandFormRef} />
      )}
      {currentStep === "loan-officer" && (
        <CreateLoForm brandId={newBrand} setFormRef={setCreateLoFormRef} />
      )}
    </SidePanel>
  )
}

export default CreateOrganizationPanel
