import { memo } from "react"

const NoData = () => (
  <div className="relative flex-1">
    <div className="w-full absolute top-1/2 text-center text-sm z-[1]">
      No data yet
    </div>
  </div>
)

export default memo(NoData)
