import { FC, useState } from "react"
import { useParams } from "react-router-dom"
import { RequireRole } from "../../common/validation"
import { ALLOWED_ROLES_BY_ROUTE } from "../../common/constants"
import OrganizationDetailsHeader from "../../common/ui/organization-details/header"
import {
  useFetchOrgDetailsQuery,
  useFetchActivitiesByOrgQuery
} from "../../redux/queries"
import Tabs from "../../common/ui/tabs"
import OrgDetailsLoanOfficers from "../../common/ui/organization-details/loan-officers"
import OrgActivities from "../../common/ui/organization-details/activities"
import OrgDetailsBrands from "../../common/ui/organization-details/brands"
import OrgDetails from "../../common/ui/organization-details/details"
import EditOrganization from "../../common/ui/organizations/edit"
import { Organization } from "../../types"

const OrganizationDetailsPage: FC<{}> = () => {
  const { orgId: id = "" } = useParams()

  const { data, isFetching } = useFetchOrgDetailsQuery({ id }, { skip: !id })

  const {
    data: activities,
    isFetching: isFetchingActivities,
    refetch: refetchActivities
  } = useFetchActivitiesByOrgQuery(
    {
      org_id: id,
      limit: 999
    },
    { skip: !id }
  )

  const [tab, setTab] = useState<string>("loan-officers") // "loan-officers", "details", "brands", "activity"
  const [selectedOrg, setSelectedOrg] = useState<Organization>()

  const resetSelected = () => setSelectedOrg(undefined)

  const onChangeTab = (id: string) => setTab(id)

  if (!data) return null

  return (
    <RequireRole allowedRoles={ALLOWED_ROLES_BY_ROUTE.organizations}>
      <div className="h-full flex flex-col">
        <OrganizationDetailsHeader data={data} onClickEdit={setSelectedOrg} />
        <div className="flex-1 flex flex-col">
          <Tabs
            className="px-6 bg-white"
            currentTab={tab}
            tabs={[
              { id: "loan-officers", label: "Loan officers" },
              { id: "brands", label: "Brands" },
              { id: "details", label: "Org. Details" },
              { id: "activity", label: "Activity" }
            ]}
            onChange={onChangeTab}
          />
          <div className="flex-1">
            {tab === "details" && <OrgDetails data={data} />}
            {tab === "loan-officers" && <OrgDetailsLoanOfficers orgId={id} />}
            {tab === "activity" && (
              <OrgActivities
                data={activities ?? []}
                isFetching={isFetchingActivities}
                refetch={refetchActivities}
              />
            )}
            {tab === "brands" && (
              <OrgDetailsBrands
                orgId={id}
                data={data.brands ?? []}
                isLoading={isFetching}
              />
            )}
          </div>
        </div>
        <EditOrganization
          open={!!selectedOrg}
          data={selectedOrg}
          onClose={resetSelected}
          isInSidePanel
        />
      </div>
    </RequireRole>
  )
}

export default OrganizationDetailsPage
