import { FC, memo } from "react"

import Skeleton from "./base"

interface FourColumnPerformanceProps {
  className?: string
}

const FourColumnPerformance: FC<FourColumnPerformanceProps> = ({
  className = ""
}) => {
  return (
    <div
      className={`bg-white flex-1 content-center overflow-hidden ${className}`}
    >
      <Skeleton className="h-full">
        <div className="flex flex-col justify-between items-between h-full">
          <div className="flex w-full h-28 mb-10">
            <div className="flex flex-col w-1/3">
              <div className="w-3/4 h-3 bg-gray-200 rounded-full mb-2.5"></div>
              <div className="w-3/5 h-5 bg-gray-100 rounded-full"></div>
            </div>
            <div className="flex flex-col w-1/3">
              <div className="w-3/4 h-3 bg-gray-200 rounded-full mb-2.5"></div>
              <div className="w-3/5 h-5 bg-gray-100 rounded-full"></div>
            </div>
          </div>
          <div className="flex-1 flex w-full items-end justify-center">
            <div className="w-1/4 h-12 bg-gray-100 mr-6"></div>
            <div className="w-1/4 h-16 bg-gray-100 mr-6"></div>
            <div className="w-1/4 h-20 bg-gray-100 mr-6"></div>
            <div className="w-1/4 h-32 bg-gray-100"></div>
          </div>
          <div className="w-full h-[3px] bg-gray-100 mb-[22px]"></div>
        </div>
      </Skeleton>
    </div>
  )
}

export default memo(FourColumnPerformance)
