import { FC } from "react"
import If from "../../../if"
import { BBColumnDef, ContactForm } from "../../../../../types"
import NameCell from "./cells/name"
import IsDefaultCell from "./cells/is-default"
import Table from "../../../table"

interface Props {
  data: ContactForm[]
  isFetching: boolean
  onSelect: (val: string) => void
}

const ContactFormTable: FC<Props> = ({ data, isFetching, onSelect }) => {
  const columns: BBColumnDef<ContactForm>[] = [
    {
      id: "name",
      header: "Name",
      accessorFn: row => row.name,
      footer: props => props.column.id,
      cell: props => <NameCell info={props.row.original} onSelect={onSelect} />
    },
    {
      id: "is_default",
      header: "Default",
      accessorFn: row => row.is_default,
      footer: props => props.column.id,
      cell: props => <IsDefaultCell info={props.row.original} />
    }
  ]

  return (
    <div>
      <If
        condition={!data?.length && !isFetching}
        then={<div>No contact forms found.</div>}
        else={
          <Table
            isLoading={isFetching}
            data={data}
            columns={columns}
            viewType="table"
            shrinkFilterSort
            noHeader
            searchBarVisible={false}
            defaultSortBy="created"
            defaultSortDir="desc"
          />
        }
      />
    </div>
  )
}

export default ContactFormTable
