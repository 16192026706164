export const HELP_TEXT_URL = "Enter base URL of site that will be integrated."

export const LOAN_TYPE_OPTIONS = [
  {
    id: "conventional",
    name: "Conventional"
  },
  {
    id: "VA",
    name: "VA"
  },
  {
    id: "USDA",
    name: "USDA"
  },
  {
    id: "FHA",
    name: "FHA"
  },
  {
    id: "ARM",
    name: "ARM"
  }
]

export const LOAN_TERM_OPTIONS = [
  {
    id: "30",
    name: "30 Year Fixed"
  },
  {
    id: "20",
    name: "20 Year Fixed"
  },
  {
    id: "15",
    name: "15 Year Fixed"
  }
]

export const ARM_TERM_OPTIONS = [
  {
    id: "10",
    name: "10"
  },
  {
    id: "7",
    name: "7"
  },
  {
    id: "5",
    name: "5"
  }
]

export const PROPERTY_TYPE_OPTIONS = [
  {
    id: "single_family_home",
    name: "Single family home"
  },
  {
    id: "townhome",
    name: "Townhome"
  },
  {
    id: "condominium",
    name: "Condominium"
  },
  {
    id: "multi_tenant_home",
    name: "Multi family"
  }
]

export const RESIDENCY_TYPE_OPTIONS = [
  {
    id: "primary_home",
    name: "Primary home"
  },
  {
    id: "second_home",
    name: "Secondary home"
  },
  {
    id: "rental_home",
    name: "Rental home"
  }
]

export const MILITARY_STATUS_OPTIONS = [
  {
    id: "yes",
    name: "Yes"
  },
  {
    id: "no",
    name: "No"
  }
]

export const LOAN_PURPOSE_OPTIONS = [
  { id: "purchase", name: "Purchase" },
  { id: "refinance", name: "Refinance" }
]

export const ENGAGEMENT_STATUS_OPTIONS = [
  { id: "active", name: "Active" },
  { id: "disabled", name: "Disabled" },
  { id: "deleted", name: "Deleted" }
]
