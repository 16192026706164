import { FC, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Lead, BBColumnDef, ViewType } from "../../../../types"
import Table from "../../table"
import { ToggleView } from "../../table/toolbar"
import LeadsCell from "./LeadsCell"
import OwnerCell from "./OwnerCell"

interface Props {
  data: Lead[]
  isLoading: boolean
}

const BrandDetailsLeads: FC<Props> = ({ data, isLoading = false }) => {
  const [viewType, setViewType] = useState<ViewType>("table")
  const navigate = useNavigate()

  const columns: BBColumnDef<Lead>[] = [
    {
      id: "name",
      header: "Leads",
      accessorFn: row => row,
      footer: props => props.column.id,
      cell: props => (
        <LeadsCell data={props.getValue() as any} onClick={handleClick} />
      )
    },
    {
      id: "owner",
      header: "Owner",
      accessorFn: row => row,
      footer: props => props.column.id,
      cell: props => <OwnerCell data={props.getValue() as any} />,
      meta: { headerClassName: "hidden md:table-cell" }
    }
  ]
  const handleClick = (id: string) => navigate(`/leads/${id}`)

  return (
    <div className="px-8">
      <Table
        data={data}
        isLoading={isLoading}
        columns={columns}
        viewType={viewType}
        pageSize={12}
        searchPlaceholder="Search leads by name or email"
        searchBarVisible={false}
        extraHeaderElement={
          <ToggleView
            className="ml-auto"
            viewType={viewType}
            setViewType={setViewType}
          />
        }
      />
    </div>
  )
}

export default BrandDetailsLeads
