export const RATEFLOW_ENGINE_TEMPLATES = [
  {
    value: "polly",
    name: "Polly"
  },
  {
    value: "mortech",
    name: "Mortech"
  },
  {
    value: "ob",
    name: "Optimal Blue"
  },
  {
    value: "loan_sifter",
    name: "Loan Sifter"
  },
  {
    value: "lender_price",
    name: "Lender Price"
  }
]

export const RATEFLOW_ENGINE_CONFIGS = [
  {
    api_mode: "polly",
    api_config: {
      audience_id: "",
      api_username: "BankingBridgeAPI",
      api_password: "7niDDeym6QGbs09kR6XR",
      client_id: "",
      client_secret: ""
    }
  },
  {
    api_mode: "lender_price",
    api_config: {
      user_id: "",
      company_id: "",
      api_key: "!AEx8FgClara.com"
    }
  },
  {
    api_mode: "loan_sifter",
    api_config: {
      user_id: "",
      company_id: "",
      client_id: "7a50b13a-57d6-484b-8fbf-f3a83c1a5eec",
      client_secret: "nWy8Q~tZ_t6Zr0dQhglW5Yu4YXVseENs7_YyLcJs"
    }
  },
  {
    api_mode: "ob"
  },
  {
    api_mode: "mortech",
    api_config: {
      customer_id: "",
      email: ""
    }
  }
]
