import { Dialog, Transition } from "@headlessui/react"
import { Fragment } from "react"
import Backdrop from "./backdrop"

const PanelWrapper = ({
  children,
  actions,
  open,
  header
}: {
  children: React.ReactNode
  actions: React.ReactNode
  open: boolean
  header: React.ReactNode
}) => (
  <>
    <Backdrop />
    <div className="fixed inset-0 overflow-hidden">
      <div className="absolute inset-0 overflow-hidden">
        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <Dialog.Panel className={`pointer-events-auto w-screen max-w-4xl`}>
              <div className="flex h-full flex-col bg-white py-6 shadow-xl">
                {header}
                <div className="relative flex-1 px-4 sm:px-6">
                  {/* Replace with your content */}
                  <div className="absolute inset-0 px-4 sm:px-6 overflow-auto">
                    <div className="h-full" aria-hidden={open}>
                      {children}
                    </div>
                  </div>
                  {/* /End replace */}
                </div>

                {actions}
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </div>
  </>
)

export default PanelWrapper
