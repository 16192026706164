import { FC } from "react"
import ImageUploader from "../../../../../../image-uploader"
import { LeadworkflowDetails } from "../../../../../../../../types"

interface Props {
  values: LeadworkflowDetails
  setBgImage: (url: string) => void
}

const LeadworkflowUpload: FC<Props> = ({ values, setBgImage }) => {
  return (
    <ImageUploader
      className="h-96"
      droppable
      imageUrl={values.config_json.background_img}
      onChangeImageUrl={setBgImage}
    />
  )
}

export default LeadworkflowUpload
