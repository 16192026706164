// auth
export const LOGIN_ENDPOINT = "/api/v1/login"
export const GET_MASQUERADE_ENDPOINT = (loid: string) =>
  `/api/v1/masquerade?uid=${loid}`
export const DELETE_MASQUERADE_ENDPOINT = "/api/v1/masquerade"
export const GET_ME_ENDPOINT = "/api/v1/me"
export const UPDATE_EMAIL_ENDPOINT = "/api/v1/reset-user-email"
export const UPDATE_PWD_ENDPOINT = "/api/v1/reset-user-password"
export const RESET_PWD_ENDPOINT = "/api/v1/password-reset"
export const RESET_PWD_EMAIL_ENDPOINT = "/api/v1/password-reset-email"
export const GET_CORE_UI_ENV = "/core-ui-env.js"

// activity
export const ACTIVITY_ENDPOINT = "/api/v1/activity"
export const ACTIVITY_LEGACY_ENDPOINT = "/api/v1/activity-legacy"

// integrations
export const ENDPOINT_INTEGRATIONS = "/api/v1/integrations"
export const ENDPOINT_STATUS = "/api/v1/embed-requests"
export const ENDPOINT_DELETE_DOMAIN = "/v1/app-domains"

// api key
export const APIKEY_ENDPOINT = "/aws_api/manage.json"

// monitor
export const MONITOR_ENDPOINT = "/api/v1/embed-monitor"

// contact forms
export const CONTACT_FORMS_ENDPOINT = "/api/v1/contact-forms"
export const CONTACT_FORM_ENDPOINT = "/api/v1/contact-form"

// lead workflows
export const LEAD_WORKFLOW_ENDPOINT = "/api/v1/lead_workflow"
export const LEAD_WORKFLOW_ACTIVE_ENDPOINT = "/api/v1/lead-workflow-active"
export const LEAD_WORKFLOWS_ENDPOINT = "/api/v1/lead-workflows"

// pricing sets
export const PRICING_SET_ENDPOINT = "/api/v1/pricing-set"
export const DEFAULT_PRICING_SET_ENDPOINT = "/api/v1/default-pricing-set"

// rateflow
export const RATEFLOW_PRICE_ENDPOINT = "/rateflow/price.json"
export const RATEFLOW_SCENARIO_ENDPOINT = "/api/v1/default-scenarios"
export const RATEFLOW_LOG_ENDPOINT = "/api/v1/rateflow-log"
export const RATEFLOW_CHART_ENDPOINT = "/api/v1/chart-data"
export const RATEFLOW_ENDPOINT = "/rateflow/manage.json"
export const RATEFLOW_COMPARE_ENDPOINT = "/api/v1/rateflow-compare"

// domains
export const DOMAINS_ENDPOINT = "/org/domains.json"

// brands
export const BRAND_MANAGE_ENDPOINT = "/bank/manage.json"
export const BRAND_REVIEWS_ENDPOINT = "/api/v1/reviews/brand"
export const PLACES_ENDPOINT = "/api/v1/places"
export const BRAND_LIST_ENDPOINT = "/api/v1/brands"

// loan officers
export const LO_MANAGE_ENDPOINT = "/lo-manage.json"
export const LO_IMPORT_MEMBER_ENDPOINT = "/api/v1/import-lo-member"
export const LO_ENDPOINT = "/api/v1/members"
export const LO_REVIEWS_ENDPOINT = "/api/v1/reviews/lo"
export const LO_IMPORT_ENDPOINT = "/api/v1/lo-import"
export const FIND_OB_LO_ENDPOINT = "/api/v1/find-ob-lo"

// leads
export const LEAD_MANAGE_ENDPOINT = "/lead-manage.json"
export const LEADLOG_ENDPOINT = "/api/v1/lead-log"
export const LEAD_VALIDATE_ENDPOINT = "/lead-validate.json"
export const LEAD_HISTORY = "/api/v1/lead-history"
export const LEAD_COUNT_ENDPOINT = "api/v1/bq-stats/leadCount"
export const LEAD_COUNT_CUMULATIVE_ENDPOINT =
  "api/v1/bq-stats/leadCountCumulative"
export const DUPLICATE_LEAD_COUNT_ENDPOINT =
  "api/v1/bq-stats/duplicateLeadCount"
export const TOP_LEAD_BREAKDOWN_URL_ENDPOINT =
  "api/v1/bq-stats/leadBreakdownUrl"
export const TOP_LEAD_BREAKDOWN_EMBEDS_ENDPOINT =
  "api/v1/bq-stats/leadBreakdownEmbed"
export const TOP_LEAD_BREAKDOWN_LOS_ENDPOINT = "api/v1/bq-stats/leadBreakdownLo"
export const LEAD_COUNT_BY_STATE_ENDPOINT = "api/v1/bq-stats/leadCountByState"
export const LEAD_COUNT_BY_CITY_ENDPOINT = "api/v1/bq-stats/leadCountByCity"
export const LEAD_COUNT_BY_PROPERTY_ENDPOINT =
  "api/v1/bq-stats/leadCountByPropertyType"
export const LEAD_COUNT_BY_RESIDENCY_ENDPOINT =
  "api/v1/bq-stats/leadCountByResidencyType"
export const LEAD_COUNT_BY_LOAN_PURPOSE_ENDPOINT =
  "api/v1/bq-stats/leadCountByLoanPurpose"
export const LEAD_METRICS_ENDPOINT = "api/v1/bq-stats/leadMetrics"
export const LEAD_CONVERSION_FUNNEL_ENDPOINT =
  "api/v1/bq-stats/leadConversionFunnel"
export const LEAD_CONVERSION_PROGRESS_ENDPOINT =
  "api/v1/bq-stats/leadConversionProgress"
export const SUBSCRIPTION_COUNT_ENDPOINT = "api/v1/bq-stats/subscriptionCount"
export const SUBSCRIPTION_COUNT_CUMULATIVE_ENDPOINT =
  "api/v1/bq-stats/subscriptionCountCumulative"
export const EXIT_POPUP_CONVERSION_FUNNEL_ENDPOINT =
  "api/v1/bq-stats/exitPopupConversionFunnel"
export const RATE_TABLE_USAGE_ENDPOINT = "api/v1/bq-stats/rateTableUsage"
export const SUBSCRIPTION_EMAIL_STATS_ENDPOINT =
  "api/v1/bq-stats/subscriptionEmailStats"
export const UNSUBSCRIBED_STATS_ENDPOINT = "api/v1/bq-stats/unsubscribedStats"
export const QUOTE_RECEIPT_STATS_ENDPOINT = "api/v1/bq-stats/quoteReceiptStats"
export const UTM_PARAMETERS_STATS_ENDPOINT = "api/v1/bq-stats/utmCount"

// organizations
export const ORG_MANAGE_ENDPOINT = "/org/manage.json"
export const ORG_MEMBERS_ENDPOINT = "/org/members.json"
export const ORG_LIST_ENDPOINT = "/api/v1/orgs"
export const ORG_CREATE_ENDPOINT = "/api/v1/new-customer"
export const FIND_OB_ORG_ENDPOINT = "/api/v1/find-ob-org"

// loan summary pdf
export const LOAN_SUMMARY_PDF_ENDPOINT = "/api/v1/loan-summary-pdf"

// subscriptions
export const SUBSCRIPTION_ENDPOINT = "/api/v1/subscriptions"
export const SUBSCRIPTION_QUOTE_ENDPOINT = "/api/v1/subscription-quote"
export const SUBSCRIPTION_LOG_ENDPOINT = "/api/v1/subscription-log"
export const SUBSCRIPTION_EMAIL_LOG_ENDPOINT = "/api/v1/subscription-email-log"
export const SUBSCRIPTION_EMAIL_ENDPOINT = "/api/v1/subscription-email"
export const SUBSCRIPTION_EVENTS_ENDPOINT = "/api/v1/subscription-events"
export const SUBSCRIPTION_QUOTES_ENDPOINT = "/api/v1/subscription-quotes"
export const SUBSCRIPTION_TAXES_INSURANCE_ENDPOINT =
  "/api/v1/taxes-and-insurance"
export const SUBSCRIPTION_ORG_ACTIVITY_STATS_ENDPOINT =
  "/api/v1/bq-stats/orgSubscriptionActivityStats"
export const SUBSCRIPTION_BRAND_ACTIVITY_STATS_ENDPOINT =
  "/api/v1/bq-stats/brandSubscriptionActivityStats"
export const SUBSCRIPTION_LO_ACTIVITY_STATS_ENDPOINT =
  "/api/v1/bq-stats/loSubscriptionActivityStats"

// dashbaord
export const DASHBOARD_PERFORMANCE_STATS_ENDPOINT =
  "/api/v1/performance-chart-data"
export const SUBSCRIPTION_CONFORMING_LIMIT_ENDPOINT = "/api/v1/conforming-limit"

// config admin
export const RATE_TABLE_CONFIG_ENDPOINT = "/api/v1/rate-table-config"
export const FIND_COUNTY_ENDPOINT = "/api/v1/county"

// subscription config
export const SUBSCRIPTION_CONFIG_ENDPOINT = "/api/v1/subscription-configs"
