import { useMemo } from "react"
import { Link } from "react-router-dom"
import { DateTime } from "luxon"
import DashboardLOCard from "../../dashboard-lo-card"
import { LoanOfficer } from "../../../../types"

interface Props {
  loList: LoanOfficer[]
}

const RecentLOs: React.FC<Props> = ({ loList }) => {
  const loListWithDaydDiff = useMemo(
    () =>
      [...loList]
        .map((lo: LoanOfficer) => {
          const dateTime = DateTime.fromMillis(lo.created * 1000)
          const daysDiff = Math.trunc(
            DateTime.now().diff(dateTime, "days").days
          )
          return {
            ...lo,
            daysDiff
          }
        })
        .filter(lo => lo.daysDiff <= 90)
        .sort((a, b) => b.created - a.created),
    [loList]
  )

  return (
    <div className="bg-white basis-2/4 flex flex-col pt-6 px-6 rounded-md">
      <div className="text-base font-semibold">Recently added LO's</div>
      <div className="py-6 flex-1">
        {loListWithDaydDiff?.length ? (
          <div className="h-full max-h-96 overflow-auto">
            {loListWithDaydDiff.map(lo => (
              <DashboardLOCard key={lo.uid} data={lo} />
            ))}
          </div>
        ) : (
          <div>No recent LO's</div>
        )}
      </div>
      <div className="py-4 text-primary-400 text-sm border-t border-gray-300 text-right">
        <Link to="/loan-officers">View all LO's</Link>
      </div>
    </div>
  )
}

export default RecentLOs
