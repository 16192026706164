import { ChevronRight } from "lucide-react"
import { PricingRate } from "../../../../../types"

type Props = {
  value: PricingRate
  onClick: (id: string) => void
}

const ActionCell = ({ value, onClick }: Props) => {
  return (
    <td className="whitespace-nowrap py-4 pl-2 pr-6 cursor-pointer">
      <div className="flex items-center justify-end text-neutral-400">
        <ChevronRight
          onClick={() => onClick(value.rateflow_log_id.toString())}
        />
      </div>
    </td>
  )
}

export default ActionCell
