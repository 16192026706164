import { FC } from "react"
import { RateflowCard } from "../../../../../../types"
import QuoteCard from "./quote-card"
import {
  DEFAULT_DATETIME_DAY_FORMAT,
  formatDateTimeFromMillis
} from "../../../../../utils"
import If from "../../../../if"

interface Props {
  quoteId: string
  loanAmount: number
  timestamp?: number
  cards: RateflowCard[]
  onSelect: (id: string) => void
}

const QuotesGroup: FC<Props> = ({ loanAmount, timestamp, cards, onSelect }) => {
  return (
    <div className="py-3">
      <time
        {...(timestamp ? { dateTime: new Date(timestamp)?.toISOString() } : {})}
        className="font-bold text-lg pb-4"
      >
        {timestamp
          ? formatDateTimeFromMillis(
              timestamp * 1000,
              DEFAULT_DATETIME_DAY_FORMAT
            )
          : "Unknown date"}
      </time>

      <If
        condition={!!cards.length}
        then={
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
            {cards.map(card => (
              <QuoteCard
                key={card.hash}
                data={{ ...card, loanAmount }}
                onSelect={onSelect}
              />
            ))}
          </div>
        }
        else={<div>No cards for this quote</div>}
      />
    </div>
  )
}

export default QuotesGroup
