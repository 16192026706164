import { Form, Formik } from "formik"

interface Props<T> {
  initialValues: T
  validationSchema: any
  onSubmit: (formValues: T) => void
  enableReinitialize?: boolean
  children: React.ReactNode
  className?: string
}

const classNameDefault = ""

const Wrapper = <T extends {}>({
  onSubmit,
  validationSchema,
  initialValues,
  enableReinitialize = true,
  children,
  className = classNameDefault
}: Props<T>) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
    enableReinitialize={enableReinitialize}
  >
    <Form className={className}>{children}</Form>
  </Formik>
)

export default Wrapper
