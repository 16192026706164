import { FC, RefObject, useEffect, useRef } from "react"
import { FormikProps, Formik, Form } from "formik"
import { CreateOrgPayload } from "../../../../../types"
import { createOrgFormSchema } from "./schemas"
import ImageUploader from "../../../image-uploader"
import Input from "../../../form/input"

interface Props {
  setFormRef: (formRef: RefObject<FormikProps<CreateOrgPayload>>) => void
  onSubmit: () => Promise<void>
}

const CreateOrgForm: FC<Props> = ({ setFormRef, onSubmit }) => {
  const formRef = useRef<FormikProps<CreateOrgPayload>>(null)

  const handleChangeLogo = (logoUrl: string) =>
    formRef.current?.setFieldValue("logo_fid", logoUrl)

  const INITIAL_VALUES: CreateOrgPayload = {
    name: "",
    about: "",
    website: "",
    logo_fid: "",
    listing_access: false
  }

  useEffect(() => {
    if (formRef.current) {
      setFormRef(formRef)
    }
  }, [setFormRef])

  return (
    <div>
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={createOrgFormSchema}
        onSubmit={onSubmit}
        innerRef={formRef}
        validateOnBlur
        enableReinitialize
      >
        {({ values }) => (
          <Form>
            <div>
              <div className="p-6">
                <div>
                  <h4 className="text-xl font-bold mb-6 text-primary-alt-400 ">
                    Organization details
                  </h4>
                </div>

                <ImageUploader
                  buttonLabel="Upload Org. logo"
                  imageUrl={values.logo_fid}
                  onChangeImageUrl={handleChangeLogo}
                />
                <div className="py-3">
                  <Input label="Name of organization" name="name" />
                </div>
                <div className="py-3">
                  <Input
                    label="Website URL"
                    name="website"
                    beforeLabel="https://"
                    placeholder="Ex. google.com"
                  />
                </div>
                <div className="py-3">
                  <Input
                    label="About organization"
                    name="about"
                    as="textarea"
                    inputProps={{ rows: 4 }}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CreateOrgForm
