import { SubscriptionLog } from "../../../../../types"
import {
  DEFAULT_DATETIME_LONG_FORMAT,
  formatDateTimeFromTimestamp
} from "../../../../utils"

type Props = {
  info: SubscriptionLog
  onClick: (id: string) => void
}

const OwnerCell = ({ info, onClick }: Props) => {
  const { id, created, owner_email } = info

  return (
    <td
      className="whitespace-nowrap py-4 pl-2 pr-3 cursor-pointer hidden md:table-cell"
      onClick={() => onClick(id)}
    >
      <div className="text-sm text-black">
        {formatDateTimeFromTimestamp(created, DEFAULT_DATETIME_LONG_FORMAT)}
      </div>
      <div className="text-sm text-black">by&nbsp;{owner_email || "N/A"}</div>
    </td>
  )
}

export default OwnerCell
