import { FC } from "react"

interface Props {
  label: string
  value?: string | number
}

const DataItem: FC<Props> = ({ label, value = "" }) => {
  return (
    <div className="pt-6">
      <div className="text-neutral-400 font-medium">{label}</div>
      <div className="text-neutral-900">{value.toString() || "-"}</div>
    </div>
  )
}

export default DataItem
