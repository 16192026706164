import { Eye, Loader2 } from "lucide-react"
import RowOptions from "../../../table/row-options"
import {
  useFetchLeadDetailsQuery,
  useFetchSubscriptionsByLeadIdQuery
} from "../../../../../redux/queries"
import { LeadLog, SubscriptionDetails } from "../../../../../types"
import BBSwitch from "../../../form/Switch"

interface Props {
  info: LeadLog
  onPushToEngagement: (log: LeadLog) => void
  onEnrollToRateAlerts: (subscription?: SubscriptionDetails) => void
}

const MenuCell: React.FC<Props> = ({
  info,
  onPushToEngagement,
  onEnrollToRateAlerts
}) => {
  const { data, isFetching } = useFetchLeadDetailsQuery(info?.id, {
    skip: !info.id
  })

  const { data: subscriptions } = useFetchSubscriptionsByLeadIdQuery(
    {
      lead_id: info.id ?? ""
    },
    {
      skip: !info.id
    }
  )

  const subscription = subscriptions?.[0]

  if (!data || isFetching) {
    return (
      <div className="w-full p-6 flex items-center justify-end">
        <Loader2
          className="animate-spin h-6 w-6 text-primary-alt-400"
          aria-hidden="true"
        />
      </div>
    )
  }

  return (
    <td className="relative whitespace-nowrap py-4 px-0 text-right text-sm font-medium sm:pr-6 pl-3 md: pr-4">
      <RowOptions
        actions={[
          {
            label: "Push to subscription",
            icon: Eye,
            onClick: () => onPushToEngagement(info)
          },
          {
            label: (
              <div className="flex items-center justify-between">
                <span className="mr-2">Enroll to rate alerts</span>
                <BBSwitch
                  label=""
                  enabled={subscription?.active === "1"}
                  setEnabled={() => onEnrollToRateAlerts(subscription)}
                />
              </div>
            ),
            onClick: () => false,
            className:
              "border-t border-neutral-200 bg-transparent focus:bg-transparent focus:text-black",
            hidden: !subscription
          }
        ]}
      />
    </td>
  )
}
export default MenuCell
