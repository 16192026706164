/**
  Embed Monitor API wrapper

	JSON endpoint: /api/v1/embed-monitor
  POST /org/domains.json - Create a monitor
  {
    "url": "https://google.com/*",
    "app_key": <app_key>,
    "delay": <number>
    "width": <number>
    "height": <number>
    "offset": <number>
  }
  GET  /org/domains.json?org_id=<org_id> - returns a list of approved URLs
**/

import { api } from "../common/utils"
import { MONITOR_ENDPOINT } from "../common/constants"
import {
  MonitorCreatePost,
  MonitorCreateResponse,
  MonitorGetResponse,
  MonitorUpdatePost
} from "../types"

const monitor = {
  create: async (
    monitorData: MonitorCreatePost
  ): Promise<MonitorCreateResponse> => {
    if (!monitorData.app_key) {
      return new Promise((resolve, reject) => {
        reject("app_key missing")
      })
    }

    const { data } = await api.post<MonitorCreateResponse>(MONITOR_ENDPOINT, {
      ...monitorData
    })
    return data
  },
  update: async (
    monitorData: MonitorUpdatePost
  ): Promise<MonitorCreateResponse> => {
    if (!monitorData.app_key) {
      return new Promise((resolve, reject) => {
        reject("app_key missing")
      })
    }

    const { data } = await api.post<MonitorCreateResponse>(MONITOR_ENDPOINT, {
      ...monitorData
    })
    return data
  },
  get: async (app_key: string): Promise<MonitorGetResponse> => {
    const { data } = await api.get<MonitorGetResponse>(
      `${MONITOR_ENDPOINT}?app_key=${app_key}`
    )
    return data
  }
}

export default monitor
