import { Loader2 } from "lucide-react"

const LOADER_SIZE_DEFAULT = 12

type Props = {
  condition: boolean
  elseCondition?: boolean
  isLoading?: boolean
  then: React.ReactNode
  else?: React.ReactNode
  default?: React.ReactNode
  loaderSize?: number
}

const If = ({
  condition,
  elseCondition = true,
  isLoading,
  then,
  else: elseComponent,
  default: defaultComponent,
  loaderSize = LOADER_SIZE_DEFAULT
}: Props) => {
  if (isLoading) {
    return (
      <div className="h-full flex items-center justify-center p-4">
        <Loader2
          className={`animate-spin h-${loaderSize} w-${loaderSize} text-primary-alt-400`}
          aria-hidden="true"
        />
      </div>
    )
  }

  if (condition) {
    return <>{then}</>
  }

  if (elseCondition && elseComponent) {
    return <>{elseComponent}</>
  }

  return defaultComponent ? <>{defaultComponent}</> : null
}

export default If
