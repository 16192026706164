import { LeadworkflowStep, LeadworkflowStepType } from "../../types"

export const getLeadworkflowStep = (
  type: LeadworkflowStepType
): LeadworkflowStep => {
  switch (type) {
    case "list":
      return {
        id: "employment_status",
        type,
        title: "What is your employment status?",
        items: [
          {
            label: "Employed",
            value: "employed"
          },
          {
            label: "Not Employed",
            value: "not_employed"
          },
          {
            label: "Military",
            value: "military"
          },
          {
            label: "Retired",
            value: "retired"
          },
          {
            label: "Self Employed",
            value: "self_employed"
          }
        ]
      }
    case "buttons":
      return {
        id: "foreclosure_bankruptcy",
        type,
        title: "Have you had a bankruptcy or foreclosure in last 4 years?",
        items: [
          {
            icon: "https://cdndev.bankingbridge.com/workflow/images/good.svg",
            label: "No",
            value: "no"
          },
          {
            icon: "https://cdndev.bankingbridge.com/workflow/images/bankruptcy.svg",
            label: "Bankruptcy",
            value: "bankruptcy"
          },
          {
            icon: "https://cdndev.bankingbridge.com/workflow/images/foreclosure.svg",
            label: "Foreclosure",
            value: "foreclosure"
          }
        ]
      }
    case "slider":
      return {
        id: "household_income",
        type,
        title: "Annual Household Income",
        range_start: 30000,
        range_end: 400000,
        point_size: 10000,
        step: 10000,
        prefix: "$"
      }
    case "location_autocomplete":
      return {
        id: "location",
        type,
        title: "In what city is the property located?",
        placeholder: "Enter City, State, Zip, etc..."
      }
    case "lo_select":
      return {
        id: "loid",
        type,
        title: "Are You Already Working With A Loan Officer?"
      }
  }
}
