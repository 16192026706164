import { FC, useMemo } from "react"
import { Loader2 } from "lucide-react"
import { If } from "../.."
import { useFetchActivitiesByLoQuery } from "../../../../redux/queries"
import { LoanOfficer } from "../../../../types"
import ActivityTable from "../../activity/table"

interface Props {
  loanOfficer: LoanOfficer
}

const LOActivities: FC<Props> = ({ loanOfficer }) => {
  const {
    data: allActivities,
    isFetching,
    refetch
  } = useFetchActivitiesByLoQuery({
    loid: loanOfficer.loid as string
  })

  const data = useMemo(
    () => allActivities?.map(a => ({ ...a, isLoActivity: true })),
    [allActivities]
  )

  return (
    <div className="px-6">
      <If
        condition={!isFetching}
        then={
          <ActivityTable
            data={data}
            isLoading={isFetching}
            searchBarVisible={false}
            refetch={refetch}
          />
        }
        else={
          <div
            className="h-full bg-white flex items-center justify-center"
            style={{ minHeight: "calc(100vh - 312px)" }}
          >
            <Loader2
              className={`animate-spin h-16 w-16 text-primary-alt-400`}
              aria-hidden="true"
            />
          </div>
        }
      />
    </div>
  )
}

export default LOActivities
