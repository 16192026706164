import { FC } from "react"
import { Switch } from "@headlessui/react"
import { classNames } from "../../utils"
import Tooltip from "../tool-tip"
import { Info } from "lucide-react"

interface Props {
  enabled: boolean
  setEnabled?: (val: boolean) => void
  label?: string
  description?: string
  helperText?: string
  className?: string
  switchPosition?: "left" | "right"
}

const BBSwitch: FC<Props> = ({
  enabled,
  setEnabled,
  label,
  description,
  helperText,
  switchPosition = "right",
  className = ""
}) => {
  return (
    <Switch.Group
      as="div"
      className={`${className} ${
        switchPosition === "left" ? "flex-row-reverse" : ""
      } z-10 flex items-center justify-between`}
    >
      <span
        className={`flex flex-grow flex-col ${
          switchPosition === "left" ? "ml-3" : ""
        }`}
      >
        {label && (
          <Switch.Label
            as="span"
            className="font-medium text-gray-900 pr-2"
            passive
          >
            <div className="flex items-center">
              {label}
              {helperText ? (
                <div className="ml-2">
                  <Tooltip
                    toolTipNode={<Info size={20} color="#63C7C4" />}
                    text={helperText}
                    displayTextClass="w-5"
                    placement="right"
                  />
                </div>
              ) : null}
            </div>
          </Switch.Label>
        )}
        {description && (
          <Switch.Description as="span" className="text-gray-500">
            {description}
          </Switch.Description>
        )}
      </span>
      <Switch
        checked={enabled}
        disabled={!setEnabled}
        onChange={setEnabled}
        className={classNames(
          enabled ? "bg-indigo-600" : "bg-gray-200",
          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
          )}
        />
      </Switch>
    </Switch.Group>
  )
}

export default BBSwitch
