import { FC, useState } from "react"
import { Link } from "react-router-dom"
import { UserDetails } from "../../../../types"
import BBSwitch from "../../form/Switch"
import { formatDateTimeFromTimestamp } from "../../../utils"

interface Props {
  info: UserDetails
}

const OtherInfo: FC<Props> = ({ info }) => {
  const [notificationsEnabled, setNotificationsEnabled] =
    useState<boolean>(false)

  return (
    <div className="pt-12">
      <div className="flex items-center justify-between pb-4 border-b border-neutral-200">
        <div className="text-lg font-bold">Other Information</div>
      </div>
      <div className="pt-6">
        <div className="text-neutral-400">
          Joined BankingBridge on&nbsp;
          {formatDateTimeFromTimestamp(info.created, "dd LLLL yyyy")}
        </div>
        <div className="pt-6">
          <BBSwitch
            enabled={notificationsEnabled}
            setEnabled={setNotificationsEnabled}
            label="Enable BankingBridge notifications"
            description="By enabling you will be receiving email notifications."
          />
        </div>
        <div className="pt-6 pb-12">
          <div className="text-neutral-900 font-medium">Member of</div>
          <div>
            {info.org?.brands?.map(b => (
              <div key={b.id}>
                <Link className="text-primary-500" to={`/brands/${b.id}`}>
                  {b.name}
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OtherInfo
