import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { setApiError, setMessage } from "./notifications"
import { RootState } from "../store"
import bbApi from "../../api"
import { User } from "../../types"
import {
  api,
  getBBUserFromLocalStorage,
  removeBBUserFromLocalStorage,
  setBBUserInLocalStorage
} from "../../common/utils"
import { pushToast } from "../../redux/slices/notifications"

const userFromStorage = getBBUserFromLocalStorage()
export const STORE_RESET = "store/reset"

type UserType = {
  username: string
  password: string
}

export interface AuthState {
  isLoggedIn: boolean
  user: User | null
  emailUpdated: boolean
  passwordUpdated: boolean
  profileUpdated: boolean
}

export const loginWithSession = createAsyncThunk(
  "auth/loginWithSession",
  async (sessid: string, thunkAPI) => {
    try {
      api.defaults.headers.common["Authorization"] = sessid
      const data = await bbApi.auth.getMe()

      if ("status" in data) {
        if (data?.status?.toLocaleLowerCase() === "error") {
          const msg = "Invalid user"
          thunkAPI.dispatch(setMessage({ text: msg, type: "danger" }))
          return thunkAPI.rejectWithValue(msg)
        }
      } else {
        const userData = { sessid, user: data } as User
        setBBUserInLocalStorage(userData)
        return { data: userData }
      }
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString()
      thunkAPI.dispatch(setMessage({ text: message, type: "danger" }))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const login = createAsyncThunk(
  "auth/login",
  async ({ username, password }: UserType, thunkAPI) => {
    try {
      const data = await bbApi.auth.login({ username, password })

      if (data.user) {
        setBBUserInLocalStorage(data)
      }

      if (data?.status?.toLocaleLowerCase() === "error") {
        const msg = "Invalid username or password"
        thunkAPI.dispatch(setMessage({ text: msg, type: "danger" }))
        return thunkAPI.rejectWithValue(msg)
      }

      return { data }
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString()
      thunkAPI.dispatch(setMessage({ text: message, type: "danger" }))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const logout = createAsyncThunk("auth/logout", (_, thunkAPI) => {
  thunkAPI.dispatch({
    type: STORE_RESET
  })
  removeBBUserFromLocalStorage()
})

export const masquerade = createAsyncThunk(
  "auth/masquerade",
  async (loid: string, thunkAPI) => {
    try {
      const data = await bbApi.auth.masquerade(loid)

      if (data.user) {
        setBBUserInLocalStorage(data)
      }

      thunkAPI.dispatch(
        pushToast({
          msg: `Masqueraded as '${data.user.username}'`,
          theme: "success",
          timeout: 3000
        })
      )

      return { data }
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString()
      thunkAPI.dispatch(setMessage({ text: message, type: "danger" }))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deleteMasquerade = createAsyncThunk(
  "auth/deleteMasquerade",
  async (_, thunkAPI) => {
    try {
      const data = await bbApi.auth.deleteMasquerade()

      if (data.user) {
        setBBUserInLocalStorage(data)
      }

      thunkAPI.dispatch(
        pushToast({
          msg: `Switched back to '${data.user.username}'`,
          theme: "success",
          timeout: 3000
        })
      )

      return { data }
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString()
      thunkAPI.dispatch(setMessage({ text: message, type: "danger" }))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getMe = createAsyncThunk("auth/getMe", async (_, thunkAPI) => {
  try {
    const data = await bbApi.auth.getMe()

    if ("status" in data) {
      if (data?.status?.toLocaleLowerCase() === "error") {
        thunkAPI.dispatch(setApiError(data))
        return thunkAPI.rejectWithValue(data)
      }
      return
    }

    return { userDetails: data }
  } catch (error: any) {
    const message =
      error?.response?.data?.message || error?.message || error?.toString()
    thunkAPI.dispatch(setMessage({ text: message, type: "danger" }))
    return thunkAPI.rejectWithValue(message)
  }
})

export const updateEmail = createAsyncThunk(
  "auth/updateEmail",
  async ({ uid, email }: { uid: string; email: string }, thunkAPI) => {
    try {
      const data = await bbApi.auth.updateEmail(uid, email)

      if ("status" in data) {
        if (data?.status?.toLocaleLowerCase() === "error") {
          thunkAPI.dispatch(setApiError(data))
          return thunkAPI.rejectWithValue(data)
        }
        return
      }

      return data
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString()
      thunkAPI.dispatch(setMessage({ text: message, type: "danger" }))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const updatePassword = createAsyncThunk(
  "auth/updatePassword",
  async ({ uid, password }: { uid: string; password: string }, thunkAPI) => {
    try {
      const data = await bbApi.auth.updatePassword(uid, password)

      if ("status" in data) {
        if (data?.status?.toLocaleLowerCase() === "error") {
          thunkAPI.dispatch(setApiError(data))
          return thunkAPI.rejectWithValue(data)
        }
        return
      }

      return data
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString()
      thunkAPI.dispatch(setMessage({ text: message, type: "danger" }))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const sendPasswordResetEmail = createAsyncThunk(
  "auth/sendPasswordResetEmail",
  async ({ email, bbSid }: { email: string; bbSid: string }, thunkAPI) => {
    try {
      const data = await bbApi.auth.sendPasswordResetEmail({ email, bbSid })

      if ("status" in data) {
        if (data?.status?.toLocaleLowerCase() === "error") {
          const msg = data.message || "Email not found"
          thunkAPI.dispatch(setMessage({ text: msg, type: "danger" }))
          return thunkAPI.rejectWithValue(msg)
        }

        return
      }

      return data
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (
    {
      newPassword,
      bbSid,
      token
    }: { newPassword: string; bbSid: string; token: string },
    thunkAPI
  ) => {
    try {
      const data = await bbApi.auth.resetPassword({ newPassword, bbSid, token })

      if ("status" in data) {
        if (data?.status?.toLocaleLowerCase() === "error") {
          const msg = data.message || "Failed to reset password"
          thunkAPI.dispatch(setMessage({ text: msg, type: "danger" }))
          return thunkAPI.rejectWithValue(msg)
        }

        return
      }

      return data
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString()
      thunkAPI.dispatch(setMessage({ text: message, type: "danger" }))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getCoreUIEnv = createAsyncThunk(
  "auth/getCoreUIEnv",
  async (_, thunkAPI) => {
    try {
      const data = await bbApi.auth.getCoreUIEnv()
      if ("status" in data) {
        if (data?.status?.toLocaleLowerCase() === "error") {
          const msg = "Email not found"
          thunkAPI.dispatch(setMessage({ text: msg, type: "danger" }))
          return thunkAPI.rejectWithValue(msg)
        }

        return
      }

      return data
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString()
      thunkAPI.dispatch(setMessage({ text: message, type: "danger" }))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

const initialState: AuthState = userFromStorage
  ? {
      isLoggedIn: true,
      user: userFromStorage,
      emailUpdated: false,
      passwordUpdated: false,
      profileUpdated: false
    }
  : {
      isLoggedIn: false,
      user: null,
      emailUpdated: false,
      passwordUpdated: false,
      profileUpdated: false
    }

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    toggleProfileUpdated: (state: AuthState, action) => {
      state.profileUpdated = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoggedIn = true
      state.user = action.payload.data
    })
    builder.addCase(login.rejected, state => {
      state.isLoggedIn = false
      state.user = null
    })
    builder.addCase(loginWithSession.fulfilled, (state, action) => {
      state.isLoggedIn = true
      state.user = action.payload?.data ?? null
    })
    builder.addCase(loginWithSession.rejected, state => {
      state.isLoggedIn = false
      state.user = null
    })
    builder.addCase(masquerade.fulfilled, (state, action) => {
      state.isLoggedIn = true
      state.user = action.payload.data
    })
    builder.addCase(masquerade.rejected, state => {})
    builder.addCase(deleteMasquerade.fulfilled, (state, action) => {
      state.user = action.payload.data
    })
    builder.addCase(deleteMasquerade.rejected, state => {})
    builder.addCase(getMe.fulfilled, (state, action) => {
      if (state.user && action.payload?.userDetails) {
        state.user = { ...state.user, user: action.payload?.userDetails }
      }
    })
    builder.addCase(getMe.rejected, state => {})
    builder.addCase(logout.fulfilled, state => {
      state.isLoggedIn = false
      state.user = null
    })
    builder.addCase(updateEmail.fulfilled, state => {
      state.emailUpdated = true
    })
    builder.addCase(updatePassword.fulfilled, state => {
      state.passwordUpdated = true
    })
  }
})

export const { toggleProfileUpdated } = authSlice.actions

export const selectSessId = (state: RootState) => state.auth.user?.sessid
export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn
export const selectUser = (state: RootState) => state.auth.user
export const selectUserDetails = (state: RootState) => state.auth.user?.user
export const selectUserRoles = (state: RootState) =>
  state.auth.user?.user.roles ?? []
export const selectUserId = (state: RootState) => state.auth.user?.user.id
export const selectEmailUpdated = (state: RootState) => state.auth.emailUpdated
export const selectPasswordUpdated = (state: RootState) =>
  state.auth.passwordUpdated
export const selectProfileUpdated = (state: RootState) =>
  state.auth.profileUpdated

export default authSlice.reducer
