type WrapperProps = {
  children: React.ReactNode
  className?: string
  theme: "active" | "initial" | "idle" | "inactive"
}

type Props = {
  className?: string
  label?: string
}

const classNameDefault = ""

const Wrapper = ({
  children,
  theme,
  className = classNameDefault
}: WrapperProps) => (
  <span
    className={`status status-${theme} ${className} h-5 inline-flex items-center`}
  >
    <i aria-hidden="true" className="mr-1" /> {children}
  </span>
)

const Active = ({ label = "Active", ...wrapperProps }: Props) => {
  return (
    <Wrapper {...wrapperProps} theme="active">
      {label}
    </Wrapper>
  )
}

const Idle = ({ label = "Idle", ...wrapperProps }: Props) => {
  return (
    <Wrapper {...wrapperProps} theme="idle">
      {label}
    </Wrapper>
  )
}

const Initial = ({ label = "Initial", ...wrapperProps }: Props) => {
  return (
    <Wrapper {...wrapperProps} theme="initial">
      {label}
    </Wrapper>
  )
}

const Inactive = ({ label = "Inactive", ...wrapperProps }: Props) => {
  return (
    <Wrapper {...wrapperProps} theme="inactive">
      {label}
    </Wrapper>
  )
}

const Status = { Active, Initial, Idle, Inactive }

export default Status
