const API_URL = process.env.REACT_APP_API_URL
const STORAGE_USER_KEY = "bb-user"
const STORATE_RECENT_SEARCH_KEY = "bb-recent-search"

export const RTK_SLICE_API_LIST = "LIST"
export const BOLD_BI_ROOT_URL = "https://bankingbridge.boldbi.com/bi"
export const BOLD_BI_DASHBOARD_API_URL =
  process.env.REACT_APP_API_HOST + "/api/v1/boldbi-dashboard"
export { API_URL, STORAGE_USER_KEY, STORATE_RECENT_SEARCH_KEY }

export const REFERRAL_LINK = "https://www.bankingbridge.com/referral-program"
export const SUPPORT_URL = "https://bankingbridgesupport.zendesk.com/hc/en-us"
