import { FC, useState } from "react"
import {
  useFindContactFormDetailsQuery,
  useFindContactFormsQuery
} from "../../../../redux/queries"
import { Brand } from "../../../../types"
import BrandAutocomplete from "../../brand-autocomplete"
import ContactFormTable from "./table"
import ManageContactForm from "./manage"

interface Props {
  brands: Brand[]
}

const ConfigContactForm: FC<Props> = ({ brands }) => {
  const [selectedBrandId, setSelectedBrandId] = useState<string>("")
  const [selectedContactFormId, setSelectedContactFormId] = useState<string>("")

  const { data: contactForms, isFetching } = useFindContactFormsQuery(
    selectedBrandId,
    { skip: !selectedBrandId }
  )

  const { data: contactFormDetails } = useFindContactFormDetailsQuery(
    { id: selectedContactFormId },
    { skip: !selectedContactFormId }
  )

  const resetSelectedContactFormId = () => setSelectedContactFormId("")

  return (
    <div>
      <BrandAutocomplete
        brands={brands}
        selected={selectedBrandId}
        onChange={setSelectedBrandId}
      />

      <div className="pt-6">
        {selectedBrandId ? (
          <ContactFormTable
            data={contactForms ?? []}
            isFetching={isFetching}
            onSelect={setSelectedContactFormId}
          />
        ) : null}
      </div>

      <ManageContactForm
        open={!!(selectedContactFormId && contactFormDetails)}
        data={contactFormDetails}
        onClose={resetSelectedContactFormId}
      />
    </div>
  )
}

export default ConfigContactForm
