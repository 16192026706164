import {
  CreateSubscriptionPayload,
  FormattedRateflowCard,
  RateflowCard,
  SubscriptionDetails,
  Lead,
  LoanPurpose,
  PropertyType,
  ResidencyType
} from "../../types"
import { formatNumber } from "./numeric"

export const convertSubscriptionDetailsToPayload = (
  data?: SubscriptionDetails,
  loid?: string,
  lead?: Lead,
  isCreateMode?: boolean
) => {
  const main = data || lead
  const details = data?.quote_info || lead?.details
  return {
    active: isCreateMode ? "1" : data?.active ?? "0",
    deleted: data?.deleted ?? "0",
    email: main?.email || "",
    first_name: main?.first_name ?? "",
    last_name: main?.last_name ?? "",
    phone: main?.phone ?? "",
    loid: data?.loid ?? loid ?? "",
    loan_type: isCreateMode ? "conventional" : details?.loan_type ?? "",
    arm_term: details?.arm_term?.toString() ?? "",
    loan_term: isCreateMode ? "30" : details?.loan_term?.toString() ?? "",
    loan_purpose: isCreateMode ? "purchase" : details?.loan_purpose ?? "",
    loan_amount: details?.loan_amount?.toString() ?? "",
    credit_score: isCreateMode ? 751 : details?.credit_score,
    property_type: isCreateMode
      ? "single_family_home"
      : details?.property_type ?? "",
    residency_type: isCreateMode
      ? "primary_home"
      : details?.residency_type ?? "",
    list_price: details?.list_price?.toString() ?? "",
    cash_out: details?.cash_out?.toString() ?? "",
    city: details?.location.city ?? "",
    county: details?.location.county ?? "",
    state: details?.location.state ?? "",
    street_address: details?.location.street_address ?? "",
    zipcode: details?.location.zipcode ?? "",
    taxes: details?.admin_fields?.taxes ?? 0,
    insurance: details?.admin_fields?.insurance ?? 0,
    seller_credit: details?.admin_fields?.seller_credit ?? 0,
    waive_escrows: data?.waive_escrows ?? false,
    send_to_crm: data?.send_to_crm ?? false,
    first_time_homebuyer: data?.quote_info?.first_time_homebuyer ?? false,
    lead_id: data?.lead_id ?? lead?.id
  }
}

export const convertPayloadToSubscriptionDetails = (
  data: CreateSubscriptionPayload
): Partial<SubscriptionDetails> => ({
  active: data.active,
  deleted: data.deleted,
  created: Math.floor(new Date().getTime() / 1000).toString(),
  email: data.email,
  first_name: data.first_name,
  last_name: data.last_name,
  loid: data.loid,
  phone: data.phone,
  lead_id: data.lead_id,
  send_to_crm: data.send_to_crm,
  waive_escrows: data.waive_escrows,
  quote_info: {
    admin_fields: {
      taxes: data.taxes,
      insurance: data.insurance,
      seller_credit: data.seller_credit
    },
    first_time_homebuyer: data.first_time_homebuyer,
    credit_score: data.credit_score,
    list_price: parseFloat(data.list_price),
    loan_amount: parseFloat(data.loan_amount),
    loan_purpose: data.loan_purpose as LoanPurpose,
    loan_term: data.loan_term ? parseInt(data.loan_term) : undefined,
    loan_type: data.loan_type,
    arm_term: data.arm_term ? parseInt(data.arm_term) : undefined,
    cash_out: parseFloat(data.cash_out ?? "0"),
    location: {
      city: data.city,
      county: data.county,
      state: data.state,
      street_address: data.street_address,
      zipcode: data.zipcode
    },
    property_type: data.property_type as PropertyType,
    residency_type: data.residency_type as ResidencyType
  }
})

export const formatRateflowCard = (
  data: RateflowCard
): FormattedRateflowCard => ({
  ...data,
  rate: formatNumber(data.rate, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 3
  })
})
