import { RateTableConfig } from "../../../../../../types"

type Props = {
  info: RateTableConfig
  onSelect: (val: string) => void
}

const NameCell = ({ info, onSelect }: Props) => {
  const { name, id } = info

  return (
    <td
      className="whitespace-nowrap py-4 pl-2 pr-3 cursor-pointer"
      onClick={() => onSelect(id)}
    >
      <div className="flex items-center">
        <div className="font-bold mr-2">{name}</div>
      </div>
      {/* <div className="text-sm text-neutral-400 mt-1"></div> */}
    </td>
  )
}

export default NameCell
