import { FC } from "react"
import { Lead } from "../../../../../../types"
import Accordion from "../../../../accordion"
import SectionCell from "../../../../section/cell"
import LoanScenario from "./accordion-sections/loan-scenario"
import PropertyLocation from "./accordion-sections/property-location"
import { formatPhoneNumber, formatDateTimeFromISO } from "../../../../../utils"
import Misc from "./accordion-sections/misc"
import Message from "./accordion-sections/message"

interface Props {
  data: Lead
}

const LeadSummary: FC<Props> = ({ data }) => (
  <div className="max-w-3xl">
    <div className="grid grid-cols-2 gap-4">
      <SectionCell label="First name" value={data.first_name} />
      <SectionCell label="Last name" value={data.last_name} />
      <SectionCell label="Email address" value={data.email} />
      <SectionCell
        label="Phone number"
        value={formatPhoneNumber(data.phone ?? "")}
      />
      <SectionCell label="Created by" value={data.owner} />
      <SectionCell
        label="Created on"
        value={formatDateTimeFromISO(data.date_time)}
      />
    </div>

    <div className="mt-12">
      <div className="my-4">
        <Accordion title="Property location" openByDefault>
          <PropertyLocation data={data} />
        </Accordion>
      </div>
      <div className="my-4">
        <Accordion title="Loan scenario" openByDefault>
          <LoanScenario data={data} />
        </Accordion>
      </div>
      <div className="my-4">
        <Accordion title="Misc." openByDefault>
          <Misc data={data} />
        </Accordion>
      </div>
      <div className="my-4">
        <Accordion title="Message" openByDefault>
          <Message data={data} />
        </Accordion>
      </div>
    </div>
  </div>
)

export default LeadSummary
