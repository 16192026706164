import { FC, Fragment } from "react"
import { Header, flexRender } from "@tanstack/react-table"
import { ArrowDown, ArrowUp } from "lucide-react"

interface ThInfoCellProps<TData, TValue> {
  hasColumnSort: boolean
  header: Header<TData, TValue>
}

const ThInfoCell: FC<ThInfoCellProps<any, any>> = ({
  hasColumnSort,
  header
}) => {
  return (
    <Fragment>
      {hasColumnSort && (
        <div
          className="flex items-center cursor-pointer select-none"
          onClick={header.column.getToggleSortingHandler()}
        >
          <div>
            {flexRender(header.column.columnDef.header, header.getContext())}
          </div>
          <div>
            {{
              asc: (
                <ArrowDown
                  size={20}
                  className="text-black"
                  aria-hidden="true"
                />
              ),
              desc: (
                <ArrowUp size={20} className="text-black" aria-hidden="true" />
              )
            }[header.column.getIsSorted() as string] ?? null}
          </div>
        </div>
      )}
      {!hasColumnSort &&
        flexRender(header.column.columnDef.header, header.getContext())}
    </Fragment>
  )
}

export default ThInfoCell
