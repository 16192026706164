import { Fragment, useEffect, useMemo, useState } from "react"
import { DateTime } from "luxon"

import {
  CustomInput,
  DatePicker,
  EmailStats,
  ExitPopupConversionFunnel,
  LeadByPropertyAndResidency,
  LeadByStateAndCity,
  LeadConversionFunnel,
  LeadConversionProgress,
  LeadGenerationTrend,
  RateTableUsageMetrics,
  SubscriptionCount,
  TopLeadBreakdown,
  UTMParameters,
  withLazyLoad
} from "../../common/ui"
import { useUserRoles } from "../../common/hooks"
import { RequireRole } from "../../common/validation"
import { ALLOWED_ROLES_BY_ROUTE } from "../../common/constants"

const LazyLoadedEmailStats = withLazyLoad(EmailStats)
const LazyLoadedExitPopupConversionFunnel = withLazyLoad(
  ExitPopupConversionFunnel
)
const LazyLoadedLeadByPropertyAndResidency = withLazyLoad(
  LeadByPropertyAndResidency
)
const LazyLoadedLeadByStateAndCity = withLazyLoad(LeadByStateAndCity)
const LazyLoadedLeadConversionFunnel = withLazyLoad(LeadConversionFunnel)
const LazyLoadedLeadConversionProgress = withLazyLoad(LeadConversionProgress)
const LazyLoadedLeadGenerationTrend = withLazyLoad(LeadGenerationTrend)
const LazyLoadedRateTableUsageMetrics = withLazyLoad(RateTableUsageMetrics)
const LazyLoadedSubscriptionCount = withLazyLoad(SubscriptionCount)
const LazyLoadedTopLeadBreakdown = withLazyLoad(TopLeadBreakdown)
const LazyLoadedUTMParameters = withLazyLoad(UTMParameters)

const Stats = () => {
  const { user, isBankAdmin, isLoanOfficer, isSuperAdmin } = useUserRoles()
  const [range, setRange] = useState<number | null>(null)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const endDate = new Date()
  const dateFormat = "dd LLL yyyy"

  const orgId = useMemo(() => {
    return !isSuperAdmin && isBankAdmin ? user?.org?.id : undefined
  }, [isBankAdmin, isSuperAdmin, user?.org?.id])
  const loid = useMemo(() => {
    return !isSuperAdmin && !isBankAdmin && isLoanOfficer ? user?.id : undefined
  }, [isBankAdmin, isLoanOfficer, isSuperAdmin, user?.id])

  const handleDateChange = (start: Date | null) => {
    setStartDate(start)
  }

  useEffect(() => {
    if (startDate) {
      setRange(
        Math.ceil(
          (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)
        )
      )
    } else {
      const thirtyDaysAgo = new Date(endDate)
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)
      setStartDate(thirtyDaysAgo)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate])

  return (
    <RequireRole allowedRoles={ALLOWED_ROLES_BY_ROUTE.stats}>
      <Fragment>
        <div className="bg-white flex flex-col md:flex-row items-start md:items-center justify-between px-6 py-4">
          <div className="text-2xl font-medium pb-3 md:pb-0">Stats</div>
          <div className="flex items-center">
            <div className="text-sm font-medium">Date range</div>
            <div className="ps-3">
              <DatePicker
                selectsStart
                selected={startDate}
                startDate={startDate}
                maxDate={new Date()}
                onChange={handleDateChange}
                dateFormat={dateFormat}
                customInput={
                  <CustomInput
                    extraValue={
                      startDate
                        ? DateTime.fromJSDate(endDate).toFormat(dateFormat)
                        : undefined
                    }
                  />
                }
              />
            </div>
          </div>
        </div>
        <div id="stats-container" className="grid gap-6 grid-cols-12 p-6 pb-20">
          {range && (
            <Fragment>
              <div className="col-span-12 lg:col-span-6 flex min-h-[450px]">
                <LazyLoadedLeadGenerationTrend
                  key={`lead-generation-trend-${range}`}
                  title="Lead Generation Trend"
                  range={range}
                  orgId={orgId}
                  loid={loid}
                />
              </div>
              <div className="col-span-12 lg:col-span-6 flex min-h-[450px]">
                <LazyLoadedTopLeadBreakdown
                  key={`top-lead-breakdown-${range}`}
                  title="Top Lead Breakdown"
                  range={range}
                  orgId={orgId}
                  loid={loid}
                />
              </div>
              <div className="col-span-12 flex">
                <LazyLoadedUTMParameters
                  key={`utm-parameters-${range}`}
                  title="UTM Parameters"
                  range={range}
                  orgId={orgId}
                  loid={loid}
                />
              </div>
              <div className="col-span-12 flex min-h-[450px]">
                <LazyLoadedLeadByStateAndCity
                  key={`lead-by-state-and-city-${range}`}
                  title="Leads by City and State"
                  range={range}
                  orgId={orgId}
                  loid={loid}
                />
              </div>
              <div className="col-span-12 flex min-h-[450px]">
                <LazyLoadedLeadByPropertyAndResidency
                  key={`lead-by-property-and-residency-${range}`}
                  title="Lead Stats"
                  range={range}
                  orgId={orgId}
                  loid={loid}
                />
              </div>
              <div className="col-span-12 lg:col-span-6 flex min-h-[450px]">
                <LazyLoadedLeadConversionFunnel
                  key={`lead-conversion-funnel-${range}`}
                  title="Lead Conversion Funnel"
                  range={range}
                  orgId={orgId}
                  loid={loid}
                />
              </div>
              <div className="col-span-12 lg:col-span-6 flex min-h-[450px]">
                <LazyLoadedLeadConversionProgress
                  key={`lead-conversion-progress-${range}`}
                  title="Lead Conversion Progress"
                  range={range}
                  orgId={orgId}
                  loid={loid}
                />
              </div>
              <div className="col-span-12 flex min-h-[450px]">
                <LazyLoadedRateTableUsageMetrics
                  key={`rate-table-usage-metrics-${range}`}
                  title="Rate Table Usage Metrics"
                  range={range}
                  orgId={orgId}
                  loid={loid}
                />
              </div>
              <div className="col-span-12 lg:col-span-6 flex min-h-[400px]">
                <LazyLoadedSubscriptionCount
                  key={`subscription-count-${range}`}
                  title="Subscription Count"
                  range={range}
                  orgId={orgId}
                  loid={loid}
                />
              </div>
              <div className="col-span-12 lg:col-span-6 flex min-h-[400px]">
                <LazyLoadedExitPopupConversionFunnel
                  key={`exit-popup-conversion-funnel-${range}`}
                  title="Exit Popup Conversion Funnel"
                  range={range}
                  orgId={orgId}
                  loid={loid}
                />
              </div>
              <div className="col-span-12 flex min-h-[510px]">
                <LazyLoadedEmailStats
                  key={`email-stats-${range}`}
                  title="Email Stats"
                  range={range}
                  orgId={orgId}
                  loid={loid}
                />
              </div>
            </Fragment>
          )}
        </div>
      </Fragment>
    </RequireRole>
  )
}

export default Stats
