import { FC } from "react"

import { TableFooterRow } from "../../../../types"

interface TableFooterProps {
  rows: TableFooterRow[]
  containerClassName?: string
}

const TableFooter: FC<TableFooterProps> = ({
  rows,
  containerClassName = "px-6"
}) => (
  <div className={`w-full overflow-auto ${containerClassName}`}>
    <table className="w-full max-w-full md:table-fixed">
      {rows.length > 0 && (
        <tbody className="divide-y divide-gray-200">
          {rows.map((row, index) => (
            <tr key={`footer-row-${index}`}>
              {row.columns.map((column, index) => (
                <td
                  key={`footer-col-${index}`}
                  className="px-3 font-extrabold whitespace-nowrap"
                  colSpan={column.colSpan || 1}
                >
                  {column.value}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      )}
    </table>
  </div>
)

export default TableFooter
