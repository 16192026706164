import { FC } from "react"
import { ChevronDown, Eye } from "lucide-react"
import { Lead, SubscriptionDetails } from "../../../../../types"
import BreadCrumbs from "../../../breadcrumbs"
import Dropdown from "../../../dropdown"
import ProfileLogo from "../../../profile-logo"
import BBSwitch from "../../../form/Switch"
import { formatDateTimeFromTimestamp } from "../../../../utils"

interface Props {
  data: Lead
  bankName?: string
  subscription?: SubscriptionDetails
  onCreateEngagement: (data: Lead) => void
  onEnrollToRateAlerts: (subscription?: SubscriptionDetails) => void
}

const LeadDetailsHeader: FC<Props> = ({
  data,
  subscription,
  bankName,
  onCreateEngagement,
  onEnrollToRateAlerts
}) => {
  return (
    <>
      <div>
        <BreadCrumbs
          pages={[
            {
              name: "Website leads",
              href: "/engagements?tab=leads",
              current: false
            },
            {
              name: data.email,
              href: "#",
              current: true
            }
          ]}
        />
      </div>
      <div className="py-6 flex items-center justify-between">
        <div className="flex items-center">
          <ProfileLogo
            label={`${data.first_name.charAt(0)}${data.last_name.charAt(0)}`}
          />
          <div className="px-4">
            <div className="font-bold text-xl">{data.email}</div>
            <div className="text-base pt-1">
              {data.first_name} {data.last_name}
              <span className="text-gray-700">&nbsp;&bull;&nbsp;</span>
              {bankName ?? "Unnamed"}
            </div>
          </div>
        </div>
        <div className="flex items-center">
          <div className="text-right px-6">
            <div>
              {formatDateTimeFromTimestamp(
                data.timestamp,
                "LLL dd 'at' hh:mm a"
              )}
            </div>
            <div>by {data.owner}</div>
          </div>
          <Dropdown
            menuBtn={
              <div className="inline-flex justify-center items-center py-3 px-4 border text-sm font-medium rounded-md focus:outline-none">
                Actions <ChevronDown className="h-5 w-5 ml-1" />
              </div>
            }
            items={[
              {
                name: "Push to engagements",
                icon: Eye,
                onClick: () => onCreateEngagement(data)
              },
              {
                name: (
                  <div className="flex items-center justify-between">
                    <span className="mr-2">
                      {subscription?.active === "1" ? "Unenroll" : "Enroll"} to
                      rate alerts
                    </span>
                    <BBSwitch
                      label=""
                      enabled={subscription?.active === "1"}
                      setEnabled={() => onEnrollToRateAlerts(subscription)}
                    />
                  </div>
                ),
                hidden: !subscription
              }
            ]}
          />
        </div>
      </div>
    </>
  )
}

export default LeadDetailsHeader
