import { default as Input } from "./input"
import { default as PhoneInput } from "./phone-input"
import { default as InputColorPicker } from "./input-color-picker"
import { default as ArrayInput } from "./array-input"
import { default as Select } from "./select"
import { default as InlineSelect } from "./inline-select"
import { default as Wrapper } from "./wrapper"
import { default as Checkbox } from "./checkbox"
import { default as DebouncedInput } from "./debouncedInput"
import { default as SearchInput } from "./searchInput"

const Form = {
  PhoneInput,
  Input,
  InputColorPicker,
  ArrayInput,
  Select,
  InlineSelect,
  Wrapper,
  Checkbox,
  DebouncedInput,
  SearchInput
}

export default Form
