import { useMemo } from "react"
import { Brand, SubscriptionConfig } from "../../../../../../types"

interface Props {
  info: SubscriptionConfig
  brands: Brand[]
  onSelect: (val: string) => void
}

const NameCell = ({ info, brands, onSelect }: Props) => {
  const { id, brand_id } = info
  const brand = useMemo(
    () => brands.find(b => b.id === brand_id),
    [brand_id, brands]
  )

  return (
    <td
      className="whitespace-nowrap py-4 pl-2 pr-3 cursor-pointer"
      onClick={() => onSelect(id)}
    >
      <div className="flex items-center">
        <div className="font-bold mr-2">
          {brand?.name ?? "Unknown brand"} Subscription Config
        </div>
      </div>
    </td>
  )
}

export default NameCell
