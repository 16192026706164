import { FC, useState } from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { Loader2 } from "lucide-react"
import { useFindChartDataByProfileIdQuery } from "../../../../redux/queries"
import ButtonGroup from "../../button-group"
import { formatDateTimeFromMillis } from "../../../utils"

interface Props {
  profileId?: string
}

const VALID_DATASETS = [
  { name: "Rate", color: "#FF9356" },
  { name: "Treasury", color: "#786FF6" }
]

const RatelogChart: FC<Props> = ({ profileId }) => {
  const [range, setRange] = useState<number>(365)

  const FILTER_BUTTONS = [
    {
      key: 365,
      label: "1 Year",
      disabled: false,
      onClick: () => setRange(365)
    },
    {
      key: 90,
      label: "90 Days",
      disabled: false,
      onClick: () => setRange(90)
    },
    {
      key: 30,
      label: "30 Days",
      disabled: false,
      onClick: () => setRange(30)
    }
  ]

  const { data, isFetching } = useFindChartDataByProfileIdQuery(
    { profileId: profileId ?? "", range },
    {
      skip: !profileId,
      refetchOnMountOrArgChange: true
    }
  )

  const [names, ...series] = data?.data ?? []
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dateName, ...otherNames] = names ?? []
  const validIndexes = VALID_DATASETS.map(ds =>
    (otherNames as string[]).findIndex((n: string) => n === ds.name)
  ).filter(index => index > -1)

  const chartData = validIndexes.map((index, idx) => ({
    name: otherNames[index],
    color: VALID_DATASETS.find(ds => ds.name === otherNames[index])?.color,
    data: series?.map((s: (number | null)[]) => [s[0] || 0, s[index + 1]])
  }))

  const options = {
    chart: {
      type: "spline"
    },
    plotOptions: {
      spline: {
        lineWidth: 2,
        marker: {
          enabled: false
        }
      }
    },
    title: {
      text: ""
    },
    xAxis: {
      type: "datetime",
      labels: {
        formatter: function (): string {
          return formatDateTimeFromMillis((this as any).value, "MMM dd")
        }
      }
    },
    yAxis: {
      title: {
        text: "Rate %"
      }
    },
    series: chartData,
    legend: {
      enabled: true
    },
    credits: {
      enabled: false
    }
  }

  if (!profileId) return null

  if (isFetching) {
    return (
      <div className="w-full py-20 flex items-center justify-center">
        <Loader2
          className="animate-spin h-16 w-16 text-primary-alt-400"
          aria-hidden="true"
        />
      </div>
    )
  }

  return (
    <div className="py-6">
      <div className="pb-6 flex items-center justify-between">
        <div className="font-medium">{data?.profile_title}</div>
        <ButtonGroup buttons={FILTER_BUTTONS} active={range} />
      </div>
      <div className="">
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    </div>
  )
}

export default RatelogChart
