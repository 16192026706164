import { GripVertical } from "lucide-react"
import { formatAsTwoDigits } from "../../../../utils"
import { PricingProfile } from "../../../../../types"
import { Row } from "@tanstack/react-table"

type Props = {
  value: Row<PricingProfile>
}

const LabelCell = ({ value }: Props) => {
  return (
    <td className="whitespace-nowrap py-4 pl-2 pr-3 cursor-pointer">
      <div className="flex items-center">
        <div className="flex items-center">
          <GripVertical className="text-neutral-400" />
          <div className="text-2xl font-extrabold ml-2">
            {formatAsTwoDigits(value.index + 1)}
          </div>
        </div>
        <div className="pl-5 font-bold">{value.original.title}</div>
      </div>
    </td>
  )
}

export default LabelCell
