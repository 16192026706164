import { FC, useMemo } from "react"
import BBSwitch from "../../../../form/Switch"
import { Brand, SubscriptionConfigDetails } from "../../../../../../types"
import ReactJSONEditor from "../../../../react-json-editor"
import BrandAutocomplete from "../../../../brand-autocomplete"

interface Props {
  values: SubscriptionConfigDetails
  brands: Brand[]
  setFormField: (name: string, value: any) => void
}

const ManageSubscriptionConfigFormContent: FC<Props> = ({
  values,
  brands,
  setFormField
}) => {
  const selectedBrandId = useMemo(
    () => brands.find(b => b.id === values.brand_id)?.id,
    [brands, values.brand_id]
  )

  return (
    <div className="py-6 flex flex-col h-full">
      <div className="grid grid-cols-2 gap-x-12">
        <div className="pt-8">
          <BBSwitch
            label="Enabled"
            enabled={values.enabled === "1"}
            setEnabled={value => setFormField("enabled", value ? "1" : "0")}
          />
        </div>
        <div className="pb-6">
          <BrandAutocomplete
            brands={brands}
            selected={selectedBrandId}
            onChange={val => setFormField("brand_id", val)}
            direction="vertical"
          />
        </div>
      </div>
      <div className="pt-6 flex-1">
        <ReactJSONEditor
          name="config"
          json={values.config}
          onChangeJSON={val => setFormField("config", val)}
        />
      </div>
    </div>
  )
}

export default ManageSubscriptionConfigFormContent
