import { memo } from "react"

import Card from "./card"
import Activity from "./activity"
import Performance from "./performance"

const Dashboard = () => {
  return (
    <div className="p-6 bg-primary-100 ">
      <div className="flex flex-wrap md:flex-nowrap gap-6 justify-center">
        {[...Array(3)].map((_, index) => (
          <Card key={index} />
        ))}
      </div>
      <div className="flex flex-wrap md:flex-nowrap gap-6 bg-primary-100 justify-center mt-6">
        {[...Array(2)].map((_, index) => (
          <Activity key={index} />
        ))}
      </div>
      <div className="flex flex-wrap md:flex-nowrap gap-6 bg-primary-100 justify-center mt-6">
        {[...Array(2)].map((_, index) => (
          <Performance key={index} />
        ))}
      </div>
    </div>
  )
}

export default memo(Dashboard)
