import { FC } from "react"
import { Brand, LoSearchParams } from "../../../../../types"
import Input from "../../../form/input"
import ImageUploader from "../../../image-uploader"
import LoanOfficerAutocomplete from "../../../loan-officer-autocomplete"

interface Props {
  values: Brand
  loSearchParams: LoSearchParams
  setFormField: (name: string, value: any) => void
}

const BrandSettings: FC<Props> = ({ values, loSearchParams, setFormField }) => {
  const handleChangeLogo = (url: string) => setFormField("logo", url)

  const handleChangeDefaultLo = (id: string) => setFormField("default_loid", id)

  return (
    <>
      <ImageUploader
        isFullRounded={false}
        imageUrl={values.logo}
        onChangeImageUrl={handleChangeLogo}
        buttonLabel="Upload Brand logo"
      />
      <div className="pt-8">
        <Input
          label="Name of brand"
          name="name"
          placeholder="Ex. Fivestar home loans"
        />
      </div>
      <div className="pt-8">
        <Input
          label="Website of brand"
          name="website"
          beforeLabel="https://"
          placeholder="Ex. www.google.com"
        />
      </div>

      <div className="grid gap-y-6 gap-x-4 grid-cols-12 pt-8">
        <div className="col-span-6">
          <LoanOfficerAutocomplete
            searchParams={loSearchParams}
            label="Default LO:"
            selected={values.default_loid}
            onChange={handleChangeDefaultLo}
            direction="vertical"
          />
        </div>
      </div>
    </>
  )
}

export default BrandSettings
