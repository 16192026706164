import { useState } from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import ButtonGroup from "../button-group"
import { columnSeries, getColumnChartOptions } from "./options"
import { useFetchDashboardPerformanceStatsQuery } from "../../../redux/queries"
import If from "../if"
import PerformanceSkeleton from "../skeletons/dashboard/performance"
import StatItem from "./stat-item"
import { DateTime } from "luxon"

interface Props {
  title: string
  type: string
  orgId?: string
}

const ChartWithStats: React.FC<Props> = ({ title, type, orgId = "" }) => {
  const [days, setDays] = useState(90)
  const { data, isFetching } = useFetchDashboardPerformanceStatsQuery({
    orgId,
    range: days,
    type
  })

  const FILTER_BUTTONS = [
    {
      key: 90,
      label: "90 Days",
      disabled: false,
      onClick: () => setDays(90)
    },
    {
      key: 30,
      label: "30 Days",
      disabled: false,
      onClick: () => setDays(30)
    },
    {
      key: 7,
      label: "7 Days",
      disabled: false,
      onClick: () => setDays(7)
    }
  ]

  const chatLabels = data?.chartData
    ?.map(item => ({
      date: DateTime.fromFormat(item.period_start, "yyyy-LL-dd"),
      y: Math.floor(
        "conversion_rate" in item ? item.conversion_rate : item.opened
      ),
      color: "#786ff6"
    }))
    .sort((a, b) => (a.date > b.date ? 1 : -1))
    .map(item => ({
      ...item,
      name: item.date.toFormat("LL/dd/yyyy")
    }))

  const chartOptions = {
    ...getColumnChartOptions(data?.type ?? ""),
    series: [{ ...columnSeries, data: chatLabels }]
  }

  return (
    <If
      condition={isFetching}
      then={<PerformanceSkeleton />}
      else={
        <div className="bg-white flex-1 p-6 rounded-md overflow-hidden">
          <div className="lg:flex flex-row items-center justify-between">
            <div className="font-bold text-lg">{title}</div>
            <div className="flex-1 pt-4 lg:pt-0">
              <ButtonGroup
                className="lg:flex justify-end lg:ml-4"
                buttons={FILTER_BUTTONS}
                active={days}
              />
            </div>
          </div>
          <div className="py-6">
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          </div>
          <div className="flex items-center gap-6 px-0 md:px-8">
            {data?.stats?.map(({ key, label, value }) => (
              <StatItem key={key} label={label} value={value} />
            ))}
          </div>
        </div>
      }
    />
  )
}

export default ChartWithStats
