import { RateFlowDetails } from "../../../../../../types"

type Props = {
  info: RateFlowDetails
}

const IsDefaultCell = ({ info }: Props) => {
  const { default: isDefault } = info
  return (
    <td className="whitespace-nowrap py-4 pl-2 pr-3 cursor-pointer text-right md:text-left">
      <div className="italic">{isDefault ? "Default" : ""}</div>
    </td>
  )
}

export default IsDefaultCell
