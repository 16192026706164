import { FC } from "react"
import { ZillowReview } from "../../../types"
import ImgZillowLogo from "../../../assets/icons/zillow_logo.svg"
import ImgStar from "../../../assets/icons/star.svg"
import If from "../if"

interface Props {
  reviews?: ZillowReview[]
  zillowBrandName?: string
}

const ZillowReviewsList: FC<Props> = ({ reviews, zillowBrandName }) => {
  return (
    <div className="pb-6">
      <div className="text-lg font-medium">Zillow reviews</div>
      {zillowBrandName && (
        <div className="py-4 flex items-center">
          <div className="pr-3">
            <img src={ImgZillowLogo} alt="Zillow Logo" />
          </div>
          <div>
            <div className="font-medium">{zillowBrandName}</div>
          </div>
        </div>
      )}

      <If
        condition={!!reviews?.length}
        then={reviews?.map(review => {
          const zillowReview = review as ZillowReview
          return (
            <div
              key={`${zillowReview.title}-${zillowReview.updated}`}
              className="py-4"
            >
              <div className="flex items-center leading-5">
                <div className="w-5 h-5">
                  <img className="h-full" src={ImgStar} alt="Star" />
                </div>
                <div className="font-medium pl-2">{zillowReview.rating}</div>
                <div className="text-neutral-300">
                  <span className="pl-3 pr-2">.</span>
                  {zillowReview.reviewerName.displayName}
                </div>
              </div>
              <div className="pt-4">{zillowReview.content}</div>
            </div>
          )
        })}
        else={<div className="py-4">-</div>}
      />
    </div>
  )
}

export default ZillowReviewsList
