import { ReactElement } from "react"
import { AlertType } from "../../../types"
import Wrapper from "./wrapper"

type Props = {
  message: string | ReactElement
  className: string
  title?: string
  noIcon?: boolean
}

const Danger = (props: Props) => {
  return <Wrapper {...props} theme="danger" />
}

const Info = (props: Props) => {
  return <Wrapper {...props} theme="info" />
}

const Warning = (props: Props) => {
  return <Wrapper {...props} theme="warning" />
}

const Success = (props: Props) => {
  return <Wrapper {...props} theme="success" />
}

const General = (props: Props & { theme: AlertType }) => {
  return <Wrapper {...props} />
}

const Alert = { General, Danger, Info, Warning, Success }

export default Alert
