import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { Loader2 } from "lucide-react"
import { useAppDispatch, useAppSelector } from "../../common/hooks"
import { PrimaryBtn, Form, Alert, SocialLogins } from "../../common/ui"
import { login } from "../../redux/slices/auth"
import { clearMessage, selectMessage } from "../../redux/slices/notifications"

interface FormValues {
  username: string
  password: string
}

const validationSchema = Yup.object().shape({
  username: Yup.string().required("This field is required!"),
  password: Yup.string().required("This field is required!")
})

const Login = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()

  const message = useAppSelector(selectMessage)
  const { isLoggedIn }: any = useAppSelector(state => {
    return state?.auth ?? false
  })
  const [loading, setLoading] = useState(false)

  const from = (location.state as any)?.from?.pathname || "/"

  const initialValues = {
    username: "",
    password: ""
  }

  const handleLogin = ({ username, password }: FormValues) => {
    setLoading(true)
    dispatch(login({ username, password }))
      .unwrap()
      .then(() => {
        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.
        navigate(from, { replace: true })
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (message.type === "danger") {
      dispatch(clearMessage())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/", { replace: true })
    }
  }, [isLoggedIn, navigate])

  return (
    <>
      <p className="py-8 text-center font-medium text-2xl font-serif">
        Sign in to continue
      </p>
      {message.text && (
        <Alert.General
          className="mb-6"
          message={message.text}
          theme={message.type}
        />
      )}
      <Form.Wrapper
        className="space-y-6"
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleLogin}
      >
        <Form.Input
          label="Username or company email"
          name="username"
          autoComplete="username"
        />
        <Form.Input
          label="Password"
          name="password"
          type="password"
          autoComplete="current-password"
        />

        <div className="flex items-center justify-end">
          <div className="text-sm">
            <Link
              to="/user/password"
              className="font-medium text-primary-400 hover:text-primary-500"
            >
              Forgot your password?
            </Link>
          </div>
        </div>

        <div>
          <PrimaryBtn
            className={`${
              loading ? "pointer-events-none" : ""
            } w-full text-base`}
            type="submit"
            disabled={loading}
            onClick={() => {}}
          >
            {loading ? (
              <Loader2
                className="animate-spin h-5 w-5 text-primary-alt-400"
                aria-hidden="true"
              />
            ) : (
              <span>Sign in</span>
            )}
          </PrimaryBtn>
        </div>
      </Form.Wrapper>

      <div className="mt-6">
        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-16 bg-white">or</span>
          </div>
        </div>

        <div className="mt-6">
          <SocialLogins theme="full" />
        </div>
      </div>
    </>
  )
}

export default Login
