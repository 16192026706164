import { RateflowCard } from "../../../types"
import { formatCurrency } from "../../utils"
import If from "../if"
import ReactJSONEditor from "../react-json-editor"

type Props = {
  row?: RateflowCard
  loanAmount?: number
}

const CardQuoteDetails = ({ row, loanAmount }: Props) => (
  <If
    condition={!!row}
    then={
      <div className="flex flex-1 pt-4 flex-col relative">
        <div className="flex border-solid border-2 border-zinc-200">
          <div className="w-1/3 flex items-center justify-left">
            <div className="ml-12">
              <h3 className="text-base-dark font-medium text-3xl break-all text-center	">
                {row?.rate}%
              </h3>
              <div className="pt-4 text-base-dark text-xs text-center	">
                {row?.loanTerm + " YR " + row?.amortizationType}
              </div>
            </div>
          </div>
          <dl className="my-3 grid grid-cols-2 gap-4 w-1/3 text-xs">
            <dt className="text-sm text-neutral-400">Price</dt>
            <dd className="text-base-dark text-sm break-all">{row?.price}</dd>
            <dt className="text-sm text-neutral-400">Fee</dt>
            <dd>
              <span>
                {row?.fee.amount ? formatCurrency(row?.fee.amount) : "-"}
              </span>
            </dd>
            <dt className="text-sm text-neutral-400">Loan Amount</dt>
            <dd>
              <span>
                {loanAmount !== undefined ? formatCurrency(loanAmount) : "-"}
              </span>
            </dd>
            <dt className="text-sm text-neutral-400">P/I</dt>
            <dd>
              <span>
                {row?.principalAndInterest
                  ? formatCurrency(row?.principalAndInterest)
                  : "-"}
              </span>
            </dd>
          </dl>
          <dl className="my-3 grid grid-cols-2 gap-4 w-1/3 text-xs">
            <dt className="text-sm text-neutral-400">Type</dt>
            <dd>
              <span>{row?.loanType}</span>
            </dd>

            <dt className="text-sm text-neutral-400">Investor</dt>
            <dd>
              <span>{row?.investor}</span>
            </dd>
          </dl>
        </div>
        <div className="pt-6 flex-1">
          <ReactJSONEditor name="Card Details" json={row} mode="view" />
        </div>
      </div>
    }
  />
)

export default CardQuoteDetails
