type Props = {
  className?: string
  title?: string | React.ReactNode
  children?: React.ReactNode
}

const PageHeader = ({ className = "", title, children }: Props) => (
  <header className={`${className} flex items-center`}>
    <h1 className="mr-auto font-serif text-2xl">{title}</h1>
    {children}
  </header>
)

export default PageHeader
