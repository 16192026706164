import { FC, FocusEvent } from "react"
import { Disclosure } from "@headlessui/react"
import { ChevronDown } from "lucide-react"
import { ChangeEvent } from "react"
import { useIntegrationSettingsForm } from "../../../../hooks"
import Form from "../../../form"
import { Integration } from "../../../../../types"

interface Props {
  integration?: Integration
}

const CUSTOM_PARAMS = [
  { key: "utm_*", scope: "lead" },
  { key: "gclid", scope: "lead" }
]

const AdvanceOptions: FC<Props> = ({ integration }) => {
  const {
    app_settings,
    saveSetting,
    handleFormikBlur,
    handleFormikChange,
    saveSettingLocal
  } = useIntegrationSettingsForm(integration)

  const { settings } = app_settings ?? {}

  // Due to the lack of standardization, we need to have multiple onChange functions
  const onChange = (
    name: string,
    value: string | number | boolean | null | any[]
  ) => {
    saveSetting({ [name]: value })
  }

  const onChangeSettings = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    onChange(name, !settings?.[name as keyof typeof settings])
  }

  const onChangeUTM = (e: ChangeEvent<HTMLInputElement>) => {
    onChange("customParams", settings?.customParams ? false : CUSTOM_PARAMS)
  }

  const onBlurPOSTURL = (e: FocusEvent<HTMLInputElement>) => {
    onChange("postUrl", e.target.value)
    handleFormikBlur(e)
  }

  const onChangePOSTURL = (e: ChangeEvent<HTMLInputElement>) => {
    saveSettingLocal({ postUrl: e.target.value })
    handleFormikChange(e)
  }

  return (
    <div className="mt-4 pt-2 pb-4 border-t-zinc-200 border-t">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full justify-between py-2 text-left focus:outline-none text-neutral-400">
              <span>Show advanced options</span>
              <ChevronDown
                className={`${
                  open ? "rotate-180" : ""
                } h-5 w-5 transition-transform`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="pt-6 pb-2">
              <div className="flex items-center">
                <input
                  checked={settings?.disableCalcCards ?? false}
                  onChange={onChangeSettings}
                  id="disableCalcCards"
                  name="disableCalcCards"
                  type="checkbox"
                  className="h-6 w-6 rounded border-neural-400 text-primary-500 focus:ring-primary-300 cursor-pointer"
                />
                <label
                  htmlFor="disableCalcCards"
                  className="select-none ml-3 cursor-pointer"
                >
                  Disable Loan Calc Cards
                </label>
              </div>
              <div className="pt-6">
                <Form.Input
                  inputProps={{
                    onBlur: onBlurPOSTURL,
                    onChange: onChangePOSTURL,
                    value: settings?.postUrl ?? ""
                  }}
                  label="POST URL"
                  name="postUrl"
                  placeholder="Ex. https://google.com/"
                />
              </div>
              <div className="flex items-center py-2 pt-6">
                <input
                  checked={
                    (settings?.customParams &&
                      settings?.customParams?.length > 0) ??
                    false
                  }
                  onChange={onChangeUTM}
                  id="customParams"
                  name="customParams"
                  type="checkbox"
                  className="h-6 w-6 rounded border-neural-400 text-primary-500 focus:ring-primary-300 cursor-pointer"
                />
                <label
                  htmlFor="customParams"
                  className="select-none ml-3 cursor-pointer"
                >
                  Add UTM Tracking
                </label>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  )
}

export default AdvanceOptions
