import { LearnVideoType } from "../../../types"
import { VideoLinks } from "../../constants"
import BBModal from "../modal"

interface Props {
  show: boolean
  videoType: LearnVideoType
  toogleModal: (show: boolean) => void
}

const HowItWorksModal = ({ show, videoType, toogleModal }: Props) => {
  const { title, link } = VideoLinks[videoType]

  const onClose = () => {
    toogleModal(false)
  }

  if (!link) return null

  return (
    <BBModal show={show} onClose={onClose} title={title} size="2xl">
      <div className="max-w-full">
        <iframe
          title="Learn how it works"
          src={link}
          allowTransparency
          frameBorder={0}
          scrolling="no"
          className="wistia_embed max-w-full"
          name="wistia_embed"
          allowFullScreen
          width="638"
          height="400"
        ></iframe>
      </div>
    </BBModal>
  )
}

export default HowItWorksModal
