import { STORAGE_USER_KEY, STORATE_RECENT_SEARCH_KEY } from "../constants"
import { SearchResult } from "../../types"

const MAX_RECENT_SEARCHES = 10

export const getBBUserFromLocalStorage = () => {
  try {
    const data = localStorage.getItem(STORAGE_USER_KEY)
    return data ? JSON.parse(data) : null
  } catch (error) {
    return null
  }
}

export const getSessionIdFromLocalStorage = () => {
  try {
    const sessionId = JSON.parse(
      localStorage.getItem(STORAGE_USER_KEY) ?? "{}"
    )?.sessid
    return sessionId
  } catch (error) {
    return null
  }
}

export const setBBUserInLocalStorage = (data: any) =>
  localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(data))

export const removeBBUserFromLocalStorage = () =>
  localStorage.removeItem(STORAGE_USER_KEY)

export const getRecentSearches = (): SearchResult[] => {
  try {
    const data = JSON.parse(
      localStorage.getItem(STORATE_RECENT_SEARCH_KEY) ?? "[]"
    )
    return data
  } catch (error) {
    return []
  }
}

export const setRecentSearches = (data: SearchResult[]) =>
  localStorage.setItem(STORATE_RECENT_SEARCH_KEY, JSON.stringify(data))

export const addRecentSearch = (search: SearchResult) => {
  const list = [
    search,
    ...getRecentSearches().filter(
      item => !(item.id === search.id && item.type === search.type)
    )
  ]

  // Store recent 10 results
  setRecentSearches(list.slice(Math.max(list.length - MAX_RECENT_SEARCHES, 0)))
}
