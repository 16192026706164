import {
  DELETE_MASQUERADE_ENDPOINT,
  GET_CORE_UI_ENV,
  GET_MASQUERADE_ENDPOINT,
  GET_ME_ENDPOINT,
  LOGIN_ENDPOINT,
  RESET_PWD_EMAIL_ENDPOINT,
  RESET_PWD_ENDPOINT,
  UPDATE_EMAIL_ENDPOINT,
  UPDATE_PWD_ENDPOINT
} from "../common/constants"
import { api } from "../common/utils"
import { APIError, User, UserDetails, UserResponse } from "../types"

const auth = {
  login: async ({
    username,
    password
  }: {
    username: string
    password: string
  }): Promise<UserResponse> => {
    const { data } = await api.post(LOGIN_ENDPOINT, {
      username,
      password
    })

    return data
  },
  masquerade: async (loid: string): Promise<User> => {
    const { data } = await api.get(GET_MASQUERADE_ENDPOINT(loid))
    return data
  },
  deleteMasquerade: async (): Promise<User> => {
    const { data } = await api.delete(DELETE_MASQUERADE_ENDPOINT)
    return data
  },
  getMe: async (): Promise<UserDetails | APIError> => {
    const { data } = await api.get(GET_ME_ENDPOINT)
    return data
  },
  updateEmail: async (uid: string, email: string): Promise<any> => {
    const { data } = await api.post(UPDATE_EMAIL_ENDPOINT, {
      uid,
      email
    })
    return data
  },
  updatePassword: async (uid: string, password: string): Promise<any> => {
    const { data } = await api.post(UPDATE_PWD_ENDPOINT, {
      uid,
      password
    })
    return data
  },
  sendPasswordResetEmail: async ({
    email,
    bbSid
  }: {
    email: string
    bbSid: string
  }): Promise<any> => {
    const { data } = await api.post(
      `${RESET_PWD_EMAIL_ENDPOINT}?bb_sid=${bbSid}`,
      {
        email
      }
    )
    return data
  },
  resetPassword: async ({
    newPassword,
    bbSid,
    token
  }: {
    newPassword: string
    bbSid: string
    token: string
  }): Promise<any> => {
    const { data } = await api.post(`${RESET_PWD_ENDPOINT}?bb_sid=${bbSid}`, {
      new_password: newPassword,
      one_time_token: token
    })
    return data
  },
  getCoreUIEnv: async () => {
    try {
      const { data: envString } = await api.get(GET_CORE_UI_ENV)
      const start = envString.indexOf('{"session":')
      const jsonString = envString.substr(start)
      const resParsed = JSON.parse(jsonString)
      api.defaults.headers.common["Authorization"] = resParsed.session

      return resParsed
    } catch (error) {
      console.error(error)
    }
  }
}

export default auth
