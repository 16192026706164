import { createApi } from "@reduxjs/toolkit/dist/query/react"
import {
  FindWorkflowsResponse,
  DefaultLeadworkflowDetails,
  LeadworkflowDetails
} from "../../types"
import {
  LEAD_WORKFLOWS_ENDPOINT,
  LEAD_WORKFLOW_ACTIVE_ENDPOINT,
  LEAD_WORKFLOW_ENDPOINT,
  RTK_SLICE_API_LIST
} from "../../common/constants"
import bbApi from "../../api"
import { baseQuery, getTags } from "../api"
import { getQueryString } from "../../common/utils"

const reducerPath = "lead-workflow"

export const leadWorkflowApi = createApi({
  reducerPath,
  baseQuery,
  tagTypes: [reducerPath],
  endpoints: builder => ({
    findWorklows: builder.query({
      query: (brand_id: string) =>
        `${LEAD_WORKFLOWS_ENDPOINT}?brand_id=${brand_id}`,
      transformResponse: (responseData: FindWorkflowsResponse) =>
        responseData.workflows ?? [],
      providesTags: result => getTags(reducerPath, "id", result)
    }),
    fetchLeadworkflowDetails: builder.query({
      query: (params: { id: string }) =>
        `${LEAD_WORKFLOW_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: LeadworkflowDetails) => responseData,
      providesTags: (result, error, arg) => [{ type: reducerPath, id: arg.id }]
    }),
    checkLeadworkflowStatus: builder.query({
      query: (params: { id: string }) =>
        `${LEAD_WORKFLOW_ACTIVE_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: { status: string }) =>
        responseData.status
    }),
    updateOrCreateLeadworkflow: builder.mutation({
      queryFn: async (payload: Partial<LeadworkflowDetails>) => {
        // Remove "id" field if it's empty which means it's CREATE action
        if (!payload.id) {
          delete payload.id
        }
        const data = await bbApi.leadWorkflow.updateOrCreate(payload)
        return { data }
      },
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: arg.id },
        { type: reducerPath, id: RTK_SLICE_API_LIST }
      ]
    }),
    deleteLeadworkflow: builder.mutation({
      queryFn: async (id: string) => {
        const data = await bbApi.leadWorkflow.delete(id)
        return { data }
      },
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: RTK_SLICE_API_LIST }
      ]
    }),
    fetchDefaultLeadworkflowDetails: builder.query({
      query: () => `${LEAD_WORKFLOW_ENDPOINT}?id=`,
      transformResponse: (responseData: DefaultLeadworkflowDetails) => {
        return responseData ?? []
      }
    })
  })
})

export const {
  useFindWorklowsQuery,
  useFetchLeadworkflowDetailsQuery,
  useCheckLeadworkflowStatusQuery,
  useUpdateOrCreateLeadworkflowMutation,
  useDeleteLeadworkflowMutation,
  useFetchDefaultLeadworkflowDetailsQuery
} = leadWorkflowApi
