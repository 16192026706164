import { FC } from "react"
import { LOsTableWithImporter } from "../../loan-officers"

interface Props {
  orgId: string
}

const OrgDetailsLoanOfficers: FC<Props> = ({ orgId }) => {
  return (
    <div className="p-6 bg-white">
      <LOsTableWithImporter
        searchParams={{ org_id: orgId }}
        hasNewButton
        hasViewTypeToggle={false}
      />
    </div>
  )
}

export default OrgDetailsLoanOfficers
