import { FC } from "react"
import { Loader2 } from "lucide-react"
import { useFetchLeadHistoryQuery } from "../../../../../../redux/queries"
import { Lead } from "../../../../../../types"
import LeadHistoryItem from "./lead-history-item"

interface Props {
  data: Lead
}

const LeadActivityPage: FC<Props> = ({ data }) => {
  const { data: leadHistory, isFetching } = useFetchLeadHistoryQuery(data.id, {
    skip: !data.id
  })

  if (isFetching) {
    return (
      <div className="w-full py-20 flex items-center justify-center">
        <Loader2
          className="animate-spin h-16 w-16 text-primary-alt-400"
          aria-hidden="true"
        />
      </div>
    )
  }

  if (!leadHistory?.length) {
    return <div className="">No activities found.</div>
  }

  return (
    <div className="max-w-2xl">
      {leadHistory.map((item, idx) => (
        <LeadHistoryItem
          key={item.id}
          data={item}
          connectorVisible={idx < leadHistory.length - 1}
        />
      ))}
    </div>
  )
}

export default LeadActivityPage
