import { FC } from "react"
import { Brand } from "../../../../types"
import BrandsGrid from "../../brands/grid"

interface Props {
  orgId: string
  data: Brand[]
  isLoading: boolean
}

const OrgDetailsBrands: FC<Props> = ({ orgId, data, isLoading }) => {
  return (
    <div className="pb-6">
      <BrandsGrid
        data={data}
        isLoading={isLoading}
        hasCreateButton
        rootPage={`/organizations/${orgId}/brands`}
      />
    </div>
  )
}

export default OrgDetailsBrands
