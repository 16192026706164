type Props = {
  children: React.ReactNode
  className?: string
  type?: "button" | "submit" | "reset"
  disabled?: boolean
  href?: string
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  target?: string
  refProp?: React.Ref<HTMLButtonElement>
}

const Button = ({
  children,
  className = "",
  type = "button",
  disabled = false,
  onClick,
  href,
  target = "_blank",
  refProp
}: Props) =>
  !!href ? (
    <a
      href={href}
      className={`${className} ${
        disabled ? "text-gray-400 pointer-events-none cursor-not-allowed" : ""
      } transition-colors relative inline-flex justify-center items-center py-3 px-4 border text-sm font-medium rounded-md focus:outline-none`}
      target={target}
    >
      {children}
    </a>
  ) : (
    <button
      ref={refProp}
      disabled={disabled}
      type={type}
      className={`${className} ${
        disabled ? "text-gray-400" : ""
      } transition-colors relative inline-flex justify-center items-center py-3 px-4 border text-sm font-medium rounded-md focus:outline-none`}
      onClick={onClick}
    >
      {children}
    </button>
  )

export default Button
