import { FC } from "react"
import { Brand } from "../../../../../types"
import Checkbox from "../../../form/checkbox"

interface Props {
  values: Brand
  setFormField: (name: string, value: any) => void
}

const BrandCheckList: FC<Props> = ({ values, setFormField }) => {
  return (
    <>
      <div className="pt-8">
        <Checkbox
          name="disable_subscription_emails"
          label="Disable subscription emails"
          subLabel="By disabling users will not receive any subscription emails from your brand"
        />
      </div>
      <div className="pt-4">
        <Checkbox
          name="disable_receipt_emails"
          label="Disable receipt emails"
          subLabel="By disabling users will not receive any receipt emails from your brand"
        />
      </div>
      <div className="pt-4">
        <Checkbox
          name="disable_itemized_fees"
          label="Disable itemized fees"
          subLabel="By disabling user is not able to view itemized fees or create PDF closing cost worksheet"
        />
      </div>
      <div className="pt-4">
        <Checkbox
          name="non_bank"
          label="This is a non bank"
          subLabel="Select if you are not a bank"
        />
      </div>
    </>
  )
}

export default BrandCheckList
