import { useState, useEffect, FC } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Loader2 } from "lucide-react"
import * as Yup from "yup"
import { Alert, Form, PrimaryBtn } from "../../common/ui"
import { useAppDispatch, useAppSelector } from "../../common/hooks"
import { resetPassword } from "../../redux/slices/auth"
import {
  clearMessage,
  selectMessage,
  setMessage
} from "../../redux/slices/notifications"
import bbApi from "../../api"

interface FormValues {
  password: string
  confirm: string
}

const validationSchema = Yup.object().shape({
  password: Yup.string().required("This field is required!"),
  confirm: Yup.string().required("This field is required!")
})

const PasswordReset: FC<{}> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const message = useAppSelector(selectMessage)

  const { token = "" } = useParams()

  const [loading, setLoading] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  const initialValues = {
    password: "",
    confirm: ""
  }

  const handleSubmit = ({ password, confirm }: FormValues) => {
    if (password !== confirm) {
      dispatch(setMessage({ text: "Passwords don't match!", type: "danger" }))
      return
    }

    setLoading(true)
    bbApi.auth.getCoreUIEnv().then(env => {
      dispatch(
        resetPassword({ newPassword: password, bbSid: env.session, token })
      )
        .unwrap()
        .then(() => {
          dispatch(clearMessage())
          setIsSuccess(true)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    })
  }

  useEffect(() => {
    dispatch(clearMessage())
  }, [dispatch])

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        setMessage({
          text: "Password reset successful. Sign in with your new password to continue.",
          type: "success"
        })
      )
      navigate("/user/login")
    }
  }, [dispatch, isSuccess, navigate])

  return (
    <>
      <p className="py-8 text-center font-medium text-2xl font-serif">
        Reset password
      </p>
      {message.text && message.type === "danger" && (
        <Alert.Danger className="mb-6" message={message.text} />
      )}
      <Form.Wrapper
        className="space-y-6"
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form.Input label="New password" name="password" type="password" />
        <Form.Input
          label="Re-enter new password"
          name="confirm"
          type="password"
        />

        <div>
          <PrimaryBtn
            className={`${loading ? "pointer-events-none" : ""} w-full`}
            type="submit"
            disabled={loading}
            onClick={() => {}}
          >
            {loading ? (
              <Loader2
                className="animate-spin h-5 w-5 text-primary-alt-400"
                aria-hidden="true"
              />
            ) : (
              <span>Reset my password</span>
            )}
          </PrimaryBtn>
        </div>
      </Form.Wrapper>
    </>
  )
}

export default PasswordReset
