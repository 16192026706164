export const formatAsTwoDigits = (input: number): string => {
  if (input < 10) return `0${input}`
  return input.toString()
}

export const formatDecimals = (input: number | string, decimals: number) => {
  const number = parseFloat(input.toString())
  if (Number.isNaN(number)) {
    return "NaN"
  }

  return number.toFixed(decimals)
}

export const formatPercentage = (val: number, decimals = 0): string => {
  return `${(val * 100).toFixed(decimals)}%`
}

export const formatNumber = (val: number | string, options: any = {}) => {
  const number = parseFloat(val.toString())

  if (Number.isNaN(number)) {
    return ""
  }

  const formatter = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
    ...options
  })

  return formatter.format(number)
}
