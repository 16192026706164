import { useAppSelector } from "./use-store"
import { selectUserDetails } from "../../redux/slices/auth"
import {
  checkIsSuperAdmin,
  checkIsBankAdmin,
  checkIsLoanOfficer
} from "../utils"

export const useUserRoles = () => {
  const user = useAppSelector(selectUserDetails)
  const roles = user?.roles ?? []
  const isSuperAdmin = checkIsSuperAdmin(roles)
  const isBankAdmin = checkIsBankAdmin(roles)
  const isLoanOfficer = checkIsLoanOfficer(roles)

  return { isBankAdmin, isLoanOfficer, isSuperAdmin, roles, user }
}
