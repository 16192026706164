import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../store"
import { PanelType } from "../../types"

type AppState = {
  visiblePanel: PanelType | null
}

const initialState: AppState = {
  visiblePanel: null
}

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setVisiblePanel: (state, action: PayloadAction<PanelType>) => {
      state.visiblePanel = action.payload
    },
    resetVisiblePanel: (state, action) => {
      state.visiblePanel = null
    }
  }
})

const { reducer, actions } = appSlice

export const { setVisiblePanel, resetVisiblePanel } = actions

export const selectVisiblePanel = (state: RootState) => state.app.visiblePanel

export default reducer
