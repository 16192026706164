import { FC } from "react"
import { Inspect } from "lucide-react"
import { SearchResult } from "../../../types"
import If from "../if"

interface Props {
  data: SearchResult
  isAdmin: boolean
  isFirstItem: boolean
  onClickItem: (result: SearchResult) => void
  handleMasquerade: (loid: string) => void
  closeModal: () => void
}

const SearchResultItem: FC<Props> = ({
  data,
  isAdmin,
  isFirstItem,
  onClickItem,
  handleMasquerade,
  closeModal
}) => {
  const showMasquerade = isAdmin && data.type === "loan_officer"

  const onMasquerade = () => {
    handleMasquerade(data.id)
    closeModal()
  }

  const onKeyDownClickItem = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      onClickItem(data)
    }
  }

  const onKeyDownMasquerade = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      onMasquerade()
    }
  }

  return (
    <>
      {isFirstItem && (
        <div className="py-2 px-6 font-medium text-sm border-b bg-neutral-100 first-letter:uppercase">
          {data.type.split("_").join(" ")}s
        </div>
      )}
      <div
        {...(!showMasquerade && {
          tabIndex: 0,
          onClick: () => onClickItem(data),
          onKeyDown: onKeyDownClickItem
        })}
        className="text-sm flex justify-between py-2 px-6 border-b border-neutral-100 hover:bg-teal-500 hover:text-white bg-white"
      >
        <div
          {...(showMasquerade && {
            tabIndex: 0,
            onClick: () => onClickItem(data),
            onKeyDown: onKeyDownClickItem
          })}
          className="cursor-pointer flex-1"
        >
          {data.item}
        </div>
        <If
          condition={showMasquerade}
          then={
            <div
              tabIndex={0}
              className="flex items-center cursor-pointer"
              onClick={onMasquerade}
              onKeyDown={onKeyDownMasquerade}
            >
              <Inspect size={16} />
              <span className="ml-2">Masquerade</span>
            </div>
          }
        />
      </div>
    </>
  )
}

export default SearchResultItem
