import { useState, FC, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Loader2 } from "lucide-react"
import * as Yup from "yup"
import { Alert, Form, PrimaryBtn } from "../../common/ui"
import { useAppDispatch, useAppSelector } from "../../common/hooks"
import { sendPasswordResetEmail } from "../../redux/slices/auth"
import {
  clearMessage,
  selectMessage,
  setMessage
} from "../../redux/slices/notifications"
import bbApi from "../../api"

interface FormValues {
  email: string
}

const validationSchema = Yup.object().shape({
  email: Yup.string().required("This field is required!")
})

const Password: FC<{}> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const message = useAppSelector(selectMessage)

  const [loading, setLoading] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  const initialValues = {
    email: ""
  }

  const handleSubmit = ({ email }: FormValues) => {
    setLoading(true)
    bbApi.auth.getCoreUIEnv().then(env => {
      dispatch(sendPasswordResetEmail({ email, bbSid: env.session }))
        .unwrap()
        .then(() => {
          setIsSuccess(true)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    })
  }

  useEffect(() => {
    dispatch(clearMessage())
  }, [dispatch])

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        setMessage({
          text: "You will receive an email with instructions on how to reset your password in a few minutes. Check your spam/junk folder.",
          type: "success"
        })
      )
      navigate("/user/login")
    }
  }, [dispatch, isSuccess, navigate])

  return (
    <>
      <p className="py-8 text-center font-medium text-2xl font-serif">
        Forgot password
      </p>
      {message.text && (
        <Alert.General
          className="mb-6"
          message={
            message.text === "Email not found" ? (
              <>
                Email not found. Contact{" "}
                <a href="mailto:support@bankingbridge.com">
                  <strong>support@bankingbridge.com</strong>
                </a>
              </>
            ) : (
              message.text
            )
          }
          theme={message.type}
        />
      )}
      <Form.Wrapper
        className="space-y-6"
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form.Input
          label="Email address"
          type="email"
          name="email"
          autoComplete="email"
        />

        <div>
          <PrimaryBtn
            className={`${
              loading ? "pointer-events-none" : ""
            } w-full text-base`}
            type="submit"
            disabled={loading}
            onClick={() => {}}
          >
            {loading ? (
              <Loader2
                className="animate-spin h-5 w-5 text-primary-alt-400"
                aria-hidden="true"
              />
            ) : (
              <span>
                {isSuccess
                  ? "Resend password reset instructions"
                  : "Send password reset instructions"}
              </span>
            )}
          </PrimaryBtn>
        </div>
        <div className="mt-6">
          <div className="mt-6 text-center">
            <Link
              to="/user/login"
              className="font-medium text-primary-400 hover:text-primary-500"
            >
              Back to Sign in
            </Link>
          </div>
        </div>
      </Form.Wrapper>
    </>
  )
}

export default Password
