import { LeadLog } from "../../../../../types"

type Props = {
  info: LeadLog
  onClick: (id: string) => void
}

const BrandCell = ({ info, onClick }: Props) => {
  const { id, brand_name } = info

  return (
    <td
      className="whitespace-nowrap py-4 px-3 cursor-pointer hidden md:table-cell"
      onClick={() => onClick(id)}
    >
      <div className="text-sm text-black">{brand_name}</div>
    </td>
  )
}

export default BrandCell
