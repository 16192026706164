import { ChangeEvent } from "react"
import { Integration } from "../../../../../types"
import { useIntegrationSettingsForm } from "../../../../hooks"
import Form from "../../../form"

type Props = {
  value: string
  name: string
  label: string
  integration?: Integration
}

const ColorPicker = ({ value, name, label, integration }: Props) => {
  const {
    saveSetting,
    saveSettingLocal,
    handleFormikBlur,
    handleFormikChange
  } = useIntegrationSettingsForm(integration)

  const onBlur = (e: ChangeEvent<HTMLInputElement>) => {
    saveSetting({ [name]: e.target.value })
    handleFormikBlur(e)
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    saveSettingLocal({ [name]: e.target.value })
    handleFormikChange(e)
  }

  return (
    <Form.InputColorPicker
      name={name}
      label={label}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      onChangeWhell={onChange}
    />
  )
}

export default ColorPicker
