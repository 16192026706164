import { memo } from "react"
import Skeleton from "./base"
import { useGridSize } from "../../hooks"

const NormalCard = () => {
  const gridSize = useGridSize()

  return (
    <div className="p-6 grid grid-rows-12 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 grid-flow-row md:grid-flow-rows gap-6 bg-primary-100">
      {[...Array(gridSize * 3)].map((_, index) => (
        <div
          key={index}
          className="bg-white w-full h-64 shadow-default rounded-md"
        >
          <Skeleton className="flex flex-col items-center justify-center gap-6 w-full h-5/6">
            <div className="w-16 h-16 bg-gray-200 rounded-full"></div>
            <div className="mx-auto">
              <div className="h-4 bg-gray-200 rounded-full w-24 mb-1.5 mx-auto"></div>
              <div className="h-4 bg-gray-100 rounded-full w-36"></div>
            </div>
          </Skeleton>
        </div>
      ))}
    </div>
  )
}

export default memo(NormalCard)
