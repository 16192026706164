import { memo } from "react"

import Skeleton from "../base"

const Activity = () => {
  return (
    <div className="bg-white w-full lg:w-1/2 shadow-default rounded-md px-6 py-8">
      <Skeleton className="h-full">
        <div className="flex flex-col justify-between items-between h-full">
          <div className="flex flex-col w-full mb-10">
            <div className="w-1/3 h-4 bg-gray-200 rounded-full mb-1.5"></div>
          </div>
          {[...Array(5)].map((_, key) => (
            <div key={key} className="flex w-full gap-6 mb-6">
              <div>
                <div className=" w-10 h-10 bg-gray-200 rounded-full"></div>
              </div>
              <div className="flex flex-col w-full">
                <div className="w-1/2 h-4 bg-gray-200 rounded-full mb-1.5"></div>
                <div className="w-3/4 h-4 bg-gray-100 rounded-full mb-1.5"></div>
              </div>
            </div>
          ))}
        </div>
      </Skeleton>
    </div>
  )
}

export default memo(Activity)
