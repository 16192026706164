import { createApi } from "@reduxjs/toolkit/dist/query/react"
import bbApi from "../../api"
import { RTK_SLICE_API_LIST } from "../../common/constants"
import { DOMAINS_ENDPOINT } from "../../common/constants"
import {
  ApprovedUrl,
  ApprovedUrlCreateArg,
  ApprovedUrlResponse
} from "../../types"
import { baseQuery, getTags } from "../api"

const reducerPath = "approved-urls"

export const approvedUrlsApi = createApi({
  reducerPath,
  baseQuery,
  tagTypes: [reducerPath],
  endpoints: builder => ({
    fetchApprovedUrls: builder.query({
      query: (orgId: string) => `${DOMAINS_ENDPOINT}?org_id=${orgId}`,
      transformResponse: (responseData: ApprovedUrlResponse) =>
        responseData || [],
      providesTags: result => getTags(reducerPath, "id", result)
    }),
    addApprovedUrl: builder.mutation({
      queryFn: async ({ domain_pattern, org_id }: ApprovedUrlCreateArg) => {
        const newURLData = await bbApi.approvedURLs.create(
          domain_pattern,
          org_id
        )

        return { data: { id: newURLData.id, domain_pattern, org_id } }
      },
      invalidatesTags: [{ type: reducerPath, id: RTK_SLICE_API_LIST }]
    }),
    updateApprovedURL: builder.mutation({
      queryFn: async ({ domain_pattern, org_id, id }: ApprovedUrl) => {
        const newURLData = await bbApi.approvedURLs.update(
          domain_pattern,
          org_id,
          id
        )

        return { data: newURLData.result.domain }
      },
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: arg.id }
      ]
    }),
    deleteApproverURL: builder.mutation({
      queryFn: async (domain_pattern_id: string) => {
        const deletedURL = await bbApi.approvedURLs.delete(domain_pattern_id)

        return { data: deletedURL.result.domain }
      },
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: arg },
        { type: reducerPath, id: RTK_SLICE_API_LIST }
      ]
    })
  })
})

export const {
  useFetchApprovedUrlsQuery,
  useAddApprovedUrlMutation,
  useUpdateApprovedURLMutation,
  useDeleteApproverURLMutation
} = approvedUrlsApi
