import { useNavigate } from "react-router-dom"
import { useAppSelector } from "./use-store"
import { selectUserDetails, selectUserRoles } from "../../redux/slices/auth"
import {
  addRecentSearch,
  checkIsLoanOfficer,
  checkIsSuperAdmin,
  getRecentSearches,
  setRecentSearches
} from "../utils"
import { useState, useEffect, MouseEvent, useCallback } from "react"
import bbApi from "../../api"
import { useFetchOrgDetailsQuery } from "../../redux/queries"
import { SearchResult } from "../../types"

export const useSearchAnything = () => {
  const navigate = useNavigate()
  const roles = useAppSelector(selectUserRoles)

  const isSuperAdmin = checkIsSuperAdmin(roles)
  const isLoanOfficer = checkIsLoanOfficer(roles)

  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [isLoadingResults, setIsLoadingResults] = useState<boolean>(false)
  const [query, setQuery] = useState<string>("")
  const [searchResults, setSearchResults] = useState<SearchResult[]>([])

  const user = useAppSelector(selectUserDetails)
  const { data: orgDetails } = useFetchOrgDetailsQuery(
    { id: user?.org?.id ?? "" },
    { skip: !user?.org?.id }
  )

  const [recentSearchesData, setRecentSearchesData] = useState<SearchResult[]>(
    getRecentSearches()
  )

  const openModal = useCallback(() => setModalVisible(true), [])
  const closeModal = useCallback(() => {
    setQuery("")
    setModalVisible(false)
  }, [])

  const onClickInput = useCallback(
    (e: MouseEvent) => {
      e.preventDefault()
      openModal()
    },
    [openModal]
  )

  const onClickItem = useCallback(
    (item: SearchResult) => {
      addRecentSearch(item)
      setRecentSearchesData(getRecentSearches())

      const link = getLink(item)
      if (link) navigate(link)

      closeModal()
    },
    [closeModal, navigate]
  )

  const onClickResetRecentSearches = useCallback(() => {
    setRecentSearches([])
    setRecentSearchesData([])
  }, [])

  const getLink = (item: SearchResult) => {
    const { type, id } = item
    switch (type) {
      case "integration":
        return `/integrations/${id}`
      case "subscription":
        return `/engagements/${id}`
      case "loan_officer":
        return `/loan-officers/${id}`
      case "brand":
        return `/brands/${id}`
      default:
        return null
    }
  }

  useEffect(() => {
    if (query?.length >= 3) {
      setIsLoadingResults(true)
      const params: any = {
        q_str: query
      }

      if (isLoanOfficer) {
        params.loid = user?.id
      } else {
        params.org_id = isSuperAdmin ? "" : orgDetails?.id ?? ""
      }

      bbApi.others
        .getSearchAnything(params)
        .then(res => {
          setIsLoadingResults(false)
          if ("status" in res) {
            setSearchResults([])
          } else {
            setSearchResults(res.sort((a, b) => (a.type > b.type ? 1 : 0)))
          }
        })
        .catch(() => {
          setIsLoadingResults(false)
          setSearchResults([])
        })
    } else {
      setSearchResults([])
    }
  }, [isLoanOfficer, isSuperAdmin, orgDetails?.id, query, user?.id])

  return {
    isLoadingResults,
    modalVisible,
    onClickInput,
    onClickItem,
    onClickResetRecentSearches,
    query,
    recentSearchesData,
    searchResults,
    setQuery,
    closeModal
  }
}
