import { FC } from "react"
import { Lead } from "../../../../../../../types"
import SectionCell from "../../../../../section/cell"

interface Props {
  data: Lead
}

const Message: FC<Props> = ({ data }) => {
  const {
    details: { message = "" }
  } = data

  return <SectionCell className="-mt-12 break-all" label="" value={message} />
}

export default Message
