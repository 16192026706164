import { FC, Fragment, useEffect, useMemo, useState } from "react"
import { DateTime } from "luxon"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"

import If from "../../../if"
import GraphSkeleton from "../../../skeletons/graph"
import {
  useFetchSubscriptionCountQuery,
  useFetchSubscriptionCumulativeCountQuery
} from "../../../../../redux/queries"
import { useCountDataTransformation } from "../../../../hooks"
import { getAreaSplineChartOptions } from "../../../chart-with-stats/options"

interface SubscriptionCountProps {
  title: string
  range: number
  orgId: string | undefined
  loid: string | undefined
}

const SubscriptionCount: FC<SubscriptionCountProps> = ({
  title,
  range,
  orgId,
  loid
}) => {
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const dateFormat = "yyyy-MM-dd"
  const labelDateFormat = "LLL dd yy"
  const purple400Color = "#786ff6"
  const { data: subscriptionCountData, isFetching: isFetchingLeadCount } =
    useFetchSubscriptionCountQuery(
      {
        range,
        org_id: orgId,
        loid
      },
      { refetchOnMountOrArgChange: true }
    )
  const containsData = useMemo(
    () => subscriptionCountData && subscriptionCountData.length > 0,
    [subscriptionCountData]
  )
  const { data: subscriptionCountCumulativeData } =
    useFetchSubscriptionCumulativeCountQuery(
      {
        range,
        org_id: orgId,
        loid
      },
      { refetchOnMountOrArgChange: true, skip: !containsData }
    )

  const cumulativeSubscriptionCount = subscriptionCountCumulativeData?.length
    ? subscriptionCountCumulativeData.reduce((latest, current) =>
        DateTime.fromFormat(latest.period_start, dateFormat) >
        DateTime.fromFormat(current.period_start, dateFormat)
          ? latest
          : current
      ).subscription_count
    : undefined

  const chartLabels = useCountDataTransformation(
    subscriptionCountData,
    containsData,
    purple400Color,
    dateFormat,
    labelDateFormat,
    range
  )

  useEffect(() => {
    setIsFetching(isFetchingLeadCount)
  }, [isFetchingLeadCount])

  return (
    <div className="bg-white flex flex-col flex-1 px-6 pt-6 pb-4 rounded-md overflow-hidden">
      <div className="flex flex-row items-center justify-between">
        <div className="text-base font-medium">{title}</div>
      </div>
      <If
        condition={isFetching}
        then={<GraphSkeleton className="mt-1 pt-12" />}
        else={
          <Fragment>
            <div className="pt-11 pb-5 relative">
              {!containsData && (
                <div className="absolute top-24 left-1/2 text-sm z-[1]">
                  No data yet
                </div>
              )}
              <HighchartsReact
                highcharts={Highcharts}
                options={getAreaSplineChartOptions(
                  containsData,
                  chartLabels,
                  "subscriptions",
                  "",
                  {
                    fillOpacity: 0,
                    lineWidth: 2,
                    lineColor: "#786ff6"
                  }
                )}
                containerProps={{ style: { height: "225px" } }}
              />
            </div>
            <div className="flex text-xs">
              <div className="flex flex-col">
                <div className="leading-5">NO. OF ACTIVE SUBSCRIPTIONS</div>
                <div className="text-lg leading-6 font-inter-extrabold">
                  {cumulativeSubscriptionCount?.toLocaleString() ?? "-"}
                </div>
              </div>
            </div>
          </Fragment>
        }
      />
    </div>
  )
}

export default SubscriptionCount
