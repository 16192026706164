import { Search } from "lucide-react"
import { MouseEvent } from "react"

interface Props {
  onClickInput: (e: MouseEvent) => void
}

const PageSearchForm = ({ onClickInput }: Props) => (
  <>
    <label htmlFor="search-field" className="sr-only">
      Search
    </label>
    <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
      <Search className="text-neutral-400" size={20} aria-hidden="true" />
    </div>
    <form autoComplete="off">
      <input
        id="search-field"
        className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
        placeholder="Search for anything"
        type="search"
        name="search"
        autoComplete="off"
        onClick={onClickInput}
        onChange={() => true}
        value=""
      />
    </form>
  </>
)

export default PageSearchForm
