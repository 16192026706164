import { Fragment } from "react"
import { NavLink } from "react-router-dom"

import DisclosureNavbarLinks from "../disclosure-navbar-links"
import { classNames } from "../../utils"
import { NavItemType } from "../../../types"

type NavbarLinkProps = {
  isFull?: boolean
  isMobile?: boolean
  items: NavItemType[]
}

const NavbarLinks = ({ isFull, isMobile, items }: NavbarLinkProps) => {
  const setClassList = (isActive: boolean) =>
    classNames(
      "group flex items-center px-2 py-2 rounded-md sm:hover:bg-white/[.1] sm:hover:text-white",
      isActive ? "bg-white/[.1] text-white" : "text-white/[.6]",
      isMobile
        ? "text-base"
        : "text-sm cursor-pointer whitespace-nowrap overflow-hidden",
      isMobile ? "" : isFull ? "justify-start" : "justify-center"
    )

  const setIconClassList = (isActive: boolean, hasNoMargin?: boolean) =>
    classNames(
      isActive ? "text-white" : "text-white/[.6]",
      hasNoMargin ? "mr-0" : isMobile ? "mr-4" : isFull ? "mr-3" : "mr-0",
      "flex-shrink-0 sm:group-hover:text-white"
    )

  return (
    <>
      {items.map((item, index) => (
        <Fragment key={`menu-${item.key}-${index}`}>
          {!item.children || (!isFull && !isMobile) ? (
            <NavLink
              key={`menu-${item.key}`}
              to={item.href}
              className={({ isActive }) => setClassList(isActive)}
            >
              {({ isActive }) => (
                <>
                  <item.icon
                    size={20}
                    aria-hidden="true"
                    className={setIconClassList(isActive)}
                  />
                  {isFull || isMobile ? item.name : ""}
                </>
              )}
            </NavLink>
          ) : (
            <DisclosureNavbarLinks
              item={item}
              isFull={isFull}
              isMobile={isMobile}
              setClassList={setClassList}
              setIconClassList={setIconClassList}
            />
          )}
        </Fragment>
      ))}
    </>
  )
}

export default NavbarLinks
