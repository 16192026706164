import { FC } from "react"
import { Pencil } from "lucide-react"
import { Organization } from "../../../../types"
import BreadCrumbs from "../../breadcrumbs"
import { Button } from "../../buttons"
import { formatDateTimeFromTimestamp } from "../../../utils"

interface Props {
  data: Organization
  onClickEdit: (org: Organization) => void
}

const OrganizationDetailsHeader: FC<Props> = ({ data, onClickEdit }) => {
  return (
    <div className="p-6 bg-white">
      <BreadCrumbs
        pages={[
          {
            name: "Organizations",
            href: "/",
            current: false
          },
          {
            name: data.name,
            href: "#",
            current: true
          }
        ]}
      />

      <div className="py-4 flex items-center justify-between">
        <div className="flex items-center">
          <div className="h-12 mr-4 p-2 border border-gray-300 rounded">
            <img
              className="h-full"
              src={data.logo}
              alt={`${data.name} Thunbnail`}
            />
          </div>
          <div>
            <h3 className="text-lg font-bold">{data.name}</h3>
            <div className="text-sm">
              <span>
                Active since&nbsp;
                {formatDateTimeFromTimestamp(data.created, "dd LLL yyyy")}
              </span>
              <span className="text-gray-400">&nbsp;&bull;&nbsp;</span>
            </div>
          </div>
        </div>
        <div className="flex">
          <Button className="bg-gray-200" onClick={() => {}}>
            View Dashboard
          </Button>
          <Button
            className="ml-2 bg-gray-200"
            onClick={() => onClickEdit(data)}
          >
            <Pencil size={20} />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default OrganizationDetailsHeader
