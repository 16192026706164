import { FC } from "react"
import { Form } from "formik"
import BrandCheckList from "../../fields/check-list"
import BrandColors from "../../fields/colors"
import BrandReviewSettings from "../../fields/review-settings"
import BrandSettings from "../../fields/settings"
import BrandTextList from "../../fields/text-list"
import { Brand, LoSearchParams } from "../../../../../types"

interface Props {
  values: Brand
  initialValues: Brand
  loSearchParams: LoSearchParams
  setFormField: (name: string, value: string | number) => void
}

const ManageBrandFormContent: FC<Props> = ({
  values,
  initialValues,
  loSearchParams,
  setFormField
}) => {
  return (
    <div className="py-12 px-2">
      <Form>
        <BrandSettings
          values={values}
          setFormField={setFormField}
          loSearchParams={loSearchParams}
        />
        <BrandColors values={values} setFormField={setFormField} />
        <BrandCheckList values={values} setFormField={setFormField} />
        <BrandTextList values={values} setFormField={setFormField} />
        <BrandReviewSettings
          values={values}
          initialValues={initialValues}
          setFormField={setFormField}
        />
      </Form>
    </div>
  )
}

export default ManageBrandFormContent
