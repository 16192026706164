import { FC, Fragment } from "react"

import { FunnelStage } from "../../../types"

interface FunnelChartProps {
  stages: FunnelStage[]
}

const FunnelChart: FC<FunnelChartProps> = ({ stages }) => {
  return (
    <div className="w-full">
      {stages.map((stage, index) => (
        <div key={index} className="flex">
          <div className="basis-3/12 text-xs md:text-sm text-center md:pt-0.5">
            {stage.label}
          </div>
          <div className="basis-auto relative w-full flex flex-col items-center justify-center text-white text-center pl-4 md:pl-8 pr-5 md:pr-11">
            {stage.percentage < 10 && (
              <div className="absolute inset-0 h-10 flex justify-center items-center left-16 text-xs text-base-dark">{`${stage.percentage?.toFixed(
                2
              )}%`}</div>
            )}

            <div
              className="h-10 flex items-center justify-center text-xs"
              style={{
                width: `${stage.percentage === 0 ? 0.1 : stage.percentage}%`,
                background: `${stage.color}`
              }}
            >
              {stage.percentage > 10 && (
                <Fragment>{`${stage.percentage?.toFixed(2)}%`}</Fragment>
              )}
            </div>
            <div
              className="w-full h-7"
              style={
                index < stages.length - 1
                  ? {
                      backgroundColor: "#E0DEFF",
                      clipPath: stage?.clipPath
                    }
                  : {}
              }
            ></div>
          </div>
          <div className="basis-2/12 font-bold pt-2">
            {stage.value?.toLocaleString()}
          </div>
        </div>
      ))}
    </div>
  )
}

export default FunnelChart
