import { useEffect, useRef, useState } from "react"
import { CornerDownRight, Loader2, Plus } from "lucide-react"
import { REGEX_HTPPS_URL_WILDCARD } from "../../constants"
import If from "../if"

const INVALID_CLASSNAME = "invalid:!ring-red-500 invalid:!ring-1"
/**
 * Regular expression that matches the below URL patterns
 *  - https://www.example.com
 *  - https:/*.example.com
 *  - https://example.com/*
 *  - https://*.example.com/*
 */

type Props = {
  url: string
  resetOnBlur?: boolean
  onChange: (url: string) => void
  onSubmit: () => void
  onBlur?: () => void
  isLoading?: boolean
  id?: string
  resetInputOnSubmit?: boolean
}

const InputURL = ({
  url,
  onChange,
  onSubmit,
  resetOnBlur,
  onBlur,
  isLoading = false,
  resetInputOnSubmit,
  id
}: Props) => {
  const newURLInput = useRef<HTMLInputElement>(null)
  const [invalidInput, setInvalidInput] = useState("")

  useEffect(() => {
    if (newURLInput.current) {
      newURLInput.current.focus()
    }
  }, [])

  const resetInput = () => {
    resetInputOnSubmit && onChange("")
    blurInput()
  }

  const blurInput = () => {
    setInvalidInput("")
    // blur newURLInput
    newURLInput.current?.blur?.()
  }

  // On press enter validate and submit the new URL
  const onKeyPress = (e: any) => {
    if (e.key === "Enter") {
      if (REGEX_HTPPS_URL_WILDCARD.test(url)) {
        blurInput()
        onSubmit()
      } else {
        setInvalidInput(INVALID_CLASSNAME)
      }
    } else if (e.key === "Escape") {
      e.stopImmediatePropagation()
      resetInput()
    }
  }

  const handleOnBlur = () => {
    if (resetOnBlur) {
      resetInput()
      onBlur?.()
    }
  }

  return (
    <div className="relative w-full">
      <If
        condition={url.length === 0}
        then={
          <div className="pointer-events-none absolute inset-y-0 left-2 flex items-center">
            <span className="text-neutral-400">
              <Plus size={20} />
            </span>
          </div>
        }
      />
      <input
        onBlur={handleOnBlur}
        ref={newURLInput}
        pattern={`${REGEX_HTPPS_URL_WILDCARD}`}
        type="url"
        {...{ id }}
        value={url}
        onKeyPress={onKeyPress}
        onChange={e => onChange(e.target.value)}
        className={`focus:ring-0 text-base-dark block border-0 w-full pr-40 sm:text-sm rounded-sm peer placeholder:text-neutral-400 ${invalidInput} ${
          url.length ? "pl-2" : "pl-7"
        }`}
        placeholder="Add new URL"
      />

      <If
        condition={isLoading}
        then={
          <div className="pointer-events-none absolute right-0 items-center pr-3 top-1.5">
            <Loader2
              className="animate-spin h-6 w-6 text-primary-alt-400"
              aria-hidden="true"
            />
          </div>
        }
        else={
          <div className="pointer-events-none absolute inset-y-0 right-0 items-center pr-3 hidden peer-focus:flex">
            <div className="text-neutral-400 text-sm flex items-center">
              <CornerDownRight size={14} className="mr-1" /> Press enter to save
            </div>
          </div>
        }
      />
    </div>
  )
}

export default InputURL
