/**
  Pricing set API wrapper

**/

import { api } from "../common/utils"
import { PRICING_SET_ENDPOINT } from "../common/constants"
import { FindPricingSetsResponse } from "../types"

const pricingSets = {
  find: async (params: any): Promise<FindPricingSetsResponse> => {
    const { data } = await api.get(PRICING_SET_ENDPOINT, { params })
    return data
  }
}

export default pricingSets
