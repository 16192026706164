import { PricingRate } from "../../../../../types"
import { formatCurrency } from "../../../../utils"

type Props = {
  value: PricingRate
}

const RateCell = ({ value }: Props) => {
  return (
    <td className="whitespace-nowrap py-4 pl-2 pr-3 cursor-pointer">
      <div>
        <div className="text-sm text-neutral-400 font-medium">
          Rate {value.rate}%
        </div>
        <div className="text-sm">Price: {formatCurrency(value.price)}</div>
      </div>
    </td>
  )
}

export default RateCell
