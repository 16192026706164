import { FC } from "react"
import {
  Direction,
  DraggingStyle,
  DropResult,
  NotDraggingStyle,
  DragDropContext,
  Droppable,
  Draggable
} from "react-beautiful-dnd"
import { GripVertical } from "lucide-react"
import { IStep } from "../../../types"

interface Props {
  items: IStep[]
  direction: Direction
  onChange: (value: IStep[]) => void
}

const reorder = (list: IStep[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const GRID = 8
const MAX_ITEM_LEFT = 40

const getItemStyle = (
  isDragging: boolean,
  draggableStyle?: DraggingStyle | NotDraggingStyle
) => {
  return {
    // some basic styles to make the items look a bit nicer
    // userSelect: "none",
    padding: GRID,
    // margin: `0 0 ${GRID}px 0`,
    fontWeight: 500,
    borderBottom: "1px solid #e4e4e4",

    // change background colour if dragging
    background: isDragging ? "#eee" : "transparent",

    // styles we need to apply on draggables
    ...draggableStyle,

    left: Math.min((draggableStyle as DraggingStyle)?.left ?? 0, MAX_ITEM_LEFT)
  }
}

const getListStyle = (isDraggingOver: boolean) => ({
  // background: isDraggingOver ? "lightgrey" : "transparent",
  padding: GRID,
  width: "100%"
})

const DndList: FC<Props> = ({ items, direction, onChange }) => {
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const newList = reorder(
      items,
      result.source.index,
      result.destination.index
    )

    onChange(newList)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="vertical-list" direction={direction}>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <div className="flex items-center py-2">
                      <GripVertical className="text-neutral-400" />
                      <span className="ml-4">{item.label}</span>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DndList
