import { Badge, Status } from "../../../../ui"
import { SubscriptionLog } from "../../../../../types"
import { capitalize } from "../../../../utils"

type Props = {
  info: SubscriptionLog
  onClick: (id: string) => void
}

const EngagementsCell = ({ info, onClick }: Props) => {
  const { email, active, source, id, deleted, first_name, last_name } = info

  const name =
    first_name || last_name ? `${first_name} ${last_name}` : "Unnamed"

  return (
    <td className="whitespace-nowrap py-4 pl-2 pr-3 cursor-pointer" colSpan={2}>
      <div className="flex items-center">
        <div
          className="font-bold mr-2 hover:border-b border-neutral-500 leading-none"
          onClick={() => onClick(id)}
        >
          {name}
        </div>
        <div className="mr-2 flex items-center">
          {deleted === "1" ? (
            <Status.Inactive label="Deleted" />
          ) : (
            <>
              {active === "1" && <Status.Active label="Active" />}
              {active === "0" && <Status.Idle label="Disabled" />}
            </>
          )}
        </div>
        {source === "engagement" && (
          <Badge.Info>{capitalize(source)}</Badge.Info>
        )}
        {source === "embed" && (
          <Badge.Success>{capitalize(source)}</Badge.Success>
        )}
        {source === "log" && <Badge.Danger>{capitalize(source)}</Badge.Danger>}
        {source === "unknown" && (
          <Badge.Disabled>{capitalize(source)}</Badge.Disabled>
        )}
      </div>
      <div className="text-neutral-400 text-sm">{email}</div>
    </td>
  )
}

export default EngagementsCell
