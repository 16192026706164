import { MEDIA_BREAKPOINTS } from "../constants"
import { useWindowSize } from "./use-window-size"

export const useGridSize = () => {
  const [width] = useWindowSize()

  if (width < MEDIA_BREAKPOINTS.sm) return 1
  if (width < MEDIA_BREAKPOINTS.lg) return 2
  if (width < MEDIA_BREAKPOINTS.xl) return 3
  return 4
}
