import { FC, useEffect } from "react"
import { CreateSubscriptionPayload } from "../../../../../../types"
import Input from "../../../../form/input"
import { Button } from "../../../../buttons"
import { subscriptionsApi } from "../../../../../../redux/queries"
import { useAppDispatch } from "../../../../../hooks"
import { pushToast } from "../../../../../../redux/slices/notifications"
import { formatCurrency } from "../../../../../utils"
import BBSwitch from "../../../../form/Switch"

interface Props {
  values: CreateSubscriptionPayload
  setFormField: (name: string, value: string | number | boolean) => void
}

const ManageEngagementMisc: FC<Props> = ({ values, setFormField }) => {
  const dispatch = useAppDispatch()

  const [fetchTaxesAndInsurance, { data, isFetching, isError }] =
    subscriptionsApi.endpoints.fetchTaxesAndInsurance.useLazyQuery()

  const handleAutoFill = () => {
    fetchTaxesAndInsurance({
      zipcode: values.zipcode
    })
  }

  useEffect(() => {
    if (isFetching) return

    if (isError || (data && "status" in data)) {
      dispatch(
        pushToast({
          msg: "Failed to fetch taxes & insurace values.",
          theme: "danger",
          timeout: 3000
        })
      )
    }

    if (data) {
      setFormField("taxes", data.taxes)
      setFormField("insurance", data.insurance)
    }
  }, [data, dispatch, isError, isFetching, setFormField])

  return (
    <div className="py-4">
      <div className="text-xl font-bold py-8">Misc.</div>
      <div className="pb-8">
        <BBSwitch
          label="Eligible for lower rate"
          helperText="Toggle on if engagement is a first-time homebuyer or earns under 80% of the county's median income."
          enabled={values.first_time_homebuyer}
          setEnabled={value => setFormField("first_time_homebuyer", value)}
        />
      </div>
      {/* <div className="pb-8">
        <BBSwitch
          label="Waive escrows"
          helperText="Toggle on to waive property taxes and home insurance. Pricing adjusts accordingly."
          enabled={values.waive_escrows}
          setEnabled={value => setFormField("waive_escrows", value)}
        />
      </div> */}
      <div className="pb-8">
        <BBSwitch
          label="Send Rate Update Emails"
          helperText="Rate subscription emails are sent out weekly to any lead that is enrolled in subscriptions. Emails are branded from the loan officer and provide real-time rates to the lead based on their saved profile."
          enabled={values.active === "1"}
          setEnabled={value => setFormField("active", value ? "1" : "0")}
        />
      </div>
      <div className="pb-8">
        <BBSwitch
          label="Send lead to CRM"
          helperText="If toggled on, lead will be sent to CRM."
          enabled={values.send_to_crm}
          setEnabled={value => setFormField("send_to_crm", value)}
        />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <Input
          name="taxes"
          label="Property taxes"
          subLabel="(% of property value)"
          type="number"
          placeholder="Ex. $100000"
          helpText={
            values.list_price && data?.taxes
              ? `${formatCurrency(
                  (parseFloat(values.list_price) * data.taxes) / 100
                )}/yr`
              : ""
          }
        />
        <Input
          name="insurance"
          label="Home insurance"
          subLabel="(% of property value)"
          type="number"
          placeholder="Ex. $100000"
          helpText={
            values.list_price && data?.insurance
              ? `${formatCurrency(
                  (parseFloat(values.list_price) * data.insurance) / 100
                )}/yr`
              : ""
          }
        />
        <Input
          name="seller_credit"
          label="Seller credit"
          type="number"
          placeholder="Ex. $100000"
          disabled={values.loan_purpose === "refinance"}
        />
      </div>
      <div className="py-3">
        <Button disabled={!values.zipcode} onClick={handleAutoFill}>
          Auto-fill Taxes & Insurance
        </Button>
        {!values.zipcode ? (
          <div className="pt-2 text-sm text-neutral-400">
            Enter property address details.
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default ManageEngagementMisc
