import { FC } from "react"
import { RateflowCard } from "../../../../../../types"
import { formatCurrency, formatNumber } from "../../../../../utils"

interface Props {
  data: RateflowCard & { loanAmount: number }
  onSelect: (id: string) => void
}

const QuoteCard: FC<Props> = ({ data, onSelect }) => {
  return (
    <div className="border border-neutral-200 rounded-md flex flex-col">
      <div className="p-4 flex-1">
        <div className="font-bold text-3xl">
          {formatNumber(data.rate, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 3
          })}
          %
        </div>
        <div className="text-sm mt-1">
          {data.amortizationTerm} YR {data.amortizationType}
        </div>

        <div className="grid grid-cols-2 gap-2 mt-4">
          <div className="text-neutral-400 text-sm mb-3">Price</div>
          <div className="text-sm mb-3">{data.price?.toFixed(2) ?? "0"}</div>

          <div className="text-neutral-400 text-sm mb-3">Fee</div>
          <div className="text-sm mb-3">{formatCurrency(data.fee.amount)}</div>

          <div className="text-neutral-400 text-sm mb-3">Loan amount</div>
          <div className="text-sm mb-3">{formatCurrency(data.loanAmount)}</div>

          <div className="text-neutral-400 text-sm mb-3">P/I</div>
          <div className="text-sm mb-3">
            {formatCurrency(data.principalAndInterest)}
          </div>

          <div className="text-neutral-400 text-sm mb-3">Type</div>
          <div className="text-sm mb-3">{data.loanType}</div>

          <div className="text-neutral-400 text-sm">Investor</div>
          <div className="text-sm break-all">{data.investor}</div>
        </div>
      </div>

      <div
        className="font-medium border-t border-neutral-200 text-primary-400 p-4 cursor-pointer"
        onClick={() => onSelect(data.hash)}
      >
        View details
      </div>
    </div>
  )
}

export default QuoteCard
