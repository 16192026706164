import { FC } from "react"
import { Trash2 } from "lucide-react"
import { selectIsSubmitting } from "../../../../redux/slices/integrations"
import {
  Integration,
  SidePanelActions,
  IntegrationStatus
} from "../../../../types"
import { useAppSelector } from "../../../hooks"
import SidePanelWithTabs from "../../side-panel-with-tabs"
import { Settings, Status, Install, Monitor } from "../tabs"
import { useShowMonitorForm } from "."

interface Props {
  integration: Integration
  isLoadingIntegration: boolean
  statusData?: IntegrationStatus[]
  isLoadingStatus: boolean
  monitorID: string | number
  open: boolean
  onClose: () => void
  onSubmitIntegration: () => void
  onSubmitMonitor: () => void
  onToggleMonitorForm: () => void
  openDeleteModal: () => void
  onSaveSettings: (integration: Integration) => void
}

const EditIntegration: FC<Props> = ({
  integration,
  isLoadingIntegration,
  statusData,
  isLoadingStatus,
  open,
  monitorID,
  onClose,
  onSubmitIntegration,
  onSubmitMonitor,
  onToggleMonitorForm,
  openDeleteModal,
  onSaveSettings
}) => {
  const selectedIsSubmitting = useAppSelector(selectIsSubmitting)
  const showMonitorForm = useShowMonitorForm()

  const monitorActions: SidePanelActions = []

  if (monitorID !== 0) {
    monitorActions.push({
      label: "Toggle form",
      onClick: onToggleMonitorForm,
      type: "secondary"
    })
  }

  if (showMonitorForm) {
    monitorActions.push({
      label: monitorID === 0 ? "Create monitor" : "Update monitor",
      type: "primary",
      onClick: onSubmitMonitor
    })
  }

  if (!integration) return null

  return (
    <SidePanelWithTabs
      isSubmitting={selectedIsSubmitting}
      title={
        <div className="flex items-center">
          {integration.title}
          <div className="ml-4 cursor-pointer" onClick={openDeleteModal}>
            <Trash2 size={20} />
          </div>
        </div>
      }
      open={open}
      onClose={onClose}
      tabs={[
        {
          id: "settings",
          label: "Settings",
          Content: () => (
            <Settings
              integration={integration}
              isLoading={isLoadingIntegration}
              onSaveSettings={onSaveSettings}
            />
          ),
          actions: [
            {
              label: "Update",
              type: "primary",
              onClick: onSubmitIntegration
            }
          ]
        },
        {
          id: "status",
          label: "Status",
          Content: () => (
            <Status
              integration={integration}
              statusData={statusData}
              isLoading={isLoadingStatus}
            />
          )
        },
        {
          id: "monitor",
          label: "Monitor",
          Content: () => <Monitor integration={integration} />,
          actions: monitorActions
        },
        {
          id: "install",
          label: "Install",
          Content: () => <Install integration={integration} />
        }
      ]}
    />
  )
}

export default EditIntegration
