import { DateTime } from "luxon"
import { calculateTotal } from "./table"

export enum RateTableUsageType {
  IMPRESSIONS = "Impressions",
  UPDATE_RATES_CLICK = "Update Rates Click",
  GET_STARTED_CLICK = "Get Started Click",
  PERSONALIZED_RATES_CLICK = "Personalized Rates Click",
  GET_RATE_ALERTS_CLICK = "Get Rate Alerts Click"
}

export enum RateTableUsageTypeColor {
  IMPRESSIONS = "#22211C",
  UPDATE_RATES_CLICK = "#FF6A6A",
  GET_STARTED_CLICK = "#FFDF34",
  PERSONALIZED_RATES_CLICK = "#786FF6",
  GET_RATE_ALERTS_CLICK = "#63C7C4"
}

export interface RateTableInfoData {
  label: string
  value: string
  containerClassName?: string
  iconColor?: string
  containsTooltip?: boolean
  tooltipText?: string
}

export const getShortFeeTitle = (fee: { title?: string }) => {
  return (fee.title ?? "").split(" ").pop()
}

export const getDefaultSeriesData = (): Record<
  RateTableUsageType,
  number[]
> => {
  const initialData: Record<RateTableUsageType, number[]> = {
    [RateTableUsageType.IMPRESSIONS]: [],
    [RateTableUsageType.UPDATE_RATES_CLICK]: [],
    [RateTableUsageType.GET_STARTED_CLICK]: [],
    [RateTableUsageType.PERSONALIZED_RATES_CLICK]: [],
    [RateTableUsageType.GET_RATE_ALERTS_CLICK]: []
  }

  for (let i = 0; i < 6; i++) {
    Object.values(RateTableUsageType).forEach(type => initialData[type].push(0))
  }

  return initialData
}

export const getDefaultNoDataDates = (labelDateFormat: string): string[] => {
  return Array.from({ length: 6 }, (_, i) =>
    DateTime.now().minus({ days: i }).toFormat(labelDateFormat)
  ).sort((a, b) => (a > b ? 1 : -1))
}

export const createRateTableUsageSeries = (
  name: RateTableUsageType,
  color: string
): Highcharts.SeriesLineOptions => ({
  type: "line",
  name,
  data: [],
  color,
  lineWidth: 2,
  marker: {
    enabled: false,
    symbol: "circle"
  }
})

export const createRateTableInfoData = (
  label: string,
  value: string,
  iconColor?: string,
  containsTooltip?: boolean,
  tooltipText?: string,
  containerClassName?: string
): RateTableInfoData => ({
  label,
  value,
  iconColor,
  containsTooltip,
  tooltipText,
  containerClassName
})

export const getRateTableInfoDataValue = (
  containsData: boolean | undefined,
  seriesData: Record<RateTableUsageType, number[]> | undefined,
  type: RateTableUsageType
): string =>
  containsData && seriesData
    ? calculateTotal(seriesData[type])?.toLocaleString()
    : "-"
