import { FC, useState } from "react"
import { useParams } from "react-router-dom"
import { ALLOWED_ROLES_BY_ROUTE } from "../../common/constants"
import EngagementDetailsHeader from "../../common/ui/engagements/details/header"
import EngagementEmailHistory from "../../common/ui/engagements/details/sections/eamil-history"
import EngagementQuoteHistory from "../../common/ui/engagements/details/sections/quote-history"
import EngagementSummary from "../../common/ui/engagements/details/sections/summary"
import Tabs from "../../common/ui/tabs"
import { RequireRole } from "../../common/validation"
import {
  useFetchSubscriptionDetailsQuery,
  useUpdateSubscriptionMutation,
  useFetchLoanOfficerQuery
} from "../../redux/queries"
import { SubscriptionDetails } from "../../types"
import { convertSubscriptionDetailsToPayload } from "../../common/utils"
import { If } from "../../common/ui"
import EngagementEvents from "../../common/ui/engagements/details/sections/events"

const EngagementDetailsPage: FC<{}> = () => {
  const { id } = useParams()

  const { data, isFetching: isFetchingSubscriptionDetails } =
    useFetchSubscriptionDetailsQuery({ id: id ?? "" }, { skip: !id })

  const { data: loDetails, isFetching: isFetchingLos } =
    useFetchLoanOfficerQuery(data?.loid ?? "", {
      skip: !data?.loid
    })

  const [updateSubscription] = useUpdateSubscriptionMutation()

  const handleChangeRateAlerts = (subscription: SubscriptionDetails) => {
    updateSubscription({
      ...convertSubscriptionDetailsToPayload(subscription),
      id: subscription.id,
      active: subscription.active === "1" ? "0" : "1"
    })
  }

  const handleChangeStatus = (
    subscription: SubscriptionDetails,
    id: string
  ) => {
    updateSubscription({
      ...convertSubscriptionDetailsToPayload(subscription),
      id: subscription.id,
      active: id === "active" ? "1" : "0",
      deleted: id === "deleted" ? "1" : "0"
    })
  }

  const [tab, setTab] = useState<string>("summary") // "summary", "email-history", "quote-history" or "activity-history"

  const isFetching = isFetchingLos || isFetchingSubscriptionDetails

  return (
    <RequireRole allowedRoles={ALLOWED_ROLES_BY_ROUTE["engagements"]}>
      <If
        isLoading={isFetching}
        condition={!!(data && loDetails)}
        then={
          <>
            <div className="h-full flex flex-col">
              <div className="pt-6 bg-white">
                <div className="px-6">
                  <EngagementDetailsHeader
                    data={data!}
                    loDetails={loDetails!}
                    handleChangeRateAlerts={handleChangeRateAlerts}
                    onChangeStatus={handleChangeStatus}
                  />
                </div>
                <Tabs
                  className="px-6"
                  currentTab={tab}
                  tabs={[
                    { id: "summary", label: "Summary" },
                    { id: "quote-history", label: "Quote history" },
                    { id: "email-history", label: "Email history" },
                    { id: "activity-history", label: "Activity history" }
                  ]}
                  onChange={setTab}
                />
              </div>
              <div className="p-6 flex-1 bg-white">
                {tab === "summary" && (
                  <EngagementSummary data={data!} loDetails={loDetails!} />
                )}
                {tab === "quote-history" && (
                  <EngagementQuoteHistory data={data!} loDetails={loDetails!} />
                )}
                {tab === "email-history" && (
                  <EngagementEmailHistory data={data!} />
                )}
                {tab === "activity-history" && (
                  <EngagementEvents data={data!} />
                )}
              </div>
            </div>
          </>
        }
        else={
          <If
            condition={!data}
            then={
              <div className="p-6">
                Could not fetch details of this engagement.
              </div>
            }
            else={
              <div className="p-6">
                No loan officer is found for this engagement.
              </div>
            }
          />
        }
      />
    </RequireRole>
  )
}

export default EngagementDetailsPage
