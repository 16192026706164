import { LoanOfficer } from "../../../../types"

export const defaultValues: LoanOfficer = {
  name: "",
  first_name: "",
  last_name: "",
  uid: "",
  email: "",
  nmls: "",
  brand_nmls: "",
  app_link: "",
  nid: "",
  external: false,
  logo: "",
  disclaimer: "",
  picture: "",
  template_preference: {
    template_id: "",
    swatch_id: ""
  },
  bank_nid: "",
  bank: "",
  non_bank: false,
  position: "Loan Officer",
  roles: ["loan officer"],
  branch_nmls: "",
  pricing_status: "inactive",
  crm_type: "",
  crm_id: "",
  active: false,
  office_street_addr: "",
  office_zipcode: "",
  office_city: "",
  office_state: "",
  mobile_phone: "",
  office_phone: "",
  phone: "",
  brand_admin: false,
  masq_link: "",
  originator_id: "",
  biz_channel: "",
  about_me: "",
  about_bank: "",
  title: "",
  website_link: "",
  facebook_link: "",
  linkedin_link: "",
  special_disclosure_link: "",
  created: 0,
  org_id: -1
}
