import { FC, useEffect, useRef } from "react"
import { FormikProps, Formik } from "formik"
import { number, object } from "yup"
import { CountyDetails } from "../../../../../types"
import Input from "../../../form/input"
import SectionCell from "../../../section/cell"
import { LightBtn, PrimaryBtn } from "../../../buttons"
import { useUpdateCountyMutation } from "../../../../../redux/queries"
import { useAppDispatch } from "../../../../hooks"
import { pushToast } from "../../../../../redux/slices/notifications"

interface Props {
  data: CountyDetails
  onClose: () => void
}

const EffectiveTaxRateSchema = object({
  effective_tax_rate: number().required()
})

const TaxRateForm: FC<Props> = ({ data, onClose }) => {
  const dispatch = useAppDispatch()
  const formRef = useRef<FormikProps<CountyDetails>>(null)

  const [
    updateCounty,
    { isLoading, isError, isSuccess, data: updateResponse }
  ] = useUpdateCountyMutation()

  const onSubmit = () => {
    if (formRef.current?.values) {
      updateCounty(formRef.current.values)
    }
  }

  useEffect(() => {
    if (isError || updateResponse?.status === "error") {
      dispatch(
        pushToast({
          msg: "Failed to update effective tax rate.",
          theme: "danger",
          timeout: 3000
        })
      )
    }
  }, [dispatch, isError, updateResponse?.status])

  useEffect(() => {
    if (isSuccess && updateResponse?.status === "success") {
      dispatch(
        pushToast({
          msg: "Successfully updated effective tax rate.",
          timeout: 3000
        })
      )
    }
  }, [dispatch, isSuccess, updateResponse?.status])

  return (
    <Formik
      initialValues={data}
      onSubmit={onSubmit}
      innerRef={formRef}
      validationSchema={EffectiveTaxRateSchema}
      enableReinitialize
    >
      {({ values, isValid, dirty }) => (
        <div className="pt-4">
          <Input name="effective_tax_rate" label="Effective tax rate" />
          <div className="grid grid-cols-2 gap-4 py-4">
            <SectionCell label="State" value={values.state} />
            <SectionCell
              label="FIPS County code"
              value={values.fips_county_code}
            />
            <SectionCell
              label="FHA one unit limit"
              value={values.fha_one_unit_limit}
            />
            <SectionCell label="Two unit limit" value={values.two_unit_limit} />
            <SectionCell
              label="Three unit limit"
              value={values.three_unit_limit}
            />
            <SectionCell
              label="Four unit limit"
              value={values.four_unit_limit}
            />
          </div>
          <div className="pt-4 flex justify-end">
            <LightBtn className="mr-3" onClick={onClose}>
              Cancel
            </LightBtn>
            <PrimaryBtn
              onClick={onSubmit}
              disabled={!isValid || !dirty || isLoading}
            >
              Save Changes
            </PrimaryBtn>
          </div>
        </div>
      )}
    </Formik>
  )
}

export default TaxRateForm
