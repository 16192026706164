import { cloneElement } from "react"
import { Field, ErrorMessage, FormikValues } from "formik"
import Label from "./label"
import If from "../if"

type Props = {
  label: string
  subLabel?: string
  name: string
  type?: HTMLInputElement["type"]
  as?: string
  className?: string
  helpText?: string
  tooltipText?: string
  autoComplete?: string
  placeholder?: string
  beforeLabel?: string | JSX.Element
  beforeIcon?: JSX.Element
  afterIcon?: string | JSX.Element
  inputProps?: { [key: string]: any }
  showLabel?: boolean
  required?: boolean
  disabled?: boolean
  children?: any
}

const TYPE_DEFAULT = "text"
const CLASSNAME_DEFAULT = ""
const DEFAULT_SHOW_LABEL = true

const Input = ({
  showLabel = DEFAULT_SHOW_LABEL,
  label,
  subLabel,
  name,
  type = TYPE_DEFAULT,
  as,
  helpText,
  tooltipText,
  autoComplete,
  placeholder,
  beforeLabel,
  beforeIcon,
  afterIcon,
  required = false,
  className = CLASSNAME_DEFAULT,
  inputProps = {},
  disabled,
  children
}: Props) => {
  const disabledClassName = disabled ? "text-neutral-400" : ""
  const fieldClassName = `appearance-none block w-full px-3 py-2.5 border border-neutral-200 ${
    beforeLabel ? "rounded-none rounded-r-md" : "rounded-md"
  } ${
    beforeIcon ? "pl-10" : ""
  } placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500
    ${disabledClassName}`

  return (
    <div className={className}>
      <Label
        className={disabledClassName}
        name={name}
        showLabel={showLabel}
        label={label}
        subLabel={subLabel}
        required={required}
        helperText={tooltipText}
      />
      <div className="mt-1 relative flex rounded-md shadow-sm">
        {beforeLabel && (
          <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
            {beforeLabel}
          </span>
        )}
        {beforeIcon && (
          <div className="absolute inset-y-0 left-0 pl-3 py-2 flex items-center pointer-events-none">
            {beforeIcon}
          </div>
        )}
        <Field
          id={name}
          name={name}
          type={type}
          autoComplete={autoComplete}
          placeholder={placeholder}
          as={as}
          className={fieldClassName}
          {...inputProps}
          disabled={disabled}
        >
          {children
            ? (props: { field: FormikValues; meta: any; form: any }) =>
                cloneElement(children, { ...props, className: fieldClassName })
            : null}
        </Field>
        {afterIcon ? (
          <div className="absolute h-full inset-y-0 right-0 flex py-1.5 pr-1.5">
            {afterIcon}
          </div>
        ) : null}
      </div>

      <If
        condition={!!helpText}
        then={<p className="mt-2 text-sm text-neutral-400">{helpText}</p>}
      />

      <ErrorMessage
        name={name}
        component="p"
        className="mt-2 text-sm text-red-600"
      />
    </div>
  )
}

export default Input
