import RowOptions from "../../../table/row-options"
import { Pencil } from "lucide-react"
import { setPreviewModal } from "../../../../../redux/slices/integrations"
import { useGetQuickStatusQuery } from "../../../../../redux/queries"
import { useAppDispatch } from "../../../../hooks"
import If from "../../../if"
import Status from "../../../status"
import { Integration } from "../../../../../types"

type Props = {
  data: Integration
  onSelect?: (id: string) => void
}

const Card = ({ data, onSelect }: Props) => {
  const dispatch = useAppDispatch()

  const { title, type, app_key, id } = data

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: status, isLoading: isLoadingQuickStatus } =
    useGetQuickStatusQuery(
      {
        integrationID: id ?? ""
      },
      {
        skip: !id
      }
    )

  const StatusTag =
    status?.status === "active"
      ? Status.Active
      : status?.status === "idle"
      ? Status.Idle
      : Status.Initial

  const openEditPanel = () => {
    onSelect?.(id)
  }

  return (
    <div className="flex flex-col flex-1 justify-between">
      <div className="text-left px-4 pt-4 relative">
        <strong className="w-5/6 text-base block">{title}</strong>
        <span className="text-sm text-neutral-400 block">{type}</span>
        <If condition={typeof status !== "undefined"} then={<StatusTag />} />

        <RowOptions
          className="absolute top-4 right-4"
          actions={[{ label: "Edit", icon: Pencil, onClick: openEditPanel }]}
        />
      </div>
      <div className="flex mt-8 border-t-gray-200 border-t">
        <button
          type="button"
          className="text-base p-4 flex-1 border-r-gray-200 border-r text-primary-alt-400"
          onClick={() => {
            dispatch(
              setPreviewModal({
                isOpen: true,
                app_key: app_key
              })
            )
          }}
        >
          Preview
        </button>
        <button
          type="button"
          className="text-base p-4 flex-1 -ml-1 text-primary-alt-400"
          onClick={openEditPanel}
        >
          View details
        </button>
      </div>
    </div>
  )
}

export default Card
