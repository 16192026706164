import { createApi } from "@reduxjs/toolkit/dist/query/react"
import {
  ContactFormConfig,
  ContactFormDetails,
  FindContactFormsResponse
} from "../../types"
import {
  CONTACT_FORMS_ENDPOINT,
  CONTACT_FORM_ENDPOINT,
  RTK_SLICE_API_LIST
} from "../../common/constants"
import { baseQuery, getTags } from "../api"
import { getQueryString } from "../../common/utils"
import bbApi from "../../api"

const reducerPath = "contact-forms"

export const contactFormsApi = createApi({
  reducerPath,
  baseQuery,
  tagTypes: [reducerPath],
  endpoints: builder => ({
    findContactForms: builder.query({
      query: (brand_id: string) =>
        `${CONTACT_FORMS_ENDPOINT}?brand_id=${brand_id}`,
      transformResponse: (responseData: FindContactFormsResponse) => {
        return responseData.contact_forms ?? []
      },
      providesTags: result => getTags(reducerPath, "id", result)
    }),
    findContactFormDetails: builder.query({
      query: (params: { id: string }) =>
        `${CONTACT_FORM_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: ContactFormDetails) => {
        return responseData
      },
      providesTags: (result, error, arg) => [{ type: reducerPath, id: arg.id }]
    }),
    fetchContactFormFields: builder.query({
      query: () => CONTACT_FORM_ENDPOINT,
      transformResponse: (responseData: ContactFormConfig) => {
        return responseData
      }
    }),
    fetchDefaultContactFormConfig: builder.query({
      query: () => `${CONTACT_FORM_ENDPOINT}?id=`,
      transformResponse: (responseData: ContactFormConfig) => {
        return {
          ...responseData,
          is_default: responseData?.is_default?.toString()
        }
      }
    }),
    updateOrCreateContactFormConfig: builder.mutation({
      queryFn: async (payload: Partial<ContactFormDetails>) => {
        // Remove "id" field if it's empty which means it's CREATE action
        if (!payload.id) {
          delete payload.id
        }
        const data = await bbApi.contactForms.updateOrCreate(payload)
        return { data }
      },
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: arg.id },
        { type: reducerPath, id: RTK_SLICE_API_LIST }
      ]
    })
  })
})

export const {
  useFindContactFormsQuery,
  useFetchContactFormFieldsQuery,
  useFindContactFormDetailsQuery,
  useFetchDefaultContactFormConfigQuery,
  useUpdateOrCreateContactFormConfigMutation
} = contactFormsApi
