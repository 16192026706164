import { FC, useRef, useState } from "react"
import { Form } from "formik"
import ProfileSection from "./profile"
import AddressSection from "./address"
import SocialSection from "./social"
import IntegrationsSection from "./integrations"
import { Brand, LoFormTab, LoanOfficer } from "../../../../types"

type TabId = "profile" | "address" | "web-social" | "integrations"

interface Props {
  values: LoanOfficer
  brands: Brand[]
  tabsVisible: boolean
  setFormField: (name: string, value: string | number) => void
}

const ManageLoForm: FC<Props> = ({
  values,
  brands,
  tabsVisible,
  setFormField
}) => {
  const [currentTab, setCurrentTab] = useState<TabId>("profile")

  const onTabChange = (tabId: string) => setCurrentTab(tabId as TabId)

  const handleClickScroll = (tab: LoFormTab) => {
    if (tab.ref.current) {
      // 👇 Will scroll smoothly to the top of the next section
      tab.ref.current.scrollIntoView({ behavior: "smooth" })
      onTabChange(tab.id)
    }
  }

  const TABS: LoFormTab[] = [
    {
      id: "profile",
      label: "Profile",
      content: ProfileSection,
      ref: useRef<HTMLDivElement>(null)
    },
    {
      id: "address",
      label: "Address",
      content: AddressSection,
      ref: useRef<HTMLDivElement>(null)
    },
    {
      id: "web-social",
      label: "Web & Social",
      content: SocialSection,
      ref: useRef<HTMLDivElement>(null)
    },
    {
      id: "integrations",
      label: "Integrations",
      content: IntegrationsSection,
      ref: useRef<HTMLDivElement>(null)
    }
  ]

  return (
    <Form>
      <div className="relative">
        {tabsVisible && (
          <ul className="w-full flex gap-4 bg-white z-50 fixed top-19 border-b">
            {TABS.map(tab => (
              <li key={tab.id} className={`p-6 `}>
                <div
                  onClick={() => handleClickScroll(tab)}
                  className={`${
                    currentTab === tab.id
                      ? "text-primary-500"
                      : "text-primary-alt-400"
                  } cursor-pointer`}
                >
                  {tab.label}
                </div>
              </li>
            ))}
          </ul>
        )}
        <div>
          {TABS.map(tab => (
            <div key={tab.id} ref={tab.ref} className="pt-16">
              <tab.content
                values={values}
                title={tab.label}
                brands={brands ?? []}
                setFormField={setFormField}
              />
            </div>
          ))}
        </div>
      </div>
    </Form>
  )
}

export default ManageLoForm
