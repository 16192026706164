import { ChangeEvent, FC, useState } from "react"
import { Button, PrimaryBtn } from "../../buttons"

interface Props {
  inEditMode: boolean
  toggleEdit: () => void
  onUpdatePassword: (newPassword: string) => void
}

const PasswordForm: FC<Props> = ({
  inEditMode,
  toggleEdit,
  onUpdatePassword
}) => {
  const [password, setPassword] = useState<string>("")
  const [confirmPassword, setConfirmPassword] = useState<string>("")

  const canUpdate = password && password === confirmPassword

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) =>
    setPassword(e.target.value)
  const handleChangeConfirmPassword = (e: ChangeEvent<HTMLInputElement>) =>
    setConfirmPassword(e.target.value)

  const onSubmitNewPassword = () => canUpdate && onUpdatePassword(password)

  return (
    <div className="pt-12">
      <div className="flex items-center justify-between pb-4 border-b border-neutral-200">
        <div className="text-lg font-bold">Account Password</div>
        <Button className="border-0" onClick={toggleEdit}>
          <div className="font-bold text-primary-500">
            {inEditMode ? "Cancel" : "Change"}
          </div>
        </Button>
      </div>
      <div className="grid grid-cols-2 gap-4 pt-6">
        {inEditMode ? (
          <>
            <div>
              <div className="text-neutral-900 pb-2">New password</div>
              <input
                className="w-full border border-neutral-200 px-3 py-2 rounded-md"
                name="password"
                type="password"
                placeholder="Enter new password"
                value={password}
                onChange={handleChangePassword}
              />
            </div>
            <div>
              <div className="text-neutral-900 pb-2">Re-enter new password</div>
              <input
                className="w-full border border-neutral-200 px-3 py-2 rounded-md"
                name="password"
                type="password"
                placeholder="Re-enter new password"
                value={confirmPassword}
                onChange={handleChangeConfirmPassword}
              />
            </div>
            <div>
              <PrimaryBtn onClick={onSubmitNewPassword} disabled={!canUpdate}>
                Change password
              </PrimaryBtn>
            </div>
          </>
        ) : (
          <div>
            <div className="text-neutral-400">Password</div>
            <div className="text-neutral-900">● ● ● ● ● ● ● ● </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default PasswordForm
