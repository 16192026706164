import { FC, memo } from "react"

interface ProgressBarProps {
  count: number
  maxCount: number
  countLabel?: string
  label?: string
  netPercentage?: string
  isPositive?: boolean
}

const ProgressBar: FC<ProgressBarProps> = memo(
  ({ count, maxCount, countLabel = "", label, netPercentage, isPositive }) => {
    const widthPercentage = (count / maxCount) * 100
    return (
      <div className="flex flex-col">
        {label && <div className="text-sm mb-2.5 line-clamp-1">{label}</div>}
        <div className="flex items-center">
          <div
            className="bg-purple-400 h-[18px] rounded"
            style={{ width: `${widthPercentage}%` }}
          ></div>
          <div className="font-bold ms-3 leading-[22px] shrink-0">
            {`${count?.toLocaleString()}${countLabel}`}
          </div>
          {netPercentage && (
            <div
              className={`text-sm ms-2 leading-[22px] ${
                isPositive ? "text-green-500" : "text-rose-500"
              }`}
            >
              {netPercentage}
            </div>
          )}
        </div>
      </div>
    )
  }
)

export default ProgressBar
