import { MouseEvent, ReactNode } from "react"
import { LucideIcon, MoreHorizontal, Trash2 } from "lucide-react"
import Menu from "../../menu"
import MenuItem from "../../menu/item"

interface ActionProps {
  label: ReactNode
  icon?: LucideIcon
  onClick: (e: MouseEvent) => void
  hidden?: boolean
  className?: string
}

interface Props {
  className?: string
  deleteAction?: (event: MouseEvent) => void
  actions: (ActionProps | null)[]
}

const RowOptions = ({ className = "", actions, deleteAction }: Props) => (
  <div className={className}>
    <Menu
      label=""
      icon={<MoreHorizontal className="text-primary-alt-400" size={20} />}
    >
      {actions.map(action =>
        action ? (
          <MenuItem
            key={action.label?.toString() ?? "default-key"}
            {...action}
            icon={action.icon ? <action.icon size={16} /> : null}
          />
        ) : null
      )}
      {deleteAction ? (
        <MenuItem
          className="focus:bg-red-400 border-transparent group flex w-full items-center rounded-md px-2 py-2 text-sm text-red-500 border"
          label="Delete"
          icon={<Trash2 size={16} />}
          onClick={deleteAction}
        />
      ) : null}
    </Menu>
  </div>
)

export default RowOptions
