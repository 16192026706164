import { useCallback, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Eye, Plus, MoreHorizontal, Code2 } from "lucide-react"
import { useAppDispatch, useAppSelector } from "../../common/hooks"
import {
  LightBtn,
  PageHeader,
  PrimaryBtn,
  APIKeyModal,
  ApprovedUrlsModal
} from "../../common/ui"
import { IntegrationsTable } from "../../common/ui/integrations"
import {
  setIsSubmitting,
  setPreviewModal
} from "../../redux/slices/integrations"
import { selectUserDetails, selectUserRoles } from "../../redux/slices/auth"
import { setVisiblePanel } from "../../redux/slices/app"
import { ALLOWED_ROLES_BY_ROUTE } from "../../common/constants"
import { RequireRole } from "../../common/validation"
import PreviewModal from "../../common/ui/preview-modal"
import ManageIntegration from "../../common/ui/integrations/manage"
import Dropdown from "../../common/ui/dropdown"
import {
  checkIsAdmin,
  checkIsSuperAdmin,
  getOrgBrandsIDs
} from "../../common/utils"
import {
  useFetchIntegrationsQuery,
  useGetIntegrationDetailsQuery,
  useGetStatusQuery,
  useFetchOrgDetailsQuery
} from "../../redux/queries"
import { DropdownItemProps } from "../../types"

const IntegrationsPage = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const userRoles = useAppSelector(selectUserRoles)
  const isAdmin = checkIsAdmin(userRoles)
  const isSuperAdmin = checkIsSuperAdmin(userRoles)
  const user = useAppSelector(selectUserDetails)

  const { data: orgDetails } = useFetchOrgDetailsQuery(
    { id: user?.org?.id ?? "" },
    { skip: !user?.org?.id }
  )
  const brandsIDs = getOrgBrandsIDs(orgDetails)
  const { data: integrations, isLoading: isLoadingIntegrations } =
    useFetchIntegrationsQuery(
      isSuperAdmin
        ? { limit: 999 }
        : { org_id: user?.org?.id, brand_id: brandsIDs, limit: 999 }
    )

  const { data: integration, isLoading: isLoadingIntegration } =
    useGetIntegrationDetailsQuery(id ?? "", {
      skip: !id
    })

  // Get the statuses of the integration
  const { data: statusData = [], isLoading: isLoadingStatus } =
    useGetStatusQuery(
      {
        integrationID: integration?.id ?? ""
      },
      {
        skip: !integration?.id
      }
    )

  const [apiModalOpen, setAPIModalOpen] = useState(false)
  const [approvedUrlsModalOpen, setApprovedUrlsModalOpen] = useState(false)

  const handleSelectIntegration = (id: string) =>
    navigate(`/integrations/${id}`)

  const clearSelectedIntegration = useCallback(() => {
    navigate("/integrations")
    dispatch(setIsSubmitting(false))
  }, [dispatch, navigate])

  const onClickNew = () => {
    dispatch(setVisiblePanel("integration/new"))
  }

  const onClosePreviewModal = () => {
    dispatch(
      setPreviewModal({
        isOpen: false,
        appKey: ""
      })
    )
  }

  return (
    <RequireRole allowedRoles={ALLOWED_ROLES_BY_ROUTE.integrations}>
      <>
        <div className="min-h-full p-6 bg-white">
          <PageHeader title="Integrations">
            {isAdmin ? (
              <>
                <LightBtn
                  className="ml-3 hidden sm:flex"
                  onClick={() => {
                    setApprovedUrlsModalOpen(true)
                  }}
                >
                  Approved URL'S
                </LightBtn>
                <LightBtn
                  className="ml-3 hidden sm:flex"
                  onClick={() => {
                    setAPIModalOpen(true)
                  }}
                >
                  <Eye size={16} className="mr-1" /> API Key
                </LightBtn>
                <PrimaryBtn className="ml-3" onClick={onClickNew}>
                  <Plus size={16} className="mr-1" /> New&nbsp;
                  <span className="hidden sm:flex pl-1">Integration</span>
                </PrimaryBtn>
                <div
                  className="flex sm:hidden ml-2 py-3 px-3 rounded-md"
                  style={{ border: "1.5px solid #000000" }}
                >
                  <Dropdown
                    items={[
                      {
                        name: "Approved URL'S",
                        onClick: (item: DropdownItemProps) =>
                          setApprovedUrlsModalOpen(true)
                      },
                      {
                        name: "API Key",
                        onClick: (item: DropdownItemProps) =>
                          setAPIModalOpen(true)
                      }
                    ]}
                    menuBtn={
                      <>
                        <MoreHorizontal size={20} className="outline-none" />
                      </>
                    }
                  />
                </div>
              </>
            ) : null}
          </PageHeader>

          <IntegrationsTable
            integrations={integrations}
            isLoading={isLoadingIntegrations}
            onSelect={handleSelectIntegration}
            emptyStateProps={{
              heading: "Add your first integration",
              subHeading: "",
              icon: Code2,
              isActionButton: true,
              buttonText: "Add new integration",
              onClick: onClickNew
            }}
          />
        </div>
        <APIKeyModal
          show={apiModalOpen}
          toggleModal={() => setAPIModalOpen(false)}
        />
        <ApprovedUrlsModal
          show={approvedUrlsModalOpen}
          toggleModal={setApprovedUrlsModalOpen}
        />
        <PreviewModal onClose={onClosePreviewModal} />

        <ManageIntegration
          open={!!id}
          integration={integration}
          statusData={statusData}
          isLoadingStatus={isLoadingStatus}
          isLoadingIntegration={isLoadingIntegration}
          onClose={clearSelectedIntegration}
        />
      </>
    </RequireRole>
  )
}

export default IntegrationsPage
