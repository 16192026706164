import { FC, useMemo } from "react"
import DndList from "../../../../../../dnd-list"
import {
  IStep,
  LeadworkflowDetails,
  LeadworkflowStep,
  LeadworkflowStepType
} from "../../../../../../../../types"
import Badge from "../../../../../../badge"
import { getLeadworkflowStep } from "../../../../../../../utils"

interface Props {
  values: LeadworkflowDetails
  setConfigField: (name: string, value: any) => void
}

const NEW_STEP_LIST: { id: LeadworkflowStepType; label: string }[] = [
  {
    id: "buttons",
    label: "Buttons"
  },
  {
    id: "list",
    label: "List"
  },
  {
    id: "slider",
    label: "Slider"
  },
  {
    id: "location_autocomplete",
    label: "Location"
  },
  {
    id: "lo_select",
    label: "LO Select"
  }
]

const LeadworkflowSteps: FC<Props> = ({ values, setConfigField }) => {
  const steps: IStep[] = useMemo(() => {
    if (!values.config_json.steps) return []
    return (values.config_json.steps as LeadworkflowStep[]).map(
      (item, idx) => ({
        id: idx.toString(),
        label: item.id
      })
    )
  }, [values.config_json.steps])

  const onChangeSteps = (value: IStep[]) => {
    const newSteps = value.map(
      val => (values.config_json.steps as LeadworkflowStep[])[parseInt(val.id)]
    )
    setConfigField("steps", newSteps)
  }

  const onAddNewStep = (stepType: LeadworkflowStepType) => {
    setConfigField("steps", [
      ...(values.config_json.steps as LeadworkflowStep[]),
      getLeadworkflowStep(stepType)
    ])
  }

  return (
    <div>
      <div>
        <span className="text-neutral-400 text-sm mr-4">Add new step:</span>
        {NEW_STEP_LIST.map(item => (
          <Badge.Info key={item.id} className="mr-2 px-4 py-2">
            <span
              className="cursor-pointer select-none"
              onClick={() => onAddNewStep(item.id)}
            >
              {item.label}
            </span>
          </Badge.Info>
        ))}
      </div>

      <DndList items={steps} direction="vertical" onChange={onChangeSteps} />
    </div>
  )
}

export default LeadworkflowSteps
