type Props = {
  children: React.ReactNode
  className?: string
}

const Card = ({ children, className = "" }: Props) => (
  <div
    className={`${className} bg-white px-4 shadow rounded-lg sm:px-8 py-4 sm:py-8`}
  >
    {children}
  </div>
)

export default Card
