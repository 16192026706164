import { ReactNode } from "react"

interface Props {
  children: ReactNode
  className?: string
}

const HighlightedText = ({ children, className = "" }: Props) => (
  <span
    className={`md:text-primary-alt-400 font-bold md:text-lg md:border-dashed md:border-b md:border-stone-300 ${className}`}
  >
    {children}
  </span>
)

export default HighlightedText
