import { FC, useEffect, useState } from "react"
import BBDialog from "../../dialog"
import { ManageEngagementPanel } from "../../engagements"

interface Props {
  brandId?: string
  leadId?: string
  onCancel: () => void
}

const PushToEngagements: FC<Props> = ({ brandId, leadId, onCancel }) => {
  const [createEngagementModalVisible, setCreateEngagementModalVisible] =
    useState<boolean>(false)

  const closeCreateEngagementModal = () =>
    setCreateEngagementModalVisible(false)
  const cancelCreateEngagementModal = () => {
    closeCreateEngagementModal()
    onCancel()
  }

  const openCreateEngagementPanel = () => {
    closeCreateEngagementModal()
  }

  const closeCreateEngagementPanel = () => {
    onCancel()
  }

  useEffect(() => {
    if (leadId) {
      setCreateEngagementModalVisible(true)
    }
  }, [leadId])

  return (
    <>
      <BBDialog
        open={createEngagementModalVisible}
        title="Create engagement from a lead"
        body="A new Engagement will be created from this lead in order to enroll this lead to rate alerts, create dashboards and to download loan estimates. "
        theme="info"
        okLabel="Yes, proceed"
        cancelLabel="Cancel"
        onOk={openCreateEngagementPanel}
        onClose={cancelCreateEngagementModal}
        onCancel={cancelCreateEngagementModal}
      />

      <ManageEngagementPanel
        brandId={brandId}
        open={!!leadId && !createEngagementModalVisible}
        leadId={leadId}
        onClose={closeCreateEngagementPanel}
      />
    </>
  )
}

export default PushToEngagements
