import { FC } from "react"
import { RateflowLog } from "../../../../types"
import DataItem from "./data-item"
import { formatDateTimeFromTimestamp } from "../../../utils"

interface Props {
  data?: RateflowLog
}

const OtherDetails: FC<Props> = ({ data }) => (
  <>
    <div className="pt-12 text-neutral-900 font-bold text-xl">
      Other details
    </div>

    <div className="grid grid-cols-2 gap-4">
      <DataItem
        label="Updated"
        value={
          data?.timestamp
            ? formatDateTimeFromTimestamp(
                data.timestamp,
                "dd/MM/yyyy 'at' hh:mm:ss a"
              )
            : ""
        }
      />
      <DataItem label="OB search ID" value={data?.ob_profile_id} />
    </div>
    <div className="grid grid-cols-2 gap-4">
      <DataItem
        label="Property state"
        value={data?.full_request.propertyState}
      />
      <DataItem
        label="Property county"
        value={data?.full_request.propertyCounty}
      />
    </div>
    <div className="grid grid-cols-2 gap-4">
      <DataItem label="Property type" value={data?.type} />

      <DataItem
        label="Credit score"
        value={data?.full_request.bb_request?.credit_score ?? "N/A"}
      />
    </div>
  </>
)

export default OtherDetails
