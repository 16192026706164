import { useRef, useState, useEffect, ComponentType, FC } from "react"

const withLazyLoad = <P extends object>(
  WrappedComponent: ComponentType<P>
): FC<P> => {
  const LazyLoadComponent: FC<P> = props => {
    const [isVisible, setIsVisible] = useState<boolean>(false)
    const ref = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              setIsVisible(true)
              observer.disconnect()
            }
          })
        },
        {
          rootMargin: "0px",
          threshold: 0.1
        }
      )

      const currentRef = ref.current

      if (currentRef) {
        observer.observe(currentRef)
      }

      return () => {
        if (currentRef) {
          observer.unobserve(currentRef)
        }
      }
    }, [])

    return (
      <div ref={ref} className="flex w-full">
        {isVisible && <WrappedComponent {...props} />}
      </div>
    )
  }

  return LazyLoadComponent
}

export default withLazyLoad
