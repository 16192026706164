import { FC } from "react"
import { SubscriptionDetails } from "../../../../../../../types"
import SectionCell from "../../../../../section/cell"

interface Props {
  data: SubscriptionDetails
}

const PropertyLocation: FC<Props> = ({ data }) => {
  const {
    quote_info: {
      location: { state, city, county, street_address, zipcode }
    }
  } = data
  return (
    <div className="grid sm:grid-cols-3 gap-4">
      <SectionCell label="Street address" value={street_address} />
      <SectionCell label="City" value={city} />
      <SectionCell label="State" value={state} />
      <SectionCell label="County" value={county} />
      <SectionCell label="Zipcode" value={zipcode} />
    </div>
  )
}

export default PropertyLocation
