import { FC, useMemo, useState } from "react"
import Autocomplete from "../form/autocomplete"
import { useSearchLoanOfficerQuery } from "../../../redux/queries"
import { LoSearchParams } from "../../../types"
import { checkIsAdmin, convertLoToAutocompleteOption } from "../../utils"

interface Props {
  searchParams: LoSearchParams
  selected?: string
  label?: string
  direction?: "vertical" | "horizontal"
  onlyAdmins?: boolean
  onChange: (val: string) => void
}

const LoanOfficerAutocomplete: FC<Props> = ({
  searchParams,
  label = "Loan officer:",
  selected,
  direction = "horizontal",
  onlyAdmins,
  onChange
}) => {
  const [query, setQuery] = useState<string>("")

  const { data, isFetching } = useSearchLoanOfficerQuery({
    fullname: query,
    limit: "10",
    ...searchParams
  })

  const options = useMemo(() => {
    if (onlyAdmins) {
      return (
        data
          ?.filter(lo => checkIsAdmin(lo.roles ?? []))
          ?.map(convertLoToAutocompleteOption) ?? []
      )
    } else {
      return data?.map(convertLoToAutocompleteOption) ?? []
    }
  }, [data, onlyAdmins])

  const selectedOption = options.find(opt => opt.id === selected)

  const { data: selectedLo, isFetching: isFetchingSelectedLo } =
    useSearchLoanOfficerQuery(
      {
        loid: selected ?? ""
      },
      { skip: !selected || !!selectedOption }
    )

  return (
    <div className="max-w-md">
      <Autocomplete
        label={label}
        name="loan_officer"
        placeholder="Select loan officer"
        value={
          selectedOption ??
          (selectedLo?.[0]
            ? convertLoToAutocompleteOption(selectedLo?.[0])
            : undefined)
        }
        query={query}
        options={options}
        direction={direction}
        onChange={option => onChange(option.id)}
        onChangeQuery={setQuery}
        isLoading={isFetching || isFetchingSelectedLo}
      />
    </div>
  )
}

export default LoanOfficerAutocomplete
