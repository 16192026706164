import { FC, useEffect, useRef } from "react"
import { Formik, FormikProps } from "formik"
import { object, string } from "yup"
import { Trash2 } from "lucide-react"
import SidePanel from "../../../side-panel"
import ManageLeadworkflowFormContent from "./form-content"
import { RateTableConfigDetails } from "../../../../../types"
import {
  useDeleteRateTableConfigMutation,
  useFetchDefaultRateTableConfigQuery,
  useUpdateOrCreateRateTableConfigMutation,
  useFetchAllBrandsQuery
} from "../../../../../redux/queries"
import { useAppDispatch } from "../../../../hooks"
import { pushToast } from "../../../../../redux/slices/notifications"

interface Props {
  open: boolean
  data?: RateTableConfigDetails
  onClose: () => void
}

const RateTableConfigSchema = object({
  name: string().required().min(1),
  is_default: string().required(),
  details: object().required(),
  brand_id: string().required().min(1)
})

const ManageRateTableConfig: FC<Props> = ({ open, data, onClose }) => {
  const dispatch = useAppDispatch()

  const [
    updateOrCreateRateTableConfig,
    {
      isSuccess,
      isError,
      data: updateOrCreateResponse,
      reset: resetUpdateOrCreateResponse
    }
  ] = useUpdateOrCreateRateTableConfigMutation()
  const [
    deleteRateTableConfig,
    {
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
      data: deleteResponse,
      reset: resetDeleteResponse
    }
  ] = useDeleteRateTableConfigMutation()

  const { data: brands } = useFetchAllBrandsQuery({ limit: 999 })
  const { data: defaultConfig } = useFetchDefaultRateTableConfigQuery({})

  const formRef = useRef<FormikProps<RateTableConfigDetails>>(null)

  const initialValues: RateTableConfigDetails = data ?? {
    name: "",
    id: "",
    brand_id: "",
    ...(defaultConfig || { is_default: "0", details: "{}" })
  }

  const isCreate = !data?.id

  const setFormField = (name: string, val: any) => {
    formRef.current?.setFieldValue(name, val)
    formRef.current?.setFieldTouched(name, true)
  }

  const onSubmit = () => {
    if (formRef.current?.values) {
      updateOrCreateRateTableConfig(formRef.current.values)
    }
  }

  const onDelete = () => {
    if (data?.id) {
      deleteRateTableConfig(data.id)
    }
  }

  useEffect(() => {
    if (isSuccess && updateOrCreateResponse?.status === "success") {
      dispatch(
        pushToast({
          msg: `Successfully ${
            isCreate ? "created" : "updated"
          } rate table config`,
          theme: "success",
          timeout: 3000
        })
      )
      resetUpdateOrCreateResponse()
      formRef.current?.resetForm()
      onClose()
    }
  }, [
    dispatch,
    isCreate,
    isSuccess,
    onClose,
    resetUpdateOrCreateResponse,
    updateOrCreateResponse?.status
  ])

  useEffect(() => {
    if (isError || updateOrCreateResponse?.status === "error") {
      dispatch(
        pushToast({
          msg: `Failed to ${isCreate ? "create" : "update"} rate table config`,
          theme: "danger",
          timeout: 3000
        })
      )
      resetUpdateOrCreateResponse()
    }
  }, [
    dispatch,
    isCreate,
    isError,
    resetUpdateOrCreateResponse,
    updateOrCreateResponse?.status
  ])

  useEffect(() => {
    if (isDeleteSuccess && deleteResponse?.status === "success") {
      dispatch(
        pushToast({
          msg: "Successfully deleted rate table config",
          theme: "success",
          timeout: 3000
        })
      )
      resetDeleteResponse()
      formRef.current?.resetForm()
      onClose()
    }
  }, [
    deleteResponse?.status,
    dispatch,
    isDeleteSuccess,
    onClose,
    resetDeleteResponse
  ])

  useEffect(() => {
    if (isDeleteError || deleteResponse?.status === "error") {
      dispatch(
        pushToast({
          msg: "Failed to delete rate table config",
          theme: "danger",
          timeout: 3000
        })
      )
      resetDeleteResponse()
    }
  }, [deleteResponse?.status, dispatch, isDeleteError, resetDeleteResponse])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      innerRef={formRef}
      validationSchema={RateTableConfigSchema}
      enableReinitialize
    >
      {({ values, isValid, dirty }) => {
        return (
          <SidePanel
            title={isCreate ? "New rate table" : `Rate table - ${data?.name}`}
            open={open}
            onClose={onClose}
            actions={[
              {
                label: "Delete",
                icon: <Trash2 size={16} />,
                type: "secondary",
                onClick: onDelete,
                hidden: isCreate,
                position: "left"
              },
              {
                label: "Cancel",
                type: "secondary",
                onClick: onClose
              },
              {
                label: isCreate ? "Create" : "Save Changes",
                type: "primary",
                onClick: onSubmit,
                disabled: !(isValid && dirty)
              }
            ]}
          >
            <ManageLeadworkflowFormContent
              values={values}
              brands={brands ?? []}
              setFormField={setFormField}
            />
          </SidePanel>
        )
      }}
    </Formik>
  )
}

export default ManageRateTableConfig
