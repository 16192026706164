import { FC } from "react"
import { YelpReview, Reviews } from "../../../types"
import ImgYelpLogo from "../../../assets/icons/yelp_logo.svg"
import ImgStar from "../../../assets/icons/star.svg"
import If from "../if"

interface Props {
  reviews?: Reviews
}

const YelpReviewsList: FC<Props> = ({ reviews }) => {
  return (
    <div className="pb-6">
      <div className="text-lg font-bold">Yelp reviews</div>

      <div className="py-4 flex items-center">
        <div className="pr-3">
          <img src={ImgYelpLogo} alt="Yelp Logo" />
        </div>
      </div>

      <If
        condition={!!reviews?.reviews?.length}
        then={reviews?.reviews.map((review, idx) => {
          const YelpReview = review as YelpReview
          return (
            <div key={idx} className="py-4">
              <div className="flex items-center leading-5">
                <div className="w-5 h-5">
                  <img className="h-full" src={ImgStar} alt="Star" />
                </div>
                <div className="font-bold pl-2">{YelpReview.rating}</div>
                <div className="text-neutral-300">
                  <span className="pl-3 pr-2">.</span>
                  {YelpReview.user.name}
                </div>
              </div>
              <div className="pt-4">{YelpReview.text}</div>
            </div>
          )
        })}
        else={<div className="py-4">-</div>}
      />
    </div>
  )
}

export default YelpReviewsList
