import { Form, Formik } from "formik"
import { FC } from "react"
import * as Yup from "yup"
import { useGetMonitorQuery } from "../../../../../redux/queries"
import {
  updateMonitor,
  createMonitor,
  selectMonitorID
} from "../../../../../redux/slices/integrations"
import { Integration, Monitor } from "../../../../../types"
import { REGEX_URL_WILDCARD } from "../../../../constants"
import { useAppDispatch, useAppSelector } from "../../../../hooks"
import Input from "../../../form/input"
import If from "../../../if"
import { useFormMonitorRef, useShowMonitorForm } from "../../manage"
import Entries from "./entries"

const requiredPositiveNumber = Yup.number().required().positive().integer()
const optionalPositiveNumber = Yup.number().optional().positive().integer()
const optionalNumber = Yup.number().optional().integer()

const MonitorSchema = Yup.object().shape({
  url: Yup.string()
    .test("url", "Invalid URL", value => {
      if (!value) {
        return true
      } else {
        return REGEX_URL_WILDCARD.test(value)
      }
    })
    .required("Required"),
  width: requiredPositiveNumber,
  height: requiredPositiveNumber,
  delay: optionalPositiveNumber,
  offset: optionalNumber
})

interface Props {
  integration?: Integration
}

const MonitorComponent: FC<Props> = ({ integration }) => {
  const dispatch = useAppDispatch()
  const formRef = useFormMonitorRef()
  const showForm = useShowMonitorForm()

  const storedMonitorId = useAppSelector(selectMonitorID)

  const { data: monitorData, isFetching: isLoadingMonitor } =
    useGetMonitorQuery(integration?.app_key ?? "", {
      skip: !integration?.app_key
    })

  const monitorValues = monitorData?.monitor.config
  const monitorEntries = monitorData?.entries
  const monitorID = monitorData?.monitor.id || storedMonitorId

  const onSubmit = (values: Monitor) => {
    let action = createMonitor
    if (monitorID !== 0) {
      action = updateMonitor
    }
    dispatch(action({ monitor: values, appKey: integration?.app_key ?? "" }))
  }

  const initialValues: Monitor = {
    ...monitorValues!
  }

  return (
    <If
      isLoading={isLoadingMonitor}
      condition={!!monitorEntries && monitorEntries.length > 0 && !showForm}
      then={<Entries monitorEntries={monitorEntries} />}
      elseCondition={showForm && !isLoadingMonitor}
      else={
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          innerRef={formRef}
          validationSchema={MonitorSchema}
          enableReinitialize
        >
          <Form>
            <div className="pt-6">
              <Input
                label="URL"
                name="url"
                beforeLabel="https://"
                placeholder="Ex. google.com/*"
              />
            </div>
            <div className="pt-6">
              <Input
                inputProps={{ pattern: "[0-9]*" }}
                type="number"
                label="Delay"
                name="delay"
                placeholder="Ex. 2000"
              />
            </div>

            <div className="grid gap-y-6 gap-x-4 grid-cols-12 pt-8">
              <div className="col-span-6">
                <Input
                  inputProps={{ pattern: "[0-9]*", required: true }}
                  type="number"
                  label="Width"
                  name="width"
                  placeholder="Ex. 960"
                />
              </div>
              <div className="col-span-6">
                <Input
                  inputProps={{ pattern: "[0-9]*", required: true }}
                  type="number"
                  label="Height"
                  name="height"
                  placeholder="Ex. 540"
                />
              </div>
            </div>

            <div className="pt-6">
              <Input
                inputProps={{ pattern: "[0-9]*" }}
                type="number"
                label="Offset"
                name="offset"
                placeholder="Ex. 200"
              />
            </div>
          </Form>
        </Formik>
      }
      default={
        <div className="h-full flex items-center justify-center text-xl">
          No monitor entries yet
        </div>
      }
    />
  )
}

export default MonitorComponent
