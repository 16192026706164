import { FC } from "react"

import If from "../../../../if"
import FourColumnPerformanceSkeleton from "../../../../skeletons/four-column-performance"
import ProgressBar from "../../../../progress-bar"
import { UTMParametersStats } from "../../../../../../types"

interface UTMParametersSectionProps {
  label: string
  data: UTMParametersStats[]
  isFetching: boolean
  maxCount: number
}

const UTMParametersSection: FC<UTMParametersSectionProps> = ({
  label,
  data,
  isFetching,
  maxCount
}) => (
  <div className="col-span-12 md:col-span-4">
    <div className="text-sm text-gray-400 font-medium leading-snug pb-2">
      {label}
    </div>
    <If
      condition={isFetching}
      then={<FourColumnPerformanceSkeleton className="mt-px pt-11" />}
      else={
        <If
          condition={data.length === 0}
          then={
            <div className="relative flex-1 h-72">
              <div className="w-full absolute top-10 text-sm z-[1]">
                No data yet
              </div>
            </div>
          }
          else={
            <div className="h-72 overflow-y-auto scroll-smooth">
              <div className="w-full grid gap-y-6 py-2">
                {data.map((item, index) => (
                  <ProgressBar
                    key={`utm-section-progress-${index}`}
                    count={item.lead_count}
                    maxCount={maxCount}
                    countLabel={item.lead_count > 1 ? " Leads" : " Lead"}
                    label={item.name}
                  />
                ))}
              </div>
            </div>
          }
        />
      }
    />
  </div>
)

export default UTMParametersSection
