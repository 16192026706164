import { FC } from "react"
import { RateflowLog } from "../../../../types"
import DataItem from "./data-item"
import { formatCurrency, formatNumber } from "../../../utils"

interface Props {
  data?: RateflowLog
}

const LoanDetails: FC<Props> = ({ data }) => (
  <>
    <div className="pt-12 text-neutral-900 font-bold text-xl">Loan details</div>
    {data?.cards?.length ? (
      <>
        <div className="grid grid-cols-2 gap-4">
          <DataItem label="Loan product" value={data.cards[0].productName} />
          <DataItem label="Loan term" value={data.cards[0].loanTerm} />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <DataItem label="Lock period" value={data.cards[0].lockPeriod} />
          <DataItem
            label="Interest rate"
            value={formatNumber(data.cards[0].rate, {
              maximumFractionDigits: 3,
              minimumFractionDigits: 2
            })}
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <DataItem label="APR" value={data.cards[0].apr} />
          <DataItem
            label="Closing cost"
            value={formatCurrency(data.cards[0].closingCost)}
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <DataItem
            label="Discount"
            value={formatCurrency(data.cards[0].discount)}
          />
          <DataItem label="Investor" value={data.cards[0].investor} />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <DataItem label="Actual price" value={data.cards[0].price} />
          <DataItem
            label="Target price"
            value={data.full_request.targetPrice}
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <DataItem
            label="Sample list price"
            value={
              data.full_request?.bb_request?.list_price
                ? formatCurrency(data.full_request?.bb_request?.list_price)
                : formatCurrency(
                    data.full_request.loan_amount +
                      data.full_request.downPayment
                  )
            }
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <DataItem
            label="Loan amount"
            value={
              data.full_request.bb_request?.loan_amount
                ? formatCurrency(data.full_request.bb_request?.loan_amount)
                : "N/A"
            }
          />
          <DataItem label="Loan type" value={data.cards[0].loanType} />
        </div>
      </>
    ) : (
      <div className="pt-6">No loan details found.</div>
    )}
  </>
)

export default LoanDetails
