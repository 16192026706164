import { QueryStatus } from "@reduxjs/toolkit/dist/query"
import {
  useLazyFetchLoanOfficersQuery,
  useLazyFetchSubscriptionDetailsQuery
} from "../../redux/queries"
import {
  CreateSubscriptionPayload,
  LoanOfficer,
  SubscriptionDetails
} from "../../types"

export const useValidateEmailAndNMLS = () => {
  const [lazyFetchLoanOfficers] = useLazyFetchLoanOfficersQuery()

  const [lazyFetchSubscriptionDetailsQuery] =
    useLazyFetchSubscriptionDetailsQuery()

  const validateLO = async (values: LoanOfficer) => {
    const errors: Partial<LoanOfficer> = {}

    if (values.nmls) {
      const { data, status } = await lazyFetchLoanOfficers({
        nmls: values.nmls
      })

      if (
        status === QueryStatus.fulfilled &&
        data?.los.length > 0 &&
        data?.los[0].loid !== values.uid &&
        data?.los[0].loid !== values.loid
      ) {
        errors.nmls = `This NMLS is already in use by ${data.los[0].name} (${data.los[0].bank})`
        return errors
      }
    }

    if (values.email) {
      console.log("values.email", values.email)
      const { data, status } = await lazyFetchLoanOfficers({
        email: values.email
      })

      if (
        status === QueryStatus.fulfilled &&
        data?.los.length &&
        data.los[0].uid !== values.uid &&
        data.los[0].uid !== values.loid
      ) {
        errors.email = `This email is already in use by ${data.los[0].name} (${data.los[0].bank})`
        return errors
      }
    }

    return errors
  }

  const validateSubscriptionEmail = async (
    values: CreateSubscriptionPayload | SubscriptionDetails,
    brand_id: string
  ) => {
    const errors: Partial<CreateSubscriptionPayload> = {}

    const emailValue = values.email.trim()

    if (emailValue) {
      const { data, status } = await lazyFetchSubscriptionDetailsQuery({
        email: emailValue,
        brand_id
      })
      console.log("data", data, values.lead_id)
      if (
        status === QueryStatus.fulfilled &&
        data?.lead_id &&
        data?.lead_id !== values.lead_id
      ) {
        errors.email = `This email is already in use by ${data.first_name} ${data.last_name}`
        return errors
      }
    }

    return errors
  }

  return { validateLO, validateSubscriptionEmail }
}
