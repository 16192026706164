import { FC, Fragment, useEffect, useMemo, useState } from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"

import If from "../../../if"
import GraphSkeleton from "../../../skeletons/graph"
import RateTableInfo from "./info"
import { useFetchRateTableUsageQuery } from "../../../../../redux/queries"
import {
  useRateTableInfos,
  useRateUsageHighchartsOptions
} from "../../../../hooks"
import { getMultiLineChartOptions } from "../../../chart-with-stats/options"
import "./scss/highcharts-tooltip.scss"

interface RateTableUsageMetricsProps {
  title: string
  range: number
  orgId: string | undefined
  loid: string | undefined
}

const RateTableUsageMetrics: FC<RateTableUsageMetricsProps> = ({
  title,
  range,
  orgId,
  loid
}) => {
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const dateFormat = "yyyy-MM-dd"
  const labelDateFormat = "LLL dd yy"

  const { data: rateTableUsageData, isFetching: isFetchingRateTableUsage } =
    useFetchRateTableUsageQuery(
      {
        range,
        org_id: orgId,
        loid
      },
      { refetchOnMountOrArgChange: true }
    )
  const containsData = useMemo(
    () => rateTableUsageData && rateTableUsageData.length > 0,
    [rateTableUsageData]
  )
  const { chartOptions, seriesData } = useRateUsageHighchartsOptions(
    rateTableUsageData,
    containsData,
    dateFormat,
    labelDateFormat
  )
  const rateTableInfoDatas = useRateTableInfos(containsData, seriesData)

  useEffect(() => {
    setIsFetching(isFetchingRateTableUsage)
  }, [isFetchingRateTableUsage])

  return (
    <div className="bg-white flex flex-col flex-1 px-6 pt-6 pb-8 rounded-md overflow-hidden">
      <div className="flex flex-row items-center justify-between">
        <div className="text-base font-medium">{title}</div>
      </div>
      <If
        condition={isFetching}
        then={
          <GraphSkeleton
            className="pt-36"
            lineClassName="mt-16 mb-12"
            showTopTiles={false}
            showBottomTiles={true}
          />
        }
        else={
          <Fragment>
            <div className="rate-table-usage-chart pt-11 pb-2 relative">
              {!containsData && (
                <div className="absolute top-24 left-1/2 text-sm z-[1]">
                  No data yet
                </div>
              )}
              {chartOptions && (
                <HighchartsReact
                  highcharts={Highcharts}
                  options={getMultiLineChartOptions(containsData, chartOptions)}
                  containerProps={{ style: { height: "268px" } }}
                />
              )}
            </div>
            <div className="flex flex-wrap">
              {rateTableInfoDatas.map((rateTableInfoData, index) => (
                <RateTableInfo
                  key={`rate-table-info-${index}`}
                  info={rateTableInfoData}
                />
              ))}
            </div>
          </Fragment>
        }
      />
    </div>
  )
}

export default RateTableUsageMetrics
