import { FC } from "react"

interface LeadMetricsInfoProps {
  label: string
  value: string
  containerClassName?: string
}

const LeadMetricsInfo: FC<LeadMetricsInfoProps> = ({
  label,
  value,
  containerClassName = ""
}) => (
  <div className={`flex flex-col ${containerClassName}`}>
    <div className="w-auto md:w-40 leading-5">{label}</div>
    <div className="text-lg leading-6 font-inter-extrabold">{value}</div>
  </div>
)

export default LeadMetricsInfo
