/**
  Lead workflow API wrapper

**/

import { api } from "../common/utils"
import {
  LEAD_WORKFLOWS_ENDPOINT,
  LEAD_WORKFLOW_ENDPOINT
} from "../common/constants"
import { FindWorkflowsResponse, LeadworkflowDetails } from "../types"

const leadWorkflow = {
  find: async (brand_id: string): Promise<FindWorkflowsResponse> => {
    const { data } = await api.get(
      LEAD_WORKFLOWS_ENDPOINT + `?brand_id=${brand_id}`
    )
    return data
  },
  getDefaultDetails: async (): Promise<LeadworkflowDetails> => {
    const { data } = await api.get(`${LEAD_WORKFLOW_ENDPOINT}?id=`)
    return data
  },
  updateOrCreate: async (
    payload: Partial<LeadworkflowDetails>
  ): Promise<{ status: string; message?: string }> => {
    const { data } = await api.post(LEAD_WORKFLOW_ENDPOINT, payload)
    return data
  },
  delete: async (id: string): Promise<{ status: string; message?: string }> => {
    const { data } = await api.delete(`${LEAD_WORKFLOW_ENDPOINT}?id=${id}`)
    return data
  }
}

export default leadWorkflow
