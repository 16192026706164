import { FC, useMemo, useState } from "react"
import { Brand, LeadworkflowDetails } from "../../../../../../types"
import Input from "../../../../form/input"
import BBSwitch from "../../../../form/Switch"
import Tabs from "../../../../tabs"
import LeadworkflowConfig from "./sections/config"
import LeadworkflowUpload from "./sections/upload"
import LeadworkflowSteps from "./sections/steps"
import BrandAutocomplete from "../../../../brand-autocomplete"

interface Props {
  values: LeadworkflowDetails
  brands: Brand[]
  setFormField: (name: string, value: any) => void
}

const ManageLeadworkflowFormContent: FC<Props> = ({
  values,
  brands,
  setFormField
}) => {
  const [tab, setTab] = useState<string>("config") // "config" | "steps" | "upload"

  const selectedBrandId = useMemo(
    () => brands.find(b => b.id === values.brand_id)?.id,
    [brands, values.brand_id]
  )

  const setConfigField = (field: string, value: any) => {
    setFormField("config_json", {
      ...values.config_json,
      [field]: value
    })
  }

  const setBgImage = (val: string) => {
    let url = val

    if (process.env.CDN_HOST) {
      url = process.env.CDN_HOST + new URL(url).pathname
    }

    setConfigField("background_img", url)
  }

  const setFullConfigField = (config: any) => {
    setFormField("config_json", config)
  }

  return (
    <div className="py-6 h-full flex flex-col">
      <div className="grid grid-cols-2 gap-x-12">
        <div>
          <Input label="Name of Lead worflow" name="name" />
        </div>
        <div className="pt-10">
          <BBSwitch
            label="Set as default"
            enabled={values.is_default === "1"}
            setEnabled={value => setFormField("is_default", value ? "1" : "0")}
          />
        </div>
      </div>
      <div className="py-6">
        <BrandAutocomplete
          brands={brands}
          selected={selectedBrandId}
          onChange={val => setFormField("brand_id", val)}
          direction="vertical"
        />
      </div>
      <div className="pt-6">
        <Tabs
          className="px-6"
          currentTab={tab}
          tabs={[
            { id: "config", label: "Config" },
            { id: "steps", label: "Steps" },
            { id: "upload", label: "Upload" }
          ]}
          onChange={setTab}
        />
      </div>
      <div className="pt-6 flex-1">
        {tab === "config" && (
          <LeadworkflowConfig
            values={values}
            setFullConfigField={setFullConfigField}
          />
        )}
        {tab === "steps" && (
          <LeadworkflowSteps values={values} setConfigField={setConfigField} />
        )}
        {tab === "upload" && (
          <LeadworkflowUpload values={values} setBgImage={setBgImage} />
        )}
      </div>
    </div>
  )
}

export default ManageLeadworkflowFormContent
