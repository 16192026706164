import { FocusEvent } from "react"
import Input from "./input"
import { Switch } from "@headlessui/react"
import { ChangeEvent } from "react"
import { classNames } from "../../utils"

type InputBase = {
  name: string
  label: string
  value: string
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
}

type Props = {
  inputProps: InputBase
  switchProps: InputBase & { options: string[] }
  placeholder?: string
  required?: boolean
  onInputChange?: (name: string, value: string) => void
  onInputBlur?: (name: string, value: string) => void
  onSwitchChange: (name: string, value: string) => void
}

const InputWithSwitch = ({
  onInputChange,
  onInputBlur,
  onSwitchChange,
  inputProps,
  switchProps,
  required,
  placeholder
}: Props) => {
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    onInputChange?.(e.target.name, e.target.value)
  }

  const handleOnBlur = (e: FocusEvent<HTMLInputElement>) => {
    onInputBlur?.(e.target.name, e.target.value)
  }

  const handleSwitchChange = (on: boolean) => {
    onSwitchChange(switchProps.name, switchProps.options[on ? 1 : 0])
  }

  const enabled = switchProps.value === switchProps.options[1]

  return (
    <>
      <Input
        inputProps={{
          required: required,
          value: inputProps.value,
          onChange: handleOnChange,
          onBlur: handleOnBlur
        }}
        label={inputProps.label}
        name={inputProps.name}
        placeholder={placeholder}
        afterIcon={
          <Switch.Group as="div" className="flex items-center justify-between">
            <span className="flex flex-grow flex-col">
              <Switch.Label as="span" className="text-neutral-400 mr-3" passive>
                {switchProps.label}
              </Switch.Label>
            </span>
            <Switch
              name={switchProps.name}
              checked={enabled}
              onChange={handleSwitchChange}
              className={classNames(
                enabled ? "bg-primary-500" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-offset-2"
              )}
            >
              <span
                className={classNames(
                  enabled ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              >
                <span
                  className={classNames(
                    enabled
                      ? "opacity-0 ease-out duration-100"
                      : "opacity-100 ease-in duration-200",
                    "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                  )}
                  aria-hidden="true"
                >
                  <svg
                    className="h-3 w-3 text-neutral-400"
                    fill="none"
                    viewBox="0 0 12 12"
                  >
                    <path
                      d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <span
                  className={classNames(
                    enabled
                      ? "opacity-100 ease-in duration-200"
                      : "opacity-0 ease-out duration-100",
                    "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                  )}
                  aria-hidden="true"
                >
                  <svg
                    className="h-3 w-3 text-primary-500"
                    fill="currentColor"
                    viewBox="0 0 12 12"
                  >
                    <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                  </svg>
                </span>
              </span>
            </Switch>
          </Switch.Group>
        }
      />
    </>
  )
}

export default InputWithSwitch
