import { FC } from "react"
import GoogleLoginBtn from "./google-login-btn"

interface Props {
  theme: "icon" | "full"
}

const SocialLogins: FC<Props> = ({ theme }) => {
  const buttonList = [
    {
      type: "google",
      component: GoogleLoginBtn,
      theme
    }
  ]

  return (
    <div className={`${theme === "icon" ? "grid grid-cols-3 gap-2" : ""}`}>
      {buttonList.map(({ type, theme, component: Component }) => (
        <Component key={type} theme={theme} />
      ))}
    </div>
  )
}

export default SocialLogins
