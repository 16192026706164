import { FC } from "react"
import { Lead } from "../../../../types"
import {
  DEFAULT_DATETIME_LONG_FORMAT,
  formatDateTimeFromISO
} from "../../../utils"

interface Props {
  data: Lead
}

const OwnerCell: FC<Props> = ({ data }) => {
  const { owner, date_time } = data

  return (
    <td className="px-4 hidden md:table-cell">
      <div>
        <div className="whitespace-normal">
          {formatDateTimeFromISO(date_time, DEFAULT_DATETIME_LONG_FORMAT)}
        </div>
        <div className="">by {owner}</div>
      </div>
    </td>
  )
}

export default OwnerCell
