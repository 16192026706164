import { SelectOption } from "../../types"

export const findSelectedOption = (
  options: { id: string; name: string }[],
  value: string
) => options?.find(opt => opt.id === value)

export const findSelectOptionName = (id: string, options: SelectOption[]) => {
  return options.find(item => item.id === id)?.name
}

export const findSelectOptionId = (name: string, options: SelectOption[]) => {
  return options.find(item => item.name === name)?.id
}
