import { useMemo } from "react"
import { Landmark, Users, Code2 } from "lucide-react"
import DashboardStatCard from "../../dashboard-stat-card"

interface Props {
  brands: number
  los: number
  integrations: number
}

const Stats: React.FC<Props> = ({ brands, los, integrations }) => {
  const stats = useMemo(
    () => [
      {
        id: 0,
        title: "total brands",
        icon: <Landmark color="white" size={24} />,
        stat: brands,
        linkLabel: "View all Brands",
        linkHref: "/brands"
      },
      {
        id: 1,
        title: "total LO's",
        icon: <Users color="white" size={24} />,
        stat: los ?? 0,
        linkLabel: "View all LO's",
        linkHref: "/loan-officers"
      },
      {
        id: 2,
        title: "total integrations",
        icon: <Code2 color="white" size={24} />,
        stat: integrations,
        linkLabel: "View all Integrations",
        linkHref: "/integrations"
      }
    ],
    [brands, integrations, los]
  )
  return (
    <div className="flex flex-col md:flex-row gap-6">
      {stats.map((stat, i) => (
        <DashboardStatCard key={stat.id} index={i} {...stat} />
      ))}
    </div>
  )
}

export default Stats
