import { LearnVideoType } from "../../types"

export const VideoLinks: {
  [key in LearnVideoType]: { title: string; link: string }
} = {
  engagements: {
    title: "Engagements - Learn how this works",
    link: "https://bankingbridge.wistia.com/medias/d8hu7tzu6k"
  },
  leads: {
    title: "Website Leads - Learn how this works",
    link: "//fast.wistia.net/embed/iframe/x124y4m3za"
  }
}
