import { FC, useMemo, useState } from "react"
import Autocomplete from "../form/autocomplete"
import { Brand } from "../../../types"

interface Props {
  selected?: string
  brands: Brand[]
  direction?: "vertical" | "horizontal"
  onChange: (val: string) => void
}

const BrandAutocomplete: FC<Props> = ({
  selected,
  brands,
  direction = "horizontal",
  onChange
}) => {
  const [query, setQuery] = useState<string>("")

  const options = useMemo(
    () =>
      brands
        .filter(brand => !!brand.name.toLowerCase().includes(query))
        .map(b => ({
          id: b.id,
          text: b.name
        })),
    [brands, query]
  )

  const selectedOption = options.find(opt => opt.id === selected)

  return (
    <div className="max-w-md">
      <Autocomplete
        label="Brand:"
        name="brand"
        placeholder="Select brand"
        value={selectedOption}
        query={query}
        options={options}
        direction={direction}
        onChange={option => onChange(option.id)}
        onChangeQuery={setQuery}
      />
    </div>
  )
}

export default BrandAutocomplete
