import { FC } from "react"
import { Integration } from "../../../../../types"
import { useIntegrationSettingsForm } from "../../../../hooks"
import If from "../../../if"
import PricingSetSelector from "../pricing-set-selector"

interface Props {
  integration?: Integration
}

const PricingCards: FC<Props> = ({ integration }) => {
  const { app_settings, saveAppSetting } =
    useIntegrationSettingsForm(integration)

  const onChange = () => {
    saveAppSetting({
      pricing: Number(app_settings?.pricing ?? "0") >= 1 ? 0 : 1
    })
  }

  return (
    <div className="py-2">
      <input
        checked={app_settings?.pricing === 1}
        onChange={onChange}
        id="pricing"
        name="pricing"
        type="checkbox"
        className="h-6 w-6 rounded border-neural-400 text-primary-500 focus:ring-primary-300 cursor-pointer"
      />

      <label htmlFor="pricing" className="select-none ml-3 cursor-pointer">
        Pricing Cards
      </label>
      <If
        condition={app_settings?.pricing === 1}
        then={<PricingSetSelector />}
      />
    </div>
  )
}

export default PricingCards
