import { FC, useState } from "react"
import { useNavigate } from "react-router-dom"
import { BBColumnDef, Organization } from "../../../../types"
import OrganizationCell from "./cells/organization"
import MenuCell from "./cells/menu"
import Table from "../../table"
import EditOrganization from "../edit"

interface Props {
  data: Organization[]
  isLoading: boolean
}

const OrganizationsTable: FC<Props> = ({ data, isLoading }) => {
  const navigate = useNavigate()

  const [selectedOrg, setSelectedOrg] = useState<Organization>()

  const onClick = (id: string) => navigate(`/organizations/${id}`)

  const onDelete = () => {}

  const resetSelected = () => setSelectedOrg(undefined)

  const columns: BBColumnDef<Organization>[] = [
    {
      id: "organization",
      header: "Organization",
      accessorFn: row => row.name,
      footer: props => props.column.id,
      cell: props => (
        <OrganizationCell info={props.row.original} onClick={onClick} />
      )
    },
    {
      id: "edit",
      header: "",
      footer: props => props.column.id,
      cell: props => (
        <MenuCell
          info={props.row.original}
          onEdit={setSelectedOrg}
          onDelete={onDelete}
        />
      )
    }
  ]
  return (
    <div>
      <Table
        data={data}
        columns={columns}
        viewType="table"
        dropdownFilterColumnId="organization"
        searchPlaceholder="Search organizations"
        shrinkFilterSort
        isLoading={isLoading}
      />
      <EditOrganization
        open={!!selectedOrg}
        data={selectedOrg}
        onClose={resetSelected}
        isInSidePanel
      />
    </div>
  )
}

export default OrganizationsTable
