import { createApi } from "@reduxjs/toolkit/dist/query/react"
import {
  FIND_COUNTY_ENDPOINT,
  RATE_TABLE_CONFIG_ENDPOINT,
  RTK_SLICE_API_LIST,
  SUBSCRIPTION_CONFIG_ENDPOINT
} from "../../common/constants"
import { baseQuery, getTags } from "../api"
import { getQueryString } from "../../common/utils"
import {
  DefaultRateTableConfig,
  RateTableConfig,
  RateTableConfigDetails,
  CountyDetails,
  SubscriptionConfigDetails,
  SubscriptionConfig
} from "../../types"
import bbApi from "../../api"

const reducerPath = "config"
const countyPath = "county"
const rateTablePath = "rate-table"
const subscriptionPath = "subscription-config"

export const configApi = createApi({
  reducerPath,
  baseQuery,
  tagTypes: [reducerPath, countyPath, rateTablePath, subscriptionPath],
  endpoints: builder => ({
    fetchRateTableConfigs: builder.query({
      query: (params: { brand_id: string }) =>
        `${RATE_TABLE_CONFIG_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: RateTableConfig[]) => {
        const index = 0
        return responseData?.[index] ? responseData : []
      },
      providesTags: result => getTags(rateTablePath, "id", result)
    }),
    fetchRateTableConfigDetails: builder.query({
      query: (params: { id: string }) =>
        `${RATE_TABLE_CONFIG_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: RateTableConfigDetails) => {
        return responseData ?? []
      },
      providesTags: (result, error, arg) => [
        { type: rateTablePath, id: arg.id }
      ]
    }),
    fetchDefaultRateTableConfig: builder.query({
      query: () => `${RATE_TABLE_CONFIG_ENDPOINT}?id=`,
      transformResponse: (responseData: DefaultRateTableConfig) => {
        return responseData ?? []
      }
    }),
    updateOrCreateRateTableConfig: builder.mutation({
      queryFn: async (payload: Partial<RateTableConfigDetails>) => {
        // Remove "id" field if it's empty which means it's CREATE action
        if (!payload.id) {
          delete payload.id
        }
        const data = await bbApi.configAdmin.updateRateTableConfig(payload)
        return { data }
      },
      invalidatesTags: (result, error, arg) => [
        { type: rateTablePath, id: arg.id },
        { type: rateTablePath, id: RTK_SLICE_API_LIST }
      ]
    }),
    deleteRateTableConfig: builder.mutation({
      queryFn: async (id: string) => {
        const data = await bbApi.configAdmin.deleteRateTableConfig(id)
        return { data }
      },
      invalidatesTags: (result, error, arg) => [
        { type: rateTablePath, id: RTK_SLICE_API_LIST }
      ]
    }),
    findCountyOptions: builder.query({
      query: (params: { name_like: string }) =>
        `${FIND_COUNTY_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: string[]) => {
        return responseData ?? []
      }
    }),
    findCountyDetails: builder.query({
      query: (params: { name: string; state: string }) =>
        `${FIND_COUNTY_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: CountyDetails) => {
        return responseData
      },
      providesTags: (result, error, arg) => [
        { type: countyPath, id: arg.name.toLowerCase() }
      ]
    }),
    updateCounty: builder.mutation({
      queryFn: async (params: Partial<CountyDetails>) => {
        const payload = {
          effective_tax_rate: params.effective_tax_rate,
          name: params.county_name,
          state: params.state
        }
        const data = await bbApi.configAdmin.updateCounty(payload)

        return { data }
      },
      invalidatesTags: (result, error, arg) => {
        return [{ type: countyPath, id: arg.county_name?.toLowerCase() }]
      }
    }),
    fetchSubscriptionConfigs: builder.query({
      query: (params: { id?: string }) =>
        `${SUBSCRIPTION_CONFIG_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: SubscriptionConfig[]) => {
        return responseData
      },
      providesTags: result => getTags(subscriptionPath, "id", result)
    }),
    updateOrCreateSubscriptionConfig: builder.mutation({
      queryFn: async (payload: Partial<SubscriptionConfigDetails>) => {
        // Remove "id" field if it's empty which means it's CREATE action
        if (!payload.id) {
          delete payload.id
        }
        const data = await bbApi.configAdmin.updateSubscriptionConfig(payload)
        return { data }
      },
      invalidatesTags: (result, error, arg) => [
        { type: subscriptionPath, id: arg.id },
        { type: subscriptionPath, id: RTK_SLICE_API_LIST }
      ]
    }),
    deleteSubscriptionConfig: builder.mutation({
      queryFn: async (id: string) => {
        const data = await bbApi.configAdmin.deleteSubscriptionConfig(id)
        return { data }
      },
      invalidatesTags: (result, error, arg) => [
        { type: subscriptionPath, id: RTK_SLICE_API_LIST }
      ]
    })
  })
})

export const {
  useFetchRateTableConfigsQuery,
  useFetchRateTableConfigDetailsQuery,
  useFindCountyDetailsQuery,
  useFindCountyOptionsQuery,
  useUpdateCountyMutation,
  useUpdateOrCreateRateTableConfigMutation,
  useDeleteRateTableConfigMutation,
  useFetchDefaultRateTableConfigQuery,
  useFetchSubscriptionConfigsQuery,
  useUpdateOrCreateSubscriptionConfigMutation,
  useDeleteSubscriptionConfigMutation
} = configApi
