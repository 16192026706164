import { Listbox, Transition } from "@headlessui/react"
import { Check, ChevronDown } from "lucide-react"
import { Fragment } from "react"
import { classNames } from "../../utils"
import If from "../if"

type Props = {
  data: any[]
  selected: any
  label: string
  name?: string
  isLoading?: boolean
  disabled?: boolean
  multiple?: boolean
  setSelected: (item: any) => void
}

/**
 * This is a wrapper for Headless UI's Listbox component
 * https://headlessui.com/react/listbox
 */

const InlineSelect = ({
  data,
  selected,
  setSelected,
  isLoading,
  multiple,
  disabled,
  name,
  label
}: Props) => {
  return (
    <Listbox
      name={name}
      value={selected?.id}
      onChange={setSelected}
      multiple={multiple}
      disabled={disabled || isLoading}
    >
      {({ open }) => (
        <div className="flex items-center">
          <Listbox.Label className="block text-sm text-neutral-400 pr-3">
            {label}
          </Listbox.Label>
          <div className="relative">
            <Listbox.Button className="relative w-56 h-9 cursor-pointer rounded-md border border-neutral-200 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-primary-400 focus:outline-none focus:ring-1 focus:ring-primary-400 sm:text-sm">
              <span className="block truncate">{selected?.name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <If
                  loaderSize={6}
                  isLoading={isLoading}
                  condition
                  then={
                    <ChevronDown
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  }
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {data.map(item => (
                  <Listbox.Option
                    key={item.id}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-primary-500" : "text-gray-900",
                        "relative select-none py-2 pl-7 pr-4 cursor-pointer"
                      )
                    }
                    value={item.id}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-medium" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {item.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-primary-500",
                              "absolute inset-y-0 left-0 flex items-center pl-2"
                            )}
                          >
                            <Check className="h-4 w-4" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  )
}

export default InlineSelect
