import { memo } from "react"

import Skeleton from "./base"
import { useGridSize } from "../../hooks"

const RateCard = () => {
  const gridSize = useGridSize()

  return (
    <div className="p-6 grid grid-rows-12 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 grid-flow-row md:grid-flow-rows gap-6 bg-primary-100">
      {[...Array(gridSize * 3)].map((_, index) => (
        <div
          key={index}
          className="bg-white shadow-default rounded-md px-6 py-8"
        >
          <Skeleton className="h-full">
            <div className="flex flex-col justify-between items-between h-full">
              <div className="flex flex-col w-full mb-11">
                <div className="w-1/2 h-4 bg-gray-200 rounded-full  mb-1.5"></div>
                <div className=" w-5/6 h-4 bg-gray-100 rounded-full"></div>
              </div>
              <div className="flex w-full mb-11 gap-6">
                {[...Array(3)].map((_, key) => (
                  <div key={key} className="w-full">
                    <div className="h-4 bg-gray-200 rounded-full w-full mb-1.5"></div>
                    <div className="h-4 bg-gray-100 rounded-full w-2/3"></div>
                  </div>
                ))}
              </div>
              <div className="w-full">
                <div className=" w-5/6 h-4 bg-gray-200 rounded-full mb-1.5"></div>
                <div className=" w-5/6 h-4 bg-gray-100 rounded-full mb-1.5"></div>
              </div>
            </div>
          </Skeleton>
        </div>
      ))}
    </div>
  )
}

export default memo(RateCard)
