import { FC } from "react"
import { PanelType } from "../../../types"
import CreateOrganizationPanel from "../organizations/create"
import { useAppDispatch } from "../../hooks"
import { resetVisiblePanel } from "../../../redux/slices/app"
import ManageBrand from "../brands/manage"
import EditLoProfile from "../account/edit-lo-profile"
import ManageIntegration from "../integrations/manage"
import ManageEngagementPanel from "../engagements/manage"
import ManageRateTableConfig from "../config-admin/config-rate-table/manage"
import ManageLeadworkflow from "../config-admin/config-lead-workflow/manage"
import ManageRateflow from "../config-admin/config-rateflow/manage"
import ManageContactForm from "../config-admin/config-contact-form/manage"
import ManageSubscriptionConfig from "../config-admin/config-subscription/manage"

interface Props {
  visiblePanel: PanelType | null
}

const PanelsGroup: FC<Props> = ({ visiblePanel }) => {
  const dispatch = useAppDispatch()

  const handleReset = () => {
    dispatch(resetVisiblePanel({}))
  }

  return (
    <>
      <CreateOrganizationPanel
        open={visiblePanel === "organization/new"}
        onClose={handleReset}
      />
      <ManageBrand
        open={visiblePanel === "brand/new"}
        isInSidePanel
        onClose={handleReset}
      />
      <EditLoProfile
        open={visiblePanel === "loan-officer/new"}
        isInSidePanel
        onClose={handleReset}
      />
      <ManageIntegration
        open={visiblePanel === "integration/new"}
        onClose={handleReset}
      />
      <ManageEngagementPanel
        open={visiblePanel === "engagement/new"}
        onClose={handleReset}
      />
      <ManageRateTableConfig
        open={visiblePanel === "rate-table-config/new"}
        onClose={handleReset}
      />
      <ManageLeadworkflow
        open={visiblePanel === "lead-workflow/new"}
        onClose={handleReset}
      />
      <ManageRateflow
        open={visiblePanel === "rate-flow/new"}
        onClose={handleReset}
      />
      <ManageContactForm
        open={visiblePanel === "contact-form/new"}
        onClose={handleReset}
      />
      <ManageSubscriptionConfig
        open={visiblePanel === "subscription-config/new"}
        onClose={handleReset}
      />
    </>
  )
}

export default PanelsGroup
