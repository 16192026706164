import { FC } from "react"
import { LoanOfficer, ParsedAddress } from "../../../../types"
import Input from "../../form/input"
import USStateSelector, { STATES } from "../../form/us-state-selector"
import Label from "../../form/label"
import ZipcodeAutocomplete from "../../zipcode-autocomplete"

interface Props {
  title: string
  values: LoanOfficer
  setFormField: (name: string, value: string) => void
}

const AddressSection: FC<Props> = ({ title, values, setFormField }) => {
  const selectedState = STATES.find(s => s.id === values?.office_state)

  const onZipcodeChange = (value: string | ParsedAddress) => {
    if (typeof value === "string") {
      setFormField("office_zipcode", value)
    } else {
      setFormField("office_zipcode", value.zipcode ?? "")
      setFormField("office_state", value.state ?? "")
      setFormField("office_city", value.city ?? "")
    }
  }

  return (
    <div className="p-6">
      <div>
        <h4 className="text-xl font-bold mb-6 text-primary-alt-400 ">
          {title}
        </h4>
      </div>
      <div className="grid gap-y-6 gap-x-8 grid-cols-12 pt-8">
        <div className="col-span-6">
          <Input label="Office Address" name="office_street_addr" />
        </div>
        <div className="col-span-6">
          <ZipcodeAutocomplete
            value={values.office_zipcode}
            handleOnChange={onZipcodeChange}
          />
        </div>
        {(values.office_city || values.office_state) && (
          <>
            <div className="col-span-6">
              <Input label="City" name="office_city" />
            </div>
            <div className="col-span-6">
              <Label label="State" name="state" />
              <USStateSelector
                name="state"
                setSelected={val => {
                  setFormField("office_state", val.id)
                }}
                selected={selectedState}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default AddressSection
