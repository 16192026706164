import { FC } from "react"
import { FormattedRateflowCard } from "../../../../../../types"
import ReactJSONEditor from "../../../../react-json-editor"
import SidePanel from "../../../../side-panel"
import { formatCurrency, formatDateTimeFromMillis } from "../../../../../utils"

interface Props {
  data?: FormattedRateflowCard
  open: boolean
  loanAmount: number
  onClose: () => void
}

const QuoteDetails: FC<Props> = ({ data, open, loanAmount, onClose }) => {
  return (
    <SidePanel
      title={`Details of quote ${
        data?.timestamp
          ? ` on ${formatDateTimeFromMillis(
              data?.timestamp * 1000,
              "LL-dd-yyyy, EEEE 'at' hh:mm a 'EST'"
            )}`
          : ""
      }`}
      open={open}
      onClose={onClose}
    >
      <div className="h-full pt-6 flex flex-col">
        {data ? (
          <>
            <div className="py-6 mb-6 border border-neutral-200 rounded-md">
              <div className="flex items-center justify-between">
                <div className="pl-10 pr-20">
                  <div className="font-bold text-3xl">{data.rate}%</div>
                  <div className="text-sm mt-1">
                    {data.amortizationTerm} YR {data.amortizationType}
                  </div>
                </div>

                <div className="flex-1">
                  <div className="grid grid-cols-[20%_20%_20%_auto] gap-2 mt-4">
                    <div className="text-neutral-400 text-sm mb-3">Price</div>
                    <div className="text-sm mb-3">
                      {data.price?.toFixed(2) ?? "0"}
                    </div>

                    <div className="text-neutral-400 text-sm mb-3">P/I</div>
                    <div className="text-sm mb-3">
                      {formatCurrency(data.principalAndInterest)}
                    </div>

                    <div className="text-neutral-400 text-sm mb-3">Fee</div>
                    <div className="text-sm mb-3">
                      {formatCurrency(data.fee.amount)}
                    </div>

                    <div className="text-neutral-400 text-sm mb-3">Type</div>
                    <div className="text-sm mb-3">{data.loanType}</div>

                    <div className="text-neutral-400 text-sm mb-3">
                      Loan amount
                    </div>
                    <div className="text-sm mb-3">
                      {formatCurrency(loanAmount)}
                    </div>

                    <div className="text-neutral-400 text-sm">Investor</div>
                    <div className="text-sm">{data.investor}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-1">
              <ReactJSONEditor name="Quote Details" json={data} mode="view" />
            </div>
          </>
        ) : (
          <div>No details available.</div>
        )}
      </div>
    </SidePanel>
  )
}

export default QuoteDetails
