import { LoanOfficer } from "../../../../../types"
import Tooltip from "../../../tool-tip"

interface Props {
  info: LoanOfficer
  onEmailCellClick?: (loID: string) => void
}

const EmailCell = ({ info, onEmailCellClick }: Props) => {
  const { email, uid } = info
  return (
    <td
      className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 hidden md:table-cell cursor-pointer"
      onClick={() => onEmailCellClick && onEmailCellClick(uid)}
    >
      <div className="truncate hidden md:table-cell w-32 lg:hidden">
        <Tooltip
          toolTipNode={<span className="truncate">{email}</span>}
          displayTextClass="w-32"
          text={email}
        />
      </div>
      <span className="hidden lg:table-cell">{email}</span>
    </td>
  )
}

export default EmailCell
