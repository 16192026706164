import { FC, KeyboardEvent } from "react"
import { Users, Landmark, Code2, AtSign } from "lucide-react"
import { SearchResult } from "../../../types"

interface Props {
  data: SearchResult
  onClickItem: (item: SearchResult) => void
}

const getIcon = (type: string) => {
  switch (type) {
    case "integration":
      return <Code2 size={16} />
    case "subscription":
      return <AtSign size={16} />
    case "loan_officer":
      return <Users size={16} />
    case "brand":
      return <Landmark size={16} />
    default:
      return null
  }
}

const RecentSearchItem: FC<Props> = ({ data, onClickItem }) => {
  const onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      onClickItem(data)
    }
  }

  return (
    <div
      tabIndex={0}
      key={data.id}
      className="search-result flex items-center px-4 text-sm cursor-pointer rounded-md hover:bg-teal-500 hover:text-white"
      onClick={() => onClickItem(data)}
      onKeyDown={onKeyDown}
    >
      <div className="text-neutral-400">{getIcon(data.type)}</div>
      <div className="search-result-type p-2 text-neutral-400 capitalize">
        {data.type?.split("_").join(" ")}:
      </div>
      <div className="">{data.item}</div>
    </div>
  )
}

export default RecentSearchItem
