import { FC, useState } from "react"
import { useInMobileView } from "../../hooks/use-window-size"
import BBModal from "../modal"
import { DropdownItemProps } from "../../../types"
import DropdownItem from "../dropdown-item"

interface Props {
  className?: string
  menuBtn: JSX.Element
  items: DropdownItemProps[]
}

const MobileMenu: FC<Props> = ({ className, menuBtn, items }) => {
  const inMobileView = useInMobileView()

  const [modalVisible, setModalVisible] = useState<boolean>(false)

  const openModal = () => setModalVisible(true)
  const closeModal = () => setModalVisible(false)

  if (!inMobileView) {
    return null
  }

  return (
    <>
      <div onClick={openModal}>{menuBtn}</div>
      <BBModal show={modalVisible} onClose={closeModal} size="2xl">
        <>
          <div className="flex justify-center pt-4">
            <div className="w-12 h-[6px] bg-neutral-200 rounded-full"></div>
          </div>
          <div className="px-6 py-3">
            {items.map((item, index) => (
              <DropdownItem
                key={`dropdown-item-${item.name}-${index}`}
                item={item}
                closeMenu={closeModal}
              />
            ))}
          </div>
        </>
      </BBModal>
    </>
  )
}

export default MobileMenu
