import { PrimaryBtn } from "../../common/ui"
import Img404 from "../../assets/images/404.svg"
import { useNavigate } from "react-router-dom"

type Props = {
  title?: string
}

const NotFoundPage: React.FC<Props> = ({ title }) => {
  const navigate = useNavigate()

  const handleGoHome = () => navigate("/")

  return (
    <div className="h-full max-h-[calc(100vh-4rem)] flex flex-col overflow-hidden bg-white text-center">
      <div className="overflow-hidden">
        <img className="h-full m-auto" src={Img404} alt="404" />
      </div>
      <div className="flex-1 pb-10">
        <div className="text-3xl py-10">Oops! Page not found</div>
        <PrimaryBtn onClick={handleGoHome}>Back to Home</PrimaryBtn>
      </div>
    </div>
  )
}

export default NotFoundPage
