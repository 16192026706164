import { FC, Fragment, useEffect, useMemo, useState } from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"

import If from "../../../if"
import MultiColumnPerformance from "../../../skeletons/multi-column-performance"
import PillTabs from "../../../pill-tabs"
import Table from "../../../table"
import NoData from "../../../no-data"
import {
  useFetchLeadCountByLoanPurposeQuery,
  useFetchLeadCountByPropertyQuery,
  useFetchLeadCountByResidencyQuery,
  useFetchLeadMetricsQuery
} from "../../../../../redux/queries"
import { useLeadCountPercentagesWithBg } from "../../../../hooks/use-lead-count-percentages-with-bg"
import { getPieChartOptions } from "../../../chart-with-stats/options"
import { getLeadByPropertyColumns, getLeadByResidencyColumns } from "./columns"
import {
  BBColumnDef,
  LeadCountByProperty,
  LeadCountByResidency
} from "../../../../../types"
import LeadMetricsInfo from "./info"

interface LeadByPropertyAndResidencyProps {
  title: string
  range: number
  orgId: string | undefined
  loid: string | undefined
}

enum LoanPurposeTabs {
  PROPERTY = "by-property-type",
  RESIDENCY = "by-residency-type"
}

enum LoanPurposeLabelTabs {
  PROPERTY = "By Property type",
  RESIDENCY = "By Residency type"
}

enum LoanPurposeType {
  PURCHASE = "Purchase",
  REFINANCE = "Refinance"
}

const LeadByPropertyAndResidency: FC<LeadByPropertyAndResidencyProps> = ({
  title,
  range,
  orgId,
  loid
}) => {
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const [chartLabels, setChartLabels] = useState<[string, number][]>([])
  const [tab, setTab] = useState<string>(LoanPurposeTabs.PROPERTY)
  const propertyColumns: BBColumnDef<LeadCountByProperty>[] =
    getLeadByPropertyColumns()
  const residencyColumns: BBColumnDef<LeadCountByResidency>[] =
    getLeadByResidencyColumns()

  const { data: leadCountByPropertyData, isFetching: isFetchingProperty } =
    useFetchLeadCountByPropertyQuery(
      {
        range,
        org_id: orgId,
        loid
      },
      { refetchOnMountOrArgChange: true }
    )
  const { data: leadCountByResidencyData, isFetching: isFetchingResidency } =
    useFetchLeadCountByResidencyQuery(
      {
        range,
        org_id: orgId,
        loid
      },
      { refetchOnMountOrArgChange: true }
    )
  const {
    data: leadCountByLoanPurposeData,
    isFetching: isFetchingLoanPurpose
  } = useFetchLeadCountByLoanPurposeQuery(
    {
      range,
      org_id: orgId,
      loid
    },
    { refetchOnMountOrArgChange: true }
  )
  const { data: leadMetricsData, isFetching: isFetchingLeadMetrics } =
    useFetchLeadMetricsQuery(
      {
        range,
        org_id: orgId,
        loid
      },
      { refetchOnMountOrArgChange: true }
    )
  const leadCountByProperty = useLeadCountPercentagesWithBg(
    leadCountByPropertyData
  )
  const leadCountByResidency = useLeadCountPercentagesWithBg(
    leadCountByResidencyData
  )
  const containsData = useMemo(
    () =>
      leadCountByProperty?.length > 0 &&
      leadCountByResidency?.length > 0 &&
      leadCountByLoanPurposeData &&
      leadCountByLoanPurposeData.length > 0 &&
      leadMetricsData &&
      Object.keys(leadMetricsData).length > 0,
    [
      leadCountByLoanPurposeData,
      leadCountByProperty?.length,
      leadCountByResidency?.length,
      leadMetricsData
    ]
  )

  useEffect(() => {
    let newChartLabels: [LoanPurposeType, number][] = []
    if (
      leadCountByLoanPurposeData?.length &&
      leadCountByLoanPurposeData.length > 0
    ) {
      const purchaseCount = leadCountByLoanPurposeData
        .filter(
          item =>
            item.LoanPurpose?.toLowerCase() ===
            LoanPurposeType.PURCHASE.toLowerCase()
        )
        .reduce((sum, item) => sum + item.lead_count, 0)
      const refinanceCount = leadCountByLoanPurposeData
        .filter(
          item =>
            item.LoanPurpose?.toLowerCase() ===
            LoanPurposeType.REFINANCE.toLowerCase()
        )
        .reduce((sum, item) => sum + item.lead_count, 0)
      newChartLabels = [
        [LoanPurposeType.PURCHASE, purchaseCount],
        [LoanPurposeType.REFINANCE, refinanceCount]
      ]
    }
    setChartLabels(newChartLabels)
  }, [leadCountByLoanPurposeData])

  useEffect(() => {
    setIsFetching(
      isFetchingProperty ||
        isFetchingResidency ||
        isFetchingLoanPurpose ||
        isFetchingLeadMetrics
    )
  }, [
    isFetchingProperty,
    isFetchingResidency,
    isFetchingLoanPurpose,
    isFetchingLeadMetrics
  ])

  return (
    <div className="bg-white flex flex-col flex-1 px-6 pt-6 pb-7 rounded-md overflow-hidden">
      <div className="flex flex-row items-center justify-between">
        <div className="text-base font-medium">{title}</div>
      </div>
      <If
        condition={isFetching}
        then={<MultiColumnPerformance className="pt-7 md:pt-36 pb-6 md:pb-3" />}
        else={
          <Fragment>
            {!containsData && <NoData />}
            {containsData && (
              <div className="grid grid-cols-12 mx-0 xl:mx-24 pb-1.5">
                <div className="col-span-12 lg:col-span-6 mt-4 mb-6 lg:mb-0">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={getPieChartOptions(chartLabels)}
                  />
                  <div className="flex justify-evenly text-xs mt-1">
                    <div className="flex flex-col">
                      <LeadMetricsInfo
                        label="HOUSEHOLD INCOME AVG."
                        value={
                          leadMetricsData?.Household_Income_Average
                            ? `$${leadMetricsData.Household_Income_Average.toLocaleString()}`
                            : "-"
                        }
                        containerClassName="mb-10"
                      />
                      <LeadMetricsInfo
                        label="LIST PRICE/VALUE AVG."
                        value={
                          leadMetricsData?.List_Price_Average
                            ? `$${leadMetricsData.List_Price_Average.toLocaleString()}`
                            : "-"
                        }
                      />
                    </div>
                    <div className="flex flex-col">
                      <LeadMetricsInfo
                        label="CREDIT SCORE AVG."
                        value={
                          leadMetricsData?.Credit_Score_Average
                            ? `${leadMetricsData.Credit_Score_Average.toLocaleString()}`
                            : "-"
                        }
                        containerClassName="mb-10"
                      />
                      <LeadMetricsInfo
                        label="LOAN BALANCE AVG."
                        value={
                          leadMetricsData?.Loan_Balance_Average
                            ? `$${leadMetricsData.Loan_Balance_Average.toLocaleString()}`
                            : "-"
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-12 lg:col-span-5 lg:col-end-13">
                  <PillTabs
                    currentTab={tab}
                    tabs={[
                      {
                        id: LoanPurposeTabs.PROPERTY,
                        label: LoanPurposeLabelTabs.PROPERTY
                      },
                      {
                        id: LoanPurposeTabs.RESIDENCY,
                        label: LoanPurposeLabelTabs.RESIDENCY
                      }
                    ]}
                    onChange={setTab}
                  />
                  {tab === LoanPurposeTabs.PROPERTY && (
                    <Table
                      data={leadCountByProperty}
                      columns={propertyColumns}
                      viewType="table"
                      pageSize={5}
                      paginationType="chart"
                      noSorter
                      tableClassName="md:table-fixed"
                      tableHeaderClassName="px-0 pt-0 pb-1 text-left text-sm font-medium uppercase"
                      searchBarVisible={false}
                    />
                  )}
                  {tab === LoanPurposeTabs.RESIDENCY && (
                    <Table
                      data={leadCountByResidency}
                      columns={residencyColumns}
                      viewType="table"
                      pageSize={5}
                      paginationType="chart"
                      noSorter
                      tableClassName="md:table-fixed"
                      tableHeaderClassName="px-0 pt-0 pb-1 text-left text-sm font-medium uppercase"
                      searchBarVisible={false}
                    />
                  )}
                </div>
              </div>
            )}
          </Fragment>
        }
      />
    </div>
  )
}

export default LeadByPropertyAndResidency
