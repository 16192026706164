import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { ACTIVITY_ENDPOINT } from "../../common/constants"
import { getQueryString } from "../../common/utils"
import { Activity, APIError } from "../../types"
import { baseQuery } from "../api"

const reducerPath = "activities"

export const activitiesApi = createApi({
  reducerPath,
  baseQuery,
  tagTypes: [reducerPath],
  endpoints: builder => ({
    fetchActivitiesByBrand: builder.query({
      query: (params: { brand_id: string; limit?: number; days?: number }) =>
        `${ACTIVITY_ENDPOINT}${getQueryString({ ...params, type: "brand" })}`,
      transformResponse: (responseData: Activity[] | APIError) =>
        "status" in responseData ? [] : responseData || []
    }),
    fetchActivitiesByLo: builder.query({
      query: (params: { loid: string; limit?: number; days?: number }) =>
        `${ACTIVITY_ENDPOINT}${getQueryString({ ...params, type: "lo" })}`,
      transformResponse: (responseData: Activity[] | APIError) =>
        "status" in responseData ? [] : responseData
    }),
    fetchActivitiesByOrg: builder.query({
      query: (params: { org_id: string; limit?: number; days?: number }) =>
        `${ACTIVITY_ENDPOINT}${getQueryString({ ...params, type: "org" })}`,
      transformResponse: (responseData: Activity[] | APIError) =>
        "status" in responseData ? [] : responseData
    })
  })
})

export const {
  useFetchActivitiesByBrandQuery,
  useFetchActivitiesByLoQuery,
  useFetchActivitiesByOrgQuery
} = activitiesApi
