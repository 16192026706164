import { FC, useEffect, useRef } from "react"
import { Formik, FormikProps } from "formik"
import { object, string } from "yup"
import SidePanel from "../../../side-panel"
import { RateFlowDetails } from "../../../../../types"
import ManageRateflowFormContent from "./form-content"
import {
  useFetchAllBrandsQuery,
  useUpdateOrCreateRateflowMutation
} from "../../../../../redux/queries"
import { useAppDispatch, useAppSelector } from "../../../../hooks"
import { pushToast } from "../../../../../redux/slices/notifications"
import { selectUserDetails } from "../../../../../redux/slices/auth"

interface Props {
  open: boolean
  data?: RateFlowDetails
  onClose: () => void
}

const LeadworkflowSchema = object({
  name: string().required().min(1),
  default: string().required(),
  bank_id: string().required().min(1),
  settings: object().required()
})

const ManageRateflow: FC<Props> = ({ open, data, onClose }) => {
  const dispatch = useAppDispatch()
  const formRef = useRef<FormikProps<RateFlowDetails>>(null)

  const [
    updateOrCreate,
    {
      data: updateOrCreateResponse,
      isSuccess,
      isError,
      reset: resetUpdateOrCreateResponse
    }
  ] = useUpdateOrCreateRateflowMutation()

  const { data: brands } = useFetchAllBrandsQuery({ limit: 999 })
  const user = useAppSelector(selectUserDetails)

  const isCreate = !data?.id

  const initialValues: RateFlowDetails = data ?? {
    name: "",
    id: "",
    default: "0",
    created: "",
    updated: "",
    bank_id: "",
    loid: user?.id ?? "",
    owner: user?.id ?? "",
    pricing_set_id: "0",
    settings: {}
  }

  const setFormField = (name: string, val: string | number | boolean) => {
    formRef.current?.setFieldValue(name, val)
  }

  const onSubmit = () => {
    if (formRef.current?.values) {
      updateOrCreate(formRef.current.values)
    }
  }

  useEffect(() => {
    if (isSuccess && updateOrCreateResponse?.status === "success") {
      dispatch(
        pushToast({
          msg: `Successfully ${isCreate ? "created" : "updated"} rateflow`,
          theme: "success",
          timeout: 3000
        })
      )
      resetUpdateOrCreateResponse()
      formRef.current?.resetForm()
      onClose()
    }
  }, [
    dispatch,
    isCreate,
    isSuccess,
    onClose,
    resetUpdateOrCreateResponse,
    updateOrCreateResponse?.status
  ])

  useEffect(() => {
    if (isError || updateOrCreateResponse?.status === "error") {
      dispatch(
        pushToast({
          msg: `Failed to ${isCreate ? "create" : "update"} rateflow`,
          theme: "danger",
          timeout: 3000
        })
      )
      resetUpdateOrCreateResponse()
    }
  }, [
    dispatch,
    isCreate,
    isError,
    resetUpdateOrCreateResponse,
    updateOrCreateResponse?.status
  ])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      innerRef={formRef}
      validationSchema={LeadworkflowSchema}
      enableReinitialize
    >
      {({ values, isValid, dirty, errors }) => {
        return (
          <SidePanel
            title={
              isCreate
                ? "Create rateflow"
                : `Rateflow - ${data.name || data.id}`
            }
            open={open}
            onClose={onClose}
            actions={[
              {
                label: "Save Changes",
                type: "primary",
                onClick: onSubmit,
                disabled: !(isValid && dirty)
              }
            ]}
          >
            <ManageRateflowFormContent
              values={values}
              brands={brands ?? []}
              setFormField={setFormField}
            />
          </SidePanel>
        )
      }}
    </Formik>
  )
}

export default ManageRateflow
