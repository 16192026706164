import { FC, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ALLOWED_ROLES_BY_ROUTE } from "../../common/constants"
import { useAppSelector } from "../../common/hooks"
import { PageHeader } from "../../common/ui"
import PricingProfilesTable from "../../common/ui/pricing-profiles/table"
import RateDetails from "../../common/ui/rates/details"
import RatesTable from "../../common/ui/rates/table"
import { ToggleView } from "../../common/ui/table/toolbar"
import Tabs from "../../common/ui/tabs"
import { RequireRole } from "../../common/validation"
import { selectUserDetails } from "../../redux/slices/auth"
import {
  useFindDefaultPricingSetsQuery,
  useFindRateflowLogsByIdQuery,
  useFindRateflowPricesByLoQuery
} from "../../redux/queries"
import { ViewType } from "../../types"

const RatesPage: FC<{}> = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const user = useAppSelector(selectUserDetails)
  const { data: defaultPricingSets, isLoading: isLoadingDefaultPricingSets } =
    useFindDefaultPricingSetsQuery({
      loid: user?.id ?? ""
    })
  const {
    data: rates,
    isLoading: isLoadingRates,
    isFetching: isFetchingRates,
    fulfilledTimeStamp,
    refetch
  } = useFindRateflowPricesByLoQuery(
    { loid: user?.id ?? "", max_results: 999 },
    { skip: !user?.id }
  )

  const rateflowLogIds = rates?.map(rate => rate.rateflow_log_id.toString())

  const { data: rateflowLogs } = useFindRateflowLogsByIdQuery(
    rateflowLogIds ?? [],
    {
      skip: !rateflowLogIds?.length
    }
  )

  const [tab, setTab] = useState<string>("rates")
  const [viewType, setViewType] = useState<ViewType>("grid")

  const tableData = useMemo(() => {
    if (!rates?.length) return []
    return rates.map(rate => {
      if (!rate.loan_ammount) {
        const loanAmount = rateflowLogs?.find(
          rfl => rfl.id === rate.rateflow_log_id.toString()
        )?.full_request.loanInformation.baseLoanAmount
        return {
          ...rate,
          loan_ammount: loanAmount
        }
      }
      return rate
    })
  }, [rateflowLogs, rates])

  const selectItem = (id: string) => navigate(`/rates/${id}`)
  const clearSelectedItem = () => navigate("/rates")

  return (
    <RequireRole allowedRoles={ALLOWED_ROLES_BY_ROUTE["rates"]}>
      <>
        <div className="h-full flex flex-col">
          <div className="pt-6 bg-white">
            <div className="px-6">
              <PageHeader title="Rates"></PageHeader>
            </div>
            <div className="relative pt-2">
              <Tabs
                className="px-6"
                currentTab={tab}
                tabs={[
                  { id: "rates", label: "My rates" },
                  { id: "pricing-profiles", label: "My pricing profiles" }
                ]}
                onChange={setTab}
              />
              {tab === "rates" && (
                <ToggleView
                  className="ml-auto absolute right-6 bottom-3"
                  viewType={viewType}
                  setViewType={setViewType}
                />
              )}
            </div>
          </div>
          <div className="p-6 flex-1">
            {tab === "rates" && (
              <RatesTable
                isLoading={isLoadingRates || isFetchingRates}
                data={tableData ?? []}
                refreshRates={refetch}
                lastFetched={fulfilledTimeStamp}
                viewType={viewType}
                onClickItem={selectItem}
              />
            )}
            {tab === "pricing-profiles" && (
              <PricingProfilesTable
                data={defaultPricingSets?.pricing_profiles ?? []}
                isLoading={isLoadingDefaultPricingSets}
              />
            )}
          </div>
        </div>

        <RateDetails id={id} onClose={clearSelectedItem} />
      </>
    </RequireRole>
  )
}

export default RatesPage
