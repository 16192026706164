import { Plus } from "lucide-react"
import { ALLOWED_ROLES_BY_ROUTE } from "../../common/constants"
import { PageHeader, PrimaryBtn } from "../../common/ui"
import { RequireRole } from "../../common/validation"
import { useFetchAllBrandsQuery } from "../../redux/queries"
import BrandsGrid from "../../common/ui/brands/grid"
import { useAppDispatch, useAppSelector } from "../../common/hooks"
import { checkIsSuperAdmin } from "../../common/utils"
import { selectUserDetails } from "../../redux/slices/auth"
import { setVisiblePanel } from "../../redux/slices/app"

type Props = {
  title?: string
}

const BrandsPage: React.FC<Props> = ({ title = "Brands" }) => {
  const dispatch = useAppDispatch()
  const userDetails = useAppSelector(selectUserDetails)
  const isSuperAmdmin = checkIsSuperAdmin(userDetails?.roles ?? [])

  const { data: brands = [], isLoading } = useFetchAllBrandsQuery({
    limit: 999,
    org_id: isSuperAmdmin ? "" : userDetails?.org?.id
  })

  const openManageBrandSidebar = () => dispatch(setVisiblePanel("brand/new"))

  return (
    <RequireRole allowedRoles={ALLOWED_ROLES_BY_ROUTE.brands}>
      <>
        <PageHeader className="p-6 pb-0 bg-white" title={title}>
          <PrimaryBtn onClick={openManageBrandSidebar}>
            <Plus size={16} />
            &nbsp;New Brand
          </PrimaryBtn>
        </PageHeader>

        <BrandsGrid data={brands} isLoading={isLoading} />
      </>
    </RequireRole>
  )
}

export default BrandsPage
