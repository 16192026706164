import Skeleton from "./base"

const SingleRowList = () => {
  return (
    <div className="p-6 h-full overflow-hidden">
      {[...Array(12)].map((_, index) => (
        <div key={index} className="bg-white border-b border-gray-100">
          <Skeleton className="w-full py-6 px-8">
            <div className="flex justify-between">
              <div className="h-4 bg-gray-100 rounded-full w-80 mb"></div>
              <div className="h-4 bg-gray-100 rounded-full w-24 ml-4 mb-1.5"></div>
            </div>
          </Skeleton>
        </div>
      ))}
    </div>
  )
}

export default SingleRowList
