import { FC } from "react"
import { SubscriptionDetails } from "../../../../../../../types"
import SectionCell from "../../../../../section/cell"

interface Props {
  data: SubscriptionDetails
}

const LoanScenario: FC<Props> = ({ data }) => {
  const {
    quote_info: {
      loan_amount,
      loan_purpose,
      loan_type,
      loan_term,
      arm_term,
      property_type,
      list_price,
      residency_type,
      credit_score
    }
  } = data
  return (
    <div className="grid sm:grid-cols-3 gap-4">
      <SectionCell label="Loan purpose" value={loan_purpose} />
      <SectionCell label="Loan type" value={loan_type} />
      <SectionCell label="Loan term" value={loan_term} />
      <SectionCell label="Arm term" value={arm_term} />
      <SectionCell label="Loan amount" value={loan_amount} />
      <SectionCell label="Property value" value={list_price} />
      <SectionCell label="Credit score" value={credit_score} />
      <SectionCell label="Property type" value={property_type} />
      <SectionCell label="Property usage" value={residency_type} />
    </div>
  )
}

export default LoanScenario
