import { FC, RefObject, useEffect, useRef, useState } from "react"
import { Formik, FormikProps } from "formik"
import { object, string } from "yup"
import { Organization } from "../../../../types"
import SidePanel from "../../side-panel"
import { useAppDispatch } from "../../../hooks"
import { pushToast } from "../../../../redux/slices/notifications"
import { REGEX_URL_WILDCARD } from "../../../constants"
import { useUpdateOrganizationMutation } from "../../../../redux/queries"
import EditOrgFormContent from "./form-content"

interface Props {
  data?: Organization
  open: boolean
  isInSidePanel?: boolean
  onClose: () => void
  setFormRef?: (formRef: RefObject<FormikProps<Organization>>) => void
}

const OrgSchema = object({
  website: string().test("url", "Invalid URL", value => {
    if (!value) {
      return true
    } else {
      return REGEX_URL_WILDCARD.test(value)
    }
  }),
  name: string().required().min(1)
})

const EditOrganization: FC<Props> = ({
  data,
  open,
  isInSidePanel,
  onClose,
  setFormRef
}) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const [updateOrg, { isSuccess, isError }] = useUpdateOrganizationMutation()

  const formRef = useRef<FormikProps<Organization>>(null)

  const initialValues = data ?? {
    website: "",
    name: "",
    id: "",
    about: "",
    logo: "",
    created: "",
    changed: "",
    disclaimer: "",
    fb_share_desc: "",
    msa_template: "",
    ob_biz_channel: "",
    disabled: false
  }

  const setFormField = (name: string, val: string | number) => {
    formRef.current?.setFieldValue(name, val)
  }

  const resetForm = () => {
    setLoading(false)
    formRef.current?.resetForm()
  }

  const onSubmit = async () => {
    if (formRef.current?.values) {
      // Update org details
      updateOrg(formRef.current.values)
      resetForm()
      onClose()
    }
  }

  useEffect(() => {
    if (formRef && setFormRef) {
      setFormRef(formRef)
    }
  }, [setFormRef])

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        pushToast({
          msg: "Organization has been updated successfully",
          timeout: 3000
        })
      )
    }
  }, [dispatch, isSuccess])

  useEffect(() => {
    if (isError) {
      dispatch(
        pushToast({
          msg: "Failed to update organization",
          timeout: 3000,
          theme: "danger"
        })
      )
    }
  }, [dispatch, isError])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      innerRef={formRef}
      validationSchema={OrgSchema}
      enableReinitialize
    >
      {({ values, isValid, dirty }) => {
        return isInSidePanel ? (
          <SidePanel
            title="Edit organization"
            open={open}
            onClose={onClose}
            actions={[
              {
                label: "Save changes",
                type: "primary",
                onClick: onSubmit,
                disabled: loading || !(isValid && dirty)
              }
            ]}
          >
            <EditOrgFormContent values={values} setFormField={setFormField} />
          </SidePanel>
        ) : (
          <EditOrgFormContent values={values} setFormField={setFormField} />
        )
      }}
    </Formik>
  )
}

export default EditOrganization
