import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { RootState } from "../store"
import { TabRangeState, EngagementsTab, DateRange } from "../../types"

export const engagementsSliceName = "engagements"

export interface EngagementsState {
  tabs: {
    range: TabRangeState
  }
}

const initialState: EngagementsState = {
  tabs: {
    range: Object.values(EngagementsTab).reduce((acc, key) => {
      acc[key] = key === EngagementsTab.activity ? 30 : 7
      return acc
    }, {} as TabRangeState)
  }
}

const engagementsSlice = createSlice({
  name: engagementsSliceName,
  initialState,
  reducers: {
    updateRange: (
      state,
      action: PayloadAction<{
        tabKey: EngagementsTab
        newValue: DateRange
      }>
    ) => {
      const { tabKey, newValue } = action.payload
      state.tabs.range[tabKey] = newValue
    }
  }
})

const { reducer, actions } = engagementsSlice

export const { updateRange } = actions

export const selectTabRange = (state: RootState, tabKey: EngagementsTab) =>
  state.engagements.tabs.range[tabKey]

export const engagementsReducer = reducer
