import { FC, useState } from "react"
import BBModal from "../modal"
import { BBColumnDef, Brand, LoanOfficer } from "../../../types"
import Table from "../table"
import BrandAutocomplete from "../brand-autocomplete"
import { useImportLoMemberMutation } from "../../../redux/queries"
import { Button, PrimaryBtn } from "../buttons"
import { useAppDispatch } from "../../hooks"
import { pushToast } from "../../../redux/slices/notifications"

interface Props {
  rows: LoanOfficer[]
  show: boolean
  brands: Brand[]
  onClose: () => void
}

const ImportMembersModal: FC<Props> = ({ show, rows, brands, onClose }) => {
  const dispatch = useAppDispatch()

  const [importMember] = useImportLoMemberMutation()

  const [selectedBrandId, setSelectedBrandId] = useState<string>("")

  const importRows = async () => {
    try {
      if (!selectedBrandId) return

      await Promise.all(
        rows.map(row => {
          return importMember({
            brand_id: selectedBrandId,
            row
          }).unwrap()
        })
      )

      dispatch(
        pushToast({
          msg: "Successfully imported LOs",
          timeout: 3000,
          theme: "info"
        })
      )

      onClose()
    } catch (error) {
      dispatch(
        pushToast({
          msg: "Failed to import some LOs",
          timeout: 3000,
          theme: "danger"
        })
      )
    }
  }

  const columns: BBColumnDef<LoanOfficer>[] = [
    {
      id: "first_name",
      header: "First Name",
      accessorFn: row => row.first_name,
      footer: props => props.column.id,
      cell: props => (
        <td className="px-2 font-normal text-sm text-neutral-500">
          {props.row.original.first_name}
        </td>
      )
    },
    {
      id: "last_name",
      header: "Last Name",
      accessorFn: row => row.last_name,
      footer: props => props.column.id,
      cell: props => (
        <td className="px-3 font-normal text-sm text-neutral-500">
          {props.row.original.last_name}
        </td>
      )
    },
    {
      id: "nmls",
      header: "NMLS",
      accessorFn: row => row.nmls,
      footer: props => props.column.id,
      cell: props => (
        <td className="px-3 font-normal text-sm text-neutral-500">
          {props.row.original.nmls}
        </td>
      )
    },
    {
      id: "email",
      header: "Email",
      accessorFn: row => row.email,
      footer: props => props.column.id,
      cell: props => (
        <td className="px-3 font-normal text-sm text-neutral-500 truncate">
          {props.row.original.email}
        </td>
      )
    }
  ]

  return (
    <BBModal
      show={show}
      title="Import Loan Officers"
      onClose={onClose}
      size="2xl"
    >
      <div className="pb-8 z-100">
        <BrandAutocomplete
          brands={brands}
          selected={selectedBrandId}
          onChange={setSelectedBrandId}
        />
      </div>
      <div className="py-4">
        <Table
          data={rows}
          columns={columns}
          viewType="table"
          searchPlaceholder="Search LO's by name, email or brand…"
          shrinkFilterSort
        />
      </div>

      <div className="flex justify-end pt-6">
        <Button className="mr-3" onClick={onClose}>
          Close
        </Button>
        <PrimaryBtn
          disabled={!selectedBrandId || !rows.length}
          onClick={importRows}
        >
          Import
        </PrimaryBtn>
      </div>
    </BBModal>
  )
}

export default ImportMembersModal
