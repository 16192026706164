import { FC } from "react"
import { Lead } from "../../../../../../../types"
import SectionCell from "../../../../../section/cell"

interface Props {
  data: Lead
}

const PropertyLocation: FC<Props> = ({ data }) => {
  const {
    details: {
      location: { state, city, street, county, zipcode }
    }
  } = data
  return (
    <div className="grid grid-cols-2 gap-4">
      <SectionCell label="Location" value={street || county} />
      <SectionCell label="City" value={city} />
      <SectionCell label="Zipcode" value={zipcode} />
      <SectionCell label="State" value={state} />
    </div>
  )
}

export default PropertyLocation
