import TdInfoCell from "../../../table/cells/td-info-cell"
import {
  BBColumnDef,
  LeadCountByCity,
  LeadCountByState
} from "../../../../../types"

export const getLeadByStateColumns = (): BBColumnDef<LeadCountByState>[] => [
  {
    id: "state",
    header: "State",
    accessorFn: row => row.state,
    footer: props => props.column.id,
    cell: props => (
      <TdInfoCell
        info={props.row.original.state}
        customContainerClass="p-0"
        customTextClass="mr-2 my-3 py-1 text-sm font-medium leading-6 text-ellipsis overflow-hidden"
      />
    )
  },
  {
    id: "leads",
    header: "Leads",
    accessorFn: row => row.lead_count,
    footer: props => props.column.id,
    cell: props => (
      <TdInfoCell
        info={props.row.original.lead_count?.toLocaleString()}
        customContainerClass="p-0"
        customTextClass="mr-2 my-3 py-1 text-sm leading-6"
      />
    )
  },
  {
    id: "percentages",
    header: "%",
    accessorFn: row => row.percentage,
    footer: props => props.column.id,
    cell: props => (
      <TdInfoCell
        info={`${props.row.original.percentage}%`}
        customContainerClass="p-0"
        customTextClass="my-3 py-1 text-sm leading-6 text-center"
        customTextStyle={{
          background: `${props.row.original.color}`
        }}
      />
    )
  }
]

export const getLeadByCityColumns = (): BBColumnDef<LeadCountByCity>[] => [
  {
    id: "city",
    header: "City",
    accessorFn: row => row.city,
    footer: props => props.column.id,
    cell: props => (
      <TdInfoCell
        info={props.row.original.city}
        customContainerClass="p-0"
        customTextClass="mr-2 my-3 py-1 text-sm font-medium leading-6 text-ellipsis overflow-hidden"
      />
    )
  },
  {
    id: "leads",
    header: "Leads",
    accessorFn: row => row.lead_count,
    footer: props => props.column.id,
    cell: props => (
      <TdInfoCell
        info={props.row.original.lead_count?.toLocaleString()}
        customContainerClass="p-0"
        customTextClass="mr-2 my-3 py-1 text-sm leading-6"
      />
    )
  },
  {
    id: "percentages",
    header: "%",
    accessorFn: row => row.percentage,
    footer: props => props.column.id,
    cell: props => (
      <TdInfoCell
        info={`${props.row.original.percentage}%`}
        customContainerClass="p-0"
        customTextClass="my-3 py-1 text-sm leading-6 text-center"
        customTextStyle={{
          background: `${props.row.original.color}`
        }}
      />
    )
  }
]
