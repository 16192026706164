import { FC, Fragment, useEffect, useMemo, useState } from "react"

import If from "../../../if"
import GraphSkeleton from "../../../skeletons/graph"
import NoData from "../../../no-data"
import FunnelChart from "../../../funnel-chart"
import { useFetchLeadConversionFunnelQuery } from "../../../../../redux/queries"
import { useFunnelStagesTransformation } from "../../../../hooks"

interface LeadConversionFunnelProps {
  title: string
  range: number
  orgId: string | undefined
  loid: string | undefined
}

const LeadConversionFunnel: FC<LeadConversionFunnelProps> = ({
  title,
  range,
  orgId,
  loid
}) => {
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const { data: leadFunnelData, isFetching: isFetchingLeadFunnel } =
    useFetchLeadConversionFunnelQuery(
      {
        range,
        org_id: orgId,
        loid
      },
      { refetchOnMountOrArgChange: true }
    )
  const containsData = useMemo(
    () => leadFunnelData && Object.keys(leadFunnelData).length > 0,
    [leadFunnelData]
  )
  const funnelStagesData = useFunnelStagesTransformation(
    leadFunnelData,
    containsData
  )

  useEffect(() => {
    setIsFetching(isFetchingLeadFunnel)
  }, [isFetchingLeadFunnel])

  return (
    <div className="bg-white flex flex-col flex-1 px-6 pt-6 pb-7 rounded-md overflow-hidden">
      <div className="flex flex-row items-center justify-between">
        <div className="text-base font-medium">{title}</div>
      </div>
      <If
        condition={isFetching}
        then={<GraphSkeleton className="mt-1 pt-12" />}
        else={
          <Fragment>
            {!containsData && <NoData />}
            {containsData && (
              <div className="mt-0.5 pt-16">
                <FunnelChart stages={funnelStagesData} />
              </div>
            )}
          </Fragment>
        }
      />
    </div>
  )
}

export default LeadConversionFunnel
