import { FC, useMemo } from "react"
import { Mail, Activity, FileText } from "lucide-react"
import { SubscriptionEventType } from "../../../../../../types"

interface Props {
  type: SubscriptionEventType
  color: string
}

const EventIcon: FC<Props> = ({ type, color }) => {
  const Icon = useMemo(() => {
    if (type === "intro_email_sent" || type === "rate_alert_email_sent") {
      return Mail
    }
    if (type === "dashboard_first_visit" || type === "dashboard_pageview") {
      return Activity
    }

    return FileText
  }, [type])

  return (
    <div className="rounded-full bg-neutral-500 p-3">
      <Icon color={color} />
    </div>
  )
}

export default EventIcon
