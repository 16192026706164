import { DragEvent, FC } from "react"
import {
  useImportCsvMutation,
  useFetchBrandsQuery
} from "../../../redux/queries"
import DropZone from "../drop-zone"
import LOsTable from "./table"
import ImportMembersModal from "../import-members-modal"
import { useAppSelector } from "../../hooks"
import { selectUserDetails, selectUserRoles } from "../../../redux/slices/auth"
import { checkIsAdmin } from "../../utils"
import If from "../if"
import { LoSearchParams } from "../../../types"

interface Props {
  searchParams: LoSearchParams
  onClickLO?: (loID: string) => void
  hasNewButton?: boolean
  hasViewTypeToggle?: boolean
  hasMasqueradeIcon?: boolean
}

const TableWithImporter: FC<Props> = ({
  searchParams,
  onClickLO,
  hasViewTypeToggle = false,
  hasNewButton = false,
  hasMasqueradeIcon = false
}) => {
  const user = useAppSelector(selectUserDetails)

  const [importCsv, { data: importCsvResponse, reset }] = useImportCsvMutation()

  const { data: brands } = useFetchBrandsQuery(
    { org_id: user?.org?.id },
    { skip: !user?.org?.id }
  )

  const userRoles = useAppSelector(selectUserRoles)
  const isAdmin = checkIsAdmin(userRoles)

  const onDropFile = (e: DragEvent<HTMLDivElement>) => {
    const file = e.dataTransfer.files[0]
    importCsv(file)
  }

  return (
    <div className="p-6 bg-white">
      <If
        condition={isAdmin}
        then={
          <DropZone onDrop={onDropFile}>
            <LOsTable
              searchParams={searchParams}
              hasNewButton={hasNewButton}
              hasViewTypeToggle={hasViewTypeToggle}
              hasMasqueradeIcon={hasMasqueradeIcon}
              {...(onClickLO && { onClickLO })}
            />
          </DropZone>
        }
        else={
          <LOsTable
            searchParams={searchParams}
            hasNewButton={hasNewButton}
            hasViewTypeToggle={hasViewTypeToggle}
            {...(onClickLO && { onClickLO })}
          />
        }
      />

      <ImportMembersModal
        show={!!importCsvResponse?.length}
        rows={importCsvResponse ?? []}
        brands={brands ?? []}
        onClose={reset}
      />
    </div>
  )
}

export default TableWithImporter
