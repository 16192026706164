import { FC } from "react"
import { PricingRate, BBColumnDef, ViewType } from "../../../../types"
import { PrimaryBtn } from "../../buttons"
import Table from "../../table"
import ActionCell from "./cells/Action"
import AprCell from "./cells/Apr"
import LabelCell from "./cells/Label"
import PtsCell from "./cells/Pts"
import RateCell from "./cells/Rate"
import RateCard from "./card"
import ListSkeleton from "../../skeletons/normal-list"
import CardSkeleton from "../../skeletons/rate-card"
import If from "../../if"
import { formatDateTimeFromMillis } from "../../../utils"

interface Props {
  isLoading: boolean
  data: PricingRate[]
  lastFetched?: number
  viewType: ViewType
  onClickItem: (id: string) => void
  isRefreshRates?: boolean
  refreshRates: () => void
}

const RatesTable: FC<Props> = ({
  isLoading,
  data,
  onClickItem,
  lastFetched,
  viewType,
  isRefreshRates = true,
  refreshRates
}) => {
  const columns: BBColumnDef<PricingRate>[] = [
    {
      id: "label",
      header: "Label",
      footer: props => props.column.id,
      cell: props => (
        <LabelCell value={props.row.original} index={props.row.index} />
      ),
      colSpan: 3
    },
    {
      id: "rate",
      header: "Rate",
      footer: props => props.column.id,
      cell: props => <RateCell value={props.row.original} />
    },
    {
      id: "apr",
      header: "APR",
      footer: props => props.column.id,
      cell: props => <AprCell value={props.row.original} />
    },
    {
      id: "pts",
      header: "PTS",
      footer: props => props.column.id,
      cell: props => <PtsCell value={props.row.original} />
    },
    {
      id: "action",
      header: "Action",
      footer: props => props.column.id,
      cell: props => (
        <ActionCell value={props.row.original} onClick={onClickItem} />
      )
    }
  ]

  return (
    <div className="h-full flex flex-col">
      <div
        className={`${
          isRefreshRates ? "flex" : "hidden"
        } pb-6 flex items-center justify-between`}
      >
        <div className="font-medium text-neutral-400">
          {lastFetched && (
            <span>
              Rates as of&nbsp;
              {formatDateTimeFromMillis(lastFetched, "MMM dd yyyy hh:mm:ss a")}
            </span>
          )}
        </div>
        <div>
          <PrimaryBtn disabled={isLoading} onClick={refreshRates}>
            Refresh rates
          </PrimaryBtn>
        </div>
      </div>
      <div className="flex-1">
        {isLoading ? (
          <If
            condition={viewType === "table"}
            then={<ListSkeleton />}
            else={<CardSkeleton />}
          />
        ) : (
          <div className="bg-white">
            <Table
              data={data}
              columns={columns}
              viewType={viewType}
              pageSize={10}
              searchBarVisible={false}
              renderCard={props => (
                <RateCard {...props} onClick={onClickItem} />
              )}
              noHeader
              gridClass="py-4 grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3"
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default RatesTable
