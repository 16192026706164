import { FC } from "react"
import { Brand, AllReviews, GooglePlace, ZillowReview } from "../../../../types"
import GoogleReviewsList from "../../google-reviews-list"
import BrandOverview from "./BrandOverview"
import ExperienceReviewsList from "../../experience-reviews-list"
import ZillowReviewsList from "../../zillow-reviews-list"
import YelpReviewsList from "../../yelp-reviews-list"

interface Props {
  brand: Brand
  review?: AllReviews
  googlePlace?: GooglePlace
}

const BrandDetails: FC<Props> = ({ brand, review, googlePlace }) => {
  return (
    <div className="p-6 max-w-3xl">
      <BrandOverview brand={brand} />
      <GoogleReviewsList place={googlePlace} reviews={review?.google} />
      <ZillowReviewsList
        reviews={review?.zillow?.reviews as ZillowReview[]}
        zillowBrandName={brand.zillow_brand_name}
      />
      <ExperienceReviewsList reviews={review?.experience} />
      <YelpReviewsList reviews={review?.yelp} />
    </div>
  )
}

export default BrandDetails
