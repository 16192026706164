import { api } from "../common/utils"
import { APIError } from "../types"

/**
 * Brands API wrapper
 */
const ENDPOINT_UPLOAD_IMAGE_S3 = "/api/v1/s3-file-upload"
const ENDPOINT_UPLOAD_IMAGE_BB = "/bb-file-upload.json"

const asset = {
  uploadImage: async (
    file: File,
    filename: string,
    useS3: boolean = true
  ): Promise<{ etag: string; filename: string; url: string } | APIError> => {
    const url = useS3 ? ENDPOINT_UPLOAD_IMAGE_S3 : ENDPOINT_UPLOAD_IMAGE_BB
    const form = new FormData()
    form.append("files[img]", file)

    const { data } = await api.post(url, form)
    return data
  }
}

export default asset
