import { FC, useState } from "react"

import {
  useFetchRateTableConfigDetailsQuery,
  useFetchRateTableConfigsQuery
} from "../../../../redux/queries"
import BrandAutocomplete from "../../brand-autocomplete"
import RateTableConfigTable from "./table"
import ManageRateTableConfig from "./manage"
import { Brand } from "../../../../types"

interface ConfigRateTableProps {
  brands: Brand[]
}

const ConfigRateTable: FC<ConfigRateTableProps> = ({ brands }) => {
  const [selectedBrandId, setSelectedBrandId] = useState<string>("")
  const { data, isFetching } = useFetchRateTableConfigsQuery(
    { brand_id: selectedBrandId ?? "" },
    { skip: !selectedBrandId }
  )

  const [selectedId, setSelectedId] = useState<string>()

  const { data: rateTableConfigDetails } = useFetchRateTableConfigDetailsQuery(
    { id: selectedId ?? "" },
    { skip: !selectedId }
  )

  const resetSelectedId = () => setSelectedId("")

  return (
    <div className="">
      <BrandAutocomplete
        brands={brands}
        selected={selectedBrandId}
        onChange={setSelectedBrandId}
      />

      <div className="pt-6">
        {selectedBrandId ? (
          <RateTableConfigTable
            data={data ?? []}
            isFetching={isFetching}
            onSelect={setSelectedId}
          />
        ) : null}
      </div>

      <ManageRateTableConfig
        open={!!(selectedId && rateTableConfigDetails)}
        data={rateTableConfigDetails}
        onClose={resetSelectedId}
      />
    </div>
  )
}

export default ConfigRateTable
