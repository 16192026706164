import { ChangeEvent, FC, useEffect, useState } from "react"
import { UserDetails } from "../../../../types"
import { Button, PrimaryBtn } from "../../buttons"

interface Props {
  info: UserDetails
  inEditMode: boolean
  toggleEdit: () => void
  onUpdateEmail: (newEmail: string) => void
}

const MyAccountInfo: FC<Props> = ({
  info,
  inEditMode,
  toggleEdit,
  onUpdateEmail
}) => {
  const { email, username, timezone } = info
  const [value, setValue] = useState<string>(email)

  const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  useEffect(() => {
    setValue(email)
  }, [email])

  return (
    <div className="pt-12">
      <div className="flex items-center justify-between pb-4 border-b border-neutral-200">
        <div className="text-lg font-bold">Account Information</div>
        <Button className="border-0" onClick={toggleEdit}>
          <div className="font-bold text-primary-500">
            {inEditMode ? "Cancel" : "Edit"}
          </div>
        </Button>
      </div>
      <div className="grid grid-cols-2 gap-4 pt-6">
        <div>
          <div className="text-neutral-400">Username</div>
          <div className="text-neutral-900">{username}</div>
        </div>
        <div>
          <div className="text-neutral-400">Email</div>
          {inEditMode ? (
            <input
              className="w-full max-w-xs border border-neutral-200 px-3 py-2 rounded-md"
              name="email"
              value={value}
              onChange={onChangeValue}
            />
          ) : (
            <div className="text-neutral-900">{email}</div>
          )}
        </div>
        <div>
          <div className="text-neutral-400">Timezone</div>
          <div className="text-neutral-900">{timezone}</div>
        </div>
        {inEditMode && (
          <div>
            <PrimaryBtn onClick={() => onUpdateEmail(value)}>
              Change Email
            </PrimaryBtn>
          </div>
        )}
      </div>
    </div>
  )
}

export default MyAccountInfo
