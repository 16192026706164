import { FocusEvent, ChangeEvent, KeyboardEvent } from "react"
import Input from "./input"
import { REGEX_HEX_COLOR } from "../../constants"

type Props = {
  name: string
  label: string
  value?: string
  required?: boolean
  onChangeWhell?: (e: ChangeEvent<HTMLInputElement>) => void
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
}

const InputColorPicker = ({
  name,
  label,
  value = "",
  required,
  onChange,
  onChangeWhell,
  onBlur
}: Props) => {
  const onChangeColor = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e)
  }

  const onChangeWhellColor = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeWhell?.(e)
  }

  const onChangeColorManual = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const newValue = e.currentTarget.value

      if (REGEX_HEX_COLOR.test(newValue)) {
        e.currentTarget.blur()
      }
    }
  }

  return (
    <>
      <Input
        inputProps={{
          required: required,
          value: value,
          onKeyPress: onChangeColorManual,
          onChange: onChangeColor,
          onBlur: onBlur,
          pattern: REGEX_HEX_COLOR
        }}
        label={label}
        name={name}
        placeholder="Ex. #000000"
        beforeIcon={
          value ? (
            <div
              className="h-6 w-6"
              style={{
                backgroundColor: value
              }}
            />
          ) : undefined
        }
        afterIcon={
          <label className="pallet-icon cursor-pointer">
            <input
              name={name}
              className="sr-only"
              type="color"
              onChange={onChangeWhellColor}
              value={value}
            />
          </label>
        }
      />
    </>
  )
}

export default InputColorPicker
