/**
  Loan Officer API wrapper

	GET /api/v1/members?loid={uid} - returns Details of a loan officer
**/

import { api } from "../common/utils"
import {
  LO_ENDPOINT,
  LO_IMPORT_ENDPOINT,
  LO_IMPORT_MEMBER_ENDPOINT,
  LO_MANAGE_ENDPOINT,
  LO_REVIEWS_ENDPOINT
} from "../common/constants"
import {
  APIError,
  LoanOfficer,
  AllReviews,
  CreateLOPayload,
  CreateLOResponse,
  UpdateLOPayload
} from "../types"

const loanOfficer = {
  get: async (lo_id: string): Promise<LoanOfficer | APIError> => {
    if (!lo_id) {
      throw new Error("lo id missing")
    }
    const { data } = await api.get(`${LO_ENDPOINT}?loid=${lo_id}`)
    return data.lo
  },
  reviews: async (lo_id: string): Promise<AllReviews | APIError> => {
    if (!lo_id) {
      throw new Error("Required params are missing")
    }
    const { data } = await api.get(`${LO_REVIEWS_ENDPOINT}?id=${lo_id}`)
    return data
  },
  createOrUpdate: async (profile: LoanOfficer): Promise<CreateLOResponse> => {
    const {
      about_me,
      mobile_phone,
      website_link,
      linkedin_link,
      facebook_link,
      special_disclosure_link,
      position,
      roles,
      branch_nmls,
      office_city,
      office_phone,
      office_state,
      office_street_addr,
      office_zipcode,
      first_name,
      last_name,
      email,
      nmls,
      originator_id,
      biz_channel,
      crm_type,
      crm_id,
      bank_nid,
      pricing_status,
      phone,
      app_link,
      picture,
      uid,
      loid
    } = profile

    const payload: CreateLOPayload | UpdateLOPayload = {
      about_me,
      mobile_phone,
      website_link,
      linkedin_link,
      facebook_link,
      special_disclosure_link,
      title: position,
      roles,
      branch_nmls,
      office_city,
      office_phone,
      office_state,
      office_street_addr,
      office_zipcode,
      first_name,
      last_name,
      email,
      nmls,
      originator_id,
      biz_channel,
      crm_entries: [{ crm_type, crm_id }],
      brand_id: bank_nid,
      pricing_status,
      phone,
      app_link,
      picture,
      loid: uid || loid
    }

    const { data } = await api.post(LO_ENDPOINT, payload)
    return data
  },
  delete: async (loid: string): Promise<any> => {
    const { data } = await api.delete(`${LO_MANAGE_ENDPOINT}?loid=${loid}`)
    return data
  },
  moveMember: async (
    loid: string,
    newBrandId: string
  ): Promise<any | APIError> => {
    if (!loid) {
      throw new Error("LO id is missing")
    }
    if (!newBrandId) {
      throw new Error("New brand id is missing")
    }
    const moveMemberData = {
      loid,
      bank_nid: newBrandId
    }

    const { data } = await api.post(LO_MANAGE_ENDPOINT, moveMemberData)
    return data
  },
  importMember: async (payload: {
    row: any
    brand_id: string
  }): Promise<any | APIError> => {
    if (!payload.brand_id) {
      throw new Error("Brand id is missing")
    }

    const { data } = await api.post(LO_IMPORT_MEMBER_ENDPOINT, payload)
    return data
  },
  importCsv: async (file: File): Promise<any> => {
    let formData = new FormData()
    formData.append("lo_list", file)
    const { data } = await api.post(LO_IMPORT_ENDPOINT, formData)
    return data
  }
}

export default loanOfficer
