import { useCallback, useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import {
  Menu,
  PlusCircle,
  Inspect,
  Contact,
  LogOut,
  Activity,
  AtSign,
  Code2,
  Landmark,
  LayoutDashboard,
  Users,
  UserCircle2
} from "lucide-react"
import { useAppDispatch, useAppSelector } from "../../hooks"
import {
  deleteMasquerade,
  logout,
  masquerade,
  selectProfileUpdated,
  selectUserDetails
} from "../../../redux/slices/auth"
import { checkIsAdmin, hasUserRole } from "../../utils"
import { AddNewDropdownItem, PanelType } from "../../../types"
import { selectVisiblePanel, setVisiblePanel } from "../../../redux/slices/app"
import { Dropdown, PanelsGroup, ImageWrapper, SearchAnything } from "../../ui"
import RoundedImg from "../../ui/rounded-img"

type Props = {
  setSidebarOpen: (sidebarOpen: boolean) => void
}

const Header = ({ setSidebarOpen }: Props) => {
  const dispatch = useAppDispatch()

  const user = useAppSelector(selectUserDetails)
  const profileUpdated = useAppSelector(selectProfileUpdated)
  const isMasq = user?.is_masq && user.is_masq !== "0"
  const isAdmin = checkIsAdmin(user?.roles ?? [])

  const [imgVersion, setImgVersion] = useState(0)

  useEffect(() => {
    if (profileUpdated) {
      setImgVersion(prev => prev + 1)
    }
  }, [profileUpdated])

  const visiblePanel = useAppSelector(selectVisiblePanel)

  const delMasquerade = useCallback(() => {
    dispatch(deleteMasquerade())
  }, [dispatch])

  const handleMasquerade = (loid: string) => {
    dispatch(masquerade(loid))
  }

  const delMasqItem = useMemo(
    () => ({
      name: "Switch Back",
      href: "#",
      icon: Inspect,
      onClick: delMasquerade
    }),
    [delMasquerade]
  )

  const handleLogout = useCallback(() => {
    dispatch(logout())
  }, [dispatch])

  const openPanel = useCallback(
    (panelType: PanelType) => {
      dispatch(setVisiblePanel(panelType))
    },
    [dispatch]
  )

  const profileDropdownItems = useMemo(
    () => [
      { name: "My account", href: "/account", icon: Contact },
      { name: "Logout", onClick: handleLogout, icon: LogOut }
    ],
    [handleLogout]
  )

  const profileItems = useMemo(
    () =>
      isMasq ? [delMasqItem, ...profileDropdownItems] : profileDropdownItems,
    [delMasqItem, isMasq, profileDropdownItems]
  )

  const addNewDropdownItems: AddNewDropdownItem[] = useMemo(
    () => [
      {
        name: "Customer",
        icon: LayoutDashboard,
        requiredRole: "administrator",
        onClick: () => openPanel("organization/new")
      },
      {
        name: "Brand",
        icon: Landmark,
        requiredRole: "bank admin",
        onClick: () => openPanel("brand/new")
      },
      {
        name: "Loan officer",
        icon: Users,
        requiredRole: "bank admin",
        onClick: () => openPanel("loan-officer/new")
      },
      {
        name: "Integration",
        icon: Code2,
        requiredRole: "bank admin",
        onClick: () => openPanel("integration/new")
      },
      {
        name: "Engagement",
        icon: AtSign,
        requiredRole: "loan officer",
        onClick: () => openPanel("engagement/new")
      }
    ],
    [openPanel]
  )

  const newDropdownItems = useMemo(
    () =>
      addNewDropdownItems.filter(item =>
        hasUserRole(user?.roles ?? [], item.requiredRole)
      ),
    [addNewDropdownItems, user?.roles]
  )

  if (!user) return null

  return (
    <div className="sticky top-0 z-40 flex-shrink-0 flex h-16 bg-white shadow">
      <button
        type="button"
        className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <Menu size={20} aria-hidden="true" />
      </button>
      <div className="flex-1 px-4 flex justify-between">
        <div className="flex-1 flex">
          <SearchAnything
            isAdmin={isAdmin}
            handleMasquerade={handleMasquerade}
          />
        </div>
        <div className="ml-2 lg:ml-6 flex items-center">
          <Link to="/account/recent-activity">
            <div className="flex items-center mr-2 lg:mr-6">
              <Activity size={20} className="mr-2" />
              <span className="hidden lg:inline">Recent Activity</span>
            </div>
          </Link>
          <Dropdown
            items={newDropdownItems}
            menuBtn={
              <div className="flex items-center">
                <PlusCircle size={20} className="mr-2" />
                <span className="hidden lg:inline">Add New</span>
              </div>
            }
          />

          <Dropdown
            className="ml-2 lg:ml-6"
            items={profileItems}
            menuBtn={
              <>
                <span className="sr-only">Open user menu</span>
                {user.picture ? (
                  <ImageWrapper>
                    <RoundedImg
                      size={8}
                      src={`${user.picture}?v=${imgVersion}`}
                      alt="user thumbnail"
                    />
                  </ImageWrapper>
                ) : (
                  <UserCircle2 size="32" aria-hidden="true" />
                )}
              </>
            }
          />
        </div>
      </div>

      <PanelsGroup visiblePanel={visiblePanel} />
    </div>
  )
}
export default Header
