import { useState } from "react"
import { SidePanelWithTabsProps } from "../../../types"
import SidePanel from "../side-panel"
import Tabs from "../tabs"

const SidePanelWithTabs = ({
  title,
  subTitle,
  open,
  onClose,
  tabs,
  isSubmitting
}: SidePanelWithTabsProps) => {
  const [tab, setTab] = useState<number>(0)

  const onChangeTab = (tabId: string) =>
    setTab(tabs.findIndex(tab => tab.id === tabId))

  const handleClose = () => {
    setTimeout(() => {
      onChangeTab(tabs[0].id) // Reset tab
    }, 1000)
    onClose()
  }

  const Content = tabs[tab].Content

  return (
    <SidePanel
      isSubmitting={isSubmitting}
      title={title}
      subTitle={subTitle}
      open={open}
      onClose={handleClose}
      actions={tabs[tab].actions}
      hasTabs
    >
      <Tabs currentTab={tabs[tab].id} tabs={tabs} onChange={onChangeTab} />
      <Content />
    </SidePanel>
  )
}

export default SidePanelWithTabs
