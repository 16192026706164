import { FC } from "react"
import { Loader2 } from "lucide-react"
import { useFindRateflowLogByIdQuery } from "../../../../redux/queries"
import SidePanel from "../../side-panel"
import LoanDetails from "./loan-details"
import OtherDetails from "./other-details"
import RatelogChart from "./ratelog-chart"
import If from "../../if"

interface Props {
  id?: string
  onClose: () => void
}

const RateDetails: FC<Props> = ({ id, onClose }) => {
  const { data, isFetching } = useFindRateflowLogByIdQuery(id ?? "", {
    skip: !id
  })

  return (
    <SidePanel title="Rate Details" open={!!id} onClose={onClose} actions={[]}>
      <div>
        <RatelogChart profileId={data?.ob_profile_id} />
        <If
          condition={isFetching}
          then={
            <div className="w-full py-20 flex items-center justify-center">
              <Loader2
                className="animate-spin h-16 w-16 text-primary-alt-400"
                aria-hidden="true"
              />
            </div>
          }
          else={
            <>
              <LoanDetails data={data} />
              <OtherDetails data={data} />
            </>
          }
        />
      </div>
    </SidePanel>
  )
}

export default RateDetails
