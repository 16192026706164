import { FC } from "react"
import { Integration } from "../../../../../types"
import { useIntegrationSettingsForm } from "../../../../hooks"
import InputWithSwitch from "../../../form/input-with-switch"

interface Props {
  integration?: Integration
}

const GoogleAnalyticsTag: FC<Props> = ({ integration }) => {
  const { saveSetting, saveSettingLocal, app_settings } =
    useIntegrationSettingsForm(integration)

  const onChange = (name: string, value: string) => {
    saveSetting({ [name]: value })
  }

  const onInputChange = (name: string, value: string) => {
    saveSettingLocal({ [name]: value })
  }

  return (
    <div className="pt-6">
      <InputWithSwitch
        onInputBlur={onChange}
        onInputChange={onInputChange}
        onSwitchChange={onChange}
        inputProps={{
          value: app_settings?.settings?.ga_tag ?? "",
          name: "ga_tag",
          label: "Google analytics tag"
        }}
        switchProps={{
          value: app_settings?.settings?.ga_mode ?? "",
          name: "ga_mode",
          label: "This is a external tag",
          options: ["internal", "external"]
        }}
        placeholder="Ex. G-T3BZ6029NZ"
      />
    </div>
  )
}

export default GoogleAnalyticsTag
