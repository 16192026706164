import { FC } from "react"
import { Mail } from "lucide-react"
import { SubscriptionEmailLog } from "../../../../../../types"
import Badge from "../../../../badge"
import { formatDateTimeFromTimestamp } from "../../../../../utils"

interface Props {
  log: SubscriptionEmailLog
}

const EngagementEmailLog: FC<Props> = ({ log }) => {
  if (!log) return null

  const { status, timestamp } = log

  return (
    <div className="flex items-center justify-between py-4 max-w-xl">
      <div className="flex items-center">
        <div className="rounded-full bg-neutral-500 p-3">
          <Mail color="#fff" />
        </div>
        <div className="ml-4">
          <span className="font-bold">Rate alert</span> sent to lead
          <span className="ml-2">
            {status === "0" ? (
              <Badge.Info>Unopened</Badge.Info>
            ) : status === "1" ? (
              <Badge.Danger>Opened</Badge.Danger>
            ) : (
              <Badge.Success>Clicked</Badge.Success>
            )}
          </span>
        </div>
      </div>

      <div className="text-neutral-400">
        {formatDateTimeFromTimestamp(timestamp, "LL/dd/yyyy 'at' hh:mm:ss a")}
      </div>
    </div>
  )
}

export default EngagementEmailLog
