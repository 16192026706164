import { FC, useMemo, useState } from "react"
import { Loader2 } from "lucide-react"
import { DateTime } from "luxon"
import { useFetchSubscriptionQuotesQuery } from "../../../../../../redux/queries"
import {
  SubscriptionDetails,
  LoanOfficer,
  FormattedRateflowCard
} from "../../../../../../types"
import {
  formatRateflowCard,
  getSessionIdFromLocalStorage
} from "../../../../../utils"
import DatePicker from "../../../../date-picker"
import Select from "../../../../form/select"
import QuoteDetails from "./quote-details"
import QuotesGroup from "./quotes-group"

interface Props {
  data: SubscriptionDetails
  loDetails: LoanOfficer
}

const RANGE_OPTIONS = [
  {
    key: "last_five",
    name: "Last five quotes"
  },
  {
    key: "custom",
    name: "Custom dates"
  }
]

const EngagementQuoteHistory: FC<Props> = ({ data }) => {
  const sessionId = getSessionIdFromLocalStorage()

  const [range, setRange] = useState<"last_five" | "custom">("last_five")
  const [startDate, setStartDate] = useState<Date | null>(new Date())
  const [endDate, setEndDate] = useState<Date | null>(new Date())
  const [selectedId, setSelectedId] = useState<string | null>()

  const resetSelectedId = () => setSelectedId(null)

  const timestampMin = useMemo(() => {
    if (range === "last_five") {
      return Math.floor(
        DateTime.local().startOf("day").minus({ days: 1000 }).toMillis() / 1000
      )
    }
    return startDate
      ? Math.floor(
          DateTime.fromMillis(startDate.getTime()).startOf("day").toMillis() /
            1000
        )
      : undefined
  }, [range, startDate])

  const timestampMax = useMemo(() => {
    if (range === "last_five") return undefined
    return endDate
      ? Math.floor(
          DateTime.fromMillis(endDate.getTime()).endOf("day").toMillis() / 1000
        )
      : undefined
  }, [endDate, range])

  const { data: quotes, isFetching } = useFetchSubscriptionQuotesQuery(
    {
      subscription_id: data?.id,
      timestamp_min: timestampMin,
      timestamp_max: timestampMax
    },
    { skip: !data?.id || !sessionId, refetchOnMountOrArgChange: true }
  )

  const selectedRange = RANGE_OPTIONS.find(r => r.key === range)

  const quotesToShow = useMemo(() => {
    if (!quotes) return []

    const sortedQuotes = Object.keys(quotes)
      .map(k => ({
        ...quotes[k],
        quoteId: k
      }))
      .sort((a, b) => (b.timestamp ?? 0) - (a.timestamp ?? 0))

    if (range === "last_five") {
      return sortedQuotes.slice(0, 5)
    }

    return sortedQuotes
  }, [quotes, range])

  const allCards = useMemo(() => {
    if (!quotes) return []
    return Object.keys(quotes).reduce(
      (acc: FormattedRateflowCard[], current) => {
        const quote = quotes[current]
        return [
          ...acc,
          ...quote.cards.map(c =>
            formatRateflowCard({
              ...c,
              timestamp: quote.timestamp ?? 0
            })
          )
        ]
      },
      []
    )
  }, [quotes])

  const selectedQuote = useMemo(
    () => allCards.find(card => card.hash === selectedId?.toString()),
    [allCards, selectedId]
  )

  const loanAmount = data.quote_info?.loan_amount ?? 0

  return (
    <div>
      <div className="flex items-center">
        <span className="mr-4">Showing quotes of</span>
        <div className="w-60">
          <Select
            label=""
            IDkey="key"
            data={RANGE_OPTIONS}
            selected={selectedRange}
            setSelected={setRange}
          />
        </div>
        {range === "custom" && (
          <div className="ml-6 flex-1">
            <div className="flex items-center">
              <div className="mr-3 whitespace-nowrap">Start date</div>
              <div className="">
                <DatePicker selected={startDate} onChange={setStartDate} />
              </div>

              <div className="mx-3 whitespace-nowrap">End date</div>
              <div className="">
                <DatePicker selected={endDate} onChange={setEndDate} />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="py-4">
        {isFetching ? (
          <div className="w-full py-20 flex items-center justify-center">
            <Loader2
              className="animate-spin h-16 w-16 text-primary-alt-400"
              aria-hidden="true"
            />
          </div>
        ) : quotesToShow?.length ? (
          quotesToShow.map(q => (
            <QuotesGroup
              key={q.quoteId}
              {...q}
              loanAmount={loanAmount}
              onSelect={setSelectedId}
            />
          ))
        ) : (
          <div className="py-4">No quotes found.</div>
        )}
      </div>

      <QuoteDetails
        open={!!selectedQuote}
        onClose={resetSelectedId}
        data={selectedQuote}
        loanAmount={loanAmount}
      />
    </div>
  )
}

export default EngagementQuoteHistory
