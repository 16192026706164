import React, { memo, useState } from "react"
import Tooltip from "../tool-tip"

export type ImageWrapperProps = {
  children: React.ReactElement
  isInitials?: boolean
  initials?: string
}

const ImageWrapper: React.FC<ImageWrapperProps> = memo(props => {
  const [loadingError, setLoadingError] = useState(false)

  const handleLoadError = (e: Error) => {
    setLoadingError(true)
  }

  const handleOnLoad = () => {
    setLoadingError(false)
  }
  return (
    <>
      {loadingError ? (
        props.isInitials ? (
          <div
            className={
              props.children.props.className +
              " inline-flex overflow-hidden relative justify-center items-center w-10 h-10 bg-gray-100 rounded-full dark:bg-gray-600"
            }
          >
            <span className="font-medium text-gray-600 dark:text-gray-300 h-full flex justify-center items-center">
              {props.initials}
            </span>
          </div>
        ) : (
          <Tooltip
            toolTipNode={props.children}
            text={props.children.props.alt}
          />
        )
      ) : (
        React.cloneElement(props.children, {
          onError: handleLoadError,
          onLoad: handleOnLoad,
          alt: props.children.props.alt
        })
      )}
    </>
  )
})

ImageWrapper.displayName = "ImageWrapper"

export default ImageWrapper
