import { UserRole, LORole } from "../../types"

export const ROLES_ORDERED: UserRole[] = [
  "administrator",
  "bank admin",
  "loan officer",
  "realtor",
  "authenticated user"
]
export const LO_ROLES_ORDERED: LORole[] = ["bank admin", "loan officer"]

export const CRM_TYPES = [
  "velocify",
  "insellerate",
  "jungo",
  "salesforce",
  "set_shape",
  "total_expert"
]

// Get the highest role the user has
export const getUserRole = (roles: UserRole[]): UserRole | undefined => {
  return ROLES_ORDERED.find(r => roles.includes(r))
}

// Checks if a user has a certain role - e.g. bank admin could have loan officer role
export const hasUserRole = (roles: UserRole[], target: UserRole): boolean =>
  roles.includes(target)

// special function to check if a user is LO and nothing more
export const checkIsLoanOfficer = (roles: UserRole[]): boolean =>
  getUserRole(roles) === "loan officer"

// checks if a user is an admin
export const checkIsAdmin = (roles: UserRole[]): boolean =>
  hasUserRole(roles, "administrator") || hasUserRole(roles, "bank admin")

// checks if a user is an admin
export const checkIsBankAdmin = (roles: UserRole[]): boolean =>
  hasUserRole(roles, "bank admin")

// checks if a user is a super admin
export const checkIsSuperAdmin = (roles: UserRole[]): boolean =>
  hasUserRole(roles, "administrator")
