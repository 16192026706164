import TdInfoCell from "../../../../table/cells/td-info-cell"
import {
  BBColumnDef,
  QuoteReceiptStats,
  SubscriptionEmailStats,
  UnsubscribedStats
} from "../../../../../../types"

export const getSubscriptionEmailStatsColumns =
  (): BBColumnDef<SubscriptionEmailStats>[] => [
    {
      id: "loan-officer-name",
      header: "Loan Officer",
      colSpan: 2,
      accessorFn: row => row.loan_officer_name,
      footer: props => props.column.id,
      cell: props => (
        <TdInfoCell
          colSpan={2}
          info={props.row.original.loan_officer_name}
          customContainerClass="h-16 p-0"
          customTextClass="px-3 text-sm font-medium leading-6 text-ellipsis overflow-hidden"
        />
      )
    },
    {
      id: "email-sent",
      header: "Sent",
      accessorFn: row => row.email_sent,
      footer: props => props.column.id,
      cell: props => (
        <TdInfoCell
          info={props.row.original.email_sent?.toLocaleString() || 0}
          customContainerClass="h-16 p-0"
          customTextClass="px-3 text-sm leading-6"
        />
      )
    },
    {
      id: "email-opened",
      header: "Opened",
      accessorFn: row => row.email_opened,
      footer: props => props.column.id,
      cell: props => (
        <TdInfoCell
          info={props.row.original.email_opened?.toLocaleString() || 0}
          customContainerClass="h-16 p-0"
          customTextClass="px-3 text-sm leading-6"
        />
      )
    },
    {
      id: "open-rate",
      header: "Open Rate",
      accessorFn: row => row.open_rate,
      footer: props => props.column.id,
      cell: props => (
        <TdInfoCell
          info={
            props.row.original.open_rate
              ? `${(props.row.original.open_rate * 100).toFixed(2)}%`
              : "0%"
          }
          customContainerClass="h-16 p-0"
          customTextClass="px-3 text-sm leading-6"
        />
      )
    },
    {
      id: "email-clicked",
      header: "Clicked",
      accessorFn: row => row.email_clicked,
      footer: props => props.column.id,
      cell: props => (
        <TdInfoCell
          info={props.row.original.email_clicked?.toLocaleString() || 0}
          customContainerClass="h-16 p-0"
          customTextClass="px-3 text-sm leading-6"
        />
      )
    }
  ]

export const getUnsubscribedStatsColumns =
  (): BBColumnDef<UnsubscribedStats>[] => [
    {
      id: "loan-officer-name",
      header: "Loan Officer",
      colSpan: 2,
      accessorFn: row => row.loan_officer_name,
      footer: props => props.column.id,
      cell: props => (
        <TdInfoCell
          colSpan={2}
          info={props.row.original.loan_officer_name}
          customContainerClass="h-16 p-0"
          customTextClass="px-3 text-sm font-medium leading-6 text-ellipsis overflow-hidden"
        />
      )
    },
    {
      id: "unsubscribed",
      header: "Unsubscribed",
      accessorFn: row => row.unsubscribed,
      footer: props => props.column.id,
      cell: props => (
        <TdInfoCell
          info={props.row.original.unsubscribed?.toLocaleString() || 0}
          customContainerClass="h-16 p-0"
          customTextClass="px-3 text-sm leading-6"
        />
      )
    },
    {
      id: "stale-unsubscribed",
      header: "Stale Unsubscribed",
      accessorFn: row => row.stale_unsubscribed,
      footer: props => props.column.id,
      cell: props => (
        <TdInfoCell
          info={props.row.original.stale_unsubscribed?.toLocaleString() || 0}
          customContainerClass="h-16 p-0"
          customTextClass="px-3 text-sm leading-6"
        />
      )
    }
  ]

export const getQuoteReceiptStatsColumns =
  (): BBColumnDef<QuoteReceiptStats>[] => [
    {
      id: "client-name",
      header: "Client Name",
      colSpan: 2,
      accessorFn: row => row.Client_Name,
      footer: props => props.column.id,
      cell: props => (
        <TdInfoCell
          colSpan={2}
          info={props.row.original.Client_Name}
          customContainerClass="h-16 p-0"
          customTextClass="px-3 text-sm font-medium leading-6 text-ellipsis overflow-hidden"
        />
      )
    },
    ...(getSubscriptionEmailStatsColumns() as BBColumnDef<QuoteReceiptStats>[])
  ]
