import { FC, useMemo } from "react"
import { ChevronDown, Pencil, Trash2 } from "lucide-react"

import BreadCrumbs from "../../breadcrumbs"
import { Button } from "../../buttons"
import Dropdown from "../../dropdown"
import { Brand, BreadcrumbItem } from "../../../../types"

interface Props {
  brand: Brand
  breadcrumbs?: BreadcrumbItem[]
  onClickEdit: (brandId: string) => void
  onClickDelete: (brandId: string) => void
}

const BrandDetailsHeader: FC<Props> = ({
  breadcrumbs,
  brand,
  onClickEdit,
  onClickDelete
}) => {
  const detailsDropdownItems = useMemo(
    () => [
      { name: "Edit", onClick: () => onClickEdit(brand.id), icon: Pencil },
      { name: "Delete", onClick: () => onClickDelete(brand.id), icon: Trash2 }
    ],
    [brand.id, onClickDelete, onClickEdit]
  )

  return (
    <div className="p-6 bg-white">
      <BreadCrumbs
        pages={
          breadcrumbs ?? [
            {
              name: "Brands",
              href: "/brands",
              current: false
            },
            {
              name: brand.name,
              href: "#",
              current: true
            }
          ]
        }
      />

      <div className="py-4 flex items-center justify-between">
        <div className="flex items-center">
          <div className="h-12 mr-4 p-2 border border-gray-300 rounded">
            <img
              className="h-full"
              src={brand.logo}
              alt={`${brand.name} Thunbnail`}
            />
          </div>
          <div>
            <h3 className="text-lg font-bold">{brand.name}</h3>
            <div className="text-sm">
              <span>ID: {brand.id}</span>
              <span className="text-gray-400">&nbsp;&bull;&nbsp;</span>
              <span>NMLS {brand.nmls || "N/A"}</span>
            </div>
          </div>
        </div>
        <div className="hidden md:block">
          <Button
            className="mr-2 bg-gray-200"
            onClick={() => onClickEdit(brand.id)}
          >
            <Pencil size={16} />
          </Button>
          <Button
            className="bg-gray-200"
            onClick={() => onClickDelete(brand.id)}
            disabled
          >
            <Trash2 size={16} />
          </Button>
        </div>
      </div>
      <div className="block md:hidden">
        <Dropdown
          className="ml-2 lg:ml-6"
          items={detailsDropdownItems}
          menuBtn={
            <div className="inline-flex justify-center items-center py-3 px-4 border text-sm font-medium rounded-md focus:outline-none bg-neutral-100">
              Actions <ChevronDown className="h-5 w-5 ml-1" />
            </div>
          }
        />
      </div>
    </div>
  )
}

export default BrandDetailsHeader
