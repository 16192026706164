import { FC } from "react"
import { DotIcon, Info } from "lucide-react"

import Tooltip from "../../../../tool-tip"
import { RateTableInfoData } from "../../../../../utils"

interface RateTableInfoProps {
  info: RateTableInfoData
}

const RateTableInfo: FC<RateTableInfoProps> = ({
  info: {
    label,
    value,
    containerClassName = "mr-8 pt-1.5",
    iconColor = "#22211C",
    containsTooltip = true,
    tooltipText = ""
  }
}) => (
  <div className={`flex flex-col ${containerClassName}`}>
    <div className="flex items-center min-h-5">
      <div className="pr-1.5">
        <DotIcon size={8} color={iconColor} strokeWidth={16} />
      </div>
      <div className="text-xs">{label}</div>
      {containsTooltip && (
        <div className="pl-2">
          <Tooltip
            toolTipNode={<Info size={20} className="cursor-pointer" />}
            text={tooltipText}
            displayTextClass="w-5"
            placement="top"
          />
        </div>
      )}
    </div>
    <div className="text-lg leading-6 font-inter-extrabold">{value}</div>
  </div>
)

export default RateTableInfo
