import { FC } from "react"
import { Integration } from "../../../../../types"
import { HELP_TEXT_URL } from "../../../../constants"
import { useIntegrationSettingsForm } from "../../../../hooks"
import Input from "../../../form/input"

interface Props {
  integration?: Integration
}

const URLField: FC<Props> = ({ integration }) => {
  const { values } = useIntegrationSettingsForm(integration)

  return (
    <div className="pt-6">
      <Input
        label="URL"
        name="url"
        beforeLabel="https://"
        placeholder="Ex. google.com/*"
        helpText={values.domains?.length === 0 ? HELP_TEXT_URL : ""}
      />
    </div>
  )
}

export default URLField
