import { FC } from "react"
import OriginalDatePicker, { ReactDatePickerProps } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import "./styles.scss"

interface CustomDatePickerProps extends ReactDatePickerProps<any, any> {
  selectsRange?: boolean
}

const DatePicker: FC<ReactDatePickerProps | CustomDatePickerProps> = props => (
  <div className="bb-date-picker">
    <OriginalDatePicker {...props} />
  </div>
)

export default DatePicker
