import { FC } from "react"
import { Integration } from "../../../../../types"
import { useIntegrationSettingsForm } from "../../../../hooks"
import If from "../../../if"
import LeadWorkflowSelector from "../lead-workflow-selector"

interface Props {
  integration?: Integration
}

const LeadWorkflowOption: FC<Props> = ({ integration }) => {
  const { app_settings, saveAppSetting } =
    useIntegrationSettingsForm(integration)

  const onChangeLeadWorkflow = () => {
    saveAppSetting({
      lead_workflow:
        !!app_settings?.lead_workflow && app_settings?.lead_workflow >= 1
          ? 0
          : 1
    })
  }

  return (
    <div className="py-2">
      <input
        onChange={onChangeLeadWorkflow}
        checked={
          !!app_settings?.lead_workflow && app_settings?.lead_workflow >= 1
        }
        id="lead_workflow"
        name="lead_workflow"
        type="checkbox"
        className="h-6 w-6 rounded border-neural-400 text-primary-500 focus:ring-primary-300 cursor-pointer"
      />
      <label
        htmlFor="lead_workflow"
        className="select-none ml-3 cursor-pointer"
      >
        Lead Workflow
      </label>
      <If
        condition={
          !!app_settings?.lead_workflow && app_settings?.lead_workflow >= 1
        }
        then={<LeadWorkflowSelector />}
      />
    </div>
  )
}

export default LeadWorkflowOption
