import { ReactNode } from "react"
import { classNames } from "../../utils"

const commonTabClassName =
  "whitespace-nowrap py-4 px-4 border-b-[3px] font-medium text-sm cursor-pointer"
const defaultTabClassName =
  "border-transparent text-neutral-400 hover:text-neutral-500 hover:border-neutral-400"
const activeTabClassName = "border-teal-500 text-gray-700"

export interface TabProps {
  id: string
  label: string
}

interface Props {
  tabs: TabProps[]
  currentTab: string
  onChange: (tabId: string) => void
  className?: string
  extraElement?: ReactNode
}

const Tabs = ({
  tabs,
  currentTab,
  onChange,
  extraElement,
  className = ""
}: Props) => (
  <div
    className={`flex justify-between items-center border-b border-gray-200 overflow-x-auto overflow-y-hidden ${className}`}
  >
    <nav className="-mb-px flex space-x-2" aria-label="Tabs">
      {tabs.map(tab => (
        <div
          key={tab.id}
          className={classNames(
            tab.id === currentTab ? activeTabClassName : defaultTabClassName,
            commonTabClassName
          )}
          onClick={() => onChange(tab.id)}
        >
          {tab.label}
        </div>
      ))}
    </nav>
    {!!extraElement && <div>{extraElement}</div>}
  </div>
)

export default Tabs
