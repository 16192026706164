import { SubscriptionConfig } from "../../../../../../types"

interface Props {
  info: SubscriptionConfig
}

const EnabledCell = ({ info }: Props) => {
  const { enabled } = info

  return (
    <td className="whitespace-nowrap py-4 pl-2 pr-3 cursor-pointer">
      <div className="italic">{enabled ? "Enabled" : "Disabled"}</div>
    </td>
  )
}

export default EnabledCell
