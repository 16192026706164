import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { baseQuery } from "../api"
import {
  FindPricingSetsResponse,
  FindDefaultPricingSetsResponse
} from "../../types"
import {
  DEFAULT_PRICING_SET_ENDPOINT,
  PRICING_SET_ENDPOINT
} from "../../common/constants"
import { getQueryString } from "../../common/utils"

const reducerPath = "pricing-sets"

export const pricingSetsApi = createApi({
  reducerPath,
  baseQuery,
  tagTypes: [reducerPath],
  endpoints: builder => ({
    findPricingSets: builder.query({
      query: (params: { [key: string]: number | string }) =>
        `${PRICING_SET_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: FindPricingSetsResponse) =>
        responseData.sets ?? []
    }),
    findDefaultPricingSets: builder.query({
      query: (params: { [key: string]: number | string }) =>
        `${DEFAULT_PRICING_SET_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: FindDefaultPricingSetsResponse) =>
        responseData.set_data
    })
  })
})

export const {
  useFindPricingSetsQuery,
  useFindDefaultPricingSetsQuery,
  endpoints: pricingSetsEndpoints
} = pricingSetsApi
