import { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"
import Backdrop from "../backdrop"
import Content from "./content"

interface Props {
  show: boolean
  title?: string
  children: React.ReactNode
  onClose: () => void
  zIndex?: number
  noPadding?: boolean
  size?: "sm" | "md" | "lg" | "xl" | "2xl"
  initialFocus?: React.RefObject<HTMLElement>
  forceShowCloseButton?: boolean
}

const BBModal = ({
  show,
  title,
  children,
  onClose,
  zIndex = 100,
  noPadding,
  size = "md",
  initialFocus,
  forceShowCloseButton = false
}: Props) => {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className={`relative z-[${zIndex}]`}
        onClose={onClose}
        initialFocus={initialFocus}
      >
        <Backdrop />
        <Content
          size={size}
          title={title}
          noPadding={noPadding}
          onClose={onClose}
          forceShowCloseButton={forceShowCloseButton}
        >
          {children}
        </Content>
      </Dialog>
    </Transition.Root>
  )
}

export default BBModal
