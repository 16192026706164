import { FC } from "react"
import { useAppSelector } from "../../common/hooks"
import { selectUserDetails } from "../../redux/slices/auth"
import { getUserRole } from "../../common/utils"
import OrganizationsPage from "../organizations"
import DashboardPage from "../dashboard"
import { LoanOfficerDashboardPage, NotFound } from ".."

const HomePage: FC<{}> = () => {
  const userDetails = useAppSelector(selectUserDetails)

  const userRole = getUserRole(userDetails?.roles ?? [])

  if (userRole === "administrator") {
    return <OrganizationsPage />
  } else if (userRole === "bank admin") {
    return <DashboardPage />
  } else if (userRole === "loan officer") {
    return <LoanOfficerDashboardPage />
  }

  return <NotFound />
}

export default HomePage
