import { Field } from "formik"
import { FC, useMemo, useState } from "react"
import { CreateSubscriptionPayload } from "../../../../../../types"
import { findSelectedOption } from "../../../../../utils"
import Input from "../../../../form/input"
import Select from "../../../../form/select"
import { useFetchConformingLimitQuery } from "../../../../../../redux/queries"
import CurrencyInput from "../../../../form/currency-input"
import {
  MILITARY_STATUS_OPTIONS,
  LOAN_TYPE_OPTIONS,
  ARM_TERM_OPTIONS,
  LOAN_TERM_OPTIONS,
  PROPERTY_TYPE_OPTIONS,
  RESIDENCY_TYPE_OPTIONS
} from "../../../../../constants"

interface Props {
  values: CreateSubscriptionPayload
  setFormField: (name: string, value: string) => void
}

const ManageEngagementSelectors: FC<Props> = ({ values, setFormField }) => {
  const { data: conformingLimitData } = useFetchConformingLimitQuery(
    {
      zipcode: values.zipcode
    },
    { skip: !values.zipcode }
  )

  const [militaryStatus, setMilitaryStatus] = useState<"yes" | "no">("no")
  const selectedMilitaryStatusOption = MILITARY_STATUS_OPTIONS.find(
    mso => mso.id === militaryStatus
  )

  const ltv = useMemo(() => {
    const numberLoanAmount = parseFloat(values.loan_amount)
    const numberListPrice = parseFloat(values.list_price)

    if (!numberLoanAmount || !numberListPrice) return ""

    const total =
      parseFloat(values.cash_out || "0") + parseFloat(values.loan_amount)
    const percent = Math.trunc((total / parseFloat(values.list_price)) * 100)

    return `LTV: ${percent}%`
  }, [values.cash_out, values.list_price, values.loan_amount])

  return (
    <div className="pt-12">
      <div className="text-xl font-bold">Loan scenario</div>
      <div className="pt-6 flex" role="group">
        <label key="purchase" className="cursor-pointer flex items-center">
          <Field
            type="radio"
            name="loan_purpose"
            value="purchase"
            className="form-radio h-4 w-4 text-primary-400 transition duration-150 ease-in-out"
          />
          <span className="ml-4">Purchase</span>
        </label>
        <label
          key="refinance"
          className="cursor-pointer flex items-center ml-8"
        >
          <Field
            type="radio"
            name="loan_purpose"
            value="refinance"
            className="form-radio h-4 w-4 text-primary-400 transition duration-150 ease-in-out"
          />
          <span className="ml-4">Refinance</span>
        </label>
      </div>

      <div className="grid grid-cols-2 gap-8 pt-8">
        <div>
          <Select
            label="Loan type"
            name="loan_type"
            selected={findSelectedOption(LOAN_TYPE_OPTIONS, values.loan_type)}
            data={LOAN_TYPE_OPTIONS}
            setSelected={val => setFormField("loan_type", val)}
          />
        </div>

        <div>
          {values.loan_type === "ARM" ? (
            <Select
              label="Arm term"
              name="arm_term"
              selected={findSelectedOption(ARM_TERM_OPTIONS, values.arm_term)}
              data={ARM_TERM_OPTIONS}
              setSelected={val => setFormField("arm_term", val)}
            />
          ) : (
            <Select
              label="Loan term"
              name="loan_term"
              selected={findSelectedOption(LOAN_TERM_OPTIONS, values.loan_term)}
              data={LOAN_TERM_OPTIONS}
              setSelected={val => setFormField("loan_term", val)}
            />
          )}
        </div>
        <div>
          <Input name="loan_amount" label="Loan amount" placeholder="Ex. 700">
            <CurrencyInput setFormField={setFormField} />
          </Input>
          {conformingLimitData?.conforming_limit && (
            <div className="text-neutral-400 text-sm pt-2">
              Conforming Limit:&nbsp; $
              {conformingLimitData?.conforming_limit.toLocaleString("en")}
            </div>
          )}
        </div>
        <div>
          <Input
            name="credit_score"
            label="Credit score"
            placeholder="Ex. 700"
            type="number"
          />
        </div>
        <div>
          <Input
            name="list_price"
            label="Property value"
            placeholder="Ex. 700"
            helpText={ltv}
          >
            <CurrencyInput setFormField={setFormField} />
          </Input>
        </div>
        <div>
          <Select
            label="Property type"
            name="property_type"
            selected={findSelectedOption(
              PROPERTY_TYPE_OPTIONS,
              values.property_type
            )}
            data={PROPERTY_TYPE_OPTIONS}
            setSelected={val => setFormField("property_type", val)}
          />
        </div>
        <div>
          <Select
            label="Property usage"
            name="residency_type"
            selected={findSelectedOption(
              RESIDENCY_TYPE_OPTIONS,
              values.residency_type
            )}
            data={RESIDENCY_TYPE_OPTIONS}
            setSelected={val => setFormField("residency_type", val)}
          />
        </div>
        <div>
          <Select
            label="Military status"
            name="military_status"
            selected={selectedMilitaryStatusOption}
            data={MILITARY_STATUS_OPTIONS}
            setSelected={val => {
              setMilitaryStatus(val)
              setFormField(
                "loan_type",
                val === "yes" ? "VA" : LOAN_TYPE_OPTIONS[0].id
              )
            }}
          />
        </div>
        {values.loan_purpose === "refinance" && (
          <div>
            <Input name="cash_out" label="Cash out" placeholder="Ex. 700">
              <CurrencyInput setFormField={setFormField} />
            </Input>
          </div>
        )}
      </div>
    </div>
  )
}

export default ManageEngagementSelectors
