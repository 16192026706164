import { LoanOfficer } from "../../../types"
import RoundedImg from "../rounded-img"

interface Props {
  data: LoanOfficer & { daysDiff: number }
}

const DashboardLOCard: React.FC<Props> = ({ data }) => {
  return (
    <div className="p-2 flex items-center justify-between">
      <RoundedImg src={data.picture} alt={data.name} size={10} />

      <div className="flex-1 pl-4">
        <div className="text-lg">{data.name}</div>
        <div className="text-sm text-gray-400">{data.email}</div>
      </div>
      <div className="text-gray-400">{data.daysDiff}d</div>
    </div>
  )
}

export default DashboardLOCard
