import { FC, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Plus } from "lucide-react"

import {
  EngagementsActivity,
  EngagementsTable,
  If,
  LeadsTable,
  PageHeader,
  PrimaryBtn,
  Tabs
} from "../../common/ui"
import { ALLOWED_ROLES_BY_ROUTE } from "../../common/constants"
import { useAppDispatch, useQuery, useUserRoles } from "../../common/hooks"
import { RequireRole } from "../../common/validation"
import { setVisiblePanel } from "../../redux/slices/app"
import { getUserBrandId } from "../../common/utils"
import { EngagementsTab } from "../../types"

const EngagementsPage: FC<{}> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const paramTab = useQuery().get("tab")
  const { user, isBankAdmin, isLoanOfficer, isSuperAdmin } = useUserRoles()

  const orgId = useMemo(() => {
    return isSuperAdmin ? user?.org?.id : undefined
  }, [isSuperAdmin, user?.org?.id])

  const bankAdminOrgId = useMemo(() => {
    return !isSuperAdmin && isBankAdmin ? user?.org?.id : undefined
  }, [isBankAdmin, isSuperAdmin, user?.org?.id])

  const brandId = useMemo(() => {
    if (isSuperAdmin || !isBankAdmin || !user?.id || !user?.org)
      return undefined

    const firstIndex = 0
    return (
      getUserBrandId(user.org, user.id) ?? user.org?.brands?.[firstIndex].id
    )
  }, [isBankAdmin, isSuperAdmin, user?.id, user?.org])

  const loid = useMemo(() => {
    return !isSuperAdmin && !isBankAdmin && isLoanOfficer ? user?.id : undefined
  }, [isBankAdmin, isLoanOfficer, isSuperAdmin, user?.id])

  const [tab, setTab] = useState<string>(paramTab ?? EngagementsTab.engagements) // "engagements" or "leads" or "activity"

  const handleNewEngagement = () => {
    dispatch(setVisiblePanel("engagement/new"))
  }

  useEffect(() => {
    if (paramTab) {
      setTab(paramTab)
    }
  }, [paramTab])

  useEffect(() => {
    if (tab) {
      navigate(`/engagements?tab=${tab}`)
    }
  }, [navigate, tab])

  if (!user) return null

  return (
    <RequireRole allowedRoles={ALLOWED_ROLES_BY_ROUTE["engagements"]}>
      <div className="h-full flex flex-col">
        <div className="pt-6 bg-white">
          <div className="px-6">
            <PageHeader title="Engagements">
              <If
                condition={tab === EngagementsTab.engagements}
                then={
                  <PrimaryBtn onClick={handleNewEngagement}>
                    <Plus size={16} />
                    &nbsp;New Subscription
                  </PrimaryBtn>
                }
                else={<div></div>}
              />
            </PageHeader>
          </div>
          <Tabs
            className="px-6"
            currentTab={tab}
            tabs={[
              { id: EngagementsTab.engagements, label: "Subscriptions" },
              { id: EngagementsTab.leads, label: "Website Leads" },
              { id: EngagementsTab.activity, label: "Activity" }
            ]}
            onChange={setTab}
          />
        </div>
        <div className="p-6 flex-1 bg-white">
          <div className={tab === EngagementsTab.engagements ? "" : "hidden"}>
            <EngagementsTable />
          </div>
          <div className={tab === EngagementsTab.leads ? "" : "hidden"}>
            <LeadsTable
              loid={loid}
              orgId={bankAdminOrgId}
              isSuperAdmin={isSuperAdmin}
            />
          </div>
          <div className={tab === EngagementsTab.activity ? "" : "hidden"}>
            <EngagementsActivity orgId={orgId} brandId={brandId} loid={loid} />
          </div>
        </div>
      </div>
    </RequireRole>
  )
}

export default EngagementsPage
