import { FC } from "react"
import { Link } from "react-router-dom"
import { ChevronRight } from "lucide-react"
import { BreadcrumbItem } from "../../../types"
import If from "../if"

interface Props {
  pages: BreadcrumbItem[]
}

const BreadCrumbs: FC<Props> = ({ pages }) => {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        {pages.map((page, idx) => (
          <li key={page.name}>
            <div className="flex items-center">
              <If
                condition={!!idx}
                then={
                  <ChevronRight
                    size={18}
                    className="flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                }
              />
              <Link
                className={`ml-${
                  idx > 0 ? "4" : "0"
                } text-sm font-medium text-gray-500 hover:text-gray-700`}
                to={page.href}
              >
                {page.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default BreadCrumbs
