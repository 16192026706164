import { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { ArrowLeftCircle, ArrowRightCircle, X } from "lucide-react"
import { Logo, NavbarLinks } from "../../ui"
import NavbarItem from "../../ui/navbar-item"
import Support from "../../ui/support"
import { classNames } from "../../utils"
import { NavItemType } from "../../../types"

type Props = {
  items: NavItemType[]
  sidebarOpen: boolean
  setSidebarOpen: (value: boolean) => void
  sidebarCollapsed: boolean
  toggleSidebarCollapsed: () => void
}

const Navbar = ({
  items,
  sidebarOpen,
  setSidebarOpen,
  sidebarCollapsed,
  toggleSidebarCollapsed
}: Props) => {
  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 flex z-40">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-primary-alt-400">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <X size={24} className="text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 flex items-center px-4">
                  <Logo className="h-8 w-auto" />
                </div>
                <div className="mt-5 flex-1 h-0 overflow-y-auto flex flex-col">
                  <nav className="px-2 space-y-1">
                    <NavbarLinks isMobile items={items} />
                  </nav>
                  <Support isFull className="mt-auto" />
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div
        className={classNames(
          sidebarCollapsed ? "lg:w-24" : "lg:w-64",
          "hidden lg:flex lg:flex-col lg:fixed lg:inset-y-0 transition-all z-50"
        )}
      >
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex-1 flex flex-col min-h-0 bg-primary-alt-400 p-4">
          <div className="flex-1 flex flex-col pt-2 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <Logo className="h-8 w-auto" isFull={!sidebarCollapsed} />
            </div>
            <nav className="mt-5 flex-1 px-2 space-y-1">
              <NavbarLinks isFull={!sidebarCollapsed} items={items} />
            </nav>
          </div>
          <div className="flex-shrink-0">
            <Support isFull={!sidebarCollapsed} isDesktop />

            <NavbarItem
              isFull={!sidebarCollapsed}
              item={{
                name: "Collapse view",
                current: false,
                icon: sidebarCollapsed ? ArrowRightCircle : ArrowLeftCircle,
                onClick: toggleSidebarCollapsed
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Navbar
