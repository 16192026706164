import { useState, useEffect } from "react"

import { LeadCountProperties } from "../../types"

type RGBColor = {
  r: number
  g: number
  b: number
}

export const useLeadCountPercentagesWithBg = <
  T extends Omit<LeadCountProperties, "timestamp_tz_date">
>(
  initialData: T[] | undefined,
  highValueColor: RGBColor = { r: 120, g: 111, b: 246 },
  lowValueColor: string = "#F4F4F4"
): T[] => {
  const [data, setData] = useState<T[]>([])

  useEffect(() => {
    let newData: T[] = []

    if (initialData?.length && initialData.length > 0) {
      const totalCount = initialData.reduce(
        (sum, item) => sum + item.lead_count,
        0
      )
      const maxCount = Math.max(...initialData.map(item => item.lead_count))
      const minCount = Math.min(...initialData.map(item => item.lead_count))

      newData = initialData.map(item => {
        const count = item.lead_count
        const percentage = ((count / totalCount) * 100).toFixed(2)
        let color = lowValueColor

        if (count === maxCount) {
          color = `rgb(${highValueColor.r}, ${highValueColor.g}, ${highValueColor.b})`
        } else if (count !== minCount) {
          const opacity = count / totalCount
          color = `rgba(${highValueColor.r}, ${highValueColor.g}, ${highValueColor.b}, ${opacity})`
        }

        return {
          ...item,
          percentage,
          color
        }
      })
    }
    setData(newData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData])
  return data
}
