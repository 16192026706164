import { FC, useState } from "react"

import Table from "../../table"
import ButtonGroup, { ButtonProps } from "../../button-group"
import ListSkeleton from "../../skeletons/normal-list"
import { useFetchSubscriptionActivityStatsQuery } from "../../../../redux/queries"
import { useAppDispatch, useAppSelector } from "../../../hooks"
import {
  selectTabRange,
  updateRange
} from "../../../../redux/slices/engagements"
import {
  BBColumnDef,
  DateRange,
  EngagementsTab,
  SubscriptionActivityStat
} from "../../../../types"

type EngagementsActivityTableProps = {
  orgId: string | undefined
  brandId: string | undefined
  loid: string | undefined
  columns: BBColumnDef<SubscriptionActivityStat>[]
}

const EngagementsActivityTable: FC<EngagementsActivityTableProps> = ({
  orgId,
  brandId,
  loid,
  columns
}) => {
  const dispatch = useAppDispatch()
  const tabKey = EngagementsTab.activity
  const [range, setRange] = useState<DateRange>(
    useAppSelector(state => selectTabRange(state, tabKey))
  )
  const handleUpdateRange = (newValue: DateRange) => {
    dispatch(updateRange({ tabKey, newValue }))
    setRange(newValue)
  }
  const FILTER_BUTTONS: ButtonProps[] = [
    {
      key: 1000,
      label: "All time",
      disabled: false,
      className: "flex-1 md:flex-none",
      onClick: () => handleUpdateRange(1000)
    },
    {
      key: 30,
      label: "30 Days",
      disabled: false,
      className: "flex-1 md:flex-none",
      onClick: () => handleUpdateRange(30)
    },
    {
      key: 7,
      label: "7 Days",
      disabled: false,
      className: "flex-1 md:flex-none",
      onClick: () => handleUpdateRange(7)
    },
    {
      key: 1,
      label: "24 hours",
      disabled: false,
      className: "flex-1 md:flex-none",
      onClick: () => handleUpdateRange(1)
    }
  ]

  // Fetch subscription activity stats
  const { data: subscriptionActivityStats, isFetching } =
    useFetchSubscriptionActivityStatsQuery(
      {
        event_range: range,
        sort_by: "num_total_dashboard_views",
        org_id: orgId,
        brand_id: brandId,
        loid
      },
      { skip: !orgId && !brandId && !loid }
    )

  return (
    <Table
      isLoading={isFetching}
      data={subscriptionActivityStats ?? []}
      columns={columns}
      viewType="table"
      searchPlaceholder="Search in activity..."
      extraHeaderElement={
        <ButtonGroup
          buttons={FILTER_BUTTONS}
          active={range}
          className="w-full md:w-auto flex md:flex-none"
        />
      }
      extraHeaderElementClassName="w-full md:w-auto md:ml-auto"
      noSorter
      defaultSortBy="total-dashboard-views"
      defaultSortDir="desc"
      tableClassName="md:table-fixed"
      loaderSkeleton={<ListSkeleton noOfRows={10} noOfColumns={4} />}
      containColumnsSorter
      removeMobilePagination
    />
  )
}

export default EngagementsActivityTable
