import { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { Loader2 } from "lucide-react"
import { ALLOWED_ROLES_BY_ROUTE } from "../../common/constants"
import Tabs from "../../common/ui/tabs"
import { RequireRole } from "../../common/validation"
import LoanOfficerDetails from "../../common/ui/loan-officer-details/details"
import LoanOfficerDetailsHeader from "../../common/ui/loan-officer-details/header"
import { Integration, ZillowReview } from "../../types"
import If from "../../common/ui/if"
import EditLoProfile from "../../common/ui/account/edit-lo-profile"
import IntegrationsTable from "../../common/ui/integrations/integrationTable"
import PricingLogs from "../../common/ui/pricing-logs"
import LoanOfficerRates from "../../common/ui/loan-officer-details/loan-officer-rates"
import {
  useFetchIntegrationsByLoIdQuery,
  useDeleteLoanOfficerMutation,
  useFetchLoanOfficerQuery,
  useFetchLoanOfficerReviewsQuery
} from "../../redux/queries"
import BBDialog from "../../common/ui/dialog"
import { pushToast } from "../../redux/slices/notifications"
import { useAppDispatch } from "../../common/hooks"
import { IDeleteLO } from "../../common/ui/loan-officers/table"
import LOActivities from "../../common/ui/loan-officer-details/activities"

const LoanOfficerDetailsPage = () => {
  const { id } = useParams()
  const [deleteLoanOfficer, { isSuccess, isError }] =
    useDeleteLoanOfficerMutation()

  const { data: loDetails, isFetching } = useFetchLoanOfficerQuery(id ?? "", {
    skip: !id
  })
  const { data: reviews } = useFetchLoanOfficerReviewsQuery(id ?? "", {
    skip: !id
  })
  const dispatch = useAppDispatch()
  let navigate = useNavigate()
  let LOAN_OFFICER_LISTING_URL = "/loan-officers"

  const { data: integrations, isFetching: isFetchingIntegrationsAll } =
    useFetchIntegrationsByLoIdQuery(id as string)

  const [tab, setTab] = useState<string>("loan-officer-details")
  const [editLoanOfficerVisible, setEditLoanOfficerVisible] =
    useState<boolean>(false)

  const closeEditLoanOfficerSidebar = () => setEditLoanOfficerVisible(false)

  const onChangeTab = (id: string) => setTab(id)

  const handleEdit = () => {
    setEditLoanOfficerVisible(true)
  }

  //Delete LO
  const [showDeletePopover, setShowDeletePopover] = useState(false)
  const [deleteLO, setDeleteLO] = useState<IDeleteLO>({
    state: false,
    loid: ""
  })

  const handleConfirmDialog = () => {
    setShowDeletePopover(!showDeletePopover)
    setDeleteLO({ ...deleteLO, state: true })
  }
  const handleCancelDialog = () => {
    setDeleteLO(prev => ({ state: false, loid: "" }))
    setShowDeletePopover(!showDeletePopover)
  }
  const handleCloseDialog = () => {
    setShowDeletePopover(!showDeletePopover)
  }
  // delete LO
  const onDelete = (loid: string) => {
    setDeleteLO({ loid: loid, state: false })
    setShowDeletePopover(!showDeletePopover)
  }

  const deleteLOHandler = async () => {
    deleteLoanOfficer(deleteLO.loid)
  }

  useEffect(() => {
    if (deleteLO.state) {
      deleteLOHandler()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteLO])

  useEffect(() => {
    if (isSuccess) {
      setDeleteLO(prev => ({ state: false, loid: "" }))
      dispatch(
        pushToast({
          theme: "success",
          msg: "Loan Officer deleted successfully",
          timeout: 3000
        })
      )
      navigate(LOAN_OFFICER_LISTING_URL, { replace: true })
    }
  }, [LOAN_OFFICER_LISTING_URL, dispatch, isSuccess, navigate])

  useEffect(() => {
    if (isError) {
      dispatch(
        pushToast({
          theme: "danger",
          msg: "something went wrong",
          timeout: 3000
        })
      )
    }
  }, [dispatch, isError])

  if (!loDetails || !id)
    return (
      <If
        condition={!isFetching}
        then={<div className="p-6">Loan Officer not found</div>}
        else={
          <div className="h-full bg-white flex items-center justify-center">
            <Loader2
              className={`animate-spin h-16 w-16 text-primary-alt-400`}
              aria-hidden="true"
            />
          </div>
        }
      />
    )

  return (
    <RequireRole allowedRoles={ALLOWED_ROLES_BY_ROUTE.brands}>
      <>
        <div className="h-full bg-white">
          <LoanOfficerDetailsHeader
            loanOfficer={loDetails}
            onClickDelete={onDelete}
            onClickEdit={handleEdit}
          />
          <Tabs
            className="px-6"
            currentTab={tab}
            tabs={[
              { id: "loan-officer-details", label: "Details" },
              { id: "integrations", label: "Integrations" },
              { id: "pricing-log", label: "Pricing Log" },
              { id: "rates", label: "Rates" },
              { id: "activity", label: "Activity" }
            ]}
            onChange={onChangeTab}
          />

          <div>
            <If
              condition={tab === "loan-officer-details"}
              then={
                <LoanOfficerDetails
                  loanOfficer={loDetails}
                  review={(reviews?.zillow?.reviews ?? []) as ZillowReview[]}
                />
              }
            />
            <If
              condition={tab === "integrations" && integrations !== undefined}
              isLoading={isFetchingIntegrationsAll}
              then={
                <IntegrationsTable
                  data={integrations as Integration[]}
                  isLoading={isFetchingIntegrationsAll}
                />
              }
            />
            <If
              condition={tab === "pricing-log"}
              then={<PricingLogs id={{ loid: id }} />}
            />
            <If
              condition={tab === "rates"}
              then={<LoanOfficerRates id={(id as string) ?? ""} />}
            />
            <If
              condition={tab === "activity"}
              then={<LOActivities loanOfficer={loDetails} />}
            />
          </div>
          <EditLoProfile
            data={loDetails}
            open={editLoanOfficerVisible}
            isInSidePanel
            onClose={closeEditLoanOfficerSidebar}
          />
        </div>
        <BBDialog
          open={showDeletePopover}
          title="Delete Loan Officer Profile"
          body="By doing this all the informaiton stored in the system about this loan officer will be deleted. Are you sure ?"
          okLabel="Yes, Delete"
          onOk={handleConfirmDialog}
          onCancel={handleCancelDialog}
          onClose={handleCloseDialog}
          cancelLabel="Cancel"
        />
      </>
    </RequireRole>
  )
}

export default LoanOfficerDetailsPage
