import { FC, useState } from "react"

import PillTabs from "../../../pill-tabs"
import RateAlertsTable from "./table/rate-alerts"
import IntroTable from "./table/intro"
import QuoteReceiptTable from "./table/quote-receipt"
import UnsubscribedTable from "./table/unsubscribed"

export enum EmailStatsTabs {
  RATE_ALERTS = "by-rate-alerts",
  INTRO_EMAILS = "by-intro-emails",
  UNSUBSCRIBED = "by-unsubscribed",
  QUOTE_RECEIPT = "by-quote-receipt"
}

enum EmailStatsLabelTabs {
  RATE_ALERTS = "Rate alerts",
  INTRO_EMAILS = "Intro emails",
  UNSUBSCRIBED = "Unsubscribed",
  QUOTE_RECEIPT = "Quote receipt"
}

interface EmailStatsProps {
  title: string
  range: number
  orgId: string | undefined
  loid: string | undefined
}

const EmailStats: FC<EmailStatsProps> = ({ title, range, orgId, loid }) => {
  const [tab, setTab] = useState<string>(EmailStatsTabs.RATE_ALERTS)

  return (
    <div className="bg-white flex flex-col flex-1 px-6 py-4 rounded-md overflow-hidden">
      <div className="flex flex-col md:flex-row items-center justify-between mb-6">
        <div className="w-full md:w-auto text-base font-medium mb-4 md:mb-0">
          {title}
        </div>
        <div className="flex">
          <PillTabs
            currentTab={tab}
            className=""
            navigationClassName="justify-center md:justify-end flex-wrap md:flex-nowrap"
            tabs={[
              {
                id: EmailStatsTabs.RATE_ALERTS,
                label: EmailStatsLabelTabs.RATE_ALERTS
              },
              {
                id: EmailStatsTabs.INTRO_EMAILS,
                label: EmailStatsLabelTabs.INTRO_EMAILS
              },
              {
                id: EmailStatsTabs.QUOTE_RECEIPT,
                label: EmailStatsLabelTabs.QUOTE_RECEIPT
              },
              {
                id: EmailStatsTabs.UNSUBSCRIBED,
                label: EmailStatsLabelTabs.UNSUBSCRIBED
              }
            ]}
            onChange={setTab}
          />
        </div>
      </div>
      {tab === EmailStatsTabs.RATE_ALERTS && (
        <RateAlertsTable range={range} orgId={orgId} loid={loid} />
      )}
      {tab === EmailStatsTabs.INTRO_EMAILS && (
        <IntroTable range={range} orgId={orgId} loid={loid} />
      )}
      {tab === EmailStatsTabs.QUOTE_RECEIPT && (
        <QuoteReceiptTable range={range} orgId={orgId} loid={loid} />
      )}
      {tab === EmailStatsTabs.UNSUBSCRIBED && (
        <UnsubscribedTable range={range} orgId={orgId} loid={loid} />
      )}
    </div>
  )
}

export default EmailStats
