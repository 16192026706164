/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react"
import { useFindContactFormsQuery } from "../../../../../redux/queries"
import { ContactForm, Integration } from "../../../../../types"
import { useIntegrationSettingsForm } from "../../../../hooks"
import Form from "../../../form"

interface Props {
  integration?: Integration
}

const ContactFormSelector: FC<Props> = ({ integration }) => {
  const { app_settings, saveSetting } = useIntegrationSettingsForm(integration)
  const { settings } = app_settings ?? {}

  const [selected, setSelectedForm] = useState<ContactForm>()
  const [selectedID, setSelectedFormID] = useState<number | string>(
    settings?.contactForm ?? 0
  )

  // Get all the workflows from a brand
  const { data: forms = [], isLoading } = useFindContactFormsQuery(
    integration?.brand_id as string
  )

  const setSelected = (contactFormID: number) => {
    if (contactFormID !== selectedID) {
      setSelectedFormID(contactFormID) // set the state
      saveSetting({ contactForm: contactFormID }) // save the setting to the server
    }
  }

  useEffect(() => {
    // get selected workflow from workflows by id
    const selectedWorkflow = forms?.find(
      forms => forms.id === selectedID + "" // TODO: this should be always number
    )
    setSelectedForm(selectedWorkflow)
  }, [selectedID, forms])

  return (
    <div className="flex items-center pl-9">
      <Form.InlineSelect
        isLoading={isLoading}
        data={forms}
        setSelected={setSelected}
        selected={selected}
        label="Choose a form"
      />
      <a href="#" className="text-primary-alt-400 inline-block ml-3">
        Edit
      </a>
    </div>
  )
}

export default ContactFormSelector
