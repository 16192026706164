import { FC, Fragment, useCallback, useEffect, useState } from "react"
import { Popover } from "@headlessui/react"
import { Column, HeaderGroup } from "@tanstack/react-table"
import { ChevronDown, ChevronUp, ArrowDownAZ, ArrowDownZA } from "lucide-react"
import { SortDirection } from "../../../../../types"
import { capitalize } from "../../../../utils"

interface Props {
  className?: string
  headerGroups: HeaderGroup<any>[]
  shrinkSort?: boolean
  defaultSortBy?: string
  defaultSortDir?: SortDirection
  popoverPlace?: "left" | "right"
  popoverPlaceInMobile?: "left" | "right"
}

const Sorter: FC<Props> = ({
  headerGroups,
  className = "",
  shrinkSort = false,
  defaultSortBy,
  defaultSortDir = "asc",
  popoverPlace = "left",
  popoverPlaceInMobile = "left"
}) => {
  const [sortBy, setSortBy] = useState<string>(
    defaultSortBy ?? headerGroups[0].headers[0].id
  )
  const [sortDir, setSortDir] = useState<SortDirection>(defaultSortDir)

  const toggleSorting = useCallback(
    (desc?: boolean, isMulti?: boolean) => {
      return (
        headerGroups[0].headers.find(header => header.id === defaultSortBy) ||
        headerGroups[0].headers[0]
      ).column.toggleSorting(desc, isMulti)
    },
    [defaultSortBy, headerGroups]
  )

  useEffect(() => {
    // sorting default to first column ascending
    toggleSorting(defaultSortDir === "desc")
  }, [toggleSorting, defaultSortDir])

  const sort = (desc: boolean, column: Column<any>, label: string) => {
    setSortDir(desc ? "desc" : "asc")
    setSortBy(label)
    column.toggleSorting(desc)
  }

  return (
    <div className={className}>
      <Popover as="div" className="relative z-10 inline-block text-left">
        <Popover.Button className="group inline-flex items-center justify-center appearance-none px-3 py-2 border border-neutral-200 rounded-md placeholder-gray-400 focus:outline-none hover:bg-primary-50 hover:border-primary-500 hover:text-primary-600 sm:text-sm">
          <span className={shrinkSort ? "hidden sm:flex" : "flex align-center"}>
            <span className="text-gray-400">Sort by:</span>
            <span className="inline-block capitalize mx-1">{sortBy}</span>
            {sortDir === "asc" ? (
              <>
                A to Z <ChevronDown size={16} aria-hidden="true" />
              </>
            ) : (
              <>
                Z to A <ChevronUp size={16} aria-hidden="true" />
              </>
            )}
          </span>
          <span className={shrinkSort ? "block sm:hidden" : ""}>
            {sortDir === "asc" ? (
              <ArrowDownAZ
                size={16}
                className="flex-shrink-0 mr-1 text-base-dark group-hover:text-primary-600 my-1 block sm:hidden"
                aria-hidden="true"
              />
            ) : (
              <ArrowDownZA
                size={16}
                className="flex-shrink-0 mr-1 text-base-dark group-hover:text-primary-600 my-1 block sm:hidden"
                aria-hidden="true"
              />
            )}
          </span>
        </Popover.Button>

        <Popover.Panel
          className={`origin-top-right absolute ${
            popoverPlaceInMobile === "left"
              ? "left-0 right-auto"
              : "left-auto right-0"
          } ${
            popoverPlace === "left"
              ? "lg:left-0 lg:right-auto"
              : "lg:left-auto lg:right-0"
          } mt-2 bg-white rounded-md shadow-2xl p-4 ring-1 ring-black ring-opacity-5 focus:outline-none z-10`}
        >
          <div className="space-y-4">
            {headerGroups.map((headerGroup, optionIdx) => {
              return headerGroup.headers.map(header => {
                const columnID = header.id
                const label =
                  (header.column.columnDef.header as string) ||
                  capitalize(columnID)
                const sortType = header.column.getIsSorted()
                const enableSorting = !(
                  header.column.columnDef.enableSorting === false
                )

                if (!enableSorting || columnID === "edit") return null

                return (
                  <Fragment key={columnID}>
                    <div className="flex items-center">
                      <input
                        id={`sort-asc-${columnID}-${optionIdx}`}
                        name={`${columnID}[]`}
                        checked={sortType === "asc"}
                        onChange={() => {
                          sort(false, header.column, label)
                        }}
                        type="checkbox"
                        className="h-4 w-4 border-gray-300 rounded text-indigo-500 focus:ring-indigo-400"
                      />
                      <label
                        htmlFor={`sort-asc-${columnID}-${optionIdx}`}
                        className="ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap"
                      >
                        <span className="capitalize">{label}</span> A to Z
                      </label>
                    </div>
                    <div key={columnID} className="flex items-center">
                      <input
                        id={`sort-desc-${columnID}-${optionIdx}`}
                        name={`${columnID}[]`}
                        checked={sortType === "desc"}
                        onChange={() => {
                          sort(true, header.column, label)
                        }}
                        type="checkbox"
                        className="h-4 w-4 border-gray-300 rounded text-indigo-500 focus:ring-indigo-400"
                      />
                      <label
                        htmlFor={`sort-desc-${columnID}-${optionIdx}`}
                        className="ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap"
                      >
                        {label} Z to A
                      </label>
                    </div>
                  </Fragment>
                )
              })
            })}
          </div>
        </Popover.Panel>
      </Popover>
    </div>
  )
}

export default Sorter
