import {
  FC,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react"
import { Formik, FormikProps } from "formik"
import { LoanOfficer } from "../../../../types"
import {
  useAppSelector,
  useToast,
  useValidateEmailAndNMLS
} from "../../../hooks"
import SidePanel from "../../side-panel"
import { selectUserDetails } from "../../../../redux/slices/auth"
import {
  useFetchBrandQuery,
  useFetchBrandsQuery,
  useUpdateLoanOfficerMutation
} from "../../../../redux/queries"
import { loFormSchema } from "./lo-form-schema"
import ManageLoForm from "./form-content"
import If from "../../if"
import { checkIsSuperAdmin } from "../../../utils"
import { defaultValues } from "./defaultValues"

interface Props {
  data?: LoanOfficer
  brandId?: string
  open: boolean
  tabsVisible?: boolean
  onClose: () => void
  setFormRef?: (formRef: RefObject<FormikProps<LoanOfficer>>) => void
  isInSidePanel: boolean
}

const EditLoProfile: FC<Props> = ({
  data,
  brandId,
  open,
  tabsVisible = true,
  onClose,
  setFormRef,
  isInSidePanel
}) => {
  const { pushErrorToast, pushSuccessToast } = useToast()

  const { validateLO } = useValidateEmailAndNMLS()

  const validate = useCallback(
    (lo: LoanOfficer) => validateLO(lo),
    [validateLO]
  )

  const user = useAppSelector(selectUserDetails)

  const [
    updateLoanOfficer,
    { data: response, isSuccess, isError, isLoading, reset }
  ] = useUpdateLoanOfficerMutation()

  const { data: brandDetails } = useFetchBrandQuery(data?.bank_nid ?? "", {
    skip: !data?.bank_nid
  })

  const isSuperAdmin = checkIsSuperAdmin(user?.roles ?? [])

  const { data: brands } = useFetchBrandsQuery(
    isSuperAdmin
      ? { limit: 999 }
      : { org_id: brandDetails?.org_id || user?.org?.id },
    {
      skip: !isSuperAdmin && !brandDetails?.org_id && !user?.org?.id,
      refetchOnMountOrArgChange: true
    }
  )

  const [loading, setLoading] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const formRef = useRef<FormikProps<LoanOfficer>>(null)

  const isCreate = !(data?.uid || data?.loid)

  const setFormField = (name: string, val: string | number) => {
    formRef.current?.setFieldValue(name, val)
  }

  const resetForm = useCallback(() => {
    setLoading(false)
    formRef.current?.resetForm()
    reset()
  }, [reset])

  const onSubmit = async () => {
    if (formRef.current?.values) {
      updateLoanOfficer(formRef.current.values)
    }
  }

  const submitFormHandler = () => {
    setIsSubmitting(true)
    formRef.current?.handleSubmit()
  }

  const handleClose = () => {
    onClose()
    formRef.current?.resetForm()
  }

  useEffect(() => {
    if (formRef.current && setFormRef) {
      setFormRef(formRef)
    }
  }, [setFormRef])

  useEffect(() => {
    if (isSuccess && response?.status === "success") {
      pushSuccessToast(
        `Successfully ${isCreate ? "created" : "updated"} loan officer`
      )
      resetForm()
      onClose()
    } else if (isError || response?.status === "error") {
      pushErrorToast(`Failed to ${isCreate ? "create" : "update"} loan officer`)
    }
    setIsSubmitting(false)
  }, [
    pushSuccessToast,
    pushErrorToast,
    resetForm,
    isCreate,
    onClose,
    isSuccess,
    isError,
    response?.status
  ])

  const brand = useMemo(
    () => brands?.find(b => b.id === brandId),
    [brands, brandId]
  )

  const initialValues = data || {
    ...defaultValues,
    bank_nid: brandId ?? "",
    bank: brand?.name ?? ""
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={loFormSchema}
      validate={validate}
      onSubmit={onSubmit}
      innerRef={formRef}
      validateOnBlur
      enableReinitialize
    >
      {({ values, dirty, isValid, errors }) => {
        return (
          <If
            condition={isInSidePanel}
            then={
              <SidePanel
                title={
                  isCreate
                    ? "Create Loan officer profile"
                    : "Edit Loan officer profile"
                }
                open={open}
                onClose={handleClose}
                isSubmitting={isSubmitting}
                actions={[
                  {
                    label: isCreate ? "Submit" : "Save changes",
                    type: "primary",
                    onClick: submitFormHandler,
                    disabled: loading || isLoading || !dirty || !isValid
                  }
                ]}
              >
                <ManageLoForm
                  values={values}
                  brands={brands ?? []}
                  tabsVisible={tabsVisible}
                  setFormField={setFormField}
                />
              </SidePanel>
            }
            else={
              <ManageLoForm
                values={values}
                brands={brands ?? []}
                tabsVisible={tabsVisible}
                setFormField={setFormField}
              />
            }
          />
        )
      }}
    </Formik>
  )
}

export default EditLoProfile
