import { FC, useMemo } from "react"

import Table from "../../table"
import DescriptionCell from "./cells/description"
import TimestampCell from "./cells/timestamp"
import EmptyState from "../../empty-state"
import RefetchButton from "../refetch-button"
import SingleRowList from "../../skeletons/single-row-list"
import { EXCLUDED_ACTIVITY_TYPES } from "../../../utils"
import { Activity, BBColumnDef } from "../../../../types"

interface Props {
  searchBarVisible?: boolean
  isLoading: boolean
  data?: Activity[]
  refetch: () => void
}

const ActivityTable: FC<Props> = ({
  data,
  isLoading,
  searchBarVisible,
  refetch
}) => {
  const columns: BBColumnDef<Activity>[] = [
    {
      id: "description",
      header: "Description",
      accessorFn: row => row,
      footer: props => props.column.id,
      cell: props => <DescriptionCell info={props.row.original} />,
      colSpan: 3
    },

    {
      id: "timestamp",
      header: "Timestamp",
      accessorFn: row => row.timestamp,
      footer: props => props.column.id,
      cell: props => <TimestampCell info={props.row.original} />
    },
    {
      id: "type",
      header: "Type",
      accessorFn: row => row.type,
      footer: props => props.column.id,
      cell: () => null,
      size: 0
    }
  ]

  const filteredActivities = useMemo(() => {
    if (data?.length && !isLoading) {
      return data!.filter(
        activity => !(activity.type in EXCLUDED_ACTIVITY_TYPES)
      )
    }
    return []
  }, [data, isLoading])

  if (!data?.length && !isLoading) {
    return (
      <EmptyState
        heading="No activities yet"
        subHeading="when you do they will show up here"
      />
    )
  }

  return (
    <div className="py-6 bg-white">
      <RefetchButton
        onClick={refetch!}
        className={`ml-auto ${searchBarVisible ? "mb-6" : ""}`}
      />
      <Table
        data={filteredActivities}
        isLoading={isLoading}
        columns={columns}
        viewType="table"
        pageSize={10}
        searchPlaceholder="Search in activities"
        shrinkFilterSort
        noHeader
        searchBarVisible={searchBarVisible}
        defaultSortBy="timestamp"
        defaultSortDir="desc"
        loaderSkeleton={<SingleRowList />}
      />
    </div>
  )
}

export default ActivityTable
