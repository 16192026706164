import { Address, GooglePlace } from "../../types"

export const convertGooglePlaceToAddressObj = (
  place?: GooglePlace
): Address => {
  if (!place?.address_components) {
    return {}
  }

  // Extract the country component.
  const country = place.address_components.find(component =>
    component.types.includes("country")
  )?.long_name

  // Extract the state component.
  const state = place.address_components.find(component =>
    component.types.includes("administrative_area_level_1")
  )?.short_name

  // Extract the county component.
  const county = place.address_components.find(component =>
    component.types.includes("administrative_area_level_2")
  )?.long_name

  // Extract the sublocality component.
  const sublocality = place.address_components.find(component =>
    component.types.includes("sublocality")
  )?.long_name

  // Extract the neighborhood component.
  const neighborhood = place.address_components.find(component =>
    component.types.includes("neighborhood")
  )?.long_name

  // Extract the city component.
  const city = place.address_components.find(component =>
    component.types.includes("locality")
  )?.long_name

  // Extract the postal code component.
  const zipcode = place.address_components.find(component =>
    component.types.includes("postal_code")
  )?.long_name

  // Extract the address component.
  const addressComponents = place.address_components.filter(
    component =>
      component.types.includes("street_number") ||
      component.types.includes("route")
  )
  const street_address = addressComponents.map(ac => ac.long_name).join(" ")

  return {
    country,
    state,
    city: city ?? sublocality ?? neighborhood,
    county,
    zipcode,
    street_address
  }
}

export const convertAddressObjToPlaceString = (address: Address): string => {
  // Combine the individual fields into a formatted address string
  return [
    address.street_address,
    address.city,
    address.state,
    address.zipcode
    // address.country
  ]
    .filter(part => !!part)
    .join(", ")
}

export const formatLocation = (location: string) =>
  location
    .split(/\s*,\s*/)
    .slice(0, 2)
    .join(", ")

export const formatZipcode = (value: string) => value.replace(/\D/g, "")

export const formatLocationOption = (val: string, location: string) => {
  let limit = ", USA"
  if (/^es\b/.test(navigator.language)) {
    limit = ", EE. UU."
  }
  location = location.substring(0, location.lastIndexOf(limit)) //remove the country
  const re = new RegExp(val, "gi") // reg exp based on the input value
  return location.replace(re, "<strong>$&</strong>")
}
