import { FC, useState } from "react"
import { Mail, Pencil } from "lucide-react"
import { Brand, LoanOfficer } from "../../../types"
import { PrimaryBtn } from "../buttons"
import SectionCell from "../section/cell"
import EditLoProfile from "./edit-lo-profile"
import RoundedImg from "../rounded-img"

interface Props {
  profile?: LoanOfficer
  brand?: Brand
}

const MyLoProfile: FC<Props> = ({ profile, brand }) => {
  const [editSectionVisible, setEditSectionVisible] = useState<boolean>(false)

  const openEdit = () => setEditSectionVisible(true)
  const closeEdit = () => setEditSectionVisible(false)

  if (!profile || !brand) return null

  return (
    <div className="p-6">
      <div className="font-bold text-xl">My LO profile</div>

      <div className="px-6">
        <div className="py-6 flex items-center justify-between border-b border-neutral-200">
          <div className="flex items-center">
            <div className="w-16 h-16 flex items-center justify-center rounded-full border border-neutral-200 overflow-hidden">
              <RoundedImg src={profile.picture} alt="Loan Officer" />
            </div>
            <div className="px-4">
              <div className="text-neutral-900 font-medium">
                {profile.first_name} {profile.last_name}
              </div>
              <div className="pt-1 flex">
                <Mail className="text-neutral-400" />
                <div className="pl-2">{profile.email}</div>
                <div className="px-2 text-neutral-400">●</div>
                ID: {profile.uid}
              </div>
            </div>
          </div>
          <div>
            <PrimaryBtn className="ml-3" onClick={openEdit}>
              <Pencil size={16} className="mr-1" /> Edit LO profile
            </PrimaryBtn>
          </div>
        </div>
        <div>
          <div className="grid grid-cols-2 gap-4">
            <SectionCell label="Brand" value={brand.name} />
            <SectionCell
              label=""
              value={
                <img className="h-full" src={brand.logo} alt="Brand Logo" />
              }
            />
            <SectionCell label="Title" value={profile.position} />
            <SectionCell label="NMLS" value={profile.nmls} />
            <SectionCell label="Pricing engine" value="" />
            <div />
            <SectionCell label="Mobile number" value={profile.mobile_phone} />
            <SectionCell label="Office number" value={profile.office_phone} />
            <SectionCell label="Primary phone number" value={profile.phone} />
            <div />
            <SectionCell
              label="Pre-application link"
              value={profile.app_link}
            />
            <div />
            <SectionCell
              label="Special disclosure link"
              value={profile.special_disclosure_link}
            />
            <div />

            <SectionCell
              label="OB originator ID"
              value={profile.originator_id}
            />
            <SectionCell
              label="OB Business channel ID"
              value={profile.biz_channel}
            />
            <SectionCell label="Bank website" value={brand.website} />
          </div>

          <div>
            <SectionCell label="About me" value="" />
            <SectionCell label="About bank" value={brand.about} />
            <SectionCell label="Loan application link" value={brand.about} />
          </div>
        </div>

        <div className="py-6">
          <div className="text-neutral-900 text-xl font-bold">Social</div>
          <SectionCell label="Facebook link" value="" />
          <SectionCell label="Linkedin link" value="" />
        </div>

        <div className="py-6">
          <div className="text-neutral-900 text-xl font-bold">Office</div>
          <SectionCell
            label="Office address"
            value={[
              profile.office_street_addr,
              profile.office_city,
              profile.office_state,
              profile.office_zipcode
            ]
              .filter(item => !!item)
              .join(", ")}
          />
          <SectionCell label="Branch NMLS" value={brand.nmls} />
        </div>
      </div>

      <EditLoProfile
        data={profile}
        open={editSectionVisible}
        isInSidePanel
        onClose={closeEdit}
      />
    </div>
  )
}

export default MyLoProfile
