import { FC } from "react"

import ActivityInfoCell from "./table/cells/activity-info-cell"
import EngagementsActivityTable from "./table/engagements-activity-table"
import { getDateValueFromString } from "../../utils"
import { BBColumnDef, SubscriptionActivityStat } from "../../../types"

type EngagementsActivityProps = {
  orgId: string | undefined
  brandId: string | undefined
  loid: string | undefined
}

const EngagementsActivity: FC<EngagementsActivityProps> = ({
  orgId,
  brandId,
  loid
}) => {
  const columns: BBColumnDef<SubscriptionActivityStat>[] = [
    {
      id: "subscriber",
      header: "Subscriber",
      accessorFn: row => `${row.first_name} ${row.last_name} ${row.email}`,
      footer: props => props.column.id,
      cell: props => (
        <ActivityInfoCell
          subscriber={`${props.row.original.first_name} ${props.row.original.last_name}`}
          email={props.row.original.email}
          customClass="py-4 pl-2 pr-3"
          colSpan={2}
        />
      ),
      enableSorting: false,
      colSpan: 2
    },
    {
      id: "loan-officer",
      header: "Loan Officer",
      accessorFn: row => row.loan_officer_name,
      footer: props => props.column.id,
      cell: props => (
        <ActivityInfoCell
          loanOfficer={props.row.original.loan_officer_name}
          colSpan={2}
        />
      ),
      enableSorting: false,
      colSpan: 2
    },
    {
      id: "intro-emails-opened",
      header: "Intro Emails Opened",
      accessorFn: row => row.num_emails_opened_intro,
      footer: props => props.column.id,
      cell: props => (
        <ActivityInfoCell info={props.row.original.num_emails_opened_intro} />
      ),
      enableGlobalFilter: false
    },
    {
      id: "alert-emails-opened",
      header: "Alert Emails Opened",
      accessorFn: row => row.num_emails_opened_alert,
      footer: props => props.column.id,
      cell: props => (
        <ActivityInfoCell info={props.row.original.num_emails_opened_alert} />
      ),
      enableGlobalFilter: false
    },
    {
      id: "total-dashboard-views",
      header: "Total Dashboard Views",
      accessorFn: row => row.num_total_dashboard_views,
      footer: props => props.column.id,
      cell: props => (
        <ActivityInfoCell info={props.row.original.num_total_dashboard_views} />
      ),
      enableGlobalFilter: false
    },
    {
      id: "last-email-open",
      header: "Last Email Open",
      accessorFn: row => row.last_email_open_date,
      footer: props => props.column.id,
      cell: props => (
        <ActivityInfoCell
          info={getDateValueFromString(props.row.original.last_email_open_date)}
        />
      ),
      enableGlobalFilter: false
    },
    {
      id: "last-dashboard-view",
      header: "Last Dashboard View",
      accessorFn: row => row.last_dashboard_view_date,
      footer: props => props.column.id,
      cell: props => (
        <ActivityInfoCell
          info={getDateValueFromString(
            props.row.original.last_dashboard_view_date
          )}
        />
      ),
      enableGlobalFilter: false
    }
  ]

  return (
    <EngagementsActivityTable
      orgId={orgId}
      brandId={brandId}
      loid={loid}
      columns={columns}
    />
  )
}

export default EngagementsActivity
