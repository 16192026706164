import { useMemo } from "react"
import { ALLOWED_ROLES_BY_ROUTE } from "../../common/constants"
import { useAppSelector } from "../../common/hooks"
import { PageHeader } from "../../common/ui"
import { PricingLogs } from "../../common/ui/index"
import {
  checkIsAdmin,
  checkIsLoanOfficer,
  checkIsSuperAdmin
} from "../../common/utils"
import { RequireRole } from "../../common/validation"
import { selectUserDetails } from "../../redux/slices/auth"

type Props = {
  title?: string
}

const PricingLogsPage: React.FC<Props> = ({ title = "Pricing logs" }) => {
  const userDetails = useAppSelector(selectUserDetails)
  const roles = userDetails?.roles ?? []
  const isSuperAdmin = checkIsSuperAdmin(roles)
  const isAdmin = checkIsAdmin(roles)
  const isLO = checkIsLoanOfficer(roles)

  const myBrand = useMemo(() => {
    const brand =
      userDetails?.org?.brands?.find(b => b.default_loid === userDetails?.id) ??
      userDetails?.org?.brands?.[0]

    return brand
  }, [userDetails?.id, userDetails?.org?.brands])

  const id = useMemo(() => {
    if (isSuperAdmin) return undefined
    if (isAdmin) return { bank_id: myBrand?.id }
    if (isLO) return { loid: userDetails?.id }
    return undefined
  }, [isAdmin, isLO, isSuperAdmin, myBrand?.id, userDetails?.id])

  return (
    <RequireRole allowedRoles={ALLOWED_ROLES_BY_ROUTE["pricing-logs"]}>
      <>
        <div className="bg-white">
          <div className="p-6">
            <PageHeader title={title} />
          </div>
          <PricingLogs id={id} isBrandVisible />
        </div>
      </>
    </RequireRole>
  )
}

export default PricingLogsPage
