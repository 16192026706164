import { FC, RefObject, useEffect, useRef, useState } from "react"
import { Formik, FormikProps } from "formik"
import { object, string } from "yup"
import { Brand } from "../../../../types"
import SidePanel from "../../side-panel"
import { useAppDispatch, useAppSelector } from "../../../hooks"
import { pushToast } from "../../../../redux/slices/notifications"
import {
  DEFAULT_DISCLAIMER,
  REGEX_HEX_COLOR,
  REGEX_URL_WILDCARD
} from "../../../constants"
import {
  useFetchOrgDetailsQuery,
  useAddBrandMutation,
  useUpdateBrandMutation
} from "../../../../redux/queries"
import ManageBrandFormContent from "./form-content"
import { selectUserDetails } from "../../../../redux/slices/auth"
import {
  checkIsSuperAdmin,
  mapBrandToDraft,
  mapDraftToBrand
} from "../../../utils"

interface Props {
  data?: Brand
  orgId?: string
  open: boolean
  isInSidePanel?: boolean
  onClose: () => void
  setFormRef?: (formRef: RefObject<FormikProps<Brand>>) => void
}

const BrandSchema = object({
  website: string().test("url", "Invalid URL", value => {
    if (!value) {
      return true
    } else {
      return REGEX_URL_WILDCARD.test(value)
    }
  }),
  name: string().required().min(1),
  primary_color: string().matches(REGEX_HEX_COLOR, "Is not a valid hex color"),
  secondary_color: string().matches(REGEX_HEX_COLOR, "Is not a valid hex color")
})

const ManageBrand: FC<Props> = ({
  data,
  orgId,
  open,
  isInSidePanel,
  onClose,
  setFormRef
}) => {
  const dispatch = useAppDispatch()
  const [
    addBrand,
    {
      isLoading: isAdding,
      isSuccess: isAddSuccess,
      isError: isAddError,
      reset: resetAddBrand
    }
  ] = useAddBrandMutation()
  const [
    updateBrand,
    {
      isLoading: isUpdating,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      reset: resetUpdateBrand
    }
  ] = useUpdateBrandMutation()

  const [loading, setLoading] = useState<boolean>(false)

  const user = useAppSelector(selectUserDetails)
  const { data: orgDetails } = useFetchOrgDetailsQuery(
    { id: user?.org?.id ?? "" },
    { skip: !user?.org?.id }
  )

  const isSuperAdmin = checkIsSuperAdmin(user?.roles ?? [])
  const isCreate = !data?.id

  const title = `${isCreate ? "Add new" : "Edit"} brand`
  const subTitle = isCreate
    ? "Setup the details of your new company"
    : undefined
  const submitLabel = isCreate ? "Add brand" : "Update"

  const formRef = useRef<FormikProps<Brand>>(null)

  const initialValues: Brand = data
    ? mapBrandToDraft(data)
    : {
        id: "",
        disable_itemized_fees: false,
        disable_receipt_emails: false,
        disable_subscription_emails: false,
        website: "",
        logo: "",
        nmls: "",
        about: "",
        target_price: "",
        org_id: orgId || orgDetails?.id,
        place_id: "",
        zillow_brand_name: "",
        name: "",
        non_bank: false,
        disclaimer: DEFAULT_DISCLAIMER,
        static_disclaimer: DEFAULT_DISCLAIMER,
        payment_details_disclaimer: DEFAULT_DISCLAIMER,
        loan_calc_disclaimer: DEFAULT_DISCLAIMER,
        lead_capture_disclaimer: DEFAULT_DISCLAIMER,
        rate_alert_disclaimer: DEFAULT_DISCLAIMER,
        third_party_fees_disclaimer: "",
        experience_id: "",
        yelp_id: ""
      }

  const setFormField = (name: string, val: string | number) => {
    formRef.current?.setFieldValue(name, val)
  }

  const resetForm = () => {
    setLoading(false)
    formRef.current?.resetForm()
  }

  const onSubmit = async () => {
    if (formRef.current?.values) {
      if (isCreate) {
        await addBrand(mapDraftToBrand(formRef.current?.values))
      } else {
        await updateBrand(mapDraftToBrand(formRef.current?.values))
      }
      resetForm()
      onClose()
    }
  }

  useEffect(() => {
    if (formRef && setFormRef) {
      setFormRef(formRef)
    }
  }, [setFormRef])

  useEffect(() => {
    if (isAddSuccess) {
      dispatch(
        pushToast({
          msg: "Brand has been added successfully",
          timeout: 3000
        })
      )
      resetAddBrand()
    }
  }, [dispatch, isAddSuccess, resetAddBrand])

  useEffect(() => {
    if (isAddError) {
      dispatch(
        pushToast({
          msg: "Failed to create brand",
          timeout: 3000,
          theme: "danger"
        })
      )
      resetAddBrand()
    }
  }, [dispatch, isAddError, resetAddBrand])

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(
        pushToast({
          msg: "Brand has been updated successfully",
          timeout: 3000
        })
      )
      resetUpdateBrand()
    }
  }, [dispatch, isUpdateSuccess, resetUpdateBrand])

  useEffect(() => {
    if (isUpdateError) {
      dispatch(
        pushToast({
          msg: "Failed to update brand",
          timeout: 3000,
          theme: "danger"
        })
      )
      resetUpdateBrand()
    }
  }, [dispatch, isUpdateError, resetUpdateBrand])

  const loSearchParams = isCreate
    ? isSuperAdmin
      ? {}
      : { org_id: orgId }
    : { brand_id: data.id }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      innerRef={formRef}
      validationSchema={BrandSchema}
      enableReinitialize
    >
      {({ values, isValid, dirty }) => {
        return isInSidePanel ? (
          <SidePanel
            title={title}
            subTitle={subTitle}
            open={open}
            onClose={onClose}
            actions={[
              {
                label: submitLabel,
                type: "primary",
                onClick: onSubmit,
                disabled:
                  loading || isAdding || isUpdating || !(isValid && dirty)
              }
            ]}
          >
            <ManageBrandFormContent
              values={values}
              initialValues={initialValues}
              loSearchParams={loSearchParams}
              setFormField={setFormField}
            />
          </SidePanel>
        ) : (
          <ManageBrandFormContent
            values={values}
            initialValues={initialValues}
            loSearchParams={loSearchParams}
            setFormField={setFormField}
          />
        )
      }}
    </Formik>
  )
}

export default ManageBrand
