import { FC, useEffect, useMemo, useState } from "react"

import ListSkeleton from "../../../../../skeletons/normal-list"
import NoData from "../../../../../no-data"
import Table from "../../../../../table"
import TableFooter from "../../../../../table/footer"
import { useFetchSubscriptionEmailStatsQuery } from "../../../../../../../redux/queries"
import { useTableFooterData } from "../../../../../../hooks"
import { getSubscriptionEmailStatsColumns } from "../columns"
import {
  BBColumnDef,
  SubscriptionEmailStats,
  SubscriptionEmailStatsType
} from "../../../../../../../types"
import { EmailStatsTabs } from "../.."

interface RateAlertsTableProps {
  range: number
  orgId: string | undefined
  loid: string | undefined
}

const RateAlertsTable: FC<RateAlertsTableProps> = ({ range, orgId, loid }) => {
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const rateAlertsColumns: BBColumnDef<SubscriptionEmailStats>[] =
    getSubscriptionEmailStatsColumns()
  const { data: rateAlertsData, isFetching: isFetchingRateAlerts } =
    useFetchSubscriptionEmailStatsQuery(
      {
        range,
        email_type: SubscriptionEmailStatsType.RATE_ALERT,
        org_id: orgId,
        loid
      },
      { refetchOnMountOrArgChange: true }
    )
  const containsData = useMemo(
    () => rateAlertsData && rateAlertsData?.length > 0,
    [rateAlertsData]
  )
  const footerData = useTableFooterData(
    rateAlertsData,
    isFetching,
    containsData,
    EmailStatsTabs.RATE_ALERTS
  )

  useEffect(() => {
    setIsFetching(isFetchingRateAlerts)
  }, [isFetchingRateAlerts])

  return (
    <div className="flex flex-col">
      <Table
        isLoading={isFetching}
        data={rateAlertsData ?? []}
        columns={rateAlertsColumns}
        viewType="table"
        tableContainerClassName="h-[380px]"
        tableClassName="md:table-fixed"
        tableHeaderClassName="px-3 pt-0 pb-6 text-left text-sm font-medium uppercase"
        extraHeaderElementClassName="w-full md:w-auto md:ml-auto"
        loaderSkeleton={
          <ListSkeleton noOfRows={4} containerClassName="px-6 pt-4 pb-8" />
        }
        extraElementAfterTable={
          footerData?.length > 0 ? (
            <TableFooter
              rows={footerData}
              containerClassName={
                rateAlertsData && rateAlertsData.length > 5
                  ? "pl-6 pr-10"
                  : "px-6"
              }
            />
          ) : null
        }
        noDataElement={
          <td colSpan={6}>
            <div className="flex w-full h-[210px]">
              <NoData />
            </div>
          </td>
        }
        searchBarVisible={false}
      />
    </div>
  )
}

export default RateAlertsTable
