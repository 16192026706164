import { FC } from "react"
import { Loader2 } from "lucide-react"
import { useFetchSubscriptionEventsQuery } from "../../../../../../redux/queries"
import { SubscriptionDetails } from "../../../../../../types"
import EngagementEventLog from "./event"

interface Props {
  data: SubscriptionDetails
}

const EngagementEvents: FC<Props> = ({ data }) => {
  const { data: events, isFetching } = useFetchSubscriptionEventsQuery(
    { subscription_id: data.id },
    { skip: !data.id }
  )

  if (isFetching) {
    return (
      <div className="h-full flex items-center justify-center">
        <Loader2
          className="animate-spin h-24 w-24 text-primary-alt-400"
          aria-hidden="true"
        />
      </div>
    )
  }

  if (!events?.length) {
    return <div>No events found.</div>
  }

  return (
    <>
      {events.map(el => (
        <EngagementEventLog key={el.id} data={el} />
      ))}
    </>
  )
}

export default EngagementEvents
