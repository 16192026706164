import { FC, Fragment, useMemo, useState } from "react"
import { Loader2 } from "lucide-react"
import { useFindRateflowLogByIdQuery } from "../../../../../../redux/queries"
import { Lead } from "../../../../../../types"
import QuoteCard from "../../../../engagements/details/sections/quote-history/quote-card"
import QuoteDetails from "../../../../engagements/details/sections/quote-history/quote-details"
import {
  DEFAULT_DATETIME_DAY_FORMAT,
  formatDateTimeFromMillis,
  formatRateflowCard
} from "../../../../../utils"

interface Props {
  data: Lead
}

const LeadQuotesPage: FC<Props> = ({ data }) => {
  const { data: rateflowLog, isFetching } = useFindRateflowLogByIdQuery(
    data.details.quote_id?.toString() ?? "",
    { skip: !data?.details.quote_id }
  )

  const [selectedId, setSelectedId] = useState<string>()

  const resetSelectedId = () => setSelectedId(undefined)

  const selectedCard = useMemo(() => {
    if (!selectedId || !rateflowLog?.cards.length) return
    const found = rateflowLog.cards.find(card => card.hash === selectedId)
    return found ? formatRateflowCard(found) : found
  }, [rateflowLog?.cards, selectedId])

  const loanAmount = rateflowLog?.full_request?.bb_request?.loan_amount || 0

  if (isFetching) {
    return (
      <div className="w-full py-20 flex items-center justify-center">
        <Loader2
          className="animate-spin h-16 w-16 text-primary-alt-400"
          aria-hidden="true"
        />
      </div>
    )
  }

  const hasValidTimestamp =
    rateflowLog?.timestamp && !Number.isNaN(parseInt(rateflowLog.timestamp))

  return (
    <Fragment>
      {hasValidTimestamp && (
        <div className="font-bold text-lg pb-4">
          {formatDateTimeFromMillis(
            parseInt(rateflowLog.timestamp) * 1000,
            DEFAULT_DATETIME_DAY_FORMAT
          )}
        </div>
      )}
      <div className="grid grid-cols-4 gap-4">
        {rateflowLog?.cards?.length ? (
          rateflowLog.cards.map(card => (
            <QuoteCard
              key={card.hash}
              data={{ ...card, loanAmount }}
              onSelect={setSelectedId}
            />
          ))
        ) : (
          <div>No quote cards found.</div>
        )}

        <QuoteDetails
          open={!!selectedCard}
          onClose={resetSelectedId}
          data={selectedCard}
          loanAmount={loanAmount}
        />
      </div>
    </Fragment>
  )
}

export default LeadQuotesPage
