import { FC, ReactNode } from "react"

interface Props {
  label?: string
  value?: ReactNode
  className?: string
  h?: number | string
}

const SectionCell: FC<Props> = ({ className = "", label, value, h = 7 }) => (
  <div className={`${className} pt-6`}>
    {label ? (
      <div className="pb-1 text-neutral-400">{label}</div>
    ) : (
      <div className="h-6" />
    )}
    <div className={`text-neutral-900 ${typeof h === "number" ? `h-${h}` : h}`}>
      {value || "-"}
    </div>
  </div>
)

export default SectionCell
