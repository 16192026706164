import { ClipboardCheck, Copy, RefreshCcw } from "lucide-react"
import { FC } from "react"
import { Integration } from "../../../../types"
import { LightBtn } from "../../buttons"
import Steps from "../../steps"

const STEPS = [
  {
    title: "Copy the code snippet from above",
    icon: Copy,
    iconBackground: "bg-stone-50"
  },
  {
    title: "Paste the code in the header section of the webpage.",
    icon: ClipboardCheck,
    iconBackground: "bg-stone-50"
  },
  {
    title: "Reload the webpage and check if its integrated.",
    icon: RefreshCcw,
    iconBackground: "bg-stone-50"
  }
]

interface Props {
  integration?: Integration
}

const Install: FC<Props> = ({ integration }) => {
  const copy = () => {
    navigator.clipboard.writeText(
      integration?.app_settings?.install_script ?? ""
    )
  }

  return (
    <div>
      <textarea
        className="appearance-none block w-full p-5 border border-neutral-200 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm cursor-text resize-none mb-4"
        name="install_script"
        rows={4}
        disabled
        value={integration?.app_settings?.install_script}
      />
      <LightBtn
        className="w-full mb-10"
        onClick={() => {
          copy()
        }}
      >
        <Copy size={20} className="mr-2" /> Copy
      </LightBtn>

      <Steps steps={STEPS} title="Steps to install" />
    </div>
  )
}
export default Install
