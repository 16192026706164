export type ResthookStatus = "success" // TODO: add more options
export type YesOrNo = "yes" | "no"
export type LoanPurpose = "purchase" | "refinance"
export type PropertyType =
  | "single_family_home"
  | "townhome"
  | "condominium"
  | "multi_tenant_home" // TODO: add more options
export type ResidencyType = "primary_home" | "second_home" | "rental_home" // TODO: add more options

export interface LeadDetails {
  coborrower?: string
  credit_score?: number
  debt_to_income?: string
  down_payment?: string
  employment_status?: string
  first_name?: string
  last_name?: string
  foreclosure_bankruptcy?: string
  has_preferred_lo?: YesOrNo
  household_income?: number
  jornaya_lead_id?: string
  list_price: number
  loan_amount: number
  loan_purpose: LoanPurpose
  loan_type: string
  loan_term?: number
  arm_term?: number
  cash_out?: number
  location: {
    city: string
    state: string
    street_address?: string
    street?: string
    county?: string
    zipcode: string
  }
  message?: string
  military_eligible?: YesOrNo
  parent_url?: string
  property_type: PropertyType
  purchase_readiness?: string
  quote_id?: number
  realtor_referral?: YesOrNo
  referral_source?: string
  residency_type: ResidencyType
  street_address?: string
  admin_fields?: any
  utm_campaign?: string
  utm_content?: string
  utm_source?: string
  utm_medium?: string
  gclid?: string
  first_time_homebuyer?: boolean
}

export interface Lead {
  first_name: string
  last_name: string
  phone: string
  email: string
  timestamp: string
  date_time: string
  validation: any
  id: string
  resthook_status: ResthookStatus
  details: LeadDetails
  owner: string
  app_key: string
  brand_id: string
  status?: string
  message?: string
}

export interface LeadLog {
  brand_name: string
  email: string
  group_id: string
  id: string
  owner: string
  owner_email: string
  resthook_status: string
  timestamp: string
  name?: string
}

export interface LeadHistory {
  app_key: string
  category: string
  id: string
  ip: string
  name: string
  timestamp: string
  token: string
}

export interface LeadHistoryResponse {
  payload: LeadHistory[]
}

export type LeadCountProperties = {
  timestamp_tz_date: string
  lead_count: number
  percentage?: number
  color?: string
}

export interface LeadCount extends LeadCountProperties {}

export interface LeadCountCumulative extends LeadCountProperties {}

export interface DuplicateLeadCount {
  duplicate_lead_count: number
}

export interface TopLeadBreakdownData {
  lead_count: number
  url?: string
  name?: string
}

export interface LeadCountByState
  extends Omit<LeadCountProperties, "timestamp_tz_date"> {
  state: string
}

export interface LeadCountByCity
  extends Omit<LeadCountProperties, "timestamp_tz_date"> {
  city: string
}

export interface LeadCountByProperty
  extends Omit<LeadCountProperties, "timestamp_tz_date"> {
  PropertyType: string
}

export interface LeadCountByResidency
  extends Omit<LeadCountProperties, "timestamp_tz_date"> {
  ResidencyType: string
}

export interface LeadCountByLoanPurpose
  extends Omit<LeadCountProperties, "timestamp_tz_date"> {
  LoanPurpose: string
}

export interface LeadMetrics {
  Household_Income_Average: number
  Credit_Score_Average: number
  List_Price_Average: number
  Loan_Balance_Average: number
}

export interface LeadConversionFunnel {
  lead_loaded_count: number
  lead_started_count: number
  lead_started_perc: number
  lead_captured_count: number
  lead_captured_perc: number
}

export interface LeadConversionProgress {
  lead_started_count: number
  lead_progress25_count: number
  lead_progress50_count: number
  lead_progress75_count: number
  lead_completed_count: number
  lead_captured_count: number
  lead_progress25_perc: number
  lead_progress50_perc: number
  lead_progress75_perc: number
  lead_completed_perc: number
  lead_captured_perc: number
  lead_between_started_and_progress25_perc: number
  lead_between_progress25_and_progress50_perc: number
  lead_between_progress50_and_progress75_perc: number
  lead_between_progress75_and_completed_perc: number
  lead_between_completed_and_captured_perc: number
}

export type SubscriptionCountProperties = {
  period_start: string
  subscription_count: number
}

export interface SubscriptionCount extends SubscriptionCountProperties {}

export interface SubscriptionCountCumulative
  extends SubscriptionCountProperties {}

export interface ExitPopUpConversionFunnel {
  Lead_Entered_Exit_Pop_Up: number
  Lead_Converted_from_Exit_Pop_Up: number
  conversion_perc: number
}

export interface RateTableUsage {
  period_start: string
  impressions: number
  update_rate_click: number
  get_started_click: number
  personalized_rate_click: number
  subscribe_switch_click: number
}

export interface SubscriptionEmailStats {
  sub_loid?: number
  loan_officer_name: string
  email_sent: number
  email_opened: number
  open_rate: number
  email_clicked: number
}

export enum SubscriptionEmailStatsType {
  RATE_ALERT = "rate_alert",
  INTRO = "intro"
}

export interface UnsubscribedStats {
  sub_loid: number
  loan_officer_name: string
  unsubscribed: number
  stale_unsubscribed: number
}

export interface QuoteReceiptStats extends SubscriptionEmailStats {
  Client_Name: string
  loid: number
}

export interface UTMParametersStats {
  name: string
  lead_count: number
}

export enum UTMParametersStatsType {
  CAMPAIGN = "campaign",
  SOURCE = "source",
  MEDIUM = "medium"
}

export interface LoanSummaryPdf {
  elapsed: number
  file: string
  label: string
}
