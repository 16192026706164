import { FC } from "react"

import If from "../if"
import Cards from "../pricing-logs/pricing-log-card-details/cards"
import { useFindRateflowLogByIdQuery } from "../../../redux/queries"
import {
  formatUnderscoresInField,
  formatDateTimeFromTimestamp,
  getNonEmptyStringOrDefault,
  isNotEmptyString
} from "../../utils"
import { OrgActivity } from "../../../types"

interface ActivityDetailsFullProps {
  data?: OrgActivity
}

export const ActivityDetailsFull: FC<ActivityDetailsFullProps> = ({ data }) => {
  const leadDetails = data?.details?.lead
  const loanInfo = leadDetails?.details
  const quoteId = loanInfo?.quote_id?.toString() ?? ""
  const loanAmount = loanInfo?.loan_amount

  const { data: rateData, isFetching } = useFindRateflowLogByIdQuery(quoteId, {
    skip: quoteId === ""
  })

  return (
    <>
      {leadDetails && (
        <div className="text-sm">
          <div className="py-6">
            <div className="font-bold text-lg">Lead details</div>
            <div className="flex pt-4">
              <div className="flex-1">
                <div className="text-gray-300">Name</div>
                <div className="pt-1">
                  {leadDetails.first_name}&nbsp;
                  {leadDetails.last_name}
                </div>
              </div>
              <div className="flex-1">
                <div className="text-gray-300">Email</div>
                <div className="pt-1">
                  {getNonEmptyStringOrDefault(leadDetails.email)}
                </div>
              </div>
            </div>
            <div className="flex pt-4">
              <div className="flex-1">
                <div className="text-gray-300">Phone</div>
                <div className="pt-1">
                  {getNonEmptyStringOrDefault(leadDetails.phone)}
                </div>
              </div>
              <div className="flex-1">
                <div className="text-gray-300">Date & Time</div>
                <div className="pt-1">
                  {isNotEmptyString(leadDetails.timestamp)
                    ? formatDateTimeFromTimestamp(
                        leadDetails.timestamp,
                        "LL/dd/yyyy 'at' hh:mm:ss a"
                      )
                    : "-"}
                </div>
              </div>
            </div>
          </div>

          {loanInfo && (
            <div className="py-6">
              <div className="font-bold text-lg">Loan information</div>
              <div className="flex pt-4">
                <div className="flex-1">
                  <div className="text-gray-300">Down payment</div>
                  <div className="pt-1">
                    {getNonEmptyStringOrDefault(loanInfo?.down_payment)}
                  </div>
                </div>
                <div className="flex-1">
                  <div className="text-gray-300">List price</div>
                  <div className="pt-1">
                    {getNonEmptyStringOrDefault(
                      loanInfo.list_price?.toString()
                    )}
                  </div>
                </div>
              </div>
              <div className="flex pt-4">
                <div className="flex-1">
                  <div className="text-gray-300">Purchase readiness</div>
                  <div className="pt-1">
                    {isNotEmptyString(loanInfo?.purchase_readiness)
                      ? formatUnderscoresInField(loanInfo.purchase_readiness)
                      : "-"}
                  </div>
                </div>
                <div className="flex-1">
                  <div className="text-gray-300">Credit score</div>
                  <div className="pt-1">
                    {getNonEmptyStringOrDefault(
                      loanInfo?.credit_score?.toString()
                    )}
                  </div>
                </div>
              </div>
              <div className="flex pt-4">
                <div className="flex-1">
                  <div className="text-gray-300">Loan purpose</div>
                  <div className="pt-1">
                    {isNotEmptyString(loanInfo.loan_purpose)
                      ? formatUnderscoresInField(loanInfo.loan_purpose)
                      : "-"}
                  </div>
                </div>
                <div className="flex-1">
                  <div className="text-gray-300">Residency type</div>
                  <div className="pt-1">
                    {isNotEmptyString(loanInfo.residency_type)
                      ? formatUnderscoresInField(loanInfo.residency_type)
                      : "-"}
                  </div>
                </div>
              </div>
              <div className="flex pt-4">
                <div className="flex-1">
                  <div className="text-gray-300">Military eligible</div>
                  <div className="pt-1">
                    {getNonEmptyStringOrDefault(loanInfo?.military_eligible)}
                  </div>
                </div>
                <div className="flex-1">
                  <div className="text-gray-300">Household income</div>
                  <div className="pt-1">
                    {getNonEmptyStringOrDefault(
                      loanInfo?.household_income?.toString()
                    )}
                  </div>
                </div>
              </div>
              <div className="flex pt-4">
                <div className="flex-1">
                  <div className="text-gray-300">Location</div>
                  <div className="pt-1">
                    {isNotEmptyString(loanInfo.location.city) ||
                    isNotEmptyString(loanInfo.location.state)
                      ? `${loanInfo.location.city} ${loanInfo.location.state}`
                      : "-"}
                  </div>
                </div>
                <div className="flex-1">
                  <div className="text-gray-300">Property type</div>
                  <div className="pt-1">
                    {isNotEmptyString(loanInfo.property_type)
                      ? formatUnderscoresInField(loanInfo.property_type)
                      : "-"}
                  </div>
                </div>
              </div>
              <div className="flex pt-4">
                <div className="flex-1">
                  <div className="text-gray-300">Loan amount</div>
                  <div className="pt-1">
                    {getNonEmptyStringOrDefault(loanAmount?.toString())}
                  </div>
                </div>
                <div className="flex-1">
                  <div className="text-gray-300">Quote ID</div>
                  <div className="pt-1">
                    {getNonEmptyStringOrDefault(quoteId)}
                  </div>
                </div>
              </div>
              <div className="flex pt-4">
                <div className="flex-1">
                  <div className="text-gray-300">Parent URL</div>
                  <div className="pt-1">
                    {getNonEmptyStringOrDefault(loanInfo?.parent_url)}
                  </div>
                </div>
                <div className="flex-1"></div>
              </div>
            </div>
          )}

          <div className="py-6">
            <If
              isLoading={isFetching}
              condition={!!rateData?.cards?.length}
              then={
                <div className="grid grid-cols-3 gap-6">
                  {rateData?.cards.map(rate => (
                    <Cards
                      key={rate.hash}
                      data={rate}
                      loanAmount={loanAmount}
                    />
                  ))}
                </div>
              }
              else={
                <div className="text-sm font-medium">No rate cards found.</div>
              }
            />
          </div>
        </div>
      )}
    </>
  )
}

export default ActivityDetailsFull
