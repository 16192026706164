import { FC } from "react"
import { Integration } from "../../../../../types"
import { useIntegrationSettingsForm } from "../../../../hooks"
import ColorPicker from "../color-picker"

interface Props {
  integration?: Integration
}

const Colors: FC<Props> = ({ integration }) => {
  const { app_settings } = useIntegrationSettingsForm(integration)
  return (
    <div className="grid gap-y-6 gap-x-4 grid-cols-12 pt-6">
      <div className="col-span-6">
        <ColorPicker
          name="accent_color"
          label="Primary Color"
          value={app_settings?.settings?.accent_color ?? ""}
        />
      </div>
      <div className="col-span-6">
        <ColorPicker
          name="secondary_accent_color"
          label="Seconday Color"
          value={app_settings?.settings?.secondary_accent_color ?? ""}
        />
      </div>
    </div>
  )
}

export default Colors
