import { FC, useCallback, useMemo, useState } from "react"
import { ChevronDown } from "lucide-react"
import { ALLOWED_ROLES_BY_ROUTE } from "../../common/constants"
import { LightBtn, PageHeader, PrimaryBtn } from "../../common/ui"
import { RequireRole } from "../../common/validation"
import Tabs from "../../common/ui/tabs"
import ConfigLeadWorkflow from "../../common/ui/config-admin/config-lead-workflow"
import ConfigRateflow from "../../common/ui/config-admin/config-rateflow"
import ConfigContactForm from "../../common/ui/config-admin/config-contact-form"
import ConfigRateTable from "../../common/ui/config-admin/config-rate-table"
import { useFetchAllBrandsQuery } from "../../redux/queries"
import ConfigCountyAdmin from "../../common/ui/config-admin/config-county-admin"
import { AddNewDropdownItem, PanelType } from "../../types"
import Dropdown from "../../common/ui/dropdown"
import { setVisiblePanel } from "../../redux/slices/app"
import { useAppDispatch } from "../../common/hooks"
import ConfigSubscription from "../../common/ui/config-admin/config-subscription"

const ConfigAdminPage: FC<{}> = () => {
  const dispatch = useAppDispatch()

  const [tab, setTab] = useState<string>("rate-table")
  const [countyAdminVisible, setCountyAdminVisible] = useState<boolean>(false)

  const { data: brands } = useFetchAllBrandsQuery({ limit: 999 })

  const onClickCountyAdmin = () => setCountyAdminVisible(true)

  const closeCountyAdmin = () => setCountyAdminVisible(false)

  const openPanel = useCallback(
    (panelType: PanelType) => {
      dispatch(setVisiblePanel(panelType))
    },
    [dispatch]
  )

  const addNewDropdownItems: AddNewDropdownItem[] = useMemo(
    () => [
      {
        name: "Rate table",
        requiredRole: "administrator",
        onClick: () => openPanel("rate-table-config/new")
      },
      {
        name: "Lead workflow",
        requiredRole: "administrator",
        onClick: () => openPanel("lead-workflow/new")
      },
      {
        name: "Rateflow",
        requiredRole: "administrator",
        onClick: () => openPanel("rate-flow/new")
      },
      {
        name: "Contact form",
        requiredRole: "administrator",
        onClick: () => openPanel("contact-form/new")
      },
      {
        name: "Subscription Config",
        requiredRole: "administrator",
        onClick: () => openPanel("subscription-config/new")
      }
    ],
    [openPanel]
  )

  return (
    <RequireRole allowedRoles={ALLOWED_ROLES_BY_ROUTE["config-admin"]}>
      <div className="h-full flex flex-col">
        <div className="h-full py-6 bg-white">
          <div className="px-6">
            <PageHeader title="Config admin">
              <Dropdown
                items={addNewDropdownItems}
                menuBtn={
                  <PrimaryBtn
                    onClick={() => true}
                    className="flex items-center px-2"
                  >
                    <span className="mr-2">Add New</span>
                    <ChevronDown size={20} />
                  </PrimaryBtn>
                }
              />
              <LightBtn className="ml-3" onClick={onClickCountyAdmin}>
                County Admin
              </LightBtn>
            </PageHeader>
          </div>

          <div>
            <Tabs
              className="px-6"
              currentTab={tab}
              tabs={[
                { id: "rate-table", label: "Rate table" },
                { id: "lead-workflow", label: "Lead workflow" },
                { id: "rateflow", label: "Rateflow" },
                { id: "contact-form", label: "Contact form" },
                { id: "subscription", label: "Subscription" }
              ]}
              onChange={setTab}
            />
          </div>
          <div className="p-6 flex-1">
            {tab === "rate-table" && <ConfigRateTable brands={brands ?? []} />}
            {tab === "lead-workflow" && (
              <ConfigLeadWorkflow brands={brands ?? []} />
            )}
            {tab === "rateflow" && <ConfigRateflow brands={brands ?? []} />}
            {tab === "contact-form" && (
              <ConfigContactForm brands={brands ?? []} />
            )}
            {tab === "subscription" && <ConfigSubscription />}
          </div>
        </div>

        <ConfigCountyAdmin
          show={countyAdminVisible}
          onClose={closeCountyAdmin}
        />
      </div>
    </RequireRole>
  )
}

export default ConfigAdminPage
