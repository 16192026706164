import { Workflow } from "../../../../../../types"
import { useCheckLeadworkflowStatusQuery } from "../../../../../../redux/queries"
import Status from "../../../../status"
import { formatDateTimeFromTimestamp } from "../../../../../utils"

type Props = {
  info: Workflow
  onSelect: (val: string) => void
}

const NameCell = ({ info, onSelect }: Props) => {
  const { name, created, id } = info

  const { data: isActive } = useCheckLeadworkflowStatusQuery(
    { id },
    { skip: !id }
  )

  return (
    <td
      className="whitespace-nowrap py-4 pl-2 pr-3 cursor-pointer"
      onClick={() => onSelect(id)}
    >
      <div className="flex items-center">
        <div className="font-bold mr-2">{name}</div>
        {isActive === "active" ? <Status.Active /> : <Status.Inactive />}
      </div>
      <div className="text-sm text-neutral-400 mt-1">
        Added on&nbsp;
        {created ? formatDateTimeFromTimestamp(created, "LLL dd yyyy") : ""}
      </div>
    </td>
  )
}

export default NameCell
