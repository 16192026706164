/**
  Organization API wrapper

	JSON endpoint: /org/manage.json
	GET /org/manage.json - list all orgs belonging to current user
	GET /org/manage.json?id={org_id} - returns org details and a list of brands that belong to the org
	POST /org/manage.json?id={org_id} - update org details. expects the same JSON model that GET returned
	GET /org/members.json?id={org_id} - returns list of members organized by brand {brand id is array key}
**/

import { api } from "../common/utils"
import {
  ORG_CREATE_ENDPOINT,
  ORG_MANAGE_ENDPOINT,
  ORG_MEMBERS_ENDPOINT
} from "../common/constants"
import {
  APIError,
  CreateOrgPayload,
  CreateOrgResponse,
  Organization,
  LoanOfficer
} from "../types"

const org = {
  get: async (): Promise<Organization | APIError> => {
    const { data } = await api.get(ORG_MANAGE_ENDPOINT)
    return data
  },
  getById: async (org_id: string): Promise<Organization | APIError> => {
    if (!org_id) {
      throw new Error("org id missing")
    }
    const { data } = await api.get(`${ORG_MANAGE_ENDPOINT}?id=${org_id}`)
    return data
  },
  update: async (orgData: Organization): Promise<Organization | APIError> => {
    if (!orgData.id) {
      throw new Error("org id missing")
    }

    const { data } = await api.post(ORG_MANAGE_ENDPOINT, orgData)
    return data
  },
  create: async (
    orgData: CreateOrgPayload
  ): Promise<CreateOrgResponse | APIError> => {
    if (!orgData.name) {
      throw new Error("org name is missing")
    }

    const { data } = await api.post(ORG_CREATE_ENDPOINT, orgData)
    return data
  },
  getMembers: async (
    org_id: string
  ): Promise<{ count: number; members: LoanOfficer[] } | APIError> => {
    if (!org_id) {
      throw new Error("org id missing")
    }
    const {
      data: { count, members }
    } = await api.get<{
      count: number
      members: {
        [key: string]: {
          members: LoanOfficer[]
        }
      }
    }>(`${ORG_MEMBERS_ENDPOINT}?id=${org_id}`)

    return {
      count,
      members: Object.values(members).flatMap(a => a.members)
    }
  }
}

export default org
