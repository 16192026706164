import { createApi } from "@reduxjs/toolkit/dist/query/react"
import bbApi from "../../api"
import {
  RTK_SLICE_API_LIST,
  ENDPOINT_INTEGRATIONS,
  ENDPOINT_STATUS,
  MONITOR_ENDPOINT
} from "../../common/constants"
import {
  IntegrationResponse,
  MonitorGetResponse,
  RootIntegrations,
  IntegrationStatus,
  IntegrationStatusValues,
  IntegrationPost,
  IntegrationUpdateOwnerPayload
} from "../../types"
import { baseQuery, getTags } from "../api"
import { getQueryString } from "../../common/utils"

const reducerPath = "integrations-api"

export const integrationsApi = createApi({
  reducerPath,
  baseQuery,
  tagTypes: [reducerPath],
  endpoints: builder => ({
    fetchIntegrations: builder.query({
      query: (params: { org_id?: string; brand_id?: string; limit?: number }) =>
        `${ENDPOINT_INTEGRATIONS}${getQueryString(params)}`,
      transformResponse: (responseData: RootIntegrations) =>
        responseData.results || [],
      providesTags: result => getTags(reducerPath, "id", result)
    }),
    fetchIntegrationsByLoId: builder.query({
      query: (LOId: string) => `${ENDPOINT_INTEGRATIONS}?loid=${LOId}`,
      transformResponse: (responseData: RootIntegrations) =>
        responseData.results || [],
      providesTags: result => getTags(reducerPath, "id", result)
    }),
    getIntegrationDetails: builder.query({
      query: (integrationID: string) =>
        `${ENDPOINT_INTEGRATIONS}?id=${integrationID}&full_detail=true`,
      transformResponse: (response: IntegrationResponse) => response.results[0],
      providesTags: (result, error, arg) => [{ type: reducerPath, id: arg }]
    }),
    getStatus: builder.query({
      query: ({
        integrationID,
        extraParams,
        limit = 25
      }: {
        integrationID: string
        extraParams?: string
        limit?: number
      }) =>
        `${ENDPOINT_STATUS}?integration_id=${integrationID}&limit=${limit}${
          extraParams ? `&${extraParams}` : ""
        }`,
      transformResponse: (response: IntegrationStatus[]) => {
        return response
      },
      providesTags: (result, error, arg) => [
        { type: reducerPath, id: arg.integrationID }
      ]
    }),
    getQuickStatus: builder.query({
      query: ({ integrationID }: { integrationID: string }) =>
        `${ENDPOINT_STATUS}?integration_id=${integrationID}&quick_status=true`,
      transformResponse: (response: IntegrationStatusValues) => {
        return response
      },
      providesTags: (result, error, arg) => [
        { type: reducerPath, id: arg.integrationID }
      ]
    }),
    getMonitor: builder.query({
      query: (appKey: string) => `${MONITOR_ENDPOINT}?app_key=${appKey}`,
      transformResponse: (response: MonitorGetResponse) => response
    }),
    saveIntegration: builder.mutation({
      queryFn: async (payload: IntegrationPost) => {
        const response = await bbApi.integrations.save(payload)
        return { data: response }
      },
      invalidatesTags: (result, error, arg) =>
        arg.id
          ? [
              { type: reducerPath, id: RTK_SLICE_API_LIST },
              { type: reducerPath, id: arg.id }
            ]
          : [{ type: reducerPath, id: RTK_SLICE_API_LIST }]
    }),
    updateIntegration: builder.mutation({
      queryFn: async ({ id, owner, integration_type, field }: any) => {
        const data = await bbApi.integrations.update({
          id,
          owner,
          integration_type,
          ...field
        })
        return { data }
      },
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: arg.id },
        { type: reducerPath, id: RTK_SLICE_API_LIST }
      ]
    }),
    updateOwner: builder.mutation({
      queryFn: async ({
        id,
        integration_type,
        newOwnerID
      }: IntegrationUpdateOwnerPayload) => {
        const data = await bbApi.integrations.update({
          id,
          owner: newOwnerID,
          integration_type
        })
        return { data }
      },
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: arg.id }
      ]
    }),
    deleteIntegration: builder.mutation({
      queryFn: async (id: string) => {
        const data = await bbApi.integrations.delete(id)
        return { data }
      },
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: arg },
        { type: reducerPath, id: RTK_SLICE_API_LIST }
      ]
    })
  })
})

export const {
  useFetchIntegrationsQuery,
  useFetchIntegrationsByLoIdQuery,
  useGetIntegrationDetailsQuery,
  useGetStatusQuery,
  useGetQuickStatusQuery,
  useGetMonitorQuery,
  useSaveIntegrationMutation,
  useUpdateIntegrationMutation,
  useUpdateOwnerMutation,
  useDeleteIntegrationMutation
} = integrationsApi
