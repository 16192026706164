import { useState, useEffect } from "react"

import { createFunnelStage, createPolygonBetweenFunnels } from "../utils"
import {
  ExitPopUpConversionFunnel,
  FunnelStage,
  LeadConversionFunnel
} from "../../types"

export const useFunnelStagesTransformation = (
  funnelData: LeadConversionFunnel | ExitPopUpConversionFunnel | undefined,
  containsData: boolean | undefined
): FunnelStage[] => {
  const [data, setData] = useState<FunnelStage[]>([])
  const isLeadConversionFunnel = (
    object: LeadConversionFunnel | ExitPopUpConversionFunnel
  ): object is LeadConversionFunnel => {
    return "lead_loaded_count" in object
  }

  useEffect(() => {
    const stages: FunnelStage[] = []

    if (containsData) {
      if (isLeadConversionFunnel(funnelData!)) {
        // Push the lead workflow loaded funnel
        stages.push(
          createFunnelStage(
            "Lead Workflow Loaded",
            funnelData!.lead_loaded_count,
            100,
            createPolygonBetweenFunnels(100, funnelData!.lead_started_perc)
          )
        )

        // Push the lead workflow started funnel
        stages.push(
          createFunnelStage(
            "Lead Workflow Started",
            funnelData!.lead_started_count,
            funnelData!.lead_started_perc,
            createPolygonBetweenFunnels(
              funnelData!.lead_started_perc,
              funnelData!.lead_captured_perc
            )
          )
        )

        // Push the lead workflow captured funnel
        stages.push(
          createFunnelStage(
            "Lead Captured",
            funnelData!.lead_captured_count,
            funnelData!.lead_captured_perc,
            undefined,
            "#63C7C4"
          )
        )
      } else {
        // Push the lead entered exit popup funnel
        stages.push(
          createFunnelStage(
            "Lead Entered Exit Pop Up",
            funnelData!.Lead_Entered_Exit_Pop_Up,
            100,
            createPolygonBetweenFunnels(100, funnelData!.conversion_perc)
          )
        )

        // Push the lead converted exit popup funnel
        stages.push(
          createFunnelStage(
            "Lead Converted Exit Pop Up",
            funnelData!.Lead_Converted_from_Exit_Pop_Up,
            funnelData!.conversion_perc * 100,
            undefined,
            "#63C7C4"
          )
        )
      }
    }
    setData(stages)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containsData])
  return data
}
