import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { Logo, Card } from "../../ui"
import { useEffect } from "react"
import { useAppDispatch } from "../../hooks"
import { loginWithSession } from "../../../redux/slices/auth"

const SingleForm = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  // If is a google sign in we get the session ID from the URL hash
  const { hash } = useLocation()
  const sessid = hash.slice(hash.lastIndexOf("=") + 1)

  useEffect(() => {
    if (!sessid) return
    dispatch(loginWithSession(sessid)).then((res: any) => {
      console.log(res)
      // if user is not loggein in, redirect to login page
      if (!res.payload?.data?.user) {
        navigate("/user/login", { replace: true })
      } else {
        // if user is logged in, redirect to home page
        navigate("/", { replace: true })
      }
    })
  }, [dispatch, sessid, navigate])

  return (
    <div className="min-h-full flex flex-col justify-center py-12 px-4 sm:px-6 lg:px-8 single-form bg-primary-alt-400 bg-bb-logos">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Card>
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <Logo className="mx-auto my-2 h-12 w-auto" isFull={false} />
          </div>
          <Outlet />
        </Card>
      </div>
    </div>
  )
}

export default SingleForm
