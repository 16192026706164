import { FC } from "react"
import { Activity, LoanOfficer } from "../../../../types"
import { convertActivityToString } from "../../../utils"
import RoundedImg from "../../rounded-img"

interface Props {
  activity: Activity
  member: LoanOfficer
  isLoActivity?: boolean
}

const ActivityCard: FC<Props> = ({ activity, member, isLoActivity }) => (
  <div className="flex items-center justify-between py-4">
    {!!isLoActivity ? (
      <>
        {member?.picture && (
          <div className="w-6 h-6 md:w-12 md:h-12 flex shrink-0 self-start md:self-center items-center rounded-full overflow-hidden mr-4">
            <RoundedImg src={member.picture} alt={member?.name} />
          </div>
        )}
        <div className="flex-1 whitespace-break-spaces break-words">
          {convertActivityToString(activity)}
        </div>
      </>
    ) : (
      <>
        {member?.logo && (
          <div className="w-6 h-6 md:w-12 md:h-12 flex shrink-0 self-start md:self-center items-center rounded-full overflow-hidden mr-4">
            <img
              src={member.logo}
              alt={member?.name}
              className="w-auto h-full object-contain"
            />
          </div>
        )}
        <div className="flex-1 whitespace-break-spaces break-words">
          {convertActivityToString(activity)}
        </div>
      </>
    )}
  </div>
)

export default ActivityCard
