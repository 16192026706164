import { FormikProps } from "formik"
import { FC, RefObject } from "react"
import { Brand } from "../../../../../types"
import ManageBrand from "../../../brands/manage"

interface Props {
  orgId?: string
  setFormRef: (formRef: RefObject<FormikProps<Brand>>) => void
}

const CreateBrandForm: FC<Props> = ({ orgId = "", setFormRef }) => {
  return (
    <div>
      <ManageBrand
        open
        orgId={orgId}
        onClose={() => true}
        isInSidePanel={false}
        setFormRef={setFormRef}
      />
    </div>
  )
}

export default CreateBrandForm
