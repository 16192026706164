import { FC } from "react"
import { LoanOfficer, ZillowReview } from "../../../../types"
import LoanOfficerOverview from "./LoanOfficerOverview"
import ZillowReviewsList from "../../zillow-reviews-list"

interface Props {
  loanOfficer: LoanOfficer | null
  review?: ZillowReview[]
}

const LoanOfficerDetails: FC<Props> = ({ loanOfficer, review }) => {
  return (
    <div className="p-6 max-w-3xl">
      <LoanOfficerOverview loanOfficer={loanOfficer as LoanOfficer} />
      <ZillowReviewsList reviews={review} />
    </div>
  )
}

export default LoanOfficerDetails
