import { FC, Fragment, useMemo, useState } from "react"
import { CellContext } from "@tanstack/react-table"
import { ChevronRight } from "lucide-react"
import Table from "../table"
import Select from "../form/select"
import TimestampCell from "./cells/TimestampCell"
import StandardCell from "./cells/StandardCell"
import ButtonGroup from "../button-group"
import PricingLogCardDetails from "./pricing-log-card-details"
import { useFetchRateflowLogsQuery } from "../../../redux/queries"
import { isNotZeroString } from "../../utils"
import { BBColumnDef, PricingLog, SelectOption } from "../../../types"

interface Props {
  id?: { loid?: string; bank_id?: string; org_id?: string }
  isBrandVisible?: boolean
}

const getClassName = (props: CellContext<PricingLog, unknown>) => {
  const { base_rate } = props.row.original
  return isNotZeroString(base_rate) ? "" : "bg-red-50"
}

enum PRICING_LOGS_OPTION_IDS {
  ALL_REQUESTS = "all-requests",
  SUCCESSFUL_REQUESTS = "successful-requests",
  FAILED_REQUESTS = "failed-requests"
}

const PRICING_LOGS_OPTIONS: SelectOption[] = [
  {
    id: PRICING_LOGS_OPTION_IDS.ALL_REQUESTS,
    name: "All Requests"
  },
  {
    id: PRICING_LOGS_OPTION_IDS.SUCCESSFUL_REQUESTS,
    name: "Successful Requests"
  },
  {
    id: PRICING_LOGS_OPTION_IDS.FAILED_REQUESTS,
    name: "Failed Requests"
  }
]

const PricingLogs: FC<Props> = ({ id, isBrandVisible }) => {
  const [period, setPeriod] = useState<number>(1)
  const [selected, setSelected] = useState<PricingLog>()
  const [selectedOption, setSelectedOption] = useState<SelectOption>(
    PRICING_LOGS_OPTIONS[0]
  )

  const minCreated = useMemo(() => {
    const now = new Date().getTime()
    return Math.floor((now - period * 24 * 3600 * 1000) / 1000)
  }, [period])

  const { data: pricingLogsAll, isFetching: isPricingLogsLoading } =
    useFetchRateflowLogsQuery({
      min_created: minCreated,
      ...id
    })

  const pricingLogs = useMemo(
    () =>
      id?.bank_id
        ? pricingLogsAll?.filter(pl => pl.brand_id === id.bank_id)
        : pricingLogsAll,
    [id?.bank_id, pricingLogsAll]
  )

  const sortedList = useMemo(
    () =>
      pricingLogs?.length
        ? [...pricingLogs].sort(
            (a, b) => parseInt(b.timestamp) - parseInt(a.timestamp)
          )
        : [],
    [pricingLogs]
  )

  const columns: BBColumnDef<PricingLog>[] = useMemo(
    () => [
      {
        id: "timestamp",
        header: "Timestamp",
        accessorFn: row => row.timestamp,
        footer: props => props.column.id,
        cell: props => (
          <TimestampCell
            value={props.getValue() as string}
            className={`py-4 hidden md:table-cell ${getClassName(props)}`}
          />
        ),
        meta: { headerClassName: "hidden md:table-cell" }
      },
      {
        id: "ob_profile_name",
        header: "Pricing Profile",
        accessorFn: row => row.ob_profile_name,
        footer: props => props.column.id,
        cell: props => (
          <div className={`flex flex-col ${getClassName(props)}`}>
            <TimestampCell
              value={props.row.original.timestamp}
              className="pt-4 pb-0.5 table-cell md:hidden"
            />
            <StandardCell
              className={`pt-0.5 pb-4 md:py-4 ${getClassName(props)}`}
              value={(props.getValue() as string) || "CUSTOM QUOTE"}
            />
          </div>
        )
      },
      {
        id: "pricing-log-id",
        header: "Quote ID",
        meta: { headerClassName: "hidden" },
        accessorFn: row => row.id,
        cell: () => <></>
      }
    ],
    []
  )

  const loanOfficerColumn: BBColumnDef<PricingLog> = useMemo(
    () => ({
      id: "lo_name",
      header: "Loan Officer",
      accessorFn: row => row.lo_name,
      footer: props => props.column.id,
      cell: props => (
        <StandardCell
          value={props.getValue() as string}
          className={`py-4 hidden md:table-cell ${getClassName(props)}`}
        />
      ),
      meta: {
        headerClassName: "hidden md:table-cell"
      }
    }),
    []
  )

  const brandColumn: BBColumnDef<PricingLog> = useMemo(
    () => ({
      id: "brand_name",
      header: "Brand",
      accessorFn: row => row.brand_name,
      footer: props => props.column.id,
      cell: props => (
        <StandardCell
          value={props.getValue() as string}
          className={`py-4 hidden md:table-cell ${getClassName(props)}`}
        />
      ),
      meta: { headerClassName: "hidden md:table-cell" }
    }),
    []
  )

  const editColumn: BBColumnDef<PricingLog> = useMemo(
    () => ({
      id: "edit",
      header: "",
      footer: props => props.column.id,
      cell: props => (
        <td className={`text-center ${getClassName(props)}`}>
          <ChevronRight
            onClick={() => setSelected(props.row.original)}
            className="cursor-pointer ml-auto"
          />
        </td>
      )
    }),
    []
  )

  const tableColumns = useMemo(() => {
    if (!id?.loid) {
      return [...columns, loanOfficerColumn, editColumn]
    }
    if (isBrandVisible) {
      return [...columns, brandColumn, editColumn]
    }

    return [...columns, editColumn]
  }, [
    brandColumn,
    columns,
    editColumn,
    id?.loid,
    isBrandVisible,
    loanOfficerColumn
  ])

  const periodOptions = [
    {
      key: 30,
      label: "30 Days",
      shortLabel: "30 D",
      onClick: () => setPeriod(30)
    },
    {
      key: 7,
      label: "7 Days",
      shortLabel: "7 D",
      onClick: () => setPeriod(7)
    },
    {
      key: 1,
      label: "1 Day",
      shortLabel: "1 D",
      onClick: () => setPeriod(1)
    }
  ]

  const closeCardDetailsPopOver = () => {
    setSelected(undefined)
  }

  const handleChange = (selectedId: string) => {
    setSelectedOption(
      PRICING_LOGS_OPTIONS.find(
        option => option.id === selectedId
      ) as SelectOption
    )
  }

  const filteredDataList = useMemo(() => {
    switch (selectedOption.id) {
      case PRICING_LOGS_OPTION_IDS.SUCCESSFUL_REQUESTS:
        return sortedList.filter(item => isNotZeroString(item.base_rate))
      case PRICING_LOGS_OPTION_IDS.FAILED_REQUESTS:
        return sortedList.filter(item => !isNotZeroString(item.base_rate))
      default:
        return sortedList
    }
  }, [selectedOption, sortedList])

  return (
    <div className="px-6 py-6">
      <Table
        columns={tableColumns}
        data={filteredDataList}
        viewType="table"
        searchPlaceholder="Search logs"
        canChangeType={false}
        shrinkFilterSort
        noSorter
        extraHeaderElement={
          <Fragment>
            <Select
              label=""
              selected={selectedOption}
              data={PRICING_LOGS_OPTIONS}
              IDkey="id"
              selectClassName="w-auto md:w-48 mb-2 md:mb-0"
              setSelected={handleChange}
            />
            <ButtonGroup
              className="ml-auto self-center"
              active={period}
              buttons={periodOptions}
            />
          </Fragment>
        }
        extraHeaderElementClassName="w-full flex flex-col md:flex-row ml-auto md:ml-1"
        searchClassName="pl-10 py-3 lg:min-w-[20rem] text-sm"
        pageSize={25}
        isLoading={isPricingLogsLoading}
      />
      <PricingLogCardDetails
        open={!!selected}
        onClose={closeCardDetailsPopOver}
        data={selected}
      />
    </div>
  )
}

export default PricingLogs
