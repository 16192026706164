import { UserCircleIcon } from "lucide-react"

import { LoanOfficer } from "../../../../../types"
import { Badge } from "../../../../ui"
import ImageWrapper from "../../../img-wrapper"
import RoundedImg from "../../../rounded-img"

type Props = {
  info: LoanOfficer
  masqueradeEnabled: boolean
  onMasquerade: (loid: string) => void
  onUserCellClick?: (loID: string) => void
}

const UserCell = ({
  info,
  masqueradeEnabled,
  onMasquerade,
  onUserCellClick
}: Props) => {
  const { uid, brand_admin, picture, email, position } = info

  return (
    <td
      className="whitespace-normal lg:whitespace-nowrap py-4 pl-2 pr-3 cursor-pointer max-w-xs"
      onClick={() => onUserCellClick && onUserCellClick(uid)}
      colSpan={3}
    >
      <div className="flex group">
        <ImageWrapper
          isInitials
          initials={`${info.first_name[0] ?? ""} ${info.last_name[0] ?? ""}`}
        >
          <RoundedImg
            size={12}
            className="min-w-[3rem] hidden md:table-cell"
            src={picture}
            alt={`${info.name} thumbnail`}
          />
        </ImageWrapper>
        <div className="pl-0 md:pl-4">
          <div className="flex items-center">
            <strong className="text-base mr-2">
              {info.name.trim().substring(0, 30) || "Unnamed"}
            </strong>
            {brand_admin ? <Badge.Success>Admin</Badge.Success> : ""}
            {masqueradeEnabled && (
              <UserCircleIcon
                size={20}
                className="mt-px text-gray-400 hidden md:group-hover:inline"
                onClick={e => {
                  e.stopPropagation()
                  e.preventDefault()
                  onMasquerade(uid)
                }}
              />
            )}
          </div>
          <span className="text-sm text-neutral-400 whitespace-normal">
            {position}
          </span>
          <div className="text-sm text-gray-500 md:hidden">{email}</div>
        </div>
      </div>
    </td>
  )
}

export default UserCell
