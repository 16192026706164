import { useState, useEffect, useMemo } from "react"

import { TopLeadBreakdownData } from "../../types"
import { TopLeadBreakdownTabs } from "../ui/leads/charts/top-lead-breakdown"

export interface TransformTopLeadBreakdown extends TopLeadBreakdownData {
  netPercentage?: string
  isPositive?: boolean
}

export const useTransformTopLeadBreakdown = (
  tab: string,
  breakdownData: TopLeadBreakdownData[] | undefined,
  previousBreakdownData: TopLeadBreakdownData[] | undefined
): TransformTopLeadBreakdown[] => {
  const [currentData, setCurrentData] = useState<TransformTopLeadBreakdown[]>(
    []
  )
  const containsData = useMemo(
    () => breakdownData && breakdownData.length > 0,
    [breakdownData]
  )
  const containsPreviousData = useMemo(
    () => previousBreakdownData && previousBreakdownData.length > 0,
    [previousBreakdownData]
  )

  useEffect(() => {
    let transformedData: TransformTopLeadBreakdown[] = []
    if (containsData && containsPreviousData) {
      transformedData = breakdownData!.map(current => {
        const previous =
          tab === TopLeadBreakdownTabs.URL
            ? previousBreakdownData!.find(prev => prev.url === current.url)
            : previousBreakdownData!.find(prev => prev.name === current.name)
        const netCount = previous
          ? current.lead_count - previous.lead_count
          : undefined
        const netPercentage =
          netCount && previous
            ? `${parseFloat(
                ((netCount / previous.lead_count) * 100).toFixed(1)
              )}%`
            : "+100%"

        return {
          ...current,
          netPercentage,
          isPositive: !netCount || netCount >= 0
        }
      })
    } else if (containsData) {
      transformedData = breakdownData!
    }
    setCurrentData(transformedData)
  }, [
    breakdownData,
    containsData,
    containsPreviousData,
    previousBreakdownData,
    tab
  ])

  return currentData
}
