import React, { useRef, DragEvent, ReactNode, useState } from "react"
import { Upload } from "lucide-react"
import If from "../if"

interface Props {
  children: ReactNode
  noDropCover?: boolean
  onDrop: (e: DragEvent<HTMLDivElement>) => void
}

const DropZone: React.FC<Props> = ({
  noDropCover = false,
  children,
  onDrop
}) => {
  const dropZoneRef = useRef<HTMLDivElement | null>(null)

  const [backVisible, setBackVisible] = useState<boolean>(false)

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setBackVisible(true)
  }

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setBackVisible(false)
    onDrop(e)
  }

  return (
    <div
      className="relative"
      ref={dropZoneRef}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragLeave={() => setBackVisible(false)}
    >
      <If
        condition={!noDropCover && backVisible}
        then={
          <div className="absolute inset-0 flex items-center justify-center z-10 text-teal-600 bg-teal-100 rounded-xl bg-opacity-20">
            <Upload />
            <span className="text-xl font-bold ml-4">Drop File to Import</span>
          </div>
        }
      />
      <div className={backVisible ? "opacity-50" : ""}>{children}</div>
    </div>
  )
}

export default DropZone
