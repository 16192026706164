import { useNavigate } from "react-router-dom"
import { DropdownItemProps } from "../../../types"

interface Props {
  item: DropdownItemProps
  closeMenu?: () => void // in mobile, menu needs to be closed when menu item in it gets clicked.
}

const DropdownItem = ({ item, closeMenu }: Props) => {
  const navigate = useNavigate()

  const handleClick = (item: DropdownItemProps) => {
    closeMenu?.()
    if (item.onClick) return item.onClick(item)
    if (item.href) return navigate(item.href)
  }

  if (item.renderItem) {
    return item.renderItem(item)
  }

  if (item.type === "divider") {
    return <div className="w-full h-[1px] bg-neutral-200 my-3"></div>
  }

  return (
    <div
      className="flex items-center sm:px-4 py-3 sm:py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
      onClick={() => handleClick(item)}
    >
      {item.icon && <item.icon className="mr-2" size={20} />}
      <div className="whitespace-nowrap">{item.name}</div>
      {item.extraElement && (
        <div className="z-10 ml-2">{item.extraElement}</div>
      )}
    </div>
  )
}

export default DropdownItem
