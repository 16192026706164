import { useEffect, useState } from "react"
import { useAppSelector } from "../../hooks"
import { UserDetails } from "../../../types"
import { selectUserDetails } from "../../../redux/slices/auth"
import If from "../if"
import InputURL from "./input-url"
import ListItem from "./list-item"
import BBModal from "../modal"
import {
  useAddApprovedUrlMutation,
  useFetchApprovedUrlsQuery
} from "../../../redux/queries"

type Props = {
  show: boolean
  toggleModal: (show: boolean) => void
}

const ApprovedUrlsModal = ({ show, toggleModal }: Props) => {
  const [newURL, setNewURL] = useState("")

  const user: UserDetails | any = useAppSelector(selectUserDetails)

  const [addApprovedUrl, { isLoading }] = useAddApprovedUrlMutation()

  const { data: approvedURLs, isFetching } = useFetchApprovedUrlsQuery(
    user?.org?.id ?? "",
    {
      skip: !user?.org?.id || !show
    }
  )

  useEffect(() => {
    if (!isLoading) {
      setNewURL("")
    }
  }, [isLoading])

  const onClose = () => {
    setNewURL("")
    toggleModal(false)
  }

  const onSubmit = () => {
    addApprovedUrl({ org_id: user?.org?.id, domain_pattern: newURL })
  }

  return (
    <BBModal show={show} onClose={onClose} title="Approved URL's">
      <div>
        <label htmlFor="new-url" className="sr-only">
          Add new URL
        </label>
        <div className="mt-1">
          <InputURL
            resetInputOnSubmit
            id="new-url"
            url={newURL}
            onChange={setNewURL}
            onSubmit={onSubmit}
            isLoading={isLoading}
          />
        </div>
      </div>
      <div
        className={`border-t border-zinc-200 mt-3 ${isFetching ? "pt-3" : ""}`}
      >
        <If
          isLoading={isFetching}
          condition={!!approvedURLs && approvedURLs?.length > 0}
          then={
            <ul className="max-h-96 overflow-auto">
              {approvedURLs?.map((url, index) => (
                <ListItem key={index} url={url} />
              ))}
            </ul>
          }
          else={
            <p className="text-neutral-400 italic text-sm">
              There are no approved URLs.
            </p>
          }
        />
      </div>
    </BBModal>
  )
}
export default ApprovedUrlsModal
