import { DotIcon } from "lucide-react"

export enum EngagementsTab {
  engagements = "engagements",
  leads = "leads",
  activity = "activity"
}

export type DateRange = 1000 | 30 | 7 | 1

export interface TabRangeState {
  [key: string]: DateRange
}

export interface StatusOption {
  id: string
  name: string
  icon: JSX.Element
}

export const STATUS_OPTIONS: StatusOption[] = [
  {
    id: "active",
    name: "Active",
    icon: <DotIcon size={8} color="#22c55e" strokeWidth={16} />
  },
  {
    id: "disabled",
    name: "Disabled",
    icon: <DotIcon size={8} color="#eab308" strokeWidth={16} />
  },
  {
    id: "deleted",
    name: "Deleted",
    icon: <DotIcon size={8} color="#dc2626" strokeWidth={16} />
  }
]
