import { FC } from "react"
import { Activity } from "../../../../types"
import ActivityTable from "../../activity/table"

interface Props {
  data: Activity[]
  isFetching: boolean
  refetch: () => void
}

const OrgActivities: FC<Props> = ({ data, isFetching, refetch }) => {
  return (
    <div className="px-6 bg-white">
      <ActivityTable
        data={data}
        isLoading={isFetching}
        searchBarVisible={false}
        refetch={refetch}
      />
    </div>
  )
}

export default OrgActivities
