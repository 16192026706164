import { FC, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { ExternalLink } from "lucide-react"
import { ALLOWED_ROLES_BY_ROUTE } from "../../common/constants"
import LeadDetailsHeader from "../../common/ui/leads/details/header"
import LeadActivityPage from "../../common/ui/leads/details/sections/activity"
import LeadQuotesPage from "../../common/ui/leads/details/sections/quotes"
import Tabs from "../../common/ui/tabs"
import { RequireRole } from "../../common/validation"
import {
  useFetchLeadDetailsQuery,
  useFetchLeadLogByIdQuery,
  useFetchSubscriptionsByLeadIdQuery,
  useUpdateSubscriptionMutation
} from "../../redux/queries"
import { SubscriptionDetails } from "../../types"
import { convertSubscriptionDetailsToPayload } from "../../common/utils"
import PushToEngagements from "../../common/ui/leads/push-to-engagements"
import LeadSummary from "../../common/ui/leads/details/sections/summary"
import { LinkBtn } from "../../common/ui"

const LeadDetailsPage: FC<{}> = () => {
  const { id } = useParams()

  const { data } = useFetchLeadDetailsQuery(id ?? "", { skip: !id })
  const { data: leadLog } = useFetchLeadLogByIdQuery(id ?? "", { skip: !id })

  const { data: subscriptions, refetch } = useFetchSubscriptionsByLeadIdQuery(
    {
      lead_id: data?.id ?? ""
    },
    {
      skip: !data?.id
    }
  )

  const [updateSubscription] = useUpdateSubscriptionMutation()

  const [tab, setTab] = useState<string>("summary") // "summary", "quotes" or "activity"

  const [createEngagementVisible, setCreateEngagementVisible] =
    useState<boolean>(false)

  const handleCreateEngagement = () => {
    setCreateEngagementVisible(true)
  }

  const handleViewInEngagement = () => {
    if (subscriptions?.length) {
      window.open(`/engagements/${subscriptions[0]?.id}`, "_blank")
    }
  }

  const cancelCreateEngagement = () => setCreateEngagementVisible(false)

  const handleEnrollToRateAlerts = (subscription?: SubscriptionDetails) => {
    if (!subscription) return

    updateSubscription({
      ...convertSubscriptionDetailsToPayload(subscription),
      id: subscription.id,
      active: subscription.active === "1" ? "0" : "1"
    })
  }

  useEffect(() => {
    if (refetch) {
      refetch()
    }
  }, [refetch])

  if (!data) return null

  return (
    <RequireRole allowedRoles={ALLOWED_ROLES_BY_ROUTE["engagements"]}>
      <>
        <div className="h-full flex flex-col">
          <div className="pt-6 bg-white">
            <div className="px-6">
              <LeadDetailsHeader
                data={data}
                bankName={leadLog?.brand_name}
                subscription={subscriptions?.[0]}
                onCreateEngagement={handleCreateEngagement}
                onEnrollToRateAlerts={handleEnrollToRateAlerts}
              />
            </div>
            <Tabs
              className="px-6"
              currentTab={tab}
              tabs={[
                { id: "summary", label: "Summary" },
                { id: "quotes", label: "Quotes" },
                { id: "activity", label: "Embed Activity" }
              ]}
              onChange={setTab}
              extraElement={
                subscriptions?.length ? (
                  <LinkBtn
                    className="-my-1 -mx-4"
                    onClick={handleViewInEngagement}
                  >
                    <span className="mr-2">View in engagements</span>
                    <ExternalLink size={16} />
                  </LinkBtn>
                ) : null
              }
            />
          </div>
          <div className="p-6 flex-1 bg-white">
            {tab === "summary" && <LeadSummary data={data} />}
            {tab === "quotes" && <LeadQuotesPage data={data} />}
            {tab === "activity" && <LeadActivityPage data={data} />}
          </div>
        </div>

        <PushToEngagements
          brandId={leadLog?.group_id}
          leadId={createEngagementVisible ? data.id : ""}
          onCancel={cancelCreateEngagement}
        />
      </>
    </RequireRole>
  )
}

export default LeadDetailsPage
