import { memo } from "react"

import Skeleton from "../base"

const DashboardCard = () => {
  return (
    <div className="bg-white h-40 w-1/3 shadow-default rounded-md px-6 py-8">
      <Skeleton className="h-full">
        <div className="flex flex-col justify-between items-between h-full">
          <div className="flex flex-col w-full">
            <div className="w-1/2 h-4 bg-gray-200 rounded-full  mb-1.5"></div>
            <div className=" w-5/6 h-4 bg-gray-100 rounded-full"></div>
          </div>
          <div className="flex w-full">
            <div className="h-4 bg-gray-100 rounded-full w-1/4"></div>
          </div>
        </div>
      </Skeleton>
    </div>
  )
}

export default memo(DashboardCard)
