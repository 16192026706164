import { FC, useState } from "react"
import { useFindRateflowPricesByLoQuery } from "../../../../redux/queries"
import RatesTable from "../../rates/table"
import { ToggleView } from "../../table/toolbar"
import RateDetails from "../../rates/details"
import { ViewType } from "../../../../types"

interface Props {
  id: string
}

const LoanOfficerRates: FC<Props> = ({ id }) => {
  const {
    data: rates,
    isLoading: isLoadingRates,
    isFetching: isFetchingRates,
    fulfilledTimeStamp,
    refetch
  } = useFindRateflowPricesByLoQuery({
    loid: id,
    max_results: 999
  })

  const [viewType, setViewType] = useState<ViewType>("table")
  const [selected, setSelected] = useState<string>()
  const selectItem = (id: string) => setSelected(id)
  const clearSelectedItem = () => setSelected(undefined)

  return (
    <div className="p-4 bg-slate-100">
      <ToggleView
        className={`${viewType === "table" ? "mb-4" : ""}`}
        viewType={viewType}
        setViewType={setViewType}
      />
      <RatesTable
        isLoading={isLoadingRates || isFetchingRates}
        data={rates ?? []}
        refreshRates={refetch}
        lastFetched={fulfilledTimeStamp}
        viewType={viewType}
        isRefreshRates={false}
        onClickItem={selectItem}
      />
      <RateDetails id={selected} onClose={clearSelectedItem} />
    </div>
  )
}

export default LoanOfficerRates
