import { PricingRate } from "../../../../../types"
import { formatCurrency } from "../../../../utils"

type Props = {
  value: PricingRate
}

const AprCell = ({ value }: Props) => {
  return (
    <td className="whitespace-nowrap py-4 pl-2 pr-3 cursor-pointer">
      <div>
        <div className="text-sm text-neutral-400 font-medium">
          APR {value.apr}%
        </div>
        <div className="text-sm">
          Loan Amount:{" "}
          {value.loan_ammount ? formatCurrency(value.loan_ammount) : "N/A"}
        </div>
      </div>
    </td>
  )
}

export default AprCell
