import { FC } from "react"
import { Lead } from "../../../../types"
import {
  DEFAULT_DATETIME_LONG_FORMAT,
  formatDateTimeFromISO
} from "../../../utils"

interface Props {
  data: Lead
  onClick: (id: string) => void
}

const LeadsCell: FC<Props> = ({ data, onClick }) => {
  const { id, email, first_name, last_name, date_time, owner } = data

  return (
    <td className="px-2 py-4">
      <div className="block md:hidden text-sm text-gray-400 pb-4">
        {formatDateTimeFromISO(date_time, DEFAULT_DATETIME_LONG_FORMAT)}
      </div>
      <div className="flex items-center">
        <div className="w-6 h-6 md:w-12 md:h-12 flex shrink-0 self-start md:self-center items-center justify-center bg-gray-600 text-xs md:text-base text-white rounded-full uppercase">
          {first_name.charAt(0)}
          {last_name.charAt(0)}
        </div>
        <div className="px-4">
          <div className="font-bold">
            <span
              className="hover:border-b border-neutral-500 break-words cursor-pointer"
              onClick={() => onClick(id)}
            >
              {first_name} {last_name}
            </span>
          </div>
          <div className="text-sm md:text-base text-gray-400">{email}</div>
          <div className="block md:hidden text-sm pt-1">by {owner}</div>
        </div>
      </div>
    </td>
  )
}

export default LeadsCell
