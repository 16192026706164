import { useMemo, useState } from "react"
import { DateTime } from "luxon"
import { ALLOWED_ROLES_BY_ROUTE } from "../../common/constants"
import { If } from "../../common/ui"
import ActivityDetailsFull from "../../common/ui/activity-details-full"
import SidePanel from "../../common/ui/side-panel"
import DashboardSkeleton from "../../common/ui/skeletons/dashboard/index"
import { getOrgBrandsIDs } from "../../common/utils"
import { RequireRole } from "../../common/validation"
import {
  useFetchIntegrationsQuery,
  useFetchOrgActivitiesQuery,
  useFetchOrgDetailsQuery,
  useFetchLoanOfficersQuery
} from "../../redux/queries"
import Charts from "../../common/ui/dashboard/charts"
import RecentLOs from "../../common/ui/dashboard/recent-los-table"
import Stats from "../../common/ui/dashboard/stats"
import { useAppSelector } from "../../common/hooks"
import { selectUserDetails } from "../../redux/slices/auth"
import DashboardActivityTable from "../../common/ui/dashboard/activity-table"

const DashboardPage = () => {
  const user = useAppSelector(selectUserDetails)
  const { data: orgDetails, isFetching: isFetchingOrgDetails } =
    useFetchOrgDetailsQuery(
      { id: user?.org?.id ?? "" },
      { skip: !user?.org?.id }
    )

  const { data: membersData, isFetching: isFetchingMembers } =
    useFetchLoanOfficersQuery(
      { org_id: orgDetails?.id ?? "" },
      {
        skip: !orgDetails?.id
      }
    )

  const {
    data: orgActivities,
    isFetching: isFetchingActivities,
    refetch: refetchOrgActivities
  } = useFetchOrgActivitiesQuery(
    {
      group_id: getOrgBrandsIDs(orgDetails),
      range_start: parseInt(
        DateTime.local()
          .minus({ days: 90 })
          .startOf("day")
          .toSeconds()
          .toString()
      ),
      range_end: parseInt(DateTime.local().endOf("day").toSeconds().toString()),
      limit: 9999
    },
    { skip: !orgDetails?.id }
  )

  const brandsIDs = getOrgBrandsIDs(orgDetails)
  const { data: integrations, isFetching: isFetchingIntegrations } =
    useFetchIntegrationsQuery({
      org_id: orgDetails?.id,
      brand_id: brandsIDs,
      limit: 9999
    })

  const [selectedActivity, setSelectedActivity] = useState<string | null>(null)

  const activityDetails = useMemo(
    () => orgActivities?.find(item => item.event_id === selectedActivity),
    [orgActivities, selectedActivity]
  )

  const clearSelectedActivity = () => setSelectedActivity(null)

  return (
    <RequireRole allowedRoles={ALLOWED_ROLES_BY_ROUTE.dashboard}>
      <div>
        <If
          condition={
            isFetchingOrgDetails ||
            isFetchingMembers ||
            isFetchingIntegrations ||
            isFetchingActivities
          }
          then={<DashboardSkeleton />}
          else={
            <div className="h-full">
              <div className="py-4 px-6 bg-white flex items-center justify-between">
                <div className="flex items-center">
                  <h2 className="font-roboto-slab">Dashboard</h2>
                  <div className="px-2 text-base text-gray-400 hidden md:block">
                    &#8226;&nbsp;{orgDetails?.name}
                  </div>
                </div>
              </div>

              {/* Overall Stats */}
              <div className="p-6">
                <Stats
                  brands={orgDetails?.brands?.length ?? 0}
                  los={membersData?.los?.length ?? 0}
                  integrations={integrations?.length ?? 0}
                />
              </div>

              {/* LO & Activity Lists */}
              <div className="p-6 pt-0 flex flex-col lg:flex-row gap-6">
                <RecentLOs loList={membersData?.los ?? []} />
                <DashboardActivityTable
                  data={orgActivities ?? []}
                  onClick={setSelectedActivity}
                  refetch={refetchOrgActivities}
                />
              </div>

              {/* Charts */}
              <div className="p-6 pt-0 flex flex-col lg:flex-row gap-6">
                <Charts orgId={orgDetails?.id} />
              </div>

              {/* Side panel with Activity details */}
              <SidePanel
                title="Activity details"
                open={!!selectedActivity}
                onClose={clearSelectedActivity}
              >
                <ActivityDetailsFull data={activityDetails} />
              </SidePanel>
            </div>
          }
        />
      </div>
    </RequireRole>
  )
}

export default DashboardPage
