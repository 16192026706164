import { useEffect } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import bbApi from "./api"
import { FullLayout, SingleForm } from "./common/layout"
import { ToastWrapper } from "./common/ui"
import { RequireAuth } from "./common/validation"
import {
  HomePage,
  Brands,
  Integrations,
  LoanOfficers,
  LODetails,
  PricingLogs,
  NotFound,
  Login,
  Password,
  BrandDetails,
  Rates,
  Account,
  Engagements,
  EngagementDetails,
  RecentActivity,
  OrganizationDetails,
  ConfigAdmin,
  Stats,
  LeadDetailsPage,
  PasswordReset
} from "./pages"

function App() {
  useEffect(() => {
    ;(window as any).BB = {
      bbApi
    }
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <FullLayout />
            </RequireAuth>
          }
        >
          <Route index element={<HomePage />} />
          <Route path="stats" element={<Stats />} />
          {/* "usage" is deprecated, we keep it temporally */}
          <Route path="usage" element={<Stats />} />
          <Route path="brands" element={<Brands />} />
          <Route path="brands/:brandId" element={<BrandDetails />} />
          <Route path="integrations" element={<Integrations />} />
          <Route path="integrations/:id" element={<Integrations />} />
          <Route path="loan-officers" element={<LoanOfficers />} />
          <Route path="loan-officers/:id" element={<LODetails />} />
          <Route path="pricing-logs" element={<PricingLogs />} />
          <Route path="rates" element={<Rates />} />
          <Route path="rates/:id" element={<Rates />} />
          <Route path="engagements" element={<Engagements />} />
          <Route path="engagements/:id" element={<EngagementDetails />} />
          <Route path="leads/:id" element={<LeadDetailsPage />} />
          <Route path="organizations/:orgId">
            <Route index element={<OrganizationDetails />} />
            <Route path="brands/:brandId" element={<BrandDetails />} />
          </Route>
          <Route path="account">
            <Route index element={<Account />} />
            <Route path="recent-activity" element={<RecentActivity />} />
          </Route>
          <Route path="config-admin" element={<ConfigAdmin />} />
          <Route path="*" element={<NotFound />} />
        </Route>

        <Route path="user" element={<SingleForm />}>
          <Route path="login" element={<Login />} />
          <Route path="password" element={<Password />} />
          <Route path="password-reset/:token" element={<PasswordReset />} />
        </Route>
      </Routes>
      <ToastWrapper />
    </BrowserRouter>
  )
}

export default App
