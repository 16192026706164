import { createApi } from "@reduxjs/toolkit/dist/query/react"
import bbApi from "../../api"
import { getQueryString } from "../../common/utils"
import { BRAND_LIST_ENDPOINT, RTK_SLICE_API_LIST } from "../../common/constants"
import {
  BRAND_MANAGE_ENDPOINT,
  BRAND_REVIEWS_ENDPOINT
} from "../../common/constants"
import { AllReviews, Brand, CreateBrandPayload } from "../../types"
import { baseQuery, getTags } from "../api"

const reducerPath = "brands"
const allBrandsPath = "brands-all"

export const brandsApi = createApi({
  reducerPath,
  baseQuery,
  tagTypes: [reducerPath, allBrandsPath],
  endpoints: builder => ({
    fetchAllBrands: builder.query({
      query: (params: any) => `${BRAND_LIST_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: { data: Brand[] }) =>
        (responseData.data || []).map(
          brand =>
            ({
              ...brand,
              id: brand.id || brand.nid || ""
            } as Brand)
        ),
      providesTags: result => getTags(allBrandsPath, "id", result)
    }),
    fetchBrands: builder.query({
      query: (params: any) => `${BRAND_LIST_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: { data: Brand[] }) =>
        responseData.data || [],
      providesTags: result => getTags(reducerPath, "id", result)
    }),
    fetchBrand: builder.query({
      query: (id: string) => `${BRAND_MANAGE_ENDPOINT}?id=${id}`,
      transformResponse: (responseData: Brand) => responseData || [],
      providesTags: (result, error, arg) => [{ type: reducerPath, id: arg }]
    }),
    fetchBrandReviews: builder.query({
      query: (params: {
        id?: string
        place_id?: string
        zillow_brand_name?: string
        experience_id?: string
        yelp_id?: string
        nmls?: string
      }) => `${BRAND_REVIEWS_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: AllReviews) => responseData || []
    }),
    addBrand: builder.mutation({
      queryFn: async (payload: CreateBrandPayload) => {
        const data = await bbApi.brand.add(payload)

        return { data }
      },
      invalidatesTags: [
        { type: reducerPath, id: RTK_SLICE_API_LIST },
        { type: allBrandsPath, id: RTK_SLICE_API_LIST }
      ]
    }),
    updateBrand: builder.mutation({
      queryFn: async (payload: Brand) => {
        const data = await bbApi.brand.update(payload)

        return { data }
      },
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: arg.id },
        { type: allBrandsPath, id: arg.id }
      ]
    }),
    deleteBrand: builder.mutation({
      queryFn: async (id: string) => {
        const data = await bbApi.brand.delete(id)

        return { data }
      },
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: arg },
        { type: allBrandsPath, id: arg },
        { type: reducerPath, id: RTK_SLICE_API_LIST },
        { type: allBrandsPath, id: RTK_SLICE_API_LIST }
      ]
    })
  })
})

export const {
  useFetchAllBrandsQuery,
  useFetchBrandsQuery,
  useFetchBrandQuery,
  useFetchBrandReviewsQuery,
  useAddBrandMutation,
  useUpdateBrandMutation,
  useDeleteBrandMutation
} = brandsApi
