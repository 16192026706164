import { useMemo, useState } from "react"
import { Outlet } from "react-router-dom"
import Navbar from "../navbar"
import Header from "../header"
import { useAppSelector } from "../../hooks"
import { selectUser } from "../../../redux/slices/auth"
import { ALLOWED_ROLES_BY_ROUTE, NavbarItems } from "../../constants"
import { getUserRole } from "../../utils"

const FullLayout = () => {
  const user = useAppSelector(selectUser)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false)

  const toggleSidebarCollapsed = () => setSidebarCollapsed(!sidebarCollapsed)

  const userRole = getUserRole(user?.user.roles ?? [])
  const navItems = useMemo(
    () =>
      userRole
        ? NavbarItems[userRole].filter(({ key, hidden }) => {
            const role = getUserRole(user?.user?.roles ?? [])
            return !hidden && role && ALLOWED_ROLES_BY_ROUTE[key].includes(role)
          })
        : [],
    [user?.user?.roles, userRole]
  )

  return (
    <div className="h-full">
      <Navbar
        items={navItems}
        sidebarOpen={sidebarOpen}
        sidebarCollapsed={sidebarCollapsed}
        setSidebarOpen={setSidebarOpen}
        toggleSidebarCollapsed={toggleSidebarCollapsed}
      />
      <div
        className={`${
          sidebarCollapsed ? "lg:pl-24" : "lg:pl-64"
        } h-full flex flex-col flex-1 transition-all bg-primary-100`}
      >
        <Header setSidebarOpen={setSidebarOpen} />
        <main className="bg-primary-100 flex-1 overflow-auto">
          <Outlet />
        </main>
      </div>
    </div>
  )
}

export default FullLayout
