import { FC, useCallback, useEffect, useMemo, useState } from "react"
import bbApi from "../../../api"
import { pushToast } from "../../../redux/slices/notifications"
import { AutocompleteOption, GooglePlace } from "../../../types"
import { useAppDispatch } from "../../hooks"
import Autocomplete from "../form/autocomplete"
import {
  convertAddressObjToPlaceString,
  convertGooglePlaceToAddressObj
} from "../../utils"

interface Props {
  name: string
  label: string
  placeholder: string
  initialValue?: string
  initialInputText?: string
  onChange: (value: GooglePlace) => void
}

const AddressAutocomplete: FC<Props> = ({
  name,
  label,
  placeholder,
  initialValue,
  initialInputText = "",
  onChange
}) => {
  const dispatch = useAppDispatch()

  const [placeQuery, setPlaceQuery] = useState<string>("")
  const [placeOptions, setPlaceOptions] = useState<AutocompleteOption[]>([])
  const [selectedPlace, setSelectedPlace] = useState<GooglePlace>()

  const autoCompleteOptions = useMemo(
    () =>
      placeOptions.map(({ place_id, description }) => ({
        id: place_id,
        text: description
      })),
    [placeOptions]
  )

  const placeAutoComplete = useCallback(
    async (input: string) => {
      try {
        if (!input) {
          return []
        }
        const data = await bbApi.brand.placeAutoComplete({ input })
        setPlaceOptions(data)
      } catch (error) {
        console.error(error)
      }
    },
    [setPlaceOptions]
  )

  const setCurrentPlace = useCallback(
    async (placeId: string) => {
      try {
        const place = await bbApi.brand.getPlace({
          place_id: placeId
        })

        if ("status" in place) {
          dispatch(
            pushToast({
              msg: "Google place API is unavailble, try again later",
              timeout: 3000
            })
          )
        } else {
          setSelectedPlace(place)
        }
      } catch (error) {
        console.error(error)
      }
    },
    [dispatch, setSelectedPlace]
  )

  useEffect(() => {
    placeAutoComplete(placeQuery)
  }, [placeAutoComplete, placeQuery])

  useEffect(() => {
    if (selectedPlace?.place_id) {
      onChange(selectedPlace)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlace?.place_id, onChange])

  useEffect(() => {
    if (initialValue) {
      setCurrentPlace(initialValue)
    }
  }, [initialValue, setCurrentPlace])

  useEffect(() => {
    return () => {
      setSelectedPlace(undefined)
      setPlaceQuery("")
      setPlaceOptions([])
    }
  }, [setPlaceOptions, setPlaceQuery, setSelectedPlace])

  const selectedOption = useMemo(
    () => ({
      id: selectedPlace?.place_id ?? "",
      text:
        convertAddressObjToPlaceString(
          convertGooglePlaceToAddressObj(selectedPlace)
        ) || initialInputText
    }),
    [selectedPlace, initialInputText]
  )

  return (
    <Autocomplete
      label={label}
      name={name}
      placeholder={placeholder}
      value={selectedOption}
      query={placeQuery}
      options={autoCompleteOptions}
      onChange={option => setCurrentPlace(option.id)}
      onChangeQuery={setPlaceQuery}
    />
  )
}

export default AddressAutocomplete
