import * as Yup from "yup"
import {
  PHONE_NUMBER_REGEX,
  REGEX_LOCAL_URL_WILDCARD,
  REGEX_HTPPS_URL
} from "../../../constants"

const STATUSES = ["active", "inactive"]

export const loFormSchema = Yup.object().shape({
  name: Yup.string(),
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  uid: Yup.string(),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  nmls: Yup.string().required(),
  brand_nmls: Yup.string(),
  app_link: Yup.string(),
  nid: Yup.string(),
  external: Yup.boolean(),
  logo: Yup.string(),
  disclaimer: Yup.string(),
  picture: Yup.string(),
  bank: Yup.string(),
  bank_nid: Yup.string().required("Brand is required"),
  position: Yup.string(),
  branch_nmls: Yup.string(),
  pricing_status: Yup.string().oneOf(STATUSES),
  crm_type: Yup.string(),
  crm_id: Yup.string(),
  active: Yup.boolean(),
  office_street_addr: Yup.string(),
  office_zipcode: Yup.string(),
  office_city: Yup.string(),
  office_state: Yup.string(),
  mobile_phone: Yup.string().matches(
    PHONE_NUMBER_REGEX,
    "Invalid Mobile Number"
  ),
  office_phone: Yup.string().matches(
    PHONE_NUMBER_REGEX,
    "Invalid Phone Number"
  ),
  phone: Yup.string().matches(PHONE_NUMBER_REGEX, "Invalid Phone Number"),
  brand_admin: Yup.boolean(),
  masq_link: Yup.string().matches(REGEX_LOCAL_URL_WILDCARD, "Invalid URL"),
  special_disclosure_link: Yup.string().matches(REGEX_HTPPS_URL, "Invalid URL"),
  originator_id: Yup.string(),
  biz_channel: Yup.string(),
  about_me: Yup.string(),
  about_bank: Yup.string(),
  title: Yup.string(),
  website_link: Yup.string().matches(REGEX_HTPPS_URL, "Invalid Website URL"),
  facebook_link: Yup.string().matches(REGEX_HTPPS_URL, "Invalid Facebook URL")
})
