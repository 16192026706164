import { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { SidePanelProps } from "../../../types"
import Actions from "./actions"
import Header from "./header"
import PanelWrapper from "./panel-wrapper"

const SidePanel = ({
  title,
  subTitle,
  isSubmitting = false,
  open,
  onClose,
  size = "4xl", // TODO: support dynamic size
  children,
  actions,
  hasTabs,
  isBack
}: SidePanelProps) => (
  <Transition.Root show={open} as={Fragment}>
    <Dialog as="div" className="relative z-50" onClose={onClose}>
      <PanelWrapper
        actions={
          actions?.length ? (
            <Actions isSubmitting={isSubmitting} actions={actions} />
          ) : null
        }
        open={open}
        header={
          <Header
            title={title}
            subTitle={subTitle}
            onClose={onClose}
            hasTabs={hasTabs}
            isBack={isBack}
          />
        }
      >
        {children}
      </PanelWrapper>
    </Dialog>
  </Transition.Root>
)

export default SidePanel
