import { createApi } from "@reduxjs/toolkit/dist/query/react"
import {
  DUPLICATE_LEAD_COUNT_ENDPOINT,
  LEADLOG_ENDPOINT,
  LEAD_COUNT_ENDPOINT,
  LEAD_COUNT_CUMULATIVE_ENDPOINT,
  LEAD_HISTORY,
  LEAD_MANAGE_ENDPOINT,
  LOAN_SUMMARY_PDF_ENDPOINT,
  TOP_LEAD_BREAKDOWN_URL_ENDPOINT,
  TOP_LEAD_BREAKDOWN_EMBEDS_ENDPOINT,
  TOP_LEAD_BREAKDOWN_LOS_ENDPOINT,
  LEAD_COUNT_BY_STATE_ENDPOINT,
  LEAD_COUNT_BY_CITY_ENDPOINT,
  LEAD_COUNT_BY_PROPERTY_ENDPOINT,
  LEAD_COUNT_BY_RESIDENCY_ENDPOINT,
  LEAD_COUNT_BY_LOAN_PURPOSE_ENDPOINT,
  LEAD_METRICS_ENDPOINT,
  LEAD_CONVERSION_FUNNEL_ENDPOINT,
  LEAD_CONVERSION_PROGRESS_ENDPOINT,
  SUBSCRIPTION_COUNT_ENDPOINT,
  SUBSCRIPTION_COUNT_CUMULATIVE_ENDPOINT,
  EXIT_POPUP_CONVERSION_FUNNEL_ENDPOINT,
  RATE_TABLE_USAGE_ENDPOINT,
  SUBSCRIPTION_EMAIL_STATS_ENDPOINT,
  UNSUBSCRIBED_STATS_ENDPOINT,
  QUOTE_RECEIPT_STATS_ENDPOINT,
  UTM_PARAMETERS_STATS_ENDPOINT
} from "../../common/constants"
import { getQueryString } from "../../common/utils"
import {
  Lead,
  LeadLog,
  LeadHistoryResponse,
  LoanSummaryPdf,
  LeadCountCumulative,
  LeadCount,
  DuplicateLeadCount,
  TopLeadBreakdownData,
  LeadCountByState,
  LeadCountByCity,
  LeadCountByProperty,
  LeadCountByResidency,
  LeadCountByLoanPurpose,
  LeadMetrics,
  LeadConversionFunnel,
  LeadConversionProgress,
  SubscriptionCount,
  SubscriptionCountCumulative,
  ExitPopUpConversionFunnel,
  RateTableUsage,
  SubscriptionEmailStatsType,
  SubscriptionEmailStats,
  UnsubscribedStats,
  QuoteReceiptStats,
  UTMParametersStats,
  UTMParametersStatsType
} from "../../types"
import { baseQuery } from "../api"

const reducerPath = "leads"
const leadCountCumulativePath = "lead-count-cumulative"

export const leadsApi = createApi({
  reducerPath,
  baseQuery,
  tagTypes: [reducerPath, leadCountCumulativePath],
  endpoints: builder => ({
    fetchLeadsByBrand: builder.query({
      query: (brandId: string) => `${LEAD_MANAGE_ENDPOINT}?brand_id=${brandId}`,
      transformResponse: (responseData: Lead[]) => responseData || []
    }),
    fetchLeadLogs: builder.query({
      query: (params: {
        limit: number
        offset: number
        min_created: number
        owner?: string | undefined
        org_id?: string | undefined
        isSuperAdmin?: boolean
      }) => `${LEADLOG_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: LeadLog[]) => responseData || []
    }),
    fetchLeadLogById: builder.query({
      query: (id: string) => `${LEADLOG_ENDPOINT}?id=${id}`,
      transformResponse: (responseData: LeadLog[]) => responseData?.[0]
    }),
    fetchLeadDetails: builder.query({
      query: (id: string) => `${LEAD_MANAGE_ENDPOINT}?id=${id}`,
      transformResponse: (responseData: Lead) => responseData || {}
    }),
    fetchLeadHistory: builder.query({
      query: (id: string) => `${LEAD_HISTORY}?lead_id=${id}`,
      transformResponse: (responseData: LeadHistoryResponse) =>
        responseData.payload || []
    }),
    fetchLoanSummaryPdf: builder.query({
      query: (payload: { subscription_id: string; quote_id: string }) =>
        `${LOAN_SUMMARY_PDF_ENDPOINT}${getQueryString(payload)}`,
      transformResponse: (responseData: LoanSummaryPdf) => responseData
    }),
    fetchLeadCount: builder.query({
      query: (params: {
        range: number
        org_id?: string | undefined
        loid?: string | undefined
      }) => `${LEAD_COUNT_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: { data: LeadCount[] }): LeadCount[] =>
        responseData?.data ?? []
    }),
    fetchLeadCumulativeCount: builder.query({
      query: (params: {
        range: number
        org_id?: string | undefined
        loid?: string | undefined
      }) => `${LEAD_COUNT_CUMULATIVE_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: {
        data: LeadCountCumulative[]
      }): LeadCountCumulative[] => responseData?.data ?? []
    }),
    fetchDuplicateLeadCount: builder.query({
      query: (params: {
        range: number
        org_id?: string | undefined
        loid?: string | undefined
      }) => `${DUPLICATE_LEAD_COUNT_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: {
        data: DuplicateLeadCount[]
      }): DuplicateLeadCount => responseData?.data[0]
    }),
    fetchTopLeadBreakdownTypeURL: builder.query({
      query: (params: {
        range: number
        org_id?: string | undefined
        loid?: string | undefined
      }) => `${TOP_LEAD_BREAKDOWN_URL_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: {
        data: TopLeadBreakdownData[]
      }): TopLeadBreakdownData[] => responseData?.data ?? []
    }),
    fetchTopLeadBreakdownTypeEmbeds: builder.query({
      query: (params: {
        range: number
        org_id?: string | undefined
        loid?: string | undefined
      }) => `${TOP_LEAD_BREAKDOWN_EMBEDS_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: {
        data: TopLeadBreakdownData[]
      }): TopLeadBreakdownData[] => responseData?.data ?? []
    }),
    fetchTopLeadBreakdownTypeLOs: builder.query({
      query: (params: {
        range: number
        org_id?: string | undefined
        loid?: string | undefined
      }) => `${TOP_LEAD_BREAKDOWN_LOS_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: {
        data: TopLeadBreakdownData[]
      }): TopLeadBreakdownData[] => responseData?.data ?? []
    }),
    fetchLeadCountByState: builder.query({
      query: (params: {
        range: number
        org_id?: string | undefined
        loid?: string | undefined
      }) => `${LEAD_COUNT_BY_STATE_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: {
        data: LeadCountByState[]
      }): LeadCountByState[] => responseData?.data ?? []
    }),
    fetchLeadCountByCity: builder.query({
      query: (params: {
        range: number
        org_id?: string | undefined
        loid?: string | undefined
      }) => `${LEAD_COUNT_BY_CITY_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: {
        data: LeadCountByCity[]
      }): LeadCountByCity[] => responseData?.data ?? []
    }),
    fetchLeadCountByProperty: builder.query({
      query: (params: {
        range: number
        org_id?: string | undefined
        loid?: string | undefined
      }) => `${LEAD_COUNT_BY_PROPERTY_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: {
        data: LeadCountByProperty[]
      }): LeadCountByProperty[] => responseData?.data ?? []
    }),
    fetchLeadCountByResidency: builder.query({
      query: (params: {
        range: number
        org_id?: string | undefined
        loid?: string | undefined
      }) => `${LEAD_COUNT_BY_RESIDENCY_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: {
        data: LeadCountByResidency[]
      }): LeadCountByResidency[] => responseData?.data ?? []
    }),
    fetchLeadCountByLoanPurpose: builder.query({
      query: (params: {
        range: number
        org_id?: string | undefined
        loid?: string | undefined
      }) => `${LEAD_COUNT_BY_LOAN_PURPOSE_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: {
        data: LeadCountByLoanPurpose[]
      }): LeadCountByLoanPurpose[] => responseData?.data ?? []
    }),
    fetchLeadMetrics: builder.query({
      query: (params: {
        range: number
        org_id?: string | undefined
        loid?: string | undefined
      }) => `${LEAD_METRICS_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: { data: LeadMetrics[] }): LeadMetrics =>
        responseData?.data?.[0] ?? {}
    }),
    fetchLeadConversionFunnel: builder.query({
      query: (params: {
        range: number
        org_id?: string | undefined
        loid?: string | undefined
      }) => `${LEAD_CONVERSION_FUNNEL_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: {
        data: LeadConversionFunnel[]
      }): LeadConversionFunnel => responseData?.data?.[0] ?? {}
    }),
    fetchLeadConversionProgress: builder.query({
      query: (params: {
        range: number
        org_id?: string | undefined
        loid?: string | undefined
      }) => `${LEAD_CONVERSION_PROGRESS_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: {
        data: LeadConversionProgress[]
      }): LeadConversionProgress => responseData?.data?.[0] ?? {}
    }),
    fetchSubscriptionCount: builder.query({
      query: (params: {
        range: number
        org_id?: string | undefined
        loid?: string | undefined
      }) => `${SUBSCRIPTION_COUNT_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: {
        data: SubscriptionCount[]
      }): SubscriptionCount[] => responseData?.data ?? []
    }),
    fetchSubscriptionCumulativeCount: builder.query({
      query: (params: {
        range: number
        org_id?: string | undefined
        loid?: string | undefined
      }) =>
        `${SUBSCRIPTION_COUNT_CUMULATIVE_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: {
        data: SubscriptionCountCumulative[]
      }): SubscriptionCountCumulative[] => responseData?.data ?? []
    }),
    fetchExitPopupConversionFunnel: builder.query({
      query: (params: {
        range: number
        org_id?: string | undefined
        loid?: string | undefined
      }) => `${EXIT_POPUP_CONVERSION_FUNNEL_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: {
        data: ExitPopUpConversionFunnel[]
      }): ExitPopUpConversionFunnel => responseData?.data?.[0] ?? {}
    }),
    fetchRateTableUsage: builder.query({
      query: (params: {
        range: number
        org_id?: string | undefined
        loid?: string | undefined
      }) => `${RATE_TABLE_USAGE_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: {
        data: RateTableUsage[]
      }): RateTableUsage[] => responseData?.data ?? []
    }),
    fetchSubscriptionEmailStats: builder.query({
      query: (params: {
        range: number
        email_type: SubscriptionEmailStatsType | undefined
        org_id?: string | undefined
        loid?: string | undefined
      }) => `${SUBSCRIPTION_EMAIL_STATS_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: {
        data: SubscriptionEmailStats[]
      }): SubscriptionEmailStats[] => responseData?.data ?? []
    }),
    fetchUnsubscribedStats: builder.query({
      query: (params: {
        range: number
        org_id?: string | undefined
        loid?: string | undefined
      }) => `${UNSUBSCRIBED_STATS_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: {
        data: UnsubscribedStats[]
      }): UnsubscribedStats[] => responseData?.data ?? []
    }),
    fetchQuoteReceiptStats: builder.query({
      query: (params: {
        range: number
        org_id?: string | undefined
        loid?: string | undefined
      }) => `${QUOTE_RECEIPT_STATS_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: {
        data: QuoteReceiptStats[]
      }): QuoteReceiptStats[] => responseData?.data ?? []
    }),
    fetchUTMParameters: builder.query({
      query: (params: {
        range: number
        utm_key: UTMParametersStatsType
        org_id?: string | undefined
        loid?: string | undefined
      }) => `${UTM_PARAMETERS_STATS_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: {
        data: UTMParametersStats[]
      }): UTMParametersStats[] => responseData?.data ?? []
    })
  })
})

export const {
  useFetchLeadsByBrandQuery,
  useFetchLeadLogsQuery,
  useFetchLeadDetailsQuery,
  useFetchLeadHistoryQuery,
  useFetchLoanSummaryPdfQuery,
  useFetchLeadLogByIdQuery,
  useFetchLeadCountQuery,
  useFetchLeadCumulativeCountQuery,
  useFetchDuplicateLeadCountQuery,
  useFetchTopLeadBreakdownTypeURLQuery,
  useFetchTopLeadBreakdownTypeEmbedsQuery,
  useFetchTopLeadBreakdownTypeLOsQuery,
  useFetchLeadCountByStateQuery,
  useFetchLeadCountByCityQuery,
  useFetchLeadCountByPropertyQuery,
  useFetchLeadCountByResidencyQuery,
  useFetchLeadCountByLoanPurposeQuery,
  useFetchLeadMetricsQuery,
  useFetchLeadConversionFunnelQuery,
  useFetchLeadConversionProgressQuery,
  useFetchSubscriptionCountQuery,
  useFetchSubscriptionCumulativeCountQuery,
  useFetchExitPopupConversionFunnelQuery,
  useFetchRateTableUsageQuery,
  useFetchSubscriptionEmailStatsQuery,
  useFetchUnsubscribedStatsQuery,
  useFetchQuoteReceiptStatsQuery,
  useFetchUTMParametersQuery
} = leadsApi
