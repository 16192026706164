import * as Yup from "yup"

export const engagementFormSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  credit_score: Yup.number().min(0).max(850)
})
