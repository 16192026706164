import { FC } from "react"
import htmlToDraft from "html-to-draftjs"
import { Brand } from "../../../../types"
import {
  convertBoolToEnabled,
  convertBoolToYesNo,
  shortenString
} from "../../../utils"
import SectionCell from "../../section/cell"

interface Props {
  brand: Brand
}

const BrandOverview: FC<Props> = ({ brand }) => (
  <div>
    <div className="flex">
      <SectionCell className="flex-1 -mt-6" label="NMLS" value={brand.nmls} />
    </div>
    <div className="flex">
      <SectionCell
        className="flex-1"
        label="Yelp account ID"
        value={brand.yelp_id}
      />

      <SectionCell
        className="flex-1"
        label="Experience account ID"
        value={brand.experience_id}
      />
    </div>

    <div className="flex">
      <SectionCell
        className="flex-1"
        label="Subscription emails"
        value={convertBoolToEnabled(!brand.disable_subscription_emails)}
      />

      <SectionCell
        className="flex-1"
        label="Receipt email"
        value={convertBoolToEnabled(!brand.disable_receipt_emails)}
      />
    </div>

    <div className="flex pb-6">
      <SectionCell
        className="flex-1"
        label="Itemized fees"
        value={convertBoolToEnabled(!brand.disable_itemized_fees)}
      />

      <SectionCell
        className="flex-1"
        label="Non bank"
        value={convertBoolToYesNo(brand.non_bank)}
      />
    </div>

    <div className="flex pb-6">
      <div className="flex-1">
        <div className="text-neutral-400">Primary color</div>
        <div className="flex items-center">
          {brand.primary_color && (
            <div
              className="w-6 h-6 mr-1"
              style={{ backgroundColor: brand.primary_color }}
            />
          )}
          <div className="text-neutral-800">{brand.primary_color || "-"}</div>
        </div>
      </div>
      <div className="flex-1">
        <div className="text-neutral-400">Secondary color</div>
        <div className="flex items-center">
          {brand.secondary_color && (
            <div
              className="w-6 h-6 mr-1"
              style={{ backgroundColor: brand.secondary_color }}
            />
          )}
          <div className="text-neutral-800">{brand.secondary_color || "-"}</div>
        </div>
      </div>
    </div>

    <div className="text-neutral-400">About brand</div>
    <div className="text-neutral-800">
      {brand.about
        ? shortenString(
            htmlToDraft(brand.about)
              .contentBlocks.map(b => b.getText())
              .join(" ")
          )
        : "-"}
    </div>

    <SectionCell
      label="General disclaimer"
      h={15}
      value={shortenString(brand.disclaimer)}
    />

    <SectionCell
      className="pb-6"
      label="Static flyer disclaimer"
      h={15}
      value={shortenString(brand.static_disclaimer)}
    />

    <SectionCell
      className="pb-6"
      label="Payment details disclaimer"
      h={15}
      value={shortenString(brand.payment_details_disclaimer)}
    />

    <SectionCell
      className="pb-6"
      label="Lead capture disclaimer"
      h={15}
      value={shortenString(brand.lead_capture_disclaimer)}
    />

    <SectionCell
      className="pb-6"
      h={15}
      value={shortenString(brand.loan_calc_disclaimer)}
      label="Loan calculator disclaimer"
    />

    <SectionCell
      className="pb-6"
      h={15}
      value={shortenString(brand?.third_party_fees_disclaimer ?? "")}
      label="Third Party Fees disclaimer"
    />
  </div>
)

export default BrandOverview
