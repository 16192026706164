import { FC, useState } from "react"
import { ChevronDown, ClipboardCopy, Eye } from "lucide-react"

import BreadCrumbs from "../../../breadcrumbs"
import { Button } from "../../../buttons"
import Dropdown from "../../../dropdown"
import BBSwitch from "../../../form/Switch"
import ProfileLogo from "../../../profile-logo"
import Status from "../../../status"
import Select from "../../../form/select"
import { useInTabletView } from "../../../../hooks"
import { copyToClipboard, getStatusID } from "../../../../utils"
import {
  DropdownItemProps,
  LoanOfficer,
  STATUS_OPTIONS,
  SubscriptionDetails
} from "../../../../../types"

interface Props {
  data: SubscriptionDetails
  loDetails: LoanOfficer
  handleChangeRateAlerts: (subscription: SubscriptionDetails) => void
  onChangeStatus: (subscription: SubscriptionDetails, status: string) => void
}

const EngagementDetailsHeader: FC<Props> = ({
  data,
  loDetails,
  handleChangeRateAlerts,
  onChangeStatus
}) => {
  const inTabletView = useInTabletView()
  const [status, setStatus] = useState<string>(getStatusID(data))
  const selectedStatusOption = STATUS_OPTIONS.find(so => so.id === status)

  const handleViewDashboard = () => window.open(data.dashboard_link, "_blank")
  const handleCopyDashboardLink = () => copyToClipboard(data.dashboard_link)
  const handleChangeStatus = (id: string) => {
    if (status === id) return
    setStatus(id)
    onChangeStatus(data, id)
  }

  const menuItems: DropdownItemProps[] = [
    {
      name: "Copy dashboard link",
      icon: ClipboardCopy,
      onClick: handleCopyDashboardLink
    }
  ]

  if (inTabletView) {
    menuItems.unshift(
      {
        renderItem: () => (
          <BBSwitch
            className="py-3 md:pb-0 md:px-4"
            enabled={data.active === "1"}
            setEnabled={() => handleChangeRateAlerts(data)}
            label="Rate alerts"
            switchPosition="left"
          />
        )
      },
      {
        type: "divider"
      },
      {
        name: "View Lead dashboard",
        icon: Eye,
        onClick: handleViewDashboard
      }
    )
  }

  return (
    <>
      <div>
        <BreadCrumbs
          pages={[
            {
              name: "Engagements",
              href: "/engagements",
              current: false
            },
            {
              name: data.email,
              href: "#",
              current: true
            }
          ]}
        />
      </div>
      <div className="py-6 block sm:flex items-center justify-between">
        <div className="flex items-center">
          <ProfileLogo
            label={`${data.first_name.charAt(0)}${data.last_name.charAt(0)}`}
          />
          <div className="px-4">
            <div className="flex items-center">
              <div className="font-bold text-xl">{data.email}</div>
              <div className="ml-2">
                {data.deleted === "1" ? (
                  <Status.Inactive label="Deleted" />
                ) : (
                  <>
                    {data.active === "1" && <Status.Active label="Active" />}
                    {data.active === "0" && <Status.Idle label="Disabled" />}
                  </>
                )}
              </div>
            </div>
            <div className="text-base pt-1">
              {data.first_name} {data.last_name}
              <span className="text-gray-400">&nbsp;&bull;&nbsp;</span>
              {loDetails.bank}
            </div>
          </div>
        </div>
        <div className="flex items-center pl-20 sm:pl-0 mt-4 sm:mt-0">
          <BBSwitch
            className="hidden lg:flex"
            enabled={data.active === "1"}
            setEnabled={() => handleChangeRateAlerts(data)}
            label="Rate alerts"
          />
          <div className="px-4 lg:pr-0">
            <Select
              label=""
              selectClassName="w-[132px]"
              buttonClassName="h-12"
              selected={selectedStatusOption}
              data={STATUS_OPTIONS}
              IDkey="id"
              setSelected={handleChangeStatus}
            />
          </div>
          <Button
            className="hidden lg:inline-flex mx-4 text-sm md:text-base h-12"
            disabled={!data.dashboard_link}
            href={data.dashboard_link}
          >
            View Lead dashboard
          </Button>
          <Dropdown
            menuBtn={
              <div className="inline-flex justify-center items-center py-3 px-4 border text-sm md:text-base font-medium rounded-md focus:outline-none bg-neutral-100 h-12">
                Actions <ChevronDown className="h-5 w-5 ml-1" />
              </div>
            }
            items={menuItems}
          />
        </div>
        <div className="sm:hidden"></div>
      </div>
    </>
  )
}

export default EngagementDetailsHeader
