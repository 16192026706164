import { FC, useEffect, useMemo, useState } from "react"
import { Plus } from "lucide-react"
import {
  useAppDispatch,
  useAppSelector,
  useInMobileView,
  useToast
} from "../../../hooks"
import {
  LoanOfficer,
  BBColumnDef,
  ViewType,
  LoSearchParams
} from "../../../../types"
import { If, PrimaryBtn, Table } from "../../../ui"
import { masquerade, selectUser } from "../../../../redux/slices/auth"
import { setVisiblePanel } from "../../../../redux/slices/app"
import { BrandCell, EmailCell, MenuCell, UserCell } from "./cells"
import LOCard from "./card"
import { ToggleView } from "../../table/toolbar"
import EditLoProfile from "../../account/edit-lo-profile"
import BBDialog from "../../dialog"
import CardSkeleton from "../../skeletons/normal-card"
import ListSkeleton from "../../skeletons/normal-list"
import {
  useDeleteLoanOfficerMutation,
  useFetchLoanOfficersQuery
} from "../../../../redux/queries"

export interface IDeleteLO {
  state: boolean
  loid: string
}

interface Props {
  searchParams: LoSearchParams
  defaultViewType?: ViewType
  hasViewTypeToggle?: boolean
  hasNewButton?: boolean
  hasMasqueradeIcon?: boolean
  onClickLO?: (loID: string) => void
}

const LOsTable: FC<Props> = ({
  searchParams,
  defaultViewType = "table",
  hasViewTypeToggle = true,
  hasNewButton = false,
  hasMasqueradeIcon = false,
  onClickLO
}) => {
  const dispatch = useAppDispatch()

  const { pushSuccessToast, pushErrorToast } = useToast()

  const [deleteLoanOfficer, { isSuccess, isError }] =
    useDeleteLoanOfficerMutation()

  const myUserId = useAppSelector(selectUser)?.user.id

  const [viewType, setViewType] = useState<ViewType>(defaultViewType)
  const [showDeletePopover, setShowDeletePopover] = useState(false)

  const pageSize = viewType === "table" ? 10 : 12

  const [selectedLo, setSelectedLo] = useState("")
  const [deleteLO, setDeleteLO] = useState("")
  const [isFetching, setIsFetching] = useState(true)
  const inMobileView = useInMobileView()

  const { data, isLoading } = useFetchLoanOfficersQuery({
    ...searchParams,
    setPaginationDefault: false
  })

  useEffect(() => {
    setIsFetching(isLoading)
  }, [isLoading])

  const los: LoanOfficer[] = useMemo(() => {
    return data?.los ?? []
  }, [data])

  const selectedLoDetails = useMemo(
    () => los?.find(item => item.uid === selectedLo),
    [los, selectedLo]
  )

  const closeEditLOPanel = () => {
    setSelectedLo("")
  }

  const onMasquerade = (loid: string) => {
    if (myUserId !== loid) {
      dispatch(masquerade(loid))
    }
  }

  const onEdit = (loanOfficer: LoanOfficer) => {
    setSelectedLo(loanOfficer.uid)
  }

  const handleConfirmDialog = () => {
    setShowDeletePopover(false)
    deleteLoanOfficer(deleteLO)
  }

  const handleCancelDialog = () => {
    setDeleteLO("")
    setShowDeletePopover(false)
  }

  const handleCloseDialog = () => {
    setShowDeletePopover(false)
  }

  // delete LO
  const onDelete = (loid: string) => {
    setDeleteLO(loid)
    setShowDeletePopover(true)
  }

  const openCreatePanel = () => {
    dispatch(setVisiblePanel("loan-officer/new"))
  }

  useEffect(() => {
    setViewType(defaultViewType)
  }, [defaultViewType])

  useEffect(() => {
    if (isSuccess) {
      pushSuccessToast("Loan Officer deleted successfully")
    } else if (isError) {
      pushErrorToast("Failed to delete Loan Officer")
    }
  }, [pushSuccessToast, pushErrorToast, isSuccess, isError])

  const columns: BBColumnDef<LoanOfficer>[] = [
    {
      id: "name",
      header: "Name",
      accessorFn: row => row.name,
      footer: props => props.column.id,
      cell: props => (
        <UserCell
          onUserCellClick={onClickLO}
          info={props.row.original}
          masqueradeEnabled={
            hasMasqueradeIcon && myUserId !== props.row.original.uid
          }
          onMasquerade={onMasquerade}
        />
      ),
      colSpan: 3
    },
    {
      id: "bank",
      header: "Brand",
      accessorFn: row => row.bank,
      footer: props => props.column.id,
      cell: props => (
        <BrandCell onBrandCellClick={onClickLO} info={props.row.original} />
      ),
      meta: { headerClassName: "hidden md:table-cell" }
    },
    {
      id: "email",
      header: "Email",
      accessorFn: row => row.email,
      footer: props => props.column.id,
      cell: props => (
        <EmailCell onEmailCellClick={onClickLO} info={props.row.original} />
      ),
      meta: { headerClassName: "hidden md:table-cell" }
    },
    {
      id: "edit",
      header: "",
      footer: props => props.column.id,
      cell: props => (
        <MenuCell
          info={props.row.original}
          masqueradeEnabled={myUserId !== props.row.original.uid}
          onMasquerade={onMasquerade}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      )
    }
  ]

  const hasExtraHeaderElement =
    hasNewButton || (hasViewTypeToggle && !inMobileView)

  return (
    <>
      <If
        condition={isFetching}
        then={
          <If
            condition={viewType === "table"}
            then={<ListSkeleton />}
            else={<CardSkeleton />}
          />
        }
        else={
          <>
            <Table
              data={los ?? []}
              columns={columns}
              viewType={viewType}
              pageSize={pageSize}
              dropdownFilterColumnId="bank"
              searchPlaceholder="Search LO's by name, email or brand…"
              renderCard={row => (
                <LOCard
                  onClickLO={onClickLO}
                  onEditLO={onEdit}
                  row={row}
                  onDeleteLO={onDelete}
                />
              )}
              extraHeaderElement={
                hasExtraHeaderElement ? (
                  <>
                    {hasViewTypeToggle ? (
                      <ToggleView
                        className="ml-auto"
                        viewType={viewType}
                        setViewType={setViewType}
                      />
                    ) : null}
                    {hasNewButton ? (
                      <PrimaryBtn onClick={openCreatePanel}>
                        <Plus size={16} />
                        &nbsp;New LO
                      </PrimaryBtn>
                    ) : null}
                  </>
                ) : null
              }
              shrinkFilterSort
            />
          </>
        }
      />
      <EditLoProfile
        data={selectedLoDetails}
        open={!!selectedLo}
        isInSidePanel
        onClose={closeEditLOPanel}
      />
      <BBDialog
        open={showDeletePopover}
        title="Delete Loan Officer Profile"
        body="By doing this all the information stored in the system about this loan officer will be deleted. Are you sure ?"
        okLabel="Yes, Delete"
        onOk={handleConfirmDialog}
        onCancel={handleCancelDialog}
        onClose={handleCloseDialog}
        cancelLabel="Cancel"
      />
    </>
  )
}

export default LOsTable
