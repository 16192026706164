import { FC } from "react"
import { SubscriptionEvent } from "../../../../../../types"
import { capitalize, formatDateTimeFromTimestamp } from "../../../../../utils"
import EventIcon from "./event-icon"

interface Props {
  data: SubscriptionEvent
}

const EngagementEventLog: FC<Props> = ({ data }) => {
  const { type, timestamp } = data

  return (
    <div className="flex items-center justify-between py-4 max-w-xl">
      <div className="flex items-center">
        <EventIcon type={type} color="#fff" />
        <div className="ml-4">
          <span>
            {type
              .split("_")
              .map(part => capitalize(part))
              .join(" ")}
          </span>
        </div>
      </div>

      <div className="text-neutral-400">
        {formatDateTimeFromTimestamp(timestamp, "LL/dd/yyyy 'at' hh:mm:ss a")}
      </div>
    </div>
  )
}

export default EngagementEventLog
