import { useState, useEffect } from "react"

import { createColumnDetail } from "../utils"
import { ColumnDetail, LeadConversionProgress } from "../../types"

export const useColumnDetailsTransformation = (
  leadProgressData: LeadConversionProgress | undefined,
  containsData: boolean | undefined
): ColumnDetail[] => {
  const [data, setData] = useState<ColumnDetail[]>([])

  useEffect(() => {
    const columns: ColumnDetail[] = []

    if (containsData) {
      // Push the workflow started column
      columns.push(
        createColumnDetail(
          "Workflow Started",
          leadProgressData!.lead_started_count,
          "100.00",
          leadProgressData!.lead_between_started_and_progress25_perc?.toFixed(2)
        )
      )

      // Push the progress 25% column
      columns.push(
        createColumnDetail(
          "Progress 25%",
          leadProgressData!.lead_progress25_count,
          leadProgressData!.lead_progress25_perc?.toFixed(2),
          leadProgressData!.lead_between_progress25_and_progress50_perc?.toFixed(
            2
          )
        )
      )

      // Push the progress 50% column
      columns.push(
        createColumnDetail(
          "Progress 50%",
          leadProgressData!.lead_progress50_count,
          leadProgressData!.lead_progress50_perc?.toFixed(2),
          leadProgressData!.lead_between_progress50_and_progress75_perc?.toFixed(
            2
          )
        )
      )

      // Push the progress 75% column
      columns.push(
        createColumnDetail(
          "Progress 75%",
          leadProgressData!.lead_progress75_count,
          leadProgressData!.lead_progress75_perc?.toFixed(2),
          leadProgressData!.lead_between_progress75_and_completed_perc?.toFixed(
            2
          )
        )
      )

      // Push the workflow completed column
      columns.push(
        createColumnDetail(
          "Lead Workflow Completed",
          leadProgressData!.lead_completed_count,
          leadProgressData!.lead_completed_perc?.toFixed(2),
          leadProgressData!.lead_between_completed_and_captured_perc?.toFixed(2)
        )
      )

      // Push the workflow captured column
      columns.push(
        createColumnDetail(
          "Lead Captured",
          leadProgressData!.lead_captured_count,
          leadProgressData!.lead_captured_perc?.toFixed(2),
          "0",
          "#63C7C4"
        )
      )
    }
    setData(columns)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containsData])
  return data
}
