import { DateTime } from "luxon"

import { LeadCount, SubscriptionCount } from "../../types"

export interface TransformedCountDataItem {
  date: DateTime
  y?: number
  color?: string
  name: string
}

export const useCountDataTransformation = (
  countData: (LeadCount | SubscriptionCount)[] | undefined,
  containsData: boolean | undefined,
  color: string,
  dateFormat: string,
  labelDateFormat: string,
  range: number
): TransformedCountDataItem[] => {
  const isLeadCount = (
    object: LeadCount | SubscriptionCount
  ): object is LeadCount => {
    return "timestamp_tz_date" in object
  }

  if (!containsData) {
    const noData: TransformedCountDataItem[] = []

    for (let i = 0; i < range; i++) {
      const date = DateTime.now().minus({ days: i })
      noData.push({
        date,
        name: date.toFormat(labelDateFormat)
      })
    }

    return noData.sort((a, b) => (a.date > b.date ? 1 : -1))
  }

  return countData!
    .map(item => {
      if (isLeadCount(item)) {
        return {
          date: DateTime.fromFormat(item.timestamp_tz_date, dateFormat),
          y: item.lead_count,
          color
        }
      } else {
        return {
          date: DateTime.fromFormat(item.period_start, dateFormat),
          y: item.subscription_count,
          color
        }
      }
    })
    .sort((a, b) => (a.date > b.date ? 1 : -1))
    .map(item => ({
      ...item,
      name: item.date.toFormat(labelDateFormat)
    }))
}
