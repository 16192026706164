/* eslint-disable import/no-anonymous-default-export */
import { Activity, LeadHistory, OrgActivity } from "../../types"
import HighlightedText from "../ui/highlighted-text"
import { capitalize } from "./string"

export const MAP_TYPE_TO_STRING: { [key: string]: string } = {
  pricing_empty: "got empty pricing",
  lead: "got a new lead",
  new_lead: "got a new lead",
  lead_workflow_loaded: "loaded lead workflow",
  lead_workflow_modal_loaded: "loaded lead workflow modal",
  lead_workflow_completed: "completed lead workflow",
  pricing_shown: "pricing is shown",
  rate_table_loaded: "loaded rate table",
  workflow_button_clicked: "clicked workflow button",
  progress_twentyFivePercent: "progressed 25%",
  progress_fiftyPercent: "progressed 50%",
  progress_seventyFivePercent: "progressed 75%",
  loan_summary_pdf: "loan summary worksheet created",
  unsubscribed: "unsubscribed",
  dashboard_update: "updated dashboard",
  dashboard_contact: "contacted in the dashboard",
  dashboard_pageview: "viewed dashboard",
  dashboard_first_visit: "received the first dashboard visit",
  reactivated: "reactivated",
  intro_email_sent: "sent subscription intro email",
  intro_email_opened: "intro email was opened",
  intro_email_clicked: "clicked intro email"
}

export enum EXCLUDED_ACTIVITY_TYPES {
  email_bounced = "email_bounced",
  stale_unsubscribed = "stale_unsubscribed",
  unsubscribe_param_passed = "unsubscribe_param_passed",
  unsubscribe_link_clicked = "unsubscribe_link_clicked",
  deleted = "deleted",
  followup_sms_sent = "followup_sms_sent"
}

export const convertActivityToString = (activity: Activity) => {
  const { type, detail, category } = activity

  switch (type) {
    case "unsubscribed":
      return (
        <>
          <HighlightedText>
            {`${detail.subscription.first_name} ${detail.subscription.last_name}`}
          </HighlightedText>
          <span className="text-gray-400 md:text-base-dark">
            {` ${MAP_TYPE_TO_STRING[type]} `}
          </span>
          <HighlightedText>{detail.lo?.name}</HighlightedText>
          <span className="text-gray-400 md:text-base-dark">
            {` from ${category}`}
          </span>
        </>
      )
    case "dashboard_first_visit":
      return (
        <>
          <HighlightedText>{detail.lo?.name}</HighlightedText>
          <span className="text-gray-400 md:text-base-dark">
            {` ${MAP_TYPE_TO_STRING[type]} from `}
          </span>
          <HighlightedText>{detail?.subscription?.email}</HighlightedText>
        </>
      )
    case "intro_email_sent":
      return (
        <>
          <HighlightedText>{detail.lo?.name}</HighlightedText>
          <span className="text-gray-400 md:text-base-dark">
            {` ${MAP_TYPE_TO_STRING[type]} to `}
          </span>
          <HighlightedText>{detail?.subscription?.email}</HighlightedText>
        </>
      )
    case "loan_summary_pdf":
    case "intro_email_opened":
    case "intro_email_clicked":
      return (
        <>
          <HighlightedText>{detail.lo?.name}'s</HighlightedText>
          <span className="text-gray-400 md:text-base-dark">
            {` ${MAP_TYPE_TO_STRING[type]} by `}
          </span>
          <HighlightedText>{detail?.subscription?.email}</HighlightedText>
        </>
      )
    case "new_lead":
      return (
        <>
          <HighlightedText>{detail.lo?.name}</HighlightedText>
          <span className="text-gray-400 md:text-base-dark">
            {` ${MAP_TYPE_TO_STRING[type]} `}
          </span>
          <HighlightedText>{`${detail.lead?.first_name} ${detail.lead?.last_name}`}</HighlightedText>
        </>
      )
    default:
      return (
        <>
          <HighlightedText>{detail.lo?.name}</HighlightedText>
          <span className="text-gray-400 md:text-base-dark">
            {` ${MAP_TYPE_TO_STRING[type]} ${category} `}
          </span>
          <HighlightedText>{detail?.subscription?.email}</HighlightedText>
        </>
      )
  }
}

export const convertOrgActivityToString = (activity: OrgActivity) => {
  const { type, lo, details } = activity
  switch (type) {
    case "lead":
      return (
        <>
          <HighlightedText>{lo?.name}</HighlightedText>
          <span className="text-gray-400 md:text-base-dark">
            {` ${MAP_TYPE_TO_STRING[type]} `}
          </span>
          <HighlightedText>{`${details.lead?.first_name} ${details.lead?.last_name}`}</HighlightedText>
          <span className="text-gray-400 md:text-base-dark">
            {` from ${details.source_details?.type} ${details.source_details?.title}`}
          </span>
        </>
      )

    default:
      break
  }
}

export const convertLeadHistoryTypeToString = (history: LeadHistory) => {
  const { name } = history
  if (!name) return ""
  const valueFromMap = (MAP_TYPE_TO_STRING as any)[name]

  if (!valueFromMap) {
    const typeWithoutUnderscores = name.replace(/_/g, " ")
    return `Activity "${
      typeWithoutUnderscores.charAt(0).toUpperCase() +
      typeWithoutUnderscores.slice(1)
    }"`
  }

  return capitalize(valueFromMap)
}

export default {
  convertActivityToString,
  convertOrgActivityToString,
  convertLeadHistoryTypeToString
}
