import PlacesAutocomplete, { geocodeByPlaceId } from "react-places-autocomplete"
import { convertGooglePlaceToAddressObj, formatZipcode } from "../../utils"
import Label from "../form/label"
import { ParsedAddress } from "../../../types"

const handelOnselect = (
  value: string,
  placeID: string,
  handleInputChange: (value: string | any) => void
) => {
  geocodeByPlaceId(placeID)
    .then(results => {
      const addressObj = convertGooglePlaceToAddressObj(results[0] as any)
      handleInputChange(addressObj)
    })
    .catch(error => console.error(error))

  handleInputChange(formatZipcode(value))
}

interface Props {
  tooltipTxt?: string
  placeholder?: string
  label?: string
  className?: string
  value?: string
  handleOnChange: (value: string | ParsedAddress) => void
}

const ZipcodeAutocomplete = ({
  tooltipTxt = "",
  placeholder = "Search zipcode...",
  value,
  className = "",
  label = "Zip Code",
  handleOnChange
}: Props) => {
  const change = (val: string | ParsedAddress) => {
    handleOnChange(typeof val === "string" ? formatZipcode(val) : val)
  }

  return (
    <div className={className}>
      <Label name="location" label={label} helperText={tooltipTxt} />
      <PlacesAutocomplete
        value={value}
        onChange={change}
        onSelect={(value: string, placeID: string) => {
          handelOnselect(value, placeID, change)
          change(value)
        }}
        debounce={400}
        searchOptions={{
          componentRestrictions: {
            country: "us"
          },
          types: ["(regions)"]
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="relative">
            <div className="mt-1 relative flex rounded-md shadow-sm">
              <input
                {...getInputProps({
                  placeholder,
                  className:
                    "appearance-none block w-full px-3 py-2.5 border border-neutral-200 rounded-md  placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                })}
              />
            </div>

            {value && (loading || suggestions?.length) ? (
              <div className="absolute z-50 max-h-60 w-full mt-1 py-1 overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {loading ? (
                  <div className="text-gray-900 select-none py-2 px-4">
                    Loading...
                  </div>
                ) : (
                  suggestions.map(suggestion => {
                    const className = `relative cursor-default select-none py-2 px-4 block truncate ${
                      suggestion.active
                        ? "bg-teal-600 text-white"
                        : "text-gray-900"
                    }`

                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    )
                  })
                )}
              </div>
            ) : null}
          </div>
        )}
      </PlacesAutocomplete>
      <p className="sr-only" id="input-desc">
        Suggestions will appear below when you start typing.
      </p>
    </div>
  )
}

export default ZipcodeAutocomplete
