/**
  API Key API wrapper

	JSON endpoint:  /aws_api/manage.json
	GET  /aws_api/manage.json?loid={loid} - Get current API key
	POST  /aws_api/manage.json?loid={loid} - Create new API key
	DELETE  /aws_api/manage.json?id={id} - Delete API key
**/

import { api, getQueryString } from "../common/utils"
import {
  FIND_COUNTY_ENDPOINT,
  RATE_TABLE_CONFIG_ENDPOINT,
  SUBSCRIPTION_CONFIG_ENDPOINT
} from "../common/constants"
import {
  CountyDetails,
  RateTableConfig,
  RateTableConfigDetails,
  SubscriptionConfigDetails
} from "../types"

const configAdmin = {
  getRateTableConfigs: async (params: any): Promise<RateTableConfig[]> => {
    const { data } = await api.get(
      `${RATE_TABLE_CONFIG_ENDPOINT}${getQueryString(params)}`
    )
    return data
  },
  getRateTableConfigDetails: async (
    id: string
  ): Promise<RateTableConfigDetails> => {
    const { data } = await api.get(
      `${RATE_TABLE_CONFIG_ENDPOINT}${getQueryString({ id })}`
    )
    return data
  },
  getDefaultRateTableConfig: async (): Promise<RateTableConfigDetails> => {
    const { data } = await api.get(`${RATE_TABLE_CONFIG_ENDPOINT}?id=`)
    return data
  },
  updateRateTableConfig: async (
    payload: Partial<RateTableConfigDetails>
  ): Promise<{ status: string; message?: string }> => {
    const { data } = await api.post(RATE_TABLE_CONFIG_ENDPOINT, payload)
    return data
  },
  deleteRateTableConfig: async (
    id: string
  ): Promise<{ status: string; message?: string }> => {
    const { data } = await api.delete(`${RATE_TABLE_CONFIG_ENDPOINT}?id=${id}`)
    return data
  },
  getCountyOptions: async (name_like: string): Promise<string[]> => {
    const { data } = await api.get(
      `${FIND_COUNTY_ENDPOINT}${getQueryString({ name_like })}`
    )
    return data
  },
  getCountyDetails: async (params: {
    name: string
    state: string
  }): Promise<CountyDetails> => {
    const { data } = await api.get(
      `${FIND_COUNTY_ENDPOINT}${getQueryString(params)}`
    )
    return data
  },
  updateCounty: async (
    payload: Partial<CountyDetails>
  ): Promise<{ status: string; message?: string }> => {
    const { data } = await api.post(FIND_COUNTY_ENDPOINT, payload)
    return data
  },
  updateSubscriptionConfig: async (
    payload: Partial<SubscriptionConfigDetails>
  ): Promise<{ status: string; message?: string }> => {
    const { data } = await api.post(SUBSCRIPTION_CONFIG_ENDPOINT, payload)
    return data
  },
  deleteSubscriptionConfig: async (
    id: string
  ): Promise<{ status: string; message?: string }> => {
    const { data } = await api.delete(
      `${SUBSCRIPTION_CONFIG_ENDPOINT}?id=${id}`
    )
    return data
  }
}

export default configAdmin
