import { FormikValues } from "formik"
import React, { ChangeEvent, useState } from "react"

interface Props {
  field?: FormikValues
  className?: string
  meta?: any
  setFormField: (name: string, value: any) => void
}

const CurrencyInput: React.FC<Props> = ({ field, className, setFormField }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const numericValue = e.target.value.replace(/[^\d.]/g, "")
    setFormField(field?.name, numericValue)
  }

  const handleFocus = () => {
    setIsEditing(true)
  }

  const handleBlur = () => {
    setIsEditing(false)
  }

  const formatCurrency = (value: string): string => {
    if (isEditing) {
      return value
    }

    const numericValue = parseFloat(value)
    if (!isNaN(numericValue)) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      })
      return formatter.format(numericValue)
    }
    return value
  }

  return (
    <input
      className={className}
      type="text"
      {...field}
      value={formatCurrency(field?.value)}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  )
}

export default CurrencyInput
