export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1)

export const formatUnderscoresInField = (input?: string) =>
  (input ?? "")
    .split("_")
    .map(part => capitalize(part))
    .join(" ")

export const formatMessage = (msg: string) =>
  msg
    .split(".")
    .map(str => capitalize(str))
    .join(".")

export const formatCurrency = (
  val: number,
  options: any = { style: "currency", currency: "USD" }
) => {
  const formatter = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
    ...options
  })

  return formatter.format(val)
}

export const formatPhoneNumber = (phoneNumber: string) => {
  // Remove any non-digit characters from the input string
  const cleaned = phoneNumber.replace(/\D/g, "")

  // Format the cleaned string based on the desired format
  if (cleaned.length === 10) {
    // Format as (XXX) XXX-XXXX
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`
  } else if (cleaned.length === 11) {
    // Format as X (XXX) XXX-XXXX (for international numbers)
    return `${cleaned.charAt(0)} (${cleaned.slice(1, 4)}) ${cleaned.slice(
      4,
      7
    )}-${cleaned.slice(7)}`
  } else {
    // If the phone number is not a valid length, return the original string
    return phoneNumber
  }
}

// shorten string to a certain length
interface ShortenString {
  (str: string, maxLength?: number): string
}

export const shortenString: ShortenString = (str, maxLength = 250) => {
  if (str.length <= maxLength) return str

  return str.substr(0, maxLength) + "…"
}

export const getNonEmptyStringOrDefault = (
  str: string | undefined,
  defaultValue: string = "-"
): string => (isNotEmptyString(str) ? str! : defaultValue)

export const isNotEmptyString = (str: string | undefined): boolean =>
  !!str && str !== ""

export const isNotZeroString = (str: string | undefined): boolean =>
  !!str && str !== "0"
