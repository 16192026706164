import { useEffect, useRef, useState } from "react"
import {
  GoogleOAuthProvider,
  GoogleLogin,
  CredentialResponse
} from "@react-oauth/google"
import { useAppDispatch } from "../../../hooks"
import { pushToast } from "../../../../redux/slices/notifications"

interface Props {
  theme: "icon" | "full"
}

const GoogleLoginBtnInner = ({ theme }: Props) => {
  const dispatch = useAppDispatch()
  const containerRef = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState<number>(0)

  const handleSuccess = (credentialResponse: CredentialResponse) => {
    window.location.replace(
      `${process.env.REACT_APP_API_HOST}/bb_goauth?token=${credentialResponse.credential}&core_ui=1`
    )
  }

  const handleError = () => {
    dispatch(
      pushToast({
        msg: "Failed to sign in with Google, please try again.",
        theme: "danger",
        timeout: 3000
      })
    )
  }

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const { width } = containerRef.current.getBoundingClientRect()
        setWidth(width)
      }
    }

    handleResize()
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const isIcon = theme === "icon"

  return (
    <div ref={containerRef}>
      <GoogleLogin
        type={isIcon ? "icon" : "standard"}
        width={isIcon ? undefined : `${width}px`}
        size="large"
        theme="filled_blue"
        onSuccess={handleSuccess}
        onError={handleError}
      />
    </div>
  )
}

const GoogleLoginBtn = (props: Props) => {
  if (!process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID) return null

  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID as string}
    >
      <GoogleLoginBtnInner {...props} />
    </GoogleOAuthProvider>
  )
}

export default GoogleLoginBtn
