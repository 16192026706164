import { FC, useMemo, useState } from "react"
import {
  useFetchAllBrandsQuery,
  useFetchSubscriptionConfigsQuery
} from "../../../../redux/queries"
import SubscriptionConfigTable from "./table"
import ManageSubscriptionConfig from "./manage"

const ConfigSubscription: FC<{}> = () => {
  const { data, isFetching } = useFetchSubscriptionConfigsQuery({})
  const { data: brands } = useFetchAllBrandsQuery({ limit: 999 })

  const [selectedId, setSelectedId] = useState<string>()

  const selected = useMemo(
    () => data?.find(item => item.id === selectedId),
    [data, selectedId]
  )

  const resetSelectedId = () => setSelectedId("")

  return (
    <div>
      <SubscriptionConfigTable
        data={data ?? []}
        brands={brands ?? []}
        isFetching={isFetching}
        onSelect={setSelectedId}
      />

      <ManageSubscriptionConfig
        open={!!selectedId && !!selected}
        data={selected}
        onClose={resetSelectedId}
      />
    </div>
  )
}

export default ConfigSubscription
