import { Dialog, Transition } from "@headlessui/react"
import { X } from "lucide-react"
import { FC, Fragment } from "react"
import If from "../if"

interface Props {
  title?: string
  children: React.ReactNode
  forceShowCloseButton?: boolean
  onClose: () => void
  size?: "sm" | "md" | "lg" | "xl" | "2xl"
  noPadding?: boolean
}

const CloseButton: FC<{ onClose: () => void }> = ({ onClose }) => (
  <button
    data-testid="close-button"
    type="button"
    className="hidden sm:block ml-auto bg-white rounded-md primary-alt-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    onClick={onClose}
  >
    <span className="sr-only">Close</span>
    <X size="24" aria-hidden="true" />
  </button>
)

const Content = ({
  title,
  children,
  onClose,
  size,
  noPadding,
  forceShowCloseButton = false
}: Props) => {
  const maxWidthClass = `max-w-${size}`

  return (
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div className="flex min-h-full items-end justify-center text-center sm:items-center p-0">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <Dialog.Panel
            className={`relative bg-white sm:rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-full ${maxWidthClass} ${
              noPadding ? "" : "sm:p-6"
            }`}
          >
            <If
              condition={!!title}
              then={
                <Dialog.Title
                  as="h3"
                  className="text-xl leading-6 font-medium text-base-dark mb-8 flex items-center"
                >
                  {title}
                  <CloseButton onClose={onClose} />
                </Dialog.Title>
              }
              else={
                forceShowCloseButton ? <CloseButton onClose={onClose} /> : null
              }
            />
            <div className="w-full">{children}</div>
          </Dialog.Panel>
        </Transition.Child>
      </div>
    </div>
  )
}

export default Content
