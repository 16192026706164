import { FC } from "react"
import { Organization } from "../../../../../types"
import Status from "../../../status"
import { formatDateTimeFromTimestamp } from "../../../../utils"

type Props = {
  info: Organization
  onClick: (id: string) => void
}

const OrganizationCell: FC<Props> = ({ info, onClick }) => {
  const { logo, name, created, disabled } = info

  return (
    <td
      className="whitespace-nowrap py-4 px-3 text-sm table-cell cursor-pointer"
      colSpan={3}
    >
      <div className="flex items-center" onClick={() => onClick(info.id)}>
        <div className="w-12 lg:w-32 h-12 p-1 rounded-md border border-neutral-300">
          <img
            className="max-h-full mx-auto object-contain"
            src={logo}
            alt={`${name} logo`}
          />
        </div>
        <div className="pl-4">
          <div className="lg:flex items-center font-bold">
            <div className="max-w-[10rem] lg:max-w-none whitespace-normal break-all">
              {name}
            </div>
            <span className="ml-0 lg:ml-2">
              {disabled ? <Status.Inactive /> : <Status.Active />}
            </span>
          </div>
          <div className="text-neutral-400">
            Active since&nbsp;
            {formatDateTimeFromTimestamp(created, "dd LLL yyyy")}
          </div>
        </div>
      </div>
    </td>
  )
}

export default OrganizationCell
