import { useCallback, useRef } from "react"
import { debounce } from "../utils"

export const useDebouncedCallback = (
  callback: Function,
  delay?: number,
  immediate?: boolean
) => {
  const callbackRef: any = useRef()
  callbackRef.current = callback
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    debounce((...args: any) => callbackRef.current(...args), delay, immediate),
    []
  )
}
