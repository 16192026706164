import { FC } from "react"
import { GooglePlace, GoogleReview, Reviews } from "../../../types"
import ImgGoogleLogo from "../../../assets/icons/google_logo.svg"
import ImgStar from "../../../assets/icons/star.svg"
import If from "../if"

interface Props {
  place?: GooglePlace
  reviews?: Reviews
}

const GoogleReviewList: FC<Props> = ({ place, reviews }) => {
  return (
    <div className="pb-6">
      <div className="text-lg font-bold">Google reviews</div>
      {place && (
        <div className="py-4 flex items-center">
          <div className="pr-3">
            <img src={ImgGoogleLogo} alt="Google Logo" />
          </div>
          <div>
            <div className="font-bold">{place.name}</div>
            <div>{place.formatted_address}</div>
          </div>
        </div>
      )}

      <If
        condition={!!reviews?.reviews?.length}
        then={reviews?.reviews.map((review, idx) => {
          const googleReview = review as GoogleReview
          return (
            <div key={idx} className="py-4">
              <div className="flex items-center leading-5">
                <div className="w-5 h-5">
                  <img className="h-full" src={ImgStar} alt="Star" />
                </div>
                <div className="font-bold pl-2">{googleReview.rating}</div>
                <div className="text-neutral-300">
                  <span className="pl-3 pr-2">.</span>
                  {googleReview.author_name}
                </div>
              </div>
              <div className="pt-4">{googleReview.text}</div>
            </div>
          )
        })}
        else={<div className="py-4">-</div>}
      />
    </div>
  )
}

export default GoogleReviewList
