import { useCallback, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import bbApi from "../../api"
import { ALLOWED_ROLES_BY_ROUTE } from "../../common/constants"
import { useAppDispatch } from "../../common/hooks"
import BrandActivities from "../../common/ui/brand-details/activities"
import BrandDetails from "../../common/ui/brand-details/details"
import BrandDetailsHeader from "../../common/ui/brand-details/header"
import BrandDetailsIntegrations from "../../common/ui/integrations/integrationTable"
import BrandDetailsLeads from "../../common/ui/brand-details/leads"
import BrandDetailsLoanOfficers from "../../common/ui/brand-details/loan-officers/BrandDetailsLoanOfficers"
import PricingLogs from "../../common/ui/pricing-logs"
import ManageBrand from "../../common/ui/brands/manage"
import BBDialog from "../../common/ui/dialog"
import Tabs from "../../common/ui/tabs"
import { RequireRole } from "../../common/validation"
import {
  useFetchIntegrationsQuery,
  useFetchLeadsByBrandQuery,
  useFetchBrandQuery,
  useFetchBrandReviewsQuery,
  useFetchOrgDetailsQuery
} from "../../redux/queries"
import { pushToast } from "../../redux/slices/notifications"
import { GooglePlace, Integration } from "../../types"
import { If } from "../../common/ui"

const BrandDetailsPage = () => {
  const { brandId: id, orgId } = useParams()

  const dispatch = useAppDispatch()

  const { data: orgDetails } = useFetchOrgDetailsQuery(
    { id: orgId ?? "" },
    { skip: !orgId }
  )
  const { data: brand, isFetching } = useFetchBrandQuery(id ?? "", {
    skip: !id
  })

  const { data: review } = useFetchBrandReviewsQuery(
    {
      id: brand?.id ?? "",
      place_id: brand?.place_id ?? "",
      zillow_brand_name: brand?.zillow_brand_name ?? "",
      experience_id: brand?.experience_id ?? "",
      yelp_id: brand?.yelp_id ?? "",
      nmls: brand?.nmls ?? ""
    },
    {
      skip:
        !brand ||
        !(
          brand?.place_id ||
          brand?.zillow_brand_name ||
          brand?.experience_id ||
          brand?.yelp_id
        )
    }
  )

  const { data: leads, isLoading: isLoadingLeads } = useFetchLeadsByBrandQuery(
    id ?? "",
    { skip: !id }
  )
  const { data: integrationsAll, isLoading: isLoadingIntegrationsAll } =
    useFetchIntegrationsQuery({ org_id: brand?.org_id, brand_id: id as string })

  const integrations = useMemo(
    () =>
      (integrationsAll as Integration[])?.filter(
        ({ brand_id }) => brand_id === id
      ),
    [id, integrationsAll]
  )

  const [googlePlace, setGooglePlace] = useState<GooglePlace>()

  const [manageBrandVisible, setManageBrandVisible] = useState<boolean>(false)
  const [deleteBrandVisible, setDeleteBrandVisible] = useState<boolean>(false)

  const closeManageBrandSidebar = () => setManageBrandVisible(false)
  const openDeleteBrand = () => setDeleteBrandVisible(true)
  const closeDeleteBrand = () => setDeleteBrandVisible(false)

  const [tab, setTab] = useState<string>("brand-details")

  const onChangeTab = (id: string) => setTab(id)

  const handleEdit = () => {
    setManageBrandVisible(true)
  }

  const handleDelete = () => {}

  const setCurrentPlace = useCallback(
    async (placeId: string) => {
      try {
        const place = await bbApi.brand.getPlace({
          place_id: placeId
        })

        if ("status" in place) {
          dispatch(
            pushToast({
              msg: "Google place API is unavailble, try again later",
              timeout: 3000
            })
          )
        } else {
          setGooglePlace(place)
        }
      } catch (error) {
        console.error(error)
      }
    },
    [dispatch]
  )

  useEffect(() => {
    if (brand?.place_id) {
      setCurrentPlace(brand.place_id)
    }
  }, [brand?.place_id, setCurrentPlace])

  return (
    <RequireRole allowedRoles={ALLOWED_ROLES_BY_ROUTE.brands}>
      <div className="h-full bg-white">
        <If
          isLoading={isFetching}
          condition={!!brand}
          then={
            <>
              <BrandDetailsHeader
                brand={brand!}
                onClickDelete={openDeleteBrand}
                onClickEdit={handleEdit}
                breadcrumbs={
                  orgDetails
                    ? [
                        {
                          name: "Organizations",
                          href: "/organizations"
                        },
                        {
                          name: orgDetails.name,
                          href: `/organizations/${orgDetails.id}`
                        },
                        {
                          name: brand?.name as string,
                          href: "#",
                          current: true
                        }
                      ]
                    : undefined
                }
              />
              <div>
                <Tabs
                  className="px-6"
                  currentTab={tab}
                  tabs={[
                    { id: "brand-details", label: "Brand Details" },
                    { id: "activity", label: "Activity" },
                    { id: "loan-officers", label: "Loan Officers" },
                    { id: "leads", label: "Leads" },
                    { id: "integrations", label: "Brand Integrations" },
                    { id: "pricing-log", label: "Pricing Log" }
                  ]}
                  onChange={onChangeTab}
                />
                <div className="bg-white">
                  {tab === "brand-details" && (
                    <BrandDetails
                      brand={brand!}
                      review={review}
                      googlePlace={googlePlace}
                    />
                  )}
                  {tab === "activity" && <BrandActivities brand={brand!} />}
                  {tab === "loan-officers" && (
                    <BrandDetailsLoanOfficers brandId={id} />
                  )}
                  {tab === "leads" && (
                    <BrandDetailsLeads
                      data={leads ?? []}
                      isLoading={isLoadingLeads}
                    />
                  )}
                  {tab === "integrations" && (
                    <BrandDetailsIntegrations
                      data={integrations ?? []}
                      isLoading={isLoadingIntegrationsAll}
                    />
                  )}
                  {tab === "pricing-log" && (
                    <PricingLogs id={{ bank_id: id }} />
                  )}
                </div>
              </div>
              <ManageBrand
                open={manageBrandVisible}
                data={brand}
                isInSidePanel
                onClose={closeManageBrandSidebar}
              />
              <BBDialog
                open={deleteBrandVisible}
                onClose={closeDeleteBrand}
                title="Delete brand"
                body="By doing this all the information stored in the system about this brand will be deleted. Are you sure?"
                okLabel="Yes, Delete"
                cancelLabel="Cancel"
                onOk={handleDelete}
                onCancel={closeDeleteBrand}
              />
            </>
          }
          else={<div className="p-6">Brand not found</div>}
        />
      </div>
    </RequireRole>
  )
}

export default BrandDetailsPage
