import { FC } from "react"
import { useFetchActivitiesByBrandQuery } from "../../../../redux/queries"
import { Brand } from "../../../../types"
import ActivityTable from "../../activity/table"

interface Props {
  brand: Brand
}

const BrandActivities: FC<Props> = ({ brand }) => {
  const {
    data: activities = [],
    isFetching,
    refetch
  } = useFetchActivitiesByBrandQuery(
    { brand_id: brand?.id, limit: 50 },
    { skip: !brand?.id }
  )

  return (
    <div className="px-6">
      <ActivityTable
        data={activities}
        isLoading={isFetching}
        searchBarVisible={false}
        refetch={refetch}
      />
    </div>
  )
}

export default BrandActivities
