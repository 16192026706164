import { DateTime } from "luxon"

import {
  RateTableUsageType,
  RateTableUsageTypeColor,
  createRateTableUsageSeries,
  getDefaultNoDataDates,
  getDefaultSeriesData
} from "../utils"
import { RateTableUsage } from "../../types"

export const useRateUsageHighchartsOptions = (
  rateDatas: RateTableUsage[] | undefined,
  containsData: boolean | undefined,
  dateFormat: string,
  labelDateFormat: string
): {
  chartOptions: Highcharts.Options
  seriesData?: Record<RateTableUsageType, number[]>
} => {
  const defaultChartOptions: Highcharts.Options = {
    xAxis: {
      categories: [],
      crosshair: true
    },
    series: [
      createRateTableUsageSeries(
        RateTableUsageType.IMPRESSIONS,
        RateTableUsageTypeColor.IMPRESSIONS
      ),
      createRateTableUsageSeries(
        RateTableUsageType.UPDATE_RATES_CLICK,
        RateTableUsageTypeColor.UPDATE_RATES_CLICK
      ),
      createRateTableUsageSeries(
        RateTableUsageType.GET_STARTED_CLICK,
        RateTableUsageTypeColor.GET_STARTED_CLICK
      ),
      createRateTableUsageSeries(
        RateTableUsageType.PERSONALIZED_RATES_CLICK,
        RateTableUsageTypeColor.PERSONALIZED_RATES_CLICK
      ),
      createRateTableUsageSeries(
        RateTableUsageType.GET_RATE_ALERTS_CLICK,
        RateTableUsageTypeColor.GET_RATE_ALERTS_CLICK
      )
    ]
  }

  if (!containsData) {
    const noSeriesData = getDefaultSeriesData()
    const noDataCategories = getDefaultNoDataDates(labelDateFormat)

    return {
      chartOptions: {
        ...defaultChartOptions,
        xAxis: {
          ...defaultChartOptions.xAxis,
          categories: noDataCategories
        },
        series: defaultChartOptions.series!.map(seriesItem => ({
          ...seriesItem,
          data: noSeriesData[seriesItem.name as RateTableUsageType]
        })) as Highcharts.SeriesOptionsType[]
      }
    }
  }

  const sortedData = [...rateDatas!].sort((a, b) =>
    a.period_start > b.period_start ? 1 : -1
  )
  const seriesData: Record<RateTableUsageType, number[]> = {
    [RateTableUsageType.IMPRESSIONS]: sortedData.map(
      rate => rate.impressions || 0
    ),
    [RateTableUsageType.UPDATE_RATES_CLICK]: sortedData.map(
      rate => rate.update_rate_click || 0
    ),
    [RateTableUsageType.GET_STARTED_CLICK]: sortedData.map(
      rate => rate.get_started_click || 0
    ),
    [RateTableUsageType.PERSONALIZED_RATES_CLICK]: sortedData.map(
      rate => rate.personalized_rate_click || 0
    ),
    [RateTableUsageType.GET_RATE_ALERTS_CLICK]: sortedData.map(
      rate => rate.subscribe_switch_click || 0
    )
  }

  return {
    chartOptions: {
      ...defaultChartOptions,
      xAxis: {
        ...defaultChartOptions.xAxis,
        categories: sortedData.map(rate =>
          DateTime.fromFormat(rate.period_start, dateFormat).toFormat(
            labelDateFormat
          )
        )
      },
      series: defaultChartOptions.series!.map(seriesItem => ({
        ...seriesItem,
        data: seriesData[seriesItem.name as RateTableUsageType]
      })) as Highcharts.SeriesOptionsType[]
    },
    seriesData
  }
}
