import {
  SubscriptionEmailStats,
  TableFooterColumn,
  UnsubscribedStats
} from "../../types"

export const createTableFooterColumn = (
  id: string,
  value: string,
  colSpan?: number
): TableFooterColumn => {
  return {
    id,
    value,
    colSpan
  }
}

export const calculateTotal = (data: number[]): number => {
  return data.reduce((sum, item) => sum + item, 0)
}

export const calculateAverage = (data: number[]): string => {
  if (!data.length) return "0%"

  const total = calculateTotal(data) * 100
  return total > 0 ? `${(total / data.length).toFixed(2)}%` : "0%"
}

export const createColumnsForEmailStats = (
  data: SubscriptionEmailStats[],
  containsData: boolean | undefined
): TableFooterColumn[] => {
  return [
    createTableFooterColumn(
      "email-sent",
      containsData
        ? calculateTotal(data.map(item => item.email_sent))?.toLocaleString()
        : "-"
    ),
    createTableFooterColumn(
      "email-opened",
      containsData
        ? calculateTotal(data.map(item => item.email_opened))?.toLocaleString()
        : "-"
    ),
    createTableFooterColumn(
      "open-rate",
      containsData ? calculateAverage(data.map(item => item.open_rate)) : "-"
    ),
    createTableFooterColumn(
      "email-clicked",
      containsData
        ? calculateTotal(data.map(item => item.email_clicked))?.toLocaleString()
        : "-"
    )
  ]
}

export const createColumnsForUnsubscribedStats = (
  data: UnsubscribedStats[],
  containsData: boolean | undefined
): TableFooterColumn[] => {
  return [
    createTableFooterColumn(
      "unsubscribed",
      containsData
        ? calculateTotal(data.map(item => item.unsubscribed))?.toLocaleString()
        : "-"
    ),
    createTableFooterColumn(
      "stale-unsubscribed",
      containsData
        ? calculateTotal(
            data.map(item => item.stale_unsubscribed)
          )?.toLocaleString()
        : "-"
    )
  ]
}
