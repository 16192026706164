import { FocusEvent } from "react"
import { FieldArray } from "formik"
import { Plus, Trash2 } from "lucide-react"
import { LinkBtn } from "../buttons"
import If from "../if"
import Input from "./input"

/**
 * This is a wrapper for the formik FieldArray.
 * Usually should be kept as a low-level component and not used directly,
 * instead use a use custom wrapper component(Ex. src/common/ui/domain-array-input/index.tsx)
 */

type Props = {
  name: string
  label: string
  required?: boolean
  showLabel?: boolean
  helpText?: string
  fieldValue?: any[]
  beforeLabel?: string | JSX.Element
  placeholder?: string
  className?: string
  IDkey: string
  newItemTemplate?: any
  inputProps?: { [key: string]: any }
}

const ID_KEY_DEFAULT = "id"
const VALUE_DEFAULT: any[] = [""]
const REQUIRED_DEFAULT = true
const CLASSNAME_DEFAULT = ""

const ArrayInput = ({
  fieldValue = VALUE_DEFAULT,
  required = REQUIRED_DEFAULT,
  showLabel = REQUIRED_DEFAULT,
  name,
  label,
  beforeLabel,
  placeholder,
  helpText,
  IDkey = ID_KEY_DEFAULT,
  className = CLASSNAME_DEFAULT,
  inputProps = {},
  newItemTemplate = ""
}: Props) => (
  <div className={className}>
    <FieldArray name={name}>
      {({ remove, push }) => (
        <div>
          {fieldValue.length > 0 &&
            fieldValue.map((field, index) => (
              <Input
                inputProps={{
                  ...inputProps,
                  onBlur: (e: FocusEvent<HTMLInputElement>) => {
                    inputProps?.onBlur(e, field)
                  },
                  value: field[IDkey]
                }}
                showLabel={showLabel}
                key={index}
                className="pt-6"
                label={label}
                name={`${name}[${index}][${IDkey}]`}
                beforeLabel={beforeLabel}
                placeholder={placeholder}
                helpText={
                  index === fieldValue.length - 1 ? helpText : undefined
                }
                afterIcon={
                  <If
                    condition={!required || index !== 0}
                    then={
                      <button type="button" onClick={() => remove(index)}>
                        <span className="sr-only">delete {name}</span>
                        <Trash2 size="16" aria-hidden="true" />
                      </button>
                    }
                  />
                }
              />
            ))}

          <LinkBtn
            className="!text-base !px-0"
            theme="alt"
            onClick={() => push(newItemTemplate)}
          >
            <Plus size={20} /> Add more
          </LinkBtn>
        </div>
      )}
    </FieldArray>
  </div>
)

export default ArrayInput
