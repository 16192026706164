import { FC, useMemo } from "react"
import Input from "../../../../form/input"
import BBSwitch from "../../../../form/Switch"
import { Brand, RateFlowDetails } from "../../../../../../types"
import ReactJSONEditor from "../../../../react-json-editor"
import BrandAutocomplete from "../../../../brand-autocomplete"
import Select from "../../../../form/select"
import {
  RATEFLOW_ENGINE_CONFIGS,
  RATEFLOW_ENGINE_TEMPLATES
} from "../../../../../constants"

interface Props {
  values: RateFlowDetails
  brands: Brand[]
  setFormField: (name: string, value: any) => void
}

const ManageRateflowFormContent: FC<Props> = ({
  values,
  brands,
  setFormField
}) => {
  const selectedBrandId = useMemo(
    () => brands.find(b => b.id === values.bank_id)?.id,
    [brands, values.bank_id]
  )

  const selectedTemplate = useMemo(
    () =>
      RATEFLOW_ENGINE_TEMPLATES.find(
        ret => ret.value === values.settings.api_mode
      ),
    [values.settings.api_mode]
  )

  const handleSelectTemplate = (templateId: string) => {
    const selectedConfig = RATEFLOW_ENGINE_CONFIGS.find(
      rec => rec.api_mode === templateId
    )
    if (selectedConfig) {
      setFormField("settings", {
        ...values.settings,
        ...selectedConfig
      })
    }
  }

  return (
    <div className="py-6 flex flex-col h-full">
      <div className="grid grid-cols-2 gap-x-12">
        <div>
          <Input label="Name" name="name" />
        </div>
        <div className="pt-10">
          <BBSwitch
            label="Set as default"
            enabled={values.default === "1"}
            setEnabled={value => setFormField("default", value ? "1" : "0")}
          />
        </div>
        <div className="py-6">
          <BrandAutocomplete
            brands={brands}
            selected={selectedBrandId}
            onChange={val => setFormField("bank_id", val)}
            direction="vertical"
          />
        </div>
        <div className="py-6">
          <Select
            label="Settings template"
            data={RATEFLOW_ENGINE_TEMPLATES}
            selected={selectedTemplate}
            setSelected={handleSelectTemplate}
            IDkey="value"
          />
        </div>
      </div>
      <div className="pt-6 flex-1">
        <ReactJSONEditor
          name="settings"
          json={values.settings}
          onChangeJSON={val => setFormField("settings", val)}
        />
      </div>
    </div>
  )
}

export default ManageRateflowFormContent
