import { FC } from "react"
import { Activity } from "lucide-react"
import { LeadHistory } from "../../../../../../types"
import {
  convertLeadHistoryTypeToString,
  formatDateTimeFromTimestamp
} from "../../../../../utils"

interface Props {
  data: LeadHistory
  connectorVisible?: boolean
}

const LeadHistoryItem: FC<Props> = ({ data, connectorVisible = true }) => {
  return (
    <div className="py-3 flex items-center justify-between">
      <div className="flex items-center">
        <div className="relative w-12 h-12 flex items-center justify-center rounded-full bg-neutral-500">
          <Activity color="#fff" />
          {connectorVisible && (
            <div className="absolute top-12 left-6 mt-1 -ml-[1px] w-0 h-4 border-2 border-neutral-400"></div>
          )}
        </div>
        <div className="px-4">{convertLeadHistoryTypeToString(data)}</div>
      </div>
      <div className="text-neutral-400">
        {formatDateTimeFromTimestamp(
          data.timestamp,
          "LL/dd/yyyy 'at' hh:mm:ss a"
        )}
      </div>
    </div>
  )
}

export default LeadHistoryItem
