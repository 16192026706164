import { FC } from "react"
import { Row } from "@tanstack/react-table"
import { GripVertical } from "lucide-react"
import { PricingRate } from "../../../../../types"
import {
  formatAsTwoDigits,
  formatCurrency,
  formatDecimals
} from "../../../../utils"
import { useFindRateflowLogByIdQuery } from "../../../../../redux/queries"

interface Props extends Row<PricingRate> {
  onClick: (id: string) => void
}

const RateCard: FC<Props> = ({
  index,
  original: {
    apr,
    rate,
    label,
    list_price,
    loan_ammount,
    price,
    term,
    rateflow_log_id
  },
  onClick
}) => {
  const { data: logDetails } = useFindRateflowLogByIdQuery(
    rateflow_log_id.toString(),
    {
      skip: !rateflow_log_id
    }
  )

  return (
    <div className="overflow-hidden">
      <div
        className="p-4 bg-neutral-50 cursor-pointer"
        onClick={() => onClick(rateflow_log_id.toString())}
      >
        <div className="flex items-center">
          <GripVertical className="text-neutral-400 -ml-2" />
          <div className="text-2xl font-extrabold ml-2">
            {formatAsTwoDigits(index + 1)}
          </div>
        </div>
        <div className="font-medium text-left pt-2">{label}</div>
      </div>
      <div className="p-4 bg-white grid grid-cols-3 gap-2 text-left text-sm text-neutral-400">
        <div>
          <div className="font-medium">Rate</div>
          <div className="text-neutral-900">{rate}%</div>
        </div>
        <div>
          <div className="font-medium">APR</div>
          <div className="text-neutral-900">{apr}%</div>
        </div>
        <div>
          <div className="font-medium">Price</div>
          <div className="text-neutral-900">{formatDecimals(price, 3)}</div>
        </div>
        <div className="text-neutral-400">
          <div>Loan</div>
          <div className="pt-2">List Price</div>
          <div className="pt-2">Term</div>
          <div className="pt-2">Investor</div>
        </div>
        <div className="col-span-2 text-neutral-900">
          <div>{loan_ammount ? formatCurrency(loan_ammount) : "N/A"}</div>
          <div className="pt-2">{formatCurrency(list_price)}</div>
          <div className="pt-2">{term / 12} Years</div>
          <div className="pt-2">{logDetails?.cards?.[0].investor ?? "N/A"}</div>
        </div>
      </div>
    </div>
  )
}

export default RateCard
