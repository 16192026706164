import { Link } from "react-router-dom"
import logo from "../../../assets/logo.svg"
import logoFull from "../../../assets/logo-full.svg"

interface Props {
  isFull?: boolean
  className?: string
  to?: string
}

const Logo = ({ isFull = true, className = "", to = "/" }: Props) => (
  <Link to={to}>
    <img
      src={isFull ? logoFull : logo}
      className={className}
      alt="BankingBridge logo"
    />
  </Link>
)

export default Logo
