import { FC, useMemo, useState } from "react"
import { Plus } from "lucide-react"
import { SearchBar } from "../../table/toolbar"
import SimpleSorter from "../../simple-sorter"
import If from "../../if"
import BrandsCardSkeleton from "../../skeletons/normal-card"
import { Brand, SortDirection } from "../../../../types"
import BrandCard from "../card"
import { useNavigate } from "react-router-dom"
import ManageBrand from "../manage"
import BBDialog from "../../dialog"
import {
  useDeleteBrandMutation,
  useFetchBrandQuery
} from "../../../../redux/queries"
import { PrimaryBtn } from "../../buttons"
import { useAppDispatch } from "../../../hooks"
import { setVisiblePanel } from "../../../../redux/slices/app"

interface Props {
  data: Brand[]
  isLoading: boolean
  hasCreateButton?: boolean
  rootPage?: string
}

const BrandsGrid: FC<Props> = ({
  data,
  isLoading,
  hasCreateButton,
  rootPage = "/brands"
}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [deleteBrand] = useDeleteBrandMutation()

  const [sortDir, setSortDir] = useState<SortDirection>("asc")
  const [filter, setFilter] = useState<string>("")
  const [selectedBrand, setSelectedBrand] = useState<Brand>()
  const [brandToDelete, setBrandToDelete] = useState<string>()

  const { data: selectedBrandDetails } = useFetchBrandQuery(
    selectedBrand?.id ?? "",
    {
      skip: !selectedBrand?.id
    }
  )

  // Sort & filter brands
  const sortedBrands = useMemo(
    () =>
      [...data]
        .sort((a, b) =>
          (sortDir === "asc" ? a.name > b.name : a.name < b.name) ? 1 : -1
        )
        .filter(
          item =>
            item.name.toLowerCase().includes((filter ?? "").toLowerCase()) ||
            item.id
              .toString()
              .toLowerCase()
              .includes((filter ?? "").toLowerCase())
        ),
    [data, filter, sortDir]
  )

  const onSearch = (value: string | number) => {
    setFilter(value.toString())
  }

  const onSort = (newDir: SortDirection) => {
    setSortDir(newDir)
  }

  const onClickCard = (brandId: string) => {
    navigate(`${rootPage}/${brandId}`)
  }

  const resetSelectedBrand = () => {
    setSelectedBrand(undefined)
  }

  const clearBrandToDelete = () => setBrandToDelete(undefined)

  const onClickEdit = (brandId: string) => {
    setSelectedBrand(data.find(b => b.id === brandId))
  }

  const onClickDelete = (brandId: string) => {
    setBrandToDelete(brandId)
  }

  const handleDelete = () => {
    if (brandToDelete) {
      deleteBrand(brandToDelete)
      clearBrandToDelete()
    }
  }

  const openCreatePanel = () => {
    dispatch(setVisiblePanel("brand/new"))
  }

  return (
    <div className="">
      <div className="px-6 bg-white flex items-center justify-between">
        <SearchBar
          className="py-4 lg:flex w-full"
          globalFilter={filter}
          onSearch={onSearch}
          searchPlaceholder="Search Brands"
        >
          <div
            className={`w-full flex items-center justify-between mt-2 lg:mt-0`}
          >
            <SimpleSorter sortDir={sortDir} changeSort={onSort} />
            {hasCreateButton && (
              <PrimaryBtn className="ml-auto" onClick={openCreatePanel}>
                <Plus size={16} />
                &nbsp;New Brand
              </PrimaryBtn>
            )}
          </div>
        </SearchBar>
      </div>
      <div className="px-6 py-2">
        <If
          condition={isLoading}
          then={<BrandsCardSkeleton />}
          else={
            <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pt-4">
              {sortedBrands.length ? (
                sortedBrands.map(brand => (
                  <li
                    key={brand.id}
                    className="col-span-1 flex flex-col bg-white rounded-lg shadow divide-y divide-gray-200"
                  >
                    <BrandCard
                      data={brand}
                      onClick={onClickCard}
                      onClickEdit={onClickEdit}
                      onClickDelete={onClickDelete}
                    />
                  </li>
                ))
              ) : (
                <div>No brands found.</div>
              )}
            </ul>
          }
        />
      </div>

      <ManageBrand
        open={!!selectedBrand}
        data={selectedBrandDetails}
        onClose={resetSelectedBrand}
        isInSidePanel
      />

      <BBDialog
        open={!!brandToDelete}
        onClose={clearBrandToDelete}
        title="Delete brand"
        body="By doing this all the information stored in the system about this brand will be deleted. Are you sure?"
        okLabel="Yes, Delete"
        cancelLabel="Cancel"
        onOk={handleDelete}
        onCancel={clearBrandToDelete}
      />
    </div>
  )
}

export default BrandsGrid
