import { createApi } from "@reduxjs/toolkit/dist/query/react"
import {
  FIND_OB_LO_ENDPOINT,
  LO_ENDPOINT,
  LO_REVIEWS_ENDPOINT,
  RTK_SLICE_API_LIST
} from "../../common/constants"
import {
  AllReviews,
  LoanOfficer,
  GetLoanOfficerDetailsResponse
} from "../../types"
import { baseQuery } from "../api"
import bbApi from "../../api"
import { getQueryString } from "../../common/utils"
import { TagDescription } from "@reduxjs/toolkit/dist/query/endpointDefinitions"

const reducerPath = "loan-officer"

export const loanOfficerApi = createApi({
  reducerPath,
  baseQuery,
  tagTypes: [reducerPath],
  endpoints: builder => ({
    fetchLoanOfficer: builder.query({
      query: (id: string) => `${LO_ENDPOINT}${getQueryString({ loid: id })}`,
      transformResponse: (responseData: GetLoanOfficerDetailsResponse) =>
        responseData?.lo,
      providesTags: (result, error, arg) => [{ type: reducerPath, id: arg }]
    }),
    fetchLoanOfficers: builder.query({
      query: ({
        isSuperAdmin,
        setPaginationDefault = true,
        ...params
      }: {
        isSuperAdmin?: boolean
        org_id?: string
        brand_id?: string
        nmls?: string
        email?: string
        limit?: number
        offset?: number
        setPaginationDefault?: boolean
      }) => {
        let { limit, offset } = params
        if (setPaginationDefault) {
          limit = limit ?? 999
          offset = offset ?? 0
        } else if (isSuperAdmin) {
          offset = offset ?? 0
        }

        const queryParams = isSuperAdmin
          ? { limit, offset }
          : { ...params, limit, offset }
        return `${LO_ENDPOINT}${getQueryString(queryParams)}`
      },
      transformResponse: (responseData: GetLoanOfficerDetailsResponse) => ({
        los: responseData?.lo ? [responseData.lo] : responseData?.los || [],
        total: responseData.total
      }),
      providesTags: result => {
        if (!result) {
          return [{ type: reducerPath, id: RTK_SLICE_API_LIST }]
        }
        const tags: TagDescription<"loan-officer">[] = result?.los.map(
          ({ loid }) => ({
            type: reducerPath,
            id: loid
          })
        )
        tags.push({ type: reducerPath, id: RTK_SLICE_API_LIST })
        return tags
      }
    }),
    // Search LOs: e.g. /api/v1/members?fullname=Jack El
    searchLoanOfficer: builder.query({
      query: (params: { [key: string]: string | number | boolean }) =>
        `${LO_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: GetLoanOfficerDetailsResponse) =>
        responseData?.lo ? [responseData.lo] : responseData?.los || []
    }),
    fetchLoanOfficerReviews: builder.query({
      query: (loId: string) => `${LO_REVIEWS_ENDPOINT}?id=${loId}`,
      transformResponse: (responseData: AllReviews) => responseData || []
    }),
    createLoanOfficer: builder.mutation({
      queryFn: async (data: LoanOfficer) => {
        const response = await bbApi.loanOfficer.createOrUpdate(data)
        return { data: response }
      },
      invalidatesTags: [{ type: reducerPath, id: RTK_SLICE_API_LIST }]
    }),
    updateLoanOfficer: builder.mutation({
      queryFn: async (data: LoanOfficer) => {
        if (data?.picture === "") {
          data.picture = "delete"
        }
        const response = await bbApi.loanOfficer.createOrUpdate(data)
        return { data: response }
      },
      invalidatesTags: (result, error, { loid }) => [
        { type: reducerPath, id: loid },
        { type: reducerPath, id: RTK_SLICE_API_LIST }
      ]
    }),
    deleteLoanOfficer: builder.mutation({
      queryFn: async (loid: string) => {
        const response = await bbApi.loanOfficer.delete(loid)
        return { data: response }
      },
      invalidatesTags: [{ type: reducerPath, id: RTK_SLICE_API_LIST }]
    }),
    importCsv: builder.mutation({
      queryFn: async (file: File) => {
        const response = await bbApi.loanOfficer.importCsv(file)
        return { data: response }
      },
      invalidatesTags: [{ type: reducerPath, id: RTK_SLICE_API_LIST }]
    }),
    importLoMember: builder.mutation({
      queryFn: async (payload: { brand_id: string; row: any }) => {
        const response = await bbApi.loanOfficer.importMember(payload)
        return { data: response }
      },
      invalidatesTags: [{ type: reducerPath, id: RTK_SLICE_API_LIST }]
    }),
    findObLoanOfficer: builder.query({
      query: (params: any) => `${FIND_OB_LO_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: any) => responseData
    })
  })
})

export const {
  useFetchLoanOfficerQuery,
  useFetchLoanOfficersQuery,
  useLazyFetchLoanOfficersQuery,
  useFetchLoanOfficerReviewsQuery,
  useCreateLoanOfficerMutation,
  useUpdateLoanOfficerMutation,
  useDeleteLoanOfficerMutation,
  useImportCsvMutation,
  useImportLoMemberMutation,
  useFindObLoanOfficerQuery,
  useSearchLoanOfficerQuery,
  useLazySearchLoanOfficerQuery,
  endpoints
} = loanOfficerApi
