/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react"
import { useFindWorklowsQuery } from "../../../../../redux/queries"
import { Integration, Workflow } from "../../../../../types"
import { useIntegrationSettingsForm } from "../../../../hooks"
import Form from "../../../form"

interface Props {
  integration?: Integration
}

const LeadWorkflowSelector: FC<Props> = ({ integration }) => {
  // Use the integration to get the brand ID
  const { app_settings, saveAppSetting } =
    useIntegrationSettingsForm(integration)

  const [selected, setSelectedWorflow] = useState<Workflow>() // the workflow object is used by the dropdown
  const [selectedID, setSelectedWorflowID] = useState<number | string>(
    app_settings?.lead_workflow ?? 0
  )

  // Get all the workflows from a brand
  const { data: workflows = [], isLoading } = useFindWorklowsQuery(
    integration?.brand_id as string
  )

  const setSelected = (workflowID: string) => {
    if (workflowID !== selectedID) {
      setSelectedWorflowID(workflowID) // set the state
      saveAppSetting({ lead_workflow: workflowID }) // save the setting to the server
    }
  }

  useEffect(() => {
    // get selected workflow from workflows by id
    const selectedWorkflow = workflows?.find(
      workflow => workflow.id === selectedID + "" // TODO: this should be always number
    )
    setSelectedWorflow(selectedWorkflow)
  }, [selectedID, workflows])

  return (
    <div className="flex items-center pl-9">
      <Form.InlineSelect
        isLoading={isLoading}
        data={workflows}
        setSelected={setSelected}
        selected={selected}
        label="Choose a workflow"
      />
      <a href="#" className="text-primary-alt-400 inline-block ml-3">
        Edit
      </a>
    </div>
  )
}

export default LeadWorkflowSelector
