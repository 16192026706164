import RowOptions from "../../../table/row-options"
import { Pencil, ClipboardList } from "lucide-react"
import { Organization } from "../../../../../types"

interface Props {
  info: Organization
  onEdit: (org: Organization) => void
  onDelete: (id: string) => void
}

const MenuCell: React.FC<Props> = ({ info, onEdit, onDelete }) => (
  <td className="relative whitespace-nowrap py-4 px-0 text-right text-sm font-medium sm:pr-6 pl-3 md: pr-4">
    <RowOptions
      actions={[
        { label: "Edit", icon: Pencil, onClick: () => onEdit(info) },
        {
          label: "Billing (coming soon)",
          icon: ClipboardList,
          onClick: () => true
        }
      ]}
      deleteAction={() => onDelete(info.id as string)}
    />
  </td>
)

export default MenuCell
