import { FC } from "react"
import { RefreshCw } from "lucide-react"
import { BBColumnDef, OrgActivity } from "../../../../types"
import Table from "../../table"
import TimestampCell from "./cells/timestamp"
import DescriptionCell from "./cells/description"
import If from "../../if"

interface Props {
  data?: OrgActivity[]
  refetch: () => void
  onClick: (id: string) => void
}

const DashboardActivityTable: FC<Props> = ({ data, refetch, onClick }) => {
  const columns: BBColumnDef<OrgActivity>[] = [
    {
      id: "description",
      header: "Description",
      accessorFn: row => row.event_id,
      footer: props => props.column.id,
      cell: props => (
        <DescriptionCell info={props.row.original} onClick={onClick} />
      )
    },
    {
      id: "timestamp",
      header: "Timestamp",
      accessorFn: row => row.timestamp,
      footer: props => props.column.id,
      cell: props => <TimestampCell info={props.row.original} />
    }
  ]

  return (
    <div className="bg-white flex flex-col basis-2/4 rounded-md pl-6 pr-2">
      <div className="text-base font-semibold pt-6">My activity</div>
      <div className="flex-1 pt-3">
        <If
          condition={!!data?.length}
          then={
            <>
              <div className="pr-6 bg-white max-h-96 overflow-auto">
                <Table
                  data={data!}
                  columns={columns}
                  viewType="table"
                  pageSize={9999}
                  dropdownFilterColumnId="type"
                  shrinkFilterSort
                  noHeader
                  searchBarVisible={false}
                />
              </div>

              <div
                className="flex items-center text-primary-400 font-medium cursor-pointer text-sm select-none py-4 border-t border-neutral-200"
                onClick={refetch}
              >
                <RefreshCw size={20} />
                <span className="ml-2">Refresh to load recent activity</span>
              </div>
            </>
          }
          else={
            <div className="h-full flex-1 flex items-center justify-center">
              No activities yet
            </div>
          }
        />
      </div>
    </div>
  )
}

export default DashboardActivityTable
