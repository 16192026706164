import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import bbApi from "../../api"
import {
  Integration,
  IntegrationStatuses,
  Monitor,
  MonitorCreatePost,
  MonitorUpdatePost,
  Domains,
  APIKeyRespose
} from "../../types"
import { RootState } from "../store"
import { pushToast } from "./notifications"

export interface IntState {
  isSubmitting: boolean
  monitorID?: string
  isLoading: boolean
  APIKey?: APIKeyRespose
  previewModal: {
    isOpen: boolean
    app_key: Integration["app_key"]
    title: string
  }
  integrationStatus?: IntegrationStatuses
}

const initialState: IntState = {
  isSubmitting: false,
  isLoading: false,
  previewModal: {
    isOpen: false,
    app_key: "",
    title: ""
  }
}

/**
 * Creates the monitor setup for the selected integration
 */
export const createMonitor = createAsyncThunk(
  "monitor/create",
  async (
    { monitor, appKey }: { monitor: Monitor; appKey: string },
    { getState }
  ) => {
    const monitorData: MonitorCreatePost = {
      app_key: appKey,
      config: monitor
    }
    const { id } = await bbApi.monitor.create(monitorData)

    return id
  }
)

/**
 * Updates the monitor values for the selected integration
 */
export const updateMonitor = createAsyncThunk(
  "monitor/update",
  async (
    { monitor, appKey }: { monitor: Monitor; appKey: string },
    { getState }
  ) => {
    const state = getState() as RootState
    const integrations = state.integrations
    const monitorData: MonitorUpdatePost = {
      app_key: appKey,
      config: monitor,
      id: integrations.monitorID!
    }
    const { id } = await bbApi.monitor.update(monitorData)

    return id
  }
)

/**
 * Fetches the API key
 */
export const fetchAPIKey = createAsyncThunk(
  "apikey/get",
  async (loid: string) => {
    const data = await bbApi.apiKey.get(loid)
    return data ? data : undefined
  }
)

/**
 * Generate the API key
 */
export const generateAPIKey = createAsyncThunk(
  "apikey/generate",
  async (loid: string) => {
    const generatedAPIKey = await bbApi.apiKey.generate(loid)

    return generatedAPIKey.result
  }
)

/**
 * Delete the API key
 */
export const deleteAPIKey = createAsyncThunk(
  "apikey/delete",
  async (id: string, thunkAPI) => {
    const deletedAPIKey = await bbApi.apiKey.delete(id)

    if (deletedAPIKey.status === "success") {
      thunkAPI.dispatch(
        pushToast({
          msg: "API Key deleted",
          theme: "success"
        })
      )
    }
    return deletedAPIKey
  }
)

/**
 * Delete integration from the backend and update the state
 */
export const deleteIntegrationDomain = createAsyncThunk(
  "integrations/deleteDomain",
  async ({ id, domains }: { id: string; domains: Domains }, thunkAPI) => {
    const data = await bbApi.integrations.deleteDomain(id)

    // If the domain is deleted, remove it from the domains array
    if (data.status === "success") {
      const prevDomains = [...(domains ?? [])]
      const index = prevDomains.findIndex(dmn => dmn?.id === id)
      if (index !== -1) {
        prevDomains.splice(index, 1)
      }

      return { domains: prevDomains }
    }
  }
)

const integrationsPendingActions = [
  createMonitor.pending.type,
  updateMonitor.pending.type,
  fetchAPIKey.pending.type,
  generateAPIKey.pending.type,
  deleteAPIKey.pending.type
]

const integrationsRejectedActions = [
  createMonitor.rejected.type,
  updateMonitor.rejected.type,
  fetchAPIKey.rejected.type,
  generateAPIKey.rejected.type,
  deleteAPIKey.rejected.type
]

const integrationsFulfilledActions = [
  createMonitor.fulfilled.type,
  updateMonitor.fulfilled.type,
  fetchAPIKey.fulfilled.type,
  generateAPIKey.fulfilled.type,
  deleteAPIKey.fulfilled.type
]

export const integrationsSlice = createSlice({
  name: "integrations",
  initialState,
  reducers: {
    setPreviewModal: (state, action) => {
      state.previewModal = action.payload
    },
    setIsSubmitting: (state, action) => {
      state.isSubmitting = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        action => integrationsPendingActions.includes(action.type),
        state => {
          state.isSubmitting = true
        }
      )
      .addMatcher(
        action => integrationsFulfilledActions.includes(action.type),
        (state, action) => {
          if (
            action.type === createMonitor.fulfilled.type ||
            action.type === updateMonitor.fulfilled.type
          ) {
            state.monitorID = action.payload
          } else if (
            action.type === fetchAPIKey.fulfilled.type ||
            action.type === generateAPIKey.fulfilled.type
          ) {
            state.APIKey = action.payload as APIKeyRespose
          } else if (action.type === deleteAPIKey.fulfilled.type) {
            state.APIKey = undefined
          }
          state.isSubmitting = false
        }
      )
      .addMatcher(
        action => integrationsRejectedActions.includes(action.type),
        state => {
          state.isSubmitting = false
        }
      )
  }
})

export const selectPreviewModal = (state: RootState) =>
  state.integrations.previewModal
export const selectAPIKey = (state: RootState) => state.integrations.APIKey
export const selectIntegrationStatus = (state: RootState) =>
  state.integrations?.integrationStatus ?? []
export const selectIsSubmitting = (state: RootState) =>
  state.integrations.isSubmitting
export const selectMonitorID = (state: RootState) =>
  state.integrations.monitorID || 0

export const { setPreviewModal, setIsSubmitting } = integrationsSlice.actions

export default integrationsSlice.reducer
