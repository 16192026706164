import { FC, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { DateTime } from "luxon"
import { PlayCircle } from "lucide-react"

import Table from "../../table"
import BrandCell from "./cells/brand"
import LeadsCell from "./cells/leads"
import OwnerCell from "./cells/owner"
import MenuCell from "./cells/menu"
import { LinkBtn } from "../../buttons"
import PushToEngagements from "../push-to-engagements"
import HowItWorksModal from "../../how-it-works-modal"
import {
  useFetchLeadLogsQuery,
  useUpdateSubscriptionMutation
} from "../../../../redux/queries"
import ButtonGroup from "../../button-group"
import { useAppDispatch, useAppSelector } from "../../../hooks"
import {
  selectTabRange,
  updateRange
} from "../../../../redux/slices/engagements"
import { convertSubscriptionDetailsToPayload } from "../../../utils"
import {
  BBColumnDef,
  SubscriptionDetails,
  LeadLog,
  EngagementsTab,
  DateRange
} from "../../../../types"

interface Props {
  loid: string | undefined
  orgId?: string
  isSuperAdmin?: boolean
}

const LeadsTable: FC<Props> = ({ loid, orgId, isSuperAdmin }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [updateSubscription] = useUpdateSubscriptionMutation()

  const [selectedLeadLog, setSelectedLeadLog] = useState<LeadLog>()
  const [showHowItWorksModal, setShowHowItWorksModal] = useState(false)

  const handleClick = (id: string) => navigate(`/leads/${id}`)

  const handleEnrollToRateAlerts = (subscription?: SubscriptionDetails) => {
    if (!subscription) return

    updateSubscription({
      ...convertSubscriptionDetailsToPayload(subscription),
      id: subscription.id,
      active: subscription.active === "1" ? "0" : "1"
    })
  }

  const handleCreateEngagement = (leadLog: LeadLog) => {
    setSelectedLeadLog(leadLog)
  }

  const cancelCreateEngagement = () => setSelectedLeadLog(undefined)

  const openLearnVideo = () => setShowHowItWorksModal(true)

  const columns: BBColumnDef<LeadLog>[] = [
    {
      id: "leads",
      header: "Leads",
      accessorFn: row => `${row.name} ${row.email}`,
      footer: props => props.column.id,
      cell: props => (
        <LeadsCell info={props.row.original} onClick={handleClick} />
      )
    },
    {
      id: "brand",
      header: "Brand",
      accessorFn: row => row.brand_name,
      footer: props => props.column.id,
      cell: props => (
        <BrandCell info={props.row.original} onClick={handleClick} />
      ),
      meta: { headerClassName: "hidden md:table-cell" }
    },
    {
      id: "owner",
      header: "Owner",
      accessorFn: row => row.owner_email,
      footer: props => props.column.id,
      cell: props => (
        <OwnerCell info={props.row.original} onClick={handleClick} />
      ),
      meta: { headerClassName: "hidden md:table-cell" }
    },
    {
      id: "timestamp",
      header: "",
      accessorFn: row => row.timestamp,
      footer: props => props.column.id,
      cell: props => null,
      meta: { headerClassName: "hidden" }
    },
    {
      id: "edit",
      header: "",
      footer: props => props.column.id,
      enableSorting: false,
      cell: props => (
        <MenuCell
          info={props.row.original}
          onPushToEngagement={handleCreateEngagement}
          onEnrollToRateAlerts={handleEnrollToRateAlerts}
        />
      )
    }
  ]

  const tabKey = EngagementsTab.leads
  const [range, setRange] = useState<DateRange>(
    useAppSelector(state => selectTabRange(state, tabKey))
  )
  const handleUpdateRange = (newValue: DateRange) => {
    dispatch(updateRange({ tabKey, newValue }))
    setRange(newValue)
  }
  const FILTER_BUTTONS = [
    {
      key: 1000,
      label: "All time",
      shortLabel: "All",
      disabled: false,
      onClick: () => handleUpdateRange(1000)
    },
    {
      key: 30,
      label: "30 Days",
      shortLabel: "30 D",
      disabled: false,
      onClick: () => handleUpdateRange(30)
    },
    {
      key: 7,
      label: "7 Days",
      shortLabel: "7 D",
      disabled: false,
      onClick: () => handleUpdateRange(7)
    },
    {
      key: 1,
      label: "24 hours",
      shortLabel: "24 H",
      disabled: false,
      onClick: () => handleUpdateRange(1)
    }
  ]

  const minCreated = useMemo(
    () => DateTime.now().endOf("day").minus({ days: range }),
    [range]
  )

  // lead logs
  const { data: leadLogs, isFetching } = useFetchLeadLogsQuery({
    limit: 99999,
    offset: 0,
    min_created: Math.floor(minCreated.toSeconds()),
    owner: loid,
    org_id: orgId,
    isSuperAdmin
  })

  return (
    <div>
      <Table
        isLoading={isFetching}
        data={leadLogs ?? []}
        columns={columns}
        viewType="table"
        dropdownFilterColumnId="leads"
        searchPlaceholder="Search website leads..."
        extraHeaderElement={
          <div className="ml-auto">
            <ButtonGroup buttons={FILTER_BUTTONS} active={range} />
          </div>
        }
        shrinkFilterSort
        defaultSortBy="timestamp"
        defaultSortDir="desc"
        extraElementBeforeTable={
          <div className="px-2">
            <LinkBtn className="-my-1" onClick={openLearnVideo}>
              <PlayCircle size={20} />
              <span className="ml-2">Learn how it works</span>
            </LinkBtn>
          </div>
        }
      />
      <PushToEngagements
        brandId={selectedLeadLog?.group_id}
        leadId={selectedLeadLog?.id}
        onCancel={cancelCreateEngagement}
      />

      <HowItWorksModal
        videoType="leads"
        show={showHowItWorksModal}
        toogleModal={setShowHowItWorksModal}
      />
    </div>
  )
}

export default LeadsTable
