import { FunnelStage } from "../../types"

export const createPolygonBetweenFunnels = (
  currentElementWidth: number,
  nextElementWidth: number
): string => {
  const topLeft = (100 - currentElementWidth) / 2
  const topRight = 100 - topLeft
  const bottomLeft = (100 - nextElementWidth) / 2
  const bottomRight = 100 - bottomLeft
  return `polygon(${topLeft}% 0%, ${topRight}% 0%, ${bottomRight}% 100%, ${bottomLeft}% 100%)`
}

export const createFunnelStage = (
  label: string,
  value: number,
  percentage: number,
  clipPath: string | undefined,
  color: string = "#786FF6"
): FunnelStage => {
  return {
    label,
    value,
    percentage,
    color,
    clipPath
  }
}
