import RowOptions from "../../../table/row-options"
import { Inspect, Pencil } from "lucide-react"
import { LoanOfficer } from "../../../../../types"

interface Props {
  onMasquerade: (loid: string) => void
  onEdit: (loanOfficer: LoanOfficer) => void
  onDelete: (loid: string) => void
  info: LoanOfficer
  masqueradeEnabled: boolean
}

const MenuCell: React.FC<Props> = ({
  onDelete,
  onEdit,
  onMasquerade,
  info,
  masqueradeEnabled
}) => (
  <td className="relative whitespace-nowrap py-4 px-0 text-right text-sm font-medium sm:pr-6 pl-3 md: pr-4">
    <RowOptions
      actions={[
        masqueradeEnabled
          ? {
              label: "Masquerade",
              icon: Inspect,
              onClick: () => onMasquerade(info?.uid as string)
            }
          : null,
        { label: "Edit", icon: Pencil, onClick: () => onEdit(info) }
      ]}
      deleteAction={() => onDelete(info?.uid as string)}
    />
  </td>
)

export default MenuCell
