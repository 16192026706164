interface Props {
  src: string
  alt: string
  size?: number | "full"
  className?: string
}

const RoundedImg = ({ src, alt, className, size = "full" }: Props) => (
  <img
    src={src}
    alt={alt}
    className={`rounded-full object-cover w-${size} h-${size} ${className}`}
  />
)

export default RoundedImg
