import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { APIError, ToastType, AlertType } from "../../types"
import { RootState } from "../store"
import { capitalize } from "../../common/utils"

interface Message {
  text: string
  type: AlertType
}

interface NotificationsState {
  toasts: ToastType[]
  message: Message
}

const initialState: NotificationsState = {
  toasts: [],
  message: {
    text: "",
    type: "info"
  }
}

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    pushToast: (state, action: PayloadAction<ToastType>) => {
      // check if same toast is already in the list
      if (state.toasts.find(t => t.msg === action.payload.msg)) {
        return
      }

      const toasts = state.toasts.concat(action.payload)
      return { ...state, toasts }
    },
    deleteToast: (state, action) => {
      const toasts = state.toasts
      return {
        ...state,
        toasts: [
          ...toasts.slice(0, action.payload),
          ...toasts.slice(action.payload + 1)
        ]
      }
    },
    setApiError: (state, action: PayloadAction<APIError>) => {
      state.message = {
        text: action.payload.message ?? "Something went wrong",
        type: "danger"
      }
    },
    setMessage: (state, action: PayloadAction<Message>) => {
      const { text, type } = action.payload
      state.message = { text: capitalize(text), type }
    },
    clearMessage: state => {
      state.message = { text: "", type: "info" }
    }
  }
})

const { reducer, actions } = notificationsSlice

export const { pushToast, deleteToast, setApiError, setMessage, clearMessage } =
  actions

export const selectToast = (state: RootState) => state.notifications.toasts
export const selectMessage = (state: RootState) => state.notifications.message

export default reducer
