import { FC, memo } from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import highChartsMap from "highcharts/modules/map"

import Skeleton from "./base"
import { getMapChartDefaultOptions } from "../chart-with-stats/options"

highChartsMap(Highcharts)

interface MapListProps {
  noOfRows?: number
  noOfSmallColumns?: number
  className?: string
}

const MapList: FC<MapListProps> = ({
  noOfRows = 3,
  noOfSmallColumns = 3,
  className = ""
}) => {
  const firstIndex = 0

  return (
    <div className={`bg-white ${className}`}>
      <Skeleton className="h-full">
        <div className="grid grid-cols-7 lg:gap-x-16">
          <div className="col-span-7 lg:col-span-3 mb-6 lg:mb-0">
            <HighchartsReact
              highcharts={Highcharts}
              constructorType={"mapChart"}
              options={getMapChartDefaultOptions()}
              containerProps={{ className: "h-[275px] lg:h-auto" }}
            />
          </div>
          <div className="col-span-7 lg:col-span-4 flex">
            <div className="flex-1 content-center">
              {[...Array(noOfRows)].map((_, index) => (
                <div
                  key={`map-list-row-${index}`}
                  className="bg-white border-b border-gray-300"
                >
                  <div className="flex w-full px-0 pt-7 pb-6 gap-8 md:gap-12">
                    <div className="w-2/3 md:w-2/5">
                      <div className="h-4 bg-gray-200 rounded-full w-4/6 mb-1.5"></div>
                      <div className="h-4 bg-gray-100 rounded-full w-full"></div>
                    </div>
                    <div className="flex flex-row-reverse md:flex-row w-1/3 md:w-3/5 md:gap-8">
                      {[...Array(noOfSmallColumns)].map((_, index) => (
                        <div
                          key={`map-list-small-col-${index}`}
                          className={
                            index === firstIndex
                              ? "w-full md:w-1/3"
                              : "w-1/3 hidden md:block"
                          }
                        >
                          <div className="h-4 bg-gray-200 rounded-full w-full mb-1.5"></div>
                          <div className="h-4 bg-gray-100 rounded-full w-3/4"></div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Skeleton>
    </div>
  )
}

export default memo(MapList)
