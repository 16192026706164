import { FC } from "react"
import { formatDateTimeFromTimestamp } from "../../../../utils"

type Props = {
  info: any
}

const TimestampCell: FC<Props> = ({ info }) => {
  return (
    <td className="hidden md:table-cell py-2 px-3 text-neutral-400 text-right">
      {formatDateTimeFromTimestamp(info.timestamp, "LLL dd 'at' hh:mm a")}
    </td>
  )
}

export default TimestampCell
