import { Fragment } from "react"

import { Badge } from "../../../../ui"
import { useInMobileView } from "../../../../hooks"
import {
  DEFAULT_DATETIME_LONG_FORMAT,
  formatDateTimeFromTimestamp
} from "../../../../utils"
import { LeadLog } from "../../../../../types"

type Props = {
  info: LeadLog
  onClick: (id: string) => void
}

const LeadsCell = ({ info, onClick }: Props) => {
  const {
    name = "Unnamed",
    email,
    resthook_status,
    id,
    timestamp,
    owner_email
  } = info
  const inMobileView = useInMobileView()

  return (
    <td
      className="whitespace-nowrap py-4 pl-2 pr-3 cursor-pointer"
      colSpan={inMobileView ? 2 : 1}
    >
      {inMobileView ? (
        <Fragment>
          <div className="text-sm text-gray-400 pb-4">
            {formatDateTimeFromTimestamp(
              timestamp,
              DEFAULT_DATETIME_LONG_FORMAT
            )}
          </div>
          <div className="flex flex-col">
            <div className="font-bold break-words">{email}</div>
            <div className="flex items-center">
              <span
                className="text-sm text-gray-400 mr-2 hover:border-b border-neutral-500 leading-none"
                onClick={() => onClick(id)}
              >
                {name}
              </span>
              {resthook_status && <Badge.Info>{resthook_status}</Badge.Info>}
            </div>
            <div className="text-sm pt-1">by {owner_email}</div>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className="flex items-center">
            <div
              className="font-bold mr-2 hover:border-b border-neutral-500 leading-none"
              onClick={() => onClick(id)}
            >
              {name}
            </div>
            {resthook_status && <Badge.Info>{resthook_status}</Badge.Info>}
          </div>
          <div className="text-neutral-400 text-sm">{email}</div>
        </Fragment>
      )}
    </td>
  )
}

export default LeadsCell
