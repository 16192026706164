/**
  Integrations API wrapper

	JSON endpoint: /integration-manage.json
	GET /api/v1/integrations?brand_id={brand_id} - returns a list of brands integrations
	POST /api/v1/integrations - creates/updates a integrations
      id => int
      title => string
      url => string
      type => string
      accent_color => string
      secondary_accent_color => string
      ga_tag => string
      mort_calc => 0/1
      afford_calc => 0/1
      lead_workflow => int
      contactForm => int
      pricing => 0/1
      pricing_set_id => int
      lo_reviews => 0/1
      brand_reviews => 0/1
      rate_table => 0/1
      about_boxes => 0/1
      disable_loan_calc_cards => 0/1
      owner => int
      post_url => string
      utm_tracking => 0/1
 
  GET /api/v1/embed-requests?integration_id=<id>&quick_status=true - return the integration status
**/

import { api } from "../common/utils"
import {
  ENDPOINT_DELETE_DOMAIN,
  ENDPOINT_INTEGRATIONS,
  ENDPOINT_STATUS
} from "../common/constants"
import {
  Integration,
  RootIntegrations,
  IntegrationStatusValues,
  IntegrationResponse,
  IntegrationStatuses,
  IntegrationPost,
  IntegrationSaveResponse,
  APIError
} from "../types"

const integrations = {
  list: async (brandsIDs: string): Promise<RootIntegrations> => {
    const { data } = await api.get<RootIntegrations>(
      `${ENDPOINT_INTEGRATIONS}?brand_id=${brandsIDs}&limit=9999`
    )
    return data
  },
  status: async (id: string): Promise<IntegrationStatusValues> => {
    const { data } = await api.get<IntegrationStatusValues>(
      `${ENDPOINT_STATUS}?integration_id=${id}&quick_status=true`
    )
    return data
  },
  find: async (params: any): Promise<Integration> => {
    const { data } = await api.get(ENDPOINT_INTEGRATIONS, params)
    return data
  },
  get: async (integrationID: string): Promise<Integration> => {
    const { data } = await api.get<IntegrationResponse>(
      `${ENDPOINT_INTEGRATIONS}?id=${integrationID}&full_detail=true`
    )
    return data.results[0]
  },
  save: async (
    integration: IntegrationPost
  ): Promise<IntegrationSaveResponse | APIError> => {
    const { data } = await api.post(ENDPOINT_INTEGRATIONS, integration)
    return data
  },
  getStatus: async (
    integrationID: string,
    extraParams?: string,
    limit: number = 25
  ): Promise<IntegrationStatuses> => {
    const { data } = await api.get<IntegrationStatuses>(
      `${ENDPOINT_STATUS}?integration_id=${integrationID}&limit=${limit}${
        extraParams ? `&${extraParams}` : ""
      }`
    )
    return data
  },
  update: async (updateData: any): Promise<any> => {
    if (!updateData.id) {
      throw new Error("Integration id is missing")
    }
    const { data } = await api.post(ENDPOINT_INTEGRATIONS, updateData)
    return data
  },
  delete: async (id: string): Promise<any> => {
    const { data } = await api.delete(`${ENDPOINT_INTEGRATIONS}?id=${id}`)
    return data
  },
  deleteDomain: async (id: string): Promise<any> => {
    if (!id) {
      throw new Error("Domain id is missing")
    }
    const { data } = await api.delete(`${ENDPOINT_DELETE_DOMAIN}?id=${id}`)
    return data
  }
}

export default integrations
