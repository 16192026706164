import { FC, useEffect, useMemo, useState } from "react"
import { selectUserDetails } from "../../../../redux/slices/auth"
import {
  Integration,
  UserDetails,
  BBColumnDef,
  ViewType
} from "../../../../types"
import { useAppSelector, useInMobileView } from "../../../hooks"
import { TH, Table } from "../../../ui"
import Card from "./card"
import { IntegrationCell } from "./cells"
import { EmptyStateProps } from "../../empty-state"
import { ToggleView } from "../../table/toolbar"
import Select from "../../form/select"

interface Props {
  integrations?: Integration[]
  isLoading: boolean
  onSelect?: (id: string) => void
  emptyStateProps?: EmptyStateProps
}

const IntegrationsTable: FC<Props> = ({ integrations, onSelect }) => {
  const user: UserDetails | any = useAppSelector(selectUserDetails)

  const [viewType, setViewType] = useState<ViewType>("table")
  const [dataSet, setDataSet] = useState<string>("all-integrations")

  const columns: BBColumnDef<Integration>[] = [
    {
      id: "title",
      header: ({ header }) => <TH header={header} srOnly label="Name" />,
      footer: props => props.column.id,
      cell: info => (
        <IntegrationCell data={info.row.original} onSelect={onSelect} />
      )
    }
  ]

  const DATA_SET_OPTIONS = [
    {
      id: "all-integrations",
      name: "All integrations"
    },
    {
      id: "my-integrations",
      name: "My integrations"
    }
  ]

  const selectedDataSet = DATA_SET_OPTIONS.find(dso => dso.id === dataSet)

  const inMobileView = useInMobileView()

  const userIntegrations = useMemo(
    () =>
      (integrations as Integration[])?.filter(
        (integration: Integration) => integration.owner === user.id
      ),
    [integrations, user.id]
  )

  const tableData = useMemo(
    () => (dataSet === "my-integrations" ? userIntegrations : integrations),
    [dataSet, integrations, userIntegrations]
  )

  useEffect(() => {
    if (inMobileView) {
      setViewType("grid")
    }
  }, [inMobileView])

  return (
    <>
      <div className="flex items-center justify-between mt-6">
        <div>
          <Select
            label=""
            selected={selectedDataSet}
            data={DATA_SET_OPTIONS}
            IDkey="id"
            setSelected={setDataSet}
          />
        </div>
        {!inMobileView && (
          <ToggleView
            className=""
            viewType={viewType}
            setViewType={setViewType}
          />
        )}
      </div>
      <Table
        className="mt-4 -mb-6"
        data={tableData ?? []}
        columns={columns}
        viewType={viewType}
        pageSize={10}
        searchBarVisible={false}
        renderCard={props => <Card data={props.original} onSelect={onSelect} />}
        noHeader
        removeMobilePagination
      />
    </>
  )
}

export default IntegrationsTable
