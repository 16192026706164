import { FC } from "react"
import { Pencil, Trash2 } from "lucide-react"
import { LoanOfficer } from "../../../../types"
import BreadCrumbs from "../../breadcrumbs"
import { Button } from "../../buttons"
import RoundedImg from "../../rounded-img"

interface Props {
  loanOfficer: LoanOfficer
  onClickEdit: (uid: string) => void
  onClickDelete: (uid: string) => void
}

const LoanOfficerDetailsHeader: FC<Props> = ({
  loanOfficer,
  onClickEdit,
  onClickDelete
}) => (
  <div className="p-6 bg-white">
    <BreadCrumbs
      pages={[
        {
          name: "Loan officers",
          href: "/loan-officers",
          current: false
        },
        {
          name: loanOfficer.name,
          href: "#",
          current: true
        }
      ]}
    />

    <div className="py-4 flex items-center justify-between">
      <div className="flex items-center">
        <div className="h-12 mr-4 border border-gray-300 rounded-full">
          <RoundedImg
            src={loanOfficer.picture}
            alt={`${loanOfficer.name} Thumbnail`}
          />
        </div>
        <div>
          <h3 className="text-lg font-bold">{loanOfficer.name}</h3>
          <div className="text-sm">
            <span>{loanOfficer.email}</span>
            <span className="text-gray-400"> &bull; </span>
            <span>ID: {loanOfficer.loid}</span>
            <span className="text-gray-400"> &bull; </span>
            <span>NMLS {loanOfficer.nmls || "N/A"}</span>
          </div>
        </div>
      </div>
      <div>
        <Button
          className="mr-2 bg-gray-200"
          onClick={() => onClickEdit(loanOfficer.loid ?? "")}
        >
          <Pencil size={16} />
        </Button>
        <Button
          className="bg-gray-200"
          onClick={() => onClickDelete(loanOfficer.loid ?? "")}
        >
          <Trash2 size={16} />
        </Button>
      </div>
    </div>
  </div>
)

export default LoanOfficerDetailsHeader
