import { FC, useEffect, useMemo, useState } from "react"

import If from "../../../if"
import FourColumnPerformanceSkeleton from "../../../skeletons/four-column-performance"
import ProgressBar from "../../../progress-bar"
import PillTabs from "../../../pill-tabs"
import NoData from "../../../no-data"
import {
  useFetchTopLeadBreakdownTypeEmbedsQuery,
  useFetchTopLeadBreakdownTypeLOsQuery,
  useFetchTopLeadBreakdownTypeURLQuery
} from "../../../../../redux/queries"
import {
  TransformTopLeadBreakdown,
  useTransformTopLeadBreakdown
} from "../../../../hooks"
import { TopLeadBreakdownData } from "../../../../../types"

interface TopLeadBreakdownProps {
  title: string
  range: number
  orgId: string | undefined
  loid: string | undefined
}

export enum TopLeadBreakdownTabs {
  URL = "breakdown-by-url",
  EMBEDS = "breakdown-by-embeds",
  LOS = "breakdown-by-los"
}

enum TopLeadBreakdownLabelTabs {
  URL = "URL",
  EMBEDS = "Embeds",
  LOS = "LO's"
}

const TopLeadBreakdown: FC<TopLeadBreakdownProps> = ({
  title,
  range,
  orgId,
  loid
}) => {
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const [tab, setTab] = useState<string>(TopLeadBreakdownTabs.URL)
  const [transformedData, setTransformedData] = useState<
    TransformTopLeadBreakdown[]
  >([])
  const previousRange = range + 30
  const fetchParams = (range: number) => ({
    range: range,
    org_id: orgId,
    loid: loid
  })
  const fetchOptions = (canSkip: boolean) => ({
    refetchOnMountOrArgChange: true,
    skip: canSkip
  })

  const { data: urlData, isFetching: isFetchingUrl } =
    useFetchTopLeadBreakdownTypeURLQuery(
      fetchParams(range),
      fetchOptions(tab !== TopLeadBreakdownTabs.URL)
    )
  const { data: urlPreviousData, isFetching: isFetchingUrlPrevious } =
    useFetchTopLeadBreakdownTypeURLQuery(
      fetchParams(previousRange),
      fetchOptions(tab !== TopLeadBreakdownTabs.URL)
    )
  const { data: embedsData, isFetching: isFetchingEmbeds } =
    useFetchTopLeadBreakdownTypeEmbedsQuery(
      fetchParams(range),
      fetchOptions(tab !== TopLeadBreakdownTabs.EMBEDS)
    )
  const { data: embedsPreviousData, isFetching: isFetchingEmbedsPrevious } =
    useFetchTopLeadBreakdownTypeEmbedsQuery(
      fetchParams(previousRange),
      fetchOptions(tab !== TopLeadBreakdownTabs.EMBEDS)
    )
  const { data: losData, isFetching: isFetchingLOs } =
    useFetchTopLeadBreakdownTypeLOsQuery(
      fetchParams(range),
      fetchOptions(tab !== TopLeadBreakdownTabs.LOS)
    )
  const { data: losPreviousData, isFetching: isFetchingLOsPrevious } =
    useFetchTopLeadBreakdownTypeLOsQuery(
      fetchParams(previousRange),
      fetchOptions(tab !== TopLeadBreakdownTabs.LOS)
    )

  const breakdownData: TopLeadBreakdownData[] | undefined = useMemo(() => {
    if (!isFetching) {
      switch (tab) {
        case TopLeadBreakdownTabs.URL:
          return urlData
        case TopLeadBreakdownTabs.EMBEDS:
          return embedsData
        case TopLeadBreakdownTabs.LOS:
          return losData
        default:
          return []
      }
    }
  }, [urlData, embedsData, losData, isFetching, tab])
  const previousBreakdownData: TopLeadBreakdownData[] | undefined =
    useMemo(() => {
      if (!isFetching) {
        switch (tab) {
          case TopLeadBreakdownTabs.URL:
            return urlPreviousData
          case TopLeadBreakdownTabs.EMBEDS:
            return embedsPreviousData
          case TopLeadBreakdownTabs.LOS:
            return losPreviousData
          default:
            return []
        }
      }
    }, [isFetching, tab, urlPreviousData, embedsPreviousData, losPreviousData])
  const maxCount = useMemo(
    () =>
      transformedData?.length > 0
        ? Math.max(...transformedData.map(data => data.lead_count))
        : 0,
    [transformedData]
  )
  const currentTransformedData = useTransformTopLeadBreakdown(
    tab,
    breakdownData,
    previousBreakdownData
  )

  useEffect(() => {
    setTransformedData(currentTransformedData)
  }, [currentTransformedData])

  useEffect(() => {
    setIsFetching(
      isFetchingUrl ||
        isFetchingEmbeds ||
        isFetchingLOs ||
        isFetchingUrlPrevious ||
        isFetchingEmbedsPrevious ||
        isFetchingLOsPrevious
    )
  }, [
    isFetchingEmbeds,
    isFetchingEmbedsPrevious,
    isFetchingLOs,
    isFetchingLOsPrevious,
    isFetchingUrl,
    isFetchingUrlPrevious
  ])

  return (
    <div className="bg-white flex flex-col flex-1 px-6 pt-4 pb-7 rounded-md overflow-hidden">
      <div className="flex flex-row items-center justify-between">
        <div className="text-base font-medium">{title}</div>
        <div className="flex-1">
          <PillTabs
            currentTab={tab}
            className="justify-end ml-4"
            tabs={[
              {
                id: TopLeadBreakdownTabs.URL,
                label: TopLeadBreakdownLabelTabs.URL
              },
              {
                id: TopLeadBreakdownTabs.EMBEDS,
                label: TopLeadBreakdownLabelTabs.EMBEDS
              },
              {
                id: TopLeadBreakdownTabs.LOS,
                label: TopLeadBreakdownLabelTabs.LOS
              }
            ]}
            onChange={setTab}
          />
        </div>
      </div>
      <If
        condition={isFetching}
        then={<FourColumnPerformanceSkeleton className="mt-px pt-11" />}
        else={
          <If
            condition={!transformedData || transformedData.length === 0}
            then={<NoData />}
            else={
              <div className="mt-7 overflow-y-auto scroll-smooth h-[330px]">
                <div className="w-full grid gap-y-6 py-2">
                  {transformedData.map((breakdownData, index) => (
                    <ProgressBar
                      key={index}
                      count={breakdownData.lead_count}
                      maxCount={maxCount}
                      label={
                        tab === TopLeadBreakdownTabs.URL
                          ? breakdownData.url
                          : breakdownData.name
                      }
                      netPercentage={breakdownData.netPercentage}
                      isPositive={breakdownData.isPositive}
                    />
                  ))}
                </div>
              </div>
            }
          />
        }
      />
    </div>
  )
}

export default TopLeadBreakdown
