import { FC, RefObject } from "react"
import { FormikProps } from "formik"
import EditLoProfile from "../../../account/edit-lo-profile"
import { LoanOfficer } from "../../../../../types"

interface Props {
  brandId?: string
  setFormRef: (ref: RefObject<FormikProps<LoanOfficer>>) => void
}

const CreateLoForm: FC<Props> = ({ brandId, setFormRef }) => {
  return (
    <EditLoProfile
      open
      brandId={brandId}
      isInSidePanel={false}
      tabsVisible={false}
      onClose={() => true}
      setFormRef={setFormRef}
    />
  )
}

export default CreateLoForm
