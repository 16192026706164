import { useEffect, useRef, useState } from "react"
import { Copy } from "lucide-react"
import {
  deleteAPIKey,
  fetchAPIKey,
  selectAPIKey,
  generateAPIKey,
  selectIsSubmitting
} from "../../../redux/slices/integrations"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { LightBtn, LinkBtnDanger, PrimaryBtn } from "../buttons"
import { UserDetails, APIKeyRespose } from "../../../types"
import { selectUserDetails } from "../../../redux/slices/auth"
import If from "../if"
import BBModal from "../modal"

type Props = {
  show: boolean
  toggleModal: () => void
}

const APIKeyModal = ({ show, toggleModal }: Props) => {
  const copyBtn = useRef(null)
  const generateBtn = useRef(null)
  const dispatch = useAppDispatch()
  const user: UserDetails | any = useAppSelector(selectUserDetails)
  const apiKey: APIKeyRespose | undefined = useAppSelector(selectAPIKey)
  const [apiKeyExist, setApiKeyExist] = useState(false)
  const [deleteKeyword, setDeleteKeyword] = useState("")
  const [showDeleteWarning, setDeleteWarning] = useState(false)
  const isLoading: boolean = useAppSelector(selectIsSubmitting)

  useEffect(() => {
    if (show && !apiKeyExist) {
      dispatch(fetchAPIKey(user.id))
    }
  }, [dispatch, show, apiKeyExist, user.id])

  const copy = () => {
    if (apiKeyExist) {
      navigator.clipboard.writeText(apiKey?.api_key as string)
    }
  }

  const deleteKey = () => {
    if (apiKeyExist) {
      dispatch(deleteAPIKey(apiKey?.id as string))
    }
  }
  const generateKey = () => {
    dispatch(generateAPIKey(user.id))
  }

  useEffect(() => {
    setApiKeyExist(
      !!(apiKey && apiKey?.api_key?.length > 0 && apiKey?.loid === user.id)
    )
  }, [apiKey, user.id])

  return (
    <BBModal
      size="sm"
      show={show}
      onClose={toggleModal}
      title={showDeleteWarning ? "Delete API Key" : "API Key"}
      initialFocus={apiKeyExist ? copyBtn : generateBtn}
    >
      <If
        isLoading={isLoading}
        condition={apiKeyExist && !showDeleteWarning}
        then={
          <>
            <div className="mb-4">
              <label htmlFor="api-key" className="sr-only">
                API key
              </label>
              <input
                className="px-4 py-3 text-base leading-6 border-2 w-full border-zinc-200 rounded-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                value={apiKey?.api_key}
                readOnly
              />
            </div>
            <PrimaryBtn
              onClick={copy}
              className="w-full mb-3"
              refProp={copyBtn}
            >
              <Copy size={20} className="mr-2" /> Copy
            </PrimaryBtn>
            <LinkBtnDanger
              onClick={() => {
                setDeleteWarning(true)
              }}
              className="w-full"
            >
              Delete Key
            </LinkBtnDanger>
          </>
        }
        elseCondition={apiKeyExist && showDeleteWarning}
        else={
          <>
            <div className="mb-4">
              <label htmlFor="deleteKeyword" className="mb-1 inline-block">
                Type "YES" to confirm
              </label>
              <input
                id="deleteKeyword"
                onChange={e => setDeleteKeyword(e.target.value)}
                className="px-4 py-3 text-base leading-6 border-2 w-full border-zinc-200 rounded-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                value={deleteKeyword}
              />
            </div>
            <div className="flex justify-end items-center">
              <LightBtn
                onClick={() => {
                  setDeleteWarning(false)
                }}
                className="mr-3"
              >
                Cancel
              </LightBtn>
              <PrimaryBtn
                onClick={deleteKey}
                disabled={deleteKeyword !== "YES"}
              >
                Delete
              </PrimaryBtn>
            </div>
          </>
        }
        default={
          <>
            <p className="text-center text-gray-400 mb-4">
              You don't have any API Key generated.
            </p>
            <div className="text-center">
              <PrimaryBtn onClick={generateKey} refProp={generateBtn}>
                Generate API Key
              </PrimaryBtn>
            </div>
          </>
        }
      />
    </BBModal>
  )
}
export default APIKeyModal
