import { ColumnDetail } from "../../types"

export const createColumnDetail = (
  label: string,
  count: number,
  completion: string,
  comparison: string,
  color: string = "#786FF6"
): ColumnDetail => {
  return {
    label,
    count,
    completion,
    comparison,
    color
  }
}
