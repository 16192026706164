import { ChangeEvent, FC } from "react"
import { Integration } from "../../../../../types"
import { useIntegrationSettingsForm } from "../../../../hooks"

interface Props {
  integration?: Integration
}

const CalcCheckboxes: FC<Props> = ({ integration }) => {
  const { app_settings, saveAppSetting } =
    useIntegrationSettingsForm(integration)

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = (app_settings as any)[name] ? 0 : 1

    saveAppSetting({ [name]: value })
  }

  return (
    <>
      <div className="flex items-center py-2">
        <input
          checked={app_settings?.mort_calc === 1}
          onChange={onChange}
          id="mort_calc"
          name="mort_calc"
          type="checkbox"
          className="h-6 w-6 rounded border-neural-400 text-primary-500 focus:ring-primary-300 cursor-pointer"
        />
        <label htmlFor="mort_calc" className="select-none ml-3 cursor-pointer">
          Mortgage Calculator
        </label>
      </div>
      <div className="flex items-center py-2">
        <input
          checked={app_settings?.afford_calc === 1}
          onChange={onChange}
          id="afford_calc"
          name="afford_calc"
          type="checkbox"
          className="h-6 w-6 rounded border-neural-400 text-primary-500 focus:ring-primary-300 cursor-pointer"
        />
        <label
          htmlFor="afford_calc"
          className="select-none ml-3 cursor-pointer"
        >
          Affordability Calculator
        </label>
      </div>
    </>
  )
}

export default CalcCheckboxes
