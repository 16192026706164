import { useListItem, useFloatingTree, useMergeRefs } from "@floating-ui/react"
import {
  forwardRef,
  ButtonHTMLAttributes,
  FocusEvent,
  MouseEvent,
  useContext,
  ReactNode
} from "react"
import MenuContext from "../context"

interface MenuItemProps {
  label: ReactNode
  icon?: ReactNode
  disabled?: boolean
  className?: string
}

const MenuItem = forwardRef<
  HTMLButtonElement,
  MenuItemProps & ButtonHTMLAttributes<HTMLButtonElement>
>(
  (
    { label, disabled, icon, className, children, hidden, ...props },
    forwardedRef
  ) => {
    const menu = useContext(MenuContext)
    const item = useListItem({ label: disabled ? null : label?.toString() })
    const tree = useFloatingTree()
    const isActive = item.index === menu.activeIndex

    const ref = useMergeRefs([item.ref, forwardedRef])

    if (hidden) return null

    return (
      <button
        {...props}
        ref={ref}
        type="button"
        role="menuitem"
        className={`${className} flex items-center w-full min-w-[6rem] border-none focus:bg-primary-400 focus:text-white focus:border-primary-400 outline-none group rounded-md p-2 text-sm font-medium ${
          disabled ? "text-neutral-300" : ""
        }`}
        tabIndex={isActive ? 0 : -1}
        disabled={disabled}
        {...menu.getItemProps({
          onClick(event: MouseEvent<HTMLButtonElement>) {
            props.onClick?.(event)
            tree?.events.emit("click")
          },
          onFocus(event: FocusEvent<HTMLButtonElement>) {
            props.onFocus?.(event)
            menu.setHasFocusInside(true)
          }
        })}
      >
        {icon ? (
          <div
            className={`mr-2 flex items-center ${isActive ? "text-white" : ""}`}
          >
            {icon}
          </div>
        ) : null}
        {label}
      </button>
    )
  }
)

export default MenuItem
