import { FC, useMemo } from "react"
import Input from "../../../../form/input"
import BBSwitch from "../../../../form/Switch"
import { Brand, RateTableConfigDetails } from "../../../../../../types"
import ReactJSONEditor from "../../../../react-json-editor"
import BrandAutocomplete from "../../../../brand-autocomplete"

interface Props {
  values: RateTableConfigDetails
  brands: Brand[]
  setFormField: (name: string, value: any) => void
}

const ManageRateTableConfigFormContent: FC<Props> = ({
  values,
  brands,
  setFormField
}) => {
  const selectedBrandId = useMemo(
    () => brands.find(b => b.id === values.brand_id)?.id,
    [brands, values.brand_id]
  )

  return (
    <div className="py-6 flex flex-col h-full">
      <div className="grid grid-cols-2 gap-x-12">
        <div>
          <Input label="Name" name="name" />
        </div>
        <div className="pt-10">
          <BBSwitch
            label="Set as default"
            enabled={values.is_default === "1"}
            setEnabled={value => setFormField("is_default", value ? "1" : "0")}
          />
        </div>
        <div className="py-6">
          <BrandAutocomplete
            brands={brands}
            selected={selectedBrandId}
            onChange={val => setFormField("brand_id", val)}
            direction="vertical"
          />
        </div>
      </div>

      <div className="pt-6 flex-1">
        <ReactJSONEditor
          name="details"
          json={values.details}
          onChangeJSON={val => setFormField("details", val)}
        />
      </div>
    </div>
  )
}

export default ManageRateTableConfigFormContent
