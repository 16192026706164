import { ChevronRight } from "lucide-react"
import { useGetQuickStatusQuery } from "../../../../../redux/queries"
import { setPreviewModal } from "../../../../../redux/slices/integrations"
import { Integration } from "../../../../../types"
import { useAppDispatch } from "../../../../hooks"
import If from "../../../if"
import Status from "../../../status"

interface Props {
  data: Integration
  onSelect?: (id: string) => void
}

const IntegrationCell = ({ data, onSelect }: Props) => {
  const dispatch = useAppDispatch()
  const { title, type, app_key, id } = data

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: status, isLoading: isLoadingQuickStatus } =
    useGetQuickStatusQuery(
      {
        integrationID: id ?? ""
      },
      {
        skip: !id
      }
    )

  const StatusTag =
    status?.status === "active"
      ? Status.Active
      : status?.status === "idle"
      ? Status.Idle
      : Status.Initial

  const openEditPanel = () => onSelect?.(id)

  return (
    <td className="whitespace-nowrap py-4 pl-2 pr-3">
      <div className="flex">
        <div
          className="pl-4"
          onClick={openEditPanel}
          tabIndex={0}
          role="button"
        >
          <strong className="text-base flex items-center">
            <span className="hover:underline">{title}&nbsp;</span>
            <If
              condition={typeof status !== "undefined"}
              then={<StatusTag className="ml-2" />}
            />
          </strong>
          <span className="text-sm text-neutral-400">{type}</span>
        </div>
        {type !== "api" && (
          <button
            onClick={() => {
              dispatch(
                setPreviewModal({
                  isOpen: true,
                  app_key,
                  title
                })
              )
            }}
            type="button"
            className="group appearance-none cursor-pointer ml-auto flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-300 rounded"
          >
            <span className="hidden transition-colors text-base border-dashed border-neutral-400 border-b lg:inline-block mr-6 ml-3 group-hover:text-primary-400">
              Desktop/Mobile preview
            </span>
            <span className="transition-colors text-base border-dashed border-neutral-400 border-b inline-block lg:hidden mr-6 ml-3 group-hover:text-primary-400">
              Preview
            </span>
            <ChevronRight className="text-neutral-400" size={24} />
          </button>
        )}
      </div>
    </td>
  )
}

export default IntegrationCell
