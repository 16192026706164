import { Button } from "../buttons"

export interface ButtonProps {
  key?: string | number
  label: string
  shortLabel?: string // label in mobile view
  onClick: () => void
  disabled?: boolean
  className?: string
}

interface Props {
  className?: string
  buttons: ButtonProps[]
  active?: string | number
}

const ButtonGroup: React.FC<Props> = ({ className, buttons, active }) => {
  return (
    <div className={`overflow-hidden ${className ?? ""}`}>
      {buttons.map(
        ({ key, label, shortLabel, onClick, disabled, className = "" }) => (
          <Button
            key={key}
            className={`rounded-none first:rounded-l-md last:rounded-r-md border border-r-0 last:border-r font-normal overflow-hidden outline-none ${
              active === key
                ? "bg-primary-50 text-primary-600 border-primary-500"
                : "border-gray-300 text-gray-500 hover:bg-gray-50"
            } ${className}`}
            disabled={disabled}
            onClick={onClick}
          >
            <span className="hidden md:inline-block">{label}</span>
            <span className="inline-block md:hidden">
              {shortLabel ?? label}
            </span>
          </Button>
        )
      )}
    </div>
  )
}

export default ButtonGroup
