import { FC } from "react"

import { formatCurrency, formatNumber } from "../../../utils"
import { RateflowCard } from "../../../../types"

type CardProps = {
  data: RateflowCard
  loanAmount?: number
  onViewDetails?: (data: RateflowCard) => void
}

const Card: FC<CardProps> = ({ data, loanAmount, onViewDetails }) => {
  const containsViewOption = !!onViewDetails

  return (
    <div
      className={`flex-1 border border-gray-300 rounded-md px-4 pt-6 text-sm ${
        !containsViewOption ? "pb-8" : ""
      }`}
    >
      <div className="font-extrabold text-3xl">
        {formatNumber(data.rate, {
          maximumFractionDigits: 3,
          minimumFractionDigits: 2
        })}
        %
      </div>
      <div className="text-xs uppercase mt-2">
        {data.loanTerm + " Yr " + data.amortizationType}
      </div>

      <div className="flex pt-6">
        <div className="flex-1 text-neutral-400">Price</div>
        <div className="flex-1">{data.price}</div>
      </div>

      <div className="flex pt-3">
        <div className="flex-1 text-neutral-400">Fee</div>
        <div className="flex-1">{formatCurrency(data.fee.amount)}</div>
      </div>

      <div className="flex pt-3">
        <div className="flex-1 text-neutral-400">Loan amount</div>
        <div className="flex-1">
          {loanAmount !== undefined ? formatCurrency(loanAmount) : "-"}
        </div>
      </div>

      <div className="flex pt-3">
        <div className="flex-1 text-neutral-400">P/I</div>
        <div className="flex-1">
          {formatCurrency(data.principalAndInterest)}
        </div>
      </div>

      <div className="flex pt-3">
        <div className="flex-1 text-neutral-400">Type</div>
        <div className="flex-1">{data.loanType}</div>
      </div>

      <div className="flex pt-3">
        <div className="flex-1 text-neutral-400">Investor</div>
        <div className="flex-1">{data.investor}</div>
      </div>
      {containsViewOption && (
        <div className="-ml-4 -mr-4 mt-8 border-t-gray-200 border-t">
          <button
            type="button"
            className="text-base p-4 text-primary-alt-400"
            onClick={() => onViewDetails(data)}
          >
            View details
          </button>
        </div>
      )}
    </div>
  )
}

export default Card
