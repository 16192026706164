import { FC } from "react"
import SidePanel from "../../side-panel"
import { Settings } from "../tabs"
import { Integration } from "../../../../types"

interface Props {
  open: boolean
  onClose: () => void
  onSubmitIntegration: () => void
  onSaveSettings: (integration: Integration) => void
}

const CreateIntegration: FC<Props> = ({
  open,
  onClose,
  onSubmitIntegration,
  onSaveSettings
}) => {
  return (
    <SidePanel
      open={open}
      title="Add new integration"
      onClose={onClose}
      actions={[
        {
          label: "Add integration",
          type: "primary",
          onClick: onSubmitIntegration
        }
      ]}
    >
      <Settings isLoading={false} onSaveSettings={onSaveSettings} />
    </SidePanel>
  )
}

export default CreateIntegration
