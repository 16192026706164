import React from "react"
import { PrimaryBtn } from "../buttons"

import If from "../if"

export type EmptyStateProps = {
  icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
  heading?: string
  subHeading?: string
  isActionButton?: boolean
  onClick?: () => void
  buttonText?: string
}

const EmptyState: React.FC<EmptyStateProps> = ({
  heading,
  subHeading,
  icon: Icon,
  isActionButton,
  onClick = () => {},
  buttonText
}) => {
  return (
    <div className="flex flex-col w-full table-wrapper items-center	justify-center">
      {!!Icon && (
        <div className="mb-4">
          <Icon width={40} height={40} strokeWidth={1} />
        </div>
      )}
      <If
        condition={!!heading}
        then={<h3 className="text-xl font-bold">{heading}</h3>}
      />
      <If
        condition={!!subHeading}
        then={<p className="text-base font-normal">{subHeading}</p>}
      />
      <If
        condition={!!isActionButton}
        then={
          <div className="mt-5 mb-24">
            <PrimaryBtn onClick={onClick}>{buttonText}</PrimaryBtn>
          </div>
        }
      />
    </div>
  )
}

export default EmptyState
