import RecentSearchItem from "./recent-search-item"
import { LinkBtn } from "../buttons"
import { SearchResult } from "../../../types"

interface Props {
  recentSearches: SearchResult[]
  onClickItem: (result: SearchResult) => void
  onClickResetRecentSearches: () => void
}

const RecentSearches = ({
  recentSearches,
  onClickItem,
  onClickResetRecentSearches
}: Props) => (
  <div className="p-4">
    <div className="text-sm text-neutral-400 font-semibold">
      <span>Recent searches</span>
      {recentSearches.length > 0 && (
        <LinkBtn
          className="mx-2 !p-0 text-xs"
          onClick={onClickResetRecentSearches}
        >
          Clear
        </LinkBtn>
      )}
    </div>
    <div className="py-2">
      {recentSearches.length ? (
        recentSearches.map(result => (
          <RecentSearchItem
            key={result.id}
            data={result}
            onClickItem={onClickItem}
          />
        ))
      ) : (
        <div className="py-2 text-sm text-neutral-400 font-medium">
          No recent searches found.
        </div>
      )}
    </div>
  </div>
)

export default RecentSearches
