import { FC } from "react"
import { LoanOfficer } from "../../../../types"
import Input from "../../form/input"

interface Props {
  title: string
  values: LoanOfficer
}

const SocialSection: FC<Props> = ({ title }) => {
  return (
    <div className="p-6">
      <div>
        <h4 className="text-xl font-bold mb-6 text-primary-alt-400 ">
          {title}
        </h4>
      </div>
      <div className="grid gap-y-6 gap-x-8 grid-cols-12 pt-8">
        <div className="col-span-12">
          <Input label="Facebook profile" name="facebook_link" type="url" />
        </div>
        <div className="col-span-12">
          <Input label="Linkedin profile" name="linkedin_link" type="url" />
        </div>
        <div className="col-span-12">
          <Input label="Website" name="website_link" type="url" />
        </div>
        <div className="col-span-12">
          <Input
            label="Special disclosure link"
            name="special_disclosure_link"
            type="url"
          />
        </div>
      </div>
    </div>
  )
}

export default SocialSection
