import { FC } from "react"
import { Lead } from "../../../../../../../types"
import SectionCell from "../../../../../section/cell"
import {
  findSelectOptionName,
  formatNumber,
  formatPercentage
} from "../../../../../../utils"
import {
  LOAN_PURPOSE_OPTIONS,
  PROPERTY_TYPE_OPTIONS,
  RESIDENCY_TYPE_OPTIONS
} from "../../../../../../constants"

interface Props {
  data: Lead
}

const LoanScenario: FC<Props> = ({ data }) => {
  const {
    details: {
      loan_amount,
      loan_purpose,
      property_type,
      list_price,
      residency_type,
      credit_score,
      down_payment
    }
  } = data

  return (
    <div className="grid grid-cols-2 gap-4">
      <SectionCell
        label="Loan purpose"
        value={findSelectOptionName(loan_purpose, LOAN_PURPOSE_OPTIONS)}
      />
      <SectionCell label="Loan amount" value={formatNumber(loan_amount)} />
      <SectionCell
        label="Down payment"
        value={formatNumber(down_payment ?? "")}
      />
      <SectionCell
        label="Down percentage"
        value={`${
          down_payment && loan_amount
            ? formatPercentage(parseFloat(down_payment) / loan_amount)
            : "-"
        }`}
      />
      <SectionCell label="List price" value={formatNumber(list_price)} />
      <SectionCell label="Credit score" value={credit_score} />
      <SectionCell
        label="Property type"
        value={findSelectOptionName(property_type, PROPERTY_TYPE_OPTIONS)}
      />
      <SectionCell
        label="Residency type"
        value={findSelectOptionName(residency_type, RESIDENCY_TYPE_OPTIONS)}
      />
    </div>
  )
}

export default LoanScenario
