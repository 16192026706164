import { createApi } from "@reduxjs/toolkit/dist/query/react"
import {
  RTK_SLICE_API_LIST,
  SUBSCRIPTION_BRAND_ACTIVITY_STATS_ENDPOINT,
  SUBSCRIPTION_CONFORMING_LIMIT_ENDPOINT,
  SUBSCRIPTION_EMAIL_LOG_ENDPOINT,
  SUBSCRIPTION_ENDPOINT,
  SUBSCRIPTION_EVENTS_ENDPOINT,
  SUBSCRIPTION_LO_ACTIVITY_STATS_ENDPOINT,
  SUBSCRIPTION_LOG_ENDPOINT,
  SUBSCRIPTION_ORG_ACTIVITY_STATS_ENDPOINT,
  SUBSCRIPTION_QUOTE_ENDPOINT,
  SUBSCRIPTION_QUOTES_ENDPOINT,
  SUBSCRIPTION_TAXES_INSURANCE_ENDPOINT
} from "../../common/constants"
import {
  getQueryString,
  convertPayloadToSubscriptionDetails
} from "../../common/utils"
import {
  CreateSubscriptionPayload,
  SubscriptionActivityStat,
  SubscriptionConformingLimitResponse,
  SubscriptionDetails,
  SubscriptionEmailLog,
  SubscriptionEvent,
  SubscriptionLog,
  SubscriptionQuotesResponse,
  TaxesAndInsuranceResponse,
  UpdateSubscriptionPayload
} from "../../types"
import { baseQuery, getTags } from "../api"
import bbApi from "../../api"

const reducerPath = "subscriptions"
const subscriptionDetails = "subscription-details"
const subscriptionDetailsByLeadPath = "subscription-details-by-lead"

export const subscriptionsApi = createApi({
  reducerPath,
  baseQuery,
  tagTypes: [reducerPath, subscriptionDetails, subscriptionDetailsByLeadPath],
  endpoints: builder => ({
    fetchSubscriptionLogs: builder.query({
      query: (params: {
        min_created: number
        offset: number
        limit: number
        loid?: string
        org_id?: string
      }) => `${SUBSCRIPTION_LOG_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: SubscriptionLog[]) =>
        responseData || [],
      providesTags: result => getTags(reducerPath, "id", result)
    }),
    fetchSubscriptionDetails: builder.query({
      query: (params: { id?: string; email?: string; brand_id?: string }) =>
        `${SUBSCRIPTION_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (
        responseData: SubscriptionDetails | SubscriptionDetails[]
      ) => (Array.isArray(responseData) ? responseData[0] : responseData),
      providesTags: result =>
        getTags(subscriptionDetails, "id", result ? [result] : [])
    }),
    fetchSubscriptionsByLeadId: builder.query({
      query: (params: { lead_id: string }) =>
        `${SUBSCRIPTION_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: SubscriptionDetails[]) =>
        responseData || [],
      providesTags: result =>
        getTags(subscriptionDetailsByLeadPath, "id", result ?? [])
    }),
    fetchSubscriptionEmailLogs: builder.query({
      query: (params: { id: string }) =>
        `${SUBSCRIPTION_EMAIL_LOG_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: { data: SubscriptionEmailLog[] }) =>
        responseData?.data || []
    }),
    fetchSubscriptionEvents: builder.query({
      query: (params: { subscription_id: string }) =>
        `${SUBSCRIPTION_EVENTS_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: { data: SubscriptionEvent[] }) =>
        responseData?.data || []
    }),
    fetchSubscriptionQuote: builder.query({
      query: (params: { id: string; bb_sid: string }) =>
        `${SUBSCRIPTION_QUOTE_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: { data: any[] }) =>
        responseData?.data || []
    }),
    fetchSubscriptionQuotes: builder.query({
      query: (params: {
        subscription_id: string
        timestamp_min?: number
        timestamp_max?: number
      }) => `${SUBSCRIPTION_QUOTES_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: SubscriptionQuotesResponse) =>
        responseData
    }),
    fetchConformingLimit: builder.query({
      query: (params: { zipcode: string }) =>
        `${SUBSCRIPTION_CONFORMING_LIMIT_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: SubscriptionConformingLimitResponse) =>
        responseData
    }),
    fetchTaxesAndInsurance: builder.query({
      query: (params: { zipcode?: string; list_price?: string }) =>
        `${SUBSCRIPTION_TAXES_INSURANCE_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: TaxesAndInsuranceResponse) =>
        responseData
    }),
    createSubscription: builder.mutation({
      queryFn: async (data: CreateSubscriptionPayload) => {
        const payload = convertPayloadToSubscriptionDetails(data)
        const response = await bbApi.subscriptions.add(payload)

        return { data: response }
      },
      invalidatesTags: [{ type: reducerPath, id: RTK_SLICE_API_LIST }]
    }),
    updateSubscription: builder.mutation({
      queryFn: async (data: UpdateSubscriptionPayload) => {
        const response = await bbApi.subscriptions.update({
          ...convertPayloadToSubscriptionDetails(data),
          id: data.id
        })
        return { data: response }
      },
      invalidatesTags: [
        { type: subscriptionDetailsByLeadPath, id: RTK_SLICE_API_LIST },
        { type: subscriptionDetails, id: RTK_SLICE_API_LIST },
        { type: reducerPath, id: RTK_SLICE_API_LIST }
      ]
    }),
    sendIntroEmail: builder.mutation({
      queryFn: async (id: string) => {
        const response = await bbApi.subscriptions.sendIntroEmail(id)
        return response
      }
    }),
    fetchSubscriptionActivityStats: builder.query({
      query: (params: {
        event_range: number
        sort_by: string
        org_id?: string | undefined
        brand_id?: string | undefined
        loid?: string | undefined
      }) => {
        const { org_id, brand_id } = params
        if (org_id) {
          return `${SUBSCRIPTION_ORG_ACTIVITY_STATS_ENDPOINT}${getQueryString(
            params
          )}`
        } else if (brand_id) {
          return `${SUBSCRIPTION_BRAND_ACTIVITY_STATS_ENDPOINT}${getQueryString(
            params
          )}`
        } else {
          return `${SUBSCRIPTION_LO_ACTIVITY_STATS_ENDPOINT}${getQueryString(
            params
          )}`
        }
      },
      transformResponse: (responseData: { data: SubscriptionActivityStat[] }) =>
        responseData?.data || []
    })
  })
})

export const {
  useFetchSubscriptionLogsQuery,
  useFetchSubscriptionDetailsQuery,
  useLazyFetchSubscriptionDetailsQuery,
  useFetchSubscriptionsByLeadIdQuery,
  useFetchSubscriptionEmailLogsQuery,
  useFetchSubscriptionEventsQuery,
  useFetchSubscriptionQuoteQuery,
  useFetchSubscriptionQuotesQuery,
  useFetchConformingLimitQuery,
  useSendIntroEmailMutation,
  useCreateSubscriptionMutation,
  useUpdateSubscriptionMutation,
  useFetchTaxesAndInsuranceQuery,
  useFetchSubscriptionActivityStatsQuery
} = subscriptionsApi
