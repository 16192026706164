import { FC } from "react"
import { ErrorMessage } from "formik"
import { Brand, LoanOfficer } from "../../../../types"
import { LO_ROLES_ORDERED, capitalize } from "../../../utils"
import Form from "../../form"
import WysiwygEditor from "../../form/WysiwygEditor"
import ImageUploader from "../../image-uploader"

const { Input, Select, PhoneInput } = Form

interface Props {
  title: string
  values: LoanOfficer
  brands: Brand[]
  setFormField: (name: string, value: string | string[]) => void
}

const USER_ROLES = LO_ROLES_ORDERED.map(r => ({
  id: r,
  name: r
    .split(" ")
    .map(s => capitalize(s))
    .join(" ")
}))

const ProfileSection: FC<Props> = ({ title, values, brands, setFormField }) => {
  const selectedBrand = brands.find(b => b.nid === values?.bank_nid)

  const setRoles = (val: any[]) => {
    const roles: string[] = val.map(item => {
      if (typeof item === "string") {
        return item
      } else {
        return item.id
      }
    })
    setFormField("roles", roles)
  }

  const handleChangePicture = (url: string) => {
    setFormField("picture", url)
  }

  const imageURL = values?.picture === "delete" ? "" : values?.picture

  return (
    <div className="p-6">
      <div>
        <h4 className="text-xl font-bold mb-6 text-primary-alt-400 ">
          {title}
        </h4>
      </div>

      <ImageUploader
        imageUrl={imageURL}
        onChangeImageUrl={handleChangePicture}
      />

      <div className="grid gap-y-6 gap-x-8 grid-cols-12 pt-8">
        <div className="col-span-6">
          <Select
            label="Brand"
            IDkey="nid"
            selected={selectedBrand}
            data={brands}
            setSelected={val => setFormField("bank_nid", val)}
            required
          />
          <ErrorMessage
            name={"bank"}
            component="p"
            className="mt-2 text-sm text-red-600"
          />
        </div>
        <div className="col-span-6">
          <Input label="NMLS" name="nmls" required={true} />
        </div>
      </div>

      <div className="grid gap-y-6 gap-x-8 grid-cols-12 pt-8">
        <div className="col-span-6">
          <Select
            multiple
            label="Roles"
            name="roles"
            selected={values?.roles ?? []}
            data={USER_ROLES}
            setSelected={val => setRoles(val)}
          />
        </div>
        <div className="col-span-6">
          <Input label="Title" name="position" />
        </div>
      </div>

      <div className="grid gap-y-6 gap-x-8 grid-cols-12 pt-8">
        <div className="col-span-6">
          <Input label="First name" name="first_name" required={true} />
        </div>
        <div className="col-span-6">
          <Input label="Last name" name="last_name" required={true} />
        </div>
      </div>

      <div className="grid gap-y-6 gap-x-8 grid-cols-12 pt-8">
        <div className="col-span-12">
          <Input label="Email address" name="email" required={true} />
        </div>
      </div>

      <div className="grid gap-y-6 gap-x-8 grid-cols-12 pt-8">
        <div className="col-span-12">
          <Input label="Branch NMLS (Optional)" name="brand_nmls" />
        </div>
      </div>

      <div className="grid gap-y-6 gap-x-8 grid-cols-12 pt-8">
        <div className="col-span-12">
          <Input label="Pre Application link" name="app_link" type="url" />
        </div>
      </div>

      <div className="grid gap-y-6 gap-x-8 grid-cols-12 pt-8">
        <div className="col-span-6">
          <PhoneInput label="Mobile number" name="mobile_phone" />
        </div>
        <div className="col-span-6">
          <PhoneInput label="Office number" name="office_phone" />
        </div>
      </div>

      <div className="grid gap-y-6 gap-x-8 grid-cols-12 pt-8">
        <div className="col-span-6">
          <PhoneInput label="Primary phone number" name="phone" />
        </div>
      </div>

      <div className="grid gap-y-6 gap-x-8 grid-cols-12 pt-8">
        <div className="col-span-12">
          <WysiwygEditor
            name="about_me"
            label="About me"
            initialValue={values?.about_me}
            setFieldValue={val => setFormField("about_me", val)}
          />
        </div>
      </div>
    </div>
  )
}

export default ProfileSection
