import { FC } from "react"
import ChartWithStats from "../../chart-with-stats"

interface Props {
  orgId?: string
}

const Charts: FC<Props> = ({ orgId }) => {
  return (
    <>
      <ChartWithStats title="Embeds performance" type="embed" orgId={orgId} />
      <ChartWithStats
        title="Subscription email performance"
        type="emails"
        orgId={orgId}
      />
    </>
  )
}

export default Charts
