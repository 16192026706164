import Form from "../../../form"

type Props = {
  globalFilter: string
  searchPlaceholder: string
  onSearch: (value: string | number) => void
  children?: React.ReactNode
  className?: string
  searchClassName?: string
}

const classNameDefault = ""

const SearchBar = ({
  globalFilter,
  onSearch,
  searchPlaceholder,
  children,
  className = classNameDefault,
  searchClassName = "pl-10 lg:min-w-[20rem] text-sm"
}: Props) => (
  <div
    className={`${className} align-middle items-center bg-white gap-1 lg:gap-3`}
  >
    <Form.SearchInput
      wrapperClassName="w-full flex-1 lg:w-auto lg:mb-0"
      placeholder={searchPlaceholder}
      value={globalFilter ?? ""}
      onSearch={onSearch}
      className={searchClassName}
    />
    <div className="flex align-center items-center lg:flex-1">{children}</div>
  </div>
)

export default SearchBar
