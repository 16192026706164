import TdInfoCell from "../../../table/cells/td-info-cell"
import { formatUnderscoresInField } from "../../../../utils"
import {
  BBColumnDef,
  LeadCountByProperty,
  LeadCountByResidency
} from "../../../../../types"

export const getLeadByPropertyColumns =
  (): BBColumnDef<LeadCountByProperty>[] => [
    {
      id: "property-type",
      header: "Property Type",
      accessorFn: row => row.PropertyType,
      footer: props => props.column.id,
      cell: props => (
        <TdInfoCell
          info={formatUnderscoresInField(props.row.original.PropertyType)}
          customContainerClass="p-0"
          customTextClass="mr-2 my-3 py-1 text-sm font-medium leading-6 text-ellipsis overflow-hidden"
        />
      )
    },
    {
      id: "leads",
      header: "Leads",
      accessorFn: row => row.lead_count,
      footer: props => props.column.id,
      cell: props => (
        <TdInfoCell
          info={props.row.original.lead_count?.toLocaleString()}
          customContainerClass="p-0"
          customTextClass="mr-2 my-3 py-1 text-sm leading-6"
        />
      )
    },
    {
      id: "percentages",
      header: "%",
      accessorFn: row => row.percentage,
      footer: props => props.column.id,
      cell: props => (
        <TdInfoCell
          info={`${props.row.original.percentage}%`}
          customContainerClass="p-0"
          customTextClass="my-3 py-1 text-sm leading-6 text-center"
          customTextStyle={{
            background: `${props.row.original.color}`
          }}
        />
      )
    }
  ]

export const getLeadByResidencyColumns =
  (): BBColumnDef<LeadCountByResidency>[] => [
    {
      id: "residency-type",
      header: "Residency Type",
      accessorFn: row => row.ResidencyType,
      footer: props => props.column.id,
      cell: props => (
        <TdInfoCell
          info={formatUnderscoresInField(props.row.original.ResidencyType)}
          customContainerClass="p-0"
          customTextClass="mr-2 my-3 py-1 text-sm font-medium leading-6 text-ellipsis overflow-hidden"
        />
      )
    },
    {
      id: "leads",
      header: "Leads",
      accessorFn: row => row.lead_count,
      footer: props => props.column.id,
      cell: props => (
        <TdInfoCell
          info={props.row.original.lead_count?.toLocaleString()}
          customContainerClass="p-0"
          customTextClass="mr-2 my-3 py-1 text-sm leading-6"
        />
      )
    },
    {
      id: "percentages",
      header: "%",
      accessorFn: row => row.percentage,
      footer: props => props.column.id,
      cell: props => (
        <TdInfoCell
          info={`${props.row.original.percentage}%`}
          customContainerClass="p-0"
          customTextClass="my-3 py-1 text-sm leading-6 text-center"
          customTextStyle={{
            background: `${props.row.original.color}`
          }}
        />
      )
    }
  ]
