import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Form, Formik, FormikProps } from "formik"
import {
  CreateSubscriptionPayload,
  GooglePlace,
  CreateSubscriptionResponse,
  SubscriptionDetails
} from "../../../../types"
import SidePanel from "../../side-panel"
import { engagementFormSchema } from "./engagement-form-schema"
import ManageEngagementBasic from "./sections/basic"
import ManageEngagementMisc from "./sections/misc"
import ManageEngagementSelectors from "./sections/selectors"
import {
  useAppSelector,
  useToast,
  useValidateEmailAndNMLS
} from "../../../hooks"
import { selectUserDetails } from "../../../../redux/slices/auth"
import {
  convertGooglePlaceToAddressObj,
  convertSubscriptionDetailsToPayload
} from "../../../utils"
import {
  useCreateSubscriptionMutation,
  useUpdateSubscriptionMutation,
  useFetchLeadDetailsQuery
} from "../../../../redux/queries"

interface Props {
  brandId?: string
  data?: SubscriptionDetails
  open: boolean
  leadId?: string
  onClose: () => void
  submitCallback?: (response: CreateSubscriptionResponse) => void
}

const ManageEngagementPanel: FC<Props> = ({
  brandId,
  data,
  open,
  leadId,
  onClose,
  submitCallback
}) => {
  const userDetails = useAppSelector(selectUserDetails)
  const { pushErrorToast, pushSuccessToast } = useToast()

  const { validateSubscriptionEmail } = useValidateEmailAndNMLS()

  const brand = useMemo(() => {
    return userDetails?.org?.brands?.find(
      brand => brand.default_loid === userDetails?.id
    )
  }, [userDetails?.id, userDetails?.org?.brands])

  const { data: leadDetails } = useFetchLeadDetailsQuery(leadId ?? "", {
    skip: !leadId
  })

  const validate = useCallback(
    (payload: CreateSubscriptionPayload | SubscriptionDetails) =>
      validateSubscriptionEmail(payload, brand?.id ?? ""),
    [validateSubscriptionEmail, brand]
  )

  const [
    createSubscription,
    {
      data: createSubscriptionData,
      isSuccess: isSuccessCreate,
      isError: isErrorCreate,
      isLoading: isLoadingCreate,
      reset: resetCreateSubscription
    }
  ] = useCreateSubscriptionMutation()
  const [
    updateSubscription,
    {
      data: updateSubscriptionData,
      isSuccess: isSuccessUpdate,
      isError: isErrorUpdate,
      isLoading: isLoadingUpdate,
      reset: resetUpdateSubscription
    }
  ] = useUpdateSubscriptionMutation()

  const formRef = useRef<FormikProps<CreateSubscriptionPayload>>(null)

  // Google Place object, when submitting parse this object to get state, city, address, zipcode
  const [googlePlace, setGooglePlace] = useState<GooglePlace>()

  const isCreateMode = !data?.id
  const isLoading = isLoadingCreate || isLoadingUpdate

  const setFormField = (name: string, val: string | number | boolean) => {
    formRef.current?.setFieldValue(name, val)
  }

  const resetForm = useCallback(() => {
    resetCreateSubscription()
    resetUpdateSubscription()
    formRef.current?.resetForm()
  }, [resetCreateSubscription, resetUpdateSubscription])

  const onSubmit = async () => {
    if (formRef.current?.values && formRef.current.isValid) {
      if (isCreateMode) {
        createSubscription(formRef.current.values)
      } else {
        updateSubscription({ id: data.id, ...formRef.current.values })
      }
    }
  }

  const INITIAL_VALUES: CreateSubscriptionPayload =
    convertSubscriptionDetailsToPayload(
      data,
      userDetails?.id,
      leadDetails,
      isCreateMode
    )

  useEffect(() => {
    if (googlePlace) {
      const address = convertGooglePlaceToAddressObj(googlePlace)
      setFormField("city", address.city ?? "")
      setFormField("county", address.county ?? "")
      setFormField("state", address.state ?? "")
      setFormField("street_address", address.street_address ?? "")
      setFormField("zipcode", address.zipcode ?? "")
    }
  }, [googlePlace])

  useEffect(() => {
    const status = isCreateMode
      ? createSubscriptionData?.status
      : updateSubscriptionData?.status
    const isSuccess = isCreateMode ? isSuccessCreate : isSuccessUpdate
    const isError = isCreateMode ? isErrorCreate : isErrorUpdate

    if (isSuccess && status === "success") {
      pushSuccessToast(
        `Successfully ${isCreateMode ? "created" : "updated"} subscription`
      )
      resetForm()
      onClose()

      submitCallback?.(
        isCreateMode ? createSubscriptionData : updateSubscriptionData
      )
    } else if (isError || status === "error") {
      pushErrorToast(
        `Failed to ${isCreateMode ? "create" : "update"} subscription`
      )
      resetCreateSubscription()
      resetUpdateSubscription()
    }
  }, [
    pushSuccessToast,
    pushErrorToast,
    resetForm,
    isCreateMode,
    onClose,
    isSuccessUpdate,
    isErrorUpdate,
    isSuccessCreate,
    isErrorCreate,
    updateSubscriptionData,
    createSubscriptionData,
    resetCreateSubscription,
    resetUpdateSubscription,
    submitCallback
  ])

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validationSchema={engagementFormSchema}
      onSubmit={onSubmit}
      innerRef={formRef}
      validateOnBlur
      validate={validate}
      enableReinitialize
    >
      {({ values, errors, touched, isValid }) => (
        <SidePanel
          title={`${isCreateMode ? "Add new" : "Edit"} subscription`}
          open={open}
          onClose={onClose}
          actions={[
            {
              label: `${isCreateMode ? "Add" : "Update"} subscription`,
              type: "primary",
              onClick: onSubmit,
              disabled: isLoading || !isValid
            }
          ]}
        >
          <Form>
            <ManageEngagementBasic
              values={values}
              loSearchParams={{ brand_id: brand?.id ?? brandId ?? "" }}
              setFormField={setFormField}
              setGooglePlace={setGooglePlace}
            />
            <ManageEngagementSelectors
              values={values}
              setFormField={setFormField}
            />
            <ManageEngagementMisc values={values} setFormField={setFormField} />
          </Form>
        </SidePanel>
      )}
    </Formik>
  )
}

export default ManageEngagementPanel
