import { FC } from "react"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import { Integration } from "../../../../types"
import If from "../../if"
import {
  REGEX_HEX_COLOR,
  REGEX_URL_WILDCARD_ASTERISK
} from "../../../constants"
import {
  Colors,
  TitleField,
  URLField,
  WidgetsCheckboxes,
  EmbedsOptions,
  GoogleAnalyticsTag,
  AdvanceOptions,
  IntegrationOwnerSelector
} from "../fields"
import { useFormIntegrationRef } from "../manage"
import { useAppSelector } from "../../../hooks"
import { selectUser } from "../../../../redux/slices/auth"

const hexColor = Yup.string().matches(
  REGEX_HEX_COLOR,
  "Is not a valid hex color"
)

const formSchema = Yup.object().shape({
  title: Yup.string().required().min(1),
  accent_color: hexColor,
  secondary_accent_color: hexColor,
  url: Yup.string()
    .matches(REGEX_URL_WILDCARD_ASTERISK, "Invalid URL")
    .required(),
  type: Yup.string().required(),
  owner: Yup.string().required()
})

interface Props {
  integration?: Integration
  isLoading: boolean
  onSaveSettings: (integration: Integration) => void
}

const Settings: FC<Props> = ({ integration, isLoading, onSaveSettings }) => {
  const user = useAppSelector(selectUser)
  const formRef = useFormIntegrationRef()

  const initialValues: Integration = integration
    ? {
        ...integration
      }
    : {
        id: "",
        title: "",
        type: "boomtown",
        app_key: "",
        owner: user?.user.id ?? "",
        url: "",
        brand_id: "",
        domains: [],
        app_settings: {
          mort_calc: 0,
          afford_calc: 0,
          pricing: 0,
          lead_workflow: 0,
          pricing_set_id: 0,
          settings: {
            aboutBoxes: false,
            loReviews: false,
            brandReviews: false,
            rateTable: false,
            ga_mode: "",
            ga_tag: "",
            contactForm: 0,
            disableCalcCards: false,
            postUrl: "",
            accent_color: "",
            secondary_accent_color: ""
          }
        }
      }

  return (
    <If
      isLoading={isLoading}
      condition
      then={
        <Formik
          initialValues={initialValues}
          onSubmit={onSaveSettings}
          innerRef={formRef}
          validationSchema={formSchema}
          validateOnBlur
          enableReinitialize
        >
          <Form>
            <TitleField />
            <URLField />
            <EmbedsOptions setFieldValue={formRef.current?.setFieldValue} />
            <Colors />
            <GoogleAnalyticsTag />
            <WidgetsCheckboxes integration={integration} />
            <IntegrationOwnerSelector />
            <AdvanceOptions />
          </Form>
        </Formik>
      }
    />
  )
}

export default Settings
