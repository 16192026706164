import React, { FC, useRef, useState } from "react"
import {
  useFloating,
  autoUpdate,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
  offset,
  flip,
  arrow,
  FloatingArrow
} from "@floating-ui/react"

export type Alignment = "start" | "end"
export type Side = "top" | "right" | "bottom" | "left"
export type AlignedPlacement = `${Side}-${Alignment}`

export interface Props {
  toolTipNode: React.ReactElement
  text: string
  displayTextClass?: string
  placement?: Side | AlignedPlacement
}

const Tooltip: FC<Props> = ({
  toolTipNode,
  text,
  placement,
  displayTextClass
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const arrowRef = useRef(null)

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: placement || "top",
    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(10),
      flip({
        fallbackAxisSideDirection: "start"
      }),
      shift(),
      arrow({
        element: arrowRef
      })
    ]
  })

  // Event listeners to change the open state
  const hover = useHover(context, { move: false })
  const focus = useFocus(context)
  const dismiss = useDismiss(context)
  // Role props for screen readers
  const role = useRole(context, { role: "tooltip" })

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role
  ])

  return (
    <>
      <div
        className={`truncate ${displayTextClass}`}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {toolTipNode}
      </div>
      <FloatingPortal>
        {isOpen && (
          <div
            className="z-50 max-w-[10rem] md:max-w-xs bg-neutral-700 rounded-md text-neutral-50 text-xs md:text-sm p-2"
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <FloatingArrow ref={arrowRef} context={context} />
            {text}
          </div>
        )}
      </FloatingPortal>
    </>
  )
}

export default Tooltip
