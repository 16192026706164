import { FC, useMemo } from "react"
import { MonitorEntry } from "../../../../../types"

interface Props {
  monitorEntries?: MonitorEntry[]
}

const Entries: FC<Props> = ({ monitorEntries }) => {
  const lis = useMemo(
    () =>
      monitorEntries?.map(entry => (
        <li
          key={entry.timestamp}
          className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"
        >
          <a
            target="_blank"
            href={entry.screen_shot_url}
            rel="noreferrer"
            className="block aspect-w-1 aspect-h-1 overflow-hidden bg-gray-100 group-hover:opacity-75"
          >
            <img
              src={entry.screen_shot_url}
              alt="monitor thumbnail"
              className="object-contain object-center"
            />
          </a>

          <time
            dateTime={entry.timestamp}
            className="block text-center p-3 text-sm"
          >
            {new Date(Number(entry.timestamp) * 1000).toLocaleString()}
          </time>
        </li>
      )),
    [monitorEntries]
  )

  return (
    <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pt-4">
      {lis}
    </ul>
  )
}

export default Entries
