import { DateTime } from "luxon"

export const DEFAULT_DATE_FORMAT = "MM-dd-yyyy"
export const DEFAULT_DATETIME_FORMAT = "LLL dd 'at' h:mm a"
export const DEFAULT_DATETIME_LONG_FORMAT = "LLL dd yyyy 'at' h:mm a"
export const DEFAULT_DATETIME_SECONDS_FORMAT = "LLL dd yyyy 'at' h:mm:ss a"
export const DEFAULT_DATETIME_DAY_FORMAT = "LL-dd-yyyy, EEEE 'at' hh:mm:ss a"

export const formatDateTimeObj = (
  dateTimeObj: DateTime,
  format?: string
): string => {
  const inThisYear = dateTimeObj.year === DateTime.now().year
  return dateTimeObj.toFormat(
    format ||
      (inThisYear ? DEFAULT_DATETIME_FORMAT : DEFAULT_DATETIME_LONG_FORMAT)
  )
}

export const formatDateTimeFromISO = (val: string, format?: string): string => {
  const dateTimeObj = DateTime.fromISO(val)
  return formatDateTimeObj(dateTimeObj, format)
}

export const formatDateTimeFromMillis = (
  val: number,
  format?: string
): string => {
  const dateTimeObj = DateTime.fromMillis(val)
  return formatDateTimeObj(dateTimeObj, format)
}

export const formatDateTimeFromTimestamp = (
  val: string,
  format?: string
): string => {
  if (Number.isNaN(parseInt(val))) return ""
  return formatDateTimeFromMillis(parseInt(val) * 1000, format)
}

export const getDateValueFromString = (
  date: string,
  format: string = DEFAULT_DATE_FORMAT
): string => (date && date !== "" ? formatDateTimeFromISO(date, format) : "")
