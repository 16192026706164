import { FC } from "react"
import { Plus } from "lucide-react"
import { RequireRole } from "../../common/validation"
import { ALLOWED_ROLES_BY_ROUTE } from "../../common/constants"
import { PageHeader, PrimaryBtn } from "../../common/ui"
import OrganizationsTable from "../../common/ui/organizations/table"
import { useFetchOrgListQuery } from "../../redux/queries"
import { useAppDispatch } from "../../common/hooks"
import { setVisiblePanel } from "../../redux/slices/app"

const OrganizationsPage: FC<{}> = () => {
  const dispatch = useAppDispatch()
  const { data, isFetching } = useFetchOrgListQuery({ limit: 999 })

  const onClickNew = () => {
    dispatch(setVisiblePanel("organization/new"))
  }

  return (
    <RequireRole allowedRoles={ALLOWED_ROLES_BY_ROUTE.organizations}>
      <>
        <div className="h-full p-6 bg-white">
          <PageHeader title="Organizations">
            <>
              <PrimaryBtn className="ml-3" onClick={onClickNew}>
                <Plus size={16} className="mr-1" /> New&nbsp;
                <span className="hidden sm:flex pl-1">Organization</span>
              </PrimaryBtn>
            </>
          </PageHeader>
          <OrganizationsTable data={data ?? []} isLoading={isFetching} />
        </div>
      </>
    </RequireRole>
  )
}

export default OrganizationsPage
