import { FC, useEffect, useState } from "react"
import { UserDetails } from "../../../types"
import MyAccountInfo from "./sections/MyAccountInfo"
import PasswordForm from "./sections/PasswordForm"
import OtherInfo from "./sections/OtherInfo"
import { useAppDispatch, useAppSelector, useToast } from "../../hooks"
import {
  logout,
  selectProfileUpdated,
  toggleProfileUpdated,
  updatePassword
} from "../../../redux/slices/auth"
import BBDialog from "../dialog"
import {
  useFetchLoanOfficerQuery,
  useUpdateLoanOfficerMutation
} from "../../../redux/queries"
import ImageUploader from "../image-uploader"
import { User } from "lucide-react"

interface Props {
  user: UserDetails
  passwordUpdated: boolean
}

const MyAccountSettings: FC<Props> = ({ user, passwordUpdated }) => {
  const dispatch = useAppDispatch()
  const { pushErrorToast, pushSuccessToast } = useToast()

  const profileUpdated = useAppSelector(selectProfileUpdated)
  const [editingSection, setEditingSection] = useState<"info" | "password">()

  const [updateLoanOfficer, { isSuccess, isError }] =
    useUpdateLoanOfficerMutation()

  const { data: loDetails } = useFetchLoanOfficerQuery(user.id, {
    skip: !user.id
  })

  useEffect(() => {
    if (isSuccess) {
      pushSuccessToast("Your account has been updated.")
      setEditingSection(undefined)
      dispatch(toggleProfileUpdated(true))
    }
    if (isError) {
      pushErrorToast("Failed to update account.")
    }
  }, [isSuccess, isError, pushErrorToast, pushSuccessToast, dispatch])

  const handleClickEditInfo = () =>
    setEditingSection(editingSection === "info" ? undefined : "info")
  const handleClickChangePwd = () =>
    setEditingSection(editingSection === "password" ? undefined : "password")

  useEffect(() => {
    if (!editingSection && !profileUpdated) return
    dispatch(toggleProfileUpdated(false))
  }, [dispatch, editingSection, profileUpdated])

  const handleUpdateEmail = (newEmail: string) => {
    if (!loDetails) return
    updateLoanOfficer({
      ...loDetails,
      email: newEmail
    })
  }

  const handleUpdateAvatar = (pictureURL: string) => {
    if (!loDetails) return
    updateLoanOfficer({
      ...loDetails,
      picture: pictureURL
    })
  }

  const handleUpdatePassword = (newPassword: string) =>
    dispatch(
      updatePassword({
        uid: user.id,
        password: newPassword
      })
    )

  const handleConfirmDialog = () => {
    dispatch(logout())
  }

  return (
    <div className="px-6">
      <div className="font-bold text-xl py-6">My account settings</div>

      <div className="px-4">
        <ImageUploader
          buttonLabel="Change"
          imageUrl={
            loDetails?.picture
              ? `${loDetails?.picture}?v=${loDetails?.updated}`
              : ""
          }
          onChangeImageUrl={handleUpdateAvatar}
          placeholder={<User size={96} color="#e5e5e5" />}
        />

        <MyAccountInfo
          info={user}
          inEditMode={editingSection === "info"}
          toggleEdit={handleClickEditInfo}
          onUpdateEmail={handleUpdateEmail}
        />
        <PasswordForm
          inEditMode={editingSection === "password"}
          toggleEdit={handleClickChangePwd}
          onUpdatePassword={handleUpdatePassword}
        />
        <OtherInfo info={user} />

        <BBDialog
          open={passwordUpdated}
          title="Success"
          body="Your password has successfully been updated."
          okLabel="Confirm"
          onOk={handleConfirmDialog}
          onClose={handleConfirmDialog}
        />
      </div>
    </div>
  )
}

export default MyAccountSettings
