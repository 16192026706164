import { FC, useEffect, useRef } from "react"
import { Formik, FormikProps } from "formik"
import { object, string } from "yup"
import { Trash2 } from "lucide-react"
import SidePanel from "../../../side-panel"
import { LeadworkflowDetails } from "../../../../../types"
import ManageLeadworkflowFormContent from "./form-content"
import {
  useDeleteLeadworkflowMutation,
  useFetchDefaultLeadworkflowDetailsQuery,
  useUpdateOrCreateLeadworkflowMutation,
  useFetchAllBrandsQuery
} from "../../../../../redux/queries"
import { useAppDispatch } from "../../../../hooks"
import { pushToast } from "../../../../../redux/slices/notifications"

interface Props {
  open: boolean
  data?: LeadworkflowDetails
  onClose: () => void
}

const LeadworkflowSchema = object({
  name: string().required().min(1),
  config_json: object().required(),
  brand_id: string().required().min(1)
})

const ManageLeadworkflow: FC<Props> = ({ open, data, onClose }) => {
  const dispatch = useAppDispatch()
  const [
    updateOrCreate,
    {
      isSuccess: isUpdateOrCreateSuccess,
      isError: isUpdateOrCreateError,
      data: updateOrCreateResponse,
      reset: resetUpdateOrCreateResponse
    }
  ] = useUpdateOrCreateLeadworkflowMutation()
  const [
    deleteLeadworkflow,
    {
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
      data: deleteResponse,
      reset: resetDeleteResponse
    }
  ] = useDeleteLeadworkflowMutation()

  const isCreate = !data?.id

  const { data: brands } = useFetchAllBrandsQuery({ limit: 999 })
  const { data: defaultData } = useFetchDefaultLeadworkflowDetailsQuery(
    {},
    { skip: !isCreate }
  )

  const formRef = useRef<FormikProps<LeadworkflowDetails>>(null)

  const initialValues: LeadworkflowDetails = data ?? {
    name: "",
    id: "",
    brand_id: "",
    ...(defaultData || { is_default: "0", config_json: {} })
  }

  const setFormField = (name: string, val: string | number | boolean) => {
    formRef.current?.setFieldValue(name, val)
  }

  const onSubmit = () => {
    if (formRef.current?.values) {
      updateOrCreate(formRef.current.values)
    }
  }

  const onDelete = () => {
    if (data?.id) {
      deleteLeadworkflow(data.id)
    }
  }

  useEffect(() => {
    if (
      isUpdateOrCreateSuccess &&
      updateOrCreateResponse?.status === "success"
    ) {
      dispatch(
        pushToast({
          msg: `Successfully ${isCreate ? "created" : "updated"} lead workflow`,
          theme: "success",
          timeout: 3000
        })
      )
      resetUpdateOrCreateResponse()
      formRef.current?.resetForm()
      onClose()
    }
  }, [
    dispatch,
    isCreate,
    isUpdateOrCreateSuccess,
    onClose,
    resetUpdateOrCreateResponse,
    updateOrCreateResponse?.status
  ])

  useEffect(() => {
    if (isUpdateOrCreateError || updateOrCreateResponse?.status === "error") {
      dispatch(
        pushToast({
          msg: `Failed to ${isCreate ? "create" : "update"} lead workflow`,
          theme: "danger",
          timeout: 3000
        })
      )
      resetUpdateOrCreateResponse()
    }
  }, [
    dispatch,
    isCreate,
    isUpdateOrCreateError,
    resetUpdateOrCreateResponse,
    updateOrCreateResponse?.status
  ])

  useEffect(() => {
    if (isDeleteSuccess && deleteResponse?.status === "success") {
      dispatch(
        pushToast({
          msg: "Successfully deleted lead workflow",
          theme: "success",
          timeout: 3000
        })
      )
      resetDeleteResponse()
      formRef.current?.resetForm()
      onClose()
    }
  }, [
    deleteResponse?.status,
    dispatch,
    isDeleteSuccess,
    onClose,
    resetDeleteResponse
  ])

  useEffect(() => {
    if (isDeleteError || deleteResponse?.status === "error") {
      dispatch(
        pushToast({
          msg: "Failed to delete lead workflow",
          theme: "danger",
          timeout: 3000
        })
      )
      resetDeleteResponse()
    }
  }, [deleteResponse?.status, dispatch, isDeleteError, resetDeleteResponse])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      innerRef={formRef}
      validationSchema={LeadworkflowSchema}
      enableReinitialize
    >
      {({ values, isValid, dirty }) => {
        return (
          <SidePanel
            title={
              isCreate ? "New lead workflow" : `Leadworkflow - ${data?.name}`
            }
            open={open}
            onClose={onClose}
            actions={[
              {
                label: "Delete",
                icon: <Trash2 size={16} />,
                type: "secondary",
                onClick: onDelete,
                hidden: isCreate,
                position: "left"
              },
              {
                label: "Cancel",
                type: "secondary",
                onClick: onClose
              },
              {
                label: isCreate ? "Create" : "Save Changes",
                type: "primary",
                onClick: onSubmit,
                disabled: !(isValid && dirty)
              }
            ]}
          >
            <ManageLeadworkflowFormContent
              values={values}
              brands={brands ?? []}
              setFormField={setFormField}
            />
          </SidePanel>
        )
      }}
    </Formik>
  )
}

export default ManageLeadworkflow
