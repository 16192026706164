import {
  RateTableInfoData,
  RateTableUsageType,
  RateTableUsageTypeColor,
  createRateTableInfoData,
  getRateTableInfoDataValue
} from "../utils"

export const useRateTableInfos = (
  containsData: boolean | undefined,
  seriesData: Record<RateTableUsageType, number[]> | undefined
): RateTableInfoData[] => {
  const infos: RateTableInfoData[] = []
  infos.push(
    createRateTableInfoData(
      RateTableUsageType.IMPRESSIONS.toLocaleUpperCase(),
      getRateTableInfoDataValue(
        containsData,
        seriesData,
        RateTableUsageType.IMPRESSIONS
      ),
      undefined,
      false
    )
  )
  infos.push(
    createRateTableInfoData(
      RateTableUsageType.UPDATE_RATES_CLICK.toLocaleUpperCase(),
      getRateTableInfoDataValue(
        containsData,
        seriesData,
        RateTableUsageType.UPDATE_RATES_CLICK
      ),
      RateTableUsageTypeColor.UPDATE_RATES_CLICK,
      true,
      "User ran a new pricing scenario on the rate table."
    )
  )
  infos.push(
    createRateTableInfoData(
      RateTableUsageType.GET_STARTED_CLICK.toLocaleUpperCase(),
      getRateTableInfoDataValue(
        containsData,
        seriesData,
        RateTableUsageType.GET_STARTED_CLICK
      ),
      RateTableUsageTypeColor.GET_STARTED_CLICK,
      true,
      "User clicked on 'Get Started' button on rate table."
    )
  )
  infos.push(
    createRateTableInfoData(
      RateTableUsageType.PERSONALIZED_RATES_CLICK.toLocaleUpperCase(),
      getRateTableInfoDataValue(
        containsData,
        seriesData,
        RateTableUsageType.PERSONALIZED_RATES_CLICK
      ),
      RateTableUsageTypeColor.PERSONALIZED_RATES_CLICK,
      true,
      "User clicked on 'Personalize My Rate' button on rate table."
    )
  )
  infos.push(
    createRateTableInfoData(
      RateTableUsageType.GET_RATE_ALERTS_CLICK.toLocaleUpperCase(),
      getRateTableInfoDataValue(
        containsData,
        seriesData,
        RateTableUsageType.GET_RATE_ALERTS_CLICK
      ),
      RateTableUsageTypeColor.GET_RATE_ALERTS_CLICK,
      true,
      "User clicked on 'Get Rate Alerts' button on rate table.",
      "pt-1.5"
    )
  )

  return infos
}
