import { selectToast } from "../../../redux/slices/notifications"
import { ToastType } from "../../../types"
import { useAppSelector } from "../../hooks"
import Toast from "../toast"

const ToastWrapper = () => {
  const toasts: ToastType[] = useAppSelector(selectToast)
  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-[101]"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          {toasts.map((toast: ToastType, i: number) => (
            <Toast
              key={i}
              msg={toast.msg}
              theme={toast.theme}
              timeout={toast.timeout}
              index={i}
            />
          ))}
        </div>
      </div>
    </>
  )
}

export default ToastWrapper
