import { FC } from "react"
import {
  convertOrgActivityToString,
  DEFAULT_DATETIME_FORMAT,
  formatDateTimeFromTimestamp
} from "../../../../utils"
import { OrgActivity } from "../../../../../types"
import RoundedImg from "../../../rounded-img"

type Props = {
  info: OrgActivity
  onClick: (id: string) => void
}

const DescriptionCell: FC<Props> = ({ info, onClick }) => {
  const { event_id, lo, timestamp } = info
  return (
    <td
      className="whitespace-nowrap py-4 pr-3 text-neutral-400 cursor-pointer"
      onClick={() => onClick(event_id)}
      colSpan={3}
    >
      <div className="block md:hidden text-sm text-gray-400 pb-4">
        {formatDateTimeFromTimestamp(timestamp, DEFAULT_DATETIME_FORMAT)}
      </div>
      <div className="flex text-neutral-900">
        <RoundedImg
          size={6}
          className="min-w-[1.5rem]"
          src={lo?.picture}
          alt={lo?.name}
        />
        <div className="flex-1 whitespace-normal ml-4">
          {convertOrgActivityToString(info)}
        </div>
      </div>
    </td>
  )
}

export default DescriptionCell
