/**
  API Key API wrapper

	JSON endpoint:  /aws_api/manage.json
	GET  /aws_api/manage.json?loid={loid} - Get current API key
	POST  /aws_api/manage.json?loid={loid} - Create new API key
	DELETE  /aws_api/manage.json?id={id} - Delete API key
**/

import { api } from "../common/utils"
import { APIKEY_ENDPOINT } from "../common/constants"
import { APIKeyRespose, APIKeyCreateResponse } from "../types"

const apiKey = {
  get: async (loid: string): Promise<APIKeyRespose> => {
    if (!loid) {
      throw new Error("loid missing")
    }
    const { data } = await api.get(APIKEY_ENDPOINT + "?loid=" + loid)
    return data.length ? data[0] : undefined
  },
  generate: async (loid: string): Promise<APIKeyCreateResponse> => {
    if (!loid) {
      throw new Error("loid missing")
    }
    const { data } = await api.post(APIKEY_ENDPOINT, { loid })
    return data
  },
  delete: async (id: string): Promise<any> => {
    if (!id) {
      throw new Error("id missing")
    }
    const { data } = await api.delete(APIKEY_ENDPOINT + "?id=" + id)
    return data
  }
}

export default apiKey
