import { ChangeEvent, FC } from "react"
import { Field } from "formik"
import { useIntegrationSettingsForm } from "../../../../hooks"
import { Integration } from "../../../../../types"
import { classNames } from "../../../../utils"
import embedIcon from "../../../../../assets/icons/embeds/lo-page.svg"
import apiIcon from "../../../../../assets/icons/embeds/api.svg"
import fullIcon from "../../../../../assets/icons/embeds/full.svg"
import standaloneIcon from "../../../../../assets/icons/embeds/standalone.svg"

const OPTIONS = [
  {
    icon: embedIcon,
    value: "boomtown",
    label: "Loan officer page embed",
    description:
      "This embed creates a full-page loan officer page that can have different components enabled like calculators, reviews, & rates."
  },
  {
    icon: standaloneIcon,
    value: "standalone",
    label: "Standalone embed",
    description:
      "You can select components that can live on a page with other content surrounding the component."
  },
  {
    icon: apiIcon,
    value: "api",
    label: "API embed",
    description:
      "Use this embed type if you want to launch an embed from a custom button on your website."
  },
  {
    icon: fullIcon,
    value: "widget",
    label: "Full embed",
    description: "A full-page embed that includes a header on top. "
  }
]

interface Props {
  integration?: Integration
  setFieldValue: (name: string, value: string) => void
}

const EmbedsOptions: FC<Props> = ({ integration, setFieldValue }) => {
  const { handleFormikChange } = useIntegrationSettingsForm(integration)

  const isEdit = integration?.id

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleFormikChange(e)
    setFieldValue("integration_type", e.target.value)
  }

  return (
    <div className="pt-6">
      <label htmlFor="type" className="block font-medium mb-6">
        Embed type
      </label>
      <div className="mt-1" role="group">
        {OPTIONS.map(option => (
          <label
            key={option.value}
            className={classNames(
              isEdit ? "" : "cursor-pointer",
              "flex items-start min-h-[4rem] pb-4"
            )}
          >
            <div className="flex shrink-0">
              <Field
                disabled={isEdit}
                onChange={onChange}
                type="radio"
                name="type"
                value={option.value}
                className="form-radio h-4 w-4 text-primary-400 transition duration-150 ease-in-out"
              />
              <img src={option.icon} alt="" className="h-8 w-8 ml-3" />
            </div>
            <div className="ml-4 text-neutral-400">
              <span className="font-normal">{option.label}</span>
              <p className="font-light">{option.description}</p>
            </div>
          </label>
        ))}
      </div>
    </div>
  )
}

export default EmbedsOptions
