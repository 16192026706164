import { createContext, useContext, useEffect, useRef, useState } from "react"
import { FormikProps } from "formik"
import {
  useDeleteIntegrationMutation,
  useSaveIntegrationMutation
} from "../../../../redux/queries"
import { selectMonitorID } from "../../../../redux/slices/integrations"
import {
  IntegrationStatus,
  Integration,
  Monitor as MonitorType,
  IntegrationPost
} from "../../../../types"
import { useAppSelector, useToast } from "../../../hooks"
import BBDialog from "../../dialog"
import CreateIntegration from "./create"
import EditIntegration from "./edit"

const IntegrationContext = createContext<any>({})

type Props = {
  open: boolean
  integration?: Integration
  statusData?: IntegrationStatus[]
  isLoadingStatus?: boolean
  isLoadingIntegration?: boolean
  onClose: () => void
}

const ManageIntegration = ({
  open,
  integration,
  statusData,
  isLoadingStatus = false,
  isLoadingIntegration = false,
  onClose
}: Props) => {
  const [deleteIntegration, { isSuccess, isError }] =
    useDeleteIntegrationMutation()

  const [
    saveIntegration,
    { reset, isSuccess: isSaveSuccess, isError: isSaveError }
  ] = useSaveIntegrationMutation()

  // get the monitor and integration ref to be able to submit the form
  const formMonitorRef = useRef<FormikProps<MonitorType>>(null)
  const formIntegrationRef = useRef<FormikProps<Integration>>(null)
  // Monitor tab
  const monitorID = useAppSelector(selectMonitorID)
  const [showMonitorForm, setShowMonitorForm] = useState(false)
  // Delete integration
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)

  const isCreate = !integration?.id

  const openDeleteModal = () => setDeleteModalVisible(true)
  const closeDeleteModal = () => setDeleteModalVisible(false)

  const onSubmitIntegration = () => formIntegrationRef.current?.submitForm()

  const onSubmitMonitor = () => formMonitorRef.current?.submitForm()

  const onToggleMonitorForm = () => setShowMonitorForm(prev => !prev)

  const handleDeleteIntegration = async () => {
    if (!isCreate && integration?.id) {
      const res = await deleteIntegration(integration?.id)
      if ("data" in res) {
        onClose()
      }
      closeDeleteModal()
    }
  }

  useEffect(() => {
    setShowMonitorForm(monitorID === 0)
  }, [monitorID])

  const { pushSuccessToast, pushErrorToast } = useToast()

  useEffect(() => {
    if (isSuccess) {
      pushSuccessToast("Integration has been deleted successfully")
    }
    if (isError) {
      pushErrorToast("Failed to delete integration")
    }
  }, [pushSuccessToast, pushErrorToast, isError, isSuccess])

  const onSaveSettings = (values: Integration) => {
    const {
      id,
      owner,
      url,
      title,
      type,
      accent_color,
      secondary_accent_color,
      app_settings
    } = values

    const {
      mort_calc = 0,
      afford_calc = 0,
      lead_workflow,
      pricing,
      pricing_set_id,
      settings
    } = app_settings ?? {}

    // set the values to the post object
    const postValues: IntegrationPost = {
      id,
      url,
      owner,
      title,
      type,
      mort_calc,
      afford_calc,
      lead_workflow,
      pricing,
      pricing_set_id,
      accent_color: accent_color ?? "",
      secondary_accent_color: secondary_accent_color ?? "",
      aboutBoxes: settings?.aboutBoxes ?? false,
      disableCalcCards: settings?.disableCalcCards ?? false,
      postUrl: settings?.postUrl ?? "",
      loReviews: settings?.loReviews || false,
      brandReviews: settings?.brandReviews ?? false,
      rateTable: settings?.rateTable ?? false,
      customParams: settings?.customParams ?? undefined,
      ga_tag: settings?.ga_tag ?? "",
      ga_mode: settings?.ga_mode ?? "",
      contactForm: settings?.contactForm ?? 0
    }

    if (!id) {
      delete postValues.id
    }

    saveIntegration(postValues)
  }

  useEffect(() => {
    if (isSaveSuccess) {
      pushSuccessToast(
        `Integration has been ${
          integration?.id ? "updated" : "created"
        } successfully`
      )
      reset()
      onClose()
    } else if (isSaveError) {
      pushErrorToast(
        `Failed to ${integration?.id ? "updated" : "created"} integration`
      )
    }
  }, [
    integration?.id,
    isSaveSuccess,
    isSaveError,
    onClose,
    reset,
    pushErrorToast,
    pushSuccessToast
  ])

  return (
    <IntegrationContext.Provider
      value={{ showMonitorForm, formMonitorRef, formIntegrationRef }}
    >
      {isCreate ? (
        <CreateIntegration
          open={open}
          onClose={onClose}
          onSubmitIntegration={onSubmitIntegration}
          onSaveSettings={onSaveSettings}
        />
      ) : (
        <EditIntegration
          integration={integration}
          isLoadingIntegration={isLoadingIntegration}
          statusData={statusData}
          isLoadingStatus={isLoadingStatus}
          open={open}
          monitorID={monitorID}
          onToggleMonitorForm={onToggleMonitorForm}
          onClose={onClose}
          onSubmitIntegration={onSubmitIntegration}
          onSubmitMonitor={onSubmitMonitor}
          openDeleteModal={openDeleteModal}
          onSaveSettings={onSaveSettings}
        />
      )}
      <BBDialog
        open={deleteModalVisible}
        onClose={closeDeleteModal}
        title="Delete integration"
        body="Are you sure you want to delete this integration?"
        okLabel="Yes, Delete"
        cancelLabel="Cancel"
        onOk={handleDeleteIntegration}
        onCancel={closeDeleteModal}
      />
    </IntegrationContext.Provider>
  )
}

const useShowMonitorForm = () => {
  const { showMonitorForm } = useContext(IntegrationContext)
  return showMonitorForm
}
const useFormMonitorRef = () => {
  const { formMonitorRef } = useContext(IntegrationContext)
  return formMonitorRef
}
const useFormIntegrationRef = () => {
  const { formIntegrationRef } = useContext(IntegrationContext)
  return formIntegrationRef
}

export default ManageIntegration

export { useShowMonitorForm, useFormMonitorRef, useFormIntegrationRef }
