import { useMemo } from "react"
import { Loader2 } from "lucide-react"
import { SidePanelActions } from "../../../types"
import ActionButton from "../buttons/action-button"
import If from "../if"

const Actions = ({
  actions,
  isSubmitting = false
}: {
  actions: SidePanelActions
  isSubmitting?: boolean
}) => {
  const leftActions = useMemo(
    () =>
      actions.filter(action => action.position === "left" && !action.hidden),
    [actions]
  )
  const rightActions = useMemo(
    () =>
      actions.filter(
        action =>
          (!action.position || action.position === "right") && !action.hidden
      ),
    [actions]
  )

  return (
    <div className="flex justify-between px-8 pt-4">
      <div className="flex flex-shrink-0 justify-start gap-3 relative">
        {leftActions.map(action => (
          <ActionButton key={action.label} {...action} />
        ))}
      </div>

      <div className="flex flex-shrink-0 justify-end gap-3 relative">
        <If
          condition={isSubmitting}
          then={
            <div className="absolute left-0 right-0 top-0 bottom-0 bg-white/25 z-10">
              <Loader2
                className="animate-spin h-12 w-12 text-primary-alt-400 m-auto"
                aria-hidden="true"
              />
            </div>
          }
        />
        {rightActions.map(action => (
          <ActionButton key={action.label} {...action} />
        ))}
      </div>
    </div>
  )
}

export default Actions
