import { FC, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { DateTime } from "luxon"
import { PlayCircle } from "lucide-react"

import ButtonGroup from "../../button-group"
import Table from "../../table"
import EngagementsCell from "./cells/engagements"
import MenuCell from "./cells/menu"
import OwnerCell from "./cells/owner"
import ViewDashboardCell from "./cells/view-dashboard"
import { LinkBtn } from "../../buttons"
import ManageEngagementPanel from "../manage"
import HowItWorksModal from "../../how-it-works-modal"
import {
  useFetchSubscriptionDetailsQuery,
  useFetchSubscriptionLogsQuery
} from "../../../../redux/queries"
import { useAppDispatch, useAppSelector } from "../../../hooks"
import { selectUserDetails } from "../../../../redux/slices/auth"
import {
  selectTabRange,
  updateRange
} from "../../../../redux/slices/engagements"
import {
  checkIsAdmin,
  checkIsSuperAdmin,
  copyToClipboard
} from "../../../utils"
import {
  SubscriptionLog,
  BBColumnDef,
  EngagementsTab,
  DateRange
} from "../../../../types"
import "./styles.scss"

interface Props {}

const EngagementsTable: FC<Props> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const userDetails = useAppSelector(selectUserDetails)

  const isAdmin = checkIsAdmin(userDetails?.roles ?? [])
  const isSuperAdmin = checkIsSuperAdmin(userDetails?.roles ?? [])

  const loid = userDetails?.id
  const orgId = userDetails?.org?.id
  const [selectedSubscriptionId, setSelectedSubscriptionId] =
    useState<string>("")
  const [showHowItWorksModal, setShowHowItWorksModal] = useState(false)

  const { data: selectedSubscription } = useFetchSubscriptionDetailsQuery(
    { id: selectedSubscriptionId },
    { skip: !selectedSubscriptionId }
  )

  const openLink = (link: string) => {
    if (link) {
      window.open(link, "_blank")
    }
  }

  const openLearnVideo = () => setShowHowItWorksModal(true)

  const navigateToDetails = (id: string) => navigate(`/engagements/${id}`)

  const resetSelectedSubscriptionId = () => setSelectedSubscriptionId("")

  const columns: BBColumnDef<SubscriptionLog>[] = [
    {
      id: "engagements",
      header: "Subscriptions",
      accessorFn: row => `${row.first_name} ${row.last_name} ${row.email}`,
      footer: props => props.column.id,
      cell: props => (
        <EngagementsCell
          info={props.row.original}
          onClick={navigateToDetails}
        />
      ),
      colSpan: 2
    },
    {
      id: "owner",
      header: "Owner",
      accessorFn: row => row.owner_email,
      footer: props => props.column.id,
      cell: props => (
        <OwnerCell info={props.row.original} onClick={navigateToDetails} />
      ),
      meta: { headerClassName: "hidden md:table-cell" }
    },
    {
      id: "view-dashboard",
      header: "",
      accessorFn: row => row.id,
      footer: props => props.column.id,
      cell: props => (
        <ViewDashboardCell info={props.row.original} openLink={openLink} />
      ),
      meta: { headerClassName: "hidden md:table-cell" },
      enableSorting: false
    },
    {
      id: "created",
      header: "",
      accessorFn: row => row.created,
      footer: props => props.column.id,
      cell: props => "",
      colSpan: 0,
      meta: { headerClassName: "hidden" }
    },
    {
      id: "edit",
      header: "",
      footer: props => props.column.id,
      enableSorting: false,
      cell: props => (
        <MenuCell
          info={props.row.original}
          onCopyDashboardLink={copyToClipboard}
          onEdit={setSelectedSubscriptionId}
          onViewLeadDashboard={openLink}
        />
      )
    }
  ]
  const tabKey = EngagementsTab.engagements
  const [range, setRange] = useState<DateRange>(
    useAppSelector(state => selectTabRange(state, tabKey))
  )
  const handleUpdateRange = (newValue: DateRange) => {
    dispatch(updateRange({ tabKey, newValue }))
    setRange(newValue)
  }
  const FILTER_BUTTONS = [
    {
      key: 1000,
      label: "All time",
      shortLabel: "All",
      disabled: false,
      onClick: () => handleUpdateRange(1000)
    },
    {
      key: 30,
      label: "30 Days",
      shortLabel: "30 D",
      disabled: false,
      onClick: () => handleUpdateRange(30)
    },
    {
      key: 7,
      label: "7 Days",
      shortLabel: "7 D",
      disabled: false,
      onClick: () => handleUpdateRange(7)
    },
    {
      key: 1,
      label: "24 hours",
      shortLabel: "24 H",
      disabled: false,
      onClick: () => handleUpdateRange(1)
    }
  ]
  const minCreated = useMemo(
    () => DateTime.now().minus({ days: range }),
    [range]
  )

  // subscription logs
  const { data: subscriptionLogs, isFetching } = useFetchSubscriptionLogsQuery({
    min_created: Math.floor(minCreated.toSeconds()),
    limit: 99999,
    offset: 0,
    ...(isSuperAdmin ? {} : isAdmin ? { org_id: orgId } : { loid })
  })

  return (
    <div>
      <Table
        className="engagements-table"
        isLoading={isFetching}
        data={subscriptionLogs || []}
        columns={columns}
        viewType="table"
        dropdownFilterColumnId="engagements"
        searchPlaceholder="Search subscriptions..."
        extraHeaderElement={
          <div className="ml-auto">
            <ButtonGroup buttons={FILTER_BUTTONS} active={range} />
          </div>
        }
        shrinkFilterSort
        defaultSortBy="created"
        defaultSortDir="desc"
        extraElementBeforeTable={
          <div className="px-2">
            <LinkBtn className="-my-1" onClick={openLearnVideo}>
              <PlayCircle size={20} />
              <span className="ml-2">Learn how it works</span>
            </LinkBtn>
          </div>
        }
      />

      <ManageEngagementPanel
        data={selectedSubscription}
        open={!!(selectedSubscriptionId && selectedSubscription)}
        onClose={resetSelectedSubscriptionId}
      />

      <HowItWorksModal
        videoType="engagements"
        show={showHowItWorksModal}
        toogleModal={setShowHowItWorksModal}
      />
    </div>
  )
}

export default EngagementsTable
