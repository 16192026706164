import { ReactNode } from "react"
import { Dialog } from "@headlessui/react"
import { ArrowLeft, X } from "lucide-react"
import { If } from ".."

const Header = ({
  title,
  subTitle,
  hasTabs,
  onClose,
  isBack
}: {
  title: ReactNode
  subTitle?: string
  hasTabs?: boolean
  onClose: () => void
  isBack?: boolean
}) => {
  const btnClass =
    "rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
  return (
    <div
      className={`px-4 sm:px-6 pb-6 ${
        hasTabs ? "" : "border-b border-gray-300"
      }`}
    >
      <div className="flex items-start justify-between">
        <div className="flex">
          <If
            condition={!!isBack}
            then={
              <div className="mr-3 flex h-7 items-center">
                <button type="button" className={btnClass} onClick={onClose}>
                  <span className="sr-only">Close panel</span>
                  <ArrowLeft className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            }
          />
          <div>
            <Dialog.Title className="text-lg font-medium text-gray-900">
              {title}
            </Dialog.Title>
            {subTitle && (
              <Dialog.Title className="text-sm font-light text-gray-900">
                {subTitle}
              </Dialog.Title>
            )}
          </div>
        </div>
        <If
          condition={!isBack}
          then={
            <div className="ml-3 flex h-7 items-center">
              <button type="button" className={btnClass} onClick={onClose}>
                <span className="sr-only">Close panel</span>
                <X className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          }
        />
      </div>
    </div>
  )
}
export default Header
