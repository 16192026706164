import { FC, useEffect, useRef, useState } from "react"
import JSONEditor, { JSONEditorOptions } from "jsoneditor"
import "jsoneditor/dist/jsoneditor.min.css"

type ReactJSONEditorProps = {
  json: any
  minHeight?: number
} & Pick<
  JSONEditorOptions,
  | "name"
  | "mode"
  | "onChange"
  | "onChangeJSON"
  | "onChangeText"
  | "search"
  | "enableSort"
>

const ReactJSONEditor: FC<ReactJSONEditorProps> = ({
  json,
  minHeight = 400,
  name = "JSON editor",
  mode = "tree",
  search = true,
  enableSort = false,
  onChange,
  onChangeJSON,
  onChangeText
}) => {
  const [editor, setEditor] = useState<JSONEditor | null>()
  const editorRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const editorOptions = {
      mode,
      name,
      enableTransform: false,
      search,
      enableSort,
      onChange,
      onChangeJSON,
      onChangeText
    }

    if (editorRef.current) {
      const jsonEditor = new JSONEditor(editorRef.current, editorOptions)
      jsonEditor.set(json)
      setEditor(jsonEditor)
    }

    return () => {
      if (!!editor) {
        editor.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (editor) {
      editor.setMode(mode)
    }
  }, [editor, mode])

  return (
    <div
      className="ReactJSONEditor"
      ref={editorRef}
      style={{ height: "100%", minHeight }}
    />
  )
}

export default ReactJSONEditor
