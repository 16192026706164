import { FC, useMemo } from "react"

import Accordion from "../../../accordion"
import UTMParametersSection from "./section"
import { useFetchUTMParametersQuery } from "../../../../../redux/queries"
import { calculateMaxCount, fetchOptions, fetchParams } from "../../../../utils"
import { UTMParametersStatsType } from "../../../../../types"

interface UTMParametersProps {
  title: string
  range: number
  orgId: string | undefined
  loid: string | undefined
}

const UTMParameters: FC<UTMParametersProps> = ({
  title,
  range,
  orgId,
  loid
}) => {
  const { data: campaignData = [], isFetching: isFetchingCampaign } =
    useFetchUTMParametersQuery(
      fetchParams(range, UTMParametersStatsType.CAMPAIGN, orgId, loid),
      fetchOptions
    )
  const maxCountCampaign = useMemo(
    () => calculateMaxCount(campaignData),
    [campaignData]
  )

  const { data: sourceData = [], isFetching: isFetchingSource } =
    useFetchUTMParametersQuery(
      fetchParams(range, UTMParametersStatsType.SOURCE, orgId, loid),
      fetchOptions
    )
  const maxCountSource = useMemo(
    () => calculateMaxCount(sourceData),
    [sourceData]
  )

  const { data: mediumData = [], isFetching: isFetchingMedium } =
    useFetchUTMParametersQuery(
      fetchParams(range, UTMParametersStatsType.MEDIUM, orgId, loid),
      fetchOptions
    )
  const maxCountMedium = useMemo(
    () => calculateMaxCount(mediumData),
    [mediumData]
  )

  return (
    <div className="flex flex-col flex-1 overflow-hidden">
      <Accordion
        title={title}
        childrenClassName="px-6 pt-2 pb-8"
        openByDefault={true}
        noBorder={true}
      >
        <div className="grid gap-x-0 md:gap-x-12 gap-y-6 md:gap-y-0 grid-cols-12 min-h-80">
          <UTMParametersSection
            label={UTMParametersStatsType.CAMPAIGN.toUpperCase()}
            data={campaignData}
            isFetching={isFetchingCampaign}
            maxCount={maxCountCampaign}
          />
          <UTMParametersSection
            label={UTMParametersStatsType.SOURCE.toUpperCase()}
            data={sourceData}
            isFetching={isFetchingSource}
            maxCount={maxCountSource}
          />
          <UTMParametersSection
            label={UTMParametersStatsType.MEDIUM.toUpperCase()}
            data={mediumData}
            isFetching={isFetchingMedium}
            maxCount={maxCountMedium}
          />
        </div>
      </Accordion>
    </div>
  )
}

export default UTMParameters
