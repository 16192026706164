import { useFormikContext } from "formik"
import {
  useUpdateIntegrationMutation,
  useUpdateOwnerMutation
} from "../../redux/queries"
import { pushToast } from "../../redux/slices/notifications"
import { ApprovedUrl, Integration, ApprovedUrls } from "../../types"
import { useDebouncedCallback } from "./use-debounced-callback"
import { useAppDispatch } from "./use-store"

type UpdatedField = {
  [key: string]: string | number | boolean | null | any[] | ApprovedUrl | {}
}

export const useIntegrationSettingsForm = (integration?: Integration) => {
  const {
    values,
    setFieldValue,
    handleBlur,
    handleChange,
    dirty,
    isSubmitting,
    isValid,
    errors
  } = useFormikContext<Integration>()
  const [updateIntegration] = useUpdateIntegrationMutation()
  const [updateOwner] = useUpdateOwnerMutation()
  const dispatch = useAppDispatch()

  const app_settings = values.app_settings

  // save settings to the formik context and to the server
  const saveSetting = (updatedField: UpdatedField) => {
    updateSettings(updatedField)
    //const { type } = integration ?? {}
    //updateFieldToServer({ [type as string]: updatedField })
  }

  /**
   * To auto update the color picker wheel must be debounded to avoid saving the value while the user is still selecting the color
   */
  const saveSettingDebounced = (updatedField: UpdatedField) => {
    updateSettings(updatedField)
    updateFieldToServerDebounced(updatedField)
  }

  const updateFieldToServerDebounced = useDebouncedCallback(
    (updatedField: UpdatedField) => {
      const { type } = integration ?? {}
      updateFieldToServer({ [type as string]: updatedField })
    },
    1000,
    true
  )
  // End color picker wheel debounced

  // save settings to the formik context only
  const saveSettingLocal = (updatedField: UpdatedField) => {
    updateSettings(updatedField)
  }

  // Update "settings" object in the formik context
  const updateSettings = (updatedField: UpdatedField) => {
    const settings = app_settings?.settings
    const newSettings = { ...settings, ...updatedField }
    setFieldValue("app_settings.settings", newSettings)
  }

  const saveAppSetting = (updatedField: UpdatedField) => {
    const newAppSettings = {
      ...app_settings,
      ...updatedField,
      settings: app_settings?.settings
    }
    setFieldValue("app_settings", newAppSettings)
    //const { type } = integration ?? {}
    //updateFieldToServer({ [type as string]: updatedField })
  }

  // Update a field in the server
  const updateFieldToServer = async (
    updatedField: UpdatedField,
    isDomain?: boolean
  ) => {
    try {
      const { type, owner, id } = integration ?? {}

      await updateIntegration({
        id,
        owner,
        integration_type: type,
        field: updatedField,
        isDomain
      }).unwrap()

      dispatch(
        pushToast({
          theme: "success",
          msg: "Integration has been updated successfully",
          timeout: 3000
        })
      )
    } catch (error) {
      dispatch(
        pushToast({
          theme: "danger",
          msg: "Something went wrong",
          timeout: 3000
        })
      )
    }
  }

  // Update integration owner
  const updateIntegrationOwner = (newOwnerID: string) => {
    if (!integration) return
    const { type, id, owner } = integration

    updateOwner({
      id,
      integration_type: type,
      newOwnerID,
      owner
    })
  }

  // this is just for the title and url
  const updateTextFieldIfChanged = (
    field: string,
    value: string | 1 | 0 | any[] | null | ApprovedUrl
  ) => {
    // Check if the value changed and if so, update the server
    if (integration?.[field as keyof typeof integration] !== value) {
      updateFieldToServer({ [field]: value })
    }
  }

  // Used for the domains array
  const updateDomainIfChanged = (domain: ApprovedUrl) => {
    if (!integration?.domains) {
      return
    }
    const domains: ApprovedUrls = integration.domains
    // get the domain from the list of domains that matches the domain id
    const domainToUpdate = domains?.find(
      d => d?.id === domain.id
    ) as ApprovedUrl

    // Check if the value changed and if so, update the server
    if (domainToUpdate?.domain_pattern !== domain.domain_pattern) {
      //updateFieldToServer({ domain }, true)
    }
  }

  return {
    integration,
    app_settings,
    saveSetting,
    saveAppSetting,
    saveSettingDebounced,
    saveSettingLocal,
    updateTextFieldIfChanged,
    updateDomainIfChanged,
    updateFieldToServer,
    updateIntegrationOwner,
    handleFormikBlur: handleBlur,
    handleFormikChange: handleChange,
    formikDirty: dirty,
    formikIsSubmitting: isSubmitting,
    formikIsValid: isValid,
    values,
    errors
  }
}
