import { FC, memo, useEffect, useMemo, useState } from "react"
import { Disclosure } from "@headlessui/react"
import { ChevronRight, DotIcon } from "lucide-react"
import { NavLink, useLocation } from "react-router-dom"

import { classNames } from "../../utils"
import { NavItemType } from "../../../types"

type DisclosureNavbarLinksProps = {
  item: NavItemType
  isFull?: boolean
  isMobile?: boolean
  setClassList: (isActive: boolean) => string
  setIconClassList: (isActive: boolean, hasNoMargin?: boolean) => string
}

const DisclosureNavbarLinks: FC<DisclosureNavbarLinksProps> = memo(
  ({ item, isFull, isMobile, setClassList, setIconClassList }) => {
    const [isActive, setIsActive] = useState(false)
    const location = useLocation()
    const isUrlActive = useMemo(
      () =>
        (item: NavItemType): boolean =>
          item.href === location.pathname,
      [location.pathname]
    )

    const checkSubItemActive = useMemo(
      () => (href: string) => {
        const currentPath = `${location.pathname}${location.search}`
        return currentPath === href
      },
      [location.pathname, location.search]
    )

    useEffect(() => {
      setIsActive(isUrlActive(item))
    }, [isUrlActive, item])

    return (
      <Disclosure defaultOpen={isUrlActive(item)}>
        {({ open }) => (
          <>
            <Disclosure.Button
              as="a"
              key={`menu-button-${item.key}`}
              className={classNames(setClassList(isActive), "cursor-pointer")}
            >
              <div className="w-full flex items-center justify-between">
                <div className="flex items-center">
                  <item.icon
                    size={20}
                    aria-hidden="true"
                    className={setIconClassList(isActive)}
                  />
                  {isFull || isMobile ? item.name : ""}
                </div>
                <ChevronRight
                  size={20}
                  className={classNames(
                    open ? "-rotate-90 text-gray-500" : "text-gray-400",
                    setIconClassList(isActive, true)
                  )}
                  aria-hidden="true"
                />
              </div>
            </Disclosure.Button>
            <Disclosure.Panel>
              {item.children?.map((subItem, index) => {
                const isSubItemActive = checkSubItemActive(subItem.href)
                return (
                  <NavLink
                    key={`sub-menu-${subItem.key}-${index}`}
                    to={subItem.href}
                    className={classNames(
                      setClassList(false),
                      isMobile ? "pl-11" : "pl-10"
                    )}
                  >
                    <>
                      <div className={isSubItemActive ? "text-white" : ""}>
                        {isFull || isMobile ? subItem.name : ""}
                      </div>
                      {isSubItemActive && (
                        <DotIcon
                          size={20}
                          strokeWidth={4}
                          className="text-white"
                        />
                      )}
                    </>
                  </NavLink>
                )
              })}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    )
  }
)

export default DisclosureNavbarLinks
