import { FC, useEffect, useMemo, useRef } from "react"
import { Formik, FormikProps } from "formik"
import { object, string } from "yup"
import SidePanel from "../../../side-panel"
import { SubscriptionConfigDetails } from "../../../../../types"
import ManageSubscriptionConfigFormContent from "./form-content"
import {
  useFetchAllBrandsQuery,
  useUpdateOrCreateSubscriptionConfigMutation
} from "../../../../../redux/queries"
import { useAppDispatch } from "../../../../hooks"
import { pushToast } from "../../../../../redux/slices/notifications"
import DefaultConfig from "./default-config.json"

interface Props {
  open: boolean
  data?: SubscriptionConfigDetails
  onClose: () => void
}

const SubscriptionConfigSchema = object({
  enabled: string().required(),
  brand_id: string().required().min(1),
  config: object().required()
})

const ManageSubscriptionConfig: FC<Props> = ({ open, data, onClose }) => {
  const dispatch = useAppDispatch()
  const formRef = useRef<FormikProps<SubscriptionConfigDetails>>(null)

  const [
    updateOrCreate,
    {
      data: updateOrCreateResponse,
      isSuccess,
      isError,
      reset: resetUpdateOrCreateResponse
    }
  ] = useUpdateOrCreateSubscriptionConfigMutation()

  const { data: brands } = useFetchAllBrandsQuery({ limit: 999 })
  const brand = useMemo(
    () => brands?.find(b => b.id === data?.brand_id),
    [brands, data?.brand_id]
  )

  const isCreate = !data?.id

  const initialValues: SubscriptionConfigDetails = data ?? {
    id: "",
    enabled: "0",
    created: "",
    brand_id: "",
    config: DefaultConfig
  }

  const setFormField = (name: string, val: string | number | boolean) => {
    formRef.current?.setFieldValue(name, val)
  }

  const onSubmit = () => {
    if (formRef.current?.values) {
      updateOrCreate(formRef.current.values)
    }
  }

  useEffect(() => {
    if (isSuccess && updateOrCreateResponse?.status === "success") {
      dispatch(
        pushToast({
          msg: `Successfully ${
            isCreate ? "created" : "updated"
          } subscription config`,
          theme: "success",
          timeout: 3000
        })
      )
      resetUpdateOrCreateResponse()
      formRef.current?.resetForm()
      onClose()
    }
  }, [
    dispatch,
    isCreate,
    isSuccess,
    onClose,
    resetUpdateOrCreateResponse,
    updateOrCreateResponse?.status
  ])

  useEffect(() => {
    if (isError || updateOrCreateResponse?.status === "error") {
      dispatch(
        pushToast({
          msg: `Failed to ${
            isCreate ? "create" : "update"
          } subscription config`,
          theme: "danger",
          timeout: 3000
        })
      )
      resetUpdateOrCreateResponse()
    }
  }, [
    dispatch,
    isCreate,
    isError,
    resetUpdateOrCreateResponse,
    updateOrCreateResponse?.status
  ])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      innerRef={formRef}
      validationSchema={SubscriptionConfigSchema}
      enableReinitialize
    >
      {({ values, isValid, dirty, errors }) => {
        return (
          <SidePanel
            title={
              isCreate
                ? "Create subscription config"
                : `${brand?.name || "Unknown brand"} Subscription Config`
            }
            open={open}
            onClose={onClose}
            actions={[
              {
                label: "Save Changes",
                type: "primary",
                onClick: onSubmit,
                disabled: !(isValid && dirty)
              }
            ]}
          >
            <ManageSubscriptionConfigFormContent
              values={values}
              brands={brands ?? []}
              setFormField={setFormField}
            />
          </SidePanel>
        )
      }}
    </Formik>
  )
}

export default ManageSubscriptionConfig
