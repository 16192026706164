import { FC } from "react"
import {
  DEFAULT_DATETIME_FORMAT,
  formatDateTimeFromTimestamp
} from "../../../../utils"

type Props = {
  info: any
}

const TimestampCell: FC<Props> = ({ info }) => {
  return (
    <td className="hidden md:table-cell whitespace-nowrap py-2 px-3 text-neutral-400 text-right">
      {formatDateTimeFromTimestamp(info.timestamp, DEFAULT_DATETIME_FORMAT)}
    </td>
  )
}

export default TimestampCell
