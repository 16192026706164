import { useNavigate } from "react-router-dom"
import { Plus } from "lucide-react"
import { PageHeader, PrimaryBtn } from "../../common/ui"
import { LOsTableWithImporter } from "../../common/ui/loan-officers"
import { RequireRole } from "../../common/validation"
import { ALLOWED_ROLES_BY_ROUTE } from "../../common/constants"
import { useAppDispatch, useAppSelector } from "../../common/hooks"
import { setVisiblePanel } from "../../redux/slices/app"
import { checkIsSuperAdmin } from "../../common/utils"
import { selectUserDetails } from "../../redux/slices/auth"

const LOPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUserDetails)

  const isSuperAdmin = checkIsSuperAdmin(user?.roles ?? [])

  const onCreate = () => dispatch(setVisiblePanel("loan-officer/new"))

  const onClickLO = (loanOfficerId: string) => {
    navigate(`/loan-officers/${loanOfficerId}`)
  }

  return (
    <RequireRole allowedRoles={ALLOWED_ROLES_BY_ROUTE["loan-officers"]}>
      <div className="p-6 bg-white">
        <PageHeader title="Loan officers">
          <PrimaryBtn onClick={onCreate}>
            <Plus size={16} className="mr-1" /> New LO
          </PrimaryBtn>
        </PageHeader>

        <LOsTableWithImporter
          searchParams={{ isSuperAdmin, org_id: user?.org?.id }}
          hasMasqueradeIcon={true}
          onClickLO={onClickLO}
        />
      </div>
    </RequireRole>
  )
}

export default LOPage
