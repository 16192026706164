import { FC, useEffect, useRef } from "react"
import { Formik, FormikProps } from "formik"
import { object, string } from "yup"
import SidePanel from "../../../side-panel"
import { ContactFormDetails } from "../../../../../types"
import ManageRateflowFormContent from "./form-content"
import {
  useFetchAllBrandsQuery,
  useFetchDefaultContactFormConfigQuery,
  useUpdateOrCreateContactFormConfigMutation
} from "../../../../../redux/queries"
import { useAppDispatch } from "../../../../hooks"
import { pushToast } from "../../../../../redux/slices/notifications"

interface Props {
  open: boolean
  data?: ContactFormDetails
  onClose: () => void
}

const ContactFormSchema = object({
  name: string().required().min(1),
  config: object().required()
})

const ManageContactForm: FC<Props> = ({ open, data, onClose }) => {
  const dispatch = useAppDispatch()

  const formRef = useRef<FormikProps<ContactFormDetails>>(null)

  const { data: brands } = useFetchAllBrandsQuery({ limit: 999 })
  const { data: defaultConfig } = useFetchDefaultContactFormConfigQuery({})
  const [
    updateOrCreate,
    {
      data: updateOrCreateResponse,
      isSuccess,
      isError,
      reset: resetUpdateOrCreateResponse
    }
  ] = useUpdateOrCreateContactFormConfigMutation()

  const isCreate = !data?.id

  const initialValues: ContactFormDetails = data ?? {
    name: "",
    id: "",
    brand_id: "",
    created: "",
    updated: "",
    ...(defaultConfig || {
      is_default: "0",
      config: {}
    })
  }

  const setFormField = (name: string, val: string | number | boolean) => {
    formRef.current?.setFieldValue(name, val)
  }

  const onSubmit = () => {
    if (formRef.current?.values) {
      updateOrCreate(formRef.current.values)
    }
  }

  useEffect(() => {
    if (isSuccess && updateOrCreateResponse?.status === "success") {
      dispatch(
        pushToast({
          msg: `Successfully ${isCreate ? "created" : "updated"} contact form`,
          theme: "success",
          timeout: 3000
        })
      )
      resetUpdateOrCreateResponse()
      formRef.current?.resetForm()
      onClose()
    }
  }, [
    dispatch,
    isCreate,
    isSuccess,
    onClose,
    resetUpdateOrCreateResponse,
    updateOrCreateResponse?.status
  ])

  useEffect(() => {
    if (isError || updateOrCreateResponse?.status === "error") {
      dispatch(
        pushToast({
          msg: `Failed to ${isCreate ? "create" : "update"} contact form`,
          theme: "danger",
          timeout: 3000
        })
      )
      resetUpdateOrCreateResponse()
    }
  }, [
    dispatch,
    isCreate,
    isError,
    resetUpdateOrCreateResponse,
    updateOrCreateResponse?.status
  ])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      innerRef={formRef}
      validationSchema={ContactFormSchema}
      enableReinitialize
    >
      {({ values, isValid, dirty }) => {
        return (
          <SidePanel
            title={
              isCreate ? "Create contact form" : `Contact Form - ${data?.name}`
            }
            open={open}
            onClose={onClose}
            actions={[
              {
                label: "Save Changes",
                type: "primary",
                onClick: onSubmit,
                disabled: !(isValid && dirty)
              }
            ]}
          >
            <ManageRateflowFormContent
              values={values}
              brands={brands ?? []}
              setFormField={setFormField}
            />
          </SidePanel>
        )
      }}
    </Formik>
  )
}

export default ManageContactForm
