import { configureStore, combineReducers, AnyAction } from "@reduxjs/toolkit"
import authReducer, { STORE_RESET } from "./slices/auth"
import integrationsReducer from "./slices/integrations"
import notificationsReducer from "./slices/notifications"
import { engagementsReducer } from "./slices/engagements"
import appReducer from "./slices/app"
import {
  activitiesApi,
  approvedUrlsApi,
  brandsApi,
  configApi,
  contactFormsApi,
  integrationsApi,
  leadWorkflowApi,
  leadsApi,
  loanOfficerApi,
  orgApi,
  pricingSetsApi,
  rateflowApi,
  subscriptionsApi,
  dashboardApi
} from "./queries"
import { handleFetchRequestsMiddleware } from "./middlewares"

const reducer = combineReducers({
  auth: authReducer,
  integrations: integrationsReducer,
  notifications: notificationsReducer,
  app: appReducer,
  engagements: engagementsReducer,
  [approvedUrlsApi.reducerPath]: approvedUrlsApi.reducer,
  [activitiesApi.reducerPath]: activitiesApi.reducer,
  [brandsApi.reducerPath]: brandsApi.reducer,
  [contactFormsApi.reducerPath]: contactFormsApi.reducer,
  [integrationsApi.reducerPath]: integrationsApi.reducer,
  [leadWorkflowApi.reducerPath]: leadWorkflowApi.reducer,
  [leadsApi.reducerPath]: leadsApi.reducer,
  [loanOfficerApi.reducerPath]: loanOfficerApi.reducer,
  [orgApi.reducerPath]: orgApi.reducer,
  [pricingSetsApi.reducerPath]: pricingSetsApi.reducer,
  [rateflowApi.reducerPath]: rateflowApi.reducer,
  [subscriptionsApi.reducerPath]: subscriptionsApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [configApi.reducerPath]: configApi.reducer
})

const rootReducer = (
  state: ReturnType<typeof reducer> | undefined,
  action: AnyAction
) => {
  if (action.type === STORE_RESET) {
    return reducer(undefined, action)
  }
  return reducer(state, action)
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      activitiesApi.middleware,
      approvedUrlsApi.middleware,
      brandsApi.middleware,
      contactFormsApi.middleware,
      integrationsApi.middleware,
      leadWorkflowApi.middleware,
      leadsApi.middleware,
      loanOfficerApi.middleware,
      orgApi.middleware,
      pricingSetsApi.middleware,
      rateflowApi.middleware,
      subscriptionsApi.middleware,
      configApi.middleware,
      dashboardApi.middleware,
      handleFetchRequestsMiddleware
    ),
  devTools: process.env.NODE_ENV !== "production"
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
