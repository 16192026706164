import { FC } from "react"
import { Organization } from "../../../../types"
import SectionCell from "../../section/cell"
import { formatDateTimeFromTimestamp } from "../../../utils"

interface Props {
  data: Organization
}

const OrgDetails: FC<Props> = ({ data }) => {
  return (
    <div className="h-full p-6 bg-white">
      <SectionCell label="Website URL" value={data.website} />
      <SectionCell
        label="Submitted by"
        value={`Admin on ${formatDateTimeFromTimestamp(
          data.created,
          "LL/dd/yyyy - hh:mm a"
        )}`}
      />
      <SectionCell label="Financial Disclaimer" value={data.disclaimer} />
      <SectionCell
        label="Facebook Share Description"
        value={data.fb_share_desc}
      />
      {/* <SectionCell label="Arch MI Email ID" value={data} /> */}
      {/* <SectionCell label="OB Business Channel ID" value={data} /> */}
    </div>
  )
}

export default OrgDetails
