import { FC, useCallback } from "react"
import { Brand, GooglePlace } from "../../../../../types"
import AddressAutocomplete from "../../../address-autocomplete"
import Input from "../../../form/input"

interface Props {
  values: Brand
  initialValues: Brand
  setFormField: (name: string, value: any) => void
}

const BrandReviewSettings: FC<Props> = ({ initialValues, setFormField }) => {
  const handleChangeGooglePlace = useCallback(
    (value: GooglePlace) => setFormField("place_id", value.place_id),
    [setFormField]
  )

  return (
    <>
      <div className="pt-8">
        <AddressAutocomplete
          label="Connect Google reviews of brand"
          name="place_id"
          placeholder="Search google reviews of brand"
          initialValue={initialValues.place_id}
          onChange={handleChangeGooglePlace}
        />
      </div>
      <div className="pt-8">
        <Input
          label="Connect Zillow reviews of brand"
          name="zillow_brand_name"
          placeholder="Zillow brand name"
        />
      </div>
      <div className="pt-8">
        <Input
          label="Connect Experience.com reviews of brand"
          name="experience_id"
          placeholder="Experience.com ID"
        />
      </div>
      <div className="pt-8">
        <Input
          label="Connect Yelp reviews of brand"
          name="yelp_id"
          placeholder="Yelp ID"
        />
      </div>
    </>
  )
}

export default BrandReviewSettings
