import { FC } from "react"
import { SidePanelAction } from "../../../../types"
import PrimaryBtn from "../primary-btn"
import Button from "../button"

const ActionButton: FC<SidePanelAction> = ({ type, label, icon, ...rest }) => {
  return type === "primary" ? (
    <PrimaryBtn key={label} {...rest}>
      {icon ? <span className="mr-1">{icon}</span> : null}
      {label}
    </PrimaryBtn>
  ) : (
    <Button key={label} {...rest}>
      {icon ? <span className="mr-1">{icon}</span> : null}
      {label}
    </Button>
  )
}

export default ActionButton
