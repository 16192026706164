import { formatNumber } from "../../../utils"

interface Props {
  label: string
  value: number
}

const StatItem = ({ label, value }: Props) => {
  return (
    <div>
      <div className="text-gray-500 uppercase text-sm">{label}</div>
      <div className="font-bold text-xl pt-1">
        {label.endsWith("rate")
          ? `${formatNumber(value, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1
            })}%`
          : formatNumber(value, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2
            })}
      </div>
    </div>
  )
}

export default StatItem
