import { FC } from "react"
import { LoanOfficer, SubscriptionDetails } from "../../../../../../types"
import Accordion from "../../../../accordion"
import SectionCell from "../../../../section/cell"
import LoFields from "./accordion-sections/lo-fields"
import LoanScenario from "./accordion-sections/loan-scenario"
import PropertyLocation from "./accordion-sections/property-location"
import {
  formatPhoneNumber,
  formatDateTimeFromMillis
} from "../../../../../utils"

interface Props {
  data: SubscriptionDetails
  loDetails: LoanOfficer
}

const EngagementSummary: FC<Props> = ({ data, loDetails }) => {
  return (
    <div className="max-w-3xl">
      <div className="grid sm:grid-cols-2 gap-4">
        <SectionCell label="First name" value={data.first_name} />
        <SectionCell label="Last name" value={data.last_name} />
        <SectionCell label="Email address" value={data.email} />
        <SectionCell
          label="Phone number"
          value={formatPhoneNumber(data.phone)}
        />
        <SectionCell label="Created by" value={loDetails.email} />
        <SectionCell
          label="Created on"
          value={formatDateTimeFromMillis(parseInt(data.created) * 1000)}
        />
      </div>

      <div className="mt-12">
        <div className="my-4">
          <Accordion title="Property location">
            <PropertyLocation data={data} />
          </Accordion>
        </div>
        <div className="my-4">
          <Accordion title="Loan scenario">
            <LoanScenario data={data} />
          </Accordion>
        </div>
        <div className="my-4">
          <Accordion title="Loan officer fields">
            <LoFields data={data} />
          </Accordion>
        </div>
      </div>
    </div>
  )
}

export default EngagementSummary
