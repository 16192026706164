import { FC, useState } from "react"
import { Loader2 } from "lucide-react"
import { PricingLog, RateflowCard } from "../../../../types"
import SidePanel from "../../side-panel"
import Tabs from "../../tabs"
import ReactJSONEditor from "../../react-json-editor"
import If from "../../if"
import CardQuoteDetails from "../../card-quote-details"
import Cards from "./cards"
import { useFindRateflowLogByIdQuery } from "../../../../redux/queries"
import {
  DEFAULT_DATETIME_SECONDS_FORMAT,
  formatDateTimeFromTimestamp
} from "../../../utils"

interface Props {
  open: boolean
  onClose: () => void
  data?: PricingLog
}

const TABS = [
  {
    id: "cards",
    label: "Cards"
  },
  {
    id: "details",
    label: "Details"
  }
]

type TabId = "cards" | "details"

const PricingLogCardDetails: FC<Props> = ({ open, onClose, data }) => {
  const [currentTab, setCurrentTab] = useState<TabId>("cards")
  const [openCardQuoteDetails, setOpenCardQuoteDetails] = useState<{
    isOpen: boolean
    data?: RateflowCard
  }>({
    isOpen: false
  })
  const { data: rate, isLoading } = useFindRateflowLogByIdQuery(
    data?.id as string,
    {
      skip: !data?.id
    }
  )

  const onTabChange = (tabId: string) => setCurrentTab(tabId as TabId)
  const onViewDetails = (data: RateflowCard) => {
    setOpenCardQuoteDetails({
      isOpen: true,
      data
    })
  }
  const onCloseViewDetails = () => {
    setOpenCardQuoteDetails({
      isOpen: false
    })
  }

  const loanAmount = rate?.full_request?.bb_request?.loan_amount || 0

  return (
    <>
      <SidePanel
        title={data?.ob_profile_name}
        subTitle={`Created on ${formatDateTimeFromTimestamp(
          data?.timestamp as string,
          DEFAULT_DATETIME_SECONDS_FORMAT
        )}`}
        open={open}
        onClose={onClose}
      >
        <div className="-mx-6 h-full">
          <If
            condition={!isLoading}
            then={
              <>
                <Tabs
                  currentTab={currentTab}
                  tabs={TABS}
                  onChange={onTabChange}
                />
                <If
                  condition={currentTab === "cards"}
                  then={
                    <div className="p-6 grid grid-cols-2 md:grid-cols-3 gap-6">
                      <If
                        condition={!!rate?.cards?.length}
                        then={rate?.cards?.map(rate => (
                          <Cards
                            key={rate.hash}
                            data={rate}
                            loanAmount={loanAmount}
                            onViewDetails={onViewDetails}
                          />
                        ))}
                        else={<div>No cards found for this log.</div>}
                      />
                    </div>
                  }
                />
                <If
                  condition={currentTab === "details"}
                  then={
                    <div className="pt-6">
                      <ReactJSONEditor
                        name="plDetails"
                        json={rate}
                        mode="view"
                      />
                    </div>
                  }
                />
              </>
            }
            else={
              <div className="h-full flex justify-center items-center">
                <Loader2
                  className="animate-spin h-16 w-16 text-primary-alt-400"
                  aria-hidden="true"
                />
              </div>
            }
          />
        </div>
      </SidePanel>
      <If
        condition={openCardQuoteDetails.isOpen}
        then={
          <SidePanel
            title="Quote details"
            open={openCardQuoteDetails.isOpen}
            onClose={onCloseViewDetails}
            isBack
          >
            <div className="h-full flex flex-col">
              <CardQuoteDetails
                row={openCardQuoteDetails.data}
                loanAmount={loanAmount}
              />
            </div>
          </SidePanel>
        }
      />
    </>
  )
}

export default PricingLogCardDetails
