import { Search } from "lucide-react"
import DebouncedInput from "./debouncedInput"

type Props = {
  value: string
  placeholder: string
  className?: string
  wrapperClassName?: string
  onSearch: (value: string | number) => void
}

const SearchInput = ({
  value,
  placeholder,
  onSearch,
  className = "",
  wrapperClassName = ""
}: Props) => (
  <div
    className={`relative lg:max-w-sm text-gray-400 focus-within:text-gray-600 ${wrapperClassName}`}
  >
    <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
      <Search size={20} aria-hidden="true" />
    </div>
    <DebouncedInput
      placeholder={placeholder}
      value={value ?? ""}
      onChange={onSearch}
      className={className}
    />
  </div>
)

export default SearchInput
