import { ChangeEvent, FC } from "react"
import { Brand } from "../../../../../types"
import Form from "../../../form"
import Input from "../../../form/input"
import USStateSelector, { STATES } from "../../../form/us-state-selector"
import Label from "../../../form/label"

interface Props {
  values: Brand
  setFormField: (name: string, value: any) => void
}

const BrandColors: FC<Props> = ({ values, setFormField }) => {
  const selectedState = STATES.find(s => s.id === values?.state)

  const onChangePrimaryColor = (e: ChangeEvent<HTMLInputElement>) => {
    setFormField("primary_color", e.target.value)
  }

  const onChangeSecondaryColor = (e: ChangeEvent<HTMLInputElement>) => {
    setFormField("secondary_color", e.target.value)
  }

  return (
    <div className="grid gap-y-6 gap-x-4 grid-cols-12 pt-8">
      <div className="col-span-6">
        <Form.InputColorPicker
          label="Primary color"
          name="primary_color"
          value={values.primary_color}
          onBlur={onChangePrimaryColor}
          onChange={onChangePrimaryColor}
          onChangeWhell={onChangePrimaryColor}
        />
      </div>
      <div className="col-span-6">
        <Form.InputColorPicker
          label="Secondary color"
          name="secondary_color"
          value={values.secondary_color}
          onBlur={onChangeSecondaryColor}
          onChange={onChangeSecondaryColor}
          onChangeWhell={onChangeSecondaryColor}
        />
      </div>

      <div className="col-span-6">
        <Input label="NMLS" name="nmls" placeholder="Ex. 12345" />
      </div>
      <div className="col-span-6">
        <Label
          label="State"
          name="state"
          helperText="Primary location for brand. Default state used for pricing. (Example: GA)"
        />
        <USStateSelector
          name="state"
          selected={selectedState}
          setSelected={val => {
            setFormField("state", val.id)
          }}
        />
      </div>
    </div>
  )
}

export default BrandColors
