import auth from "./auth"
import org from "./org"
import activity from "./activity"
import integrations from "./integrations"
import leadWorkflow from "./lead-workflow"
import apiKey from "./api-key"
import brand from "./brand"
import asset from "./asset"
import lead from "./lead"
import others from "./others"
import approvedURLs from "./approved-urls"
import monitor from "./monitor"
import contactForms from "./contact-forms"
import rateflow from "./rateflow"
import loanOfficer from "./loan-officer"
import pricingSets from "./pricing-sets"
import dashboard from "./dashboard"
import subscriptions from "./subscriptions"
import configAdmin from "./config-admin"

const bbApi = {
  auth,
  org,
  activity,
  brand,
  leadWorkflow,
  integrations,
  apiKey,
  asset,
  lead,
  others,
  approvedURLs,
  monitor,
  contactForms,
  rateflow,
  loanOfficer,
  pricingSets,
  dashboard,
  subscriptions,
  configAdmin
}

export default bbApi
