import { FC, useCallback, useMemo } from "react"
import { useAppSelector } from "../../common/hooks"
import ActivityTable from "../../common/ui/activity/table"
import { getUserBrandId, hasUserRole } from "../../common/utils"
import {
  useFetchActivitiesByBrandQuery,
  useFetchActivitiesByLoQuery
} from "../../redux/queries"
import { selectUserDetails, selectUserRoles } from "../../redux/slices/auth"
import { PageHeader } from "../../common/ui"

const RecentActivity: FC<{}> = () => {
  const userDetails = useAppSelector(selectUserDetails)
  const userRoles = useAppSelector(selectUserRoles)

  const isBrandAdmin = hasUserRole(userRoles, "bank admin")
  const isLo = hasUserRole(userRoles, "loan officer")

  const brandId = useMemo(() => {
    if (!isBrandAdmin || !userDetails?.org || !userDetails?.id) return null
    return getUserBrandId(userDetails?.org, userDetails?.id)
  }, [isBrandAdmin, userDetails?.id, userDetails?.org])

  const {
    data: brandActivities,
    isFetching: isFetchingBrandActivities,
    refetch: refetchBrandActivities
  } = useFetchActivitiesByBrandQuery(
    { brand_id: brandId ?? "" },
    { skip: !isBrandAdmin || !brandId }
  )

  const {
    data: loActivities,
    isFetching: isFetchingLoActivities,
    refetch: refetchLoActivities
  } = useFetchActivitiesByLoQuery(
    { loid: userDetails?.id ?? "" },
    { skip: !isLo || !userDetails?.id }
  )

  const data = useMemo(() => {
    return [...(brandActivities ?? []), ...(loActivities ?? [])].map(
      loActivity => ({
        ...loActivity,
        isLoActivity: false
      })
    )
  }, [brandActivities, loActivities])

  const refetch = useCallback(() => {
    refetchBrandActivities()
    refetchLoActivities()
  }, [refetchBrandActivities, refetchLoActivities])

  return (
    <div className="h-full p-6 bg-white">
      <PageHeader title="Recent activity"></PageHeader>

      <div className="-mt-12">
        <ActivityTable
          data={data}
          isLoading={isFetchingBrandActivities || isFetchingLoActivities}
          searchBarVisible
          refetch={refetch}
        />
      </div>
    </div>
  )
}

export default RecentActivity
