import { useEffect, useState, useCallback, Fragment } from "react"
import {
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  flexRender,
  Row,
  PaginationState
} from "@tanstack/react-table"

import Pagination from "../pagination"
import ChartPagination from "../chart-pagination"
import ListSkeleton from "../skeletons/normal-list"
import ThInfoCell from "./cells/th-info-cell"
import { Sorter, DropdownFilter, SearchBar } from "./toolbar"
import { classNames } from "../../utils"
import If from "../if"
import { SortDirection, BBColumnDef, ViewType } from "../../../types"

type PaginationType = "default" | "chart"

type Props<T> = {
  data: T[]
  viewType: ViewType
  canChangeType?: boolean
  columns: BBColumnDef<T>[]
  dropdownFilterColumnId?: string
  searchBarVisible?: boolean
  searchPlaceholder?: string
  pageSize?: number
  gridsPerPage?: number
  pageCount?: number
  paginationType?: PaginationType
  pagination?: PaginationState
  setPagination?: React.Dispatch<React.SetStateAction<PaginationState>>
  renderCard?: (row: Row<T>) => JSX.Element | null
  extraHeaderElement?: React.ReactNode
  extraHeaderElementClassName?: string
  extraElementBeforeTable?: React.ReactNode
  extraElementAfterTable?: React.ReactNode
  noDataElement?: React.ReactNode
  shrinkFilterSort?: boolean
  noHeader?: boolean
  noSorter?: boolean
  gridClass?: string
  isLoading?: boolean
  loaderSkeleton?: React.ReactNode
  defaultSortBy?: string
  defaultSortDir?: SortDirection
  className?: string
  tableContainerClassName?: string
  tableClassName?: string
  tableHeaderClassName?: string
  searchClassName?: string
  containColumnsSorter?: boolean
  removeMobilePagination?: boolean
}

const Table = <P extends unknown>({
  data,
  viewType,
  columns,
  searchPlaceholder,
  dropdownFilterColumnId,
  searchBarVisible = true,
  pageSize = 10,
  gridsPerPage = 12,
  pageCount,
  paginationType = "default",
  pagination,
  setPagination,
  renderCard,
  extraHeaderElement = null,
  extraHeaderElementClassName = "ml-auto",
  extraElementBeforeTable = null,
  extraElementAfterTable = null,
  noDataElement = null,
  shrinkFilterSort = false,
  noHeader = false,
  noSorter = false,
  gridClass,
  isLoading = false,
  loaderSkeleton = <ListSkeleton />,
  defaultSortBy,
  defaultSortDir,
  className = "",
  tableContainerClassName = "",
  tableClassName = "table-fixed",
  tableHeaderClassName,
  searchClassName,
  containColumnsSorter = false,
  removeMobilePagination = false
}: Props<P>) => {
  // TABLE
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = useState("")
  const initialState =
    containColumnsSorter && defaultSortBy && defaultSortDir
      ? {
          initialState: {
            sorting: [
              {
                id: defaultSortBy,
                desc: defaultSortDir === "desc"
              }
            ]
          }
        }
      : {}

  const instance = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      globalFilter,
      ...(pagination ? { pagination } : {})
    },
    ...(pageCount ? { pageCount } : {}),
    ...(setPagination ? { onPaginationChange: setPagination } : {}),
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    manualPagination: !!pagination,
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
    ...initialState
  })

  useEffect(() => {
    instance.setPageSize(viewType === "table" ? pageSize : gridsPerPage)
  }, [viewType, instance, pageSize, gridsPerPage])

  const onSearch = useCallback((value: string | number) => {
    setGlobalFilter(String(value))
  }, [])

  const { rows } = instance.getRowModel()

  return (
    <div className={`flex flex-col ${className}`}>
      <div className="-mx-6 sm:-mx-6 lg:-mx-6">
        <div
          className={`inline-block min-w-full w-full align-middle border-white ${
            viewType === "grid" ? "bg-slate-100" : ""
          }`}
        >
          {searchBarVisible && (
            <>
              <SearchBar
                className={`pb-4 px-6 lg:px-6 ${
                  extraHeaderElement ? "lg:flex" : "flex"
                }`}
                searchClassName={searchClassName}
                globalFilter={globalFilter}
                onSearch={onSearch}
                searchPlaceholder={searchPlaceholder ?? ""}
              >
                <div
                  className={`w-full flex items-center justify-between ${
                    extraHeaderElement ? "mt-2 lg:mt-0" : ""
                  }`}
                >
                  {dropdownFilterColumnId && (
                    <DropdownFilter
                      columnID={dropdownFilterColumnId}
                      instance={instance as any}
                      className="flex items-center"
                      shrinkFilter={shrinkFilterSort}
                      popoverPlace="left"
                      popoverPlaceInMobile={
                        extraHeaderElement ? "left" : "right"
                      }
                    />
                  )}
                  {!noSorter && (
                    <Sorter
                      headerGroups={instance.getHeaderGroups()}
                      className="ml-1 lg:ml-3 flex items-center"
                      shrinkSort={shrinkFilterSort}
                      defaultSortBy={defaultSortBy}
                      defaultSortDir={defaultSortDir}
                      popoverPlace="left"
                      popoverPlaceInMobile={
                        extraHeaderElement ? "left" : "right"
                      }
                    />
                  )}
                  <div className={extraHeaderElementClassName}>
                    {extraHeaderElement}
                  </div>
                </div>
              </SearchBar>
              {!extraElementBeforeTable && <div className="h-2" />}
            </>
          )}
          {extraElementBeforeTable}
          <If
            condition={isLoading}
            then={loaderSkeleton}
            else={
              <div
                className={`px-6 w-full overflow-auto ${tableContainerClassName}`}
              >
                {viewType === "table" ? (
                  <table className={`w-full max-w-full ${tableClassName}`}>
                    <thead className={noHeader ? "sr-only" : ""}>
                      {instance.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id} className="text-neutral-400">
                          {headerGroup.headers.map((header, i) => {
                            const foundCol = columns.find(
                              c => c.id === header.id
                            )
                            const hasColumnSort =
                              containColumnsSorter && header.column.getCanSort()
                            return (
                              <th
                                scope="col"
                                key={header.id}
                                colSpan={foundCol?.colSpan || header.colSpan}
                                className={
                                  tableHeaderClassName ||
                                  classNames(
                                    i === 0 ? "py-4 pl-2 pr-3" : "py-4 px-3",
                                    header.column.columnDef?.meta
                                      ?.headerClassName ?? "",
                                    "text-left text-sm font-medium uppercase"
                                  )
                                }
                              >
                                {header.isPlaceholder ? null : (
                                  <ThInfoCell
                                    hasColumnSort={hasColumnSort}
                                    header={header}
                                  />
                                )}
                              </th>
                            )
                          })}
                        </tr>
                      ))}
                    </thead>
                    {rows.length ? (
                      <tbody className="divide-y divide-gray-200">
                        {rows.map(row => (
                          <tr key={row.id}>
                            {row.getVisibleCells().map((cell, index) => {
                              return (
                                <Fragment key={index}>
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </Fragment>
                              )
                            })}
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          {noDataElement || (
                            <td colSpan={columns.length}>
                              <div className="px-2 py-4 text-neutral-500 text-lg">
                                No rows found
                              </div>
                            </td>
                          )}
                        </tr>
                      </tbody>
                    )}
                  </table>
                ) : rows.length ? (
                  <ul
                    className={`${
                      gridClass
                        ? gridClass
                        : "grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 py-4"
                    }`}
                  >
                    {rows.map(row => (
                      <li
                        key={row.id}
                        className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200 overflow-hidden"
                      >
                        {renderCard?.(row)}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="px-2 py-4 text-neutral-500">
                    No rows found
                  </div>
                )}
              </div>
            }
          />
          {extraElementAfterTable}
          {instance.getPageCount() > 1 ? (
            <div className="px-6">
              {paginationType === "default" && (
                <Pagination
                  instance={instance as any}
                  totalCount={instance.getPageCount()}
                  viewType={viewType}
                  removeMobilePagination={removeMobilePagination}
                />
              )}
              {paginationType === "chart" && (
                <ChartPagination
                  instance={instance as any}
                  totalCount={instance.getPageCount()}
                />
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default Table
