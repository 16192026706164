import { FC } from "react"
import { Check } from "lucide-react"
import { classNames } from "../../utils"
import { IStep } from "../../../types"

interface Props {
  className?: string
  steps: IStep[]
  activeStep: string
  onStepChange: (id: string) => void
}

const CircleSteps: FC<Props> = ({ className = "", steps, activeStep }) => {
  const activeStepIdx = steps.findIndex(s => s.id === activeStep)

  return (
    <nav aria-label="Progress" className={className}>
      <ol className="flex items-center">
        {steps.map((step, stepIdx) => (
          <li
            key={step.id}
            className={classNames(
              stepIdx !== steps.length - 1 ? "pr-8 sm:pr-40" : "",
              "relative"
            )}
          >
            {stepIdx < activeStepIdx ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-indigo-600" />
                </div>
                <div className="relative flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 hover:bg-indigo-900">
                  <Check size={20} className="text-white" aria-hidden="true" />
                  <span className="sr-only">{step.id}</span>
                </div>
              </>
            ) : stepIdx === activeStepIdx ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <div
                  className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white"
                  aria-current="step"
                >
                  <span
                    className="h-2.5 w-2.5 rounded-full bg-indigo-600"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.id}</span>
                </div>
              </>
            ) : (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <div className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400">
                  <span
                    className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.id}</span>
                </div>
              </>
            )}
            <div
              className={`absolute whitespace-nowrap py-2 font-semibold ${
                stepIdx > activeStepIdx ? "text-neutral-300" : ""
              }`}
              style={{ transform: "translateX(calc(-50% + 1rem))" }}
            >
              {step.label}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default CircleSteps
