import { FC, Fragment, useEffect, useMemo, useState } from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import highChartsMap from "highcharts/modules/map"

import If from "../../../if"
import MapListSkeleton from "../../../skeletons/map-list"
import Table from "../../../table"
import NoData from "../../../no-data"
import {
  useFetchLeadCountByCityQuery,
  useFetchLeadCountByStateQuery
} from "../../../../../redux/queries"
import { useLeadCountPercentagesWithBg } from "../../../../hooks/use-lead-count-percentages-with-bg"
import { getMapChartOptions } from "../../../chart-with-stats/options"
import {
  BBColumnDef,
  LeadCountByCity,
  LeadCountByState
} from "../../../../../types"
import { getLeadByCityColumns, getLeadByStateColumns } from "./columns"

highChartsMap(Highcharts)

interface LeadByStateAndCityProps {
  title: string
  range: number
  orgId: string | undefined
  loid: string | undefined
}

const LeadByStateAndCity: FC<LeadByStateAndCityProps> = ({
  title,
  range,
  orgId,
  loid
}) => {
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const [chartLabels, setChartLabels] = useState<[string, number][]>([])
  const columns: BBColumnDef<LeadCountByState>[] = getLeadByStateColumns()
  const cityColumns: BBColumnDef<LeadCountByCity>[] = getLeadByCityColumns()

  const { data: leadCountByStateData, isFetching: isFetchingState } =
    useFetchLeadCountByStateQuery(
      {
        range,
        org_id: orgId,
        loid
      },
      { refetchOnMountOrArgChange: true }
    )
  const { data: leadCountByCityData, isFetching: isFetchingCity } =
    useFetchLeadCountByCityQuery(
      {
        range,
        org_id: orgId,
        loid
      },
      { refetchOnMountOrArgChange: true }
    )
  const leadCountByState = useLeadCountPercentagesWithBg(leadCountByStateData)
  const leadCountByCity = useLeadCountPercentagesWithBg(leadCountByCityData)
  const containsData = useMemo(
    () => leadCountByState?.length > 0 && leadCountByCity?.length > 0,
    [leadCountByCity?.length, leadCountByState?.length]
  )

  useEffect(() => {
    let newChartLabels: [string, number][] = []
    if (leadCountByStateData?.length && leadCountByStateData.length > 0) {
      newChartLabels = leadCountByStateData.map(item => [
        `us-${item.state.toLowerCase()}`,
        item.lead_count
      ])
    }
    setChartLabels(newChartLabels)
  }, [leadCountByStateData])

  useEffect(() => {
    setIsFetching(isFetchingState || isFetchingCity)
  }, [isFetchingCity, isFetchingState])

  return (
    <div className="bg-white flex flex-col flex-1 px-6 pt-6 pb-7 rounded-md overflow-hidden">
      <div className="flex flex-row items-center justify-between">
        <div className="text-base font-medium">{title}</div>
      </div>
      <If
        condition={isFetching}
        then={<MapListSkeleton className="mt-1 pt-7 md:pt-12 pb-6 md:pb-11" />}
        else={
          <Fragment>
            {!containsData && <NoData />}
            {containsData && (
              <div className="pt-1.5 pb-3.5 grid grid-cols-7 lg:gap-x-16">
                <div className="col-span-7 lg:col-span-3 mb-6 lg:mb-0">
                  <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={"mapChart"}
                    options={getMapChartOptions(chartLabels)}
                  />
                </div>
                <div className="col-span-7 lg:col-span-2 mb-6 lg:mb-0">
                  <Table
                    data={leadCountByState}
                    columns={columns}
                    viewType="table"
                    pageSize={5}
                    paginationType="chart"
                    noSorter
                    tableClassName="md:table-fixed"
                    tableHeaderClassName="px-0 pt-0 pb-1 text-left text-sm font-medium uppercase"
                    searchBarVisible={false}
                  />
                </div>
                <div className="col-span-7 lg:col-span-2">
                  <Table
                    data={leadCountByCity}
                    columns={cityColumns}
                    viewType="table"
                    pageSize={5}
                    paginationType="chart"
                    noSorter
                    tableClassName="md:table-fixed"
                    tableHeaderClassName="px-0 pt-0 pb-1 text-left text-sm font-medium uppercase"
                    searchBarVisible={false}
                  />
                </div>
              </div>
            )}
          </Fragment>
        }
      />
    </div>
  )
}

export default LeadByStateAndCity
