import { FC } from "react"
import { Brand } from "../../../../../types"
import Input from "../../../form/input"
import WysiwygEditor from "../../../form/WysiwygEditor"

interface Props {
  values: Brand
  setFormField: (name: string, value: any) => void
}

const BrandTextList: FC<Props> = ({ values, setFormField }) => {
  return (
    <>
      <div className="pt-8">
        <WysiwygEditor
          name="about"
          label="About brand"
          initialValue={values.about}
          setFieldValue={val => setFormField("about", val)}
        />
      </div>
      <div className="pt-8">
        <Input
          label="General disclaimer"
          name="disclaimer"
          placeholder="Type disclaimer here..."
          as="textarea"
          inputProps={{ rows: 4 }}
        />
      </div>
      <div className="pt-8">
        <Input
          label="Static flyer disclaimer"
          name="static_disclaimer"
          placeholder="Type disclaimer here..."
          as="textarea"
          inputProps={{ rows: 4 }}
        />
      </div>
      <div className="pt-8">
        <Input
          label="Payment details disclaimer"
          name="payment_details_disclaimer"
          placeholder="Type disclaimer here..."
          as="textarea"
          inputProps={{ rows: 4 }}
        />
      </div>
      <div className="pt-8">
        <Input
          label="Lead capture disclaimer"
          name="lead_capture_disclaimer"
          placeholder="Type disclaimer here..."
          as="textarea"
          inputProps={{ rows: 4 }}
        />
      </div>
      <div className="pt-8">
        <Input
          label="Loan calculator disclaimer"
          name="loan_calc_disclaimer"
          placeholder="Type disclaimer here..."
          as="textarea"
          inputProps={{ rows: 4 }}
        />
      </div>
      <div className="pt-8">
        <Input
          label="Rate alert disclaimer"
          name="rate_alert_disclaimer"
          placeholder="Type disclaimer here..."
          as="textarea"
          inputProps={{ rows: 4 }}
        />
      </div>
      <div className="pt-8">
        <Input
          label="Third Party Fees disclaimer"
          name="third_party_fees_disclaimer"
          placeholder="Type disclaimer here..."
          as="textarea"
          inputProps={{ rows: 4 }}
        />
      </div>
    </>
  )
}

export default BrandTextList
