import { FC, Fragment } from "react"

import { classNames } from "../../utils"
import { ColumnDetail } from "../../../types"

interface ColumnChartProps {
  columns: ColumnDetail[]
  containerClassName?: string
  bottomContainerClassName?: string
}

const ColumnChart: FC<ColumnChartProps> = ({
  columns,
  containerClassName = "grid-cols-6",
  bottomContainerClassName = "grid-cols-5 w-5/6 mx-auto"
}) => {
  return (
    <div className="w-full text-center">
      <div className={`grid ${containerClassName}`}>
        {columns.map((column, index) => (
          <div
            key={index}
            className={classNames(
              "col-span-1 w-full flex items-end justify-end mx-auto",
              index !== columns.length - 1 &&
                "relative after:absolute after:bottom-0 after:w-px after:h-6 after:bg-gray-300"
            )}
          >
            <div className="w-full flex flex-col-reverse px-1 md:px-2">
              <div className="w-full h-44 flex items-end justify-center mt-5 px-0 md:px-2 text-white text-xs">
                <div
                  className="w-full max-w-16"
                  style={{
                    height: `${
                      !Number.isNaN(parseFloat(column.completion)) &&
                      parseFloat(column.completion) > 100
                        ? 100
                        : column.completion
                    }%`,
                    backgroundColor: column.color
                  }}
                >
                  <div className="px-1 md:px-2 pt-1 break-words">{`${column.completion}%`}</div>
                </div>
              </div>
              <div className="mt-2 text-lg font-extrabold">
                {column.count?.toLocaleString()}
              </div>
              <div className="leading-5 text-sm break-words">
                {column.label}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="h-px my-3 bg-gray-300"></div>
      <div className={`grid ${bottomContainerClassName}`}>
        {columns.map((column, index) => (
          <Fragment>
            {index < columns.length - 1 && (
              <div
                key={`colmun-comp-${index}`}
                className="col-span-1 flex justify-center px-1"
              >
                <div className="w-14 text-sm break-words">{`${column.comparison}%`}</div>
              </div>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  )
}

export default ColumnChart
