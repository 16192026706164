import { FC, useEffect, useMemo, useState } from "react"

import ListSkeleton from "../../../../../skeletons/normal-list"
import NoData from "../../../../../no-data"
import Table from "../../../../../table"
import TableFooter from "../../../../../table/footer"
import { useFetchQuoteReceiptStatsQuery } from "../../../../../../../redux/queries"
import { useTableFooterData } from "../../../../../../hooks"
import { getQuoteReceiptStatsColumns } from "../columns"
import { BBColumnDef, QuoteReceiptStats } from "../../../../../../../types"
import { EmailStatsTabs } from "../.."

interface QuoteReceiptTableProps {
  range: number
  orgId: string | undefined
  loid: string | undefined
}

const QuoteReceiptTable: FC<QuoteReceiptTableProps> = ({
  range,
  orgId,
  loid
}) => {
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const quoteReceiptColumns: BBColumnDef<QuoteReceiptStats>[] =
    getQuoteReceiptStatsColumns()
  const { data: quoteReceiptData, isFetching: isFetchingQuoteReceipt } =
    useFetchQuoteReceiptStatsQuery(
      {
        range,
        org_id: orgId,
        loid
      },
      { refetchOnMountOrArgChange: true }
    )
  const containsData = useMemo(
    () => quoteReceiptData && quoteReceiptData?.length > 0,
    [quoteReceiptData]
  )
  const footerData = useTableFooterData(
    quoteReceiptData,
    isFetching,
    containsData,
    EmailStatsTabs.QUOTE_RECEIPT
  )

  useEffect(() => {
    setIsFetching(isFetchingQuoteReceipt)
  }, [isFetchingQuoteReceipt])

  return (
    <div className="flex flex-col">
      <Table
        isLoading={isFetching}
        data={quoteReceiptData ?? []}
        columns={quoteReceiptColumns}
        viewType="table"
        tableContainerClassName="h-[380px]"
        tableClassName="md:table-fixed"
        tableHeaderClassName="px-3 pt-0 pb-6 text-left text-sm font-medium uppercase"
        extraHeaderElementClassName="w-full md:w-auto md:ml-auto"
        loaderSkeleton={
          <ListSkeleton noOfRows={4} containerClassName="px-6 pt-4 pb-8" />
        }
        extraElementAfterTable={
          footerData?.length > 0 ? (
            <TableFooter
              rows={footerData}
              containerClassName={
                quoteReceiptData && quoteReceiptData.length > 5
                  ? "pl-6 pr-10"
                  : "px-6"
              }
            />
          ) : null
        }
        noDataElement={
          <td colSpan={8}>
            <div className="flex w-full h-[210px]">
              <NoData />
            </div>
          </td>
        }
        searchBarVisible={false}
      />
    </div>
  )
}

export default QuoteReceiptTable
