import { useEffect, useState } from "react"
import { Download, Eye, Copy, Send, Pencil, Loader2 } from "lucide-react"
import RowOptions from "../../../table/row-options"
import { SubscriptionDetails, SubscriptionLog } from "../../../../../types"
import {
  useFetchSubscriptionDetailsQuery,
  useSendIntroEmailMutation,
  useCreateRateflowPriceMutation,
  useFetchLoanSummaryPdfQuery
} from "../../../../../redux/queries"
import { useAppDispatch } from "../../../../hooks"
import { pushToast } from "../../../../../redux/slices/notifications"

interface Props {
  info: SubscriptionLog
  onViewLeadDashboard: (link: string) => void
  onCopyDashboardLink: (link: string) => void
  onEdit: (id: string) => void
}

const MenuCell: React.FC<Props> = ({
  info,
  onViewLeadDashboard,
  onCopyDashboardLink,
  onEdit
}) => {
  const dispatch = useAppDispatch()
  const [shouldOpenPdf, setShouldOpenPdf] = useState<boolean>(false)

  const { data } = useFetchSubscriptionDetailsQuery({ id: info.id })
  const [
    createRateflowPrice,
    {
      data: newRateflowPrice,
      isLoading: isLoadingRateflowPrice,
      isError: isErrorRateflowPriceApi
    }
  ] = useCreateRateflowPriceMutation()

  const isErrorRateflowPrice =
    isErrorRateflowPriceApi ||
    (newRateflowPrice && "status" in newRateflowPrice)

  const {
    data: loanSummaryPdf,
    isFetching: isFetchingLoanSummaryPdf,
    isError: isErrorLoanSummaryPdf
  } = useFetchLoanSummaryPdfQuery(
    {
      subscription_id: data?.id ?? "",
      quote_id: isErrorRateflowPrice
        ? ""
        : newRateflowPrice?.[0].quote_id?.toString() ?? ""
    },
    {
      skip: !data?.id || isErrorRateflowPrice || !newRateflowPrice?.[0].quote_id
    }
  )

  const [
    sendIntroEmail,
    { isLoading: isSendingIntroEmail, isError: isErrorIntroEmail }
  ] = useSendIntroEmailMutation()

  const isLoading =
    isLoadingRateflowPrice || isFetchingLoanSummaryPdf || isSendingIntroEmail
  const isError =
    isErrorLoanSummaryPdf || isErrorRateflowPrice || isErrorIntroEmail

  const handleSendIntroEmail = (log: SubscriptionLog) => {
    sendIntroEmail(log.id)
  }

  const handleDownloadLoanEstimate = (data: SubscriptionDetails) => {
    setShouldOpenPdf(true)
    createRateflowPrice({
      ...data.quote_info,
      loid: data.loid
    })
  }

  useEffect(() => {
    if (shouldOpenPdf && loanSummaryPdf?.file) {
      window.open(loanSummaryPdf.file, "_blank")
      setShouldOpenPdf(false)
    }
  }, [loanSummaryPdf?.file, shouldOpenPdf])

  useEffect(() => {
    if (isError) {
      dispatch(
        pushToast({
          msg: "Something went wrong, please try again.",
          theme: "danger",
          timeout: 3000
        })
      )
    }
  }, [dispatch, isError])

  if (!data || isLoading) {
    return (
      <div className="w-full p-6 flex items-center justify-end">
        <Loader2
          className="animate-spin h-6 w-6 text-primary-alt-400"
          aria-hidden="true"
        />
      </div>
    )
  }

  return (
    <td className="relative whitespace-nowrap py-4 px-0 text-right text-sm font-medium sm:pr-6 pl-3 md: pr-4">
      <RowOptions
        actions={[
          {
            label: "View lead dashboard",
            icon: Eye,
            onClick: () => onViewLeadDashboard(data?.dashboard_link ?? "")
          },
          {
            label: "Copy dashboard link",
            icon: Copy,
            onClick: () => onCopyDashboardLink(data?.dashboard_link ?? "")
          },
          {
            label: "Download loan estimate",
            icon: Download,
            onClick: () => handleDownloadLoanEstimate(data)
          },
          {
            label: "Send intro email to lead",
            icon: Send,
            onClick: () => handleSendIntroEmail(info)
          },
          {
            label: "Edit subscription",
            icon: Pencil,
            onClick: () => onEdit(info.id)
          }
        ]}
      />
    </td>
  )
}
export default MenuCell
