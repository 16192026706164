import { ContactForm } from "../../../../../../types"

type Props = {
  info: ContactForm
}

const IsDefaultCell = ({ info }: Props) => {
  const { is_default } = info
  return (
    <td className="whitespace-nowrap py-4 pl-2 pr-3 cursor-pointer text-right md:text-left">
      <div className="italic">{is_default === "1" ? "Default" : ""}</div>
    </td>
  )
}

export default IsDefaultCell
