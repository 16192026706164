import { FC } from "react"
import { Lead } from "../../../../../../../types"
import SectionCell from "../../../../../section/cell"

interface Props {
  data: Lead
}

const Misc: FC<Props> = ({ data }) => {
  const {
    details: {
      utm_campaign,
      utm_content,
      utm_medium,
      utm_source,
      gclid,
      parent_url
    }
  } = data
  return (
    <div className="grid grid-cols-2 gap-4">
      <SectionCell
        className="break-all"
        label="utm campaign"
        value={utm_campaign}
        h={"min-h-[1.75rem] h-auto"}
      />
      <SectionCell
        className="break-all"
        label="utm content"
        value={utm_content}
        h={"min-h-[1.75rem] h-auto"}
      />
      <SectionCell
        className="break-all"
        label="utm medium"
        value={utm_medium}
        h={"min-h-[1.75rem] h-auto"}
      />
      <SectionCell
        className="break-all"
        label="utm source"
        value={utm_source}
        h={"min-h-[1.75rem] h-auto"}
      />
      <SectionCell
        className="break-all"
        label="gclid"
        value={gclid}
        h={"min-h-[1.75rem] h-auto"}
      />
      <SectionCell
        className="row-span-2 break-all"
        label="parent url"
        value={parent_url}
        h={"min-h-[1.75rem] h-auto"}
      />
    </div>
  )
}

export default Misc
