import { FC } from "react"
import { Loader2 } from "lucide-react"
import { PricingProfile, BBColumnDef } from "../../../../types"
import Table from "../../table"
import LabelCell from "./cells/Label"

interface Props {
  data: PricingProfile[]
  isLoading: boolean
}

const PricingProfilesTable: FC<Props> = ({ data, isLoading }) => {
  const columns: BBColumnDef<PricingProfile>[] = [
    {
      id: "label",
      header: "Label",
      footer: props => props.column.id,
      cell: props => <LabelCell value={props.row} />
    }
  ]
  return (
    <div className="h-full flex flex-col">
      <div className="flex-1">
        {isLoading ? (
          <div className="h-full flex items-center justify-center">
            <Loader2
              className={`animate-spin h-16 w-16 text-primary-alt-400`}
              aria-hidden="true"
            />
          </div>
        ) : (
          <div className="bg-white">
            <Table
              data={data}
              columns={columns}
              viewType="table"
              searchBarVisible={false}
              noHeader
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default PricingProfilesTable
