import { FC } from "react"
import { SubscriptionDetails } from "../../../../../../../types"
import SectionCell from "../../../../../section/cell"

interface Props {
  data: SubscriptionDetails
}

const LoFields: FC<Props> = ({ data }) => {
  const {
    quote_info: { admin_fields, list_price }
  } = data
  return (
    <div className="grid sm:grid-cols-2 gap-4">
      <SectionCell
        label="Annual taxes"
        value={
          admin_fields?.taxes > 0
            ? `${admin_fields.taxes}% | ${(
                (admin_fields.taxes / 100) *
                list_price
              ).toFixed(2)}`
            : null
        }
      />
      <SectionCell
        label="Annual home insurance"
        value={
          admin_fields?.insurance > 0
            ? `${admin_fields.insurance}% | ${(
                (admin_fields.insurance / 100) *
                list_price
              ).toFixed(2)}`
            : null
        }
      />
    </div>
  )
}

export default LoFields
