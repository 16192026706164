/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react"
import { useFindPricingSetsQuery } from "../../../../../redux/queries"
import { Integration, PricingSet } from "../../../../../types"
import { useIntegrationSettingsForm } from "../../../../hooks"
import Form from "../../../form"

const getSelected = (id: string | number, sets: any[]) => {
  // get selected workflow from workflows by id
  const selectedSet = sets?.find(
    sets => sets.id === id + "" // TODO: this should be always number
  )
  return selectedSet
}

interface Props {
  integration?: Integration
}

const PricingSetSelector: FC<Props> = ({ integration }) => {
  const { app_settings, saveAppSetting } =
    useIntegrationSettingsForm(integration)

  const [selected, setSelectedSet] = useState<PricingSet>()
  const [selectedID, setSelectedSetID] = useState<number | string>(
    app_settings?.pricing_set_id ?? ""
  )

  // Get all the workflows from a brand
  const { data: sets = [], isLoading } = useFindPricingSetsQuery({
    brand_id: integration?.brand_id as string
  })

  const setSelected = (setID: string) => {
    if (setID !== selectedID) {
      setSelectedSetID(setID) // set the state
      saveAppSetting({ pricing_set_id: setID }) // save the setting to the server
    }
  }

  useEffect(() => {
    setSelectedSet(getSelected(selectedID, sets))
  }, [selectedID, sets])

  return (
    <div className="flex items-center pl-9">
      <Form.InlineSelect
        isLoading={isLoading}
        data={sets}
        setSelected={setSelected}
        selected={selected}
        label="Choose a set"
      />
      <a href="#" className="text-primary-alt-400 inline-block ml-3">
        Edit
      </a>
    </div>
  )
}

export default PricingSetSelector
