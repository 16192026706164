import { FC, useMemo, useState } from "react"
import { Organization } from "../../../../types"
import ImageUploader from "../../image-uploader"
import Input from "../../form/input"
import WysiwygEditor from "../../form/WysiwygEditor"
import Autocomplete from "../../form/autocomplete"
import { useFindObOrgQuery } from "../../../../redux/queries"

interface Props {
  values: Organization
  setFormField: (name: string, val: string | number) => void
}

const EditOrgFormContent: FC<Props> = ({ values, setFormField }) => {
  const [query, setQuery] = useState<string>("")

  const { data: obOrgData } = useFindObOrgQuery({
    name: query,
    ignore_cache: true
  })

  const pricingEngineOptions = useMemo(() => {
    if (!obOrgData?.length) return []
    return obOrgData.map(item => ({
      id: item.index.toString(),
      text: item.name
    }))
  }, [obOrgData])

  const handleChangeLogo = (url: string) => setFormField("logo", url)

  return (
    <div className="py-6">
      <ImageUploader
        isFullRounded={false}
        imageUrl={values.logo}
        onChangeImageUrl={handleChangeLogo}
      />

      <div className="pt-8">
        <Input
          label="Name of organization"
          name="name"
          placeholder="Ex. Fivestar home loans"
        />
      </div>
      <div className="pt-8">
        <Input
          label="Website URL"
          name="website"
          placeholder="Ex. www.google.com"
        />
      </div>
      <div className="pt-8">
        <WysiwygEditor
          name="about"
          label="About organization"
          initialValue={values.about}
          setFieldValue={val => setFormField("about", val)}
        />
      </div>
      <div className="pt-8">
        <Autocomplete
          name="pricing_engine"
          label="Search & Connect pricing engine"
          query={query}
          onChangeQuery={setQuery}
          onChange={val => setFormField("ob_biz_channel", val.id)}
          options={pricingEngineOptions}
        />
      </div>
    </div>
  )
}

export default EditOrgFormContent
