import { classNames } from "../../utils"

type Step = {
  icon: React.ElementType
  title: string
  iconBackground: string
}

type Props = {
  steps: Step[]
  title: string
}

const Steps = ({ steps, title }: Props) => (
  <>
    <h1 className="mb-4">{title}</h1>
    <ol>
      {steps.map((step: Step, index) => (
        <li key={index} className="relative pb-6">
          {index !== steps.length - 1 ? (
            <span
              className="absolute bottom-0 left-6 -ml-px h-6 w-0.5 bg-gray-200"
              aria-hidden="true"
            />
          ) : null}
          <div className="relative flex space-x-3">
            <div>
              <span
                className={classNames(
                  step.iconBackground,
                  "h-12 w-12 rounded-full flex items-center justify-center text-neutral-400"
                )}
              >
                <step.icon size={18} focusable="false" aria-hidden="true" />
              </span>
            </div>
            <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
              <div>
                <p className="leading-10">{step.title}</p>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ol>
  </>
)

export default Steps
