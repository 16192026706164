import { FC } from "react"

interface ActivityInfoCellProps {
  subscriber?: string
  email?: string
  loanOfficer?: string
  info?: string | number
  customClass?: string
  colSpan?: number
}

const ActivityInfoCell: FC<ActivityInfoCellProps> = ({
  subscriber,
  email,
  loanOfficer,
  info,
  customClass = "py-4 px-3",
  colSpan = 1
}) => {
  return (
    <td
      className={`whitespace-nowrap cursor-pointer ${customClass}`}
      colSpan={colSpan}
    >
      {subscriber && (
        <div className="text-base font-medium text-ellipsis overflow-hidden">
          {subscriber}
        </div>
      )}
      {email && (
        <div className="text-sm text-neutral-400 text-ellipsis overflow-hidden">
          {email}
        </div>
      )}
      {loanOfficer && (
        <div className="text-sm text-ellipsis overflow-hidden">
          {loanOfficer}
        </div>
      )}
      {info && <div className="text-sm">{info}</div>}
    </td>
  )
}

export default ActivityInfoCell
