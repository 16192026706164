/**
  Contact forms API wrapper

**/

import { api } from "../common/utils"
import {
  CONTACT_FORMS_ENDPOINT,
  CONTACT_FORM_ENDPOINT
} from "../common/constants"
import { ContactFormDetails, FindContactFormsResponse } from "../types"

const contactForms = {
  find: async (brand_id: string): Promise<FindContactFormsResponse> => {
    const { data } = await api.get(
      CONTACT_FORMS_ENDPOINT + `?brand_id=${brand_id}`
    )
    return data
  },
  updateOrCreate: async (
    payload: Partial<ContactFormDetails>
  ): Promise<{ status: string; message?: string }> => {
    const { data } = await api.post(CONTACT_FORM_ENDPOINT, payload)
    return data
  }
}

export default contactForms
