import { FC, memo } from "react"

import Skeleton from "./base"

interface MultiColumnPerformanceProps {
  className?: string
}

const MultiColumnPerformance: FC<MultiColumnPerformanceProps> = ({
  className = ""
}) => {
  return (
    <div
      className={`bg-white flex-1 content-center overflow-hidden ${className}`}
    >
      <Skeleton className="h-full">
        <div className="flex flex-col justify-between items-between h-full">
          <div className="flex-1 grid gap-x-3 md:gap-x-6 grid-cols-8 w-full items-end justify-center px-5 md:px-10">
            <div className="col-span-2 md:col-span-1 h-12 bg-gray-100"></div>
            <div className="col-span-2 md:col-span-1 h-16 bg-gray-100"></div>
            <div className="col-span-2 md:col-span-1 h-20 bg-gray-100"></div>
            <div className="col-span-2 md:col-span-1 h-32 bg-gray-100"></div>
            <div className="hidden md:block col-span-1 h-16 bg-gray-100"></div>
            <div className="hidden md:block col-span-1 h-5 bg-gray-100"></div>
            <div className="hidden md:block col-span-1 h-14 bg-gray-100"></div>
            <div className="hidden md:block col-span-1 h-32 bg-gray-100"></div>
          </div>
          <div className="w-full h-[3px] bg-gray-100 mb-12"></div>
          <div className="flex w-9/12">
            <div className="flex flex-col w-3/4 md:w-1/3">
              <div className="w-3/4 h-3 bg-gray-200 rounded-full mb-2.5"></div>
              <div className="w-3/5 h-5 bg-gray-100 rounded-full"></div>
            </div>
            <div className="flex flex-col w-3/4 md:w-1/3">
              <div className="w-3/4 h-3 bg-gray-200 rounded-full mb-2.5"></div>
              <div className="w-3/5 h-5 bg-gray-100 rounded-full"></div>
            </div>
            <div className="hidden md:flex flex-col w-1/3">
              <div className="w-3/4 h-3 bg-gray-200 rounded-full mb-2.5"></div>
              <div className="w-3/5 h-5 bg-gray-100 rounded-full"></div>
            </div>
            <div className="hidden md:flex flex-col w-1/3">
              <div className="w-3/4 h-3 bg-gray-200 rounded-full mb-2.5"></div>
              <div className="w-3/5 h-5 bg-gray-100 rounded-full"></div>
            </div>
            <div className="hidden md:flex flex-col w-1/3">
              <div className="w-3/4 h-3 bg-gray-200 rounded-full mb-2.5"></div>
              <div className="w-3/5 h-5 bg-gray-100 rounded-full"></div>
            </div>
          </div>
        </div>
      </Skeleton>
    </div>
  )
}

export default memo(MultiColumnPerformance)
