import { memo } from "react"
import Skeleton from "../base"

const Performance = () => {
  return (
    <div className="bg-white flex-1 p-6 rounded-md overflow-hidden min-h-[594px]">
      <Skeleton className="h-full">
        <div className="flex flex-col justify-between items-between h-full">
          <div className="flex flex-col w-full mb-10 h-36">
            <div className="w-1/3 h-4 bg-gray-200 rounded-full mb-1.5"></div>
            <div className="w-1/2 h-4 bg-gray-100 rounded-full mb-1.5"></div>
          </div>
          <div className="flex w-full items-end">
            <div className="w-6 h-12 bg-gray-200 mr-2.5"></div>
            <div className="w-6 h-24 bg-gray-100 mr-2.5"></div>
            <div className="w-6 h-16 bg-gray-200 mr-2.5"></div>
            <div className="w-6 h-36 bg-gray-100 mr-2.5"></div>
            <div className="w-6 h-10 bg-gray-200 mr-2.5"></div>
            <div className="w-6 h-8 bg-gray-100 mr-2.5"></div>
            <div className="w-6 h-16 bg-gray-200 mr-2.5"></div>
            <div className="w-6 h-20 bg-gray-100 mr-2.5"></div>
            <div className="w-6 h-10 bg-gray-200 mr-2.5"></div>
            <div className="w-6 h-16 bg-gray-100 mr-2.5"></div>
            <div className="w-6 h-24 bg-gray-200 mr-2.5"></div>
            <div className="w-6 h-32 bg-gray-100 mr-2.5"></div>
            <div className="w-6 h-16 bg-gray-200 mr-2.5"></div>
            <div className="w-6 h-12 bg-gray-100 mr-2.5"></div>
          </div>
        </div>
      </Skeleton>
    </div>
  )
}

export default memo(Performance)
