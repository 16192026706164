import { FC, useState } from "react"
import { Brand } from "../../../../types"
import BrandAutocomplete from "../../brand-autocomplete"
import {
  useFetchLeadworkflowDetailsQuery,
  useFindWorklowsQuery
} from "../../../../redux/queries"
import LeadworkflowTable from "./table"
import ManageLeadworkflow from "./manage"

interface Props {
  brands: Brand[]
}

const ConfigLeadWorkflow: FC<Props> = ({ brands }) => {
  const [selectedBrandId, setSelectedBrandId] = useState<string>("")
  const [selectedWorkflowId, setSelectedWorkflowId] = useState<string>()

  const { data: workflows, isFetching } = useFindWorklowsQuery(
    selectedBrandId ?? "",
    {
      skip: !selectedBrandId
    }
  )

  const { data: leadworkflowDetails } = useFetchLeadworkflowDetailsQuery(
    { id: selectedWorkflowId ?? "" },
    { skip: !selectedWorkflowId }
  )

  const resetSelectedWorkflowId = () => setSelectedWorkflowId("")

  return (
    <div className="">
      <BrandAutocomplete
        brands={brands}
        selected={selectedBrandId}
        onChange={setSelectedBrandId}
      />

      <div className="pt-6">
        {selectedBrandId ? (
          <LeadworkflowTable
            data={workflows ?? []}
            isFetching={isFetching}
            onSelect={setSelectedWorkflowId}
          />
        ) : null}
      </div>

      <ManageLeadworkflow
        open={!!(selectedWorkflowId && leadworkflowDetails)}
        data={leadworkflowDetails}
        onClose={resetSelectedWorkflowId}
      />
    </div>
  )
}

export default ConfigLeadWorkflow
