import { FC } from "react"
import { BBColumnDef, Integration, IntegrationStatus } from "../../../../types"
import If from "../../if"
import Table from "../../table"
import { formatDateTimeFromTimestamp } from "../../../utils"

interface Props {
  integration?: Integration
  statusData?: IntegrationStatus[]
  isLoading: boolean
}

const Status: FC<Props> = ({ integration, statusData = [], isLoading }) => {
  const columns: BBColumnDef<IntegrationStatus>[] = [
    {
      id: "timestamp",
      header: "Date/Time",
      accessorFn: row => row.timestamp,
      footer: props => props.column.id,
      cell: props => (
        <td
          className={`px-2 py-4 ${
            props.row.original.validated === "1" ? "text-primary-400" : ""
          }`}
        >
          {formatDateTimeFromTimestamp(
            props.getValue() as string,
            "LL/dd/yyyy h:mm:ss a"
          )}
        </td>
      )
    },
    {
      id: "referrer",
      header: "Referrer",
      accessorFn: row => row.referrer,
      footer: props => props.column.id,
      cell: props => (
        <td
          className={`pl-3 py-4 ${
            props.row.original.validated === "1" ? "text-primary-400" : ""
          }`}
          colSpan={2}
        >
          {props.getValue() as string}
        </td>
      ),
      meta: { headerClassName: "hidden md:table-cell" }
    }
  ]

  return (
    <If
      isLoading={isLoading}
      condition={statusData?.length > 0}
      then={
        <Table
          columns={columns}
          data={statusData}
          viewType="table"
          pageSize={25}
          searchBarVisible={false}
        />
      }
      else={<div className="px-4 py-6">No data available.</div>}
    />
  )
}

export default Status
