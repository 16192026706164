import { FC } from "react"
import { ExperienceReview, Reviews } from "../../../types"
import ImgExperienceLogo from "../../../assets/icons/experience_logo.svg"
import ImgStar from "../../../assets/icons/star.svg"
import If from "../if"

interface Props {
  reviews?: Reviews
}

const ExperienceReviewsList: FC<Props> = ({ reviews }) => {
  return (
    <div className="pb-6">
      <div className="text-lg font-bold">Experience reviews</div>

      <If
        condition={!!reviews?.reviews?.length}
        then={
          <>
            {(reviews?.reviews[0] as ExperienceReview)?.review.source && (
              <div className="py-4 flex items-center">
                <div className="pr-3">
                  <img src={ImgExperienceLogo} alt="Experience Logo" />
                </div>
                <div>
                  <div className="font-bold">
                    {(reviews?.reviews[0] as ExperienceReview)?.review.source}
                  </div>
                </div>
              </div>
            )}
            {reviews?.reviews.map(review => {
              const experienceReview = review as ExperienceReview
              return (
                <div key={experienceReview.id} className="py-4">
                  <div className="flex items-center leading-5">
                    <div className="w-5 h-5">
                      <img className="h-full" src={ImgStar} alt="Star" />
                    </div>
                    <div className="font-bold pl-2">
                      {experienceReview.review.rating}
                    </div>
                    <div className="text-neutral-300">
                      <span className="pl-3 pr-2">.</span>
                      {experienceReview.serviceProviderInfo.agent_name}
                    </div>
                  </div>
                  <div className="pt-4">{experienceReview.review.review}</div>
                </div>
              )
            })}
          </>
        }
        else={<div className="py-4">-</div>}
      />
    </div>
  )
}

export default ExperienceReviewsList
