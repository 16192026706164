/**
    Dashboard API wrapper
**/

import { api } from "../common/utils"
import { DASHBOARD_PERFORMANCE_STATS_ENDPOINT } from "../common/constants"
import { PerformanceStats } from "../types"

const dashboard = {
  get: async (org_id: string, range: number): Promise<PerformanceStats> => {
    const { data } = await api.get(
      DASHBOARD_PERFORMANCE_STATS_ENDPOINT + `?org_id=${org_id}&range=${range}`
    )
    return data
  }
}

export default dashboard
