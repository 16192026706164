import { FC } from "react"

interface Props {
  imageSrc?: string
  label?: string
  className?: string
}

const ProfileLogo: FC<Props> = ({
  className = "w-16 h-16",
  imageSrc,
  label = ""
}) => {
  return (
    <div
      className={`flex items-center justify-center border border-neutral-300 rounded-full overflow-hidden ${
        imageSrc ? "bg-white" : "bg-neutral-500"
      } ${className}`}
    >
      {imageSrc ? (
        <img src={imageSrc} alt={label} />
      ) : (
        <div className="font-medium text-neutral-50">{label}</div>
      )}
    </div>
  )
}

export default ProfileLogo
