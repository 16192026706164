import { Gift } from "lucide-react"
import { REFERRAL_LINK } from "../../constants"
import If from "../if"

interface Props {
  isIcon?: boolean
  className?: string
}

const ReferralBadge = ({ isIcon, className = "" }: Props) => (
  <a
    className={`${className} ${
      isIcon ? "p-3" : "py-3 pl-4 pr-2 gap-2.5 max-w-[256px]"
    } flex bg-[#140F29] border border-[#53429D] rounded-lg  hover:no-underline`}
    href={REFERRAL_LINK}
    target="_blank"
    rel="noreferrer"
    title={isIcon ? "Invite & earn $500 for each friend you refer" : ""}
  >
    <Gift size={isIcon ? 20 : 30} className="text-yellow-300 -mt-0.5" />
    <If
      condition={!isIcon}
      then={
        <strong className="font-normal text-sm whitespace-break-spaces">
          Invite & earn $500 for each friend you refer
        </strong>
      }
    />
  </a>
)

export default ReferralBadge
