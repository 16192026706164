import { GripVertical } from "lucide-react"
import { PricingRate } from "../../../../../types"
import { formatAsTwoDigits } from "../../../../utils"

type Props = {
  value: PricingRate
  index: number
}

const LabelCell = ({ value, index }: Props) => {
  return (
    <td className="whitespace-nowrap py-4 pl-2 pr-3 cursor-pointer" colSpan={3}>
      <div className="flex items-center">
        <GripVertical className="text-neutral-400" />
        <div className="text-2xl font-extrabold ml-2">
          {formatAsTwoDigits(index + 1)}
        </div>
        <div className="pl-5">
          <div className="font-bold">{value.label}</div>
          <div className="text-sm text-neutral-400">
            TERM: {value.term / 12} Years
          </div>
        </div>
      </div>
    </td>
  )
}

export default LabelCell
