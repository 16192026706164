/**
 * Regular expression that matches the below URL patterns
 *  - https://www.example.com
 *  - https:/*.example.com
 *  - https://example.com/*
 *  - https://*.example.com/*
 */
const REGEX_HTPPS_URL_WILDCARD =
  /^https:\/\/(((www)|\*)\.)?([-a-zA-Z0-9@:%._+~#=]{1,256})\.([\w-]+\/?)+\*?$/

const REGEX_URL_WILDCARD =
  /^((ftp|http|https):\/\/)?(www.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/

const REGEX_URL_WILDCARD_ASTERISK =
  /^(?:(ftp|http|https):\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b(?:[-a-zA-Z0-9@:%_+.~#?&//=]*\*)?$/

const REGEX_HTPPS_URL =
  /^https:\/\/(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/[\w.-]*)*(?:\?[a-zA-Z0-9-._~,!%&'()*;=:@+/]*)?(?:#[\w-]*)?$/

const REGEX_LOCAL_URL_WILDCARD = /^\/.*$/

const REGEX_HEX_COLOR = /^#([0-9A-F]{3}){1,2}$/i

const PHONE_NUMBER_REGEX =
  /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/

export {
  REGEX_HTPPS_URL_WILDCARD,
  REGEX_URL_WILDCARD_ASTERISK,
  REGEX_LOCAL_URL_WILDCARD,
  REGEX_HTPPS_URL,
  REGEX_URL_WILDCARD,
  REGEX_HEX_COLOR,
  PHONE_NUMBER_REGEX
}
