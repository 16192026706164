import { Listbox, Transition } from "@headlessui/react"
import { Check, ChevronDown } from "lucide-react"
import { Fragment } from "react"
import { classNames } from "../../utils"
import If from "../if"

/**
 * This is a wrapper for Headless UI's Listbox component
 * https://headlessui.com/react/listbox
 * Usually should be kept as a low-level component and not used directly,
 * instead use a use custom wrapper component(Ex. src/common/ui/integration-owner-selector/index.tsx)
 */

type Props = {
  data: any[]
  selected: any
  label: string
  name?: string
  IDkey?: string
  isLoading?: boolean
  disabled?: boolean
  multiple?: boolean
  showLabel?: boolean
  error?: string
  required?: boolean
  selectClassName?: string
  buttonClassName?: string
  setSelected: (item: any) => void
}

const ID_KEY_DEFAULT = "id"

const Select = ({
  data,
  selected,
  IDkey = ID_KEY_DEFAULT,
  setSelected,
  isLoading,
  multiple,
  disabled,
  name,
  label,
  showLabel = true,
  error,
  required = false,
  selectClassName = "",
  buttonClassName = ""
}: Props) => (
  <>
    <Listbox
      name={name}
      value={multiple ? selected : selected?.[IDkey]}
      onChange={setSelected}
      multiple={multiple}
      disabled={disabled || isLoading}
    >
      {({ open }) => (
        <>
          <Listbox.Label
            className={`block font-medium ${showLabel ? "" : "sr-only"}`}
          >
            {label} {required && <span className="text-red-400">*</span>}
          </Listbox.Label>
          <div className={`relative ${selectClassName} ${label ? "mt-1" : ""}`}>
            <Listbox.Button
              className={`relative w-full cursor-pointer rounded-md border border-neutral-200 p-3 pr-10 text-left shadow-sm focus:border-primary-400 focus:outline-none focus:ring-1 focus:ring-primary-400 sm:text-sm ${buttonClassName}`}
            >
              <span className="block truncate">
                {multiple && (selected?.length ? selected.join(", ") : "-")}{" "}
                {!multiple && (
                  <>
                    {selected?.icon && (
                      <span className="flex items-center">
                        <span className="pr-2">{selected.icon}</span>
                        <span className="font-medium text-sm md:text-base">
                          {selected.name}
                        </span>
                      </span>
                    )}
                    {!selected?.icon && (selected?.name || "-")}
                  </>
                )}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <If
                  loaderSize={6}
                  isLoading={isLoading}
                  condition
                  then={
                    <ChevronDown
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  }
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {data.map(item => (
                  <Listbox.Option
                    key={item[IDkey]}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-primary-500" : "text-gray-900",
                        "relative select-none py-2 pl-7 pr-4 cursor-pointer"
                      )
                    }
                    value={item[IDkey]}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-medium" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {item?.icon ? (
                            <span className="flex items-center">
                              <span className="pr-2">{item.icon}</span>
                              <span className="text-sm md:text-base">
                                {item.name}
                              </span>
                            </span>
                          ) : (
                            item.name
                          )}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-primary-500",
                              "absolute inset-y-0 left-0 flex items-center pl-2"
                            )}
                          >
                            <Check className="h-4 w-4" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
    {error ? (
      <p className="mt-2 text-sm text-red-600" id="email-error">
        {error}
      </p>
    ) : null}
  </>
)

export default Select
