import { FC, useEffect, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../common/hooks"
import { PageHeader } from "../../common/ui"
import MyAccountSettings from "../../common/ui/account/MyAccountSettings"
import MyLoProfile from "../../common/ui/account/MyLoProfile"
import { checkIsLoanOfficer } from "../../common/utils"
import {
  useFetchBrandQuery,
  useFetchBrandsQuery,
  useFetchLoanOfficerQuery
} from "../../redux/queries"
import {
  getMe,
  selectPasswordUpdated,
  selectProfileUpdated,
  selectUserDetails
} from "../../redux/slices/auth"

type Props = {
  title?: string
}

type SectionType = "settings" | "lo_profile"

interface ISection {
  key: SectionType
  label: string
}

const SECTIONS: ISection[] = [
  {
    key: "settings",
    label: "My account settings"
  },
  {
    key: "lo_profile",
    label: "My loan officer profile"
  }
]

const AccountPage: FC<Props> = ({ title = "Account" }) => {
  const dispatch = useAppDispatch()

  const passwordUpdated = useAppSelector(selectPasswordUpdated)
  const profileUpdated = useAppSelector(selectProfileUpdated)

  const user = useAppSelector(selectUserDetails)

  const { data: loProfile } = useFetchLoanOfficerQuery(user?.id ?? "", {
    skip: !user?.id
  })

  const [section, setSection] = useState<SectionType>("settings")
  const isLoanOfficer = checkIsLoanOfficer(user?.roles || [])

  const { data: brands } = useFetchBrandsQuery({})

  const selectedBrand = useMemo(
    () => brands?.find(b => b.id === loProfile?.bank_nid),
    [brands, loProfile?.bank_nid]
  )
  const { data: apiBrand } = useFetchBrandQuery(loProfile?.bank_nid ?? "", {
    skip: !loProfile?.bank_nid || !!selectedBrand?.id
  })

  const brand = selectedBrand || apiBrand

  useEffect(() => {
    profileUpdated && dispatch(getMe())
  }, [dispatch, profileUpdated])

  useEffect(() => {
    setSection("settings")
  }, [user?.id])

  if (!user) return null

  return (
    <div>
      <div className="p-6">
        <PageHeader title={title} />
      </div>
      <div className="px-6 pb-6">
        <div className="flex items-start">
          {isLoanOfficer && (
            <div className="w-64 bg-white p-4 mr-6 rounded-md">
              {SECTIONS.map(({ key, label }) => (
                <div
                  key={key}
                  className={`text-sm font-medium py-2 px-3 rounded-md cursor-pointer ${
                    section === key ? "bg-neutral-100" : "bg-white"
                  }`}
                  onClick={() => setSection(key)}
                >
                  {label}
                </div>
              ))}
            </div>
          )}

          <div className="flex-1 rounded-md bg-white">
            {section === "settings" && (
              <MyAccountSettings
                user={user}
                passwordUpdated={passwordUpdated}
              />
            )}
            {section === "lo_profile" && (
              <MyLoProfile profile={loProfile} brand={brand} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountPage
