import { FC } from "react"
import { LoanOfficer } from "../../../../types"
import htmlToDraft from "html-to-draftjs"

interface Props {
  loanOfficer: LoanOfficer
}

const LoanOfficerOverview: FC<Props> = ({ loanOfficer }) => (
  <div>
    <div className="flex pb-6">
      <div className="flex-1">
        <div className="text-neutral-400">Title</div>
        <div className="text-neutral-800">{loanOfficer.position}</div>
      </div>
      <div className="flex-1">
        <div className="text-neutral-400">NMLS</div>
        <div className="text-neutral-800">{loanOfficer.nmls ?? "-"}</div>
      </div>
    </div>

    <div className="flex pb-6">
      <div className="flex-1">
        <div className="text-neutral-400">Pricing engine</div>
        <div className="text-neutral-800">{loanOfficer.pricing_status}</div>
      </div>
    </div>

    <div className="flex pb-6">
      <div className="flex-1">
        <div className="text-neutral-400">Mobile number</div>
        <div className="text-neutral-800">
          {loanOfficer.mobile_phone ?? "-"}
        </div>
      </div>
      <div className="flex-1">
        <div className="text-neutral-400">Office number</div>
        <div className="text-neutral-800">
          {loanOfficer.office_phone ?? "-"}
        </div>
      </div>
    </div>

    <div className="flex pb-6">
      <div className="flex-1">
        <div className="text-neutral-400">Primary phone number (optional)</div>
        <div className="text-neutral-800">{loanOfficer.phone ?? "-"}</div>
      </div>
    </div>

    <div className="flex pb-6">
      <div className="flex-1">
        <div className="text-neutral-400">Pre app link</div>
        <div className="text-neutral-800">{loanOfficer.app_link ?? "-"}</div>
      </div>
    </div>

    <div className="flex pb-6">
      <div className="flex-1">
        <div className="text-neutral-400">Special disclosure link</div>
        <div className="text-neutral-800">
          {loanOfficer.special_disclosure_link ?? "-"}
        </div>
      </div>
    </div>

    <div className="pb-6">
      <div className="text-neutral-400">About me</div>
      <div className="text-neutral-800">
        {loanOfficer.about_me
          ? htmlToDraft(loanOfficer.about_me)
              .contentBlocks.map(b => b.getText())
              .join(" ")
          : "-"}
      </div>
    </div>

    <div className="pb-6">
      <div className="text-lg font-medium">Social</div>
    </div>
    <div className="pb-6">
      <div className="text-neutral-400">Facebook link</div>
      <div className="text-neutral-800">{loanOfficer.facebook_link ?? "-"}</div>
    </div>

    <div className="pb-6">
      <div className="text-neutral-400">Linkedin link</div>
      <div className="text-neutral-800">{loanOfficer.linkedin_link ?? "-"}</div>
    </div>

    <div className="pb-6">
      <div className="text-lg font-medium">Office</div>
    </div>
    <div className="pb-6">
      <div className="text-neutral-400">Office address</div>
      <div className="text-neutral-800">
        {loanOfficer.office_street_addr ?? "-"}
      </div>
    </div>

    <div className="pb-6">
      <div className="text-neutral-400">Branch NMLS</div>
      <div className="text-neutral-800">{loanOfficer.branch_nmls ?? "-"}</div>
    </div>
  </div>
)

export default LoanOfficerOverview
