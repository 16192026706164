import { api } from "../common/utils"
import {
  BRAND_MANAGE_ENDPOINT,
  LO_ENDPOINT,
  BRAND_REVIEWS_ENDPOINT,
  PLACES_ENDPOINT
} from "../common/constants"
import {
  APIError,
  Brand,
  LoanOfficer,
  CreateBrandPayload,
  CreateBrandResponse,
  AllReviews,
  GooglePlace
} from "../types"

/**
 * Brands API wrapper
 */

const brand = {
  get: async (): Promise<Brand[] | APIError> => {
    const { data } = await api.get(BRAND_MANAGE_ENDPOINT)
    return data
  },
  getById: async (id: string): Promise<Brand | APIError> => {
    const params = { id }
    const { data } = await api.get(BRAND_MANAGE_ENDPOINT, { params })
    return data
  },
  add: async (brandData: CreateBrandPayload): Promise<CreateBrandResponse> => {
    if (!brandData.name) {
      throw new Error("Brand name is missing")
    }
    const { data } = await api.post(BRAND_MANAGE_ENDPOINT, brandData)
    return data
  },
  update: async (brandData: Brand): Promise<CreateBrandResponse | APIError> => {
    if (!brandData.id) {
      throw new Error("Brand id is missing")
    }
    const { data } = await api.post(BRAND_MANAGE_ENDPOINT, brandData)
    return data
  },
  delete: async (id: string) => {
    const { data } = await api.delete(`${BRAND_MANAGE_ENDPOINT}?id=${id}`)
    return data
  },
  getMembers: async (brandId: string): Promise<LoanOfficer[] | APIError> => {
    const { data } = await api.get(LO_ENDPOINT, {
      params: { brand_id: brandId }
    })
    return data?.members ? data.members : data
  },

  reviews: async (params: any): Promise<AllReviews | APIError> => {
    if (
      !params.id &&
      !params.place_id &&
      !params.zillow_brand_name &&
      !params.experience_id &&
      !params.yelp_id
    ) {
      throw new Error("Required params are missing")
    }
    const { data } = await api.get(BRAND_REVIEWS_ENDPOINT, { params })
    return data
  },
  find: async (params: any): Promise<any | APIError> => {
    const { data } = await api.get(BRAND_MANAGE_ENDPOINT, { params })
    return data
  },
  placeAutoComplete: async (params: {
    input: string
  }): Promise<any | APIError> => {
    const { data } = await api.get(PLACES_ENDPOINT, { params })
    return data
  },
  getPlace: async (params: {
    place_id: string
  }): Promise<GooglePlace | APIError> => {
    const { data } = await api.get(PLACES_ENDPOINT, { params })
    return data
  }
}

export default brand
