import { useEffect, useMemo } from "react"
import { LayoutList, LayoutGrid } from "lucide-react"
import { useWindowSize } from "../../../../hooks"
import { ViewType } from "../../../../../types"

const currentClassName = "z-2 bg-primary-50 border-primary-500 text-primary-600"
const defaultClassName =
  "bg-white border-gray-300 text-gray-500 hover:bg-gray-50"

type Props = {
  className: string
  viewType: ViewType
  setViewType: (viewType: ViewType) => void
}

const classNameDefault = ""

const ToggleView = ({
  viewType,
  setViewType,
  className = classNameDefault
}: Props) => {
  const windowSize = useWindowSize()

  const inMobileView = useMemo(
    () => windowSize[0] && windowSize[0] < 640,
    [windowSize]
  )

  useEffect(() => {
    if (inMobileView) {
      setViewType("grid")
    }
  }, [inMobileView, setViewType])

  if (inMobileView) return null

  return (
    <div className={`flex rounded-md -space-x-px ${className}`}>
      <button
        type="button"
        className={`${
          viewType === "table" ? currentClassName : defaultClassName
        } relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-l-md group`}
        onClick={() => setViewType("table")}
      >
        <span className="sr-only">Table view</span>
        <LayoutList
          size={16}
          aria-hidden="true"
          className={
            viewType === "table"
              ? "text-base-dark"
              : "group-hover:text-base-dark"
          }
        />
      </button>
      <button
        type="button"
        className={`${
          viewType === "grid" ? currentClassName : defaultClassName
        } relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-r-md group`}
        onClick={() => setViewType("grid")}
      >
        <span className="sr-only">Grid view</span>
        <LayoutGrid
          size={16}
          aria-hidden="true"
          className={
            viewType === "grid"
              ? "text-base-dark"
              : "group-hover:text-base-dark"
          }
        />
      </button>
    </div>
  )
}
export default ToggleView
