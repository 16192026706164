import { RefreshCw } from "lucide-react"

interface Props {
  onClick: () => void
  className?: string
}

const RefetchButton = ({ onClick, className = "" }: Props) => (
  <button
    tabIndex={0}
    className={`flex items-center text-primary-400 font-medium cursor-pointer select-none ${className}`}
    onClick={onClick}
  >
    <RefreshCw size={20} />
    <span className="hidden md:inline ml-2">
      Refresh to load recent activity
    </span>
  </button>
)

export default RefetchButton
