import { ChangeEvent, FC } from "react"
import { useIntegrationSettingsForm } from "../../../../hooks"
import CalcCheckboxes from "./calc-checkboxes"
import LeadWorkflowOption from "./lead-workflow"
import ContactForm from "./contact-form"
import PricingCards from "./pricing-cards"
import { Integration } from "../../../../../types"

interface Props {
  integration?: Integration
}

/**
 * TODO: We should standardize the way we save widget settings
 */
const WidgetsCheckboxes: FC<Props> = ({ integration }) => {
  const { app_settings, saveSetting } = useIntegrationSettingsForm(integration)

  const { settings } = app_settings ?? {}

  const onChangeSettings = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    saveSetting({ [name]: !settings?.[name as keyof typeof settings] })
  }

  return (
    <fieldset>
      <legend className="pt-6 font-medium">Widgets</legend>
      <p className="text-neutral-400 pb-3">
        Choose the embeds you would like to integrate
      </p>
      <CalcCheckboxes />
      <LeadWorkflowOption />
      <ContactForm />
      <PricingCards />
      <div className="flex items-center py-2">
        <input
          checked={settings?.loReviews ?? false}
          onChange={onChangeSettings}
          id="loReviews"
          name="loReviews"
          type="checkbox"
          className="h-6 w-6 rounded border-neural-400 text-primary-500 focus:ring-primary-300 cursor-pointer"
        />
        <label htmlFor="loReviews" className="select-none ml-3 cursor-pointer">
          LO Reviews
        </label>
      </div>
      <div className="flex items-center py-2">
        <input
          checked={settings?.brandReviews ?? false}
          onChange={onChangeSettings}
          id="brandReviews"
          name="brandReviews"
          type="checkbox"
          className="h-6 w-6 rounded border-neural-400 text-primary-500 focus:ring-primary-300 cursor-pointer"
        />
        <label
          htmlFor="brandReviews"
          className="select-none ml-3 cursor-pointer"
        >
          Brand Reviews
        </label>
      </div>
      <div className="flex items-center py-2">
        <input
          checked={settings?.rateTable ?? false}
          onChange={onChangeSettings}
          id="rateTable"
          name="rateTable"
          type="checkbox"
          className="h-6 w-6 rounded border-neural-400 text-primary-500 focus:ring-primary-300 cursor-pointer"
        />
        <label htmlFor="rateTable" className="select-none ml-3 cursor-pointer">
          Rate Table
        </label>
      </div>
      <div className="flex items-center py-2">
        <input
          checked={settings?.aboutBoxes ?? false}
          onChange={onChangeSettings}
          id="aboutBoxes"
          name="aboutBoxes"
          type="checkbox"
          className="h-6 w-6 rounded border-neural-400 text-primary-500 focus:ring-primary-300 cursor-pointer"
        />
        <label htmlFor="aboutBoxes" className="select-none ml-3 cursor-pointer">
          About Boxes
        </label>
      </div>
    </fieldset>
  )
}

export default WidgetsCheckboxes
