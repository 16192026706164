import { FC } from "react"
import { ActivityTableRow } from "../../../../../types"
import ActivityCard from "../../card"
import {
  DEFAULT_DATETIME_FORMAT,
  formatDateTimeFromTimestamp
} from "../../../../utils"

interface Props {
  info: ActivityTableRow
}

const DescriptionCell: FC<Props> = ({ info }) => (
  <td className="whitespace-nowrap py-3 px-3" colSpan={3}>
    <div className="block md:hidden text-sm text-gray-400">
      {formatDateTimeFromTimestamp(info.timestamp, DEFAULT_DATETIME_FORMAT)}
    </div>
    <ActivityCard
      activity={info}
      member={info.detail?.lo}
      isLoActivity={info.isLoActivity}
    />
  </td>
)

export default DescriptionCell
