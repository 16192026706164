import { LoanOfficer } from "../../../../../types"
import ImageWrapper from "../../../img-wrapper"

interface Props {
  info: LoanOfficer
  onBrandCellClick?: (id: string) => void
}

const BrandCell = ({ info, onBrandCellClick }: Props) => {
  const { bank, logo, uid } = info

  return (
    <td
      className="whitespace-nowrap py-4 px-3 hidden md:table-cell cursor-pointer"
      onClick={() => onBrandCellClick && onBrandCellClick(uid)}
    >
      <div className="flex items-center">
        <ImageWrapper>
          <img
            className="max-h-12 max-w-[7rem] truncate object-contain"
            src={logo}
            alt={`${bank} logo`}
          />
        </ImageWrapper>
      </div>
    </td>
  )
}

export default BrandCell
