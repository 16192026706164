/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 */

export function debounce(
  func: Function,
  wait: number = 250,
  immediate?: boolean,
  context?: any
): Function {
  let timeout: any
  return function (this: any, ...args: any[]) {
    const later = () => {
      timeout = null
      if (!immediate) {
        func.apply(context || this, args)
      }
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) {
      func.apply(context || this, args)
    }
  }
}
