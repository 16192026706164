import { forwardRef } from "react"
import { Calendar } from "lucide-react"

const CustomInput = forwardRef<HTMLInputElement, any>((props, ref) => {
  return (
    <div className="relative">
      <input
        type="text"
        onClick={props.onClick}
        value={
          `${props.value}${props.extraValue ? ` - ${props.extraValue}` : ""}` ||
          props.placeholder
        }
        ref={ref}
        className="w-[220px] h-10 text-sm ps-3 pr-10 py-2"
      />
      <span
        className="absolute top-3 right-3 cursor-pointer text-neutral-400"
        onClick={props.onClick}
      >
        <Calendar size={16} />
      </span>
    </div>
  )
})

export default CustomInput
