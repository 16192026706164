import { FC } from "react"
import { LOsTableWithImporter } from "../../loan-officers"

interface Props {
  brandId?: string
}

const BrandDetailsLoanOfficers: FC<Props> = ({ brandId }) => {
  return (
    <div className="p-6">
      <LOsTableWithImporter searchParams={{ brand_id: brandId }} />
    </div>
  )
}

export default BrandDetailsLoanOfficers
