import {
  createColumnsForEmailStats,
  createColumnsForUnsubscribedStats,
  createTableFooterColumn
} from "../utils"
import {
  QuoteReceiptStats,
  SubscriptionEmailStats,
  TableFooterColumn,
  TableFooterRow,
  UnsubscribedStats
} from "../../types"
import { EmailStatsTabs } from "../ui/leads/charts/email-stats"

export const useTableFooterData = (
  data:
    | (SubscriptionEmailStats | QuoteReceiptStats | UnsubscribedStats)[]
    | undefined,
  isFetching: boolean,
  containsData: boolean | undefined,
  type: EmailStatsTabs
): TableFooterRow[] => {
  if (isFetching) return []

  const footerColumns: TableFooterColumn[] = []
  footerColumns.push(createTableFooterColumn("grand-total", "Grand Total", 2))

  if (type === EmailStatsTabs.QUOTE_RECEIPT) {
    footerColumns.push(createTableFooterColumn("loan-officer", "", 2))
  }

  if (
    type === EmailStatsTabs.RATE_ALERTS ||
    type === EmailStatsTabs.INTRO_EMAILS ||
    type === EmailStatsTabs.QUOTE_RECEIPT
  ) {
    footerColumns.push(
      ...createColumnsForEmailStats(
        data as SubscriptionEmailStats[],
        containsData
      )
    )
  } else {
    footerColumns.push(
      ...createColumnsForUnsubscribedStats(
        data as UnsubscribedStats[],
        containsData
      )
    )
  }

  return [
    {
      columns: footerColumns
    }
  ]
}
