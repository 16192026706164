import Skeleton from "./base"

const SingleRowListSlim = ({ rows = 12 }) => {
  return (
    <div className=" h-full overflow-hidden">
      {[...Array(rows)].map((_, index) => (
        <div key={index} className="bg-white border-b border-gray-100">
          <Skeleton className="w-full py-2 px-6">
            <div className="flex justify-between">
              <div className="h-4 bg-gray-100 rounded-full w-2/5 mb"></div>
              <div className="h-4 bg-gray-100 rounded-full w-1/4 mb-1"></div>
            </div>
          </Skeleton>
        </div>
      ))}
    </div>
  )
}

export default SingleRowListSlim
