import { FC } from "react"
import ReactJSONEditor from "../../../../../../react-json-editor"
import { LeadworkflowDetails } from "../../../../../../../../types"

interface Props {
  values: LeadworkflowDetails
  setFullConfigField: (value: any) => void
}

const LeadworkflowConfig: FC<Props> = ({ values, setFullConfigField }) => {
  return (
    <div className="pt-6 h-full">
      <ReactJSONEditor
        name="config_json"
        json={values.config_json}
        onChangeJSON={val => setFullConfigField(val)}
      />
    </div>
  )
}

export default LeadworkflowConfig
