/**
 * Lead manage API wrapper
 */

import { api, getSessionIdFromLocalStorage } from "../common/utils"
import {
  LEADLOG_ENDPOINT,
  LEAD_MANAGE_ENDPOINT,
  LEAD_VALIDATE_ENDPOINT
} from "../common/constants"
import { APIError, Lead } from "../types"

const lead = {
  get: async (params: any): Promise<Lead[] | APIError> => {
    const { data } = await api.get(LEAD_MANAGE_ENDPOINT, { params })
    return data
  },
  getLog: async (params: any) => {
    const { data } = await api.get(LEADLOG_ENDPOINT, { params })
    return data
  },
  add: async (leadData: any) => {
    const url = `${LEAD_MANAGE_ENDPOINT}?bb_sid=${getSessionIdFromLocalStorage()}`
    if (!leadData.owner) {
      throw new Error("owner is missing")
    }
    if (!leadData.email && !leadData.phone) {
      throw new Error("not enough info to add a lead")
    }

    const { data } = await api.post(url, leadData)
    return data
  },
  validate: async (validationId: string) => {
    const url = `${LEAD_VALIDATE_ENDPOINT}?bb_sid=${getSessionIdFromLocalStorage()}`
    const { data } = await api.post(url, { id: validationId })
    return data
  }
}

export default lead
