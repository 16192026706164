import { FC, useState } from "react"
import { useFetchOrgDetailsQuery } from "../../../../../redux/queries"
import { Integration } from "../../../../../types"
import { useAppSelector, useIntegrationSettingsForm } from "../../../../hooks"
import { getOrgBrandsIDs } from "../../../../utils"
import { selectUserDetails } from "../../../../../redux/slices/auth"
import LoanOfficerAutocomplete from "../../../loan-officer-autocomplete"

interface Props {
  integration?: Integration
}

const IntegrationOwnerSelector: FC<Props> = ({ integration }) => {
  const { values, handleFormikChange } = useIntegrationSettingsForm(integration)

  const user = useAppSelector(selectUserDetails)
  const { data: orgDetails } = useFetchOrgDetailsQuery(
    { id: user?.org?.id ?? "" },
    { skip: !user?.org?.id }
  )

  const brandsIDs = getOrgBrandsIDs(orgDetails)

  const [selectedID, setSelectedMemberID] = useState<string>(values.owner)

  const setSelected = (ownerID: string) => {
    // emulates the formik change event
    handleFormikChange({
      target: {
        name: "owner",
        value: ownerID
      }
    })
    if (ownerID !== selectedID) {
      setSelectedMemberID(ownerID) // set the state
    }
  }

  return (
    <div className="py-6">
      <LoanOfficerAutocomplete
        searchParams={{ brand_id: integration?.brand_id ?? brandsIDs ?? "" }}
        label="Change owner"
        selected={selectedID}
        onChange={val => setSelected(val)}
        direction="vertical"
      />
    </div>
  )
}

export default IntegrationOwnerSelector
