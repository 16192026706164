import { Fragment } from "react"
import { Menu, Transition } from "@headlessui/react"
import { useInMobileView } from "../../hooks/use-window-size"
import MobileMenu from "../mobile-menu"
import { DropdownItemProps } from "../../../types"
import DropdownItem from "../dropdown-item"

interface Props {
  className?: string
  menuBtn: JSX.Element
  items: DropdownItemProps[]
}

const Dropdown: React.FC<Props> = ({ className, menuBtn, items }) => {
  const inMobileView = useInMobileView()

  if (inMobileView) {
    return <MobileMenu className={className} menuBtn={menuBtn} items={items} />
  }

  return (
    <Menu as="div" className={`relative z-10 ${className}`}>
      <div>
        <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white">
          {menuBtn}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 min-w-[12rem] rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          {items
            .filter(item => !item.hidden)
            .map((item, idx) => (
              <Menu.Item key={idx}>
                <DropdownItem item={item} />
              </Menu.Item>
            ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default Dropdown
