import { FC, useMemo, useState } from "react"
import { useFindRateflowsQuery } from "../../../../redux/queries"
import BrandAutocomplete from "../../brand-autocomplete"
import { Brand } from "../../../../types"
import RateflowTable from "./table"
import ManageRateflow from "./manage"

interface Props {
  brands: Brand[]
}

const ConfigRateflow: FC<Props> = ({ brands }) => {
  const [selectedBrandId, setSelectedBrandId] = useState<string>("")
  const [selectedRateflowId, setSelectedRateflowId] = useState<string>("")

  const { data: rateflows, isFetching } = useFindRateflowsQuery(
    { bank_id: selectedBrandId },
    { skip: !selectedBrandId }
  )

  const rateflowDetails = useMemo(
    () => rateflows?.find(rf => rf.id === selectedRateflowId),
    [rateflows, selectedRateflowId]
  )

  // TODO: This api throws CORS error
  // const { data: rateflowDetails } = useFindRateflowDetailsQuery(
  //   { id: selectedRateflowId },
  //   { skip: !selectedRateflowId }
  // )

  const resetSelectedRateflowId = () => setSelectedRateflowId("")

  return (
    <div>
      <BrandAutocomplete
        brands={brands}
        selected={selectedBrandId}
        onChange={setSelectedBrandId}
      />

      <div className="pt-6">
        {selectedBrandId ? (
          <RateflowTable
            data={rateflows ?? []}
            isFetching={isFetching}
            onSelect={setSelectedRateflowId}
          />
        ) : null}
      </div>

      <ManageRateflow
        open={!!(selectedRateflowId && rateflowDetails)}
        data={rateflowDetails}
        onClose={resetSelectedRateflowId}
      />
    </div>
  )
}

export default ConfigRateflow
