import { FC } from "react"
import { Loader2 } from "lucide-react"
import { useFetchSubscriptionEmailLogsQuery } from "../../../../../../redux/queries"
import { SubscriptionDetails } from "../../../../../../types"
import EngagementEmailLog from "./email-log"

interface Props {
  data: SubscriptionDetails
}

const EngagementEmailHistory: FC<Props> = ({ data }) => {
  const { data: emailLogs, isFetching } = useFetchSubscriptionEmailLogsQuery(
    { id: data.id },
    { skip: !data.id }
  )

  if (isFetching) {
    return (
      <div className="h-full flex items-center justify-center">
        <Loader2
          className="animate-spin h-24 w-24 text-primary-alt-400"
          aria-hidden="true"
        />
      </div>
    )
  }

  if (!emailLogs?.length) {
    return <div>No email logs found.</div>
  }

  return (
    <div>
      {emailLogs.map(el => (
        <EngagementEmailLog key={el.id} log={el} />
      ))}
    </div>
  )
}

export default EngagementEmailHistory
