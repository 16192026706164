import { CSSProperties, FC } from "react"

interface TdInfoCellProps {
  info: string | number
  customContainerClass?: string
  customTextClass?: string
  customTextStyle?: CSSProperties
  colSpan?: number
}

const TdInfoCell: FC<TdInfoCellProps> = ({
  info,
  customContainerClass = "",
  customTextClass = "",
  customTextStyle,
  colSpan = 1
}) => {
  return (
    <td
      className={`whitespace-nowrap cursor-pointer ${customContainerClass}`}
      colSpan={colSpan}
    >
      <div className={customTextClass} style={customTextStyle}>
        {info}
      </div>
    </td>
  )
}

export default TdInfoCell
