import { FC } from "react"
import {
  GooglePlace,
  LoSearchParams,
  SubscriptionStatus,
  CreateSubscriptionPayload
} from "../../../../../../types"
import AddressAutocomplete from "../../../../address-autocomplete"
import Input from "../../../../form/input"
import Select from "../../../../form/select"
import PhoneInput from "../../../../form/phone-input"
import { convertAddressObjToPlaceString } from "../../../../../utils"
import { ENGAGEMENT_STATUS_OPTIONS } from "../../../../../constants"
import LoanOfficerAutocomplete from "../../../../loan-officer-autocomplete"

interface Props {
  values: CreateSubscriptionPayload
  loSearchParams: LoSearchParams
  setFormField: (name: string, value: any) => void
  setGooglePlace: (value: GooglePlace) => void
}

const ManageEngagementBasic: FC<Props> = ({
  values,
  loSearchParams,
  setFormField,
  setGooglePlace
}) => {
  const currentStatus: SubscriptionStatus =
    values.deleted === "1"
      ? "deleted"
      : values.active === "1"
      ? "active"
      : "disabled"
  const selectedStatus = ENGAGEMENT_STATUS_OPTIONS.find(
    eso => eso.id === currentStatus
  )

  const handleChangeStatus = (val: SubscriptionStatus) => {
    switch (val) {
      case "deleted":
        setFormField("deleted", "1")
        setFormField("active", "0")
        break

      case "active":
        setFormField("deleted", "0")
        setFormField("active", "1")
        break

      case "disabled":
        setFormField("deleted", "0")
        setFormField("active", "0")
        break

      default:
        break
    }
  }

  return (
    <div className="py-6">
      <div className="pb-6">
        <LoanOfficerAutocomplete
          onlyAdmins
          searchParams={loSearchParams}
          label="Loan officer"
          selected={values.loid}
          onChange={val => setFormField("loid", val)}
          direction="vertical"
        />
      </div>

      <div className="pb-6">
        <Input
          name="email"
          label="Email address"
          placeholder="Ex. john@email.com"
        />
      </div>
      <div className="grid grid-cols-2 gap-8">
        <Input name="first_name" label="First name" placeholder="Ex. John" />
        <Input name="last_name" label="Last name" placeholder="Ex. Doe" />
        <PhoneInput
          name="phone"
          label="Phone number"
          placeholder="Ex. (178) 346-7821"
        />
        <div>
          <Select
            label="Status"
            name="status"
            selected={selectedStatus}
            data={ENGAGEMENT_STATUS_OPTIONS}
            setSelected={handleChangeStatus}
          />
        </div>
      </div>

      <div className="pt-10">
        <div className="text-xl font-bold pb-6">Property location</div>
        <AddressAutocomplete
          label="Property address"
          name="location"
          initialInputText={convertAddressObjToPlaceString({
            city: values.city,
            county: values.county,
            state: values.state,
            street_address: values.street_address,
            zipcode: values.zipcode
          })}
          placeholder="Search for address, city or zipcode"
          onChange={setGooglePlace}
        />
      </div>
    </div>
  )
}

export default ManageEngagementBasic
