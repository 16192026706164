import axios from "axios"

const api = axios.create({
  baseURL: process.env.REACT_APP_API_HOST ?? "/",
  withCredentials: true
})

export default api

export const getQueryString = (params: {
  [key: string]: number | string | boolean | undefined
}): string => {
  const str = Object.keys(params ?? {})
    .filter(
      k => params[k] !== null && params[k] !== undefined && params[k] !== ""
    )
    .map(k => `${k}=${params[k]}`)
    .join("&")

  return str ? `?${str}` : ""
}
