import { FC } from "react"
import { Table } from "@tanstack/react-table"
import { ChevronLeft, ChevronRight } from "lucide-react"

import { usePagination } from "../../hooks"

type ChartPaginationProps = {
  instance: Table<any>
  totalCount: number
  siblingCount?: number
}

const ChartPagination: FC<ChartPaginationProps> = ({
  instance,
  totalCount,
  siblingCount = 1
}) => {
  const { pageIndex, pageSize } = instance.getState().pagination
  const paginationRange: (string | number)[] | undefined = usePagination({
    pageIndex,
    totalCount,
    siblingCount
  })

  if (paginationRange?.length < 2) {
    return null
  }

  return (
    <div className="bg-white flex items-center justify-between border-t border-gray-200 py-2">
      <div className="flex-1 flex items-center justify-end">
        <div className="mr-2 text-sm">
          {`${(pageIndex + 1) * pageSize - pageSize + 1} - ${
            (pageIndex + 1) * pageSize
          } of ${instance.getPrePaginationRowModel().rows.length}`}
        </div>
        <nav
          className="relative z-0 inline-flex rounded-md -space-x-px"
          aria-label="Pagination"
        >
          <button
            aria-label="Previous"
            type="button"
            onClick={instance.previousPage}
            disabled={!instance.getCanPreviousPage()}
            className="relative inline-flex items-center p-2 rounded-l-md bg-white font-bold text-base-dark hover:bg-gray-50"
          >
            <ChevronLeft size={20} aria-hidden="true" />
          </button>
          <button
            aria-label="Next"
            type="button"
            onClick={instance.nextPage}
            disabled={!instance.getCanNextPage()}
            className="relative inline-flex items-center p-2 rounded-r-md bg-white font-bold text-base-dark hover:bg-gray-50"
          >
            <ChevronRight size={20} aria-hidden="true" />
          </button>
        </nav>
      </div>
    </div>
  )
}

export default ChartPagination
