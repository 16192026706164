import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { getQueryString } from "../../common/utils"
import {
  LOAN_SUMMARY_PDF_ENDPOINT,
  RATEFLOW_CHART_ENDPOINT,
  RATEFLOW_ENDPOINT,
  RATEFLOW_LOG_ENDPOINT,
  RATEFLOW_PRICE_ENDPOINT,
  RATEFLOW_SCENARIO_ENDPOINT,
  RTK_SLICE_API_LIST
} from "../../common/constants"
import {
  FindPricingRateResponse,
  FindDefaultScenariosResponse,
  APIError,
  RateflowLog,
  RateflowChartData,
  PricingLog,
  RateflowLogPdfData,
  CreateRateflowPriceFromLeadPayload,
  RateflowCard,
  RateFlowDetails
} from "../../types"
import { baseQuery, getTags } from "../api"
import bbApi from "../../api"

const reducerPath = "rateflows"
const rateflowPricesPath = "rateflow-prices"
const rateflowLogsPath = "rateflow-logs"

export const rateflowApi = createApi({
  reducerPath,
  baseQuery,
  tagTypes: [reducerPath, rateflowPricesPath, rateflowLogsPath],
  endpoints: builder => ({
    findRateflows: builder.query({
      query: (params: { bank_id: string }) =>
        `${RATEFLOW_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: RateFlowDetails[]) =>
        responseData ?? [],
      providesTags: result => getTags(reducerPath, "id", result)
    }),
    findRateflowDetails: builder.query({
      query: (params: { id: string }) =>
        `${RATEFLOW_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: RateFlowDetails) => responseData ?? []
    }),
    findRateflowPricesByLo: builder.query({
      query: (params: { loid: string; max_results?: number }) =>
        `${RATEFLOW_PRICE_ENDPOINT}${getQueryString(params)}`,
      transformResponse: (responseData: FindPricingRateResponse) =>
        responseData.results ?? [],
      providesTags: result => getTags(rateflowPricesPath, "id", result)
    }),
    findRateflowPricesById: builder.query({
      query: (id: string) => `${RATEFLOW_PRICE_ENDPOINT}?id=${id}`,
      transformResponse: (responseData: FindPricingRateResponse) => responseData
    }),
    findDefaultScenariosByLo: builder.query({
      query: (loid: string) => `${RATEFLOW_SCENARIO_ENDPOINT}?loid=${loid}`,
      transformResponse: (responseData: FindDefaultScenariosResponse) =>
        responseData.scenarios
    }),
    fetchRateflowLogs: builder.query({
      query: (params: any) => {
        return `${RATEFLOW_LOG_ENDPOINT}${getQueryString({
          ...params,
          limit: 10000
        })}`
      },
      transformResponse: (responseData: { data: PricingLog[] }) =>
        responseData.data,
      providesTags: result => getTags(rateflowLogsPath, "id", result)
    }),
    findRateflowLogById: builder.query({
      query: (id: string) => {
        return `${RATEFLOW_LOG_ENDPOINT}?id=${id}&include_request=true&include_cards=true`
      },
      transformResponse: (responseData: RateflowLog) => responseData
    }),
    findRateflowLogsById: builder.query({
      query: (id: string[]) => {
        const ids = Array.isArray(id) ? id.join(",") : id
        return `${RATEFLOW_LOG_ENDPOINT}?id=${ids}&include_request=true&include_cards=true`
      },
      transformResponse: (responseData: RateflowLog[]) => responseData
    }),
    findChartDataByProfileId: builder.query({
      query: ({ profileId, range }: { profileId: string; range: number }) => {
        return `${RATEFLOW_CHART_ENDPOINT}?profile_id=${profileId}&type=line_chart&include_treasury=true&range=${range}`
      },
      transformResponse: (responseData: RateflowChartData) => responseData
    }),
    fetchRateflowPdf: builder.query({
      query: (params: { quote_id: string; card_hash?: string }) => {
        return `${LOAN_SUMMARY_PDF_ENDPOINT}${getQueryString(params)}`
      },
      transformResponse: (responseData: RateflowLogPdfData, _, arg) => {
        return { ...responseData, ...arg }
      }
    }),
    createRateflowPrice: builder.mutation({
      query: (body: CreateRateflowPriceFromLeadPayload) => ({
        url: RATEFLOW_PRICE_ENDPOINT,
        method: "POST",
        body
      }),
      transformResponse: (responseData: RateflowCard[] | APIError) =>
        responseData
    }),
    updateOrCreateRateflow: builder.mutation({
      queryFn: async (payload: Partial<RateFlowDetails>) => {
        // Remove "id" field if it's empty which means it's CREATE action
        if (!payload.id) {
          delete payload.id
        }
        const data = await bbApi.rateflow.updateOrAdd(payload)
        return { data }
      },
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: arg.id },
        { type: reducerPath, id: RTK_SLICE_API_LIST }
      ]
    })
  })
})

export const {
  useFindRateflowDetailsQuery,
  useFindRateflowsQuery,
  useFindDefaultScenariosByLoQuery,
  useFindRateflowPricesByLoQuery,
  useFindRateflowPricesByIdQuery,
  useFindRateflowLogsByIdQuery,
  useFindRateflowLogByIdQuery,
  useFindChartDataByProfileIdQuery,
  useFetchRateflowLogsQuery,
  useFetchRateflowPdfQuery,
  useCreateRateflowPriceMutation,
  useUpdateOrCreateRateflowMutation,
  endpoints: rateflowEndpoints
} = rateflowApi
