import { FC } from "react"
import { Integration } from "../../../../../types"
import { useIntegrationSettingsForm } from "../../../../hooks"
import If from "../../../if"
import ContactFormSelector from "../contact-form-selector"

interface Props {
  integration?: Integration
}

const ContactForm: FC<Props> = ({ integration }) => {
  const { app_settings, saveSetting } = useIntegrationSettingsForm(integration)

  const { settings } = app_settings ?? {}

  const onChange = () => {
    saveSetting({ contactForm: Number(settings?.contactForm) >= 1 ? 0 : 1 })
  }

  return (
    <div className="py-2">
      <input
        onChange={onChange}
        checked={settings?.contactForm ? true : false}
        id="contactForm"
        name="contactForm"
        type="checkbox"
        className="h-6 w-6 rounded border-neural-400 text-primary-500 focus:ring-primary-300 cursor-pointer"
      />
      <label htmlFor="contactForm" className="select-none ml-3 cursor-pointer">
        Contact Form
      </label>
      <If
        condition={!!settings?.contactForm && settings?.contactForm > 0}
        then={<ContactFormSelector />}
      />
    </div>
  )
}

export default ContactForm
