import { Loader2 } from "lucide-react"
import { SubscriptionLog } from "../../../../../types"
import { useFetchSubscriptionDetailsQuery } from "../../../../../redux/queries"

interface Props {
  info: SubscriptionLog
  openLink: (link: string) => void
}

const ViewDashboardCell: React.FC<Props> = ({ info, openLink }) => {
  const { data } = useFetchSubscriptionDetailsQuery({ id: info.id })

  if (!data) {
    return (
      <div className="w-full px-6 py-2 items-center justify-end hidden md:flex">
        <Loader2
          className="animate-spin h-6 w-6 text-primary-alt-400"
          aria-hidden="true"
        />
      </div>
    )
  }

  return (
    <td className="view-dashboard-cell relative whitespace-nowrap py-4 px-0 text-right text-sm cursor-pointer sm:pr-6 pl-3 md:pr-4 hidden md:table-cell">
      <span
        className="pb-1 border-b border-dotted border-neutral-400"
        onClick={() => openLink(data?.dashboard_link ?? "")}
      >
        View Lead dashboard
      </span>
    </td>
  )
}
export default ViewDashboardCell
