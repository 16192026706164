import { useLocation, Navigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../hooks"
import { useEffect } from "react"
import { getMe, logout, selectSessId } from "../../redux/slices/auth"
import { api } from "../utils"

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const dispatch = useAppDispatch()
  const sessid = useAppSelector(selectSessId)
  const isLoggedIn: boolean = useAppSelector(state => {
    return state?.auth.isLoggedIn ?? false
  })
  const location = useLocation()

  useEffect(() => {
    if (sessid) {
      api.defaults.headers.common["Authorization"] = sessid
      dispatch(getMe()).then((res: any) => {
        // if user is not loggein in, redirect to login page
        if (!res.payload?.userDetails) {
          dispatch(logout())
        }
      })
    }
  }, [dispatch, sessid])

  if (!isLoggedIn) {
    // Redirect them to the /user/login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/user/login" state={{ from: location }} replace />
  }

  return children
}

export default RequireAuth
