import {
  DEFAULT_DATETIME_SECONDS_FORMAT,
  formatDateTimeFromTimestamp
} from "../../../utils"

type Props = {
  value: string
  className?: string
}

const TimestampCell = ({
  value,
  className = "py-4 hidden md:table-cell"
}: Props) => (
  <td className={`${className} whitespace-nowrap px-3 text-sm text-gray-500`}>
    {formatDateTimeFromTimestamp(value, DEFAULT_DATETIME_SECONDS_FORMAT)}
  </td>
)

export default TimestampCell
