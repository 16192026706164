import { FC } from "react"
import If from "../../../if"
import NameCell from "./cells/name"
import Table from "../../../table"
import { BBColumnDef, Brand, SubscriptionConfig } from "../../../../../types"
import EnabledCell from "./cells/enabled"

interface Props {
  data: SubscriptionConfig[]
  brands: Brand[]
  isFetching: boolean
  onSelect: (val: string) => void
}

const SubscriptionConfigTable: FC<Props> = ({
  data,
  brands,
  isFetching,
  onSelect
}) => {
  const columns: BBColumnDef<SubscriptionConfig>[] = [
    {
      id: "name",
      header: "Name",
      accessorFn: row => row.brand_id,
      footer: props => props.column.id,
      cell: props => (
        <NameCell
          info={props.row.original}
          brands={brands}
          onSelect={onSelect}
        />
      )
    },
    {
      id: "enabled",
      header: "Enabled",
      accessorFn: row => row.enabled,
      footer: props => props.column.id,
      cell: props => <EnabledCell info={props.row.original} />
    }
  ]

  return (
    <div>
      <If
        condition={!data?.length && !isFetching}
        then={<div>No subscription configs found.</div>}
        else={
          <Table
            isLoading={isFetching}
            data={data}
            columns={columns}
            viewType="table"
            shrinkFilterSort
            noHeader
            searchBarVisible={false}
            defaultSortBy="name"
            defaultSortDir="desc"
          />
        }
      />
    </div>
  )
}

export default SubscriptionConfigTable
