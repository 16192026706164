import { FC } from "react"
import { LoanOfficer } from "../../../../types"
import { capitalize, CRM_TYPES } from "../../../utils"
import Input from "../../form/input"
import Select from "../../form/select"

interface Props {
  title: string
  values: LoanOfficer
  setFormField: (name: string, value: string) => void
}

const CRM_TYPE_OPTIONS = CRM_TYPES.map(ct => ({
  id: ct,
  name: ct
    .split("_")
    .map(str => capitalize(str))
    .join(" ")
}))

const IntegrationsSection: FC<Props> = ({ title, values, setFormField }) => {
  const selectedCrmType = CRM_TYPE_OPTIONS.find(
    cto => cto.id === values?.crm_type
  )

  return (
    <div className="p-6">
      <div>
        <h4 className="text-xl font-bold mb-6 text-primary-alt-400 ">
          {title}
        </h4>
      </div>
      <div className="grid gap-y-6 gap-x-8 grid-cols-12 pt-8">
        <div className="col-span-12">
          <Input label="Search LO & Connect pricing engine" name="n/a" />
        </div>
        <div className="col-span-12">
          <Select
            label="CRM type"
            selected={selectedCrmType}
            data={CRM_TYPE_OPTIONS}
            IDkey="id"
            setSelected={val => setFormField("crm_type", val)}
          />
        </div>
        <div className="col-span-12">
          <Input label="Unique CRM ID" name="crm_id" />
        </div>
      </div>
    </div>
  )
}

export default IntegrationsSection
