import { FC } from "react"

import Skeleton from "./base"

type NormalListProps = {
  noOfRows?: number
  noOfColumns?: number
  containerClassName?: string
}

const NormalList: FC<NormalListProps> = ({
  noOfRows = 12,
  noOfColumns = 3,
  containerClassName = "shadow-default p-6 bg-primary-100"
}) => {
  const firstIndex = 0

  return (
    <div className={containerClassName}>
      {[...Array(noOfRows)].map((_, index) => (
        <div key={index} className="bg-white border-b border-gray-300">
          <Skeleton className="w-full py-6 px-8">
            <div className="flex">
              <div className="w-2/3 md:w-1/2">
                <div className="h-4 bg-gray-200 rounded-full w-2/3 md:w-56 mb-1.5"></div>
                <div className="h-4 bg-gray-100 rounded-full md:w-80"></div>
              </div>
              <div className="flex flex-row-reverse md:flex-row w-1/3 md:w-1/2 md:gap-8">
                {[...Array(noOfColumns)].map((_, index) => (
                  <div
                    key={index}
                    className={index === firstIndex ? "" : "hidden md:block"}
                  >
                    <div className="h-4 bg-gray-200 rounded-full w-20 mb-1.5"></div>
                    <div className="h-4 bg-gray-100 rounded-full w-16"></div>
                  </div>
                ))}
              </div>
            </div>
          </Skeleton>
        </div>
      ))}
    </div>
  )
}

export default NormalList
