import { FC, Fragment, useEffect, useMemo, useState } from "react"
import { DateTime } from "luxon"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"

import If from "../../../if"
import GraphSkeleton from "../../../skeletons/graph"
import {
  useFetchDuplicateLeadCountQuery,
  useFetchLeadCountQuery,
  useFetchLeadCumulativeCountQuery
} from "../../../../../redux/queries"
import { useCountDataTransformation } from "../../../../hooks"
import { getAreaSplineChartOptions } from "../../../chart-with-stats/options"

interface LeadGenerationTrendProps {
  title: string
  range: number
  orgId: string | undefined
  loid: string | undefined
}

const LeadGenerationTrend: FC<LeadGenerationTrendProps> = ({
  title,
  range,
  orgId,
  loid
}) => {
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const dateFormat = "yyyy-MM-dd"
  const labelDateFormat = "LLL dd yy"
  const purple400Color = "#786ff6"
  const { data: leadCountData, isFetching: isFetchingLeadCount } =
    useFetchLeadCountQuery(
      {
        range,
        org_id: orgId,
        loid
      },
      { refetchOnMountOrArgChange: true }
    )
  const containsData = useMemo(
    () => leadCountData && leadCountData.length > 0,
    [leadCountData]
  )
  const { data: leadCountCumulativeData } = useFetchLeadCumulativeCountQuery(
    {
      range,
      org_id: orgId,
      loid
    },
    { refetchOnMountOrArgChange: true, skip: !containsData }
  )
  const { data: duplicateLeadCountData } = useFetchDuplicateLeadCountQuery(
    {
      range,
      org_id: orgId,
      loid
    },
    { refetchOnMountOrArgChange: true, skip: !containsData }
  )

  const cumulativeLeadsCount = leadCountCumulativeData?.length
    ? leadCountCumulativeData.reduce((latest, current) =>
        DateTime.fromFormat(latest.timestamp_tz_date, dateFormat) >
        DateTime.fromFormat(current.timestamp_tz_date, dateFormat)
          ? latest
          : current
      ).lead_count
    : undefined
  const duplicateLeadsCount = duplicateLeadCountData?.duplicate_lead_count
  const canCheckForNetLeads =
    (cumulativeLeadsCount || cumulativeLeadsCount === 0) &&
    (duplicateLeadsCount || duplicateLeadsCount === 0)
  const netLeadsCount = canCheckForNetLeads
    ? cumulativeLeadsCount - duplicateLeadsCount
    : undefined
  const netLeadsLostPercentage = canCheckForNetLeads
    ? parseFloat(
        ((duplicateLeadsCount / cumulativeLeadsCount) * 100).toFixed(1)
      )
    : undefined

  const chartLabels = useCountDataTransformation(
    leadCountData,
    containsData,
    purple400Color,
    dateFormat,
    labelDateFormat,
    range
  )

  useEffect(() => {
    setIsFetching(isFetchingLeadCount)
  }, [isFetchingLeadCount])

  return (
    <div className="bg-white flex flex-col flex-1 px-6 pt-6 pb-7 rounded-md overflow-hidden">
      <div className="flex flex-row items-center justify-between">
        <div className="text-base font-medium">{title}</div>
      </div>
      <If
        condition={isFetching}
        then={<GraphSkeleton className="mt-1 pt-12" />}
        else={
          <Fragment>
            <div className="pt-11 pb-3 relative">
              {!containsData && (
                <div className="absolute top-1/2 left-1/2 text-sm z-[1]">
                  No data yet
                </div>
              )}
              <HighchartsReact
                highcharts={Highcharts}
                options={getAreaSplineChartOptions(
                  containsData,
                  chartLabels,
                  "leads",
                  "LEADS"
                )}
                containerProps={{ style: { height: "274px" } }}
              />
            </div>
            <div className="flex text-xs">
              <div className="flex flex-col">
                <div className="leading-5">TOTAL LEADS</div>
                <div className="text-lg leading-6 font-inter-extrabold">
                  {cumulativeLeadsCount?.toLocaleString() ?? "-"}
                </div>
              </div>
              <div className="flex items-center text-base px-4">-</div>
              <div className="flex flex-col">
                <div className="leading-5">DUPLICATE LEADS</div>
                <div className="text-lg leading-6 font-inter-extrabold">
                  {duplicateLeadsCount?.toLocaleString() ?? "-"}
                </div>
              </div>
              <div className="flex items-center text-base px-4">=</div>
              <div className="flex flex-col">
                <div className="leading-5">NET LEADS</div>
                <div className="flex items-center">
                  <div className="text-lg leading-6 font-inter-extrabold pe-2">
                    {netLeadsCount?.toLocaleString() ?? "-"}
                  </div>
                  {canCheckForNetLeads && (
                    <div className="text-sm text-rose-500 font-semibold mt-1">
                      {netLeadsLostPercentage}%
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Fragment>
        }
      />
    </div>
  )
}

export default LeadGenerationTrend
