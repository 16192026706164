import { Fragment, useCallback, useEffect, useState } from "react"
import { Transition } from "@headlessui/react"
import { Loader2, X } from "lucide-react"
import { ToastType } from "../../../types"
import { deleteToast } from "../../../redux/slices/notifications"
import ToastIcon from "./toast-icon"
import If from "../if"
import { useAppDispatch } from "../../hooks"

const MSG_DFT = ""

type Props = ToastType & {
  index: number
}

const Toast = ({
  msg = MSG_DFT,
  theme,
  index,
  timeout = 2000,
  dismiss = true,
  retry
}: Props) => {
  const dispatch = useAppDispatch()
  const [show, setShow] = useState(true)

  const closeToast = useCallback(() => {
    setShow(false)
    dispatch(deleteToast(index))
  }, [dispatch, index])

  useEffect(() => {
    // if timeout is not 0, then close the toast after timeout
    if (timeout > 0) {
      setTimeout(() => {
        closeToast()
      }, timeout)
    }
  }, [closeToast, timeout])

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden flex">
        <div className="flex items-start p-4 flex-1 items-center">
          <If
            condition={theme !== undefined}
            then={
              <div className="mr-2.5">
                <ToastIcon theme={theme} />
              </div>
            }
          />
          <div className="w-0 flex-1 pt-0.5">
            {msg.length > 0 && <p className="text-sm font-medium">{msg}</p>}
          </div>
          <If
            condition={!!(theme !== "danger" && theme !== undefined && dismiss)}
            then={
              <div className="ml-4 flex-shrink-0 flex">
                <button
                  type="button"
                  className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-transparent active:ring-0"
                  onClick={closeToast}
                >
                  <span className="sr-only">Close</span>
                  <X size={16} />
                </button>
              </div>
            }
          />
          <If
            condition={!!(theme === undefined)}
            then={
              <Loader2
                className="animate-spin h-6 w-6 text-primary-500"
                aria-hidden="true"
              />
            }
          />
        </div>
        <If
          condition={!!(theme === "danger")}
          then={
            <div className="ml-4 flex-shrink-0 flex flex-col border-l">
              <button
                type="button"
                className="bg-white py-2 px-4 border-b inline-flex text-sm text-primary-500 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-transparent active:ring-0"
                onClick={retry}
              >
                Try again
              </button>
              <button
                type="button"
                className="bg-white py-2 px-4 rounded-md inline-flex text-sm text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-transparent active:ring-0"
                onClick={closeToast}
              >
                Dismiss
              </button>
            </div>
          }
        />
      </div>
    </Transition>
  )
}

export default Toast
