type Props = {
  value: string
  className?: string
}

const StandardCell = ({ value, className = "py-4" }: Props) => (
  <td className={`${className} whitespace-nowrap px-3 text-sm text-gray-500`}>
    {value}
  </td>
)

export default StandardCell
