import { FC, Fragment, useEffect, useMemo, useState } from "react"

import If from "../../../if"
import FourColumnPerformanceSkeleton from "../../../skeletons/four-column-performance"
import NoData from "../../../no-data"
import ColumnChart from "../../../column-chart"
import { useFetchLeadConversionProgressQuery } from "../../../../../redux/queries"
import { useColumnDetailsTransformation } from "../../../../hooks"

interface LeadConversionProgressProps {
  title: string
  range: number
  orgId: string | undefined
  loid: string | undefined
}

const LeadConversionProgress: FC<LeadConversionProgressProps> = ({
  title,
  range,
  orgId,
  loid
}) => {
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const { data: leadProgressData, isFetching: isFetchingLeadProgress } =
    useFetchLeadConversionProgressQuery(
      {
        range,
        org_id: orgId,
        loid
      },
      { refetchOnMountOrArgChange: true }
    )
  const containsData = useMemo(
    () => leadProgressData && Object.keys(leadProgressData).length > 0,
    [leadProgressData]
  )
  const columnsData = useColumnDetailsTransformation(
    leadProgressData,
    containsData
  )

  useEffect(() => {
    setIsFetching(isFetchingLeadProgress)
  }, [isFetchingLeadProgress])

  return (
    <div className="bg-white flex flex-col flex-1 px-6 pt-6 pb-7 rounded-md overflow-hidden">
      <div className="flex flex-row items-center justify-between">
        <div className="text-base font-medium">{title}</div>
      </div>
      <If
        condition={isFetching}
        then={<FourColumnPerformanceSkeleton className="mt-px pt-11" />}
        else={
          <Fragment>
            {!containsData && <NoData />}
            {containsData && (
              <div className="pt-4">
                <ColumnChart columns={columnsData} />
              </div>
            )}
          </Fragment>
        }
      />
    </div>
  )
}

export default LeadConversionProgress
