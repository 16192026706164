import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { DASHBOARD_PERFORMANCE_STATS_ENDPOINT } from "../../common/constants"
import { baseQuery } from "../api"
import { PerformanceStats, EmbedStat, EmailStat } from "../../types"

const reducerPath = "dashboard-stats-api"

interface queryArgs {
  orgId: string
  range: number
  type: string
}

const keyValuePairFormatter = (obj: EmbedStat | EmailStat) =>
  Object.entries(obj).map(([key, value]) => ({
    key,
    label: key.replaceAll("_", " "),
    value
  }))

export const dashboardApi = createApi({
  reducerPath,
  baseQuery,
  tagTypes: [reducerPath],
  refetchOnMountOrArgChange: true,
  endpoints: builder => ({
    fetchDashboardPerformanceStats: builder.query({
      query: ({ orgId, range, type }: queryArgs) =>
        `${DASHBOARD_PERFORMANCE_STATS_ENDPOINT}?org_id=${orgId}&range=${range}&type=${type}`,
      transformResponse: (
        responseData: PerformanceStats,
        meta,
        args: queryArgs
      ) => {
        if (args.type === "embed") {
          const chartData = responseData?.embed_performance?.stats ?? []
          const stats =
            responseData?.embed_performance &&
            keyValuePairFormatter(responseData?.embed_performance).filter(
              item => item.key !== "stats"
            )

          return {
            type: args.type,
            stats,
            chartData
          }
        }
        const chartData = responseData?.email_performance?.stats || []
        const stats =
          responseData?.email_performance &&
          keyValuePairFormatter(responseData?.email_performance).filter(
            item => item.key !== "stats"
          )

        return {
          type: args.type,
          stats,
          chartData
        }
      }
    })
  })
})

export const { useFetchDashboardPerformanceStatsQuery } = dashboardApi
